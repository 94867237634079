<div class="layout">
  <div class="content" *ngIf="!openSlideTutorial">

    <ng-container>
      <div class="nav-menu" *ngIf="isShow()">
        <app-nav-menu [(openSlideTutorial)]="openSlideTutorial"></app-nav-menu>
      </div>
      <div class="flex justify-content-center pt-10 h-100">

        <div class="col-3 flex justify-content-center pt-5" id="left" *ngIf="isShow()">
          <app-side-left></app-side-left>
        </div>

        <div class="col-6">
          <ng-content></ng-content>
        </div>

        <div class="col-3 flex justify-content-center pt-5" id="right" *ngIf="isShow()">
          <app-side-right></app-side-right>
        </div>
      </div>
    </ng-container>

    <p-toast position="top-right" key="message" (onClose)="clearMessage()">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center cursor-pointer w-100"
          (click)="openMessage(message!)">
          <div class="flex align-items-center justify-content-center w-20">
            <!-- <div class="icon-info"></div> -->
            <img *ngIf="message.data!.sender!.Avatar!" [src]="message.data.sender.Avatar" width="42" height="42"
              style="border-radius: 5px;">
          </div>
          <div class="flex flex-column w-80 pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>


    <p-toast position="top-right" key="noti-comment-album" (onClose)="clearMessage()">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center" (click)="openDetailAlbum(message!)">
          <div class="flex align-items-center justify-content-center w-20">
            <div class="icon-info"></div>
          </div>
          <div class="flex flex-column w-80  pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <p-toast position="top-right" key="noti-comment-post-discussion" (onClose)="clearMessage()">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center cursor-pointer w-100"
          (click)="openDetailPost(message!)">
          <div class="flex align-items-center justify-content-center w-20">
            <div class="icon-info"></div>
          </div>
          <div class="flex flex-column w-80  pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <p-toast position="top-right" key="notification" (onClose)="clearMessage()">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center cursor-pointer w-100"
          (click)="clickNotification(message)">
          <div class="flex align-items-center justify-content-center w-20">
            <!-- <div class="icon-info"></div> -->
            <img *ngIf="message.data.imageUrl" [src]="getThumbnail(message)" width="42" height="42"
              style="border-radius: 5px;">
          </div>
          <div class="flex flex-column w-80 pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <p-toast position="top-right" key="notificationOnWeb" (onClose)="clearMessage()">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center cursor-pointer w-100">
          <div class="flex align-items-center justify-content-center w-20">
            <div class="icon-info"></div>
          </div>
          <div class="flex flex-column w-80 pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <p-toast position="top-right" key="copied">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center w-100">
          <div class="flex align-items-center justify-content-center w-20">
            <div class="icon-info"></div>
          </div>
          <div class="flex flex-column w-80 pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <p-toast position="top-right" key="noti-copy">
      <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center w-100">
          <div class="flex align-items-center justify-content-center w-20">
            <div class="icon-info"></div>
          </div>
          <div class="flex flex-column w-80 pt-3 pb-3">
            <span class="title" [innerHTML]="message.summary"></span>
            <span class="subtitle" [innerHTML]="message.detail"></span>
          </div>
        </div>
      </ng-template>
    </p-toast>

    <ng-container *ngIf="accountInvalidPopup">
      <app-account-invalid-popup [(accountInvalidPopup)]="accountInvalidPopup"></app-account-invalid-popup>
    </ng-container>
  </div>

  <ng-container *ngIf="openSlideTutorial">
    <p-galleria [value]="images" [(visible)]="openSlideTutorial" [containerStyle]="{'width':'90%'}" [numVisible]="5"
      [circular]="true" [showItemNavigators]="true" [fullScreen]="true" [showThumbnails]="false"
      [showIndicators]="activeIndex == 4 ? false : true" [(activeIndex)]="activeIndex"
      [showItemNavigatorsOnHover]="true" [showIndicatorsOnItem]="true" (visibleChange)="close()">
      <ng-template pTemplate="item" let-item>
        <img [src]="item.poster" style="width: 100%; display: block;" />
        <button *ngIf="activeIndex == 4" pButton pRipple type="button" label="{{'LETS_GO' | translate}}"
          (click)="close()" class="p-button-outlined p-button-secondary fixed-button"></button>
      </ng-template>
    </p-galleria>
  </ng-container>

  <ng-container *ngIf="isShowReviewCamera">
    <p-sidebar [(visible)]="isShowReviewCamera" [fullScreen]="true" [showCloseIcon]="false" [dismissible]="true">
      <app-review-camera (close)="isShowReviewCamera = false" (join)="handleInvite(true)"></app-review-camera>
    </p-sidebar>
  </ng-container>

  <ng-container *ngIf="showListClubsPosted">
    <app-list-clubs-celeb-post [clubs]="tempClubCelebPosted" [postCelebId]="tempPostCelebId"
      [(showListClubsPosted)]="showListClubsPosted"></app-list-clubs-celeb-post>
  </ng-container>

  <ng-container *ngIf="showListClubsCelebGoinlive">
    <app-list-clubs-celeb-post [clubs]="tempGoInlive.clubs!" [live]="tempGoInlive" [fromGoInliveCeleb]="true"
      [(showListClubsPosted)]="showListClubsCelebGoinlive"></app-list-clubs-celeb-post>
  </ng-container>

</div>