<div class="flex flex-column cursor-pointer card-shadow" pRipple (click)="openAlbum()" *ngIf="album">
    <div class="card">
        <div class="zone-avt">
            <img [src]="getUrl(album.coverImage!) | fetchUrlImage" alt="" class="fit-size">
            <div class="name-club mt-2">
                <span>
                    {{album!.name! || 'name'}}
                </span>
            </div>
            <div *ngIf="album!.stage!.name! && !inPost" class="link-stage flex align-items-center">
                <img src="../../../assets/images/svgs/link-stage.svg" style="object-fit: fill;" class="mr-1">
                {{album!.stage!.name!}}
            </div>
        </div>
        <img class="inliverplus" *ngIf="album!.isPrivate!" src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
    </div>
</div>

<ng-container *ngIf="isShowPopup && !isMobile">
    <app-applyrole-accesslevel [clubId]="album!.clubId!" [(isShowPopup)]="isShowPopup" [title]="'ROLE_REQUIRED_INLIVERPLUS' | translate"></app-applyrole-accesslevel>
</ng-container>

<ng-container *ngIf="isShowPopup && isMobile">
    <app-mobile-applyrole-accesslevel [clubId]="album!.clubId!" [(isShowPopup)]="isShowPopup" [title]="'ROLE_REQUIRED_INLIVERPLUS' | translate"></app-mobile-applyrole-accesslevel>
</ng-container>

<ng-container *ngIf="isConfirmJoinClub">
    <app-confirm-request-join-club [(isConfirmJoinClub)]="isConfirmJoinClub"></app-confirm-request-join-club>
</ng-container>