<div class="text-my-discussion mb-4" *ngIf="posts!.length > 0">
    {{'MY_DISCUSSIONS' | translate}}
    <img src="../../../../assets/images/svgs/3-dot.svg" alt="" srcset="">
</div>


<section>

    <div class="flex justify-content-between zone-arrow" *ngIf="isShowScroll">
        <div class="flex">
            <i *ngIf="isShowLeft" class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo()"></i>
        </div>
        <div class="flex">
            <i *ngIf="isShowRight" class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(false)"></i>
        </div>
    </div>

    <div class="flex list-discussion" #listBalloon>
        <div class="post" *ngFor="let item of posts" (click)="openCommentPostDiscussion(item)">
            <div class="avatar" [ngClass]="checkActive(item.id!) ? 'active' : ''">
                <img [src]="item.club?.avatar" class="fit-size" alt="">
            </div>
            <div class="title flex flex-column ml-3">
                <span class="name-club">
                    {{item.club?.name!}}
                </span>
                <span class="text-title">
                    {{item.title}}
                </span>
            </div>
        </div>
    </div>
</section>