/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { CreateHashtagInputModel } from '../models/create-hashtag-input-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { HashtagModelResponseForDiscoverPaginationModelResponseModel } from '../models/hashtag-model-response-for-discover-pagination-model-response-model';
import { HashtagResponseModelListResponseModel } from '../models/hashtag-response-model-list-response-model';
import { HashtagResponseModelPaginationModelResponseModel } from '../models/hashtag-response-model-pagination-model-response-model';
import { HashtagResponseModelResponseModel } from '../models/hashtag-response-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class HashtagService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithHashtagsPageGet
   */
  static readonly InlivewithHashtagsPageGetPath = '/inlivewith/Hashtags/Page';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsDeletedGet
   */
  static readonly InlivewithHashtagsDeletedGetPath = '/inlivewith/Hashtags/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsActivatedGet
   */
  static readonly InlivewithHashtagsActivatedGetPath = '/inlivewith/Hashtags/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsLikeNameNameGet
   */
  static readonly InlivewithHashtagsLikeNameNameGetPath = '/inlivewith/Hashtags/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagByNameNameGet
   */
  static readonly InlivewithHashtagByNameNameGetPath = '/inlivewith/Hashtag/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Plain(params: {
    name: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Json(params: {
    name: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagByIdHashtagIdGet
   */
  static readonly InlivewithHashtagByIdHashtagIdGetPath = '/inlivewith/Hashtag/ById/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain(params: {
    hashtagId: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json(params: {
    hashtagId: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagGetSuggestionHashtagGet
   */
  static readonly InlivewithHashtagGetSuggestionHashtagGetPath = '/inlivewith/Hashtag/GetSuggestionHashtag';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetSuggestionHashtagGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetSuggestionHashtagGet$Plain$Response(params?: {
    quantity?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetSuggestionHashtagGetPath, 'get');
    if (params) {
      rb.query('quantity', params.quantity, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetSuggestionHashtagGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetSuggestionHashtagGet$Plain(params?: {
    quantity?: number;
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithHashtagGetSuggestionHashtagGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetSuggestionHashtagGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetSuggestionHashtagGet$Json$Response(params?: {
    quantity?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetSuggestionHashtagGetPath, 'get');
    if (params) {
      rb.query('quantity', params.quantity, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetSuggestionHashtagGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetSuggestionHashtagGet$Json(params?: {
    quantity?: number;
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithHashtagGetSuggestionHashtagGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagClubsHashtagIdGet
   */
  static readonly InlivewithHashtagClubsHashtagIdGetPath = '/inlivewith/Hashtag/Clubs/{hashtagId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagClubsHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Plain$Response(params: {
    accountRequestId?: string;
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagClubsHashtagIdGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagClubsHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Plain(params: {
    accountRequestId?: string;
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagClubsHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagClubsHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Json$Response(params: {
    accountRequestId?: string;
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagClubsHashtagIdGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagClubsHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Json(params: {
    accountRequestId?: string;
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagClubsHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost
   */
  static readonly InlivewithHashtagGetListSuggestionClubBasedOnHashtagsPostPath = '/inlivewith/Hashtag/GetListSuggestionClubBasedOnHashtags';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetListSuggestionClubBasedOnHashtagsPostPath, 'post');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetListSuggestionClubBasedOnHashtagsPostPath, 'post');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetListSuggestionClubBasedOnHashtagsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagMembersHashtagIdGet
   */
  static readonly InlivewithHashtagMembersHashtagIdGetPath = '/inlivewith/Hashtag/Members/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagMembersHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagMembersHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagMembersHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Plain(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithHashtagMembersHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagMembersHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Json$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagMembersHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagMembersHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Json(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithHashtagMembersHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagCreatePost
   */
  static readonly InlivewithHashtagCreatePostPath = '/inlivewith/Hashtag/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Plain$Response(params?: {
    body?: Array<CreateHashtagInputModel>
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Plain(params?: {
    body?: Array<CreateHashtagInputModel>
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithHashtagCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Json$Response(params?: {
    body?: Array<CreateHashtagInputModel>
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Json(params?: {
    body?: Array<CreateHashtagInputModel>
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithHashtagCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagGetListClubByHashtagIdHashtagIdGet
   */
  static readonly InlivewithHashtagGetListClubByHashtagIdHashtagIdGetPath = '/inlivewith/Hashtag/GetListClubByHashtagId/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetListClubByHashtagIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Plain(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Json$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetListClubByHashtagIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Json(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetListClubByHashtagIdHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsGetTrendHashtagGet
   */
  static readonly InlivewithHashtagsGetTrendHashtagGetPath = '/inlivewith/Hashtags/GetTrendHashtag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsGetTrendHashtagGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsGetTrendHashtagGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsGetTrendHashtagGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsGetTrendHashtagGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsGetTrendHashtagGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagModelResponseForDiscoverPaginationModelResponseModel> {

    return this.inlivewithHashtagsGetTrendHashtagGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>) => r.body as HashtagModelResponseForDiscoverPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsGetTrendHashtagGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsGetTrendHashtagGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsGetTrendHashtagGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsGetTrendHashtagGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsGetTrendHashtagGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagModelResponseForDiscoverPaginationModelResponseModel> {

    return this.inlivewithHashtagsGetTrendHashtagGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagModelResponseForDiscoverPaginationModelResponseModel>) => r.body as HashtagModelResponseForDiscoverPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagIsDeletedHashtagIdGet
   */
  static readonly InlivewithHashtagIsDeletedHashtagIdGetPath = '/inlivewith/Hashtag/IsDeleted/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagIsDeletedHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagIsDeletedHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Plain(params: {
    hashtagId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagIsDeletedHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Json$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagIsDeletedHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagIsDeletedHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Json(params: {
    hashtagId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagIsDeletedHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagDeleteHashtagIdDelete
   */
  static readonly InlivewithHashtagDeleteHashtagIdDeletePath = '/inlivewith/Hashtag/Delete/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagDeleteHashtagIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Plain$Response(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagDeleteHashtagIdDeletePath, 'delete');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagDeleteHashtagIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Plain(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagDeleteHashtagIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagDeleteHashtagIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Json$Response(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagDeleteHashtagIdDeletePath, 'delete');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagDeleteHashtagIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Json(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagDeleteHashtagIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagRemoveDuplicateHashTagDelete
   */
  static readonly InlivewithHashtagRemoveDuplicateHashTagDeletePath = '/inlivewith/Hashtag/RemoveDuplicateHashTag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagRemoveDuplicateHashTagDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagRemoveDuplicateHashTagDelete$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagRemoveDuplicateHashTagDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagRemoveDuplicateHashTagDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagRemoveDuplicateHashTagDelete$Plain(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagRemoveDuplicateHashTagDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagRemoveDuplicateHashTagDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagRemoveDuplicateHashTagDelete$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagRemoveDuplicateHashTagDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagRemoveDuplicateHashTagDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagRemoveDuplicateHashTagDelete$Json(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagRemoveDuplicateHashTagDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
