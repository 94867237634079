<div class="flex justify-content-center flex-column text-center text-white">
    <span class="mt-2 fs-18 mb-2 text-center">
        {{title}} @{{nameObject}}
    </span>
    <div class="mt-2 mb-2">
        <span>{{'REPORT_WAITING' | translate}}</span>
    </div>

    <div class="flex align-items-center justify-content-center">
        <button pButton type="submit" label="OK" (click)="cancel.emit()"
            class="mt-5 ml-2 p-button-outlined p-button-secondary label"></button>
    </div>
</div>