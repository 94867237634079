<div class="post-zone">
    <div class="flex align-items-center">
        <div class="avatar-zone">
            <span class="skeleton-box"></span>
        </div>
        <div class="info-zone ml-2">
            <div class="club-name"><span class="skeleton-box"></span></div>
            <div class="author-name mt-1"><span class="skeleton-box"></span></div>
            <div class="time mt-2"><span class="skeleton-box"></span></div>
        </div>
    </div>
    <div class="mb-5 mt-5 flex">
        <div class="stage">
            <span class="skeleton-box"></span>
        </div>
    </div>
    <div class="mb-5 mt-5">
        <div class="mb-2" style="height: 150px; width: 95%;">
            <span class="skeleton-box"></span>
        </div>
    </div>
</div>