<p-dialog [(visible)]="isOpenEditEventPopup" (onHide)="onHide()" (onShow)="onShow()" [draggable]="false"
    [resizable]="false" [closable]="true">
    <div class="card">
        <div class="flex justify-content-between">
            <div class="mt-1 w-70 p-input-icon-right">
                <i>{{getCharacterLeft().numberLeft}}</i>
                <input [(ngModel)]="tempName" class="w-100" style="width: 100%;" type="text" pInputText
                    [value]="event!.name!" [placeholder]="event!.name!" />
            </div>
            <div class="flex align-items-center text-error cursor-pointer" (click)=" isShowPopupDeleteEvent = true">
                <span class="mr-2">{{'EVENT_DELETE' | translate}}</span>
                <i class="pi pi pi-trash"></i>
            </div>
        </div>
        <div *ngIf="(tempName && tempName.trim().length <= 0)" class="text-error">
            {{'EVENT_NAME_REQUIRED' | translate}}
        </div>
        <div class="w-100 mt-4 poster position-relative">
            <img class="fit-size" style="border-radius: 15px;" [src]="imgBlob ? imgBlob : event!.poster!">
            <div class="position-absolute image-edit" style="right: 20px; top: 20px;">
                <img src="../../../assets/images/svgs/edit-pencil.svg" (click)="inputFile.click()"
                    class="cursor-pointer">
            </div>
            <input style="display: none" id="file-avatar" type="file" #inputFile (change)="selectPoster($event)"
                accept="image/png, image/jpeg">
        </div>
        <div class="flex flex-row justify-content-between mt-4">
            <div class="flex flex-row justify-content-start">
                <div class="flex flex-column">
                    <span class="text-white fs-14 mb-3 ml-2">{{'EVENT_DATE_DEBUT' | translate}}</span>
                    <p-calendar placeholder="Choisis une date" (onSelect)="getMinimumDateEnd()" [minDate]="minimumDate"
                        dateFormat="dd/mm/yy" class="m-1" [(ngModel)]="dateCreated"></p-calendar>
                </div>
                <div class="flex flex-column">
                    <span class="text-white fs-14 mb-3 ml-2">{{'EVENT_HOUR_DEBUT' | translate}}</span>
                    <input class="m-1 type-time-input" [placeholder]="hourStart" [(ngModel)]="hourStart" type="time">
                </div>
            </div>
            <div class="flex flex-row justify-content-end" *ngIf="event.type! != 'GOINLIVE_EVENT'">
                <div class="flex flex-column">
                    <span class="text-white fs-14 mb-3 ml-2">{{'EVENT_DATE_FINISH' | translate}}</span>
                    <p-calendar placeholder="Choisis une date" class="m-1" dateFormat="dd/mm/yy"
                        [minDate]="minimumDateEnd" [(ngModel)]="dateEnded"></p-calendar>
                </div>
                <div class="flex flex-column">
                    <span class="text-white fs-14 mb-3 ml-2">{{'EVENT_HOUR_FINISH' | translate}}</span>
                    <input class="m-1 type-time-input" [(ngModel)]="hourEnd" [placeholder]="hourEnd" type="time">
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-content-between mt-2">
            <div></div>
            <div *ngIf="compareTimeEdit && event.type! != 'GOINLIVE_EVENT'" class="text-error mt-2">
                {{'EVENT_CHECKDATE_ERROR' | translate}}
            </div>
        </div>
        <div class="mt-5 ml-2">
            <span class="text-white">{{'LIEU' | translate}}</span>
            <div class="mt-3 flex align-items-center">
                <p-radioButton class="mr-3" name="groupname" label="{{'ADDRESS' | translate}}" [value]="false"
                    [(ngModel)]="selectedValue"></p-radioButton>
                <p-radioButton class="ml-3" name="groupname" label="{{'PERSONALISE' | translate}}" [value]="true"
                    [(ngModel)]="selectedValue"></p-radioButton>
            </div>
            <div id="pac-container" class="mt-5">
                <input #search class="w-40 fs-14" type="text" [(ngModel)]="address" [value]="event!.place!"
                    [placeholder]="event!.place!" pInputText placeholder="Dans nos canapés" />
                <div class="map-container" *ngIf="!selectedValue">
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (mapClick)="addMarker($event)">
                        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map>
                </div>
            </div>
            <div *ngIf="address?.trim()?.length! == 0" class="text-error">
                {{'EVENT_ADDRESS_REQUIRED' | translate}}
            </div>
        </div>
        <div class="mt-5 ml-2">
            <span class="text-white fs-20">{{'EVENT_DESCRIPTION' | translate}}</span>
            <div class="mt-5">
                <textarea class="textarea" style="resize: none;" [(ngModel)]="description" [value]="event!.description!"
                    [placeholder]="event!.description!" [rows]="9"
                    placeholder="Décris ton événement en quelques mots"></textarea>
            </div>
            <div *ngIf="description && description?.trim()?.length! == 0" class="text-error">
                {{'EVENT_DESCRIPTION_REQUIRED' | translate}}
            </div>
        </div>

        <div class="flex row mt-5 align-items-baseline justify-content-between" *ngIf="event.type != 'GOINLIVE_EVENT'">
            <div class="col-5 justify-content-center flex flex-column">
                <div class=" mb-2" [ngClass]="isPublic ? 'text-disabled' : ''">
                    <p-radioButton class="mr-3" name="groupname" [value]="true" [(ngModel)]="isPublic"></p-radioButton>
                    <img class="mr-3"
                        [src]="isPublic ? '../../../assets/images/svgs/unlock.svg' : '../../../assets/images/svgs/unlock-disabled.svg'">
                    {{'EVENT_STATUS_PUBLIC' | translate}}
                </div>
                <div class="fs-14" [ngClass]="isPublic ? 'text-disabled' : ''">
                    {{'EVENT_STATUS_PUBLIC_TITLE' | translate}}
                </div>
            </div>
            <div class="col-5 justify-content-center flex flex-column">
                <div class=" mb-2" [ngClass]="!isPublic ? 'text-disabled' : ''">
                    <p-radioButton class="mr-3" name="groupname" [value]="false" [(ngModel)]="isPublic"></p-radioButton>
                    <img class="mr-3"
                        [src]="!isPublic ? '../../../assets/images/svgs/lock.svg': '../../../assets/images/svgs/lock-disabled.svg'">
                    {{'EVENT_STATUS_PRIVATE' | translate}}
                </div>
                <div class="fs-14" [ngClass]="!isPublic ? 'text-disabled' : ''">
                    {{'EVENT_STATUS_PRIVATE_TITLE' | translate}}
                </div>
                <div class="fs-14 mt-4 add-inliver cursor-pointer" (click)="openPopupAddInlivers = true">
                    <span class="mt-3">{{'CLUB_ALBUM_PICK_LIST_MEMBERS' | translate}}</span>
                    <div class="avatars" *ngIf="selectedInlivers?.length! > 0">
                        <div class="avatars__item" *ngFor="let inliver of selectedInlivers">
                            <div class="avatars__image zone-avatar">
                                <!-- Image -->
                                <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                            </div>
                        </div>
                    </div>
                    <div class="avatars"
                        *ngIf="selectedInlivers?.length! == 0 && (!isPublic && event?.accountJoinEvents?.length! > 0)">
                        <div class="avatars__item" *ngFor="let inliver of getListJoined()">
                            <div class="avatars__image zone-avatar">
                                <!-- Image -->
                                <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fs-14 mt-4 add-inliver cursor-pointer" (click)="openPopupAddInlivers = true"
            *ngIf="event.type == 'GOINLIVE_EVENT'">
            <span class="mt-3">{{'CLUB_ALBUM_PICK_LIST_MEMBERS' | translate}}</span>
            <div class="avatars" *ngIf="selectedInlivers?.length! > 0">
                <div class="avatars__item" *ngFor="let inliver of selectedInlivers">
                    <div class="avatars__image zone-avatar">
                        <!-- Image -->
                        <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                    </div>
                </div>
            </div>
            <div class="avatars"
                *ngIf="selectedInlivers?.length! == 0 && (!isPublic && event?.accountJoinEvents?.length! > 0)">
                <div class="avatars__item" *ngFor="let inliver of getListJoined()">
                    <div class="avatars__image zone-avatar">
                        <!-- Image -->
                        <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-content-center mt-3 mb-3">
            <button pButton type="submit" label="{{'REGISTER' | translate}}" (click)="suivant()"
                class="mt-5 mb-3 p-button-outlined p-button-secondary label"></button>
        </div>

    </div>
</p-dialog>


<ng-container *ngIf="isShowPopupDeleteEvent">
    <app-card-confirm [(isShowPopup)]="isShowPopupDeleteEvent" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'YES' | translate" (doAction)="deleteEvent()" [title]="'EVENT_DELETE_CONFIRM' | translate"
        [content]="event.name!">
    </app-card-confirm>
</ng-container>

<ng-container *ngIf="openPopupAddInlivers">
    <app-list-inliver [(openPopup)]="openPopupAddInlivers" [listInliverPlus]="listInliverPlus"
        [(selectedInlivers)]="selectedInlivers" [title]="'MEMBERS' | translate"></app-list-inliver>
</ng-container>