import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AccountClubAccessLevelRequestInputModel } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-applyrole-accesslevel',
  templateUrl: './applyrole-accesslevel.component.html',
  styleUrls: ['./applyrole-accesslevel.component.scss']
})
export class ApplyroleAccesslevelComponent implements OnInit {

  @Input() title!: string;
  @Input() isShowPopup!: boolean;
  @Input() clubId!: string;
  @Output() isShowPopupChange = new EventEmitter<boolean>();

  constructor(
    private _accountService: AccountService
  ) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
  }


  requestAccessLevel() {
    let body: AccountClubAccessLevelRequestInputModel = {
      accountId: AuthService.GetAccountInfo()?.id!,
      clubId: this.clubId,
      accessLevel: "PLUS"
    }
    this._accountService.inlivewithAccountClubRequestAccessLevelPost$Json({
      body: body
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          window.location.reload();
        }
      })
    ).subscribe();
  }

}
