<div class="card" *ngIf="club">
    <div class="flex flex-column align-items-center justify-content-center text-center">
        <div class="flex position-fixed">
            <div class="zone-avt" *ngIf="club && club.avatar">
                <img [src]="club.avatar! | fetchUrlAvatarClub" class="fit-size">
            </div>
        </div>


        <div class="text-white flex flex-column text-center mb-3">
            <div class="label mt-3" *ngIf="club && club.name">
                {{club.name}}
            </div>
            <span class="mt-2 text">
                {{club.totalMember!}} {{ (club!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') | translate}}
            </span>
            <div class="flex align-items-baseline justify-content-center">
                <span class="mt-2 mr-2 text">
                    {{'CLUB' | translate }} {{club!.type! || 'type' | translate}}
                </span>
                <i class="half-icon-heart" *ngIf="club!.type! == 'CLUB_TYPE_FAN'"></i>
                <i class="half-icon-star" *ngIf="club!.type! == 'CLUB_TYPE_TALENT'"></i>
            </div>
        </div>

        <div class="text-white">
            <span>
                {{club.description! | limitString:false:100}}
            </span>
        </div>

    </div>
    <div class="flex align-items-center justify-content-center mt-5">
        <button pButton [label]="'CANCEL' | translate"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2 mr-4"
            (click)="closePopup()">
        </button>
        <button pButton [label]="'VIEW_CLUB' | translate"
            class="p-button-red p-button-secondary text-black bg-white border-white ml-2" (click)="viewClub()">
        </button>
    </div>
</div>