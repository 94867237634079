import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss']
})
export class UploadAvatarComponent implements OnInit {

  @Input() imageChangedEvent: any = '';
  @Output() upload = new EventEmitter<Blob>();

  @Input() showDialog = false;
  @Output() showDialogChange = new EventEmitter<boolean>();

  croppedImage?: Blob;

  constructor() {
    LoadingService.$(true);
  }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    if (event.blob) this.croppedImage = event.blob;
  }

  loadImageFaile() {
    this.showDialog = false;
    this.showDialogChange.emit(this.showDialog);
    this.imageLoaded();
  }

  imageLoaded() {
    LoadingService.$(false);
  }
}
