import { AuthService } from 'src/app/core/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-card-apply-club',
  templateUrl: './card-apply-club.component.html',
  styleUrls: ['./card-apply-club.component.scss'],
})
export class CardApplyClubComponent implements OnInit {
  @Input() invitation!: NotificationResponse;
  defaultAvatar = defaultAvatar;
  constructor(
    private _accountService: AccountService,
    private _clubService: ClubService
  ) { }

  ngOnInit(): void { }

  doAction(isAccept: boolean) {
    this._clubService.inlivewithClubAccountApprovalPut$Json({
      body: {
        accountId: this.invitation.senderId!,
        clubId: this.invitation.clubId!,
        approval: isAccept,
      }
    }).subscribe(() => location.reload());
  }
}
