<div class="flex justify-content-center flex-column text-white text-center">
    <span class="mt-2 fs-18 mb-2">
        {{'REPORT' | translate}}
    </span>
    <span class="mt-2 fs-16 mb-2" [innerHTML]="'REPORT_SUBTITLE' | translate"></span>
    <div class="flex align-items-center justify-content-center">
        <button pButton type="submit" label="{{'CANCEL' | translate}}" (click)="cancel.emit()"
            class="mt-5 ml-2 p-button-outlined p-button-secondary label"></button>
        <button pButton type="submit" label="{{'CONTINUE' | translate}}" (click)="next.emit()"
            class="mt-5 mr-2 p-button-outlined p-button-secondary label"></button>
    </div>
</div>