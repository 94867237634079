<div class="card p-3" id="scrollId1" *ngIf="myClubs && !isReport">
    <div class="title align-items-center">
        {{ 'MANAGED_CLUBS' | translate}} ({{totalItems!}})
        <div *ngIf="totalItems! > 0" class="cursor-pointer create-club" (click)="createClub()">
            <i class="pi pi-plus" style="font-size: 20px;"></i>
        </div>
    </div>


    <div class="flex flex-wrap" style="overflow: hidden;" [ngClass]="isShowMoreClubs ? 'collapsed': 'collapse'">
        <div *ngIf="totalItems! == 0" pRipple class="btn-create mt-4 cursor-pointer mt-4" (click)="createClub()">
            <i class="pi pi-plus mb-2" style="font-size: 24px;"></i>
            {{ 'CREATE' | translate}}
        </div>

        <div class="flex mt-4" style="margin-right: 0.5rem; margin-left: 0.5rem;" *ngFor="let club of myClubs">
            <app-card-club [club]="club"></app-card-club>
        </div>
    </div>

    <div class="flex align-items-center justify-content-center mt-3 cursor-pointer" *ngIf="totalItems! > 6"
        (click)="clickScroll()">
        <span class="fs-14" style="font-weight: bold;" *ngIf="!isShowMoreClubs">{{ 'SEE_MORE' | translate}}</span>
        <span class="fs-14" style="font-weight: bold;" *ngIf="isShowMoreClubs">{{ 'SHOW_LESS' | translate}}</span>
    </div>

</div>

<div class="p-3 mt-3" *ngIf="isReport" [ngClass]="!openReportByAccount ? 'card' : ''">
    <div class="title align-items-center cursor-pointer" (click)="handleClick()">
        {{ 'CLUB_MANAGED' | translate}}
    </div>
    <div>
        <div class="mt-3" *ngFor="let item of myClubs">
            <div [ngClass]="this.currentClubReports == item.id ? 'text-white' : 'text-disabled'">
                <app-club-report [club]="item" (changeOpenReportAccount)="setOpenReportByAccount()"></app-club-report>
            </div>
        </div>
        <div class="flex align-items-center justify-content-center mt-3 cursor-pointer" *ngIf="totalItems! > 6"
            (click)="clickScroll()">
            <span class="fs-14" style="font-weight: bold;" *ngIf="!isShowMoreClubs">{{ 'SEE_MORE' | translate}}</span>
            <span class="fs-14" style="font-weight: bold;" *ngIf="isShowMoreClubs">{{ 'SHOW_LESS' | translate}}</span>
        </div>
    </div>
</div>