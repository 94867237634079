<div class="zone-recorder fs-16 w-100 p-1">

    <div class="flex align-items-center">
        <i class="btn-stop mr-3 ml-2 cursor-pointer" *ngIf="openRecording && recording" (click)="onClickRecord()"></i>

        <div class="boxContainer" *ngIf="openRecording && recording">
            <div class="box box1"></div>
            <div class="box box2"></div>
            <div class="box box3"></div>
            <div class="box box4"></div>
            <div class="box box5"></div>
            <div class="box box6"></div>
            <div class="box box7"></div>
        </div>
    </div>

    <div class="ml-2 mr-2" *ngIf="recording">
        <span>{{ minutesLabel }}:</span>
        <span>{{ secondsLabel }}</span>
    </div>

    <div class="flex align-items-center justify-content-between w-100" style="z-index: 1000;" *ngIf="!recording && url">

        <div class="cursor-pointer m-1 flex align-items-center justify-content-center" (click)="onPlayButtonClick()">
            <div class="zone-btn" *ngIf="!isPlayingAudio">
                <i class="btn-play"></i>
            </div>
            <div class="zone-btn" *ngIf="isPlayingAudio">
                <i class="btn-pause"></i>
            </div>
        </div>

        <div *ngIf="url && !recording" class="flex align-items-center justify-content-center zone-audio w-100">
            <div class="ware-form">
                <ng-waveform #waveform class="waveform" [src]="url" backgroundColor="#00000"
                    regionBackgroundColor="rgba(255, 255, 255, 0.7)" regionStartStickColor="#21f032"
                    regionEndStickColor="red" regionTextColor="#09417e" [withRegionLabels]="true" waveColor="#50c9ff"
                    (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)"
                    (durationChange)="onDurationChange($event)" (timeUpdate)="onTimeUpdate($event)"
                    (paused)="onPaused()" (regionChange)="onRegionChange($event)">
                </ng-waveform>
            </div>

            <div style="width: 4rem; text-align: center">
                {{timeOffAudio*1000 | date:'mm:ss'}}
            </div>
        </div>


        <div class="flex align-items-center mr-1">
            <i class="pi m-1 pi-times button-close" (click)="close()"></i>
            <i class="button-send" *ngIf="checkSubmit && isShowSend" (click)="sendComment()"></i>
        </div>
    </div>

</div>