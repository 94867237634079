import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  Album,
  Club,
  ClubResponseWithTotalMemberAndGoInliveModel,
  EventWithCheckAccountJoinEventResponseModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  ClubService,
  EventService,
} from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-side-right',
  templateUrl: './side-right.component.html',
  styleUrls: ['./side-right.component.scss'],
})
export class SideRightComponent implements OnInit {
  myClubs: Club[] = [];
  albums: Album[] = [];
  eventsInClub: EventWithCheckAccountJoinEventResponseModel[] = [];
  eventsIsNotInClub: EventWithCheckAccountJoinEventResponseModel[] = [];
  eventsByAuthor: EventWithCheckAccountJoinEventResponseModel[] = [];
  events: EventWithCheckAccountJoinEventResponseModel[] = [];

  clubId!: string;
  club!: ClubResponseWithTotalMemberAndGoInliveModel;

  isJoined!: boolean;
  currentClub!: boolean;
  openEditPolicyClub: boolean = false;
  isOpenConfirmRuleClub: boolean = false;

  accountInClub!: AccountResponseWithRoleInCLubModel;

  isAdmin!: boolean;
  isLogined = AuthService.GetAccountInfo() !== null;

  refetchWhenReceiveNoti$ = this._notificationService.isReloadEvent$$.pipe(
    mergeMap((eventId: string) =>
      this._eventService
        .inlivewithEventByIdAccountRequestIdEventIdGet$Json({
          accountRequestId: AuthService.GetAccountInfo().id!,
          eventId: eventId,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              const index = this.eventsByAuthor.findIndex(
                (x) => x.id == eventId
              );
              if (index >= 0) {
                this.eventsByAuthor[index] = res.data!;
                window.location.reload();
              }
            }
          })
        )
    )
  );

  constructor(
    private _clubService: ClubService,
    private _accountService: AccountService,
    private _activeRoute: ActivatedRoute,
    public _router: Router,
    private _eventService: EventService,
    private _notificationService: NotificationServicee
  ) {
    this._activeRoute.queryParams.subscribe((res) => {
      this.clubId = res?.clubId!;
      this.albums = [];
      // if (res?.clubId! && !this._router.url.includes('/detail-club')) {
      //   this.getStatusClub(this.clubId);
      //   this.getRoleAccount(this.clubId);
      //   this.getListEventByClub(this.clubId);
      //   this.getDetailClub(this.clubId);
      // }
    });

    this.refetchWhenReceiveNoti$.subscribe();
  }

  ngOnInit(): void {
    if (AuthService.GetAccountInfo()?.id! && !this._router.url.includes('/detail-club')) {
      this.getListEventPage();
      this.getListEventByAuthor();
      this.getListEventNotInClub();
    }
  }

  getStatusClub(clubId: any) {
    if (clubId) {
      this._clubService
        .inlivewithClubIsPrivateClubIdGet$Json({ clubId: clubId })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.currentClub = res?.data!;
            }
          })
        )
        .subscribe();
    }
  }

  getDetailClub(clubId: any) {
    if (clubId) {
      this._clubService
        .inlivewithClubByIdClubIdGet$Json({ clubId: clubId })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.club = res?.data!;
            }
          })
        )
        .subscribe();
    }
  }

  getRoleAccount(clubId: any) {
    return this._accountService
      .inlivewithAccountClubDetailAccountIdClubIdGet$Json({
        clubId: clubId,
        accountId: AuthService.GetAccountInfo()?.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.accountInClub = res?.data!;
            this.isJoined = true;
            this.isAdmin = this.accountInClub?.roleInClub === 'ROLE_ADMIN';
          }
          if (res.res == -2) {
            this.isJoined = false;
            this.isAdmin = false;
          }
        }),
        catchError((err) => {
          this.isJoined = false;
          this.isAdmin = false;
          return of(err);
        })
      )
      .subscribe();
  }

  createEvent() {
    this._router.navigate(['manage-club/create-event', this.clubId], {
      queryParams: { clubId: this.clubId },
    });
  }

  getListEventByClub(clubId: string) {
    this._eventService
      .inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json({
        ClubId: clubId,
        accountRequestId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.eventsInClub = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  getListEventByAuthor() {
    this._eventService
      .inlivewithEventGetByAuthorAccountRequestIdGet$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.eventsByAuthor = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  getListEventPage() {
    this._eventService
      .inlivewithEventGetEventForHomePageWithoutAuthorAccountRequestIdGet$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.events = res.data?.page!;
            // .filter((object1) => {
            //   return this.eventsInClub.some((object2) => {
            //     return object1.id !== object2.id;
            //   });
            // })!;
          }
        })
      )
      .subscribe();
  }

  getListEventNotInClub() {
    this._eventService
      .inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.eventsIsNotInClub = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  seeAll() {
    if (this.isJoined) {
      this._router.navigate(['manage-club/list-event', this.clubId], {
        queryParams: { clubId: this.clubId },
      });
    }
  }

  editClubRule() {
    this.openEditPolicyClub = true;
  }
}
