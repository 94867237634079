<div class="il-video" #video>
  <div class="il-video__container">
    <video [id]="videoId" (click)="playPause(videoId)" (dblclick)="playPause(videoId); volume()" class="il-video__embed"
      (ended)="videoEnd(videoPlayer)" (loadedmetadata)="processMetaData(videoPlayer)" #videoPlayer
      (timeupdate)="updateDisplayTime(videoPlayer)" [hidden]="!url" controls="" playsinline [poster]="poster">
      <source [src]="url" type='video/mp4;codecs="avc1.4D401E, mp4a.40.2"' />
    </video>

    <div [ngSwitch]="layout">
      <div *ngSwitchCase="'full'" class="full-control">
        <div class="il-controls flex" style="background-color: #5050508c;">
          <div class="il-left-controls">

            <i style="width: 18px;height: 18px; display: flex;" (click)="playPause(videoId)"
              [ngClass]="isPlay ? 'flex btn-pause':'flex btn-play'"></i>

            <div class="flex text-white fs-12">
              <p>{{ currTime }}</p>
            </div>

            <img class="flex" (click)="volume()" [src]="isMuted ? volumePath : mutePath" alt="video-icon" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>