import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LastUrlService } from 'src/app/core/services/last-url.service';
import { MobileService } from 'src/app/core/services/is-mobile.service';

@Component({
  selector: 'app-page-restricted',
  templateUrl: './page-restricted.component.html',
  styleUrls: ['./page-restricted.component.scss']
})
export class PageRestrictedComponent implements OnInit {

  constructor(
    private _router: Router,
    private _location: Location,
    private _lastUrlService: LastUrlService,
    private _mobileService: MobileService,
  ) { }

  isMobile: boolean = this._mobileService.isMobile()

  ngOnInit(): void { }

  backBtn() {
    if (this._lastUrlService.getLastUrl()) {
      return this._location.back()
    }

    return this._router.navigate(this.isMobile ? ['/mobile', '/home'] : ['home'])
  }

}
