import { UntypedFormGroup } from '@angular/forms';

export const GROUP_ILW_DISCUSSION = 'ilw-post-discussion';
export const FILE_SIZE_LIMIT_POST = 300; // Mb
export const FILE_DURATION_LIMIT = 4 * 60; // Second
export const LIMIT_TIME_GOINLIVE = 6 * 60; // Second

export const NAV_MENU = {
  HOME: 'HOME',
  EXPLORER: 'EXPLORER',
  CHAT: 'CHAT',
  NOTIFICATION: 'NOTIFICATION',
};

export const enum CRYPTO {
  TOKEN_KEY = 'DVROrKiHmncUgWBj',
  TOKEN_IV = 'xcsJYPvUkxjdLi8b',
}

export enum ROLE_NAME {
  ROOTS = 'roots',
  ADMINISTRATORS = 'administrators',
  MANAGERS = 'managers',
  CLIENTS = 'clients',
  ANONYMOUS = 'anonymous',
  AMBASSADORS = 'ambassadors',
  BRANDS = 'brands',
}

export const enum STORAGE_KEY {
  TOKEN = 'access_token',
  EMAIL = 'email',
  ACCOUNT = 'account',
}

export interface SignalRResponse {
  timestamp: string;
  from: string;
  data: any;
}

export class ILWAppEnum {
  public static ENUM_ILW: any = {};
}

export enum PostEnum {
  PUBLICATION = 'PUBLICATION',
  DISCUSSION = 'DISCUSSION',
  ALL = 'ALL',
}

export enum TYPE_REPORT_STATUS {
  STATUS_ACCEPTED = 'STATUS_ACCEPTED',
  STATUS_IGNORE = 'STATUS_IGNORE',
  STATUS_NEW = 'STATUS_NEW',
  STATUS_WARN = 'STATUS_WARN',
}

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// /^[a-zA-Z0-9.-]+@(?=[a-zA-Z0-9])[a-zA-Z0-9]{1,}\.(?=[a-zA-Z0-9])[a-zA-Z0-9]{2,}$/;
export const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(.*).{6,}$/;
export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const typeImageMedia = [
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfif',
  'jfi',
  'jp2',
  'j2k',
  'jpf',
  'jpx',
  'jpm',
  'mj2',
  'svg',
  'svgz',
  'png',
  'webp',
  'gif',
  'tiff',
  'tif',
  'gif',
  'bmp',
  'dib',
  'flv',
  'vob',
  'ogv',
  'ogg',
  'drc',
  'gifv',
  'mng',
  'avi',
  'MTS',
  'M2TS',
  'TS',
  'mov',
  'qt',
  'wmv',
  'yuv',
  'rm',
  'rmvb',
  'viv',
  'asf',
  'amv',
  'mp4',
  'mp4p',
  'mp4v',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'mpg',
  'mpeg',
  'm2v',
  'm4v',
  'svi',
  '3gp',
  '3g2',
  'mxf',
  'roq',
  'nsv',
  'flv',
  'f4v',
  'f4p',
  'f4b',
];
export const typeAudio = [
  'aac',
  'adp',
  'aif',
  'aifc',
  'aiff',
  'au',
  'flac',
  'kar',
  'm2a',
  'm3a',
  'm4a',
  'mid',
  'midi',
  'mka',
  'mp2a',
  'mp3',
  'mp4a',
  'mpg2',
  'mpga',
  'oga',
  'ra',
  'rma',
  'rmi',
  'rmp',
  's3m',
  'sil',
  'snd',
  'spx',
  'uva',
  'wav',
  'wax',
  'weba',
  'webm',
  'wma',
  'xm',
];
export function CheckErrorForm(
  formGroup: UntypedFormGroup,
  name: string,
  submitted: boolean
) {
  return (
    (formGroup.controls[name].errors && formGroup.controls[name].touched) ||
    (formGroup.controls[name].errors && submitted)
  );
}

export function ConfirmPasswordValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function base64ToBlob(
  b64Data: string,
  contentType = '',
  sliceSize = 512
) {
  const byteCharacters = atob(b64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function getFileExtension(filename: string) {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
}

export function toNotificationTime(time: string) {
  const created = new Date(time).getTime();
  const now = new Date().getTime();
  const delta = Math.abs(now - created);
  const oneday = 24 * 60 * 60 * 1000;
  const oneweek = oneday * 7;
  const onemonth = oneday * 30;
  /** Compare time values */
  if (delta < oneday) {
    return 1;
  } else {
    return 2;
  }
}

export const defaultAvatar = '../../../assets/images/svgs/avatar-default.svg';
export const REGEX_VERIFIED_NAME =
  /([a-zA-Z0-9 àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ.-]|(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$[a-zA-Z0-9 àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ.-])/;
export const MENTION_REGEX = /(?:^@|[\s]@)\S+/gm
export const REASON_BANNED = [
  {
    value: 'IMPERSONATE_OTHER_USER',
    name: 'Usurpation d’identité',
  },
  {
    value: 'ACCOUNT_ATTACKED',
    name: 'Compte piraté',
  },
  {
    value: 'ACCOUNT_FAKE',
    name: 'Faux compte',
  },
  {
    value: 'CONTENT_NOT_SUITABLE',
    name: 'Contenu inapproprié',
  },
  {
    value: 'VIOLENCE',
    name: 'Harcèlement, violence',
  },
  {
    value: 'RACIST',
    name: 'Incitations à la haine, propos racistes',
  },
  {
    value: 'OTHER',
    name: 'Autre',
  },
];

// export const clubTypes = [
//   {
//     "name": "Clubs Fan",
//     "value": "FAN",
//     "code": "CLUB_TYPE_FAN"
//   },
//   {
//     "name": "Clubs Talent",
//     "value": "TALENT",
//     "code": "CLUB_TYPE_TALENT"
//   }
// ]

export const enum typeFilter {
  ALPHABETICAL_ORDER = 'ALPHABETICAL_ORDER',
  RECENT = 'RECENT',
  CREATORS = 'CREATORS',
  ADMIN_PLUS = 'ADMIN_PLUS',
  ADMINS = 'ADMINS',
  MEMBER = 'MEMBER',
}

export const enum TYPE_MENTION {
  POST = 'POST_MENTION',
  COMMENT_POST = 'COMMENT_POST_MENTION',
  COMMENT_ALBUM = 'COMMENT_ALBUMASSET_MENTION',
}

export const imagesTutorial = [
  {
    id: 0,
    poster: '../../assets/images/WEB1.png',
  },
  {
    id: 1,
    poster: '../../assets/images/WEB2.png',
  },
  {
    id: 2,
    poster: '../../assets/images/WEB3.png',
  },
  {
    id: 3,
    poster: '../../assets/images/WEB4.png',
  },
  {
    id: 4,
    poster: '../../assets/images/WEB5.png',
  },
];

// type Color = 'red' | 'green' | 'blue';

// type ColorIds = `${Color}Id`;

// type ColorOptions = {
//   [Type in ColorIds]:{
//     value : number,
//     type: Type
//   }
// }[ColorIds];

export const rulesDefaultWhenCreateClub = `Charte d'utilisation de la communauté Inlive With

Prise d'effet : 10/08/2022

Inlive With est un espace communautaire bienveillant pour favoriser les échanges entre Inlivers au sein des Clubs.

Notre Charte d'utilisation de la communauté est mise en place pour permettre aux inlivers d'évoluer dans un environnement sain et respectueux. La présente charte explique ce qui est autorisé et ce qui ne l'est pas sur Inlive With. Tous les Inlivers doivent respecter ces règles. Si ces règles ne sont pas respectées, nos équipes suspendent directement le compte utilisateur. Les Inlivers jouent également un rôle de modérateur et peuvent signaler des contenus et/ou des profils, ce qui entraine une suspension de compte de l'Inliver signalé. Un contrôle est ensuite effectué par nos équipes et décident si l'Inliver doit être exclu de la plateforme ou non.

1. Respect mutuel

a. Ne suscitez pas, n'encouragez pas et ne participez pas au harcèlement envers d'autres personnes. Des désaccords peuvent se produire, mais les commentaires négatifs à répétition ou particulièrement violents sont considérés comme du harcèlement et ne sont pas tolérés.

b. Necréezpas,n'encouragez pas et ne participez pas àl'incitationà la haine ou à des comportements haineux. Il est inacceptable d'attaquer une personne ou une communauté sur la base des attributs tels que l'origine ethnique, la caste, l'origine nationale, le sexe, l'identité sexuelle, la présentation de genre, l'orientation sexuelle, l'affiliation religieuse, l'âge, la maladie grave, les handicaps ou d'autres classifications protégées.

c. N'émettez pas de menaces de violence et ne menacez pas de porter atteinte à d'autres personnes.Cela inclut les menaces indirectes, ainsi que le partage ou la menace de partager les informations personnelles d'une personne.

d. N'utilisez pas Inlive With pour organiser, encourager ou soutenir l'extrémisme violent. Cela comprend également la glorification d'événements violents, des auteurs d'actes violents ou des comportements similaires.

e. Les contenus à tendance sexuelle concernant des enfants sont interdits. Vous ne pouvez pas partager de contenu ou de liens illustrant des enfants dans un contexte pornographique, sexuellement suggestif ou violent, y compris la diffusion d'illustrations ou de contenu pornographique retouché mettant en scène des enfants et adoptent des comportements de « grooming ».
 
f. Ne partagez pas de contenu sexuellement explicite à d'autres personnes sans leur consentement, et n'encouragez pas le partage non consensuel d'images intimes (images, contenu vidéo ou audio).

g. Ne partagez pas de contenu célébrant ou encourageant le suicide  ou l'automutilation, y compris toute incitation à se blesser ou à adopter l'anorexie, la boulimie ou tout autre trouble du comportement alimentaire.

h. Ne partagez pas de contenu réel illustrant des scènes gores, une violence extrême ou de la cruauté envers les animaux, notamment dans l'intention de harceler ou de choquer autrui.

i. Ne partagez pas de contenu portant atteinte à la propriété intellectuelle ou à d'autres droits d'une personne.

2. Soyezhonnête

a. Ne partagez pas d'informations fausses ou trompeuses. Un contenu qui est faux, trompeur et qui peut entraîner un risque significatif de préjudice physique ou sociétal ne peut pas être partagé sur Inlive With. Nous pouvons supprimer du contenu si nous estimons que sa propagation pourrait entraîner des dommages à l'infrastructure physique, des blessures à autrui, une obstruction de la participation aux processus civiques ou la mise en danger de la santé publique.

b. N'organisez pas et ne participez pas à une usurpation d'identité d'une personne ou d'une organisation.

c. Ne vous engagez pas dans des activités destinées à causer des dommages ou à obtenir un accès non autorisé au compte, réseau ou système d'un autre utilisateur. Cela comprend l'usurpation d'identité, la distribution de logiciels malveillants, le vol de jetons d'authentification, l'hameçonnage, le déni de service distribué (DDoS) et d'autres techniques de piratage ou d'ingénierie sociale.

d. N'encouragez pas, ne distribuez pas et ne donnez pas accès à du contenu lié au piratage, au crack, à la distribution de biens volés, de logiciels piratés ou de comptes volés.

3. RespectezInliveWith

a. N'utilisez pas Inlive With pour envoyer des spam, manipuler l'engagement ou perturber les expériences d'autres Inlivers, y compris essayer d'influencer ou de perturber les conversations à l'aide de robots, de faux comptes, de comptes multiples ou d'autres automatisations.

b. N'organisezpas,ne faites pas la promotion et ne vous engagez pas dans un comportement illégal ou dangereux, tel que la sollicitation sexuelle, le trafic d'êtres humains et la vente ou la facilitation de la vente de marchandises interdites ou potentiellement dangereuses (armes à feu, munitions, drogues et substances contrôlées). Un tel comportement peut conduire à votre bannissement de Inlive With, et à votre signalement auprès des autorités compétentes.

c. N'utilisez pas de self-bots. Chaque compte doit être associé à un être humain, et non à un robot.

d. Ne trompez pas les équipes de Inlive With. N'effectuezpasdefaux signalements dans une intention malveillante, n'envoyez pas plusieurs signalements concernant le même problème, et ne demandez pas à un groupe d'Inlivers de signaler le même contenu ou problème. Ces comportements peuvent entraîner des actions sur votre compte.

Si vous êtes témoin d'activités allant à l'encontre de cette charte d'utilisation, de nos Conditions d'utilisation ou de nos autres politiques, veuillez nous le signaler. La présente charte continuera à évoluer au fil du temps. Cela signifie que nous pouvons prendre des mesures contre un Inliver, un Club ou un contenu qui viole l'esprit de la présente charte.
 

`;

export abstract class FormBodyBuilder {
  public static BuildBodyContent(value: any) {
    var _bodyContent: any = {};
    if (value !== null && value !== undefined) {
      const formData = new FormData();
      for (const key of Object.keys(value)) {
        var val = value[key];
        if (val instanceof Array) {
          var count = 0;
          for (const v of val) {
            if (v instanceof Object) {
              for (const objName of Object.keys(v)) {
                const toAppend = v[objName];
                var keyName = `${key}[${count}].${objName}`;
                if (toAppend !== null) {
                  formData.append(keyName, toAppend);
                }
              }
            } else {
              for (const v of val) {
                const toAppend = this._formDataValue(v);
                if (toAppend !== null) {
                  formData.append(key, toAppend);
                  val = val.filter((x: any) => x != v);
                }
              }
            }
            count++;
          }
        } else {
          const toAppend = this._formDataValue(val);
          if (toAppend !== null) {
            formData.set(key, toAppend);
          }
        }
      }
      _bodyContent = formData;
    }
    return _bodyContent;
  }
  private static _formDataValue(value: any): any {
    if (value === null || value === undefined) {
      return null;
    }
    if (value instanceof Blob) {
      return value;
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return String(value);
  }
}
