import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Post, PostResponseModel } from 'src/app/core/api/be/models';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-confirm-delete-post',
  templateUrl: './confirm-delete-post.component.html',
  styleUrls: ['./confirm-delete-post.component.scss']
})
export class ConfirmDeletePostComponent implements OnInit {

  @Input() showDialog: boolean = false;
  @Input() post!: PostResponseModel;
  @Output() showDialogChange = new EventEmitter();
  @Output() isDelete = new EventEmitter();

  currentUser = AuthService.GetAccountInfo();

  constructor() { }

  ngOnInit(): void {
  }

  onHide() {
    this.showDialogChange.emit()
  }

}
