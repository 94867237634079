import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { async, of } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { AccountEventResponseModel, AccountResponseWithRoleInCLubModel, EventUpdateInputModel, EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import { ClubService, EventService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {

  @ViewChild('search', { read: ElementRef }) searchElementRef!: ElementRef<HTMLInputElement>;

  @Input() isOpenEditEventPopup: boolean = false;
  @Input() event!: EventWithCheckAccountJoinEventResponseModel;

  @Output() isOpenEditEventPopupChange = new EventEmitter<boolean>();

  tempName!: string;
  address!: string;
  description!: string;
  isPublic!: boolean;
  isShowPopupDeleteEvent: boolean = false;
  defaultPoster = "../../../../assets/images/svgs/matt-club-avt.svg";

  openPopupAddInlivers: boolean = false;
  submitted: boolean = false;
  compareTimeEdit: boolean = false;

  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];
  selectedInlivers: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar

  avtUpload: any;
  imgBlob!: string | ArrayBuffer | null;

  dateCreated!: Date;
  hourStart!: any;
  dateEnded!: Date;
  hourEnd!: any;

  minimumDate!: Date;
  minimumDateEnd!: Date;

  selectedValue: boolean = false;

  lat!: number;
  lng!: number;
  zoom = 15;
  geoCoder!: any;

  titleListInliver = this._translateService.instant('MEMBERS');

  constructor(
    private _translateService: TranslateService,
    private _eventService: EventService,
    private _clubService: ClubService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _messageService: MessageService,
    private _uploadService: UploadService
  ) { }

  ngOnInit(): void {
    this.isPublic = this.event?.isPublic!;
    this.description = this.event?.description!;
    this.minimumDate = new Date(this.event?.dateStart!).valueOf() < new Date().valueOf() ? new Date(this.event?.dateStart!) : new Date();

    if (this.event) {
      this.getListInliverPlus().subscribe();
      this.getAccountJoinedEvent().subscribe();
    }

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.setCurrentPosition();
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
          this.address = place.formatted_address!;
        });
      });
    });
  }

  getMinimumDateEnd() {
    this.minimumDateEnd = new Date(this.dateCreated);
  }

  onHide() {
    this.isOpenEditEventPopupChange.emit(false);
  }

  onShow() {
    this.dateCreated = new Date(this.event?.dateStart! + 'Z');
    this.dateEnded = new Date(this.event?.dateEnd! + 'Z');
    this.hourStart = moment(this.event?.dateStart! + 'Z').format("HH:mm");
    this.hourEnd = moment(this.event?.dateEnd! + 'Z').format("HH:mm");
  }

  deleteEvent() {
    LoadingService.$(true);
    this._eventService.inlivewithEventDeleteEventIdAccountIdDelete$Json({
      accountId: AuthService.GetAccountInfo().id!,
      eventId: this.event?.id!
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          LoadingService.$(false);
        }
      }),
      catchError(() => {
        LoadingService.$(false);
        return of();
      }),
      finalize(() => {
        window.location.reload()
      })
    ).subscribe();
  }

  selectPoster(event: any) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.avtUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgBlob = reader.result;
      }
    }
  }

  getListInliverPlus() {
    return this._clubService.inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json({ clubId: this.event?.clubId!, accountRequestId: AuthService.GetAccountInfo().id!, limit: 1000 }).pipe(
      tap(res => {
        if (res.data) {
          let found = res.data?.page!.some(x => x.id === AuthService.GetAccountInfo()?.id!);
          if (found) {
            this.listInliverPlus = res.data?.page!.filter(x => x.id !== AuthService.GetAccountInfo()?.id!);
          } else {
            this.listInliverPlus = res.data?.page!;
          }
        }
      })
    )
  }

  getListJoined() {
    return this.event.accountJoinEvents?.filter(x => x.id !== AuthService.GetAccountInfo().id);
  }

  suivant() {
    this.compareTimeEdit = false;
    var body: EventUpdateInputModel = {}

    if (this.tempName && (_.toArray(this.tempName?.trim()).length! == 0 || this.getCharacterLeft().isFullChar)) {
      return;
    }

    if (this.description && _.toArray(this.description?.trim()).length! == 0) {
      return;
    }

    if (this.event.type == "NORMAL_EVENT") {
      LoadingService.$(true);
      let dateStarted = this.dateCreated.toString().substring(0, 16) + this.hourStart
      let dateFinished = this.dateEnded.toString().substring(0, 16) + this.hourEnd
      let compareTime = new Date(dateStarted).valueOf() - new Date(dateFinished).valueOf() < 0;

      if (compareTime) {
        body = {
          authorId: AuthService.GetAccountInfo().id!,
          clubId: this.event.clubId!,
          dateEnd: dateFinished ? new Date(new Date(dateFinished.replace(/-/g, "/"))).toISOString() : "",
          dateStart: dateStarted ? new Date(new Date(dateStarted.replace(/-/g, "/"))).toISOString() : "",
          description: this.description ? this.description : this.event.description,
          isPublic: this.isPublic,
          name: this.tempName ? this.tempName : this.event.name,
          place: this.address ? this.address : this.event.place,
          id: this.event.id!,
          type: this.event.type,
        }
      } else {
        LoadingService.$(false);
        this.compareTimeEdit = true;
        return;
      }
    } else if (this.event.type == "GOINLIVE_EVENT") {
      let dateStarted = this.dateCreated.toString().substring(0, 16) + this.hourStart

      body = {
        authorId: AuthService.GetAccountInfo().id!,
        clubId: this.event.clubId!,
        dateEnd: null,
        dateStart: dateStarted ? new Date(new Date(dateStarted.replace(/-/g, "/"))).toISOString() : "",
        description: this.description ? this.description : this.event.description,
        isPublic: this.isPublic,
        name: this.tempName ? this.tempName : this.event.name,
        place: this.address ? this.address : this.event.place,
        id: this.event.id!,
        type: this.event.type,
      }
    }

    this._eventService.inlivewithEventUpdatePut$Json({
      body: body
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          if (this.selectedInlivers.length > 0) {
            let ids: string[] = []
            this.selectedInlivers.forEach(x => ids.push(x.id!));
            this.unJoinMember(ids);
          }
        }
      }),
      mergeMap((res) => this.avtUpload ? this.uploadPoster(res.data?.id!) : of(null)),
      tap((res) => {
        LoadingService.$(false);
        //this.onHide();
        this.showNotification();
        window.location.reload();
      })
    ).subscribe();
  }


  uploadPoster(eventId: string) {
    return this._eventService.inlivewithEventCreatePosterEventIdFileNamePut$Json({
      eventId: eventId,
      fileName: this.avtUpload.name!
    }).pipe(
      mergeMap((res) => this._uploadService.AddMediaPosts(this.avtUpload, res.data?.path!, res.data?.hook!, eventId))
    )
  }

  unJoinMember(accountIds: string[]) {
    return this._eventService.inlivewithEventChangeListMemberJoinEventEventIdPut$Json({
      eventId: this.event.id!,
      body: accountIds
    }).subscribe()
  }

  addMarker(event: any) {
    console.log(event);
    this.lat = event.coords.lat;
    this.lng = event.coords.lng
    this.findAddressByCoordinates();
  }

  findAddressByCoordinates() {
    this.geoCoder.geocode({
      'location': {
        lat: this.lat,
        lng: this.lng
      }
    }, (results: any, status: any) => {
      this.address = results[0]?.formatted_address;
    });
  }

  setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords
        this.lat = latitude;
        this.lng = longitude
      });
      this.findAddressByCoordinates();
    }
  }

  getAccountJoinedEvent() {
    return this._eventService.inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json({
      eventId: this.event.id!,
      accountRequestId: AuthService.GetAccountInfo().id!,
      limit: 1000
    }).pipe(
      tap((res) => {
        if (res.res == 0) {
          this.selectedInlivers = res.data?.page!.filter(x => x.id! != AuthService.GetAccountInfo().id!)!;
        }
      })
    )
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: this._translateService.instant('CHANGE_INFOR_CLUB'),
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => this.onHide());
  }

  getCharacterLeft() {
    let numberLeft: number;
    let isFullChar: boolean = false;
    numberLeft = 20 - _.toArray(this.tempName?.trimStart().trimEnd())?.length! > 0 ? 20 - _.toArray(this.tempName?.trimStart().trimEnd()).length! : 0
    isFullChar = this.tempName?.length! > 20 ? true : false;
    return { numberLeft, isFullChar };
  }
}
