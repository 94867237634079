<p-dialog [closable]="true" [(visible)]="showSchedule" [resizable]="false" [modal]="true" (onHide)="onHide()" [draggable]="false">
    <div class="card">
        <div class="text-center w-100 mb-3">
            <span class="text-white fs-22">
                {{'GOINLIVE_PLUS' | translate}}
            </span>
        </div>
        <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</span>
        <div class="mt-3">
            <input class="w-100" type="text" [(ngModel)]="goInliveName" pInputText [placeholder]="'GOINLIVE_NAME' | translate"/>
        </div>
        <div class="flex flex-row justify-content-between mt-5">
            <div class="flex flex-row justify-content-start">
                <div class="flex flex-column">
                    <span class="text-white fs-16 mb-2 ml-2">{{'GOINLIVE_DATE' | translate}}</span>
                    <p-calendar placeholder="Choisis une date" class="m-1" [(ngModel)]="dateCreated"></p-calendar>
                </div>
            </div>
            <div class="flex flex-row justify-content-end">
                <div class="flex flex-column">
                    <span class="text-white fs-16 mb-2 ml-2">{{'EVENT_HOUR_DEBUT' | translate}}</span>
                    <p-calendar placeholder="— : —" class="m-1" dataType="string" [timeOnly]="true" hourFormat="24" [(ngModel)]="hourCreated"></p-calendar>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <div class="flex flex-column">
                <span class="fs-18 mb-3 text-white">
                    {{'PARTICIPANTS' | translate}}
                </span>
                <span class="fs-16 mb-3">
                    {{'PARTICIPANT_INLIVE_TITLE' | translate}}
                </span>
            </div>
            <div class="fs-14 w-100 mt-4 add-inliver cursor-pointer">
                <span class="mt-3" (click)="openPopupAddInlivers = true">{{'INVITER_PLACEHOLDER' | translate}}</span>
                <div class="avatars" *ngIf="selectedInlivers.length > 0">
                    <div class="avatars__item" *ngFor="let inliver of selectedInlivers">
                        <div class="avatars__image zone-avatar">
                            <!-- Image -->
                            <img class="fit-size" [src]="inliver?.avatar! | fetchUrlImage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5 flex justify-content-center">
            <button pButton label="{{'PROGRAM' | translate}}" (click)="scheduleGoInlive()"
                class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"></button>
        </div>
    </div>
</p-dialog>

<ng-container *ngIf="openPopupAddInlivers">
    <app-list-inlivers-goinlive [(openPopup)]="openPopupAddInlivers" [listInliverPlus]="listInliverPlus" [(selectedInlivers)]="selectedInlivers" [title]="'INVITER' | translate"></app-list-inlivers-goinlive>
</ng-container>
