import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sendmail-success',
  templateUrl: './sendmail-success.component.html',
  styleUrls: ['./sendmail-success.component.scss']
})
export class SendmailSuccessComponent implements OnInit {
  @Output() resendEmail = new EventEmitter();
  @Input() email!:string
  constructor() { }

  ngOnInit(): void {
  }

}
