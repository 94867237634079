import { LoadingService } from 'src/app/core/services/loading.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Club, ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';

@Component({
  selector: 'app-select-type-goinlive',
  templateUrl: './select-type-goinlive.component.html',
  styleUrls: ['./select-type-goinlive.component.scss'],
})
export class SelectTypeGoinliveComponent implements OnInit {
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() showDialog!: boolean;
  @Output() showDialogChange = new EventEmitter<boolean>();

  openPopupScheduleGoInlive: boolean = false;

  cameraIsAvailable: boolean = false;

  constructor(private router: Router) {
    // navigator.mediaDevices
    //   .getUserMedia({ video: true, audio: true })
    //   .then((res) => {
    //     let streamVideo = res.getVideoTracks();
    //     if (streamVideo.length > 0) {
    //       this.cameraIsAvailable = true;
    //     }
    //   });
  }

  ngOnInit(): void {}

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  goInlive(type: string) {
    LoadingService.$(true);

    // if (this.cameraIsAvailable) {
    //   this.router
    //     .navigate(['go-inlive'], {
    //       queryParams: { clubId: this.club?.id!, type },
    //     })
    //     .then(() => {});
    // } else {
    //   alert('Require camera');
    // }
    // LoadingService.$(false);

    this.router
      .navigate(['go-inlive'], {
        queryParams: { clubId: this.club?.id!, type },
      })
      .then(() => {
        LoadingService.$(false);
      });
  }

  goSchedule() {
    this.router.navigate(['manage-club/create-event', this.club.id!], {
      queryParams: { clubId: this.club.id, createFromGoInlive: true },
    });
  }
}
