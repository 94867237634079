import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-confirm-delete-asset-album',
  templateUrl: './mobile-confirm-delete-asset-album.component.html',
  styleUrls: ['./mobile-confirm-delete-asset-album.component.scss'],
})
export class MobileConfirmDeleteAssetAlbumComponent implements OnInit {
  @Input() showSidebar: boolean = true;
  @Output() showSidebarChange = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  cancel() {
    this.showSidebar = false;
    this.showSidebarChange.emit(this.showSidebar);
  }

  onDeleteAsset() {
    this.delete.emit();
  }
}
