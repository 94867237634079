import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth.service';
import { STORAGE_KEY } from './core/constant';
import { LocalStorageService } from './core/services/local-storage.service';

var routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import(
  //       './pages/landing-page/landing-page.module'
  //     ).then((m) => m.LandingPageModule),
  // },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        './pages/manage-account/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'complete-account',
    loadChildren: () =>
      import(
        './pages/manage-account/complete-account/complete-account.module'
      ).then((m) => m.CompleteAccountModule),
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./pages/main-menu/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'explorer',
    loadChildren: () =>
      import('./pages/main-menu/explorer/explorer.module').then(
        (m) => m.ExplorerModule
      ),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./pages/main-menu/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./pages/main-menu/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-club',
    loadChildren: () =>
      import('./pages/manage-club/manage-club.module').then(
        (m) => m.ManageClubModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'go-inlive',
    loadChildren: () =>
      import('./pages/go-inlive/go-inlive.module').then(
        (m) => m.GoInliveModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'go-inlive-view',
    loadChildren: () =>
      import('./pages/go-inlive-viewer/go-inlive-viewer.module').then(
        (m) => m.GoInliveViewerModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account-info',
    loadChildren: () =>
      import('./pages/manage-account/account-info/account-info.module').then(
        (m) => m.AccountInfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'signalements',
    loadChildren: () =>
      import(
        './pages/manage-club/manage-report/manage-report-routing.module'
      ).then((m) => m.ManageReportRoutingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setting-user/:currentSetting',
    loadChildren: () =>
      import(
        './pages/manage-account/parameters-account/parameters-account-routing.module.'
      ).then((m) => m.ParametersAccountRoutingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-favourite',
    loadChildren: () =>
      import(
        './pages/manage-account/my-favourite/my-favourite-routing.module'
      ).then((m) => m.MyFavuriteRoutingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-user',
    loadChildren: () =>
      import('./pages/manage-account/edit-user/edit-user-routing.module').then(
        (m) => m.EditUserRoutingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'friend-list',
    loadChildren: () =>
      import('./pages/friend-list/friend-list.module').then(
        (m) => m.FriendListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'club-list',
    loadChildren: () =>
      import('./pages/club-list/club-list.module').then(
        (m) => m.ClubListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'research',
    loadChildren: () =>
      import('./pages/research/research.module').then((m) => m.ResearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mobile',
    loadChildren: () =>
      import('./pages/mobile/mobile.module').then((m) => m.MobileModule),
  },
  {
    path: 'announcement',
    loadChildren: () =>
      import('./pages/announcement/announcement.module').then(
        (m) => m.AnnouncementModule
      ),
  },
  {
    path: 'inlivers-suggestion-list',
    loadChildren: () =>
      import(
        './pages/inlivers-suggestion-list/inlivers-suggestion-list.module'
      ).then((m) => m.InliversSuggestionListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'list-club-by-top-hashtag',
    loadChildren: () =>
      import(
        './pages/list-club-by-top-hashtag/list-club-by-top-hashtag.module'
      ).then((m) => m.ListClubByTopHashtagModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'list-club-by-trending',
    loadChildren: () =>
      import('./pages/list-club-by-trending/list-club-by-trending.module').then(
        (m) => m.ListClubByTrendingModule
      ),
    canActivate: [AuthGuard],
  },
];

function getRoute() {
  const account = LocalStorageService.GetLocalStorage('account');
  let _routes: Routes;
  if (account) {
    _routes = [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      ...routes,
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ];
  } else {
    _routes = [
      { path: '', redirectTo: 'explorer', pathMatch: 'full' },
      ...routes,
      { path: '**', redirectTo: 'explorer', pathMatch: 'full' },
    ];
  }
  return _routes;
}

var route_landing_page: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: 'announcement',
    loadChildren: () =>
      import('./pages/announcement/announcement.module').then(
        (m) => m.AnnouncementModule
      ),
  },
  {
    path: 'mobile',
    loadChildren: () =>
      import('./pages/mobile/mobile.module').then((m) => m.MobileModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(getRoute())],
  //imports: [RouterModule.forRoot(route_landing_page)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
