import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inliver-list-skeleton',
  templateUrl: './inliver-list-skeleton.component.html',
  styleUrls: ['./inliver-list-skeleton.component.scss']
})
export class InliverListSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
