/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GoInliveScheduleCreateModel } from '../models/go-inlive-schedule-create-model';
import { GoInliveScheduleInviteModel } from '../models/go-inlive-schedule-invite-model';
import { GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel } from '../models/go-inlive-schedule-response-model-with-club-detail-pagination-model-response-model';
import { GoInliveScheduleResponseModelWithClubDetailResponseModel } from '../models/go-inlive-schedule-response-model-with-club-detail-response-model';
import { GoInliveScheduleUpdateModel } from '../models/go-inlive-schedule-update-model';
import { InvitationGoInliveScheduleResponseModelListResponseModel } from '../models/invitation-go-inlive-schedule-response-model-list-response-model';
import { InvitationGoInliveScheduleResponseModelPaginationModelResponseModel } from '../models/invitation-go-inlive-schedule-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class GoInliveScheduleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithGoInliveSchedulePageGet
   */
  static readonly InlivewithGoInliveSchedulePageGetPath = '/inlivewith/GoInliveSchedule/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSchedulePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveSchedulePageGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveSchedulePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSchedulePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveSchedulePageGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveSchedulePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSchedulePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveSchedulePageGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveSchedulePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSchedulePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveSchedulePageGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveSchedulePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet
   */
  static readonly InlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGetPath = '/inlivewith/GoInliveSchedule/GetGoInliveScheduleById/{goInliveScheduleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Plain$Response(params: {
    goInliveScheduleId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGetPath, 'get');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Plain(params: {
    goInliveScheduleId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Json$Response(params: {
    goInliveScheduleId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGetPath, 'get');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Json(params: {
    goInliveScheduleId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByIdGoInliveScheduleIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet
   */
  static readonly InlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGetPath = '/inlivewith/GoInliveSchedule/GetGoInliveScheduleByStreaming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByStreamingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet
   */
  static readonly InlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGetPath = '/inlivewith/GoInliveSchedule/GetGoInliveScheduleByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Plain(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Json(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet
   */
  static readonly InlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGetPath = '/inlivewith/GoInliveSchedule/GetGoInliveScheduleThatAccountNotInClub/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Plain$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Plain(params: {
    accountId: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Json$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Json(params: {
    accountId: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleThatAccountNotInClubAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet
   */
  static readonly InlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGetPath = '/inlivewith/GoInliveSchedule/GetGoInliveScheduleByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Plain(params: {
    clubId: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Json(params: {
    clubId: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetGoInliveScheduleByClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleCreateGoInliveSchedulePost
   */
  static readonly InlivewithGoInliveScheduleCreateGoInliveSchedulePostPath = '/inlivewith/GoInliveSchedule/CreateGoInliveSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Plain$Response(params?: {
    body?: GoInliveScheduleCreateModel
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleCreateGoInliveSchedulePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Plain(params?: {
    body?: GoInliveScheduleCreateModel
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json$Response(params?: {
    body?: GoInliveScheduleCreateModel
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleCreateGoInliveSchedulePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json(params?: {
    body?: GoInliveScheduleCreateModel
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleUpdateGoInliveSchedulePut
   */
  static readonly InlivewithGoInliveScheduleUpdateGoInliveSchedulePutPath = '/inlivewith/GoInliveSchedule/UpdateGoInliveSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Plain$Response(params?: {
    body?: GoInliveScheduleUpdateModel
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleUpdateGoInliveSchedulePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Plain(params?: {
    body?: GoInliveScheduleUpdateModel
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Json$Response(params?: {
    body?: GoInliveScheduleUpdateModel
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleUpdateGoInliveSchedulePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Json(params?: {
    body?: GoInliveScheduleUpdateModel
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleUpdateGoInliveSchedulePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut
   */
  static readonly InlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPutPath = '/inlivewith/GoInliveSchedule/EndGoInliveSchedule/{goInliveScheduleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Plain$Response(params: {
    goInliveScheduleId: string;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPutPath, 'put');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Plain(params: {
    goInliveScheduleId: string;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Json$Response(params: {
    goInliveScheduleId: string;
  }): Observable<StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPutPath, 'put');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Json(params: {
    goInliveScheduleId: string;
  }): Observable<GoInliveScheduleResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveScheduleEndGoInliveScheduleGoInliveScheduleIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveScheduleResponseModelWithClubDetailResponseModel>) => r.body as GoInliveScheduleResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetAllInvitationGet
   */
  static readonly InlivewithGoInliveScheduleGetAllInvitationGetPath = '/inlivewith/GoInliveSchedule/GetAllInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetAllInvitationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetAllInvitationGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetAllInvitationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetAllInvitationGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetAllInvitationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetAllInvitationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetAllInvitationGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetAllInvitationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetAllInvitationGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetAllInvitationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet
   */
  static readonly InlivewithGoInliveScheduleGetInvitationByAccountAccountIdGetPath = '/inlivewith/GoInliveSchedule/GetInvitationByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetInvitationByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetInvitationByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetInvitationByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet
   */
  static readonly InlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGetPath = '/inlivewith/GoInliveSchedule/GetInvitationByGoInliveScheduleId/{goInliveScheduleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Plain$Response(params: {
    goInliveScheduleId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGetPath, 'get');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Plain(params: {
    goInliveScheduleId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Json$Response(params: {
    goInliveScheduleId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGetPath, 'get');
    if (params) {
      rb.path('goInliveScheduleId', params.goInliveScheduleId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Json(params: {
    goInliveScheduleId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveScheduleGetInvitationByGoInliveScheduleIdGoInliveScheduleIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleInviteToGoInliveSchedulePost
   */
  static readonly InlivewithGoInliveScheduleInviteToGoInliveSchedulePostPath = '/inlivewith/GoInliveSchedule/InviteToGoInliveSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Plain$Response(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleInviteToGoInliveSchedulePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Plain(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<InvitationGoInliveScheduleResponseModelListResponseModel> {

    return this.inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json$Response(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleInviteToGoInliveSchedulePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<InvitationGoInliveScheduleResponseModelListResponseModel> {

    return this.inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut
   */
  static readonly InlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePutPath = '/inlivewith/GoInliveSchedule/UnInvitationToGoInliveSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Plain$Response(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Plain(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<InvitationGoInliveScheduleResponseModelListResponseModel> {

    return this.inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Json$Response(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveScheduleService.InlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Json(params?: {
    body?: GoInliveScheduleInviteModel
  }): Observable<InvitationGoInliveScheduleResponseModelListResponseModel> {

    return this.inlivewithGoInliveScheduleUnInvitationToGoInliveSchedulePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveScheduleResponseModelListResponseModel>) => r.body as InvitationGoInliveScheduleResponseModelListResponseModel)
    );
  }

}
