import { ClubResponseWithTotalMemberModel } from './../../../core/api/be/models/club-response-with-total-member-model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { forkJoin, of } from 'rxjs';
import { debounceTime, mergeMap, tap } from 'rxjs/operators';
import {
  CelebrityAliasResponseModel,
  Club,
  Hashtag,
  HashtagResponseModel,
} from 'src/app/core/api/be/models';
import { ClubService, HashtagService } from 'src/app/core/api/be/services';
import { ILWAppEnum, REGEX_VERIFIED_NAME } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-edit-club',
  templateUrl: './edit-club.component.html',
  styleUrls: ['./edit-club.component.scss'],
})
export class EditClubComponent implements OnInit {
  @Input() avtUpload: any;
  @Input() club!: ClubResponseWithTotalMemberModel;
  @Input() currentHashtags: HashtagResponseModel[] = [];
  @Input() openPopupEdit!: boolean;

  @Output() openPopupEditChange = new EventEmitter<boolean>();
  @Output() reloadClub = new EventEmitter();

  celebListExist: boolean = false;
  showDialogUploadAvatar = false;
  checkClubName: boolean = false;
  checkClubNameExist: boolean = false;
  form!: UntypedFormGroup;
  imgBlob!: string | ArrayBuffer | null;
  listHashtagByCategory: HashtagResponseModel[] = [];
  listIdsHashTags: string[] = [];
  listCelebs: string[] = [];
  suggestionCelebrities: CelebrityAliasResponseModel[] = [];
  suggestionList!: any[];
  suggestionValue: string = '';
  tempClubName: string = '';
  imageChangedEvent: any = '';
  textErrorMax5!: boolean;

  listCategories!: {
    url: string;
    name: string;
    value: string;
    label?: string;
  }[];
  selectedCategory!: { url: string; name: string; value: string };
  listHashtagAll!: HashtagResponseModel[];
  REGEX = REGEX_VERIFIED_NAME;

  constructor(
    private _clubService: ClubService,
    private _fb: UntypedFormBuilder,
    private _hashtagService: HashtagService,
    private _translateService: TranslateService,
    private messageService: MessageService,
    private _uploadService: UploadService
  ) {
    this.form = this._fb.group({
      isLinked: false,
      textSearchSuggestion: [null, []],
    });
  }

  ngOnInit(): void {
    this.listCategories = this.initListCategories();

    this.selectedCategory = this.listCategories.filter(
      (h) => h.value == this.club.category!
    )[0];

    this.getAllHashtags();
    this.suggestionCelebrities.push(...this.club.celebrityAliases!);
    this.suggestionCelebrities.forEach((x) => {
      this.listCelebs.push(x?.name!);
    });
    this.celebListExist = this.suggestionCelebrities.length >= 1;

    this.tempClubName = this.club.name!;
  }

  selectAvatar(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.imageChangedEvent = event;
      this.showDialogUploadAvatar = true;
    }
  }

  cancelImage() {
    this.imgBlob = '';
    this.avtUpload = '';
    (<HTMLInputElement>document.getElementById('file-avatar')).value = '';
  }

  checkClubNameExistRegister(event: any) {
    if (!this.tempClubName.trim() || this.tempClubName === this.club.name!) {
      this.checkClubNameExist = false;
      this.checkClubName = true
      return;
    }

    this._clubService
      .inlivewithClubByNameNameGet$Json({
        name: `${this.tempClubName.trim()}`,
        details: true,
      })
      .pipe(
        debounceTime(500),
        tap((data) => {
          if (!data.data?.id!) {
            this.checkClubNameExist = false;
            this.checkClubName = true;
          }
          else {
            this.checkClubNameExist = true;
            this.checkClubName = true;
          }
        })
      )
      .subscribe();

  }

  closePopup() {
    this.openPopupEditChange.emit(false);
  }

  getIcon() {
    let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter(
      (c: any) => c.value == this.club?.category
    )[0]?.url!;
    return iconUrl;
  }

  getAllHashtags() {
    LoadingService.$(true);
    this._hashtagService
      .inlivewithHashtagsPageGet$Json({ page: 1, limit: 1000 })
      .subscribe((res) => {
        if (res.res == 0) {
          this.listHashtagAll = res.data?.page!;

          this.listHashtagByCategory = this.listHashtagAll.filter(
            (h) => this.club.category?.includes(h.category!) // 'CLUB_CATEGORY_MUSIC'.includes('MUSIC') ==> :)))
          );

          LoadingService.$(false);
        }
      });
  }

  checkDisable(item: any): boolean {
    return (
      this.currentHashtags.length >= 5 &&
      this.currentHashtags.findIndex((x) => x.id == item.id!) < 0
    );
  }

  selectHashtag(h: HashtagResponseModel) {
    if (this.checkDisable(h)) {
      return;
    }

    if (this.currentHashtags.length >= 5) {
      if (!this.checkDisable(h)) {
        this.currentHashtags = this.currentHashtags.filter(
          (x) => x.id !== h.id!
        );
        return;
      } else {
        this.textErrorMax5 = true;
        return;
      }
    } else {
      this.textErrorMax5 = false;
    }

    this.currentHashtags.includes(h)
      ? (this.currentHashtags = this.currentHashtags.filter(
        (v) => v.id! !== h?.id!
      ))
      : this.currentHashtags.push(h);
  }

  getItemsSelected(h: any) {
    return this.currentHashtags.some((x) => x.id === h.id);
  }

  editClub() {
    if ((this.checkClubNameExist && this.checkClubName)) {
      return;
    }

    LoadingService.$(true);
    if (
      this.currentHashtags.length <= 5 &&
      (!this.isCharLetter(this.tempClubName) && this.getCharacterLeft().numberLeft == 0 &&
        !this.getCharacterLeft().isFullChar) ||
      (this.getCharacterLeft().numberLeft > 0 &&
        this.getCharacterLeft().numberLeft <= 38)
    ) {
      this._clubService
        .inlivewithClubNameAdminIdClubIdPut$Json({
          adminId: AuthService.GetAccountInfo()?.id!,
          clubId: this.club.id!,
          body: {
            adminId: AuthService.GetAccountInfo()?.id!,
            clubId: this.club.id!,
            value: this.tempClubName ? this.tempClubName : this.club.name,
          },
        })
        .pipe(
          tap(() => { }),
          mergeMap(() =>
            forkJoin([
              this.editCategory(),
              this.editClubByHashTags(),
              this.uploadAvatar(),
              this.editClubCelebList(),
            ])
          )
        )
        .subscribe(() => {
          LoadingService.$(false);
          this.showNotification();
          this.closePopup();
          //window.location.reload();
          //console.log(this.currentHashtags);

          this.reloadClub.emit();
        });
    } else {
      LoadingService.$(false);
    }
  }

  editClubCelebList() {
    // if (this.listCelebs.length == 0) {
    //   return of(null);
    // }

    return this._clubService.inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json(
      {
        clubId: this.club.id!,
        body: this.celebListExist ? this.listCelebs : [],
      }
    );
  }

  editClubByHashTags() {
    this.currentHashtags.forEach((x) => {
      this.listIdsHashTags.push(x?.id!);
    });

    return this._clubService.inlivewithClubHashtagsAdminIdClubIdPut$Json({
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club.id!,
      body: {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club.id!,
        values: this.listIdsHashTags,
      },
    });
  }

  upload(blob: Blob) {
    const file = new File([blob], 'clubAvatar.jpeg', { type: 'jpeg' });
    this.avtUpload = file;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.imgBlob = reader.result;
    };
  }

  uploadAvatar() {
    return this._clubService.inlivewithClubAvatarAdminIdClubIdFileNamePost$Json({
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club.id!,
      fileName: this.avtUpload.name!
    }).pipe(
      mergeMap(res => this._uploadService.AddMediaPosts(this.avtUpload, res.data?.path!, res.data?.hook!, this.club.id!))
    )
  }

  searchSuggestionList(name: string) {
    if (name.trim().length == 0) {
      return;
    }
    this._clubService
      .inlivewithClubGetCelebrityAliasLikeNameNameGet$Json({ name })
      .pipe()
      .subscribe((res) => {
        this.suggestionList = res!.data!.page!.map((d) => {
          return {
            id: d.id,
            name: d.normalizedName,
            value: d.name,
          };
        });
      });
  }

  selectedSuggestion(selected: { id: string; name: string; value: string }) {
    if (selected) {
      this.suggestionValue = selected.name;

      this.addToSuggestionListCallee();
    }
  }

  SuggestionEntered(event: any) {
    this.suggestionValue = event.target.value;
  }

  addToSuggestionListCallee() {
    if (this.suggestionValue.trim().length == 0) {
      return;
    }
    if (this.suggestionValue) {
      if (!this.listCelebs.includes(this.suggestionValue)) {
        this.listCelebs.push(this.suggestionValue);
      }

      this.form.controls.textSearchSuggestion.setValue('');
    }
  }

  editCategory() {
    let body = {
      adminId: AuthService.GetAccountInfo().id!,
      clubId: this.club.id!,
      body: {
        adminId: AuthService.GetAccountInfo().id!,
        clubId: this.club.id!,
        value: this.selectedCategory.name,
      },
    };

    return this._clubService.inlivewithClubCategoryAdminIdClubIdPut$Json(body);
  }

  selectCategogy(event: any) {
    this.selectedCategory = event.value;

    this.currentHashtags = [];

    this.listHashtagByCategory = this.listHashtagAll.filter(
      (h) => h.category == this.selectedCategory.name
    )!;
  }

  initListCategories() {
    let list: any = [];
    ILWAppEnum.ENUM_ILW.clubCategories.forEach((c: any) => {
      list.push({
        name: c.name,
        value: c.value,
        url: c.url,
        index: c.index,
        label: this._translateService.instant(c.value!),
      });
    });

    return list;
  }

  getHashtagByCategory(name: string) {
    let listHashtagss: HashtagResponseModel[] = [];

    if (name == null) {
      listHashtagss = this.currentHashtags.filter(
        (x) => x.category === 'DANCE'
      );
    } else {
      listHashtagss = this.currentHashtags.filter((x) => x.category === name);
    }

    return listHashtagss;
  }

  getCharacterLeft() {
    let numberLeft: number;
    let isFullChar: boolean = false;
    numberLeft =
      40 - _.toArray(this.tempClubName)?.length! > 0
        ? 40 - _.toArray(this.tempClubName).length!
        : 0;
    isFullChar = this.tempClubName?.length! > 40 ? true : false;
    return { numberLeft, isFullChar };
  }

  isCharLetter(nameClub: string) {
    return /[^a-zA-Z0-9 àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ.-]/i.test(nameClub);
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: this._translateService.instant('CHANGE_INFOR_CLUB'),
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => window.location.reload());
  }
}
