import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { finalize, tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-club-notification-admin-setting',
  templateUrl: './club-notification-admin-setting.component.html',
  styleUrls: ['./club-notification-admin-setting.component.scss'],
})
export class ClubNotificationAdminSettingComponent implements OnInit {
  @Input() isMobile!: boolean;
  @Input() showDialog!: boolean;
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;

  @Output() showDialogChange = new EventEmitter();

  isTurnOffIdentificationsNoti!: boolean;
  isTurnOffPublicationsNoti!: boolean;
  isTurnOffInliverNoti!: boolean;
  isAll!: boolean;

  constructor(
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getNotiSetting().subscribe();
  }

  onHide() {
    this.showDialogChange.emit(false);
  }

  editNotiSetting() {
    this._clubService
      .inlivewithClubEditNotificationInClubForAdminPut$Json({
        body: {
          accountRequestId: AuthService.GetAccountInfo().id!,
          clubId: this.club.id!,
          isTurnOffIdentificationsNoti: !this.isTurnOffIdentificationsNoti,
          isTurnOffPublicationsNoti: !this.isTurnOffPublicationsNoti,
          isTurnOffInliverNoti: !this.isTurnOffInliverNoti,
        },
      })
      .pipe(
        tap((res) => {
          //this.onHide();
          this.showNotification();
        })
      )
      .subscribe();
  }

  getNotiSetting() {
    LoadingService.$(true);

    return this._clubService
      .inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
        clubId: this.club.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.isTurnOffInliverNoti = !res.data?.isTurnOffInliverNoti!;
            this.isTurnOffPublicationsNoti =
              !res.data?.isTurnOffPublicationsNoti!;
            this.isTurnOffIdentificationsNoti =
              !res.data?.isTurnOffIdentificationsNoti!;

            if (
              this.isTurnOffInliverNoti &&
              this.isTurnOffPublicationsNoti &&
              this.isTurnOffIdentificationsNoti
            ) {
              this.isAll = true;
            } else {
              this.isAll = false;
            }
          }
        }),
        finalize(() => LoadingService.$(false))
      );
  }

  clear() {
    if (this.isAll) {
      this.isTurnOffInliverNoti = true;
      this.isTurnOffPublicationsNoti = true;
      this.isTurnOffIdentificationsNoti = true;
    } else {
      this.isTurnOffInliverNoti = false;
      this.isTurnOffPublicationsNoti = false;
      this.isTurnOffIdentificationsNoti = false;
    }
  }

  save() {
    this.editNotiSetting();
  }

  check() {
    if (
      this.isTurnOffInliverNoti &&
      this.isTurnOffPublicationsNoti &&
      this.isTurnOffIdentificationsNoti
    ) {
      this.isAll = true;
    } else {
      this.isAll = false;
    }
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: this._translateService.instant('CHANGE_INFOR_CLUB'),
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => this.onHide());
  }
}
