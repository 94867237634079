<p-dialog *ngIf="!isMobile" [(visible)]="showDialog" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="onHide()">
    <div style="width: 650px;">
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'USER_LIST_BANNED' | translate}}
            </span>
        </div>
        <div class="mt-3">
            {{'USER_LIST_BANNED_TITLE' | translate}}
        </div>
        <div class="mt-3" *ngFor="let item of listInliversBanned">
            <div class="flex align-items-center justify-content-between mt-2 mb-2">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="item?.accountBanned?.avatar! ? item?.accountBanned?.avatar! : defaultAvatar" class="fit-size">
                    </div>
                    <div class="text-white fs-16 ml-3">
                        <span>{{item?.accountBanned?.nickName! | limitString:false:40}}</span>
                    </div>
                </div>
                <div>
                    <button pButton label="{{'UNBLOCK' | translate}}" (click)="unBlock(item)" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"> 
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>


<p-sidebar *ngIf="isMobile" [(visible)]="showDialog" [modal]="true" [draggable]="false" [showCloseIcon]="true" position="bottom" (onHide)="onHide()">
    <div>
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'USER_LIST_BANNED' | translate}}
            </span>
        </div>
        <div class="mt-3">
            {{'USER_LIST_BANNED_TITLE' | translate}}
        </div>
        <div class="mt-3" *ngFor="let item of listInliversBanned">
            <div class="flex align-items-center justify-content-between mt-2 mb-2">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="item?.accountBanned?.avatar! ? item?.accountBanned?.avatar! : defaultAvatar" class="fit-size">
                    </div>
                    <div class="text-white fs-16 ml-3">
                        <span>{{item?.accountBanned?.nickName! | limitString:false:40}}</span>
                    </div>
                </div>
                <div>
                    <button pButton label="{{'UNBLOCK' | translate}}" (click)="unBlock(item)" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"> 
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>
