import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customTranslate",
})
export class CustomTranslatePipe implements PipeTransform {
  readonly KEY_ENTER = "<enter>";
  readonly KEY_ENTER_REPLACE = "<br/>";

  transform(value: string, keyReplace?: string, valueReplace?: string): string {
    value = value.split(this.KEY_ENTER).join(this.KEY_ENTER_REPLACE);
    value = value.split(keyReplace!).join(valueReplace!);
    return value;
  }
}
