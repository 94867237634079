import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'app-record-audio',
  templateUrl: './record-audio.component.html',
  styleUrls: ['./record-audio.component.scss']
})
export class RecordAudioComponent implements OnInit {

  //Lets initiate Record OBJ
  record: any;
  //Will use this flag for detect recording
  recording!: boolean;
  permissionAllowed = false;
  //Url of Blob
  error: any;

  minutesLabel: string = '0';
  secondsLabel: string = '0';
  totalSeconds: number = 0;
  loop: any;

  @Input() showDialog: boolean = false;
  @Input() url!: any
  @Input() blobFile!: Blob | undefined;
  @Input() isMobile:boolean = false;

  @Output() showDialogChange = new EventEmitter();
  @Output() urlChange = new EventEmitter();
  @Output() blobFileChange = new EventEmitter();

  title: string = "send nudes"

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

  }


  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  /**
   * Start recording.
   */
  initiateRecording() {

    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream: any) {
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();

    this.setTimer();
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob: any) {
    this.blobFile = blob;
    console.log("blob", blob);
    this.url = URL.createObjectURL(blob);
  }
  /**
   * Process Error.
   */
  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';
  }

  get classType() {
    if (this.recording) return 'active';
    else return 'recorded';
  }

  setTimer(): void {
    this.minutesLabel = '00';
    this.secondsLabel = '00';
    this.totalSeconds = 0;

    this.loop = setInterval(() => {
      /** Display */
      ++this.totalSeconds;

      this.secondsLabel = this.calculateTime(this.totalSeconds % 60);
      this.minutesLabel = this.calculateTime(Math.floor(this.totalSeconds / 60));
    }, 1000);
  }

  calculateTime(val: number): string {
    var valString = val + '';
    if (valString.length < 2) {
      return '0' + valString;
    } else {
      return valString;
    }
  }

  toggleRecord() {
    /** Do main action */
    if (this.recording) {
      this.stopRecording();
      this.afterRecord();
    } else {
      this.reset();
      this.initiateRecording();
    }
  }

  onClickRecord() {
    if (!this.permissionAllowed) {
      this.askPermission(this.toggleRecord.bind(this));
    } else {
      this.toggleRecord();
    }
  }

  askPermission(callback?: any) {
    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then(result => {
        console.log('[Record Permission Success]', result);
        this.permissionAllowed = true;
        if (callback) {
          callback();
        }
      })
      .catch(result => {
        console.log('[Record Permission Decline]', result);
        this.permissionAllowed = false;
      });
  }

  reset() {
    this.url = '';
    this.totalSeconds = 0;
  }

  afterRecord(): void {
    clearInterval(this.loop);
    this.totalSeconds = 0;
  }

  close() {

    const blob = new Blob([this.blobFile!], { 
      'type': 'audio/wav;' 
    });

    let file = new File([blob], 'recording.wav');

    this.urlChange.emit(this.url);
    this.blobFileChange.emit(file);

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(mediaStream => {
        // Stop the stream after 5 seconds
        setTimeout(() => {
          const tracks = mediaStream.getTracks()
          tracks[0].stop();

          //
        }, 1000)
      }).then(() => this.closePopup())
  }

  closePopup() {
    this.showDialogChange.emit(false)
  }

}
