import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ClubResponseWithTotalMemberModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-club-managed',
  templateUrl: './club-managed.component.html',
  styleUrls: ['./club-managed.component.scss'],
})
export class ClubManagedComponent implements OnInit {
  @Input() isReport!: boolean;
  @Input() currentClubReports!: string;
  @Input() openReportByAccount: boolean = true;

  @Output() openReportByAccountChange = new EventEmitter();

  isShowMoreClubs!: boolean;

  myClubs: ClubResponseWithTotalMemberModel[] = [];
  totalItems: number | undefined;

  constructor(private _router: Router, private _clubService: ClubService) { }

  ngOnInit(): void {
    if (!AuthService.GetAccountInfo()?.id!) {
      return;
    }

    if (!this._router.url.includes('/detail-club')) {
      this.getClubByAdmin(6)
    }

  }

  createClub() {
    this._router.navigate(['manage-club/create']);
  }

  clickScroll() {
    this.isShowMoreClubs = !this.isShowMoreClubs;
    this.isShowMoreClubs ? this.getClubByAdmin(100000) : this.getClubByAdmin(6);
  }

  getClubByAdmin(limit: number) {
    return this._clubService
      .inlivewithClubsByAdministratorAdminIdGet$Json({
        adminId: AuthService.GetAccountInfo()?.id!,
        limit: limit,
      })
      .subscribe((res) => {
        this.myClubs = res.data?.page!;
        this.totalItems = res.data?.totalItems!;

        let myListClubIds = this.myClubs?.map((c) => c.id);
        LocalStorageService.AddLocalStorage(
          'myListClubIds',
          JSON.stringify(myListClubIds)
        );
      });
  }

  handleClick() {
    this.currentClubReports = this.myClubs[0].id!;
    this.openReportByAccountChange.emit(false);
    this._router.navigate(['signalements', this.currentClubReports], { queryParams: { clubId: this.currentClubReports } })
  }

  setOpenReportByAccount() {
    this.openReportByAccountChange.emit(false);
  }
}
