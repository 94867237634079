<p-dialog [(visible)]="showDialog" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="onHide()">
    <div style="width: 650px;">
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'BAN' | translate}} @{{getAccountBannedId().nickName! | limitString:false:40}}
            </span>
        </div>
        <div class="mt-5">
            <span class="text-white fs-18"
                [innerHTML]="'BAN_MEMBER_POPUP_SUBTITLE' | translate:{value:getAccountBannedId().nickName! | limitString:false:40}"></span>
        </div>
        <div class="mt-5 flex align-items-center justify-content-center">
            <button id="notify" pButton label="{{'CANCEL' | translate}}" (click)="onHide()"
                class="p-button-red p-button-secondary text-white transparent bg-transparent border-white ml-2">
            </button>
            <button id="notify" pButton label="{{'BAN' | translate}}" (click)="bannedFromClub()"
                class="p-button-red p-button-secondary text-white white  border-white ml-2">
            </button>
        </div>
    </div>
</p-dialog>