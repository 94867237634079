import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, CommentPostResponseModel, PostResponseModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { REASON_BANNED } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-inliver-reason-banned',
  templateUrl: './list-inliver-reason-banned.component.html',
  styleUrls: ['./list-inliver-reason-banned.component.scss']
})
export class ListInliverReasonBannedComponent implements OnInit {

  @Input() showDialog!: boolean
  @Input() post!: PostResponseModel
  @Input() account!: AccountResponseWithRoleInCLubModel
  @Input() clubId!: string;
  @Input() commentPost!: CommentPostResponseModel

  @Output() showDialogChange = new EventEmitter();
  @Output() getNewListMember = new EventEmitter();

  notifySuccess: boolean = false;

  REASON_BANNED = REASON_BANNED

  constructor(
    private _clubService: ClubService
  ) { }

  ngOnInit(): void {
  }

  onHide() {
    this.showDialogChange.emit(false);
  }

  onHideNotify() {
    this.notifySuccess = false;
    this.onHide();
  }

  bannedFromClub() {
    return this._clubService
      .inlivewithClubCreateAccountClubBannedPost$Json({
        body: {
          accountBannedId: this.getAccountBannedId().id!,
          authorId: AuthService.GetAccountInfo().id!,
          clubId: this.post ? this.post.clubId! : this.clubId,
          type: 'ADMIN_BANNED_MEMBER',
        },
      })
      .pipe(
        tap((res) => {
          this.getNewListMember.emit();
          this.onHide()
        })
      )
      .subscribe();
  }

  getAccountBannedId() {
    if (this.post) {
      return this.post.author!
    }
    if (this.commentPost) {
      return this.commentPost.author!
    }
    else return this.account!
  }

  getReason(item: any) {
    this._clubService.inlivewithClubAccountBannedAdminClubIdClubIdGet$Json({
      adminClubId: AuthService.GetAccountInfo().id!,
      clubId: this.post?.club?.id! ? this.post.club?.id! : this.clubId!
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.notifySuccess = true;
          //this.onHide();
          this.getNewListMember.emit();
          //window.location.reload();
        }
      })
    ).subscribe();

  }

}
