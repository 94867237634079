import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { pairwise, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LastUrlService {

  constructor(private _router: Router) {
    this.initLastUrl()
  }

  lastUrl: string | undefined;

  private initLastUrl() {
    this._router.events
    .pipe(
      filter((evt: any) => evt instanceof RoutesRecognized),
      pairwise()
    )
    .subscribe((events: RoutesRecognized[]) => this.lastUrl = events[0].urlAfterRedirects)
  }


  public getLastUrl() {
    return this.lastUrl
  }
}
