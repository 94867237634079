<p-sidebar [(visible)]="isShowPopup" position="bottom" [showCloseIcon]="false" [dismissible]="false">
    <div>
        <div class="flex flex-column justify-content-center text-center">
            
            <span class="text-white mt-3 fs-20">
                {{title}}
            </span>

            <div class="mt-3 fs-16">
                {{content}}
            </div>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-sidebar>
