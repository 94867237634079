<p-sidebar [(visible)]="showSidebar" position="bottom" (onHide)="onHide()" [fullScreen]="true" [showCloseIcon]="true"
    [dismissible]="false">

    <div class="flex flex-column justify-content-center h-100">
        <div class="flex w-100 position-relative">
            <div class="flex w-100 h-100 align-items-center justify-content-between zone-arrow">
                <i class="flex pi pi-angle-left cursor-pointer" (click)="pre()" style="font-size: 24px"></i>
                <i class="flex pi pi-angle-right cursor-pointer" (click)="next()" style="font-size: 24px"></i>
            </div>

            <img *ngIf="currentAsset.url!.includes('?i=')" [src]="currentAsset.url" style="width: 100%; height: auto"
                srcset="">
            <div class="w-100" *ngIf="currentAsset.url!.includes('?v=')">
                <video [id]="currentAsset.id" class="w-100" [src]="currentAsset.url!" controls></video>
            </div>
        </div>

        <div class="flex w-100 justify-content-end position-relative">
            <span class="num-page"> {{indexAsset + 1}}/{{listAssets.length}}</span>
        </div>

        <div class="flex align-items-center p-3">
            <div class="flex" style="width: 72px">
                <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="currentAsset.isReacted!"
                    style="font-size: 24px; transition: color 0.5s" (click)="react()"
                    [ngStyle]="{'color' : currentAsset.isReacted! ? '#FF5050' : '#FFF'}"></i>
                <span class="fs-12 ml-2" (click)="showListLike = true">{{currentAsset.totalReact!}}</span>
            </div>
            <div class="flex ml-5 cursor-pointer">
                <img src="../../../assets/images/svgs/post-comment.svg">
                <span class="fs-12 ml-2" (click)="showComment = true">{{currentAsset!.totalComment!}}</span>
            </div>
        </div>

        <div class="pl-3 pr-3">
            <div class="flex align-items-center justify-content-between" style="position: relative;">
                <div class="flex align-items-center">
                    <div class="zone-avt">
                        <img [src]="currentAsset.author?.avatar ? currentAsset.author?.avatar : defaultAvatar"
                            class="fit-size">
                    </div>
                    <span class="fs-14 text-white ml-3 mr-3">{{currentAsset.author?.nickName!}}</span>
                </div>
                <div class="flex align-items-center">
                    <span class="fs-11"> {{currentAsset.dateCreated! + 'Z' | date: 'dd/MM/YYYY - HH:mm'}}</span>

                </div>
            </div>
            <div class="content mt-3">
                <p class="text-white fs-11">
                    {{currentAsset!.content!}}
                </p>
            </div>
        </div>

    </div>

</p-sidebar>

<p-sidebar [(visible)]="showComment" position="bottom" (onHide)="onHideComment()" [showCloseIcon]="true"
    [dismissible]="true">
    <div class="p-2 h-100">
        <app-comment-album [commentAsset]="listComments" [asset]="currentAsset" (refresh)="refresh()">
        </app-comment-album>
    </div>
</p-sidebar>


<p-sidebar [(visible)]="showListLike" position="bottom" (onHide)="onHideListLike()" [showCloseIcon]="true"
    [dismissible]="true">
    <div class="p-2 h-100">
        <app-list-like-media-album [albumAssetId]="currentAsset.id!"></app-list-like-media-album>
    </div>
</p-sidebar>