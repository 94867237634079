import { Injectable } from "@angular/core";
import { patch, updateItem } from "@ngxs/store/operators";
import {
  State,
  NgxsOnInit,
  Action,
  StateContext,
  StateToken,
  Selector,
} from "@ngxs/store";
import { Club, ClubResponseWithTotalMemberModel } from "../api/be/models";

const CLUB_STATE_TOKEN = new StateToken<IClubStateModel>("Club");

export class InitClubState {
  static readonly type = "[Club] Init";
  constructor(public payload: ClubResponseWithTotalMemberModel[]) {}
}

export class AddClub {
  static readonly type = "[Club] Add";
  constructor(public payload: Club) {}
}

export class AddClubs {
  static readonly type = "[Club] Add many";
  constructor(public payload: Club[]) {}
}

export class EditClub {
  static readonly type = "[Club] Edit";
  constructor(public payload: ClubResponseWithTotalMemberModel) {}
}

export class FetchAllClubs {
  static readonly type = "[Club] Fetch All";
}

export class GetClub {
  static readonly type = "[Club] Get";
  constructor(public id: string) {}
}

export class DeleteClub {
  static readonly type = "[Club] Delete";
  constructor(public id: string) {}
}

export interface IClubStateModel {
  clubs: ClubResponseWithTotalMemberModel[];
}

@State<IClubStateModel>({
  name: CLUB_STATE_TOKEN,
  defaults: {
    clubs: [],
  },
})
@Injectable({
  providedIn: "root",
})
export class ClubStateService implements NgxsOnInit {
  ngxsOnInit(ctx?: StateContext<IClubStateModel>) {
    ctx?.dispatch(new FetchAllClubs());
  }

  @Action(InitClubState)
  InitClubState(
    ctx: StateContext<IClubStateModel>,
    { payload }: InitClubState
  ) {
    ctx.patchState({
      clubs: payload,
    });
  }

  @Action(AddClub)
  AddClub(ctx: StateContext<IClubStateModel>, { payload }: AddClub) {
    const state = ctx.getState();

    ctx.patchState({
      clubs: [...state.clubs, { ...payload }],
    });
  }

  @Action(AddClubs)
  AddClubs(ctx: StateContext<IClubStateModel>, { payload }: AddClubs) {
    const state = ctx.getState();

    ctx.patchState({
      clubs: [...state.clubs, ...payload],
    });
  }

  @Action(EditClub)
  EditClub(ctx: StateContext<IClubStateModel>, { payload }: EditClub) {
    const state = ctx.getState();
    const index = state.clubs.findIndex((item) => item.id === payload.id);
    ctx.setState(
      patch<IClubStateModel>({
        clubs: updateItem(index, patch({ ...payload })),
      })
    );
  }

  @Action(DeleteClub)
  DeleteClub(ctx: StateContext<IClubStateModel>, { id }: DeleteClub) {
    const state = ctx.getState();
    ctx.patchState({
      clubs: state.clubs.filter((item) => item.id != id) || [],
    });
  }
}
