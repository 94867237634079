import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterListItems" })
export class FilterListItemsPipe implements PipeTransform {
    transform(snapshotArr: any, typeToSearch: string, searchValue: string) {
        if (!snapshotArr) {
            return [];
        }
        if (!searchValue) {
            return snapshotArr;
        }
        if (snapshotArr && searchValue) {
            return snapshotArr.filter((snapshot: any) => {
                return snapshot[`${typeToSearch}`].toLowerCase().includes(searchValue.toLowerCase());
            });
        }
    }
}
