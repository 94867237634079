import { Route, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AccountResponseGeneral } from 'src/app/core/api/be/models';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-card-profile-inliver',
  templateUrl: './card-profile-inliver.component.html',
  styleUrls: ['./card-profile-inliver.component.scss'],
})
export class CardProfileInliverComponent implements OnInit {
  @Input() inliver!: AccountResponseGeneral;
  @Input() addFriendDirectly: boolean = false;

  constructor(public _router: Router) { }

  ngOnInit(): void { }

  handle() {
    // if (this.addFriendDirectly) {
    //   return;
    // }

    this.openProfile();
  }

  openProfile() {
    // if (!this.addFriendDirectly) {
    //   return;
    // }

    LoadingService.$(true);
    this._router.navigate(['account-info'], {
      queryParams: {
        accountId: this.inliver.id!,
      },
    }).then(() => LoadingService.$(false));
  }
}
