import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
    constructor(private _router: Router) {
      this.isMobile()
    }

  public isMobile () {
    let splitRoute = this._router.url.split('/')

    return splitRoute[1] === 'mobile' ? true : false;
  }
}
