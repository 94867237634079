
<div class="w-100">
    <div class="flex justify-content-center">
        <span class="fs-20 text-white">
            {{'LIKED_BY' | translate}}
        </span>
    </div>

    <div *ngIf="listInliversPlus && listInliversPlus.length! > 0">
        <span class="fs-20 text-white">
            {{'INLIVERSPLUS' | translate}}
        </span>
        <div class="mt-3" *ngFor="let item of listInliversPlus">
            <div class="flex align-items-center justify-content-between mt-2 mb-2">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="item?.account!.avatar! ? item?.account!.avatar : defaultAvatar" class="fit-size">
                    </div>
                    <div class="text-white fs-16 ml-3">
                        <span>{{item?.account!.nickName! | limitString:false:40}}</span>
                    </div>
                </div>
                <div *ngIf="item?.account?.id !== currentUser.id!">
                    <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                        (click)="menu.toggle($event)"></i>
                    <p-menu #menu [popup]="true" [model]="initMenu(item)" styleClass="menucus" [appendTo]="'body'">
                    </p-menu>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-3" *ngIf="listInlivers && listInlivers.length! > 0">
        <span class="fs-20 text-white">
            {{'INLIVERS' | translate}}
        </span>
        <div class="mt-3" *ngFor="let item of listInlivers">
            <div class="flex align-items-center justify-content-between mt-2 mb-2">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="item?.account!.avatar! ? item.account?.avatar : defaultAvatar" class="fit-size">
                    </div>
                    <div class="text-white fs-16 ml-3">
                        <span>{{item?.account?.nickName! | limitString:false:40}}</span>
                    </div>
                </div>
                <div *ngIf="item?.account?.id !== currentUser.id!">
                    <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                        (click)="menu.toggle($event)"></i>
                    <p-menu #menu [popup]="true" [model]="initMenu(item)" styleClass="menucus" [appendTo]="'body'">
                    </p-menu>
                </div>
            </div>
        </div>
    </div>
</div>