<p-dialog [(visible)]="showDialog" [draggable]="false" [resizable]="false" [modal]="true" [closable]="false">

    <div class="card flex align-items-center flex-column">

        <div class="flex" style="margin-top: -7rem; position: fixed;">
            <div class="zone-avt">
                <img [src]="imgBlob" class="fit-size">
            </div>
        </div>

        <div class="label mt-5">
            {{name}}
        </div>

        <div class="text mt-5" style="width: 76%;" [innerHTML]="'CLUB_REQUEST_PENDING' | translate"></div>

        <div class="flex justify-content-center mt-5">
            <button pButton type="submit" label="Ok" (click)="close()"
                class="mt-5 p-button-outlined p-button-secondary"></button>
        </div>
    </div>
</p-dialog>

