import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-confirm-create-stage',
  templateUrl: './mobile-confirm-create-stage.component.html',
  styleUrls: ['./mobile-confirm-create-stage.component.scss']
})
export class MobileConfirmCreateStageComponent implements OnInit {

  @Input() showPopupConfirm: boolean = false;
  @Input() imgBlob:any
  @Input() name!: string;

  @Output() showPopupConfirmChange = new EventEmitter()
  @Output() doAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closePopup(){
    this.showPopupConfirmChange.emit(false);
  }

}
