import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountResponseWithRoleInCLubModel } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-list-inlivers-goinlive',
  templateUrl: './list-inlivers-goinlive.component.html',
  styleUrls: ['./list-inlivers-goinlive.component.scss']
})
export class ListInliversGoinliveComponent implements OnInit {

  @Input() openPopup!:boolean
  @Input() listInliverPlus:AccountResponseWithRoleInCLubModel[] = [];
  @Input() listInliver:AccountResponseWithRoleInCLubModel[] = [];
  @Input() selectedInlivers:AccountResponseWithRoleInCLubModel[] = [];
  @Input() title!:string
  @Input() showMorderation:boolean = false;

  @Output() openPopupChange = new EventEmitter();
  @Output() selectedInliversChange = new EventEmitter<AccountResponseWithRoleInCLubModel[]>();

  isSelectAll:boolean = false;
  defaultAvatar = defaultAvatar

  searchText!:string
  

  constructor() { }

  ngOnInit(): void {
  }

  hidePopup(){
    this.openPopupChange.emit(false);
  }

  pickAll(){
    this.isSelectAll = !this.isSelectAll;
    if(this.isSelectAll){
      this.selectedInlivers = [...this.listInliverPlus];
    }else{
      this.selectedInlivers = [];
    }
  }

  valider(){
    this.selectedInliversChange.emit(this.selectedInlivers);
    this.hidePopup();
  }

}
