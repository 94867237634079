import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, takeLast, tap } from 'rxjs/operators';
import {
  AccountResponseGeneral,
  AccountResponseGeneralPaginationModelResponseModel,
  AccountWithRelationshipResponseModel,
} from 'src/app/core/api/be/models';
import { FriendRelationService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-my-friend-message',
  templateUrl: './list-my-friend-message.component.html',
  styleUrls: ['./list-my-friend-message.component.scss'],
})
export class ListMyFriendMessageComponent implements OnInit {
  @Input() openListMyFriend = false;

  @Output() openListMyFriendChange = new EventEmitter();
  @Output() selectedValues = new EventEmitter();

  listFriends: AccountWithRelationshipResponseModel[] = [];
  searchText: string = '';
  defaultAvatar = defaultAvatar;

  selectedFriends: AccountWithRelationshipResponseModel[] = [];

  constructor(private _friendService: FriendRelationService) { }

  ngOnInit(): void {
    this.getListFriend();
  }

  getListFriend(): void {
    this._friendService
      .inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000000,
        nickName: this.searchText
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listFriends = res.data?.page!.sort(function (a, b) {
              if (a.nickName?.toLowerCase()! < b.nickName!.toLowerCase()) {
                return -1;
              }
              if (a.nickName!.toLowerCase() > b.nickName!.toLowerCase()) {
                return 1;
              }
              return 0;
            })!;
          }
        })
      )
      .subscribe();

    // this._friendService
    //   .inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json({
    //     accountRelatingId: AuthService.GetAccountInfo().id!,
    //     limit: 10000,
    //   })
    //   .pipe(
    //     tap((res) => {
    //       if (res.res == 0) {
    //         this.listFriends = res.data?.page!.sort(function (a, b) {
    //           if (a.nickName?.toLowerCase()! < b.nickName!.toLowerCase()) {
    //             return -1;
    //           }
    //           if (a.nickName!.toLowerCase() > b.nickName!.toLowerCase()) {
    //             return 1;
    //           }
    //           return 0;
    //         })!;
    //       }
    //     })
    //   )
    //   .subscribe();
  }

  onHide() {
    this.openListMyFriendChange.emit(false);
  }

  valider() {
    this.selectedValues.emit(this.selectedFriends);
    this.onHide();
  }

  removeSelected(data: AccountResponseGeneral) {
    this.selectedFriends = this.selectedFriends.filter(
      (x) => x.id! !== data.id
    );
  }
}
