<div *ngIf="!isMobile" class="w-100 m-2 zone-club">
    <div class="flex justify-content-between align-items-center">
        <div class="flex align-items-center">
            <div class="zone-avatar">
                <img [src]="account!.avatar! ? (account.avatar | fetchUrlImage) : defaultAvatar" class="fit-size cursor-pointer"
                    (click)="accountInfo()">
            </div>
            <div class="zone-name ml-3 flex flex-column align-items-start">
                <div class="flex align-items-center">
                    <span class="text-white fs-16 mr-3">{{account!.nickName! | limitString:false:40}}</span>
                    <div class="zone-role">

                    </div>
                </div>
                <div class="text-disabled mt-2 fs-14">

                </div>
            </div>
        </div>
        <div>
            <button *ngIf="!account.isFriend && !account.isAddFriend && (accountOwnId !== account.id)" pButton
                type="submit" label="{{'ADD_FRIEND' | translate}}" class="mt-5 p-button-outlined p-button-secondary"
                (click)="openConfirmFriend = true"></button>
            <button *ngIf="account.isFriend" pButton label="{{'WAS_FRIEND' | translate}}" [ngClass]="'was-friend'" [disabled]="account.isFriend"
                class="mt-5 p-button-outlined p-button-secondary"></button>
            <div class="flex text-white" *ngIf="!account.isFriend && account.isPrivate && account.isAddFriend">
                <img src="../../../assets/images/svgs/pending.svg">
                <p>
                    {{'REQUEST_PENDING' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="!account.isFriend && openConfirmFriend">
    <app-card-account-friend-confirm [isMobile]="isMobile" [account]="account" [isFriend]="false"
        [(isShowPopup)]="openConfirmFriend" [firstButtonTitle]="'NO' | translate"
        [secondButtonTitle]="'YES' | translate" (doAction)="doAction()"></app-card-account-friend-confirm>
</ng-container>