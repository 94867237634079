import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-resetpass-success',
  templateUrl: './resetpass-success.component.html',
  styleUrls: ['./resetpass-success.component.scss']
})
export class ResetpassSuccessComponent implements OnInit {
  @Output() login = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
