import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/api/be/services';
import { EmailServiceCustom } from 'src/app/core/services/email.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LoginService } from 'src/app/core/services/login.service';
import { RegisterService } from 'src/app/core/services/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Input() showDialogRegister!: boolean;
  @Output() showDialogRegisterChange = new EventEmitter<boolean>();

  fieldTextType: boolean = false;
  inputType: string = 'password';

  fieldTextTypeForConfirm: boolean = false;
  inputTypeForConfirm: string = 'password';

  day!: number;
  month!: number;
  year!: number;
  checkAgeValid: boolean = true;
  checkAgeFormat: boolean = true;

  email!: string;
  password!: string;
  confirmPassword!: string;
  dataUrlMail!: string;

  isValidPassword!: boolean;

  acceptReceiveMail: boolean = false;
  acceptCondition: boolean = false;

  statusRegister: string = 'INIT';

  checkEmailExist!: boolean;
  checkEmail!: boolean;
  tempEmail: string = '';
  emailConfirmed!: boolean;
  emailIsExitsIdentity: boolean = false;

  constructor(
    private _accountService: AccountService
  ) { }

  ngOnInit(): void { }

  register() {
    LoadingService.$(true);

    let monthOfBirth: string = "";
    let dayOfBirth: string = "";

    if (this.month < 10) {
      monthOfBirth = "0" + this.month;
    }else{
      monthOfBirth = this.month.toString();
    }
    if (this.day < 10) {
      dayOfBirth = "0" + this.day;
    }else{
      dayOfBirth = this.day.toString();
    }

    let birthday = dayOfBirth + "/" + monthOfBirth + "/" + this.year;
    
    if (!this.email || !this.password || !this.confirmPassword || !this.acceptCondition || !birthday || this.checkEmailExist) {
      LoadingService.$(false);
      return;
    }

    if (this.dateIsValid(birthday) == 1) {
      LoadingService.$(false);
      this.checkAgeValid = false;
      this.checkAgeFormat = true;
      return;
    } else if (this.dateIsValid(birthday) == 0) {
      LoadingService.$(false);
      this.checkAgeFormat = false;
      this.checkAgeValid = true;
      return;
    } else if (this.dateIsValid(birthday) == 2) {
      LoadingService.$(false);
      this.checkAgeFormat = true;
      this.checkAgeValid = true;
    }

    if ((this.emailConfirmed || (!this.emailConfirmed && this.emailIsExitsIdentity)) && !this.checkEmailExist) {
      IdentityService.ReSendConfirmEmail(this.email).pipe(
        tap(res => {
          if (res.res == 0) {
            this.dataUrlMail = res.data;
          }
        }),
        tap(() => this.statusRegister = 'SENDMAIL_SUCCESS'),
        mergeMap(() => EmailServiceCustom.SendEmailActiveAccount(this.email, this.dataUrlMail))
      ).subscribe();
    } else {

      let body = {
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        birthday: new Date(this.getTimeStamp(birthday))?.toISOString()!
      }
      LoadingService.$(true);
      IdentityService.RegisterAccount(body).pipe(
        tap((res) => {
          console.log('RegisterAccount:', res);
        }),
        mergeMap((res) => {
          if (res.res == 0) {
            return EmailServiceCustom.SendEmailActiveAccount(this.email!, res?.data?.confirmEmailUrl!)
          } else {
            return of()
          }
        })
      ).subscribe((res: any) => {
        if (res.res == 0) {
          this.statusRegister = 'SENDMAIL_SUCCESS';
        }

      }, () => { }, () => { LoadingService.$(false) });
    }
    LoadingService.$(false);
  }

  login() {
    RegisterService.$(false);
    LoginService.$(true);
  }

  onHide() {
    this.showDialogRegisterChange.emit(this.showDialogRegister);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType == true) {
      this.inputType = 'text'
    } else {
      this.inputType = 'password'
    }
  }

  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeForConfirm = !this.fieldTextTypeForConfirm;
    if (this.fieldTextTypeForConfirm == true) {
      this.inputTypeForConfirm = 'text'
    } else {
      this.inputTypeForConfirm = 'password'
    }
  }

  checkPassWord() {
    this.CheckLeastOneLowerCase();
    this.CheckLeastOneNumber();
    this.CheckLeastOneUpperCase();
    this.CheckLength();

    let list = [this.CheckLeastOneLowerCase(), this.CheckLeastOneNumber(), this.CheckLeastOneUpperCase(), this.CheckLength()];
    this.isValidPassword = _.union(list).length == 1 && _.union(list)[0] == true && this.password === this.confirmPassword;
    console.log(this.isValidPassword);

  }

  CheckLeastOneLowerCase() {
    const PWD_REGEX = new RegExp(/^(?=.*[a-z]).*$/);
    const password: string = this.password!;
    const result = password?.match(PWD_REGEX);
    return result != null;
  }

  CheckLeastOneUpperCase() {
    const PWD_REGEX = new RegExp(/^(?=.*[A-Z]).*$/);
    const password: string = this.password!;
    const result = password?.match(PWD_REGEX);
    return result != null;
  }

  CheckLeastOneNumber() {
    const PWD_REGEX = new RegExp(/^(?=.*[0-9]).*$/);
    const password: string = this.password!;
    const result = password?.match(PWD_REGEX);
    return result != null;
  }

  CheckLeastOneSpecialCharacter() {
    const PWD_REGEX = new RegExp(/^(?=.*?[!@#\$&*~]).*$/);
    const password: string = this.password!;
    const result = password?.match(PWD_REGEX);
    return result != null;
  }

  CheckLength() {
    const password: string = this.password!;
    return password?.length! >= 8;
  }

  checkEmailExistRegister(event: any) {
    if (!event.target.value!.trim()) {
      this.checkEmailExist = false;
      this.checkEmail = true;
      return;
    }
    else if (this.tempEmail?.trim() === event.target.value!.trim()) return;
    this.checkEmailConfirmed();
    this._accountService.inlivewithAccountByEmailEmailGet$Json({ email: `"${event.target.value!?.trim()}"`, details: false }).pipe(
      debounceTime(1000),
      tap(() => this.tempEmail = event.target.value!),
      tap(data => {
        if (data.data !== null) {
          this.checkEmailExist = true;
          this.checkEmail = true
        } else {
          this.checkEmailExist = false;
          this.checkEmail = true
        }
      })
    ).subscribe()
  }

  checkEmailConfirmed() {
    IdentityService.GetAccountByEmail(this.email).pipe(
      debounceTime(1000),
      tap(res => {
        // if (data.data !== null && !data.data.emailConfirmed) {
        //   this.emailConfirmed = true;
        //   this.checkEmail = true
        // } else if (data.data !== null && data.data.emailConfirmed) {
        //   this.emailConfirmed = false;
        //   this.checkEmail = true
        // } else {
        //   this.checkEmail = true;
        // }

        if (res.res! >= 0) {
          this.emailIsExitsIdentity = true;
          if (res.data.emailConfirmed) {
            this.emailConfirmed = true;
          } else {
            this.emailConfirmed = false;
            this.checkEmail = true;
          }
        } else {
          this.emailIsExitsIdentity = false;
          this.emailConfirmed = false;
        }
      })
    ).subscribe()
  }

  dateIsValid(dateStr: string) {
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    if (dateStr.match(regex) === null) {
      return 0;
    }

    let timestamp = this.getTimeStamp(dateStr);
    let idx = new Date().getFullYear() - new Date(timestamp).getFullYear();

    if (idx <= 13) {
      return 1;
    }

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return 0;
    }

    return 2;
  }

  getTimeStamp(dateString: string) {
    const [day, month, year] = dateString.split('/');
    console.log(day,month,year);
    
    const isoFormattedStr = `${year}-${month}-${day}`;
    const date = new Date(isoFormattedStr);
    const timestamp = date.getTime();

    return timestamp;
  }

}
