import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs/operators';
import {
  Club,
  GoInliveResponseModelWithClubDetail,
} from 'src/app/core/api/be/models';
import { ClubService, GoInliveService } from 'src/app/core/api/be/services';
import { ILWAppEnum } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-goinlive',
  templateUrl: './card-goinlive.component.html',
  styleUrls: ['./card-goinlive.component.scss'],
})
export class CardGoinliveComponent implements OnInit {
  @Input() live!: GoInliveResponseModelWithClubDetail;
  isShowReviewCamera: boolean = false;
  showListClubs = false;
  constructor(
    private _router: Router,
    private _goInliveService: GoInliveService
  ) {}

  ngOnInit(): void {}

  getIcon() {
    let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter(
      (c: any) => c.value == this.live?.clubs![0]?.category
    )[0]?.url!;
    return iconUrl;
  }

  checkGoInlive() {
    console.log(this.live);
    if (this.live!.isFromCelebrity) {
      this.showListClubs = true;
      return;
    }

    if (this.live.isPrivate) {
      this.isShowReviewCamera = true;
    } else {
      this.goToViewGoInlive();
    }
  }

  join() {
    this._goInliveService
      .inlivewithGoInliveGetInvitationByGoInliveIdGet$Json({
        goInliveId: this.live.id!,
      })
      .pipe(
        map((res) => {
          return res.data?.page?.filter(
            (data) => data.accountId! == AuthService.GetAccountInfo().id!
          );
        }),
        mergeMap((res) =>
          forkJoin(res?.map((d) => this.handleInvite(true, d.id!)))
        )
      )
      .subscribe((res) => {
        this.goToViewGoInlive();
      });
  }

  handleInvite(isAccept: boolean, invitationId: string) {
    let body = {
      invitationId,
      accountId: AuthService.GetAccountInfo().id!,
      isAccept: isAccept,
      isDeny: !isAccept,
    };

    return this._goInliveService.inlivewithGoInliveSelectGoInlivedInvitePut$Json(
      {
        body,
      }
    );
  }

  goToViewGoInlive() {
    if (!this.live!.clubs![0]!.isUserJoinClub!) {
      this.gotoClubDetail(this.live!.clubs![0].id);
      return;
    }

    this._router.navigate(['go-inlive-view'], {
      queryParams: {
        goInliveId: this.live?.id,
        channel: this.live?.channelName,
        hostUid: this.live?.author?.agoraUid!,
        mode: this.live.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
        type: this.live.type!,
      },
    });
  }

  gotoClubDetail(clubId?: string) {
    let url = `/go-inlive-view?goInliveId=${this.live?.id}&channel=${
      this.live.channelName
    }&hostUid=${this.live?.author?.agoraUid!}&mode=${
      this.live.type! == 'GOINLIVE_CLASSIC' ? 'live' : null
    }&mode=${this.live.type}`;

    this._router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: clubId },
      state: { redirect: url },
    });
  }
}
