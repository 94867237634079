/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LocalResultModelResponseModel } from '../models/local-result-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
@Injectable({
  providedIn: 'root',
})
class UploadService extends __BaseService {
  static readonly postUploadLocalPath = '/Upload/Local';
  static readonly postUploadMediaPath = '/Upload/Media';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UploadService.PostUploadLocalParams` containing the following parameters:
   *
   * - `FileName`:
   *
   * - `File`:
   *
   * @return Success
   */
  postUploadLocalResponse(params: UploadService.PostUploadLocalParams): __Observable<__StrictHttpResponse<LocalResultModelResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.FileName != null) { __formData.append('FileName', params.FileName as string | Blob);}
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Upload/Local`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocalResultModelResponseModel>;
      })
    );
  }
  /**
   * @param params The `UploadService.PostUploadLocalParams` containing the following parameters:
   *
   * - `FileName`:
   *
   * - `File`:
   *
   * @return Success
   */
  postUploadLocal(params: UploadService.PostUploadLocalParams): __Observable<LocalResultModelResponseModel> {
    return this.postUploadLocalResponse(params).pipe(
      __map(_r => _r.body as LocalResultModelResponseModel)
    );
  }

  /**
   * @param params The `UploadService.PostUploadMediaParams` containing the following parameters:
   *
   * - `ServiceId`:
   *
   * - `Path`:
   *
   * - `Id`:
   *
   * - `Hook`:
   *
   * - `File`:
   *
   * - `CustomLength`:
   *
   * @return Success
   */
  postUploadMediaResponse(params: UploadService.PostUploadMediaParams): __Observable<__StrictHttpResponse<BooleanResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.ServiceId != null) { __formData.append('ServiceId', params.ServiceId as string | Blob);}
    if (params.Path != null) { __formData.append('Path', params.Path as string | Blob);}
    if (params.Id != null) { __formData.append('Id', params.Id as string | Blob);}
    if (params.Hook != null) { __formData.append('Hook', params.Hook as string | Blob);}
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.CustomLength != null) { __formData.append('CustomLength', JSON.stringify(params.CustomLength));}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Upload/Media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
        reportProgress: true,
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }
  /**
   * @param params The `UploadService.PostUploadMediaParams` containing the following parameters:
   *
   * - `ServiceId`:
   *
   * - `Path`:
   *
   * - `Id`:
   *
   * - `Hook`:
   *
   * - `File`:
   *
   * - `CustomLength`:
   *
   * @return Success
   */
  postUploadMedia(params: UploadService.PostUploadMediaParams): __Observable<BooleanResponseModel> {
    return this.postUploadMediaResponse(params).pipe(
      __map(_r => _r.body as BooleanResponseModel)
    );
  }
}

module UploadService {

  /**
   * Parameters for postUploadLocal
   */
  export interface PostUploadLocalParams {
    FileName?: string;
    File?: Blob;
  }

  /**
   * Parameters for postUploadMedia
   */
  export interface PostUploadMediaParams {
    ServiceId?: string;
    Path?: string;
    Id?: string;
    Hook?: string;
    File?: Blob;
    CustomLength?: number;
  }
}

export { UploadService }
