/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GendersEnumPaginationModelResponseModel } from '../models/genders-enum-pagination-model-response-model';
import { GendersEnumResponseModel } from '../models/genders-enum-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppEnumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAppEnumGendersGet
   */
  static readonly InlivewithAppEnumGendersGetPath = '/inlivewith/AppEnum/Genders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGendersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GendersEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGendersGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGendersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<GendersEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumGendersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumPaginationModelResponseModel>) => r.body as GendersEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGendersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GendersEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGendersGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGendersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<GendersEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumGendersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumPaginationModelResponseModel>) => r.body as GendersEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumGetGenderByNameNameGet
   */
  static readonly InlivewithAppEnumGetGenderByNameNameGetPath = '/inlivewith/AppEnum/GetGenderByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGetGenderByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGetGenderByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGetGenderByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByNameNameGet$Plain(params: {
    name: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGetGenderByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGetGenderByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGetGenderByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGetGenderByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByNameNameGet$Json(params: {
    name: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGetGenderByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumGetGenderByValueValueGet
   */
  static readonly InlivewithAppEnumGetGenderByValueValueGetPath = '/inlivewith/AppEnum/GetGenderByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGetGenderByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGetGenderByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGetGenderByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByValueValueGet$Plain(params: {
    value: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGetGenderByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGetGenderByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGetGenderByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGetGenderByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGetGenderByValueValueGet$Json(params: {
    value: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGetGenderByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

}
