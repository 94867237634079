<p-dialog [(visible)]="openPopup" [modal]="true" [draggable]="false" [modal]="true" (onHide)="hidePopup()">
    <div class="card">
        <div class="flex align-items-center justify-content-center text-white">
            <span class="label text-center fs-22">{{title}}</span>
        </div>
        <span class="fs-16 mt-3">
            {{'INLIVER_GOINLIVE_TITLE' | translate}}
        </span>
        <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%;position: relative;">
            <i class="pi pi-search icon-search"></i>
            <input style="width:100%" type="text" pInputText [(ngModel)]="searchText"
                placeholder="{{'SEARCH_INLIVER' | translate}}" />
        </div>
        <div class="inliver-plus" *ngIf="listInliverPlus?.length! > 0">
            <!-- <div class="title">
                <span class="label fs-20 text-white">{{'INLIVERSPLUS' | translate}} ({{listInliverPlus?.length}})</span>
            </div> -->
            <div class="list-inliverplus" *ngIf="listInliverPlus.length > 0">
                <!-- <div class="mb-4">
                    <p-checkbox inputId="forall" [binary]="true" (onChange)="pickAll()"
                    ></p-checkbox>
                    <label class="ml-4 label fs-18" for="forall">
                        {{'SELECT_ALL' | translate}}
                    </label>
                </div> -->
                <div class="flex align-items-center mb-2" *ngFor="let inliver of listInliverPlus| filter:searchText">
                    <p-checkbox name="group2" value="inliver" [value]="inliver" [(ngModel)]="selectedInlivers"
                        [inputId]="inliver?.id!"
                    ></p-checkbox>
                    <img class="avatar ml-4 mr-1" [src]="inliver?.avatar! | fetchUrlImage">
                    <label class="ml-2 label" [for]="inliver.id!">{{inliver.nickName! | limitString:false:40}}</label>
                </div>
            </div>
        </div>
        <!-- <div class="inliver-normal" *ngIf="listInliver?.length! > 0">
            <div class="title">
                <span class="label fs-20 text-white">{{'INLIVERS' | translate}} ({{listInliver?.length}})</span>
            </div>
            <div class="list-inliverplus" *ngIf="listInliver.length > 0">
                <div class="flex align-items-center mt-3 mb-3" *ngFor="let inliver of listInliver">
                    <p-checkbox name="group2" value="inliver" [value]="inliver" [(ngModel)]="selectedInlivers"
                        [inputId]="inliver?.id!"
                    ></p-checkbox>
                    <img class="avatar ml-4 mr-1" [src]="inliver?.avatar! | fetchUrlImage">
                    <label class="ml-2 label text-white" [for]="inliver.id!">{{inliver.nickName}}</label>
                </div>
            </div>
        </div> -->
        <div class="flex pt-3 pb-3 justify-content-center">
            <button pButton type="submit" label="{{'VALID' | translate}}" (click)="valider()"
                class="mt-5 p-button-outlined p-button-secondary label">
            </button>
        </div>
    </div>
</p-dialog>
