import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-join-club-success',
  templateUrl: './card-join-club-success.component.html',
  styleUrls: ['./card-join-club-success.component.scss']
})
export class CardJoinClubSuccessComponent implements OnInit {

  showDialog = true;

  @Input() name!: string;
  @Input() imgBlob!: string | ArrayBuffer | null;

  constructor() { }

  ngOnInit(): void {
    var reader = new FileReader();
    reader.onload = (_event) => {
      this.imgBlob = reader.result;
    }
  }

  close() {
    this.showDialog = false;
  }

}
