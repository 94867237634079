import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  Renderer2,
  AfterViewInit,
  ViewChildren,
  QueryList,
  SecurityContext,
} from '@angular/core';
import {
  PostResponseModel,
  CommentPostResponseModel,
  AccountResponseGeneral,
  MediaCommentPostResponseModel,
  AccountResponseInPost,
  MediaPostResponseModel,
  CommentInputEditContentModel,
  MentionReceiver,
  CommentPostResponseModelResponseModel,
  MediaCreateResponseModel,
  CreateCommentPostResponseModelResponseModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  ClubService,
  MentionService,
  PostService,
} from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import * as moment from 'moment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Observable, Subject, Subscription, forkJoin, from } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import {
  EEvent,
  EventService,
  IEvent,
} from 'src/app/core/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  GROUP_ILW_DISCUSSION,
  MENTION_REGEX,
  TYPE_MENTION,
  URL_REGEX,
  base64ToBlob,
  getFileExtension,
} from 'src/app/core/constant';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItem, MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/core/services/loading.service';
import _ from 'lodash';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { NgWaveformComponent } from 'ng-waveform';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-comment-post-discussion',
  templateUrl: './comment-post-discussion.component.html',
  styleUrls: ['./comment-post-discussion.component.scss'],
})
export class CommentPostDiscussionComponent implements OnInit, OnDestroy {
  isAdmin!: boolean;
  isJoinedClub!: boolean;
  isCommenttingReply: boolean = false;
  is4max = false;
  items!: MenuItem[];

  private _post: PostResponseModel | null = null;
  focusMentionList = false;
  openPopupReport = false;
  openPopupReportPost = false;
  closeByManual = false;
  openPopupEditTitle = false;
  isOverflowChracters = false;
  sendMsg = false;
  tempTitleEdit = '';
  roleOfCurrentUser!: string;
  linkForCopy: any;
  intervalDetectFile!: NodeJS.Timeout;

  isChrome =
    navigator.userAgent.toLowerCase().includes('chrome') &&
    !navigator.userAgent.toLowerCase().includes('edg');

  @Input() set post(value: PostResponseModel | null) {
    this._post = value;
    if (value) {
      this.checkJoinedClub().subscribe();
      this.isAdmin = value.author?.id == AuthService.GetAccountInfo().id;
      this.initMenu();

      if (this._post) {
        this.notiService.leaveGroup(this._post?.id!);
      }
      this.comments = [];
      this.nameColors = [];
      this.page = 1;
      this.getComments(20).subscribe(() => {
        this.scrollToBottom();
        this.page++;
      });
      this.show();
      if (value.isDiscussing) {
        this.notiService.joinGroup(value.id!);
        this.timeRemain();
      } else {
        let currentLang =
          LocalStorageService.GetLocalStorage('language').toLowerCase();
        this.timeEnd = moment
          .utc(value.dateEnd)
          .locale(currentLang)
          .format('ll');
      }
      this.cdf.detectChanges();
      this.initInputCustom();
      this.getListInlivers();
    }
  }
  get post() {
    return this._post;
  }

  comments: CommentPostResponseModel[] = [];

  account: AccountResponseGeneral;
  page = 1;
  duration!: moment.Duration;
  countDown!: NodeJS.Timeout;
  timeEnd = '';
  content: string = '';
  newCommentId = '';
  urlAudio!: string;
  handlingAfterGetData$ = new Subject();
  isLoading = false;
  isDone = false;
  countUserTyping = 0;
  checkCountTyped = 0;

  nameColors: { authorId: string; color: string }[] = [];

  blobFileAudio!: Blob;
  urls: any[] = [];
  filePosts: any[] = [];

  listMention: any[] = [];
  tribute!: any;
  mentionConfig: any = {};
  listMember: AccountResponseInPost[] = [];
  currentComment!: CommentPostResponseModel;
  isEditComment = false;

  isShowMultiMedia = false;
  isShowPopupConfirm = false;
  multiMedias: MediaCommentPostResponseModel[] = [];
  activeIndex = 0;

  readonly defaultAvatar = '/assets/images/svgs/avatar-default.svg';
  private readonly eventFilter = [
    EEvent.OPEN_POST,
    EEvent.HIDDEN_POST,
    EEvent.DELETE_POST,
    EEvent.END_POST,
    EEvent.CREATE_COMMENT,
    EEvent.DELETE_COMMENT,
  ];
  private readonly minColor = 0.3;
  private readonly maxColor = 255;

  @ViewChildren('waveform') waveform!: QueryList<NgWaveformComponent>;

  @ViewChild('elCommentsPostDiscussion')
  elComments!: ElementRef<HTMLDivElement>;
  @ViewChild('inputCustomContent') input!: ElementRef<HTMLDivElement>;
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('toggleEmoji') toggleEmoji!: ElementRef;

  showEmoji = false;
  openPopupRecordAudio = false;

  subscription = new Subscription();
  destroy$ = new Subject<any>();

  refetchWhenReceiveNoti$ = this.notiService.isReloadComment$$.pipe(
    takeUntil(this.destroy$),
    tap((res: any) => {
      if (!res || !this.post) {
        return;
      }
      let postId = JSON.parse(JSON.parse(res?.content!).Content!).PostId!;
      let commentId = JSON.parse(JSON.parse(res?.content!).Content!).CommentId!;
      if (postId == this.post!.id!) {
        // console.log('get api fetch data');
        this.getCommentByCommentId(commentId);
      }
    })
  );

  refetchWhenReceiveNotiFile$ =
    this.notiService.isReloadCommentFileProcess$$.pipe(
      tap((res: any) => {
        if (!res || !this.post) {
          return;
        }

        let postId = JSON.parse(res?.content!).Content!.split(',')![1];
        let commentId = JSON.parse(res?.content!).Content!.split(',')![0];

        if (postId == this.post!.id! && commentId) {
          this.getCommentByCommentId(commentId);
        }
      })
    );

  showConfirmDeletePost = false;
  showConfirmEndDiscussion = false;

  authId = AuthService.GetAccountInfo().id!;

  @Output() deletePostEvent = new EventEmitter<string>();
  @Output() askJoinClub = new EventEmitter();

  listAudioFiles: any[] = [];

  constructor(
    private el: ElementRef<HTMLElement>,
    private postService: PostService,
    private notiService: NotificationServicee,
    private eventService: EventService,
    private _translateService: TranslateService,
    private cdf: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private _messageService: MessageService,
    private clipboardService: ClipboardService,
    private _clubService: ClubService,
    private renderer: Renderer2,
    public _router: Router,
    public _firebaseService: FirebaseService,
    public _accountService: AccountService,
    public sanitizer: DomSanitizer,
    private _mentionService: MentionService,
    private _uploadService: UploadService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleEmoji!.nativeElement!) {
        this.showEmoji = false;
      }
    });
    this.account = AuthService.GetAccountInfo();
    this.eventService
      .receive()
      .pipe(
        filter(
          (p: IEvent) => this.eventFilter.findIndex((e) => e == p.action) >= 0
        )
      )
      .subscribe((data: IEvent) => {
        if (data.action === EEvent.OPEN_POST) {
          if (this.post! != data.payload) {
            this.post! = data.payload as PostResponseModel;
          }
        } else if (data.action === EEvent.CREATE_COMMENT) {
          if (this.post!) {
            this.post!!.totalMemberDiscussion = [
              ...new Set(this.comments.map((c) => c.authorId)),
            ].length;
            if (this.post!!.totalMemberDiscussion !== this.listMember.length!) {
              this.listMember = [];
              this.getListInlivers();
            }
          }
        } else if (data.action === EEvent.END_POST) {
          this.post! = data.payload.post;
          this.close();
        } else {
          const post = data.payload as PostResponseModel;
          if (post.id === this.post!?.id) this.close();
        }
      });

    this._router.events.subscribe((res) => {
      this.post ? this.initMenu() : '';
    });
  }

  private generateRandomColorRgb() {
    // const red = Math.floor((Math.random() + this.minColor) * this.maxColor);
    // const green = Math.floor((Math.random() + this.minColor) * this.maxColor);
    // const blue = Math.floor((Math.random() + this.minColor) * this.maxColor);
    return 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
  }

  initInputCustom(): void {
    if (this.input) {
      const ele = this.input.nativeElement;
      ele.setAttribute(
        'data-placeholder',
        this._translateService.instant('SEND_A_MESSAGE')
      );
      let placeholder = ele.getAttribute('data-placeholder');
      if (placeholder) {
        ele.innerHTML === '' && (ele.innerHTML = placeholder);

        ele.addEventListener('focus', (e: any) => {
          const value = e.target.innerHTML;
          value === placeholder && (e.target.innerHTML = '');
        });

        ele.addEventListener('blur', (e: any) => {
          const value = e.target.innerHTML;
          value === '' && (e.target.innerHTML = placeholder);
        });
      }
    }
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }


  getListInlivers() {
    return this.postService
      .inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json({
        postId: this.post?.id!,
        stageId: this.post?.stageId!,
        limit: 100000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listMember = res.data?.page!;
            this.post!!.totalMemberDiscussion! = res.data?.totalItems!;
            this.listMember.forEach((data) => {
              if (data.id == AuthService.GetAccountInfo().id) {
                return;
              }

              if (data.accessLevelInClub == '') {
                return;
              }

              this.listMention.push({
                username: data.nickName?.trimStart(),
                name: data.nickName?.trimStart(),
                img: data.avatar,
                id: data.id,
              });
            });

            this.initConfigMention();
          }
        })
      )
      .subscribe();
  }

  urlify(text: any) {
    let responseTextFR = 'Réponse à';

    text = text?.replace(responseTextFR, (response: any) => {
      return `<span>${this._translateService.instant(
        'COMMENT_REPLY_PLACEHOLDER'
      )}</span>`;
    });

    let responseText = '🔥 COMMENT_REPLY_PLACEHOLDER';

    text = text?.replace(responseText, (response: any) => {
      return `<span>${this._translateService.instant(
        'COMMENT_REPLY_PLACEHOLDER'
      )}</span>`;
    });

    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(
        url
      )}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName!.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      if (user.isBannedByClub) {
        return `<a style="pointer-events: none;">${this._translateService.instant('INLIVER_UNKNOWN')}</a>`;
      }
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username.replace('@', '').replaceAll('_', '')}" target="_blank">${username}</a>`;
    }
  }

  inputEvent(event: any) {
    // if (event.target.textContent.trim().length == 0) {
    //   event.target.textContent = '';
    // }
    this.content = event.target.textContent;
  }

  getNumberWithZero(num: number) {
    return num >= 10 ? num.toString() : '0' + num;
  }

  timeRemain() {
    if (this.post!) {
      const eventTime: number = moment.utc(this.post!.dateEnd).unix();
      const currentTime: number = moment.utc().unix();
      var diffTime = eventTime - currentTime;
      this.duration = moment.duration(diffTime * 1000, 'milliseconds');
      var interval = 1000;
      clearInterval(this.countDown);
      this.countDown = setInterval(() => {
        this.duration = moment.duration(
          this.duration.asMilliseconds() - interval,
          'milliseconds'
        );
        this.handleCountDownDone();
      }, 1000);
    }
  }

  onScroll() {
    this.getComments().subscribe(() => this.handlingAfterGetData$.next());
  }

  getComments(limit: number = 10) {
    this.isLoading = true;
    return this.postService
      .inlivewithPostGetCommentsOnPostPostIdGet$Json({
        postId: this.post!?.id!,
        stageId: this.post?.stageId!,
        limit: limit,
        page: this.page++,
        isDiscusstion: true
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.comments = [...this.comments, ...(res.data?.page ?? [])];
            //this.comments = _.unionBy(this.comments, 'id');
            this.currentComment = {};
            this.isCommenttingReply = false;
            if (res.data?.next == -1) {
              this.isDone = true;
            }
            this.cdf.detectChanges();
            this.makeColor(res.data?.page ?? []);

            // this.test();
          }
          this.isLoading = false;
        })
      );
  }

  makeColor(comments: CommentPostResponseModel[]) {
    console.log('makeColor');
    let t = document.getElementById('textarea-cus');
    t!.style.height = '44px';

    for (let index = 0; index < comments.length!; index++) {
      if (
        this.nameColors.findIndex(
          (n) => n.authorId === comments![index]!.authorId!
        ) < 0
      ) {
        this.nameColors.push({
          authorId: comments[index].authorId!,
          color: this.generateRandomColorRgb(),
        });
      }
    }
  }

  findColor(id: string) {
    if (!this.nameColors.find((c) => c.authorId === id)) {
      var obj = {
        authorId: id,
        color: this.generateRandomColorRgb(),
      };
      this.nameColors.push(obj);
    }
    return this.nameColors.find((c) => c.authorId === id)?.color ?? obj!.color;
  }

  private handleCountDownDone() {
    if (
      this.duration.hours() <= 0 &&
      this.duration.minutes() <= 0 &&
      this.duration.seconds() <= 0 &&
      this.duration.milliseconds() <= 0
    ) {
      this.endPostDiscussion();
    }
  }

  private endPostDiscussion() {
    this.postService
      .inlivewithPostEndPostDiscusionPostIdPut$Json({ postId: this.post!?.id! })
      .subscribe((res) => {
        if (res.res == 0 && this.post!) {
          this.post!.isDiscussing = false;
        }
      });
  }

  isTyping(value: boolean) {
    this.notiService.isTyping(this.post!?.id!, this.account.id!, value);
  }

  checkIsOpenMentionList(isOpen: boolean) {
    if (isOpen) this.focusMentionList = true;
    else {
      setTimeout(() => {
        this.focusMentionList = false;
      }, 100);
    }
  }

  addMentionReceivers(receivers: MentionReceiver[], objectId: string) {
    this._mentionService.inlivewithMentionCreatesPost$Json({
      isPushNoti: this.filePosts.length > 0 ? false : true,
      body: {
        clubId: this.post?.clubId!,
        dependObjectId: objectId,
        senderId: AuthService.GetAccountInfo().id!,
        receivers: receivers,
        type: TYPE_MENTION.COMMENT_POST
      }
    }).subscribe();
  }

  createComment(event?: KeyboardEvent) {
    if (
      (event && event.code !== 'Enter') ||
      (event?.code === 'Enter' && event?.shiftKey) ||
      (event?.code === 'Enter' && event?.ctrlKey)
    ) {
      return;
    }

    if (
      this.showErrorMixedFiles ||
      this.showErrorDuration ||
      this.showErrorMaximumFiles ||
      this.showErrorLimitSizeFile
    ) {
      return;
    }

    LoadingService.$(true);

    let a = document.getElementById('na-check');
    if (this.focusMentionList && a) {
      LoadingService.$(false);
      return;
    }

    this.showEmoji = false;

    if (
      this.urls.length == 0 &&
      (!this.content || (this.content && this.content!.trim().length == 0))
    ) {
      LoadingService.$(false);
      this.content = '';
      this.autoGrowTextZone(event);
      return;
    }

    var mentionIdsInReply: MentionReceiver[] = [];

    if (
      (this.content && this.content.trim().length != 0) ||
      this.filePosts.length > 0 ||
      this.urlAudio
    ) {
      this.checkCountTyped++;

      if (this.getCharacterLeft().isFullCharContent) {
        LoadingService.$(false);
        this.sendMsg = false;
        this.autoGrowTextZone(event);
        this.checkCountTyped = 0;
        return;
      }

      if (this.content && this.content.trim().length != 0) {
        let listMentionsInReply = this.content.match(MENTION_REGEX);
        this.listMention
          .filter((user) =>
            listMentionsInReply
              ?.map((value) => value.replace('@', '').replaceAll(' ', ''))
              .includes(user.username.replaceAll(' ', ''))
          )!
          .forEach((value) => mentionIdsInReply.push({
            id: value.id,
            name: value.name
          }))! || [];
      }

      if (this.checkCountTyped == 1) {
        this.sendMsg = true;
        let fileName = this.filePosts.map(x => x.name)!
        if (!this.isCommenttingReply) {
          this.postService
            .inlivewithPostCommentCreateCommentPostPost$Json({
              body: {
                PostId: this.post!.id,
                StageId: this.post!.stageId!,
                AuthorId: this.account.id,
                Message: this.content,
                MediaCommentFiles: [],
                MediaFileNames: fileName
              }
            })
            .subscribe((res) => {
              if (res.res == 0) {
                this.resetAfterCreatedComment(res, mentionIdsInReply, event);
              }
            });
        } else {
          this.postService
            .inlivewithPostCommentCreateCommentPostPost$Json({
              body: {
                PostId: this.post!.id,
                StageId: this.post!.stageId!,
                AuthorId: this.account.id,
                Message: this._translateService.instant('COMMENT_REPLY_PLACEHOLDER') +
                  '@' +
                  this.currentComment.author?.nickName!.replaceAll(' ', '') +
                  '\n' +
                  this.content!.trim(),
                MediaCommentFiles: [],
                ParentId: this.currentComment.id!,
                MediaFileNames: fileName
              }
            })
            .subscribe((res) => {
              if (res.res == 0) {
                this.resetAfterCreatedComment(res, mentionIdsInReply, event);
              }
            });
        }
      }
    }
  }

  private addMediaPosts(fileComments: any[], mediaCreateComment: MediaCreateResponseModel[]) {
    let obs: Observable<any>[] = [];
    fileComments.forEach((file: Blob, i: number) => {
      obs.push(
        this._uploadService.AddMediaPosts(file, mediaCreateComment[i].path!, mediaCreateComment[i].hook!, mediaCreateComment[i].id!)
      );
    });

    if (obs.length > 0) {
      forkJoin(obs).subscribe();
    }
  }

  resetAfterCreatedComment(res: CreateCommentPostResponseModelResponseModel, mentionIdsInReply: MentionReceiver[], event?: KeyboardEvent): void {
    if (!res.data?.author) {
      res.data!.author = AuthService.GetAccountInfo()!;
    }

    this.makeColor([res.data!]);
    this.comments.unshift(res.data!);
    this.newCommentId = res.data?.id!;
    this.isCommenttingReply = false;

    // this.notiService.sendMessage(
    //   this.post!?.id!,
    //   this.account.id!,
    //   res.data!
    // );
    this.addMentionReceivers(mentionIdsInReply, res.data?.id!);

    if (res.data?.mediaCreateResponseModels?.length! > 0) {
      this.addMediaPosts(this.filePosts, res.data?.mediaCreateResponseModels!)
    }

    this.eventService.push({
      action: EEvent.CREATE_COMMENT,
      payload: res.data,
    });

    this.content = '';
    this.autoGrowTextZone(event);
    this.urls = [];
    this.filePosts = [];
    this.checkCountTyped = 0;

    this.listFileAudio = [];
    this.listFileImageOrVideo = [];
    this.listOtherFile = [];

    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    this.showErrorDuration = false;
    this.sendMsg = false;
    this.currentComment = {};
    this.scrollToBottom();

    LoadingService.$(false);
  }

  editCommentFunc(event?: KeyboardEvent) {
    if (
      (event && event.code !== 'Enter') ||
      (event?.code === 'Enter' && event?.shiftKey) ||
      (event?.code === 'Enter' && event?.ctrlKey)
    ) {
      return;
    }

    LoadingService.$(true);

    let a = document.getElementById('na-check');
    if (this.focusMentionList && a) {
      LoadingService.$(false);
      return;
    }

    this.showEmoji = false;

    if (this.content!.trim().length == 0 || !this.post!) {
      LoadingService.$(false);
      this.content = '';
      this.autoGrowTextZone(event);
      return;
    }

    if (this.getCharacterLeft().isFullCharContent) {
      LoadingService.$(false);
      // this.sendMsg = false;
      this.autoGrowTextZone(event);
      this.checkCountTyped = 0;
      return;
    }

    if (this.content && this.content.trim().length != 0) {
      this.checkCountTyped++;
      let mentionIds: MentionReceiver[] = []

      if (this.checkCountTyped == 1) {

        if (this.content && this.content.trim().length != 0) {
          let listMentions = this.content.match(MENTION_REGEX);
          this.listMention
            .filter((user) =>
              listMentions
                ?.map((value) => value.replace('@', '').replaceAll(' ', ''))
                .includes(user.username.replaceAll(' ', ''))
            )!
            .forEach((value) => {
              mentionIds.push({
                id: value.id,
                name: value.name
              })
            })! || [];
        }

        this.postService
          .inlivewithPostCommentEditContentCommentOnPostPut$Json({
            body: {
              commentId: this.currentComment.id!,
              message: this.isCommenttingReply
                ? this.currentComment!.message!.split('\r\n')[0]! +
                '\r\n' +
                this.content!.trim()
                : this.content?.trim()!,
              mentionReceivers: mentionIds
            }
          })
          .subscribe((res) => {
            if (res.res == 0) {
              this.isCommenttingReply = false;
              this.isEditComment = false;

              let index = this.comments.findIndex(
                (x) => x.id! === res.data?.id!
              );
              if (index > -1) {
                this.comments[index] = res.data!;
              }

              // this.addMentionReceivers(mentionIds, res.data?.id!)

              this.content = '';
              this.autoGrowTextZone(event);
              this.eventService.push({
                action: EEvent.EDIT_COMMENT,
                payload: res.data,
              });

              this.checkCountTyped = 0;
              this.currentComment = {};
            }
            LoadingService.$(false);
          });
      }
    }
  }

  scrollToBottom() {
    if (this.elComments) {
      this.cdf.detectChanges();
      this.elComments.nativeElement.scrollTop =
        this.elComments.nativeElement.scrollHeight;
    }
  }

  ngOnInit(): void {
    this.subscription
      .add(this.refetchWhenReceiveNoti$.subscribe())
      .add(this.refetchWhenReceiveNotiFile$.subscribe());
    this.notiService.onReceiveMessage(
      (accountId: string, res: any) => {
        if (Array.isArray(res.data)) {
          this.comments = this.comments.filter(x => x.id !== res.data[0] && x.parentId !== res.data[0]);
        } else {
          let data: any = _.mapKeys(res, (value, key) => _.camelCase(key));
          data.author = _.mapKeys(data!.author!, (value, k) => _.camelCase(k));

          if (
            this.listMember.filter((user) => user.id == accountId).length == 0
          ) {
            if (!this.post?.id!) {
              return;
            }

            this.getListInlivers();
          }
          this.cdf.detectChanges();
          data.mediaCommentPosts = data.mediaCommentPosts?.map((d: any) =>
            _.mapKeys(d, (value, k) => _.camelCase(k))
          );

          let indexComment = this.comments.findIndex((c) => c.id === data.id);

          if (data && data.id && indexComment < 0) {
            this.makeColor([data]);
            this.comments.unshift(data);
            this.comments = _.uniqBy(this.comments, 'id');
            // this.eventService.push({
            //   action: EEvent.CREATE_COMMENT,
            //   payload: data,
            // });
            this.scrollToBottom();
            this.post!!.totalMemberDiscussion = [
              ...new Set(this.comments.map((c) => c.authorId)),
            ].length;
          }

          if (indexComment > -1) {
            this.comments[indexComment].mediaCommentPosts =
              data.mediaCommentPosts;
          }
        }

      }
    );
    // this.notiService.onTyping((accountId: string, value: boolean) => {
    //   if (accountId != this.account.id) {
    //     value ? this.countUserTyping++ : this.countUserTyping--;
    //   }
    // });
  }

  hidden() {
    this.el.nativeElement.style.display = 'none';
    this.el.nativeElement.style.height = '0';
  }

  show() {
    this.el.nativeElement.style.display = 'block';
    this.el.nativeElement.style.height = 'calc(100% - 128px)';
    this.content = null as any;
    this.listMember = [];
    this.listMention = [];
    //this.getListInlivers();
  }

  close() {
    this.notiService.listIdPostForNotiDiscussion =
      this.notiService.listIdPostForNotiDiscussion.filter(
        (id) => id != this.post?.id
      );

    if (this.post!?.isDiscussing) {
      this.notiService.leaveGroup(this.post!?.id!);
    }
    this.post = null;
    this.comments = [];
    this.page = 1;
    this.isDone = false;
    this.timeEnd = '';
    this.hidden();
    clearInterval(this.countDown);
    this.nameColors = [];
    this.eventService.push({
      action: EEvent.CLOSE_COMMENT_POST_DISCUSSION,
      payload: this.post!,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.countDown);
  }

  calcHeight(value: any) {
    let numberOfLineBreaks = (value.match(/\n/g) || []).length;
    if (numberOfLineBreaks == 0) {
      return 49.6;
    }
    let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
  }

  checkKey(event?: KeyboardEvent) {
    if (event && event.code === 'Enter' && !event.shiftKey && !event.ctrlKey) {
      return false;
    }
    return true;
  }

  changeShowEmoji(event: any) {
    event.stopPropagation();
    this.showEmoji = !this.showEmoji;
  }

  addEmoji(event: any) {
    event.$event.stopPropagation();
    if (this.content) {
      this.content += event.emoji.native;
    } else this.content = event.emoji.native;
  }

  startRecord() {
    this.openPopupRecordAudio = true;
    this.content = '';
  }

  listFileAudio: any[] = [];
  listFileImageOrVideo: any[] = [];
  listOtherFile: any[] = [];

  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;
  validatingFile = false;

  detectFiles(event: any) {
    LoadingService.$(true);
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];

      if (file) {
        file.id = new Date().getTime() + file.name;
        this.filePosts.push(file);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          this.urls.push({
            name: file.name,
            format: file.type.split('/')[0],
            data: this.domSanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(blob!)
            ),
            description: '',
            file: file,
            id: file.id,
            originalTypeFile: file.type,
          });

          /* check SIZE */
          const fileSize = file!.size;
          const fileMb = fileSize / 1024 ** 2;
          if (fileMb >= FILE_SIZE_LIMIT_POST) {
            this.showErrorLimitSizeFile = true;
          }

          /* check LIMIT */
          if (this.filePosts.length > 4) {
            this.showErrorMaximumFiles = true;
          } else {
            this.showErrorMaximumFiles = false;
          }

          if (
            file.type.split('/')[0] == 'audio' ||
            file.type.split('/')[0] == 'video' ||
            file.type.split('/')[0] == 'image'
          ) {
            if (file.type.split('/')[0] == 'audio') {
              this.listFileAudio.push(file);
            } else {
              this.listFileImageOrVideo.push(file);
            }
          } else {
            this.listOtherFile.push(file);
          }

          event.target.value = null;
        };
      }
    }

    this.intervalDetectFile = setInterval(() => {
      if (
        this.listFileAudio.length +
        this.listFileImageOrVideo.length +
        this.listOtherFile.length ==
        this.filePosts.length
      ) {
        clearInterval(this.intervalDetectFile);
        setTimeout(() => {
          LoadingService.$(false);
        }, 1000);
        this.handleFileValidation();
      }
    }, 300);

    this.textarea.nativeElement.focus();
    // LoadingService.$(false);
  }

  removeAsset(fileName: string) {
    LoadingService.$(true);

    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    this.listFileAudio = [];
    this.listFileImageOrVideo = [];
    this.listOtherFile = [];

    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    this.showErrorDuration = false;

    this.resetValidationMedia();
  }

  checkDuration(fileName: string, type: string) {
    let v_duration;
    setTimeout(() => {
      type == 'audio'
        ? (v_duration = (document.getElementById(fileName) as HTMLAudioElement)
          .duration)
        : (v_duration = (document.getElementById(fileName) as HTMLVideoElement)
          .duration);

      if (v_duration >= FILE_DURATION_LIMIT) {
        this.showErrorDuration = true;
      } else {
        // this.showErrorDuration = false;
      }
    }, 1000);
  }

  handleFileValidation() {
    if (
      this.listFileAudio.length >= 1 &&
      this.listFileImageOrVideo.length >= 1
    ) {
      // CHECK MIX FILE
      this.showErrorMixedFiles = true;
    } else {
      if (this.listFileAudio.length >= 1) {
        // HANDLE SIZE, DURATION
        this.listFileAudio.forEach((file) => {
          this.checkDuration(file.name, 'audio');
        });
      } else {
      }
      if (this.listFileImageOrVideo.length >= 1) {
        // HANDLE SIZE, DURATION
        this.listFileImageOrVideo.forEach((file) => {
          if (file.type.split('/')[0] == 'video') {
            this.checkDuration(file.name, 'video');
          }
        });
      } else {
      }
    }
  }

  resetValidationMedia() {
    if (this.filePosts.length > 4) {
      this.showErrorMaximumFiles = true;
    } else {
      this.showErrorMaximumFiles = false;
    }

    this.filePosts.forEach((file) => {
      const fileSize = file!.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
      }

      if (
        file.type.split('/')[0] == 'audio' ||
        file.type.split('/')[0] == 'video' ||
        file.type.split('/')[0] == 'image'
      ) {
        if (file.type.split('/')[0] == 'audio') {
          this.listFileAudio.push(file);
        } else {
          this.listFileImageOrVideo.push(file);
        }
      } else {
        this.listOtherFile.push(file);
      }
    });

    this.intervalDetectFile = setInterval(() => {
      if (
        this.listFileAudio.length +
        this.listFileImageOrVideo.length +
        this.listOtherFile.length ==
        this.filePosts.length
      ) {
        clearInterval(this.intervalDetectFile);
        setTimeout(() => {
          LoadingService.$(false);
        }, 1000);
        this.handleFileValidation();
      }
    }, 300);
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  checkTypeMedia(asset: MediaCommentPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  createCommentByAudio() {
    if ((!this.openPopupRecordAudio && !this.blobFileAudio) || !this.post) {
      return;
    }

    const body = {
      PostId: this.post!.id,
      StageId: this.post.stageId!,
      AuthorId: this.account.id,
      MediaCommentFiles: [this.blobFileAudio],
    };

    this.postService
      .inlivewithPostCommentCreateCommentPostPost$Json({ body })
      .subscribe((res) => {
        if (res.res == 0) {
          this.newCommentId = res.data?.id!;
          this.makeColor([res.data!]);
          this.comments.unshift(res.data!);
          // this.notiService.sendMessage(
          //   this.post!?.id!,
          //   this.account.id!,
          //   res.data!
          // );
          // this.eventService.push({
          //   action: EEvent.CREATE_COMMENT,
          //   payload: res.data,
          // });
          this.scrollToBottom();
          this.urlAudio = '';
        }
      });
  }

  getCommentByCommentId(commentId: string) {
    this.postService
      .inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json({
        commentId: commentId,
        stageId: this.post?.stageId!,
        postId: this.post!?.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            let index = this.comments.findIndex((x) => x.id == commentId);
            if (index > -1) {
              this.comments[index] = res.data!;
              this.eventService.push({
                action: EEvent.UPDATE_COMMENT_MEDIA,
                payload: res.data,
              });
            } else {
              this.comments.unshift(res.data!);
              this.scrollToBottom();
            }
          }
        })
      )
      .subscribe();
  }

  openClubDetail() {
    this._router.navigate(['manage-club', 'detail-club'], {
      queryParams: { clubId: this.post?.club?.id! },
    });
  }

  initMenuFor() {
    /**
     * OWNER / CREATER / OTHER
     * ______________________________
     *
     * OWNER => create discussion
     * CREATER => creater club
     * OTHER => other admin club or normal user
     *
     */
    var limitStringPipe = new LimitStringPipe();
    this.items = [];

    if (this.roleOfCurrentUser == 'OWNER') {
      this.items.push({
        icon: 'pi pi-pencil',
        label: this._translateService.instant('EDIT_TITLE_DISCUSSION'),
        disabled: this.post!.isReported || !this.post!.isDiscussing,
        command: () => {
          this.openPopupEditTitle = true;
          this.tempTitleEdit = this.post!.title!;
        },
      });

      this.items.push({
        icon: 'pi pi-calendar-times',
        label: this._translateService.instant('END_POST'),
        disabled: !this.post!.isDiscussing,
        command: () => {
          this.showConfirmEndDiscussion = true;
        },
      });

      this.items.push({
        icon: 'pi pi-trash',
        label: this._translateService.instant('DELETE_DISCUSSION'),
        command: () => (this.showConfirmDeletePost = true),
      });

      this.items.push({
        icon: 'pi pi-share-alt',
        label: this._translateService.instant('DISCUSSION_SHARE'),
        command: () => {
          this.clipboardService.copyFromContent(this.linkForCopy);

          this._messageService.add({
            severity: 'success',
            // summary: this._translateService.instant('NOTIFICATION'),
            detail: this._translateService.instant('COPIED'),
            key: 'copied',
            closable: false,
          });
        },
      });
    }

    if (this.roleOfCurrentUser == 'CREATER') {
      this._router.url.startsWith('/manage-club')
        ? this.items.push({
          icon: 'pi pi-user',
          label: limitStringPipe.transform(
            this._translateService.instant('SEE') +
            ' ' +
            this.post?.author?.nickName!,
            false,
            40
          ),
          disabled:
            AuthService.GetAccountInfo().id! == this.post?.author?.id!,
          visible:
            this.post?.author?.accessLevelInClub! !==
            'ACCESSLEVEL_CELEBRITY' ||
            AuthService.GetAccountInfo().id! == this.post?.author?.id!,
          command: () => {
            this.openAccountInfo();
          },
        })
        : this.items.push({
          icon: 'pi pi-star',
          label:
            this._translateService.instant('VIEW_CLUB') +
            ' ' +
            this.post?.club?.name!,
          command: () => {
            this.openClubDetail();
          },
        });

      this.items.push({
        icon: 'pi pi-calendar-times',
        label: this._translateService.instant('END_POST'),
        disabled: !this.post!.isDiscussing,
        command: () => {
          this.showConfirmEndDiscussion = true;
        },
      });

      this.items.push({
        icon: 'pi pi-trash',
        label: this._translateService.instant('DELETE_DISCUSSION'),
        command: () => (this.showConfirmDeletePost = true),
      });

      this.items.push({
        icon: 'pi pi-share-alt',
        label: this._translateService.instant('DISCUSSION_SHARE'),
        command: () => {
          this.clipboardService.copyFromContent(this.linkForCopy);

          this._messageService.add({
            severity: 'success',
            summary: this._translateService.instant('NOTIFICATION'),
            detail: this._translateService.instant('COPIED'),
            key: 'copied',
            closable: false,
          });
        },
      });

      this.items.push({
        icon: 'pi pi-flag',
        label: this._translateService.instant('REPORT_DISCUSSION'),
        disabled: this.post!.isReported,
        command: () => {
          this.openPopupReport = true;
        },
      });
    }

    if (this.roleOfCurrentUser == 'OTHER') {
      this._router.url.startsWith('/manage-club')
        ? this.items.push({
          icon: 'pi pi-user',
          label: limitStringPipe.transform(
            this._translateService.instant('SEE') +
            ' ' +
            this.post?.author?.nickName!,
            false,
            40
          ),
          disabled:
            AuthService.GetAccountInfo().id! == this.post?.author?.id!,
          visible:
            this.post?.author?.accessLevelInClub! !==
            'ACCESSLEVEL_CELEBRITY' ||
            AuthService.GetAccountInfo().id! == this.post?.author?.id!,
          command: () => {
            this.openAccountInfo();
          },
        })
        : this.items.push({
          icon: 'pi pi-star',
          label:
            this._translateService.instant('VIEW_CLUB') +
            ' ' +
            this.post?.club?.name!,
          command: () => {
            this.openClubDetail();
          },
        });

      this.items.push({
        icon: 'pi pi-share-alt',
        label: this._translateService.instant('DISCUSSION_SHARE'),
        command: () => {
          this.clipboardService.copyFromContent(this.linkForCopy);

          this._messageService.add({
            severity: 'success',
            summary: this._translateService.instant('NOTIFICATION'),
            detail: this._translateService.instant('COPIED'),
            key: 'copied',
            closable: false,
          });
        },
      });

      this.items.push({
        icon: 'pi pi-flag',
        label: this._translateService.instant('REPORT_DISCUSSION'),
        disabled: this.post!.isReported,
        command: () => {
          this.openPopupReportPost = true;
        },
      });
    }
  }

  initMenu() {
    if (
      AuthService.GetAccountInfo().id === this.post!.authorId ||
      AuthService.GetAccountInfo().id == this.post!.club?.authorId
    ) {
      if (AuthService.GetAccountInfo().id == this.post!.club?.authorId) {
        this.roleOfCurrentUser = 'CREATER';
      }
      if (AuthService.GetAccountInfo().id === this.post!.authorId) {
        this.roleOfCurrentUser = 'OWNER';
      }
    } else {
      this.roleOfCurrentUser = 'OTHER';
    }

    this.initMenuFor();
  }

  deletePost() {
    this.postService
      .inlivewithPostDeletePostDelete$Json({
        stageId: this.post?.stageId!,
        postId: this.post?.id,
      })
      .subscribe((res) => {
        if (res.res == 0) {
          this.close();
          location.reload();
        }
      });
  }

  endDiscussion() {
    LoadingService.$(true);
    this.postService
      .inlivewithPostEndPostDiscusionPostIdPut$Json({ postId: this.post!.id! })
      .subscribe((res) => {
        this.post!.isDiscussing = false;
        this.eventService.push({
          action: EEvent.END_POST,
          payload: {
            post: this.post,
            closeByManual: this.closeByManual,
          },
        });
        LoadingService.$(false);
      });
  }

  checkJoinedClub() {
    return this._clubService
      .inlivewithClubHasAccessAccountIdClubIdGet$Json({
        accountId: AuthService.GetAccountInfo()?.id!,
        clubId: this.post?.clubId!,
      })
      .pipe(
        tap((res) => {
          this.isJoinedClub = res.data!;
        })
      );
  }

  shareDiscussion() {
    this._messageService.add({
      severity: 'success',
      // summary: this._translateService.instant('NOTIFICATION'),
      detail: this._translateService.instant('COPIED'),
      key: 'copied',
      closable: false,
    });
    this.clipboardService.copyFromContent(
      environment.BASE_URL +
      '/manage-club/post-detail' +
      '?postId=' +
      this.post?.id! +
      '&clubId=' +
      this.post?.clubId!
    );
  }

  focusText(item: CommentPostResponseModel) {
    this.currentComment = item;
    this.isCommenttingReply = true;
    this.isEditComment = false;
    this.textarea!.nativeElement.focus();
    this.content = '';
    //this.content = '@' + item.author?.nickName! + ' ';
  }

  reportComment(item: CommentPostResponseModel) {
    this.currentComment = item;
    this.openPopupReport = true;
  }

  clickOnSide(event: Event) {
    event.stopPropagation();
  }

  checkZ(time: string) {
    if (time.endsWith('Z')) {
      return time;
    }
    return time + 'Z';
  }

  openAccountInfo() {
    this._router.navigate(['account-info'], {
      queryParams: { accountId: this.post?.author?.id! },
    });
  }

  openAccountInfoFromChat(comment: CommentPostResponseModel) {
    if (
      comment.authorId == AuthService.GetAccountInfo().id ||
      comment.isBannedByClub
    ) {
      return;
    }
    this._router.navigate(['account-info'], {
      queryParams: { accountId: comment.authorId },
    });
  }

  renderLinkForCopy() {
    let url =
      environment.BASE_URL +
      '/manage-club/post-detail' +
      '?postId=' +
      this.post?.id! +
      '&clubId=' +
      this.post?.clubId!;

    LoadingService.$(true);

    this._firebaseService.generateDeepLink(url).subscribe((res: any) => {
      this.linkForCopy = res.shortLink + '?efr=1';
      LoadingService.$(false);
    });
  }

  // checkProcessMediaFail(asset: MediaPostResponseModel) {
  //   if (
  //     !asset.isProcessed &&
  //     (asset.url?.includes('?i=') ||
  //       asset.url?.includes('?v=') ||
  //       asset.url?.includes('?a='))
  //   ) {
  //     return true;
  //   } else {
  //     if (
  //       asset.isProcessed &&
  //       !asset.url?.includes('?i=') &&
  //       !asset.url?.includes('?v=') &&
  //       !asset.url?.includes('?a=')
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  checkProcessMediaFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showMultiMedia(urls: MediaCommentPostResponseModel[], index: number) {
    this.multiMedias = urls;
    this.activeIndex = index;
    this.isShowMultiMedia = true;
  }

  editComment(item: CommentPostResponseModel) {
    this.currentComment = item;
    this.textarea.nativeElement.focus();
    this.isEditComment = true;
    if (
      item.message!.includes(
        this._translateService.instant('COMMENT_REPLY_PLACEHOLDER')
      )
    ) {
      this.content = item.message?.split('\r\n')[1]!;
      this.isCommenttingReply = true;
    } else this.content = item.message!;
    // this.content = item.message!;
  }

  showPopupConfirmDeleteComment(item: CommentPostResponseModel) {
    this.isShowPopupConfirm = true;
    this.currentComment = item;
  }

  deleteComment() {
    LoadingService.$(true);
    this.postService
      .inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(
        {
          commentPostId: this.currentComment.id!,
          accountRequestDeleteId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          this.comments = this.comments.filter(
            (c) => c.id !== this.currentComment.id
          );
          this.eventService.push({
            action: EEvent.DELETE_COMMENT,
            payload: this.currentComment,
          });
        })
      )
      .subscribe(() => {
        this.currentComment = {};
        this.isShowPopupConfirm = false;
        LoadingService.$(false);
      });
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }

  editTitleDiscussion() {
    let body = {
      accountRequestId: AuthService.GetAccountInfo().id,
      id: this.post!.id,
      authorId: this.post!.authorId,
      message: this.tempTitleEdit,
      stageId: this.post!.stageId,
    };

    if (this.tempTitleEdit.length == 0 || this.tempTitleEdit.length > 40) {
      return;
    } else {
      return this.postService
        .inlivewithPostEditTitlePostDiscussionPut$Json({
          body,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.post!.title = res.data?.title!;
              this.openPopupEditTitle = false;
            }
          })
        )
        .subscribe();
    }
  }

  triggerEventEnterSendDiscussion() {
    if (!this.content && this.getCharacterLeft().numberLength > 500) {
      return;
    }
    let event = new KeyboardEvent('keydown', { bubbles: true, code: 'Enter' });
    this.textarea.nativeElement.dispatchEvent(event);
  }

  autoGrowTextZone(e: any) {
    if (!e) {
      return;
    }

    if (!this.content || (this.content && this.content.trim() == '')) {
      e.target.style.height = '44px !important';
      return;
    }

    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 'px';
  }

  getCharacterLeft() {
    let numberLeft: number;
    let numberLength: number;
    let isFullCharContent: boolean = false;
    numberLeft =
      500 - _.toArray(this.content?.trimStart().trimEnd())?.length! > 0
        ? 500 - _.toArray(this.content?.trimStart().trimEnd()).length!
        : 0;
    isFullCharContent =
      _.toArray(this.content?.trimStart().trimEnd())?.length! > 500
        ? true
        : false;
    numberLength = _.toArray(this.content?.trimStart().trimEnd()).length!;
    return { numberLeft, isFullCharContent, numberLength };
  }

  refreshWhenReportSuccess() {
    this.post!.isReported = true;
    this.openPopupReportPost = false;
    this.initMenuFor();
    this.initMenu();
  }
}
