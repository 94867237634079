import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Club, ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-edit-description-club',
  templateUrl: './edit-description-club.component.html',
  styleUrls: ['./edit-description-club.component.scss']
})
export class EditDescriptionClubComponent implements OnInit {

  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() showDialog: boolean = false;
  @Output() showDialogChange = new EventEmitter();

  description!: string;

  constructor(private _clubService: ClubService) { }

  ngOnInit(): void {
    this.description = this.club?.description!;
  }

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  submit() {
    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club?.id!,
      value: this.description
    }

    LoadingService.$(true);
    
    if (this.description.trim().length == 0 || this.description.trim().length > 250) {
      LoadingService.$(false);
      return;
    } else {
      this._clubService.inlivewithClubDescriptionAdminIdClubIdPut$Json({
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club?.id!,
        body
      })
        .subscribe(res => {
          if (res.res == 0) {
            this.showDialog = false;
            LoadingService.$(false);
            location.reload();
          }
        })
    }
  }

}
