<p-dialog [(visible)]="showDialog" [draggable]="false" [resizable]="false" (onHide)="onHide()" [modal]="true" [closable]="true">

    <ng-container *ngIf="showDialogForgotPassword == false">
        <div class="login-modal">
            <div class="content">
                <div class="title pb-5">
                    {{'CONNECTION' | translate}}
                </div>
                <div class="pt-5" [innerHTML]="'CLUB_LOGIN_TITLE' | translate"></div>
                <form [formGroup]="form" (ngSubmit)="login()">
                    <div class="flex pt-5">
                        <div class="w-100">
                            <input formControlName="email" class="w-100" type="text" pInputText
                                placeholder="estelle.j75@gmail.com" />
                            <div *ngIf="CheckErrorForm(form, 'email', submited )" class="mt-1">
                                <small
                                    *ngIf="CheckErrorForm(form, 'email', submited) && !form.controls.email.errors?.required"
                                    class="text-error">
                                    {{'EMAIL_VALID' | translate}}
                                </small>
                                <small *ngIf="form.controls.email.errors?.required" class="text-error">
                                   {{'EMAIL_REQUIRED' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex pt-5 mb-2 ">
                        <div class="p-input-icon-right w-100 flex align-items-center">
                            <i class="pi cursor-pointer text-white"
                                [ngClass]="{'pi-eye-slash': fieldTextType, 'pi-eye' : !fieldTextType}"
                                (click)="toggleFieldTextType()"></i>
                            <input formControlName="password" class="w-100" [type]="inputType" pInputText
                                placeholder="*******" />
                        </div>
                    </div>
                    <div *ngIf="CheckErrorForm(form, 'password', submited )">
                        <small *ngIf="form.controls.password.errors?.required" class="text-error">
                            {{'PASSWORD_REQUIRED' | translate}}
                        </small>
                    </div>
                    <div *ngIf="isLoginSuccess == false && !submited">
                        <small class="text-error">
                            {{'LOGIN_FAILED' | translate}}
                        </small>
                    </div>

                    <small class="cursor-pointer label-forgot-password" (click)="showDialogForgotPassword = true">
                        {{'FORGOT_PASSWORD' | translate}}
                    </small>
                    <div class="flex pt-5 justify-content-center ">
                        <button pButton type="submit" label="{{'CONNECTION' | translate}}"
                            class="mt-5 p-button-outlined p-button-secondary">
                        </button>
                    </div>
                </form>

                <div class="pt-5" style="margin-top:20%">
                    <div class="text">
                        <span>
                            {{'OR' | translate}}
                        </span>
                    </div>
                    <div class="line w-100"></div>
                </div>

                <div class="pt-5" style="margin-top:15%; margin-bottom: 10%;">
                    <div class="flex">
                        <div class="flex col-4 justify-content-center">
                            <img class="cursor-pointer" src="../../../assets/images/svgs/google.svg">
                        </div>
                        <div class="flex col-4 justify-content-center">
                            <img class="cursor-pointer" src="../../../assets/images/svgs/facebook.svg">
                        </div>
                        <div class="flex col-4 justify-content-center">
                            <img class="cursor-pointer" src="../../../assets/images/svgs/apple.svg">
                        </div>
                    </div>
                    <div class="flex justify-content-center pt-5">
                        {{'REGISTER_ACCOUNT_TITLE' | translate}}<strong class="ml-1 cursor-pointer" (click)="register()"> {{'REGISTER_ACCOUNT' | translate}}</strong>
                    </div>
                </div>


            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showDialogForgotPassword">
        <app-forgot-password></app-forgot-password>
    </ng-container>

</p-dialog>