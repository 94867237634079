import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';

@Component({
  selector: 'app-card-confirm-has-image',
  templateUrl: './card-confirm-has-image.component.html',
  styleUrls: ['./card-confirm-has-image.component.scss']
})
export class CardConfirmHasImageComponent implements OnInit {

  @Input() isShowPopup!:boolean;
  @Input() title:string = '';
  @Input() firstButtonTitle:string = '';
  @Input() secondButtonTitle:string = '';
  @Input() username!:string
  @Input() content:string = '';
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel

  @Output() isShowPopupChange = new EventEmitter<boolean>();
  @Output() doAction = new EventEmitter();

  
  constructor() { }

  ngOnInit(): void {
  }

  onHide() {
    this.isShowPopupChange.emit(this.isShowPopup);
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
  }

}
