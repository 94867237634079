import { Injectable } from "@angular/core";
import { patch, updateItem } from "@ngxs/store/operators";
import {
  State,
  NgxsOnInit,
  Action,
  StateContext,
  StateToken,
  Selector,
} from "@ngxs/store";
import { AppEnumAllModel } from "../api/be/models";

const APP_ENUM_STATE_TOKEN = new StateToken<IAppEnumStateModel>("AppEnum");

export class InitAppEnumState {
  static readonly type = "[AppEnum] Init";
  constructor(public payload: AppEnumAllModel) { }
}

export class FetchAppEnum {
  static readonly type = "[AppEnum] Fetch All";
}

export interface IAppEnumStateModel {
  appEnum: AppEnumAllModel;
}

@State<IAppEnumStateModel>({
  name: APP_ENUM_STATE_TOKEN,
  defaults: {
    appEnum: {},
  },
})
@Injectable({
  providedIn: "root",
})
export class AppEnumStateService implements NgxsOnInit {
  ngxsOnInit(ctx?: StateContext<IAppEnumStateModel>) {
    ctx?.dispatch(new FetchAppEnum());
  }

  @Action(InitAppEnumState)
  InitClubState(
    ctx: StateContext<IAppEnumStateModel>,
    { payload }: InitAppEnumState
  ) {
    ctx.patchState({
      appEnum: payload,
    });
  }

}
