/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GenerateTokenResultModelResponseModel } from '../models/generate-token-result-model-response-model';
import { StartRecordingInputModel } from '../models/start-recording-input-model';
import { StartRecordingResultModelResponseModel } from '../models/start-recording-result-model-response-model';
import { StopRecordingInputModel } from '../models/stop-recording-input-model';
import { StopRecordingResponseModel } from '../models/stop-recording-response-model';
import { UpdateRecordingInputModel } from '../models/update-recording-input-model';

@Injectable({
  providedIn: 'root',
})
export class AgoraService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAgoraGetRtmTokenChannelNameGet
   */
  static readonly InlivewithAgoraGetRtmTokenChannelNameGetPath = '/inlivewith/Agora/GetRTMToken/{channelName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraGetRtmTokenChannelNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameGet$Plain$Response(params: {
    channelName: string;
  }): Observable<StrictHttpResponse<GenerateTokenResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraGetRtmTokenChannelNameGetPath, 'get');
    if (params) {
      rb.path('channelName', params.channelName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateTokenResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraGetRtmTokenChannelNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameGet$Plain(params: {
    channelName: string;
  }): Observable<GenerateTokenResultModelResponseModel> {

    return this.inlivewithAgoraGetRtmTokenChannelNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateTokenResultModelResponseModel>) => r.body as GenerateTokenResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraGetRtmTokenChannelNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameGet$Json$Response(params: {
    channelName: string;
  }): Observable<StrictHttpResponse<GenerateTokenResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraGetRtmTokenChannelNameGetPath, 'get');
    if (params) {
      rb.path('channelName', params.channelName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateTokenResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraGetRtmTokenChannelNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameGet$Json(params: {
    channelName: string;
  }): Observable<GenerateTokenResultModelResponseModel> {

    return this.inlivewithAgoraGetRtmTokenChannelNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateTokenResultModelResponseModel>) => r.body as GenerateTokenResultModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAgoraGetRtmTokenChannelNameUidGet
   */
  static readonly InlivewithAgoraGetRtmTokenChannelNameUidGetPath = '/inlivewith/Agora/GetRTMToken/{channelName}/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraGetRtmTokenChannelNameUidGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameUidGet$Plain$Response(params: {
    channelName: string;
    uid: string;
  }): Observable<StrictHttpResponse<GenerateTokenResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraGetRtmTokenChannelNameUidGetPath, 'get');
    if (params) {
      rb.path('channelName', params.channelName, {});
      rb.path('uid', params.uid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateTokenResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraGetRtmTokenChannelNameUidGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameUidGet$Plain(params: {
    channelName: string;
    uid: string;
  }): Observable<GenerateTokenResultModelResponseModel> {

    return this.inlivewithAgoraGetRtmTokenChannelNameUidGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateTokenResultModelResponseModel>) => r.body as GenerateTokenResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraGetRtmTokenChannelNameUidGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameUidGet$Json$Response(params: {
    channelName: string;
    uid: string;
  }): Observable<StrictHttpResponse<GenerateTokenResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraGetRtmTokenChannelNameUidGetPath, 'get');
    if (params) {
      rb.path('channelName', params.channelName, {});
      rb.path('uid', params.uid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateTokenResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraGetRtmTokenChannelNameUidGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAgoraGetRtmTokenChannelNameUidGet$Json(params: {
    channelName: string;
    uid: string;
  }): Observable<GenerateTokenResultModelResponseModel> {

    return this.inlivewithAgoraGetRtmTokenChannelNameUidGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateTokenResultModelResponseModel>) => r.body as GenerateTokenResultModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAgoraMixRecordingPost
   */
  static readonly InlivewithAgoraMixRecordingPostPath = '/inlivewith/Agora/MixRecording';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraMixRecordingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraMixRecordingPost$Plain$Response(params?: {
    body?: StartRecordingInputModel
  }): Observable<StrictHttpResponse<StartRecordingResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraMixRecordingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartRecordingResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraMixRecordingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraMixRecordingPost$Plain(params?: {
    body?: StartRecordingInputModel
  }): Observable<StartRecordingResultModelResponseModel> {

    return this.inlivewithAgoraMixRecordingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StartRecordingResultModelResponseModel>) => r.body as StartRecordingResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraMixRecordingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraMixRecordingPost$Json$Response(params?: {
    body?: StartRecordingInputModel
  }): Observable<StrictHttpResponse<StartRecordingResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraMixRecordingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartRecordingResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraMixRecordingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraMixRecordingPost$Json(params?: {
    body?: StartRecordingInputModel
  }): Observable<StartRecordingResultModelResponseModel> {

    return this.inlivewithAgoraMixRecordingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StartRecordingResultModelResponseModel>) => r.body as StartRecordingResultModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAgoraUpdateMixRecordingPut
   */
  static readonly InlivewithAgoraUpdateMixRecordingPutPath = '/inlivewith/Agora/UpdateMixRecording';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraUpdateMixRecordingPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraUpdateMixRecordingPut$Plain$Response(params?: {
    body?: UpdateRecordingInputModel
  }): Observable<StrictHttpResponse<StartRecordingResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraUpdateMixRecordingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartRecordingResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraUpdateMixRecordingPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraUpdateMixRecordingPut$Plain(params?: {
    body?: UpdateRecordingInputModel
  }): Observable<StartRecordingResultModelResponseModel> {

    return this.inlivewithAgoraUpdateMixRecordingPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StartRecordingResultModelResponseModel>) => r.body as StartRecordingResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraUpdateMixRecordingPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraUpdateMixRecordingPut$Json$Response(params?: {
    body?: UpdateRecordingInputModel
  }): Observable<StrictHttpResponse<StartRecordingResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraUpdateMixRecordingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartRecordingResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraUpdateMixRecordingPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraUpdateMixRecordingPut$Json(params?: {
    body?: UpdateRecordingInputModel
  }): Observable<StartRecordingResultModelResponseModel> {

    return this.inlivewithAgoraUpdateMixRecordingPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StartRecordingResultModelResponseModel>) => r.body as StartRecordingResultModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAgoraStopRecordingPost
   */
  static readonly InlivewithAgoraStopRecordingPostPath = '/inlivewith/Agora/StopRecording';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraStopRecordingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraStopRecordingPost$Plain$Response(params?: {
    body?: StopRecordingInputModel
  }): Observable<StrictHttpResponse<StopRecordingResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraStopRecordingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StopRecordingResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraStopRecordingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraStopRecordingPost$Plain(params?: {
    body?: StopRecordingInputModel
  }): Observable<StopRecordingResponseModel> {

    return this.inlivewithAgoraStopRecordingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StopRecordingResponseModel>) => r.body as StopRecordingResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAgoraStopRecordingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraStopRecordingPost$Json$Response(params?: {
    body?: StopRecordingInputModel
  }): Observable<StrictHttpResponse<StopRecordingResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AgoraService.InlivewithAgoraStopRecordingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StopRecordingResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAgoraStopRecordingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAgoraStopRecordingPost$Json(params?: {
    body?: StopRecordingInputModel
  }): Observable<StopRecordingResponseModel> {

    return this.inlivewithAgoraStopRecordingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StopRecordingResponseModel>) => r.body as StopRecordingResponseModel)
    );
  }

}
