import { LoadingService } from 'src/app/core/services/loading.service';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmailService } from 'src/app/core/api/be/services';
import { EMAIL_REGEX } from 'src/app/core/constant';
import { Observable, of, forkJoin } from 'rxjs';
import { EmailServiceCustom } from 'src/app/core/services/email.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-invite-friend-via-mail',
  templateUrl: './invite-friend-via-mail.component.html',
  styleUrls: ['./invite-friend-via-mail.component.scss'],
})
export class InviteFriendViaMailComponent implements OnInit {
  @Input() showDialog!: boolean;
  @Output() showDialogChange = new EventEmitter();
  listEmail = [];

  constructor(
    private _emailService: EmailService,
    private _translateService: TranslateService,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {}

  onHide() {
    this.showDialogChange.emit(false);
  }

  validateEmailAddress(event: any) {
    let trigger = event.value!;
    let regex = new RegExp(EMAIL_REGEX);

    let check = regex.test(trigger);

    if (!check) {
      this.listEmail.pop();
      this.showMessage(this._translateService.instant('EMAIL_VALID'));
      return;
    }

    if (trigger == LocalStorageService.GetLocalStorage('email')) {
      this.showMessage(this._translateService.instant('DONT_ENTER_YOUR_MAIL'));
      this.listEmail.pop();
    }
  }

  submit() {
    if (this.listEmail.length == 0) {
      this.showMessage(this._translateService.instant('PLEASE_ENTER_MAIL'));
      return;
    }

    LoadingService.$(true);
    const lang = LocalStorageService.GetLocalStorage('language').toLowerCase();
    const subject = this._translateService.instant('SUBJECT_SEND_MAIL_INVITE');

    let body = {
      avatarSender: AuthService.GetAccountInfo().avatar
        ? AuthService.GetAccountInfo().avatar
        : environment.BASE_URL + `/assets/images/svgs/avatar-default.svg`,
      culture: `${lang}-${lang}`,
      nicknameSender: AuthService.GetAccountInfo().nickName,
      url:
        environment.BASE_URL +
        `/account-info?accountId=${AuthService.GetAccountInfo().id!}`,
    };

    this._emailService
      .inlivewithEmailGetTemplateInvitationPost$Json({ body })
      .pipe(
        mergeMap((res) =>
          EmailServiceCustom.SendEmailInvite(
            this.listEmail.join(),
            subject,
            res.data!
          )
        )
      )
      .subscribe((res) => {
        LoadingService.$(false);
        this.showDialog = false;
      });
  }

  showMessage(text: string) {
    this._messageService.clear();

    this._messageService.add({
      severity: 'error',
      // summary: this._translateService.instant('NOTIFICATION'),
      detail: text,
      key: 'toast',
      closable: false,
    });
  }
}
