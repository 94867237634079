import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/core/api/be/services';
import { CheckErrorForm, EMAIL_REGEX } from 'src/app/core/constant';
import { EmailServiceCustom } from 'src/app/core/services/email.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {
  @Output() login = new EventEmitter();
  @Input() emailCheck!: string;
  @Input() resendMail: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({});

  CheckErrorForm = CheckErrorForm;
  submited: boolean = false;

  constructor(private _accountService: AccountService, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]]
    })
  }
  ngOnInit(): void {
    console.log(this.emailCheck);
    
  }

  ReSendConfirmEmail() {
    this.submited = true;
    if (!this.form.value.email) {
      return;
    }

    let confirmEmailUrl = `${environment.WEB_PORTAL_URL}/auth/active?callback=${window.origin}/complete-account&email=${this.form.value.email}&token=${LocalStorageService.GetLocalStorage('access_token')}`;
    console.log(confirmEmailUrl);
    LoadingService.$(true);

    // IdentityService.ReSendConfirmEmail(this.form.value.email).subscribe(res => {
    //   LoadingService.$(false);
    // })

    EmailServiceCustom.SendEmailActiveAccount(this.form.value.email, confirmEmailUrl)
      .subscribe(() => {
        console.log('SendEmailActiveAccount success');
        LoadingService.$(false)
      })
  }

}
