import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { unionBy } from 'lodash';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, InvitationClubResponseModel } from 'src/app/core/api/be/models';
import { ClubService, FriendRelationService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-inliver-invite-club',
  templateUrl: './list-inliver-invite-club.component.html',
  styleUrls: ['./list-inliver-invite-club.component.scss']
})
export class ListInliverInviteClubComponent implements OnInit {

  @Input() openPopup!: boolean
  @Input() clubId!: string;

  @Output() openPopupChange = new EventEmitter();

  listMembers: AccountResponseWithRoleInCLubModel[] = [];
  selectedInlivers!: AccountResponseWithRoleInCLubModel[];
  listInliverInvitedPending: InvitationClubResponseModel[] = [];

  currentPage: number = 1;
  defaultAvatar = defaultAvatar
  searchText!: string;
  isNextStep: boolean = false;
  isDone: boolean = false;


  constructor(
    private _friendRelation: FriendRelationService,
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getListInliver();
    this.getListPending();
  }

  hidePopup() {
    this.openPopupChange.emit(false);
  }

  getListInliver() {

    this._friendRelation.inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json({
      adminId: AuthService.GetAccountInfo().id!,
      clubId: this.clubId,
      limit: 10,
      page: this.currentPage
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listMembers = res.data?.page!
        }
        if (res.data?.next! == -1) {
          this.isDone = true;
        }
      })
    ).subscribe();

  }

  loadFriend() {

    this.currentPage = this.listMembers.length! < 10 * this.currentPage ? this.currentPage : this.currentPage + 1;

    this._friendRelation.inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json({
      adminId: AuthService.GetAccountInfo().id!,
      clubId: this.clubId,
      limit: 10,
      page: this.currentPage
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listMembers = this.listMembers.concat(res.data?.page!);
          this.listMembers = unionBy(this.listMembers, 'id')
        }
        if (res.data?.next! == -1) {
          this.isDone = true;
        }
      })
    ).subscribe();
  }

  getListPending() {
    this._clubService.inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json({
      adminId: AuthService.GetAccountInfo().id!,
      clubId: this.clubId
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listInliverInvitedPending = res.data?.page!
        }
      })
    ).subscribe();
  }

  invite() {
    if (this.selectedInlivers.length! > 0) {
      let listIds: string[] = [];
      this.selectedInlivers.forEach(x => listIds.push(x.id!));

      this._clubService.inlivewithClubMembersAddMembersAdminIdClubIdPost$Json({
        adminId: AuthService.GetAccountInfo().id!,
        clubId: this.clubId,
        body: {
          adminId: AuthService.GetAccountInfo().id!,
          clubId: this.clubId,
          values: listIds
        }
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.showNotification(this._translateService.instant('SENT_INVITATION'));
          }
        })
      ).subscribe(() => { this.hidePopup() }, () => { this.hidePopup() });
    }
  }

  showNotification(subtitle: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: subtitle,
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => this.hidePopup());
  }

}
