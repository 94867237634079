<div class="position-relative container cursor-pointer"
    [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}"
    *ngIf="post" (click)="handleClick()">
    <div *ngIf="checkTypeMedia(post.mediaPosts![0]) == 'VIDEO'" class="wrap-video">
        <img class="icon-play" src="../../../../assets/images/svgs/play-post-video-celeb.svg">
    </div>
    <div *ngIf="post.mediaPosts?.length! > 0" class="w-100 poster">
        <img loading="lazy" style="border-radius: 0px 0px 15px 15px"
            *ngIf="(checkTypeMedia(post.mediaPosts![0]) == 'IMAGE' || checkTypeMedia(post.mediaPosts![0]) == 'VIDEO') && checkTypeMedia(post.mediaPosts![0]) != 'RECORD'"
            [src]="getUrl(post.mediaPosts![0])" alt="" class="fit-size" />

        <div class="flex w-100 h-100 justify-content-center align-items-center"
            *ngIf="checkTypeMedia(post.mediaPosts![0]) == 'UNKNOWN'">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin: 3rem;"></i>
        </div>
    </div>
    <div class="flex fs-12 text-white w-90 mt-5" style="position: absolute; top: 45px"
        *ngIf="post.isAdminPost && post.mediaPosts?.length! > 0 && checkTypeMedia(post.mediaPosts![0]) == 'AUDIO'"
        [innerHTML]="post.mediaPosts![0].originalFileName">
    </div>
    <div class="flex flex-column w-90 mt-5" style="position: absolute; top: 43px"
        *ngIf="post.mediaPosts?.length! > 0 && checkTypeMedia(post.mediaPosts![0]) == 'AUDIO'">
        <app-wave-form-audio [assetUrl]="post.mediaPosts![0].url!" [forPostCeleb]="true"></app-wave-form-audio>
    </div>
    <div class="zone-header flex-column h-90">
        <div class="flex zone-celeb-info">
            <div class="zone-info-celeb"
                [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="post.author?.avatar ?? defaultAvatar" class="fit-size cursor-pointer">
                    </div>
                    <div class="ml-2 flex flex-column">
                        <span class="flex text-name text-white mr-2 cursor-pointer">
                            {{post.author!.nickName! | limitString:false:14}}

                            <img *ngIf="!post.isAdminPost" class="fit-size ml-1"
                                src="../../../assets/images/svgs/celeb-post.svg">
                        </span>
                        <span class="time text-white mr-2 cursor-pointer">
                            {{timeAgo(post!.dateCreated!)}}
                        </span>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex flex-column justify-content-between h-100"
            [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}"
            style="padding: 0px 12px;" *ngIf="post.mediaPosts?.length! == 0">
            <div class="flex w-100">
                <div class="mt-3 text-message text-message-body" [innerHTML]="urlify(post.message)"></div>
            </div>

            <div class="flex">
                <div class="flex align-items-center">
                    <img class="fit-size mr-1" src="../../../assets/images/svgs/star.svg">
                    <span class="ml-1 fs-12 text-white cursor-pointer">{{post.totalNumberOfClub}}
                        {{(post.totalNumberOfClub! > 1 ? 'CLUBS' : 'CLUB') | translate | lowercase}}</span>
                </div>
            </div>
        </div>
    </div>


    <div class="zone-footer"
        [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}"
        *ngIf="post.mediaPosts?.length! > 0">
        <div class="mt-2" *ngIf="post.message">
            <div class="text-message text-message-footer" [innerHTML]="urlify(post.message)"></div>
        </div>
        <div class="flex justify-content-between align-items-center w-100 mt-2">
            <div class="flex align-items-center">
                <img class="fit-size mr-1" src="../../../assets/images/svgs/star.svg">
                <span class="ml-1 fs-12 text-white cursor-pointer">{{post.totalNumberOfClub}} {{(post.totalNumberOfClub!
                    > 1 ?
                    'CLUBS' : 'CLUB') | translate | lowercase}}</span>
            </div>
            <div class="flex justify-content-center align-items-center zone-count-media"
                style="background-image: url('../../../assets/images/svgs/celeb-post-icon-right.svg');"
                *ngIf="post.mediaPosts?.length! > 1">
                <div class="text-bold number-count-media">
                    {{post.mediaPosts?.length}}</div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showListClubsPosted">
    <app-list-clubs-celeb-post [clubs]="post.clubs!" [postCelebId]="post.postCelebrityId!"
        [(showListClubsPosted)]="showListClubsPosted"></app-list-clubs-celeb-post>
</ng-container>