/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResponseInPostPaginationModelResponseModel } from '../models/account-response-in-post-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubsResponeForNewFeedPostCelebrityResponseModel } from '../models/clubs-respone-for-new-feed-post-celebrity-response-model';
import { CommentInputEditContentModel } from '../models/comment-input-edit-content-model';
import { CommentPostResponseModelListResponseModel } from '../models/comment-post-response-model-list-response-model';
import { CommentPostResponseModelPaginationModelResponseModel } from '../models/comment-post-response-model-pagination-model-response-model';
import { CommentPostResponseModelResponseModel } from '../models/comment-post-response-model-response-model';
import { CreateCommentPostInputModel } from '../models/create-comment-post-input-model';
import { CreateCommentPostResponseModelResponseModel } from '../models/create-comment-post-response-model-response-model';
import { CreatePostResponseModelResponseModel } from '../models/create-post-response-model-response-model';
import { MediaCommentPostDeleteModel } from '../models/media-comment-post-delete-model';
import { MediaCreateResponseModelListResponseModel } from '../models/media-create-response-model-list-response-model';
import { MediaPostDeleteModel } from '../models/media-post-delete-model';
import { MediaPostResponseModelListResponseModel } from '../models/media-post-response-model-list-response-model';
import { MediaPostResponseModelResponseModel } from '../models/media-post-response-model-response-model';
import { MediaPostWithUrlInputCreateModel } from '../models/media-post-with-url-input-create-model';
import { MentionReceiver } from '../models/mention-receiver';
import { PostInputEditContentModel } from '../models/post-input-edit-content-model';
import { PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel } from '../models/post-of-celebrity-for-new-feed-response-model-pagination-model-response-model';
import { PostOfCelebrityResponseModelResponseModel } from '../models/post-of-celebrity-response-model-response-model';
import { PostResponseModelPaginationModelResponseModel } from '../models/post-response-model-pagination-model-response-model';
import { PostResponseModelResponseModel } from '../models/post-response-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';
import { ReactInputModel } from '../models/react-input-model';
import { ReactionPostResponseModelPaginationModelResponseModel } from '../models/reaction-post-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class PostService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithPostPageGet
   */
  static readonly InlivewithPostPageGetPath = '/inlivewith/Post/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByIdPostIdGet
   */
  static readonly InlivewithPostGetByIdPostIdGetPath = '/inlivewith/Post/GetById/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByIdPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdGet$Plain$Response(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByIdPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByIdPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdGet$Plain(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetByIdPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByIdPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdGet$Json$Response(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByIdPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByIdPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdGet$Json(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetByIdPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetPostCelebrityByIdPostIdGet
   */
  static readonly InlivewithPostGetPostCelebrityByIdPostIdGetPath = '/inlivewith/Post/GetPostCelebrityById/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostCelebrityByIdPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostCelebrityByIdPostIdGet$Plain$Response(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostCelebrityByIdPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostCelebrityByIdPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostCelebrityByIdPostIdGet$Plain(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetPostCelebrityByIdPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostCelebrityByIdPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostCelebrityByIdPostIdGet$Json$Response(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostCelebrityByIdPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostCelebrityByIdPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostCelebrityByIdPostIdGet$Json(params: {
    postId: string;
    clubId?: string;
    stageId?: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetPostCelebrityByIdPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetHiddenPostAccountRequestIdGet
   */
  static readonly InlivewithPostGetHiddenPostAccountRequestIdGetPath = '/inlivewith/Post/GetHiddenPost/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetHiddenPostAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetHiddenPostAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetHiddenPostAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetHiddenPostAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath = '/inlivewith/Post/GetByAuthor/{authorId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByStageStageIdAccountRequestIdTypeGet
   */
  static readonly InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath = '/inlivewith/Post/GetByStage/{stageId}/{accountRequestId}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByClubClubIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetByClubClubIdAccountRequestIdGetPath = '/inlivewith/Post/GetByClub/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetPostForNewFeedAccountRequestIdGet
   */
  static readonly InlivewithPostGetPostForNewFeedAccountRequestIdGetPath = '/inlivewith/Post/GetPostForNewFeed/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostForNewFeedAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostForNewFeedAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetPostOfCelebrityOnNewFeedGet
   */
  static readonly InlivewithPostGetPostOfCelebrityOnNewFeedGetPath = '/inlivewith/Post/GetPostOfCelebrityOnNewFeed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostOfCelebrityOnNewFeedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityOnNewFeedGet$Plain$Response(params?: {
    isFrom3DayAgo?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostOfCelebrityOnNewFeedGetPath, 'get');
    if (params) {
      rb.query('isFrom3DayAgo', params.isFrom3DayAgo, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostOfCelebrityOnNewFeedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityOnNewFeedGet$Plain(params?: {
    isFrom3DayAgo?: boolean;
    page?: number;
    limit?: number;
  }): Observable<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostOfCelebrityOnNewFeedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>) => r.body as PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostOfCelebrityOnNewFeedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityOnNewFeedGet$Json$Response(params?: {
    isFrom3DayAgo?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostOfCelebrityOnNewFeedGetPath, 'get');
    if (params) {
      rb.query('isFrom3DayAgo', params.isFrom3DayAgo, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostOfCelebrityOnNewFeedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityOnNewFeedGet$Json(params?: {
    isFrom3DayAgo?: boolean;
    page?: number;
    limit?: number;
  }): Observable<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostOfCelebrityOnNewFeedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel>) => r.body as PostOfCelebrityForNewFeedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetClubByPostCelebrityIdGet
   */
  static readonly InlivewithPostGetClubByPostCelebrityIdGetPath = '/inlivewith/Post/GetClubByPostCelebrityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetClubByPostCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubByPostCelebrityIdGet$Plain$Response(params?: {
    postCelebId?: string;
  }): Observable<StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetClubByPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.query('postCelebId', params.postCelebId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetClubByPostCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubByPostCelebrityIdGet$Plain(params?: {
    postCelebId?: string;
  }): Observable<PostOfCelebrityResponseModelResponseModel> {

    return this.inlivewithPostGetClubByPostCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>) => r.body as PostOfCelebrityResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetClubByPostCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubByPostCelebrityIdGet$Json$Response(params?: {
    postCelebId?: string;
  }): Observable<StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetClubByPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.query('postCelebId', params.postCelebId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetClubByPostCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubByPostCelebrityIdGet$Json(params?: {
    postCelebId?: string;
  }): Observable<PostOfCelebrityResponseModelResponseModel> {

    return this.inlivewithPostGetClubByPostCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostOfCelebrityResponseModelResponseModel>) => r.body as PostOfCelebrityResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetPostOfCelebrityByClubClubIdGet
   */
  static readonly InlivewithPostGetPostOfCelebrityByClubClubIdGetPath = '/inlivewith/Post/GetPostOfCelebrityByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostOfCelebrityByClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityByClubClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostOfCelebrityByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostOfCelebrityByClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityByClubClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostOfCelebrityByClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostOfCelebrityByClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityByClubClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostOfCelebrityByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostOfCelebrityByClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostOfCelebrityByClubClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostOfCelebrityByClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet
   */
  static readonly InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath = '/inlivewith/Post/GetCurrentPostDiscussion/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet
   */
  static readonly InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath = '/inlivewith/Post/SearchCurrentPostDiscussion/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('title', params.title, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('title', params.title, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet
   */
  static readonly InlivewithPostGetMediaPostByIdPostIdMediaPostIdGetPath = '/inlivewith/Post/GetMediaPostById/{postId}/{mediaPostId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Plain$Response(params: {
    postId: string;
    stageId?: string;
    mediaPostId: string;
  }): Observable<StrictHttpResponse<MediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetMediaPostByIdPostIdMediaPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.path('mediaPostId', params.mediaPostId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Plain(params: {
    postId: string;
    stageId?: string;
    mediaPostId: string;
  }): Observable<MediaPostResponseModelResponseModel> {

    return this.inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelResponseModel>) => r.body as MediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Json$Response(params: {
    postId: string;
    stageId?: string;
    mediaPostId: string;
  }): Observable<StrictHttpResponse<MediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetMediaPostByIdPostIdMediaPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.path('mediaPostId', params.mediaPostId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Json(params: {
    postId: string;
    stageId?: string;
    mediaPostId: string;
  }): Observable<MediaPostResponseModelResponseModel> {

    return this.inlivewithPostGetMediaPostByIdPostIdMediaPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelResponseModel>) => r.body as MediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsOnPostPostIdGet
   */
  static readonly InlivewithPostGetCommentsOnPostPostIdGetPath = '/inlivewith/Post/GetCommentsOnPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.query('isDiscusstion', params.isDiscusstion, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Plain(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Json$Response(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.query('isDiscusstion', params.isDiscusstion, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Json(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsReplyPostIdCommentIdGet
   */
  static readonly InlivewithPostGetCommentsReplyPostIdCommentIdGetPath = '/inlivewith/Post/GetCommentsReply/{postId}/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsReplyPostIdCommentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsReplyPostIdCommentIdGet$Plain$Response(params: {
    stageId?: string;
    postId: string;
    commentId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsReplyPostIdCommentIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsReplyPostIdCommentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsReplyPostIdCommentIdGet$Plain(params: {
    stageId?: string;
    postId: string;
    commentId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsReplyPostIdCommentIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json$Response(params: {
    stageId?: string;
    postId: string;
    commentId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsReplyPostIdCommentIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json(params: {
    stageId?: string;
    postId: string;
    commentId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetAuthorCommentPostDiscussionPostIdGet
   */
  static readonly InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath = '/inlivewith/Post/GetAuthorCommentPostDiscussion/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseInPostPaginationModelResponseModel> {

    return this.inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>) => r.body as AccountResponseInPostPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseInPostPaginationModelResponseModel> {

    return this.inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>) => r.body as AccountResponseInPostPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsPostByIdPostIdCommentIdGet
   */
  static readonly InlivewithPostGetCommentsPostByIdPostIdCommentIdGetPath = '/inlivewith/Post/GetCommentsPostById/{postId}/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Plain$Response(params: {
    stageId?: string;
    postId: string;
    commentId: string;
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsPostByIdPostIdCommentIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Plain(params: {
    stageId?: string;
    postId: string;
    commentId: string;
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json$Response(params: {
    stageId?: string;
    postId: string;
    commentId: string;
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsPostByIdPostIdCommentIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json(params: {
    stageId?: string;
    postId: string;
    commentId: string;
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet
   */
  static readonly InlivewithPostGetClubWithMostMemberByPostCelebrityIdGetPath = '/inlivewith/Post/GetClubWithMostMemberByPostCelebrityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Plain$Response(params?: {
    postCelebId?: string;
    page?: number;
    limit?: number;
    isFilterJoined?: boolean;
  }): Observable<StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetClubWithMostMemberByPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.query('postCelebId', params.postCelebId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isFilterJoined', params.isFilterJoined, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Plain(params?: {
    postCelebId?: string;
    page?: number;
    limit?: number;
    isFilterJoined?: boolean;
  }): Observable<ClubsResponeForNewFeedPostCelebrityResponseModel> {

    return this.inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>) => r.body as ClubsResponeForNewFeedPostCelebrityResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json$Response(params?: {
    postCelebId?: string;
    page?: number;
    limit?: number;
    isFilterJoined?: boolean;
  }): Observable<StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetClubWithMostMemberByPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.query('postCelebId', params.postCelebId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isFilterJoined', params.isFilterJoined, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json(params?: {
    postCelebId?: string;
    page?: number;
    limit?: number;
    isFilterJoined?: boolean;
  }): Observable<ClubsResponeForNewFeedPostCelebrityResponseModel> {

    return this.inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubsResponeForNewFeedPostCelebrityResponseModel>) => r.body as ClubsResponeForNewFeedPostCelebrityResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreatePost
   */
  static readonly InlivewithPostCreatePostPath = '/inlivewith/Post/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePost$Plain$Response(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePost$Plain(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePost$Json$Response(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePost$Json(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostNewCreatePost
   */
  static readonly InlivewithPostNewCreatePostPath = '/inlivewith/Post/NewCreate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostNewCreatePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostNewCreatePost$Plain$Response(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CreatePostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostNewCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostNewCreatePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostNewCreatePost$Plain(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<CreatePostResponseModelResponseModel> {

    return this.inlivewithPostNewCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreatePostResponseModelResponseModel>) => r.body as CreatePostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostNewCreatePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostNewCreatePost$Json$Response(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CreatePostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostNewCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostNewCreatePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostNewCreatePost$Json(params?: {
    body?: {
'Message'?: string;
'StageIds'?: Array<string>;
'Title'?: string;
'Type'?: string;
'NumberOfMedia'?: number;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaPostFileNames'?: Array<string>;
'Urls'?: Array<string>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<CreatePostResponseModelResponseModel> {

    return this.inlivewithPostNewCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreatePostResponseModelResponseModel>) => r.body as CreatePostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreateMediaPostPost
   */
  static readonly InlivewithPostCreateMediaPostPostPath = '/inlivewith/Post/CreateMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Plain$Response(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaPostPostPath, 'post');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Plain(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Json$Response(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaPostPostPath, 'post');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Json(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreateMediaCommentPostPost
   */
  static readonly InlivewithPostCreateMediaCommentPostPostPath = '/inlivewith/Post/CreateMediaCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaCommentPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaCommentPostPost$Plain$Response(params?: {
    commentPostId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaCommentPostPostPath, 'post');
    if (params) {
      rb.query('commentPostId', params.commentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaCommentPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaCommentPostPost$Plain(params?: {
    commentPostId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaCommentPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaCommentPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaCommentPostPost$Json$Response(params?: {
    commentPostId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaCommentPostPostPath, 'post');
    if (params) {
      rb.query('commentPostId', params.commentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaCommentPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaCommentPostPost$Json(params?: {
    commentPostId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaCommentPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEditPostContentPut
   */
  static readonly InlivewithPostEditPostContentPutPath = '/inlivewith/Post/EditPostContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Plain$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Plain(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Json$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Json(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEditTitlePostDiscussionPut
   */
  static readonly InlivewithPostEditTitlePostDiscussionPutPath = '/inlivewith/Post/EditTitlePostDiscussion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditTitlePostDiscussionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditTitlePostDiscussionPut$Plain$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditTitlePostDiscussionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditTitlePostDiscussionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditTitlePostDiscussionPut$Plain(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditTitlePostDiscussionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditTitlePostDiscussionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditTitlePostDiscussionPut$Json$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditTitlePostDiscussionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditTitlePostDiscussionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditTitlePostDiscussionPut$Json(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditTitlePostDiscussionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEndPostDiscusionPostIdPut
   */
  static readonly InlivewithPostEndPostDiscusionPostIdPutPath = '/inlivewith/Post/EndPostDiscusion/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostHidePostAccountRequestIdPostIdPut
   */
  static readonly InlivewithPostHidePostAccountRequestIdPostIdPutPath = '/inlivewith/Post/HidePost/{accountRequestId}/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostHidePostAccountRequestIdPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Plain(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostHidePostAccountRequestIdPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Json(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostUnHidePostAccountRequestIdPostIdPut
   */
  static readonly InlivewithPostUnHidePostAccountRequestIdPostIdPutPath = '/inlivewith/Post/UnHidePost/{accountRequestId}/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostUnHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostUnHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeletePostDelete
   */
  static readonly InlivewithPostDeletePostDeletePath = '/inlivewith/Post/DeletePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain$Response(params?: {
    postId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain(params?: {
    postId?: string;
    stageId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json$Response(params?: {
    postId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json(params?: {
    postId?: string;
    stageId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeletePostCelebrityDelete
   */
  static readonly InlivewithPostDeletePostCelebrityDeletePath = '/inlivewith/Post/DeletePostCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostCelebrityDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostCelebrityDelete$Plain$Response(params?: {
    postCelebrityId?: string;
    accountRequestDeleteId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostCelebrityDeletePath, 'delete');
    if (params) {
      rb.query('postCelebrityId', params.postCelebrityId, {});
      rb.query('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostCelebrityDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostCelebrityDelete$Plain(params?: {
    postCelebrityId?: string;
    accountRequestDeleteId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostCelebrityDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostCelebrityDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostCelebrityDelete$Json$Response(params?: {
    postCelebrityId?: string;
    accountRequestDeleteId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostCelebrityDeletePath, 'delete');
    if (params) {
      rb.query('postCelebrityId', params.postCelebrityId, {});
      rb.query('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostCelebrityDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostCelebrityDelete$Json(params?: {
    postCelebrityId?: string;
    accountRequestDeleteId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostCelebrityDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostPinPostPostIdStageIdPost
   */
  static readonly InlivewithPostPinPostPostIdStageIdPostPath = '/inlivewith/Post/PinPost{postId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPinPostPostIdStageIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPinPostPostIdStageIdPost$Plain$Response(params: {
    postId: string;
    stageId: string;
    isPinPost?: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPinPostPostIdStageIdPostPath, 'post');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('stageId', params.stageId, {});
      rb.query('isPinPost', params.isPinPost, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPinPostPostIdStageIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPinPostPostIdStageIdPost$Plain(params: {
    postId: string;
    stageId: string;
    isPinPost?: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostPinPostPostIdStageIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPinPostPostIdStageIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPinPostPostIdStageIdPost$Json$Response(params: {
    postId: string;
    stageId: string;
    isPinPost?: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPinPostPostIdStageIdPostPath, 'post');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('stageId', params.stageId, {});
      rb.query('isPinPost', params.isPinPost, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPinPostPostIdStageIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPinPostPostIdStageIdPost$Json(params: {
    postId: string;
    stageId: string;
    isPinPost?: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostPinPostPostIdStageIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnPostPostIdGet
   */
  static readonly InlivewithPostGetReactOnPostPostIdGetPath = '/inlivewith/Post/GetReactOnPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Plain$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Plain(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Json$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Json(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostReactReactOnPostPost
   */
  static readonly InlivewithPostReactReactOnPostPostPath = '/inlivewith/Post/React/ReactOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostAddMediaPostPut
   */
  static readonly InlivewithPostMediaPostAddMediaPostPutPath = '/inlivewith/Post/MediaPost/AddMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostAddMediaPostUrlsPut
   */
  static readonly InlivewithPostMediaPostAddMediaPostUrlsPutPath = '/inlivewith/Post/MediaPost/AddMediaPostUrls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostUrlsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostUrlsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Plain(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostUrlsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostUrlsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Json(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostDeleteMediaPostDelete
   */
  static readonly InlivewithPostMediaPostDeleteMediaPostDeletePath = '/inlivewith/Post/MediaPost/DeleteMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateCommentPostPost
   */
  static readonly InlivewithPostCommentCreateCommentPostPostPath = '/inlivewith/Post/Comment/CreateCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentPostPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Plain$Response(params?: {
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CreateCommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentPostPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Plain(params?: {
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<CreateCommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCommentPostResponseModelResponseModel>) => r.body as CreateCommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentPostPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Json$Response(params?: {
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CreateCommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentPostPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Json(params?: {
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
'MediaFileNames'?: Array<string>;
}
  }): Observable<CreateCommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCommentPostResponseModelResponseModel>) => r.body as CreateCommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateCommentsPostPostIdPost
   */
  static readonly InlivewithPostCommentCreateCommentsPostPostIdPostPath = '/inlivewith/Post/Comment/CreateCommentsPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentsPostPostIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response(params: {
    stageId?: string;
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<StrictHttpResponse<CommentPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentsPostPostIdPostPath, 'post');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Plain(params: {
    stageId?: string;
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<CommentPostResponseModelListResponseModel> {

    return this.inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelListResponseModel>) => r.body as CommentPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentsPostPostIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response(params: {
    stageId?: string;
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<StrictHttpResponse<CommentPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentsPostPostIdPostPath, 'post');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Json(params: {
    stageId?: string;
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<CommentPostResponseModelListResponseModel> {

    return this.inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelListResponseModel>) => r.body as CommentPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost
   */
  static readonly InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath = '/inlivewith/Post/Comment/CreateReplyCommentOnPost/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'StageId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceivers'?: Array<MentionReceiver>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentEditContentCommentOnPostPut
   */
  static readonly InlivewithPostCommentEditContentCommentOnPostPutPath = '/inlivewith/Post/Comment/EditContentCommentOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnPostPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response(params?: {
    body?: CommentInputEditContentModel
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Plain(params?: {
    body?: CommentInputEditContentModel
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnPostPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Json$Response(params?: {
    body?: CommentInputEditContentModel
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnPostPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Json(params?: {
    body?: CommentInputEditContentModel
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentAddMediaCommentOnPostPut
   */
  static readonly InlivewithPostCommentAddMediaCommentOnPostPutPath = '/inlivewith/Post/Comment/AddMediaCommentOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnPostPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Plain(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnPostPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Json(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete
   */
  static readonly InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath = '/inlivewith/Post/Comment/DeleteCommentOnPost/{commentPostId}/{accountRequestDeleteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteMediaOnCommentPostDelete
   */
  static readonly InlivewithPostCommentDeleteMediaOnCommentPostDeletePath = '/inlivewith/Post/Comment/DeleteMediaOnCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaMediaPostIdPut
   */
  static readonly InlivewithPostProcessMediaMediaPostIdPutPath = '/inlivewith/Post/ProcessMedia/{mediaPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaMediaPostIdPost
   */
  static readonly InlivewithPostProcessMediaMediaPostIdPostPath = '/inlivewith/Post/ProcessMedia/{mediaPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Plain$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Plain(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Json$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Json(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaCommentMediaCommentPostIdPut
   */
  static readonly InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath = '/inlivewith/Post/ProcessMediaComment/{mediaCommentPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaCommentMediaCommentPostIdPost
   */
  static readonly InlivewithPostProcessMediaCommentMediaCommentPostIdPostPath = '/inlivewith/Post/ProcessMediaComment/{mediaCommentPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Plain$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Plain(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Json$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Json(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
