<section class="cursor-pointer">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
        <div class="flex align-items-center w-100" (click)="handlePostCeleb()">
            <div class="zone-avatar" style="border-radius: 15rem !important;">
                <img [src]="post.author?.avatar! ? post.author?.avatar : defaultAvatar" class="fit-size cursor-pointer">
            </div>
            <div class="flex flex-column ml-2">
                <div class="flex align-items-center w-100">
                    <span class="mr-1 cursor-pointer w-100 fs-16"
                        style="color: #FFFFFF; font-weight: bold;">{{post.author?.nickName! | limitString:false:40}}
                    </span>
                    <!-- <div class="flex align-items-center" *ngIf="post.author?.isCelebrity!">
                        <img class="fit-size" src="../../../assets/images/svgs/celeb-post.svg">
                    </div> -->
                </div>
                <span class="text-disabled fs-11">{{timeAgo(post.dateCreated!)}}</span>
            </div>
        </div>

        <div>
            <i class="pi pi-ellipsis-h cursor-pointer" (click)="menu.toggle($event); renderLinkForCopy()"
                style="font-size: 24px"></i>
            <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" styleClass="menu-post"></p-menu>
        </div>
    </div>
    <div class="mt-3 mb-2" [class.show]="show" (click)="handlePostCeleb()">
        <div id="content" class="show overflow-wrap-breakword fs-14" style="white-space: break-spaces;"
            [innerHTML]="urlify(post!.message! | limitString:completeText:400)"></div>
    </div>
    <div class="text-right cursor-pointer" *ngIf="post?.message! && post?.message!.length! > 400">
        <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div>

    <div *ngIf="indexMetaLinks >= 0">
        <div *ngIf="post?.metaLinks?.length! >= 1 && (post?.metaLinks![indexMetaLinks].title != '' || post?.metaLinks![indexMetaLinks].domain != '') && !post.isBannedByClub"
            (click)="gotoUrl()" class="zone-metadata">
            <div class="zone-image-metadata" *ngIf="post?.metaLinks![indexMetaLinks].image! != ''">
                <img [src]="post.metaLinks![indexMetaLinks].image" class="fit-size">
            </div>
            <div class="zone-content-metadata">
                <span class="domain">
                    {{post.metaLinks![indexMetaLinks].domain}}
                </span>
                <span class="title">
                    {{post.metaLinks![indexMetaLinks].title}}
                </span>
            </div>
        </div>
    </div>

    <div class="flex justify-content-center w-100" (click)="handlePostCeleb()">
        <app-layout-media-post [id]="post!.id!" [post]="post" [isJoined]="isJoined" [isAdmin]="isAdmin"
            [listMember]="listMember">
        </app-layout-media-post>
    </div>

    <div class="flex align-items-center mt-5" *ngIf="_router.url.startsWith('/home')" (click)="handlePostCeleb()">
        <img class="fit-size mr-1" src="../../../assets/images/svgs/star.svg">
        <span class="ml-1 fs-14 text-white ">{{post.numberOfClub!}} {{(post.numberOfClub! > 1 ?
            'CLUBS' : 'CLUB') | translate | lowercase}}</span>
    </div>

    <div *ngIf="_router.url.startsWith('/manage-club/detail-club')"
        class="flex justify-content-between align-items-center mt-5" (click)="handlePostCeleb()">
        <div class="flex">
            <div class="flex align-items-center" style="margin-right: 24px">
                <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post!.isReacted!"
                    style="font-size: 24px; transition: color 0.5s" [ngClass]="{'liked' : post!.isReacted!}"
                    (click)="likePost()"></i>
                <span (click)="openZoneLike = true" class="fs-12 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
            </div>
            <div class="flex cursor-pointer align-items-center" (click)="openCommentZone()">
                <img class="cursor-pointer"
                    [src]="post!.isCommented ? '../../../assets/images/svgs/commented.svg' : '../../../assets/images/svgs/comment.svg'">
                <span class="fs-12 ml-2">{{post!.totalComment!}}</span>
            </div>
        </div>
        <div class="comment-zone">
            <div class="flex flex-column input-text justify-content-center ">
                <div class="flex flex-wrap" [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                    <div class="zone-media-cus" *ngFor="let url of urls; let i = index">
                        <img loading="lazy" class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url"
                            height="50">
                        <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url" height="50"
                            controls></video>
                        <img class="delete-asset" (click)="removeAsset(i)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>
                    <!-- <div *ngIf="urlAudio" class="position-relative">
                        <audio controls>
                            <source [src]="sanitize()" type="audio/wav">
                        </audio>
                        <img class="delete-asset-audio" (click)="removeAudio()"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div> -->
                </div>

                <div class="flex justify-content-center align-items-center" style="position: relative;">
                    <ng-template #mentionListTemplate let-item="item">
                        <div class="flex align-items-center">
                            <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40" height="40"
                                style="border-radius: 5px;">
                            <span class="p-1">{{item.name! | limitString:false:20}}</span>
                        </div>
                    </ng-template>
                    <ng-container *ngIf="openPopupRecordAudio">
                        <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'525px'"
                            [widthAudio]="'440px'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                            (sendAudio)="createComment()"></app-record-audio-v2>
                    </ng-container>
                    <textarea class="textarea-cus w-100" style="resize: none;" *ngIf="!openPopupRecordAudio && !created"
                        [rows]="1" [(ngModel)]="content" [mentionConfig]="mentionConfig"
                        [mentionListTemplate]="mentionListTemplate"
                        placeholder="{{'COMMENT_WRITE' | translate}}"></textarea>

                    <div #toggleEmoji (click)="clickOnSide($event)">
                        <emoji-mart [isNative]="true" class="emoji" [enableSearch]="false"
                            [ngClass]="(checkTypeMedia(post.mediaPosts![0]) == 'AUDIO' || post.mediaPosts?.length! == 0) ? 'emoji-audio' : 'emoji-media'"
                            *ngIf="showEmoji" [sheetSize]="32" (emojiClick)="addEmoji($event)"></emoji-mart>
                    </div>

                    <div class="flex justify-content-center align-items-center cursor-pointer"
                        *ngIf="!openPopupRecordAudio && !created">
                        <img class="cursor-pointer mr-3" src="../../../../assets/images/svgs/emojis.svg" alt=""
                            srcset="" height="16" (click)="changeShowEmoji($event)">
                        <img class="mr-3" src="../../../assets/images/svgs/mic.svg" (click)="startRecord()" height="16"
                            *ngIf="!urls.length">
                        <i class="pi pi pi-image mr-3" (click)="isJoined ? inputFile.click() : askJoinClub.emit()"
                            *ngIf="!urlAudio"></i>
                        <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                            *ngIf="content.length > 0 || (urls.length > 0) || (urlAudio && blobFileAudio)"></i>
                    </div>
                </div>
                <input style="display: none" type="file" #inputFile multiple (change)="detectFiles($event)">
            </div>

        </div>
    </div>

</section>

<ng-container *ngIf="showListClubsPosted">
    <app-list-clubs-celeb-post [postCelebId]="post.postCelebrityId!"
        [(showListClubsPosted)]="showListClubsPosted"></app-list-clubs-celeb-post>
</ng-container>

<ng-container *ngIf="showCommentZone">
    <app-comment-post [(showCommentZone)]="showCommentZone" [post]="post" [isAdmin]="isAdmin" (refresh)="refresh()"
        [listMember]="listMember" (deletePostEvent)="deletePostEvent.emit(this.post.id)"
        (commentEvent)="updateComment($event)">
    </app-comment-post>
</ng-container>

<ng-container *ngIf="openZoneLike">
    <app-list-like [(showDialog)]="openZoneLike" [isMobile]="false" [post]="post"></app-list-like>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'POST'" [nameObject]="this.post"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()"></app-report>
</ng-container>