<p-dialog [(visible)]="showDialog" (onHide)="onHide()" (onShow)="getAsset()" [draggable]="false" [resizable]="false"
    [modal]="true" [style]="{width: '90%', height: '90%'}">

    <div class="card h-100">
        <div class="flex row h-100" *ngIf="currentAsset">
            <div class="w-60">
                <p-carousel [value]="listAssets" [circular]="false" [page]="indexAsset"
                    (onPage)="getDetailAsset($event)" [numVisible]="1" [numScroll]="1">
                    <ng-template let-asset pTemplate="item">
                        <div class="flex h-100 w-100 justify-content-center align-items-centers position-relative">
                            <div class="flex align-items-center w-100 justify-content-center">
                                <img *ngIf="asset.url!.includes('?i=')" [src]="asset?.url!">

                                <video *ngIf="asset.url!.includes('?v=')" [id]="asset.id + 'v'" [src]="asset?.url!"
                                    controls></video>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
                <div class="flex align-items-center" style="margin-left: 6%;">
                    <div class="flex" style="width: 72px">
                        <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="currentAsset.isReacted!"
                            style="font-size: 24px; color: #fff; transition: color 0.5s" (click)="react()"
                            [ngClass]="{'liked' : currentAsset!.isReacted!}"></i>
                        <span (click)="openZoneLike = true"
                            class="fs-12 ml-2 cursor-pointer">{{currentAsset.totalReact!}}</span>
                    </div>
                    <div class="flex align-items-center ml-5 cursor-pointer">
                        <img class="cursor-pointer"
                            [src]="currentAsset!.isCommented ? '../../../assets/images/svgs/commented.svg' : '../../../assets/images/svgs/comment.svg'">
                        <span class="fs-12 ml-2">{{currentAsset!.totalComment!}}</span>
                    </div>
                </div>

            </div>
            <div class="w-40">

                <div class="flex flex-column h-100" *ngIf="currentAsset">
                    <div class="flex flex-column h-10">
                        <div class="flex align-items-center justify-content-between position-relative">
                            <div class="flex align-items-center">
                                <div class="zone-avt">
                                    <img [src]="currentAsset.author?.avatar ? currentAsset.author?.avatar : defaultAvatar"
                                        class="fit-size">
                                </div>
                                <span class="fs-18 text-white ml-3 mr-3">{{currentAsset.author?.nickName! | limitString:false:40}}</span>
                            </div>
                            <div class="flex align-items-center">
                                {{currentAsset.dateCreated! + 'Z' | date: 'dd/MM/YYYY - HH:mm'}}
                            </div>
                        </div>
                        <div [ngStyle]="currentAsset.content! ? {'height': '100px'} : {}"
                            class="text-white content mt-3" [innerHTML]="urlify(currentAsset!.content!)">
                        </div>
                    </div>
                    <div class="flex h-90 mt-1 position-relative">
                        <app-comment-album [popupChanged]="popupChanged" [commentAsset]="listComments" [listMention]="listMention"
                            [asset]="currentAsset" (refresh)="refresh()" [isJoined]="isJoined">
                        </app-comment-album>
                    </div>
                </div>

            </div>
        </div>
    </div>

</p-dialog>

<ng-container *ngIf="openZoneLike">
    <p-dialog [(visible)]="openZoneLike" [resizable]="false" [draggable]="false" [closable]="true" [modal]="true"
        (onHide)="openZoneLike = false" [style]="{width: '30%', height: '90%'}">
        <app-list-like-media-album [albumAssetId]="currentAsset.id!"></app-list-like-media-album>
    </p-dialog>
</ng-container>

<ng-container *ngIf="applyClub">
    <app-confirm-request-join-club [(isConfirmJoinClub)]="applyClub" (isConfirmJoinClubChange)="back()"></app-confirm-request-join-club>
</ng-container>