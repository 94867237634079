<p-dialog [(visible)]="showDialog" [modal]="true" [draggable]="false" (onHide)="onHide()" [resizable]="false"
    [closable]="true">

    <div class="card">
        <div class="flex title">
            {{'STAGE' | translate}}
        </div>

        <div class="flex flex-column align-items-center">
            <div class="w-80 mt-3 item-stage" *ngFor="let stage of listStage">
                <div class="flex justify-content-between align-items-baseline">
                    <div class="w-100">
                        <div class="flex" *ngIf="currentStage?.id !== stage?.id">
                            <i class="pi pi-lock mr-3" *ngIf="stage.isPrivate"></i>
                            <i class="pi pi-lock-open mr-3" *ngIf="!stage.isPrivate"></i>
                            <div class="text-stage-name">
                                <span>{{stage.name}}</span>
                            </div>
                        </div>
                        <div *ngIf="currentStage?.id === stage?.id">
                            <div class="mt-5 w-80 p-input-icon-right">
                                <i>{{getCharacterLeft().numberLeft}}</i>
                                <input [(ngModel)]="tempName" class="w-100" type="text" (blur)="checkExistName()"
                                    [value]="currentStage!.name!" pInputText [placeholder]="currentStage!.name!"/>
                            </div>
                            <div *ngIf="isExisited" class="text-error fs-16">
                                {{'SCENE_EXIST' | translate}}
                            </div>
                        </div>
                    </div>

                    <div class="flex cursor-pointer" *ngIf="!stage.isDefault && currentStage?.id !== stage?.id">
                        <i class="pi pi-pencil mr-3" (click)="collapseZoneStage(stage)"></i>
                        <i class="pi pi-trash" (click)="deleteStage(stage)"></i>
                    </div>
                    <div class="flex cursor-pointer align-items-center" style="height: 40px;"
                        *ngIf="currentStage?.id === stage?.id">
                        <button pButton label="{{'REGISTER' | translate}}"
                            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                            (click)="editStage(currentStage)"></button>
                        <button pButton label="{{'CANCEL' | translate}}"
                            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                            (click)="isEditting = false; currentStage = {}"></button>
                        <img class="ml-2" src="../../../assets/images/svgs/trash-bg-white.svg" (click)="deleteStage(currentStage)">
                    </div>
                </div>
                <div>
                    <div class="flex row mt-5 align-items-center" *ngIf="currentStage?.id === stage?.id">
                        <div class="justify-content-center flex">
                            <div class="label mb-2 flex align-items-center"
                                [ngClass]="isPrivate ? 'text-disabled' : ''">
                                <img class="mr-2"
                                    [src]="isPrivate ? '../../../assets/images/svgs/unlock-disabled.svg': '../../../assets/images/svgs/unlock.svg'">
                                {{'CLUB_STAGE_STATUS_PUBLIC' | translate}}
                            </div>
                        </div>
                        <div class="col-2 justify-content-center flex">
                            <p-inputSwitch [(ngModel)]="isPrivate"></p-inputSwitch>
                        </div>
                        <div class="justify-content-center flex">
                            <div class="label mb-2 flex align-items-center"
                                [ngClass]="!isPrivate ? 'text-disabled' : ''">
                                <img class="mr-2"
                                    [src]="isPrivate ? '../../../assets/images/svgs/lock.svg': '../../../assets/images/svgs/lock-disabled.svg'">
                                {{'CLUB_STAGE_STATUS_PRIVATE' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentStage?.id === stage?.id" class="text-disabled">
                        {{'CLUB_STAGE_INLIVERPLUS' | translate}}
                    </div>
                    <div class="mt-5" *ngIf="currentStage?.id === stage?.id && isPrivate">
                        <span class="text-white fs-18">
                            {{'ADVANCE_OPTION' | translate}}
                        </span><br><br>
                        <p-checkbox [(ngModel)]="isAcceptViewPrivate" class="fs-14" [binary]="true"
                            label="{{'CLUB_STAGE_ACCEPT' | translate}}">
                        </p-checkbox>
                    </div>
                    <div class="mt-4" *ngIf="currentStage?.id === stage?.id">
                        <span class="text-white fs-18">{{'CLUB_STAGE_LINKED_ALBUM' | translate}}</span><br>
                        <span class="text-disabled fs-11">{{'CLUB_STAGE_LINKED_ALBUM_TITLE' | translate}}</span>
                        <div class="mt-4 flex" *ngFor="let item of stage.albums">
                            <div class="flex align-items-center" *ngIf="!item?.isDeleted!">
                                <div class="zone-avatar">
                                    <img [src]="item?.coverImage!" class="fit-size" alt="" srcset="">
                                </div>
                                <span class="text-white ml-2 fs-14">
                                    {{item.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex" *ngIf="currentStage?.id !== stage?.id">
                        <div class="flex pt-3 pr-3" *ngFor="let item of stage.albums">
                            <span class="album-name" *ngIf="!item?.isDeleted!">
                                <i class="pi pi-link mr-1"></i>
                                {{item.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</p-dialog>