<p-sidebar [(visible)]="showSidebar" position="bottom" [showCloseIcon]="false" [dismissible]="false">
    <div class="text-center text-white">
        <span>
            {{'CLUB_ALBUM_DELETE_ASSETS_CONFIRM' | translate}}
        </span>
    </div>
    <div class="flex align-items-center justify-content-center mt-5">
        <button pButton label="{{'CANCEL' | translate}}"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="cancel()"></button>
        <button pButton label="{{'YES' | translate}}"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="onDeleteAsset()"></button>
    </div>
</p-sidebar>