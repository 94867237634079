<p-dialog [(visible)]="showDialog" [modal]="true" [draggable]="false" (onHide)="onHide()" [resizable]="false"
    [closable]="true">
    <div class="card">
        <div class="fex header">
            {{'CLUB_DESCRIPTION' | translate}}
        </div>

        <div class="flex flex-column content mt-5">

            <span class="label"> 
                {{'DESCRIPTION' | translate}}:    
            </span>
            <div class="mt-5">
                <textarea class="" [rows]="8" [(ngModel)]="description"></textarea>
            </div>

            <div class="flex justify-content-center mt-5">
                <button pButton label="{{'REGISTER' | translate}}"
                    class="btn-custom" (click)="submit()"></button>
            </div>
        </div>
    </div>
</p-dialog>