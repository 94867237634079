<div class="flex flex-column cursor-pointer align-items-center" style="position: relative;" (click)="gotoAccountInfo()">
    <div class="card" pRipple>
        <div class="zone-avt">
            <img [src]="account!.avatar! ? account!.avatar!: account!.avatar! | fetchUrlImage" alt="" class="fit-size">
        </div>
    </div>
    <div class="flex justify-content-between">
        <div class="name-club mt-2">
            {{(account!.nickName! | limitString:false:40) || 'name'}}
        </div>
    </div>
</div>