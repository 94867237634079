<p-dialog [(visible)]="showDialogRegister" [draggable]="false" [resizable]="false" [modal]="true" (onHide)="onHide()">
    <ng-container *ngIf="statusRegister == 'INIT'">
        <div class="card">
            <div class="title">
                {{'INSCRIPTION' | translate}}
            </div>

            <div class="content">
                <div class="flex flex-column birthday-pick">
                    <span>
                        {{'BIRTHDAY' | translate}}
                    </span>
                    <div class="mt-3 flex">
                        <input id="birthday" [(ngModel)]="day" class="w-100" style="margin: 5px;" type="number" pInputText placeholder="DD" />
                        <input id="birthday" [(ngModel)]="month" class="w-100" style="margin: 5px;" type="number" pInputText placeholder="MM" />
                        <input id="birthday" [(ngModel)]="year" class="w-100" style="margin: 5px;" type="number" pInputText placeholder="YYYY" />
                    </div>
                    <span *ngIf="!checkAgeValid" class="text-error mt-3">
                        {{'BIRTHDAY_CHECK_AGE' | translate}}
                    </span>
                    <span *ngIf="!checkAgeFormat" class="text-error mt-3">
                        {{'BIRTHDAY_CHECK_VALID' | translate}}
                    </span>
                </div>
                <div class="flex w-100 flex-column mt-5">

                    <div class="flex p-input-icon-right flex-column" [ngClass]="checkEmail ? 'pb-1' : 'pb-4'">
                        <input [(ngModel)]="email" autocomplete="off" name="email" pInputText placeholder="E-mail" (blur)="checkEmailExistRegister($event)"/>
                    </div>
                    <div *ngIf="checkEmail" class="text-error m-1">
                        <span *ngIf="checkEmailExist">
                            {{'EMAIL_EXISTED' | translate}}
                        </span>
                        <!-- <span *ngIf="emailConfirmed">
                            This mail has been registered but hasnt activated yet
                        </span> -->
                    </div>

                    <div class="flex p-input-icon-right flex-column">
                        <i class="pi cursor-pointer text-white"
                            [ngClass]="{'pi-eye-slash': fieldTextType, 'pi-eye' : !fieldTextType}"
                            (click)="toggleFieldTextType()"></i>
                        <input [(ngModel)]="password" [type]="inputType" pInputText placeholder="{{'PASSWORD' | translate}}"
                            autocomplete="off" (ngModelChange)="checkPassWord()" />
                    </div>

                    <div class="flex pt-5 pb-3 flex list-valid flex-column">
                        <div class="flex align-items-center m-1" [ngClass]="CheckLength() ? 'text-white' : 'text-gray'">
                            <i class="pi pi-check-circle mr-3"></i>
                            <span>
                                {{'PASSWORD_REQUIRED_LENGTH' | translate}}
                            </span>
                        </div>
                        <div class="flex align-items-center m-1"
                            [ngClass]="CheckLeastOneNumber() ? 'text-white' : 'text-gray'">
                            <i class="pi pi-check-circle mr-3"></i>
                            <span>
                                {{'PASSWORD_REQUIRED_NUMBER' | translate}}
                            </span>
                        </div>
                        <div class="flex align-items-center m-1"
                            [ngClass]="CheckLeastOneSpecialCharacter() ? 'text-white' : 'text-gray'">
                            <i class="pi pi-check-circle mr-3"></i>
                            <span>
                                {{'PASSWORD_REQUIRED_SPECIAL' | translate}}
                            </span>
                        </div>
                        <div class="flex align-items-center m-1"
                            [ngClass]="CheckLeastOneUpperCase() ? 'text-white' : 'text-gray'">
                            <i class="pi pi-check-circle mr-3"></i>
                            <span>
                                {{'PASSWORD_REQUIRED_UPPERCASE' | translate}}
                            </span>
                        </div>

                    </div>

                    <div class="flex p-input-icon-right flex-column">
                        <i class="pi cursor-pointer text-white"
                            [ngClass]="{'pi-eye-slash': fieldTextTypeForConfirm, 'pi-eye' : !fieldTextTypeForConfirm}"
                            (click)="toggleFieldTextTypeConfirm()"></i>
                        <input [(ngModel)]="confirmPassword" [type]="inputTypeForConfirm" name="password" autocomplete="new-password"
                            (ngModelChange)="checkPassWord()" pInputText placeholder="{{'PASSWORD_CONFIRM' | translate}}" />
                    </div>

                    <div class="flex align-items-center m-1 text-error"
                        *ngIf="password != confirmPassword && confirmPassword">
                        <span>
                            {{'PASSWORD_CONFIRM_REQUIRED' | translate}}
                        </span>
                    </div>


                    <div class="flex pt-5 pb-3 flex list-valid flex-column">
                        <div class="flex align-items-center"
                            [ngClass]="acceptReceiveMail ? 'text-white' : 'text-gray'">
                            <div>
                                <input [(ngModel)]="acceptReceiveMail" type="checkbox" name="groupname" id="groupname">
                            </div>
                            <label for="groupname" class="ml-3 cursor-pointer">
                                {{'OPTION_RECEIVE_MAIL' | translate}}
                            </label>
                        </div>
                        <div class="flex align-items-center mt-3"
                            [ngClass]="acceptCondition ? 'text-white' : 'text-gray'">
                            <div>
                                <input [(ngModel)]="acceptCondition" type="checkbox" name="groupname2" id="groupname2">
                            </div>
                            <label for="groupname2" class="ml-3 cursor-pointer" [innerHTML]="'OPTION_ACCEPT_PRIVACY' | translate"></label>
                        </div>

                    </div>

                    <div class="flex pt-3 pb-3 justify-content-center">
                        <button pButton type="submit" label="{{'NEXT' | translate}}" class="mt-5 p-button-outlined p-button-secondary"
                            (click)="register()">
                        </button>
                    </div>

                    <div class="pt-5">
                        <div class="text">
                            <span>
                                {{'OR' | translate}}
                            </span>
                        </div>
                        <div class="line w-100"></div>
                    </div>

                    <div class="pt-5">
                        <div class="flex">
                            <div class="flex col-4 justify-content-center">
                                <img class="cursor-pointer" src="../../../assets/images/svgs/google.svg">
                            </div>
                            <div class="flex col-4 justify-content-center">
                                <img class="cursor-pointer" src="../../../assets/images/svgs/facebook.svg">
                            </div>
                            <div class="flex col-4 justify-content-center">
                                <img class="cursor-pointer" src="../../../assets/images/svgs/apple.svg">
                            </div>
                        </div>
                        <div class="flex justify-content-center pt-5">
                            {{'ACCOUNT_ALREADY_HAD' | translate}} <strong class="cursor-pointer ml-1" (click)="login()">
                                {{'LOGIN_ACCOUNT' | translate}} </strong>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="statusRegister == 'SENDMAIL_SUCCESS'">
        <app-register-success [emailCheck]="email" (login)="login()"></app-register-success>
    </ng-container>

</p-dialog>