<p-sidebar id="confirm" [(visible)]="showPopupConfirm" position="bottom" [modal]="true" [baseZIndex]="2" [dismissible]="false">
    <div class="flex flex-column align-items-center justify-content-center text-center">

        <div class="flex position-fixed">
            <div class="zone-avt">
                <img [src]="imgBlob" class="fit-size">
            </div>
        </div>

        <div class="label mt-5">
            {{name}}
        </div>

        <div class="fs-16 text-white flex flex-column text-center mt-3 mb-5">
            <span>
                {{'ALBUM_CONFIRM_CREATE' | translate}}
            </span>
        </div>

    </div>
    <div class="flex align-items-center justify-content-center mt-5">
        <button pButton label="Annuler"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2" (click)="closePopup()">
        </button>
        <button pButton label="Publier"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="doAction.emit()">
        </button>
    </div>
</p-sidebar>