import { AuthService } from 'src/app/core/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {
  GoInliveResponseModelWithClubDetail,
  NotificationResponse,
} from 'src/app/core/api/be/models';
import {
  GoInliveService,
  NotificationService,
} from 'src/app/core/api/be/services';
import { LoadingService } from 'src/app/core/services/loading.service';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-card-invite-goinlive',
  templateUrl: './card-invite-goinlive.component.html',
  styleUrls: ['./card-invite-goinlive.component.scss'],
})
export class CardInviteGoinliveComponent implements OnInit {
  @Input() invitation!: NotificationResponse;
  goInliveId!: string;
  isShowReviewCamera: boolean = false;
  live!: GoInliveResponseModelWithClubDetail;
  defaultAvatar = defaultAvatar;
  constructor(
    private _router: Router,
    private _goInliveService: GoInliveService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  goGoInlive() {
    LoadingService.$(true);
    this._goInliveService
      .inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json({
        goInliveId: this.goInliveId!,
        isStreaming: true,
      })
      .pipe(
        tap((res) => {
          this.live = res.data!;
          if (res.res == 0) {
            if (res?.data && res?.data!.isStreaming!) {
              this._router
                .navigate(['go-inlive-view'], {
                  queryParams: {
                    channel: res.data!.channelName!,
                    hostUid: res.data?.author?.agoraUid!,
                    type: res?.data.type,
                    goInliveId: res?.data.id,
                    mode: res?.data.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
                  },
                })
                .then(() => {
                  this.joinGoinlive();
                });
            }
          }
        })
      )
      .subscribe(() => {
        LoadingService.$(false);
      });
  }

  deleteInvitation() {
    this._notificationService
      .inlivewithNotificationDeleteDelete$Json({ notiId: this.invitation.id! })
      .subscribe();
  }

  doAction(isAccept: boolean) {
    if (isAccept) {
      this.isShowReviewCamera = true;
    } else {
      this.handleInvite(false).subscribe(() => location.reload());
    }
  }

  handleInvite(isAccept: boolean) {
    let body = {
      invitationId: this.invitation?.content!,
      accountId: AuthService.GetAccountInfo().id!,
      isAccept: isAccept,
      isDeny: !isAccept,
    };

    return this._goInliveService.inlivewithGoInliveSelectGoInlivedInvitePut$Json(
      {
        body,
      }
    );
  }

  acceptJoin() {
    this.handleInvite(true)
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.goInliveId = res.data?.goInliveId!;
            this.goGoInlive();
          } else {
            location.reload();
          }
        })
      )
      .subscribe();
  }

  joinGoinlive(role?: string) {
    let body = {
      goInliveId: this.goInliveId,
      accountId: AuthService.GetAccountInfo().id,
      clubId: this.live!.clubs![0]!.id!,
      role: 'STREAMER',
    };
    return this._goInliveService.inlivewithGoInliveJoinGoInlivePost$Json({
      body,
    });
  }
}
