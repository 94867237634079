import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api/menuitem';
import { forkJoin, Subject } from 'rxjs';
import { tap, switchMap, debounceTime, mergeMap } from 'rxjs/operators';
import {
  AccountResponseInClub,
  ClubResponseWithTotalMemberModel,
  EventResponseModel,
  EventWithCheckAccountJoinEventResponseModel,
  MessageResponseModel,
  NotificationTabDot,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  ClubService,
  EventService,
  NotificationService,
} from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoginService } from 'src/app/core/services/login.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('searchZone') searchZone!: ElementRef;

  @Input() openSlideTutorial = false;
  @Output() openSlideTutorialChange = new EventEmitter();

  environment = environment;

  showDialog: boolean = false;
  showDialogRegister: boolean = false;

  items!: MenuItem[];
  openMenuMulti: boolean = false;
  openSearchZone: boolean = false;

  listLanguage: string[] = ['FR', 'EN'];

  languageSelected = LocalStorageService.GetLocalStorage('language');

  NAV_MENU = {
    HOME: 'HOME',
    EXPLORER: 'EXPLORER',
    CHAT: 'CHAT',
    NOTIFICATION: 'NOTIFICATION',
  };

  links: string[] = [];

  menuSelected: string = this.NAV_MENU.HOME;
  pathAssets = '../../../assets/images/svgs/';

  isLoggedIn: boolean = false;
  searchText!: string;
  clubs: (ClubResponseWithTotalMemberModel & { order?: number })[] = [];
  inlivers: (AccountResponseInClub & { order?: number })[] = [];
  events: (EventResponseModel & { order?: number })[] = [];

  readonly defaultAvatar: string =
    '../../../assets/images/svgs/avatar-default.svg';

  t = new Date().getTime();
  isJoinClub: any;

  refetchWhenReceiveNoti$ =
    this._notificationServicee.isReloadNotiIsRead$$.pipe(
      tap((isAuth) => {
        if (isAuth) {
          this.checkHaveNoti();
        }
      })
    );
  haveNoti = false;

  dataCheckNoti!: NotificationTabDot;

  constructor(
    public _router: Router,
    private _translateService: TranslateService,
    private _clubService: ClubService,
    private _accountService: AccountService,
    private _eventService: EventService,
    private _activeRoute: ActivatedRoute,
    private _notificationService: NotificationService,
    private _notificationServicee: NotificationServicee,
    private titleService: Title,
    private messageService: MessageService
  ) {
    // AuthService.OnChangeAvatar().subscribe((res) => (this.avatar = res));

    if (!LocalStorageService.GetLocalStorage('language')) {
      LocalStorageService.AddLocalStorage('language', 'FR');
    }

    this.languageSelected = LocalStorageService.GetLocalStorage('language');

    Object.keys(this.NAV_MENU).forEach((x) => {
      this.links.push(x.toLowerCase());
    });

    this._activeRoute.queryParams
      .pipe(
        tap((params) => {
          this.searchText = params!.searchText!;
        })
      )
      .subscribe();
  }

  account = AuthService.GetAccountInfo();
  search$ = new Subject<string>();

  ngOnInit(): void {
    this.refetchWhenReceiveNoti$.subscribe();
    this.items = [
      {
        icon: 'pi pi-user',
        label: this._translateService.instant('MY_PROFILE'),
        command: () => {
          this.openEditUser();
        },
      },
      {
        icon: 'pi pi-heart-fill',
        label: this._translateService.instant('MY_FAVOURITE'),
        command: () => {
          this.openMyFavourite();
        },
      },
      {
        icon: 'pi pi-flag',
        label: this._translateService.instant('REPORTS'),
        command: () => {
          this.openSignalements();
        },
      },
      {
        icon: 'pi pi-cog',
        label: this._translateService.instant('SETTINGS_AND_PRIVACY'),
        command: () => {
          this.openSettingUser();
        },
      },
      {
        icon: 'pi pi-book',
        label: this._translateService.instant('MENTION_LEGALS'),
        command: () => {
          this.openLegales();
        },
      },
      {
        icon: 'pi pi-question-circle',
        label: this._translateService.instant('CONTACT'),
        command: () => {
          this.openContact();
        },
      },
      {
        icon: 'tutorial',
        label: this._translateService.instant('TUTORIAL'),
        command: () => {
          this.openSlideTutorialChange.emit(true);
        },
      },
      {
        icon: 'pi pi-sign-out',
        label: this._translateService.instant('LOGOUT'),
        command: () => {
          this.logout();
        },
      },
    ];

    !this.account ? (this.isLoggedIn = false) : (this.isLoggedIn = true);

    setTimeout(() => {
      if (
        this._router.url.startsWith('/complete-account') ||
        this._router.url.startsWith('/reset-password')
      ) {
        return;
      }
    }, 1000);

    this.search$
      .pipe(
        debounceTime(500),
        mergeMap((res) => this.handleObsForSearch(res))
      )
      .subscribe((res) => {
        console.log(res);

        const list = [...this.clubs!, ...this.inlivers!, ...this.events!];
        this.openSearchZone = list.length > 0;
        const max = 10000;
        list.forEach((o) => {
          let order = Math.round(Math.random() * max);
          o.order = order;
        });
      });
  }

  ngAfterViewInit() {
    this.checkHaveNoti();
    document.querySelector('.layout')?.addEventListener(
      'click',
      (e) => {
        this.checkIfClickedInside(e);
      },
      true
    );
  }

  ngOnDestroy(): void {}

  getAccount() {
    return AuthService.GetAccountInfo();
  }

  login() {
    LoginService.$(true);
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['explorer']).then(() => {
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  openEditUser() {
    this._router.navigate(['/edit-user']);
  }

  openSettingUser() {
    this._router.navigate(['/setting-user/notification']);
  }

  openLegales() {
    window.open(
      environment.INLIVE_GROUP_URL + 'conditions-generales.html',
      '_blank'
    );
  }

  openSignalements() {
    this._router.navigate(['/signalements', AuthService.GetAccountInfo().id!]);
  }

  openMyFavourite() {
    this._router.navigate(['/my-favourite']);
  }

  selectLanguage(event: any) {
    this.languageSelected = event.value;
    LocalStorageService.AddLocalStorage('language', this.languageSelected);
    this.showNotification(this._translateService.instant('CHANGE_INFOR_CLUB'));
  }

  openMenuMultiApp() {
    this.openMenuMulti = !this.openMenuMulti;
    if (
      this.modal?.nativeElement?.className ===
      'flex flex-column position-absolute menu-multi-app ng-star-inserted'
    ) {
      this.openMenuMulti = false;
    }
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modal?.nativeElement?.contains(event.target);
    if (!isClickInside) {
      this.openMenuMulti = false;
    }
  };

  openDetailClub(item: ClubResponseWithTotalMemberModel) {
    this.searchText = '';
    this._router
      .navigate(['manage-club/detail-club'], {
        queryParams: { clubId: item?.id! },
      })
      .then(() => window.location.reload());
  }

  gotoAccountInfo(item: AccountResponseInClub) {
    if (AuthService.GetAccountInfo().id! !== item?.id!) {
      this.searchText = '';
      this._router.navigate(['account-info'], {
        queryParams: { accountId: item?.id! },
      });
    }
  }

  detailEvent(event: EventWithCheckAccountJoinEventResponseModel) {
    this.searchText = '';
    // this._router.navigate(['manage-club/detail-event', event.id!], {
    //   queryParams: { eventId: event.id!, clubId: event.clubId! },
    // });
    this.searchText = '';
    this._router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: event.clubId! },
    });
  }

  private handleObsForSearch(searchText: string) {
    let findClub = this._clubService
      .inlivewithClubsLikeNameWithBannedNameGet$Json({
        name: searchText.trim(),
        accountBannedId: AuthService.GetAccountInfo()?.id ?? '',
        limit: 10000,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.clubs = res.data?.page!;
          }
        })
      );

    let findInliver = this._accountService
      .inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json(
        {
          nickname: searchText.trim(),
          limit: 10000,
          accountRequestId: AuthService.GetAccountInfo()?.id ?? '',
        }
      )
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.inlivers = res.data?.page!;
          }
        })
      );

    let findEvent = this._eventService
      .inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json({
        name: searchText.trim(),
        accountRequestId: AuthService.GetAccountInfo()?.id ?? '',
      })
      .pipe(
        tap((res) => {
          this.events = res.data?.page!;
        })
      );

    if (AuthService.GetAccountInfo()?.id!) {
      return forkJoin([findClub, findInliver, findEvent]);
    } else {
      return forkJoin([findClub]);
    }
  }

  showMessage(text: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      detail: text,
      key: 'notificationOnWeb',
      closable: false,
      life: 3 * 1000,
      data: '',
    });
  }

  search() {
    if (this.searchText.trim() !== '' && this.searchText.trim().length > 1) {
      this.search$.next(this.searchText);
    } else {
      this.clubs = [];
      this.inlivers = [];
      this.events = [];
    }
  }

  gotoSearchPage(event: KeyboardEvent) {
    if (event && event.code == 'Enter') {
      if (this.searchText.trim() == '') {
        return;
      }

      if (this.searchText.trim().length < 2) {
        this.showMessage(this._translateService.instant('SEARCH_TEXT_MAX_2'));
        return;
      }

      this._router.navigate(['research'], {
        queryParams: { searchText: this.searchText! },
      });
    }
  }

  clickMenu(item: string) {
    if (item.includes('notification')) {
      // this.setIsReadNotificationFor('ACTUS');
    } else {
      this.checkHaveNoti();
    }
  }

  outFocus() {
    setTimeout(() => {
      this.openSearchZone = false;
    }, 1000);
  }

  getItem(item: string) {
    if (item == 'home') {
      return '34';
    } else if (item == 'explorer') {
      return '44';
    } else if (item == 'chat') {
      return '36';
    }
    return '40';
  }

  openContact() {
    window.open(
      environment.INLIVE_GROUP_URL +
        'contact.html' +
        `?callback=${environment.BASE_URL}`
    );
  }

  showNotification(subtitle: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: subtitle,
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => window.location.reload());
  }

  checkHaveNoti() {
    console.log('ccccccccccccccccc');

    if (!this.account) {
      return;
    }

    this._accountService
      .inlivewithAccountNotificationTabDotGet$Json()
      .subscribe((res) => {
        this.haveNoti = [
          res.data!.actus,
          res.data!.amis,
          res.data!.moderation,
        ].includes(true);

        this.dataCheckNoti = res.data!;
      });
  }
}
