<p-sidebar [(visible)]="showCommentZone" position="bottom" [dismissible]="false" [modal]="true" [showCloseIcon]="true"
    (onHide)="onHide()">
    <div class="login-modal">
        <div class="content">
            <div class="text-center fs-20 pb-5">
                {{'COMMENT' | translate}} {{post!.totalComment! > 0 ? '(' + post!.totalComment! + ')' : ''}}
            </div>
            <div class="list-comment">
                <div class="comments" *ngIf="post?.commentPosts?.length! > 0">
                    <ng-container *ngFor="let item of comments">
                        <div class="item">
                            <div class="avatar">
                                <img class="fit-size cursor-pointer" [src]="item?.author?.avatar! | fetchUrlImage"
                                    alt="" (click)="openAccountInfo(item.authorId!)">
                            </div>
                            <div class="detail">
                                <div class="name" (click)="openAccountInfo(item?.author?.id!)">
                                    {{item?.author?.nickName!}}
                                </div>
                                <div class="time">
                                    <span>{{timeAgo(item?.dateCreated!)}}</span>
                                </div>
                                <div class="content">
                                    {{item?.message}}
                                </div>
                                <div style="position: relative">

                                    <!-- <div class="flex justify-content-between zone-arrow"
                                        *ngIf="checkOverflow(item?.id!)">
                                        <i class="pi pi-arrow-left arrow cursor-pointer"
                                            (click)="scrollTo(item.id!)"></i>
                                        <i class="pi pi-arrow-right arrow cursor-pointer"
                                            (click)="scrollTo(item.id!, false)"></i>
                                    </div> -->

                                    <div class="flex list-media" *ngIf="item?.mediaCommentPosts?.length! > 0"
                                        [id]="item.id">
                                        <div class="mr-1" *ngFor="let asset of item?.mediaCommentPosts!">

                                            <div class="flex justify-content-center align-items-center ml-5"
                                                *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 100px">
                                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                            </div>

                                            <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                                style="height:  10rem">
                                                <p-image [preview]="true" [src]="getUrl(asset)"></p-image>
                                            </div>

                                            <div class="w-100" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                    [url]="asset?.url!" [poster]="getUrl(asset)">
                                                </app-video-layer>
                                            </div>

                                            <div *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                <audio controls>
                                                    <source [src]="asset?.url!">
                                                </audio>
                                            </div>

                                        </div>

                                    </div>

                                    <!-- <div *ngIf="item?.mediaCommentPosts?.length! > 0">
                                        <div class="flex justify-content-center media w-100" *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
                                            <div class="flex flex-column w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                <div class="flex flex align-items-center justify-content-center zone-audio">
                                                    <div class="cursor-pointer flex align-items-center justify-content-center" (click)="onPlayButtonClick()"
                                                        style="width: 4rem;">
                                                        <i class="pi pi-play btn-audio" *ngIf="!isPlayingAudio"></i>
                                                        <i class="pi pi-pause btn-audio" *ngIf="isPlayingAudio"></i>
                                                    </div>
                                    
                                                    <div class="ware-form">
                                                        <ng-waveform #waveform class="waveform" [src]="(asset?.newUrl!)" backgroundColor="#242536"
                                                            regionBackgroundColor="rgba(255, 255, 255, 0.7)" regionStartStickColor="#21f032"
                                                            regionEndStickColor="red" regionTextColor="#09417e" [withRegionLabels]="true"
                                                            waveColor="#50c9ff" (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)"
                                                            (durationChange)="onDurationChange($event)" (timeUpdate)="onTimeUpdate($event)"
                                                            (paused)="onPaused()" (regionChange)="onRegionChange($event)">
                                                        </ng-waveform>
                                                    </div>
                                    
                                                    <div style="width: 4rem; text-align: center">
                                                        {{timeOffAudio*1000 | date:'mm:ss'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="action">
                                    <div class="flex">
                                        <img src="../../../assets/images/svgs/reply.svg" alt="">
                                        <span (click)="focusText(item)">{{'RESPONSE' | translate}}</span>
                                    </div>
                                    <div class="flex flex-column mt-5 input-text" id="replyComment"
                                        *ngIf="isHaveParent && currentReplyComment.id === item.id">
                                        <div class="flex flex-wrap"
                                            [ngStyle]="urlsChild.length > 0 || urlAudioChild ? {'padding':'1.5rem'} : {}">
                                            <div class="position-relative" style="overflow: auto;"
                                                *ngFor="let url of urlsChild; let i = index">
                                                <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url"
                                                    height="100">
                                                <video class="m-3" [src]="url.data"
                                                    *ngIf="url.format === 'video' && url" height="100" controls></video>
                                                <img class="delete-asset" (click)="removeAssetChild(i)"
                                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                                            </div>
                                            <div *ngIf="urlAudioChild" class="position-relative">
                                                <audio controls>
                                                    <source [src]="sanitizeChild()" type="audio/wav">
                                                </audio>
                                                <img class="delete-asset-audio" (click)="removeAudioChild()"
                                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <textarea class="textarea-cus w-100" style="resize: none;"
                                                [(ngModel)]="contentReply"
                                                [placeholder]="('COMMENT_REPLY_PLACEHOLDER' | translate) + '@' + currentReplyComment.author?.nickName!"
                                                autofocus></textarea>
                                            <div class="flex justify-content-end pt-4">
                                                <div class="cursor-pointer flex align-items-center pt-4">
                                                    <img class="mr-3" src="../../../assets/images/svgs/mic.svg"
                                                        (click)="startRecord()" *ngIf="!urlsChild.length">
                                                    <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                                                        *ngIf="!urlAudioChild"></i>
                                                    <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                                        *ngIf="contentReply.length > 0 || (urlsChild.length > 0) || (urlAudioChild && blobFileAudioChild)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                                        (change)="detectFiles($event)" accept="image/png, image/jpeg,video/*">
                                </div>
                            </div>
                            <div class="action-menu">
                                <div>
                                    <i class="pi pi-ellipsis-h"
                                        (click)="menu.toggle($event); isHaveParent = false;"></i>
                                    <p-menu #menu [popup]="true" [model]="getItem(item)" [appendTo]="'body'"
                                        styleClass="menu-comment"></p-menu>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item && item?.inverseParent?.length! > 0">
                            <div *ngFor="let itemChild of item?.inverseParent">
                                <div class="item-child">
                                    <div class="avatar">
                                        <img class="fit-size cursor-pointer"
                                            [src]="itemChild?.author?.avatar ? itemChild.author?.avatar : defaultAvatar"
                                            alt="" (click)="openAccountInfo(itemChild)">
                                    </div>
                                    <div class="detail">
                                        <div class="name">
                                            {{itemChild?.author?.nickName!}}
                                        </div>
                                        <div class="time">
                                            <span>{{timeAgo(itemChild?.dateCreated!)}}</span>
                                        </div>
                                        <div class="content">
                                            {{itemChild?.message}}
                                        </div>
                                        <div class="mt-5 mb-5" style="position: relative">

                                            <div class="flex justify-content-between zone-arrow"
                                                *ngIf="checkOverflow(itemChild?.id!)">
                                                <i class="pi pi-arrow-left arrow cursor-pointer"
                                                    (click)="scrollTo(itemChild.id!)"></i>
                                                <i class="pi pi-arrow-right arrow cursor-pointer"
                                                    (click)="scrollTo(itemChild.id!, false)"></i>
                                            </div>

                                            <div class="flex list-media"
                                                *ngIf="itemChild?.mediaCommentPosts?.length! > 0" [id]="itemChild.id">
                                                <div *ngFor="let asset of itemChild?.mediaCommentPosts!">

                                                    <div class="flex justify-content-center align-items-center ml-5"
                                                        *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')"
                                                        style="height: 70px">
                                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                                    </div>

                                                    <div class="flex mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                                        style="height: 100px">
                                                        <p-image [src]="getUrl(asset)" alt=""></p-image>
                                                    </div>

                                                    <div class="flex mr-4" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                        <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                            [url]="asset?.url!" [poster]="getUrl(asset)">
                                                        </app-video-layer>
                                                    </div>

                                                    <div class="flex" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                        <audio controls>
                                                            <source [src]="asset?.url!">
                                                        </audio>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-menu">
                                        <div>
                                            <i class="pi pi-ellipsis-h"
                                                (click)="menu.toggle($event); isHaveParent = false;"></i>
                                            <p-menu #menu [popup]="true" [model]="getItem(itemChild)"
                                                [appendTo]="'body'" styleClass="menu-comment"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="comments text-disabled text-center" *ngIf="post?.commentPosts?.length! == 0"
                    [innerHTML]="'POST_COMMENT_EMPTY' | translate"></div>
                <div class="flex flex-column mt-5 input-text">
                    <div class="flex flex-wrap" *ngIf="(urls.length > 0 || urlAudio) && !isEditComment"
                        [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                        <div class="position-relative" style="overflow: auto;" *ngFor="let url of urls; let i = index">
                            <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url" height="150">
                            <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url" height="150"
                                controls></video>
                            <img class="delete-asset" (click)="removeAsset(i)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                        <div *ngIf="urlAudio" class="position-relative">
                            <audio controls>
                                <source [src]="sanitize()" type="audio/wav">
                            </audio>
                            <img class="delete-asset-audio" (click)="removeAudio()"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>
                    <div class="flex flex-wrap"
                        *ngIf="isEditComment && currentReplyComment?.mediaCommentPosts?.length! > 0"
                        [ngStyle]="isEditComment && currentReplyComment!.mediaCommentPosts!.length! > 0 ? {'padding':'1.5rem'} : {}">
                        <div class="position-relative" style="overflow: auto;"
                            *ngFor="let media of currentReplyComment?.mediaCommentPosts!; let i = index">
                            <img class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'IMAGE'" height="150">
                            <video class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'VIDEO'" height="150"
                                controls></video>
                            <audio controls *ngIf="checkTypeMedia(media) == 'AUDIO'">
                                <source [src]="media.url" type="audio/wav">
                            </audio>
                            <img class="delete-asset" (click)="deleteFromComment(media)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>
                    <div class="flex flex-wrap" *ngIf="(urls.length > 0 || urlAudio) && isEditComment"
                        [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                        <div class="position-relative" style="overflow: auto;" *ngFor="let url of urls; let i = index">
                            <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url" height="150">
                            <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url" height="150"
                                controls></video>
                            <img class="delete-asset" (click)="removeAsset(i)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                        <div *ngIf="urlAudio" class="position-relative">
                            <audio controls>
                                <source [src]="sanitize()" type="audio/wav">
                            </audio>
                            <img class="delete-asset-audio" (click)="removeAudio()"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>

                    <div class="flex">
                        <textarea class="textarea-cus w-100" style="resize: none;" [(ngModel)]="content"
                            placeholder="{{'POST_PLACEHOLDER' | translate}}"></textarea>
                        <div class="flex justify-content-end pt-4">
                            <div class="cursor-pointer flex pt-4 align-items-center">
                                <img class="mr-3" src="../../../assets/images/svgs/mic.svg" (click)="startRecord()"
                                    *ngIf="(!urls.length && (!isEditComment)) || (isEditComment && ((checkTypeMedia(currentReplyComment?.mediaCommentPosts![0]) == 'AUDIO' || currentReplyComment?.mediaCommentPosts?.length! == 0) && !urls.length))">
                                <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                                    *ngIf="(!urlAudio && (!isEditComment)) || (isEditComment && ((checkTypeMedia(currentReplyComment?.mediaCommentPosts![0]) == 'VIDEO' || checkTypeMedia(currentReplyComment?.mediaCommentPosts![0]) == 'IMAGE' || currentReplyComment?.mediaCommentPosts?.length! == 0) && !urlAudio))"></i>
                                <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                    *ngIf="content?.length! > 0 || (urls?.length! > 0) || (urlAudio! && blobFileAudio!) || (isEditComment)"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                    (change)="detectFiles($event)" accept="image/png, image/jpeg,video/*">

            </div>
        </div>
    </div>
</p-sidebar>

<ng-container *ngIf="isShowPopupConfirm">
    <app-mobile-confirm-delete-comment [(isShowPopup)]="isShowPopupConfirm" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'DELETE' | translate" (doAction)="deleteComment()" [title]="'COMMENT_DELETE' | translate"
        [content]="'COMMENT_DELETE_CONFIRM' | translate:{value: currentReplyComment.author?.nickName!}">
    </app-mobile-confirm-delete-comment>
</ng-container>

<ng-container *ngIf="openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudio" [(blobFile)]="blobFileAudio">
    </app-record-audio>
</ng-container>


<ng-container *ngIf="isHaveParent && currentReplyComment && openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudioChild" [(blobFile)]="blobFileAudioChild">
    </app-record-audio>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'COMMENT'" [nameObject]="this.currentReplyComment"
        (refreshWhenReportSuccess)="getCommentByPostId()">
    </app-report>
</ng-container>