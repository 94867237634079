<div class="flex flex-column pt-3 pb-3 cursor-pointer raw-noti">
    <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
            <div class="flex">
                <div class="zone-avatar">
                    <img [src]="invitation!.senderAvatar ?? defaultAvatar" class="fit-size">
                </div>
                <div class="flex align-items-center w-80" style="white-space: pre-line;">
                    <span class="ml-2" [innerHTML]="invitation!.subTitle!.replace('Clique ici pour le rejoindre !','')"></span>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="flex align-items-center justify-content-end w-100 time-noti">
                {{invitation.updateDate! + 'Z' | date: 'dd/MM/yy, HH:mm'}}
            </div>
        </div>

    </div>
    <div class="flex ml-6 mt-2 zone-action" *ngIf="invitation!.statusGoInlive! == 'PENDING_GOINLIVE'">
        <button pButton label="{{'REFUSE' | translate}}"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="doAction(false)">
        </button>
        <button pButton label="{{'ACCEPT' | translate}}"
            class="p-button-red btn-accept text-white  border-white ml-2" (click)="doAction(true)">
        </button>
    </div>

    <div class="flex ml-5 text-gray mt-2 zone-action" style="margin-left: 4rem !important;"
        *ngIf="invitation!.statusGoInlive! == 'DENY_GOINLIVE'">
        {{ 'DENY' | translate}}
    </div>

    <div class="flex ml-5 text-gray mt-2 zone-action" style="margin-left: 4rem !important;"
        *ngIf="invitation!.statusGoInlive! == 'CANCEL_GOINLIVE'">
        {{ 'EXPIRED' | translate}}
    </div>
</div>


<ng-container *ngIf="isShowReviewCamera">
    <p-sidebar [(visible)]="isShowReviewCamera" position="bottom" [fullScreen]="true" [showCloseIcon]="false"
        [dismissible]="true">
        <app-review-camera (close)="isShowReviewCamera = false" (join)="acceptJoin()"></app-review-camera>
    </p-sidebar>
</ng-container>