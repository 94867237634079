import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fetchUrlVideo'
})
export class FetchUrlVideoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return '../../assets/images/loading_circle_bars.mp4';
  }

}
