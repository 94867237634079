import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
  HttpXhrBackend,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from '../api/be/request-builder';
import { FormBodyBuilder, STORAGE_KEY } from '../constant';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) { }

  public AddMediaPosts(
    file: Blob,
    path?: string,
    hook?: string,
    id?: string
  ) {
    const endPoint = '/Upload/Media';
    const body = {
      File: [file],
      ServiceId: environment.SERVICE_ID,
      Path: path,
      Hook: hook,
      Id: id,
      CustomLength: -1
    };
    return this._request(body, endPoint, 'post');
  }

  private _request(
    body: any,
    endPoint: string,
    method: string,
    context?: HttpContext
  ) {
    const rb = new RequestBuilder(environment.UPLOAD_URL, endPoint, method);
    rb.body(body, 'multipart/form-data');

    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'text/json',
        reportProgress: true,
      })
    );
  }
}
