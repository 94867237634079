import { Component, Input, OnInit } from '@angular/core';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { FriendRelationService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-friend-request',
  templateUrl: './card-friend-request.component.html',
  styleUrls: ['./card-friend-request.component.scss'],
})
export class CardFriendRequestComponent implements OnInit {
  @Input() notification!: NotificationResponse;

  defaultAvatar = defaultAvatar;

  constructor(private _friendService: FriendRelationService) {}

  ngOnInit(): void {}

  doAction(isAccept: boolean) {
    this._friendService
      .inlivewithFriendRelationApprovalRequestAddFriendPut$Json({
        body: {
          accountRelatedId: AuthService.GetAccountInfo().id!,
          accountRelatingId: this.notification!.senderId!,
          approval: isAccept,
        },
      })
      .subscribe(() => {
        window.location.reload();
      });
  }
}
