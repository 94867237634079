import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  Account,
  AccountResponseWithRoleInCLubModel,
  ClubResponseWithTotalMemberAndGoInliveModel,
} from 'src/app/core/api/be/models';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { FriendRelationService } from 'src/app/core/api/be/services/friend-relation.service';
import { defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-list-members-club',
  templateUrl: './list-members-club.component.html',
  styleUrls: ['./list-members-club.component.scss'],
})
export class ListMembersClubComponent implements OnInit {
  @Input() openPopup!: boolean;
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() title!: string;

  listMembers: AccountResponseWithRoleInCLubModel[] = [];
  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];
  listInliver: AccountResponseWithRoleInCLubModel[] = [];
  listAdminstrators: AccountResponseWithRoleInCLubModel[] = [];

  currentAccount!: AccountResponseWithRoleInCLubModel;

  @Output() openPopupChange = new EventEmitter();

  isSelectAll: boolean = false;
  isFriend: boolean = false;
  isRequestedFriend!: boolean;
  openPopupBanInliver!: boolean;
  openPopupReport: boolean = false;

  defaultAvatar = defaultAvatar;
  accountOwner = AuthService.GetAccountInfo().id!;

  listMenu!: MenuItem[];

  searchText!: string;
  isReceivedFriendRequest!: boolean;

  constructor(
    private _clubService: ClubService,
    private _router: Router,
    private _translateService: TranslateService,
    private _accountService: AccountService,
    private _friendService: FriendRelationService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getAllMemebers();
  }

  hidePopup() {
    this.openPopupChange.emit(false);
  }

  getAllMemebers() {
    this._clubService
      .inlivewithClubGetAllMembersClubIdGet$Json({
        clubId: this.club.id!,
        limit: 100000,
      })
      .pipe(
        tap((res) => {
          this.listMembers = res.data?.page!;
          this.splitList();
        })
      )
      .subscribe();
  }

  search() {
    if (this.searchText == '') {
      this.getAllMemebers();
    } else {
      this._clubService
        .inlivewithClubMembersLikeNameClubIdCriteriaGet$Json({
          clubId: this.club.id!,
          criteria: this.searchText,
          limit: 10000,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.listMembers = res.data?.page!;
              this.splitList();
            }
          })
        )
        .subscribe();
    }
  }

  splitList() {
    this.listAdminstrators = this.listMembers.filter(
      (x) => x.roleInClub === 'ROLE_ADMIN'
    )!;
    this.listInliverPlus = this.listMembers.filter(
      (x) =>
        x.roleInClub === 'ROLE_ANONYMOUS' &&
        x.accessLevelInClub === 'ACCESSLEVEL_PLUS'
    );
    this.listInliver = this.listMembers.filter(
      (x) =>
        x.roleInClub === 'ROLE_ANONYMOUS' &&
        x.accessLevelInClub === 'ACCESSLEVEL_ANONYMOUS'
    );
  }

  getColorRole(account: AccountResponseWithRoleInCLubModel) {
    if (this.club.author?.id! === account.id!) {
      return 'ml-2 text-creator';
    }
    if (
      this.listMembers.some(
        (x) => x.id === account.id && x.roleInClub == 'ROLE_ADMIN'
      )
    ) {
      return 'ml-2 text-admin';
    } else return 'text-inliver';
  }

  getItemMenu(account: AccountResponseWithRoleInCLubModel, typeUser: string) {
    var limitStringPipe = new LimitStringPipe();
    let isAdmin = this.listMembers.some(
      (x) =>
        x.id === AuthService.GetAccountInfo().id! &&
        x.roleInClub == 'ROLE_ADMIN'
    );

    if (typeUser == 'MODERATERS') {
      return (this.listMenu = [
        {
          icon: 'pi pi-users',
          label: this._translateService.instant('PROFILE_SEE'),
          command: (event: any) => {
            this.openAccountInfo(account);
          },
        },
        {
          icon: 'pi pi-user-minus',
          label: limitStringPipe.transform(this._translateService.instant('RETIRE') +
            ` ${account.nickName!} ` +
            this._translateService.instant('OF_THE_CLUB'), false, 30),

          visible: AuthService.GetAccountInfo().id == this.club.author?.id,
          command: (event: any) => {
            this.rejectMember(account);
          },
        },
        {
          icon: 'pi pi-ban',
          label: limitStringPipe.transform(this._translateService.instant('BAN') + ' @' + account.nickName!, false, 30),
          visible: AuthService.GetAccountInfo().id == this.club.author?.id,
          command: () => {
            this.currentAccount = account;
            this.openPopupBanInliver = true;
          },
        },
        {
          icon: 'pi pi pi-flag',
          label: limitStringPipe.transform(this._translateService.instant('REPORT') + ' @' + account.nickName!, false, 30),
          command: () => {
            this.currentAccount = account;
            this.openPopupReport = true;
          },
        },
      ]);
    }
    if (typeUser == 'INLIVERSPLUS') {
      return (this.listMenu = [
        {
          icon: 'pi pi-users',
          label: this._translateService.instant('PROFILE_SEE'),
          command: (event: any) => {
            this.openAccountInfo(account);
          },
        },
        {
          icon: 'pi pi-user-minus',
          label: this._translateService.instant('REMOVE_INLIVERPLUS'),
          visible: isAdmin,
          command: (event: any) => {
            this.updateAccessLevelAnonymous(account);
          },
        },
        {
          icon: 'pi pi-user-minus',
          label:
            limitStringPipe.transform(this._translateService.instant('RETIRE') +
              ` ${account.nickName!} ` +
              this._translateService.instant('OF_THE_CLUB'), false, 30),
          visible: isAdmin,
          command: (event: any) => {
            this.rejectMember(account);
          },
        },
        {
          icon: 'pi pi-ban',
          label: limitStringPipe.transform(this._translateService.instant('BAN') + ' @' + account.nickName!, false, 30),
          visible: isAdmin,
          command: () => {
            this.currentAccount = account;
            this.openPopupBanInliver = true;
          },
        },
        {
          icon: 'pi pi pi-flag',
          label: limitStringPipe.transform(this._translateService.instant('REPORT') + ' @' + account.nickName!, false, 30),
          command: () => {
            this.currentAccount = account;
            this.openPopupReport = true;
          },
        },
      ]);
    }
    if (typeUser == 'INLIVERS') {
      return (this.listMenu = [
        {
          icon: 'pi pi-users',
          label: this._translateService.instant('PROFILE_SEE'),
          command: (event: any) => {
            this.openAccountInfo(account);
          },
        },
        {
          icon: 'pi pi-user-plus',
          label: this._translateService.instant('ADD_INLIVER_PLUS'),
          visible: isAdmin,
          command: (event: any) => {
            this.updateAccessLevelPlus(account);
          },
        },
        {
          icon: 'pi pi-user-minus',
          label: limitStringPipe.transform(this._translateService.instant('RETIRE') +
            ` ${account.nickName!} ` +
            this._translateService.instant('OF_THE_CLUB'), false, 30),
          visible: isAdmin,
          command: (event: any) => {
            this.rejectMember(account);
          },
        },
        {
          icon: 'pi pi-ban',
          visible: isAdmin,
          label: limitStringPipe.transform(this._translateService.instant('BAN') + ' @' + account.nickName!, false, 30),
          command: () => {
            this.currentAccount = account;
            this.openPopupBanInliver = true;
          },
        },
        {
          icon: 'pi pi pi-flag',
          label: limitStringPipe.transform(this._translateService.instant('REPORT') + ' @' + account.nickName!, false, 30),
          command: () => {
            this.currentAccount = account;
            this.openPopupReport = true;
          },
        },
      ]);
    }

    return [];
  }

  rejectMember(account: AccountResponseWithRoleInCLubModel) {
    LoadingService.$(true);
    let body = {
      accountId: account.id!,
      clubId: this.club.id,
    };
    this._accountService
      .inlivewithAccountClubAccountIdDelete$Plain({
        accountId: account.id!,
        body,
      })
      .subscribe(() => {
        LoadingService.$(false);
        this.refreshWhenReportSuccess();
      });
  }

  checkIsFriend(accountId: string) {
    this._friendService
      .inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json(
        {
          accountRelatingId: this.accountOwner,
          accountRelatedId: accountId,
        }
      )
      .subscribe((res) => {
        if (res.res == 0) {
          this.isFriend = res?.data?.isFriend!;
          this.isRequestedFriend = res.data?.isAddFriend!;
          this.isReceivedFriendRequest = res.data?.isReceivedFriendRequest!;
        }
      });
  }

  addFriend(account: AccountResponseWithRoleInCLubModel) {
    this._friendService
      .inlivewithFriendRelationAddFriendPost$Json({
        accountRelatingId: this.accountOwner,
        accountRelatedId: account.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.checkIsFriend(account.id!);
          }
        })
      )
      .subscribe();
  }

  removeFriend(account: AccountResponseWithRoleInCLubModel) {
    this._friendService
      .inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json(
        {
          accountRelatingId: this.accountOwner,
          accountRelatedId: account.id!,
        }
      )
      .pipe(
        tap((res) => {
          0;
        })
      )
      .subscribe();
  }

  unBanAccount(account: AccountResponseWithRoleInCLubModel) {
    return this._clubService
      .inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json({
        adminClubId: AuthService.GetAccountInfo().id!,
        clubId: this.club.id!,
        body: [account.id!],
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.getNewListMember();
          }
        })
      )
      .subscribe();
  }

  getNewListMember() {
    this.getAllMemebers();
  }

  openAccountInfo(account: AccountResponseWithRoleInCLubModel) {
    if (AuthService.GetAccountInfo().id! !== account.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: account?.id! },
      });
    }
  }

  bannedInliver(account: AccountResponseWithRoleInCLubModel) {
    this._clubService
      .inlivewithClubCreateAccountClubBannedPost$Json({
        body: {
          accountBannedId: account.id!,
          authorId: AuthService.GetAccountInfo().id!,
          clubId: this.club.id!,
          type: 'ADMIN_BANNED_MEMBER',
        },
      })
      .pipe(tap(() => this.getNewListMember()))
      .subscribe();
  }

  refreshWhenReportSuccess() {
    this.openPopupReport = false;
    this.ngOnInit();
  }

  approveFriendRequest(account: AccountResponseWithRoleInCLubModel) {
    let body = {
      accountRelatedId: AuthService.GetAccountInfo().id,
      accountRelatingId: account.id,
      approval: true,
    };
    this._friendService
      .inlivewithFriendRelationApprovalRequestAddFriendPut$Json({ body })
      .subscribe((res) => {
        if (res.res == 0) {
          window.location.reload();
        }
      });
  }

  refuseFriendRequest(account: AccountResponseWithRoleInCLubModel) {
    let body = {
      accountRelatedId: account.id,
      accountRelatingId: AuthService.GetAccountInfo().id,
      approval: false,
    };
    this._friendService
      .inlivewithFriendRelationApprovalRequestAddFriendPut$Json({ body })
      .subscribe((res) => {
        if (res.res == 0) {
          window.location.reload();
        }
      });
  }

  updateAccessLevelPlus(account: AccountResponseWithRoleInCLubModel) {
    return this._accountService
      .inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json({
        accountId: account.id!,
        clubId: this.club.id!,
        body: {
          accountId: account.id!,
          clubId: this.club.id!,
        },
      })
      .pipe(
        tap(() =>
          this.showNotification(
            account.nickName +
            ' ' +
            this._translateService.instant('GRANT_INLIVER_PLUS_SUCCESS')
          )
        )
      )
      .subscribe(() => {
        this.refreshWhenReportSuccess();
      });
  }

  updateAccessLevelAnonymous(account: AccountResponseWithRoleInCLubModel) {
    return this._accountService
      .inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json({
        accountId: account.id!,
        clubId: this.club.id!,

        body: {
          accountId: account.id!,
          clubId: this.club.id!,
        },
      })
      .pipe(
        tap(() =>
          this.showNotification(
            account.nickName +
            ' ' +
            this._translateService.instant('DELETE_INLIVERPLUS_SUCCESS')
          )
        )
      )
      .subscribe(() => {
        this.refreshWhenReportSuccess();
      });
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }
}
