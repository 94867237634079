<section class="zone-setting" *ngIf="_router.url.startsWith('/setting-user/notification')">
    <div class="flex flex-column p-3">
        <div class="header flex text-white align-items-center">
            <i class="pi pi-bell mr-3"></i>
            <span class="fs-18">
                {{'NOTIFICATION' | translate}}
            </span>
        </div>
        <div class="flex flex-column mt-5">
            <span class="text-white fs-18">
                {{'NOTIFICATION_PUSH' | translate}}
            </span>
            <span class="text-small fs-14 mt-2">
                {{'NOTIFICATION_PUSH_TITLE' | translate}}
            </span>
        </div>
        <div class="zone-switch">
            <div class="zone-item-switch align-items-center">
                <span>
                    {{'PUBLICATION' | translate}}
                </span>
                <p-inputSwitch [(ngModel)]="isPublicationTurnOn"></p-inputSwitch>
            </div>
            <div class="zone-item-switch align-items-center">
                <span>
                    {{'IDENTIFICATION' | translate}}
                </span>
                <p-inputSwitch [(ngModel)]="isIdentificationTurnOn"></p-inputSwitch>
            </div>
            <div class="zone-item-switch align-items-center">
                <span>
                    {{'MESSAGE_PRIVATE' | translate}}
                </span>
                <p-inputSwitch [(ngModel)]="isMessageTurnOn"></p-inputSwitch>
            </div>
            <div class="zone-item-switch align-items-center">
                <span>
                    {{'FRIEND_REQUEST' | translate}}
                </span>
                <p-inputSwitch [(ngModel)]="isAddFriendRequestTurnOn"></p-inputSwitch>
            </div>
        </div>
        <div class="flex flex-column mt-5">
            <span class="text-white fs-18">
                {{'NOTIFICATION_EMAIL' | translate}}
            </span>
            <span class="text-small fs-14 mt-2">
                {{'NOTIFICATION_EMAIL_TITLE' | translate}}
            </span>
        </div>
        <div class="zone-switch">
            <div class="zone-item-switch">
                <span>
                    {{'EMAIL_COMMERCIAL' | translate}}
                </span>
                <p-inputSwitch [(ngModel)]="isAdvertisementTurnOn"></p-inputSwitch>
            </div>
        </div>
        <div class="mt-5 flex justify-content-center">
            <button pButton type="submit" (click)="applyChangesSettings()" label="{{'SAVE_CHANGES' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary"></button>
        </div>
    </div>
</section>

<section class="zone-setting" *ngIf="_router.url.startsWith('/setting-user/security')">
    <div class="flex flex-column p-3">
        <div class="header flex text-white align-items-center">
            <i class="pi pi-bell mr-3"></i>
            <span class="fs-18">
                {{'SECURITY_PRIVACY' | translate}}
            </span>
        </div>
        <div class="zone-switch">
            <div class="zone-item-switch">
                <div class="flex flex-column">
                    <span class="text-white fs-18">
                        {{'USER_STATUS' | translate}}
                    </span>
                    <span class="text-small fs-14 mt-2">
                        {{'USER_STATUS_TITLE' | translate}}
                    </span>
                </div>
                <p-inputSwitch [(ngModel)]="isPublicAccount"></p-inputSwitch>
            </div>
            <div class="zone-item-switch" *ngIf="isPublicAccount">
                <div class="flex flex-column">
                    <span class="text-white fs-18">
                        {{'MESSAGE_RECEIVE' | translate}}
                    </span>
                    <span class="text-small fs-14 mt-2">
                        {{'MESSAGE_RECEIVE_ANYONE' | translate}}
                    </span>

                </div>
                <p-inputSwitch [(ngModel)]="isAcceptRecieveMessageAnyone"></p-inputSwitch>
            </div>
        </div>
        <div class="flex align-items-center justify-content-between text-white mt-3 cursor-pointer"
            (click)="openListBlock = true">
            <div class="flex align-items-center">
                <i class="pi pi-ban mr-3"></i>
                <span class="fs-18">
                    {{'INLIVER_BLOCK' | translate}}
                </span>
            </div>
            <i class="pi pi-chevron-right"></i>
        </div>

        <div class="flex align-items-center justify-content-between text-white mt-5 cursor-pointer"
            (click)="changepass()">
            <div class="flex align-items-center ml-5">
                <!-- <i class="pi pi-ban mr-3"></i> -->
                <span class="fs-18">
                    {{'CHANGE_PASSWORD' | translate}}
                </span>
            </div>
            <i class="pi pi-chevron-right"></i>
        </div>
        <!-- <div class="zone-password">
            <span class="text-white fs-18">
                {{'PASSWORD_CURRENT' | translate}}
            </span>
            <div class="flex p-input-icon-right flex-column mb-5">
                <i class="pi cursor-pointer text-white"
                    [ngClass]="{'pi-eye-slash': fieldTextTypeOldPassword, 'pi-eye' : !fieldTextTypeOldPassword}"
                    (click)="toggleFieldTextTypeOldPassword()"></i>
                <input [(ngModel)]="oldPassword" [type]="inputTypeForOldPassword" pInputText placeholder="{{'PASSWORD_CURRENT' | translate}}" autocomplete="off" />
            </div>

            <span class="text-white fs-18">
                {{'PASSWORD_NEW' | translate}}
            </span>
            <div class="flex p-input-icon-right flex-column">
                <i class="pi cursor-pointer text-white"
                    [ngClass]="{'pi-eye-slash': fieldTextType, 'pi-eye' : !fieldTextType}"
                    (click)="toggleFieldTextType()"></i>
                <input [(ngModel)]="password" [type]="inputType" pInputText placeholder="{{'PASSWORD_NEW' | translate}}"
                    autocomplete="off" (ngModelChange)="checkPassWord()" />
            </div>
    
            <div class="flex pt-5 pb-5 flex list-valid flex-column">
                <div class="flex align-items-center m-1"
                    [ngClass]="CheckLeastOneNumber() ? 'text-green' : 'text-red'">
                    <i class="pi pi-check-circle mr-3"></i>
                    <span>
                        {{'PASSWORD_NEW_REQUIRED_NUMBER' | translate}}
                    </span>
                </div>
                <div class="flex align-items-center m-1"
                    [ngClass]="CheckLeastOneUpperCase() ? 'text-green' : 'text-red'">
                    <i class="pi pi-check-circle mr-3"></i>
                    <span>
                        {{'PASSWORD_NEW_REQUIRED_UPPERCASE' | translate}}
                    </span>
                </div>
                <div class="flex align-items-center m-1"
                    [ngClass]="CheckLeastOneSpecialCharacter() ? 'text-green' : 'text-red'">
                    <i class="pi pi-check-circle mr-3"></i>
                    <span>
                        {{'PASSWORD_NEW_REQUIRED_SPECIAL' | translate}}
                    </span>
                </div>
                <div class="flex align-items-center m-1" [ngClass]="CheckLength() ? 'text-green' : 'text-red'">
                    <i class="pi pi-check-circle mr-3"></i>
                    <span>
                        {{'PASSWORD_NEW_REQUIRED_LENGTH' | translate}}
                    </span>
                </div>
            </div>
    
            <span class="text-white fs-18">
                {{'PASSWORD_CONFIRM' | translate}}
            </span>
            <div class="flex p-input-icon-right flex-column">
                <i class="pi cursor-pointer text-white"
                    [ngClass]="{'pi-eye-slash': fieldTextTypeForConfirm, 'pi-eye' : !fieldTextTypeForConfirm}"
                    (click)="toggleFieldTextTypeConfirm()"></i>
                <input [(ngModel)]="confirmPassword" [type]="inputTypeForConfirm" name="password" autocomplete="new-password"
                    (ngModelChange)="checkPassWord()" pInputText placeholder="{{'PASSWORD_CONFIRM' | translate}}" />
            </div>
    
            <div class="flex align-items-center m-1 text-error"
                *ngIf="password != confirmPassword && confirmPassword">
                <span>
                    {{'PASSWORD_CONFIRM_REQUIRED' | translate}}
                </span>
            </div>
        </div> -->
        <div class="mt-5 flex justify-content-center">
            <button pButton type="submit" label="{{'SAVE_CHANGES' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary" (click)="applyChanges()"></button>
        </div>
    </div>
</section>

<p-dialog [(visible)]="openListBlock" [draggable]="false" [resizable]="false" [closable]="true" [modal]="true"
    (onHide)="openListBlock = false">
    <div style="width: 650px;">
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'USER_LIST_BLOCKED' | translate}}
            </span>
        </div>
        <div class="mt-3">
            {{'USER_LIST_BLOCKED_TITLE' | translate}}
        </div>
        <div class="mt-3" *ngFor="let item of listAccountsBlocked">
            <div class="flex align-items-center justify-content-between mt-2 mb-2">
                <div class="flex align-items-center cursor-pointer" (click)="gotoAccountInfo(item)">
                    <div class="zone-avatar">
                        <img [src]="item?.avatar! ? item?.avatar! : defaultAvatar" class="fit-size">
                    </div>
                    <div class="text-white fs-16 ml-3">
                        <span>{{item?.nickName! | limitString:false:40}}</span>
                    </div>
                </div>
                <div>
                    <button pButton label="{{'UNBLOCK' | translate}}" (click)="openConfirmUnBlock(item)"
                        class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2">
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<ng-container *ngIf="openConfirmUnBlockPopup">
    <app-card-confirm [(isShowPopup)]="openConfirmUnBlockPopup"
        [title]="('UNBLOCK' | translate) + ' ' +(this.currentUserIsChoosing.nickName! | limitString:false:40)"
        [content]="'CONFIRM_UNBLOCK' | translate:{value:(this.currentUserIsChoosing.nickName! | limitString:false:40)}"
        [firstButtonTitle]="'CANCEL' | translate" [secondButtonTitle]="'CONFIRM' | translate" (doAction)="unBlock()">
    </app-card-confirm>
</ng-container>