<div class="card p-3 mt-3" id="scrollId">
    <div class="title">
        {{ 'FOLLOWED_CLUBS' | translate}} ({{listClubFollowing.length!}})
    </div>

    <div class="flex flex-wrap" style="overflow: hidden;">
        <div class="flex mt-4 mr-2 ml-2" *ngFor="let club of listClubFollowing.slice(0,6)">
            <app-card-club [club]="club"></app-card-club>
        </div>
    </div>

    <div class="flex align-items-center justify-content-center mt-3 cursor-pointer" routerLink="/club-list"
        *ngIf="listClubFollowing.length! > 6">
        <span class="fs-14" style="font-weight: bold;">{{ 'SEE_MORE' | translate}}</span>
    </div>

    <ng-container *ngIf="listClubFollowing.length == 0">
        <div class="flex mt-5">
            <span class="text-gray">
                {{'CLUB_YOU_JOIN_SHOW_UP_HERE' | translate}}
            </span>
        </div>
        <div class="flex justify-content-end">
            <button style="height: 1rem;font-size: 12px;" pButton pRipple type="button" label="{{'EXPLORER_NOW' | translate}}" routerLink="explorer"
                class="p-button-outlined p-button-secondary text-white">
            </button>
        </div>
    </ng-container>
</div>