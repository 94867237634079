import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-step-iii',
  templateUrl: './report-step-iii.component.html',
  styleUrls: ['./report-step-iii.component.scss']
})
export class ReportStepIiiComponent implements OnInit {

  @Input() name!: string
  @Input() nameObject!: string;
  @Input() type!: string
  @Input() description: string = '';

  @Output() next = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() descriptionChange = new EventEmitter();

  title!: string;

  constructor(
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    console.log(this.name, this.type);

    if (this.type == "EVENT") {
      this.title = this._translateService.instant('REPORT_EVENT');
    } else if (this.type == "CLUB") {
      this.title = this._translateService.instant('REPORT_CLUB');
    } else if (this.type == "COMMENT" || this.type == "COMMENT_ALBUM") {
      this.title = this._translateService.instant('REPORT_COMMENT');
    } else if (this.type == "POST") {
      this.title = this._translateService.instant('REPORT_POST');
    } else if (this.type == "INLIVER") {
      this.title = this._translateService.instant('REPORT_INLIVER');
    }
  }

  goNext(){
    this.descriptionChange.emit(this.description);
    this.next.emit();
  }

}
