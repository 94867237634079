import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-noti-report-content-member',
  templateUrl: './noti-report-content-member.component.html',
  styleUrls: ['./noti-report-content-member.component.scss'],
})
export class NotiReportContentMemberComponent implements OnInit {
  @Input() notification!: NotificationResponse;
  @Output() refreshPage = new EventEmitter();

  defaultAvatar = defaultAvatar;

  constructor() {}

  ngOnInit(): void {}
}
