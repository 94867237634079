/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AgoraService } from './services/agora.service';
import { AlbumService } from './services/album.service';
import { AlbumAssetsService } from './services/album-assets.service';
import { ApiInfosService } from './services/api-infos.service';
import { AppEnumService } from './services/app-enum.service';
import { CelebrityService } from './services/celebrity.service';
import { ClubService } from './services/club.service';
import { ConversationService } from './services/conversation.service';
import { DatabaseService } from './services/database.service';
import { EmailService } from './services/email.service';
import { EventService } from './services/event.service';
import { FriendRelationService } from './services/friend-relation.service';
import { GoInliveService } from './services/go-inlive.service';
import { GoInliveScheduleService } from './services/go-inlive-schedule.service';
import { HashtagService } from './services/hashtag.service';
import { HealthcheckService } from './services/healthcheck.service';
import { MediaCommentAlbumAssetsService } from './services/media-comment-album-assets.service';
import { MediaCommentPostsService } from './services/media-comment-posts.service';
import { MediaMessagesService } from './services/media-messages.service';
import { MediaPostsService } from './services/media-posts.service';
import { MentionService } from './services/mention.service';
import { MessageService } from './services/message.service';
import { NotificationService } from './services/notification.service';
import { PostService } from './services/post.service';
import { ReportService } from './services/report.service';
import { StageService } from './services/stage.service';
import { SystemSettingService } from './services/system-setting.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AgoraService,
    AlbumService,
    AlbumAssetsService,
    ApiInfosService,
    AppEnumService,
    CelebrityService,
    ClubService,
    ConversationService,
    DatabaseService,
    EmailService,
    EventService,
    FriendRelationService,
    GoInliveService,
    GoInliveScheduleService,
    HashtagService,
    HealthcheckService,
    MediaCommentAlbumAssetsService,
    MediaCommentPostsService,
    MediaMessagesService,
    MediaPostsService,
    MentionService,
    MessageService,
    NotificationService,
    PostService,
    ReportService,
    StageService,
    SystemSettingService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
