import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  MediaCreateResponseModel,
  MediaPostResponseModel,
  MentionReceiver,
  PostInputEditContentModel,
  PostResponseModel,
} from 'src/app/core/api/be/models';
import { ClubService, MentionService, PostService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NgWaveformComponent } from 'ng-waveform';
import { MobileService } from 'src/app/core/services/is-mobile.service';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  MENTION_REGEX,
  TYPE_MENTION,
  URL_REGEX,
  getFileExtension,
  typeAudio,
  typeImageMedia,
} from 'src/app/core/constant';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { OpenGraphModel } from 'src/app/core/api/metareader/models';
import { LinkPreviewService } from 'src/app/core/api/metareader/services';
import { UploadService } from 'src/app/core/services/upload.service';
@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss'],
})
export class EditPostComponent implements OnInit {
  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;

  private _post!: PostResponseModel;
  @Input() set post(value: PostResponseModel) {
    if (value) {
      console.log(value);

      this._post = value;
      this.content = this.getInnerHTML(value.message!);
      this.mediaPosts = value.mediaPosts!;
    }
  }
  get post() {
    return this._post;
  }

  @Input() listMention: any[] = [];
  mentionConfig: any = {};

  @Input() showDialog!: boolean;
  @Output() showDialogChange = new EventEmitter<boolean>();

  @Output() updatePostEvent = new EventEmitter<PostResponseModel>();

  listMediaIdDelete: string[] = [];

  mediaPosts: MediaPostResponseModel[] = [];

  openPopupRecordAudio: boolean = false;

  content: any;
  urls: any[] = [];
  filePosts: any[] = [];
  urlAudio!: string;
  blobFileAudio!: Blob;
  format!: string;
  typeToEditPost!: string;

  isValidToAddAssets: boolean = true;
  isValidToAddAudio: boolean = true;
  confirmCancelPost: boolean = false;
  notifySuccess: boolean = false;
  isShowRuleEdit: boolean = false;
  isHasText: boolean = false;

  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;
  countClick = 0;

  // For audio files
  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  isMobile: boolean = this._mobileService.isMobile();

  tribute!: any;

  metaDataItem!: OpenGraphModel;
  indexMetaLinks: number = 0;

  constructor(
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    private _mobileService: MobileService,
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _metaReader: LinkPreviewService,
    private _mentionService: MentionService,
    private _uploadService: UploadService
  ) { }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  ngAfterViewInit(): void {
    let elm = document.getElementById('card');
    if (this.isMobile) {
      elm!.style.width! = '90vw';
    } else {
      elm!.style.width! = '641px';
    }
  }

  ngOnInit(): void {
    this.getMetaLinksIndex();
    this.initConfigMention();
    this.typeToEditPost = this.checkTypeToEditPost(this.post);
    if (this.typeToEditPost === 'UNKNOWN') {
      if (this.content.trim().length > 0) {
        this.isHasText = true;
      } else this.isHasText = false;
    }
    console.log(this.typeToEditPost);
  }

  getMetaLinksIndex() {
    let arrayOfStringUrls: string[] = [];
    let urls = this.post?.message?.match(URL_REGEX);
    if (urls?.length! > 0) {
      this.post.metaLinks?.map((x) => {
        arrayOfStringUrls?.push(x.url!);
      })!;
      let commonItem = _.intersectionWith(urls, arrayOfStringUrls, _.isEqual);
      this.indexMetaLinks = this.post.metaLinks?.findIndex(
        (x) => commonItem[0] == x.url!
      )!;
      return;
    } else {
      this.indexMetaLinks = -1;
      return;
    }
  }

  onHide() {
    this.showDialogChange.emit(false);
    this.updatePostEvent.emit();
  }

  // closePopup() {
  //   if (
  //     this.filePosts.length > 0 ||
  //     this.content ||
  //     this.listMediaIdDelete.length > 0
  //   ) {
  //     this.confirmCancelPost = true;
  //   } else {
  //     this.showDialogChange.emit(false);
  //     this.updatePostEvent.emit(this.post);
  //   }
  // }

  okCancel() {
    this.showDialogChange.emit(false);
  }

  startRecord() {
    if (this.mediaPosts.length == 0 || this.checkContentAudioMediaPost()) {
      if (this.post.mediaPosts?.length! > 0) {
        this.deleteAsset(this.post.mediaPosts![0].id!, 0);
      }
      this.urlAudio = '';
      this.openPopupRecordAudio = true;
      this.isValidToAddAudio = true;
    } else {
      this.isValidToAddAudio = false;
    }
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  sanitizeNewForWareform() {
    return this.domSanitizer.sanitize(
      SecurityContext.URL,
      this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio)
    );
  }

  detectFiles(event1: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorDuration = false;

    this.isValidToAddAssets = true;
    for (let index = 0; index < event1.target.files.length; index++) {
      let file = event1.target.files[index];
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event1.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                event1.target.value = null;
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                //return;
              }
            });
        }

        reader.onload = (event) => {
          this.filePosts.push(file);
          this.urls.push({
            format: file.type.split('/')[0],
            data: (<FileReader>event.target).result,
            description: '',
            file: file,
          });
        };
      }
    }

    event1.target.value = null;
  }

  editPost() {
    this.isShowRuleEdit = false;
    LoadingService.$(true);

    if (this.mediaPosts.length + this.filePosts.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    this.countClick++;
    if (this.countClick == 1) {
      if (this.typeToEditPost == 'TEXT') {
        if (this.content.trim().length! > 0) {
          this.editContent().subscribe(() => {
            LoadingService.$(false);
            this.countClick = 0;
            this.showNotification();
            //this.notifySuccess = true;
          });
          return;
        } else {
          this.isShowRuleEdit = true;
          this.countClick = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeToEditPost == 'UNKNOWN') {
        if (this.isHasText && this.content.trim().length == 0) {
          this.isShowRuleEdit = true;
          this.countClick = 0;
          LoadingService.$(false);
          return;
        }

        if (this.mediaPosts.length + this.filePosts.length > 0) {
          var isValidToAddAudio = true;
          var isValidToAddAssets = true;

          if (this.mediaPosts.length > 0) {
            this.mediaPosts.forEach((x) => {
              if (this.checkTypeMedia(x) === 'AUDIO') {
                this.filePosts.forEach((x) => {
                  if (
                    x.type.split('/')[0] === 'image' ||
                    x.type.split('/')[0] === 'video'
                  ) {
                    isValidToAddAssets = false;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
              if (
                this.checkTypeMedia(x) === 'IMAGE' ||
                this.checkTypeMedia(x) === 'VIDEO'
              ) {
                this.filePosts.forEach((x) => {
                  if (x.type.split('/')[0] === 'audio') {
                    isValidToAddAudio = false;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
            });
          }

          if (this.filePosts.length > 0) {
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                isValidToAddAssets = false;
                //LoadingService.$(false);
                //return;
              }

              if (x.type.split('/')[0] === 'audio') {
                isValidToAddAudio = false;
                //LoadingService.$(false);
                //return;
              }

              if (!isValidToAddAssets && !isValidToAddAudio) {
                this.showErrorMixedFiles = true;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClick = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClick = 0;
            LoadingService.$(false);
            return;
          } else {
            // if (!isValidToAddAssets || !isValidToAddAudio) {
            //   this.showErrorMixedFiles = true;
            //   this.countClick = 0;
            //   LoadingService.$(false);
            //   return;
            // }

            if (this.listMediaIdDelete.length > 0) {
              this.removeAsset().subscribe();
            }

            if (this.filePosts.length > 0 || this.urlAudio) {
              this.addAssest().subscribe(() => {
                LoadingService.$(false);
                //this.notifySuccess = true;
                this.showNotification();
                this.countClick = 0;
              });
            }

            if (this.content?.trim().length > 0) {
              this.editContent()
                .pipe(
                  tap((res) => {
                    res.data!.mediaPosts = res.data!.mediaPosts?.filter(
                      (m) =>
                        !m.isDeleted &&
                        this.listMediaIdDelete.findIndex((id) => id === m.id!) <
                        0
                    );
                    this.post = res.data!;
                  })
                )
                .subscribe(() => {
                  LoadingService.$(false);
                  this.countClick = 0;
                  if (this.notifySuccess) {
                    return;
                  } else this.showNotification();
                });
            }
          }
        } else {
          this.isShowRuleEdit = true;
          this.countClick = 0;
          LoadingService.$(false);
        }
        return;
      }

      if (this.typeToEditPost == 'TEXT_AND_MEDIAS') {
        if (
          this.content &&
          this.mediaPosts.length + this.filePosts.length > 0
        ) {
          this.isShowRuleEdit = false;
          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClick = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            LoadingService.$(false);
            return;
          } else {
            if (
              this.isValidToAddAssets &&
              this.isValidToAddAudio &&
              !this.isShowRuleEdit
            ) {
              this.editContent()
                .pipe(
                  tap((res) => {
                    res.data!.mediaPosts = res.data!.mediaPosts?.filter(
                      (m) =>
                        !m.isDeleted &&
                        this.listMediaIdDelete.findIndex((id) => id === m.id!) <
                        0
                    );
                    this.post = res.data!;
                  }),
                  mergeMap(() =>
                    this.listMediaIdDelete.length > 0
                      ? this.removeAsset()
                      : of(null)
                  )
                )
                .subscribe(() => {
                  LoadingService.$(false);
                });

              if (this.filePosts.length > 0 || this.urlAudio) {
                this.addAssest().subscribe(() => {
                  this.countClick = 0;
                  LoadingService.$(false);
                  //this.notifySuccess = true;
                  this.showNotification();
                });
              } else {
                this.countClick = 0;
                LoadingService.$(false);
                // this.notifySuccess = true;
                this.showNotification();
              }
            }
          }
        } else {
          this.countClick = 0;
          this.isShowRuleEdit = true;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeToEditPost == 'TEXT_AND_AUDIO') {
        if (
          this.content &&
          this.mediaPosts.length + this.filePosts.length > 0
        ) {
          this.isShowRuleEdit = false;
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClick = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClick = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.filePosts.length > 0) {
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this.addAssest().subscribe(() => {
                  LoadingService.$(false);
                });
              } else {
                LoadingService.$(false);
                return;
              }
            } else {
              this.countClick = 0;
              LoadingService.$(false);
              //this.notifySuccess = true;
            }

            this.editContent()
              .pipe(
                tap((res) => {
                  res.data!.mediaPosts = res.data!.mediaPosts?.filter(
                    (m) =>
                      !m.isDeleted &&
                      this.listMediaIdDelete.findIndex((id) => id === m.id!) < 0
                  );
                  this.post = res.data!;
                }),
                mergeMap(() =>
                  this.listMediaIdDelete.length > 0
                    ? this.removeAsset()
                    : of(null)
                )
              )
              .subscribe(() => {
                LoadingService.$(false);
                this.countClick = 0;
                this.showNotification();
                //this.notifySuccess = true;
              });
          }
        } else {
          this.countClick = 0;
          this.isShowRuleEdit = true;
          LoadingService.$(false);
          return;
        }
      }

      if (
        this.typeToEditPost == 'ONLY_AUDIO' ||
        this.typeToEditPost == 'ONLY_MEDIAS'
      ) {
        if (this.typeToEditPost == 'ONLY_AUDIO') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = true;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClick = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        } else if (this.typeToEditPost == 'ONLY_MEDIAS') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClick = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClick = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        }

        if (
          this.showErrorLimitSizeFile ||
          this.showErrorMaximumFiles ||
          this.showErrorDuration
        ) {
          LoadingService.$(false);
          return;
        } else {
          if (this.mediaPosts.length + this.filePosts.length == 0) {
            this.isShowRuleEdit = true;
            this.countClick = 0;
            LoadingService.$(false);
            return;
          }

          if (this.listMediaIdDelete.length > 0) {
            this.removeAsset().subscribe();
          }

          if (this.filePosts.length > 0 || this.urlAudio) {
            if (this.isValidToAddAssets && this.isValidToAddAudio) {
              this.addAssest().subscribe(() => {
                LoadingService.$(false);
                //this.notifySuccess = true;
                this.showNotification();
              });
            } else {
              this.countClick = 0;
              LoadingService.$(false);
              return;
            }
          } else {
            this.countClick = 0;
            LoadingService.$(false);
            //this.notifySuccess = true;
            this.showNotification();
          }
          return;
        }
      }
    }
  }

  addMentionReceivers(receivers: MentionReceiver[], objectId: string) {
    this._mentionService.inlivewithMentionCreatesPost$Json({
      isPushNoti: this.filePosts.length > 0 ? false : true,
      body: {
        clubId: this.post.clubId!,
        dependObjectId: objectId,
        senderId: AuthService.GetAccountInfo().id!,
        receivers: receivers,
        type: TYPE_MENTION.POST,
        postId: objectId,
      }
    }).subscribe();
  }

  editContent() {
    var mentionIds: MentionReceiver[] = []

    let listMentions = this.content.match(MENTION_REGEX);
    this.listMention
      .filter((user) =>
        listMentions
          ?.map((value: any) => value.replace('@', '').replaceAll(' ', ''))
          .includes(user.username.replaceAll(' ', ''))
      )!
      .forEach((value) => {
        mentionIds.push({
          id: value.id,
          name: value.name
        })
      })! || [];

    let urlsBrowser: string[] = [];

    this.content?.replace(URL_REGEX, (url: any) => {
      urlsBrowser.push(decodeURIComponent(url));
    });

    let body: PostInputEditContentModel = {
      id: this.post.id,
      authorId: this.post.authorId,
      message: this.content,
      stageId: this.post.stageId,
      urls: urlsBrowser,
      mentionReceivers: mentionIds
    };

    return this._postService
      .inlivewithPostEditPostContentPut$Json({ body })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.post.message = res.data?.message!;
            // this.addMentionReceivers(mentionIds, res.data?.id!)
          }
        })
      );
  }

  removeAsset() {
    let body = {
      postId: this.post?.id!,
      mediaPostIds: _.union(this.listMediaIdDelete),
    };

    return this._postService
      .inlivewithPostMediaPostDeleteMediaPostDelete$Json({
        body,
      })
      .pipe(
        tap(() => {
          this.updatePostEvent.emit();
        })
      );
  }

  addAssest() {
    let nameFiles: string[] = [];
    this.filePosts!.map(x => nameFiles.push(x.name));

    return this._postService
      .inlivewithPostCreateMediaPostPost$Json({
        postId: this.post.id!,
        body: nameFiles
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.uploadMedia(this.filePosts, res.data!)
          }
        })
      );
  }

  uploadMedia(filePosts: any[], mediaCreatePost: MediaCreateResponseModel[]) {
    let obs: Observable<any>[] = [];
    filePosts.forEach((file: Blob, i: number) => {
      obs.push(
        this._uploadService.AddMediaPosts(file, mediaCreatePost[i].path!, mediaCreatePost[i].hook!, mediaCreatePost[i].id!)
      );
    });

    if (obs.length > 0) {
      forkJoin(obs).subscribe();
    }
  }

  deleteAsset(id: string, i: number) {
    this.mediaPosts = this.mediaPosts.filter((x) => x.id !== id);
    this.post.mediaPosts = this.post.mediaPosts!.filter((x) => x.id !== id);
    this.listMediaIdDelete.push(id!);
  }

  deleteNewAsset(i: number) {
    this.urls.splice(i, 1);
    this.filePosts.splice(i, 1);
  }

  checkContentAudioMediaPost() {
    return this.mediaPosts?.some((x) => this.checkTypeMedia(x) == 'AUDIO');
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio;
  }

  goHome() {
    this.confirmCancelPost = false;
    this.showDialogChange.emit(false);
    this.updatePostEvent.emit();
  }

  check() {
    console.log('check');
  }

  onPlayButtonClick() {
    this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
    this.isPlayingAudio = !this.isPlayingAudio;
  }

  onTrackLoaded(event: any) { }

  onTrackRendered(event: any) { }

  onTimeUpdate(event: any) {
    this.timeOffAudio = event.time;
    if (event.progress >= 100) {
      this.isPlayingAudio = false;
    }
  }

  onDurationChange(event: any) {
    this.timeOffAudio = event;
  }

  onRegionChange(event: any) { }

  onPaused() { }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (asset?.url.includes('agora/recording')) {
        return asset?.url!;
      }

      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  itemSelected(event: any) {
    console.log('line 965', event);
  }

  checkTypeToEditPost(post: PostResponseModel) {
    let type = '';
    if (post.mediaPosts?.length! > 0) {
      post.mediaPosts?.forEach((x) => {
        if (this.checkTypeMedia(x) == 'AUDIO') {
          if (post.message!) {
            type = 'TEXT_AND_AUDIO';
          } else type = 'ONLY_AUDIO';
        } else if (
          this.checkTypeMedia(x) == 'IMAGE' ||
          this.checkTypeMedia(x) == 'VIDEO'
        ) {
          if (post.message!) {
            type = 'TEXT_AND_MEDIAS';
          } else type = 'ONLY_MEDIAS';
        }
      });

      if (
        type == 'TEXT_AND_AUDIO' ||
        type == 'ONLY_AUDIO' ||
        type == 'TEXT_AND_MEDIAS' ||
        type == 'ONLY_MEDIAS'
      ) {
        return type;
      } else return 'UNKNOWN';
    } else type = 'TEXT';

    return type;
  }

  checkFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this._messageService.add({
          severity: 'success',
          summary: '',
          detail: this._translateService.instant('ACCOUNT_EDIT_POST'),
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => this.goHome());
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;
    let regexTagA = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi;

    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(
        url
      )}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');;
    let user = this.listMention?.filter(
      (user) => user!.nickName.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  detachText() {
    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    if (this.content == '') {
      this.metaDataItem = {};
      return;
    }

    let wordSearch = this.content;

    setTimeout(() => {
      if (wordSearch == this.content) {
        this.content?.replace(URL_REGEX, (url: any) => {
          if (url != '') {
            // LoadingService.$(true);
            this._metaReader
              .apiLinkPreviewGetLinkPreviewPost$Json({
                body: [decodeURIComponent(url)],
              })
              .pipe(
                debounceTime(2000),
                tap((res) => {
                  this.metaDataItem = res.data![0] || {};
                  // LoadingService.$(false);
                }),
                catchError((err) => {
                  // LoadingService.$(false);
                  return of(err);
                })
              )
              .subscribe();
          }
        });
      }
    }, 2000);
  }

  gotoUrl(type: number = 1) {
    if (type == 1) {
      return window.open(this.post.metaLinks![0]!.url!, '_blank');
    }
    return window.open(this.metaDataItem.url!, '_blank');
  }

  getInnerHTML(val: any) {
    if (!val) {
      return;
    }
    return val.replace(/(<([^>]+)>)/gi, '');
  }
}
