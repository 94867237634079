<div class="flex flex-column p-4 mt-5 mr-3" style="background-color: rgb(38, 38, 38); border-radius: 10px;">
    <div style="width: 300px; height: 150px">
        <div style="width: 100px; height: 20px;">
            <span class="skeleton-box"></span>
        </div>
        <div class="mt-5" style="height: 65px; width: 300px;">
            <span class="skeleton-box"></span>
        </div>
        <div class="flex justify-content-between mt-3" style="height: 20px;">
            <div style="width: 100px;" >
                <span class="skeleton-box"></span>
            </div>
            <div style="width: 150px;">
                <span class="skeleton-box"></span>
            </div>
        </div>
    </div>
</div>