import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AccountClubInputModel, Club } from 'src/app/core/api/be/models';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-request-join-club',
  templateUrl: './confirm-request-join-club.component.html',
  styleUrls: ['./confirm-request-join-club.component.scss']
})
export class ConfirmRequestJoinClubComponent implements OnInit {
  @Input() isConfirmJoinClub: boolean = false;
  @Output() isConfirmJoinClubChange = new EventEmitter<boolean>();
  @Input() clubId!: string;

  club!: Club;

  constructor(private _accountSerivce: AccountService, private _clubService: ClubService, private _activeRoute: ActivatedRoute) {
    this._activeRoute.queryParams.subscribe(res => {
      this.clubId = res?.clubId!;
      if (this.clubId) {
        this.getDetailClub().subscribe();

      }
    })
  }

  ngOnInit(): void {

  }

  onHide() {
    this.isConfirmJoinClubChange.emit(this.isConfirmJoinClub);
  }

  getDetailClub() {
    LoadingService.$(true);
    return this._clubService.inlivewithClubByIdClubIdGet$Json({ clubId: this.clubId }).pipe(
      tap(res => {
        this.club = Object.assign(res.data!);
        LoadingService.$(false);
      })
    )
  }

  joinClub() {
    let countClick = 0;
    countClick++;
    LoadingService.$(true);
    let body: AccountClubInputModel = {
      accountId: AuthService.GetAccountInfo()?.id!,
      clubId: this.clubId
    }
    if (countClick == 1) {
      this._accountSerivce.inlivewithAccountClubRequestPost$Json({
        body: body
      }).pipe(
        tap(res => {
          if (res.data == true) {
            LoadingService.$(false);
            this.isConfirmJoinClub = false;
            window.location.reload();
          }
        })
      ).subscribe(() => LoadingService.$(false));
    }
  }
}
