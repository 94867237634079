<div class="flex flex-column cursor-pointer" style="position: relative;" (click)="detailClub()">
    <div class="icon-club">
        <img class="w-100 h-100" [src]="getIcon()">
    </div>

    <div class="card" pRipple>
        <div class="zone-avt">
            <img [src]="getUrl(club!.avatar!) | fetchUrlAvatarClub" alt="" class="fit-size">
        </div>
    </div>
    <div class="flex flex-column">
        <div class="flex align-items-center name-club mt-2">
            <span class="fs-16">{{club!.name!}}</span>
            <img class="ml-2" *ngIf="club.isPrivate!" style="width: 10px" src="../assets/images/svgs/lock.svg">
        </div>
        <div class="text mt-1 flex align-items-center fs-14">
            {{club!.type! || 'type' | translate}}
            <i class="half-icon-heart" style="margin-left: 0.25rem" *ngIf="club!.type! == 'CLUB_TYPE_FAN'"></i>
            <i class="half-icon-star" style="margin-left: 0.25rem" *ngIf="club!.type! == 'CLUB_TYPE_TALENT'"></i>
        </div>
        <div class="text fs-12" *ngIf="showQuantityMembers">
            {{club!.totalMember!}} {{ (club!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') | translate}}
        </div>
    </div>
</div>