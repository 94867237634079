import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-confirm-join-event',
  templateUrl: './card-confirm-join-event.component.html',
  styleUrls: ['./card-confirm-join-event.component.scss']
})
export class CardConfirmJoinEventComponent implements OnInit {

  @Input() isShowPopup!:boolean;
  @Input() event!:EventWithCheckAccountJoinEventResponseModel
  @Input() firstButtonTitle:string = '';
  @Input() secondButtonTitle:string = '';
  @Input() reloadPage: boolean = false;

  @Output() isShowPopupChange = new EventEmitter<boolean>();
  @Output() doAction = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
    console.log(this.reloadPage);
    
    if(this.reloadPage){
      console.log(this.reloadPage);
      window.location.reload();
    }
  }

  moment(date: string) {
    let currentLang = LocalStorageService.GetLocalStorage('language').toLowerCase();
    return (moment(date)).locale(currentLang ? currentLang : 'fr').format('DD MMM YYYY HH:mm')
  }

}
