<p-sidebar [(visible)]="showSidebar" position="bottom" (onHide)="onHide()" [fullScreen]="true" [showCloseIcon]="false"
    [dismissible]="false">
    <div class="flex justify-content-between align-items-center mt-5 text-white">
        <div class="flex align-items-center ml-2">
            <i class="flex pi pi-angle-left cursor-pointer mr-2" (click)="onHide()" style="font-size: 24px"></i>
            <span class="fs-20">
                {{'MODIFIER' | translate}}
            </span>
        </div>
        <div class="flex align-items-center">
            <i class="flex pi pi-times mr-3 cursor-pointer" (click)="onHide()"></i>
        </div>
    </div>

    <div class="p-3">
        <div class="text-disabled w-100 mb-3">
            <input class="w-100" type="text" [(ngModel)]="tempName" pInputText [placeholder]="album!.name!" />
        </div>

        <span class="text-white label">{{'AVATAR_ALBUM' | translate}}</span>
        <div class="flex flex-column mt-3 mb-3 zone-avt-album position-relative">
            <img [src]="imgBlob ? imgBlob : album!.coverImage!" class="fit-size">
            <i *ngIf="imgBlob" class="pi pi-times cancel-image" (click)="cancelImage()"></i>
            <img src="../../../assets/images/svgs/edit-pencil.svg" (click)="inputFile.click()"
                class="mr-3 cursor-pointer position-absolute" style="left: 45%;">
        </div>
        <input style="display: none" id="file-avatar" type="file" #inputFile (change)="selectAvatar($event)"
            accept="image/png, image/jpeg">

        <span class="text-white label mt-3">{{'DESCRIPTION' | translate}}</span>

        <textarea class="textarea mt-2" maxlength="250" style="resize: none;" [placeholder]="album!.description!"
            [(ngModel)]="tempDescription" [rows]="6"
            placeholder="{{'CLUB_DESCRIPTION_PLACEHOLDER' | translate}}"></textarea>

        <div class="flex mt-5" [ngClass]="!isPrivate ? 'text-disabled' : ''">
            <div class="flex flex-column">
                <p-radioButton name="groupname" label="{{'CLUB_ALBUM_STATUS_PUBLIC' | translate}}"
                    (ngModelChange)="changeStage()" [(ngModel)]="isPrivate" [value]="false">
                </p-radioButton>

                <span class="ml-5 fs-14" style="margin-top: 10px;">
                    {{'CLUB_ALBUM_PUBLIC_TITLE' | translate}}
                </span>
            </div>

        </div>

        <div class="flex mt-5" [ngClass]="isPrivate ? 'text-disabled' : ''">
            <div class="flex flex-column">
                <p-radioButton name="groupname" label="{{'CLUB_ALBUM_STATUS_PRIVATE' | translate}}"
                    (ngModelChange)="changeStage()" [(ngModel)]="isPrivate" [value]="true">
                </p-radioButton>

                <span name="groupname" class="ml-5 fs-14" style="margin-top: 10px;">
                    {{'CLUB_ALBUM_PRIVATE_TITLE' | translate}}
                </span>
            </div>

        </div>

        <div class="mt-5" *ngIf="listStages.length! > 0">
            <p-checkbox class="fs-14 text-white mb-4" [binary]="true" [(ngModel)]="isLinkedToState"
                label="{{(!isPrivate ? 'CLUB_ALBUM_LINK_STAGE_PUBLIC' : 'CLUB_ALBUM_LINK_STAGE_PUBLIC') | translate}}">
            </p-checkbox>
            <p-dropdown class="ml-5 mt-4" [options]="listStages" *ngIf="isLinkedToState" [(ngModel)]="selectedStage" (ngModelChange)="selectedStageChange($event)"
                placeholder="{{'CLUB_STAGE_NAME' | translate}}" optionLabel="name"></p-dropdown>
        </div>

        <div class="mt-5 flex flex-column">
            <span class="text-white mb-3">
                {{'CLUB_ALBUM_WHO_CAN_POST' | translate}}
            </span>
            <p-radioButton class="mb-2" name="groupname" label="{{'CLUB_ALBUM_ADMIN_CAN_POST' | translate}}"
                [value]="false" [(ngModel)]="selectedValue"></p-radioButton>
            <p-radioButton class="mt-2" name="groupname" label="{{'CLUB_ALBUM_INLIVERPLUS_CAN_POST' | translate}}"
                [value]="true" [(ngModel)]="selectedValue"></p-radioButton>

            <div class="ml-5 fs-14 mt-3 add-inliver cursor-pointer" (click)="openPopup = true;" *ngIf="selectedValue">
                <span *ngIf="selectedInlivers.length == 0" class="mt-3">
                    {{'CLUB_ALBUM_PICK_LIST_MEMBERS' | translate}}
                </span>
                <div class="avatars" *ngIf="selectedInlivers.length > 0">
                    <div class="avatars__item" *ngFor="let inliver of selectedInlivers">
                        <div class="avatars__image zone-avatar">
                            <!-- Image -->
                            <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5" *ngIf="isPrivate">
            <div class="text-white">
                {{'ADVANCE_OPTION' | translate}}
            </div>
            <p-checkbox class="fs-14 mt-3" [disabled]="true" [binary]="true" [(ngModel)]="isAcceptViewPrivate"
                label="{{'ALBUM_AGREE_TO_ACCEPT' | translate}}">
            </p-checkbox>
            <div class="text-white ml-4 mt-3 fs-14">
                {{'CLUB_ADVANCE_OPTION_ALBUM' | translate}}
            </div>
        </div>

        <div class="flex justify-content-center mt-5">
            <button pButton type="submit" label="{{'REGISTER' | translate}}" (click)="editAlbum()"
                class="mt-5 p-button-outlined p-button-secondary label"></button>
        </div>


        <ng-container *ngIf="openPopup">
            <app-list-inliver [(openPopup)]="openPopup" [listInliverPlus]="listInliverPlus"
                [(selectedInlivers)]="selectedInlivers" [title]="'CLUB_WHO_CAN_POST' | translate"></app-list-inliver>
        </ng-container>


    </div>
</p-sidebar>