import {
  Component,
  Input,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  ITimeUpdateEvent,
  NgWaveformComponent,
  IRegionPositions,
} from 'ng-waveform';
import { MediaPostResponseModel } from 'src/app/core/api/be/models';

@Component({
  selector: 'app-layout-media-post-mobile',
  templateUrl: './layout-media-post-mobile.component.html',
  styleUrls: ['./layout-media-post-mobile.component.scss'],
})
export class LayoutMediaPostMobileComponent implements OnInit {
  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;

  currentMedia!: MediaPostResponseModel;

  private _post: any;
  @Input() set post(value: any) {
    if (value) {
      value?.mediaPosts?.forEach(async (m: any) => {
        const url = m?.url!;
        var res = await fetch(url);
        var blob = await res.blob();
        m['newUrl'] = this.sanitizer.sanitize(
          SecurityContext.URL,
          this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
        );
      });
      this._post = value;
    }
  }
  get post() {
    return this._post;
  }

  @Input() isJoined!: boolean;
  @Input() isAdmin!: boolean;

  imgMainIsLandscape!: boolean;

  showViewMediaPost: boolean = false;
  indexAsset: number = 0;

  // For audio files
  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.currentMedia = this.post.mediaPosts[0];
  }

  onLoad(event: Event) {
    const smallImage = event?.target as HTMLImageElement;
    let imageWidth = Number(smallImage.naturalWidth);
    let imageHeight = Number(smallImage.naturalHeight);
    this.imgMainIsLandscape = imageWidth / imageHeight >= 1;
  }

  openMedia(index: number) {
    this.showViewMediaPost = true;
    this.indexAsset = index;
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (asset?.url.includes('agora/recording')) {
        return asset?.url!;
      }

      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  onPlayButtonClick() {
    this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
    this.isPlayingAudio = !this.isPlayingAudio;
  }

  onTrackLoaded(event: any) {}

  onTrackRendered(event: any) {}

  onTimeUpdate(event: any) {
    this.timeOffAudio = event.time;
    if(event.progress >= 100) {
      this.isPlayingAudio = false;
    }
  }

  onDurationChange(event: any) {
    this.timeOffAudio = event;
  }

  onRegionChange(event: any) {}

  onPaused() {}

  selectMedia(asset: MediaPostResponseModel) {
    this.currentMedia = asset;
  }
}
