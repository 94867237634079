import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgWaveformComponent } from 'ng-waveform';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'app-record-audio-v2',
  templateUrl: './record-audio-v2.component.html',
  styleUrls: ['./record-audio-v2.component.scss']
})
export class RecordAudioV2Component implements OnInit {

  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;
  //Lets initiate Record OBJ
  record: any;
  //Will use this flag for detect recording
  permissionAllowed = false;
  //Url of Blob
  error: any;

  recording!: boolean;
  minutesLabel: string = '0';
  secondsLabel: string = '0';
  totalSeconds: number = 0;
  isPlayingAudio: boolean = false;
  timeOffAudio!: number;
  loop: any;
  streamsOnRecorded: any;
  streamsOnAsked: any;

  @Input() openRecording: boolean = false
  @Input() url!: any
  @Input() blobFile!: Blob | undefined;
  @Input() width!: string
  @Input() widthAudio!: string
  @Input() checkSubmit: boolean = true;
  @Input() isShowSend = true;

  @Output() openRecordingChange = new EventEmitter<boolean>();
  @Output() sendAudio = new EventEmitter();
  @Output() urlChange = new EventEmitter();
  @Output() blobFileChange = new EventEmitter();


  constructor(
    private domSanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.onClickRecord();
  }

  ngOnDestroy() {
    this.stopDevices();
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  close() {
    this.openRecordingChange.emit(false);
    this.urlChange.emit('');
  }

  initiateRecording() {

    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream: any) {
    this.streamsOnRecorded = stream;
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      disabledLogs: true
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();

    this.setTimer();
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
    this.stopDevices()
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob: any) {
    this.blobFile = blob;
    console.log("blob", blob);
    this.url = URL.createObjectURL(blob);

    if (!this.checkSubmit) {
      const blob = new Blob([this.blobFile!], {
        'type': 'audio/wav;'
      });

      let file = new File([blob], 'recording.wav');

      this.urlChange.emit(this.url);
      this.blobFileChange.emit(file);
    }
  }
  /**
   * Process Error.
   */
  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';
  }


  setTimer(): void {
    this.minutesLabel = '00';
    this.secondsLabel = '00';
    this.totalSeconds = 0;

    this.loop = setInterval(() => {
      /** Display */
      ++this.totalSeconds;

      this.secondsLabel = this.calculateTime(this.totalSeconds % 60);
      this.minutesLabel = this.calculateTime(Math.floor(this.totalSeconds / 60));
    }, 1000);
  }

  calculateTime(val: number): string {
    var valString = val + '';
    if (valString.length < 2) {
      return '0' + valString;
    } else {
      return valString;
    }
  }

  toggleRecord() {
    /** Do main action */
    if (this.recording) {
      this.stopRecording();
      this.afterRecord();
    } else {
      this.reset();
      this.initiateRecording();
    }
  }

  onClickRecord() {
    if (!this.permissionAllowed) {
      this.askPermission(this.toggleRecord.bind(this));
    } else {
      this.toggleRecord();
    }
  }

  askPermission(callback?: any) {
    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then(result => {
        console.log('[Record Permission Success]', result);
        this.streamsOnAsked = result;
        this.permissionAllowed = true;
        if (callback) {
          callback();
        }
      })
      .catch(result => {
        console.log('[Record Permission Decline]', result);
        this.permissionAllowed = false;
        this.close()
      });
  }

  reset() {
    this.url = '';
    this.totalSeconds = 0;
  }

  afterRecord(): void {
    clearInterval(this.loop);
    this.totalSeconds = 0;
  }


  onPlayButtonClick() {
    this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
    this.isPlayingAudio = !this.isPlayingAudio;
  }

  onTrackLoaded(event: any) { }
  onTrackRendered(event: any) { }

  onTimeUpdate(event: any) {
    this.timeOffAudio = event.time;
    if (event.progress >= 100) {
      this.isPlayingAudio = false;
    }
  }
  onDurationChange(event: any) {
    this.timeOffAudio = event;
  }
  onRegionChange(event: any) { }
  onPaused() { }


  sendComment() {
    const blob = new Blob([this.blobFile!], {
      'type': 'audio/wav;'
    });

    let file = new File([blob], 'recording.wav');

    this.urlChange.emit(this.url);
    this.blobFileChange.emit(file);
    this.openRecordingChange.emit(false);

    this.sendAudio.emit();
  }

  stopDevices() {
    const stopFn = (streams: any) => {
      if (!streams) {
        return;
      }
      const tracks = streams.getTracks();
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].stop();
      }
    }
    stopFn(this.streamsOnAsked);
    stopFn(this.streamsOnRecorded);
  }


}
