<p-dialog [(visible)]="showDialog" (onHide)="onHide()" [modal]="true">
    <div class="content">

        <div class="flex justify-content-center mb-5 text-white" style="font-weight: bold; font-size: 26px;">
            {{'INVITE_FRIEND_VIA_MAIL_TEXT_TITLE' | translate}}
        </div>
        <div class="flex text-white mt-3">
            {{'INVITE_FRIEND_VIA_MAIL_TEXT_LABEL' | translate}}
        </div>
        <div class="mt-5 mb-5">
            <p-chips class="flex" [placeholder]="'EMAIL' | translate" [(ngModel)]="listEmail"
                [allowDuplicate]="false" (onAdd)="validateEmailAddress($event)"></p-chips>
        </div>

        <div>
            <div class="flex justify-content-center">
                <button pButton pRipple type="button" label="{{'Send' | translate}}"
                    class="p-button-outlined p-button-secondary" (click)="submit()"></button>
            </div>
        </div>
    </div>
</p-dialog>

<p-toast position="top-right" key="toast"></p-toast>
