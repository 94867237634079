<div class="flex flex-column m-2">
    <div style="width: 100px; height: 150px;">
        <span class="skeleton-box"></span>
    </div>
    <div class="flex flex-column">
        <div class="mt-1" style="width: 50px; height: 10px;">
            <span class="skeleton-box"></span>
        </div>
        <div class="mt-1" style="width: 50px; height: 8px;">
            <span class="skeleton-box"></span>
        </div>
    </div>
</div>