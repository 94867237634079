import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberModel } from 'src/app/core/api/be/models';
import { ReportService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-club-report',
  templateUrl: './club-report.component.html',
  styleUrls: ['./club-report.component.scss']
})
export class ClubReportComponent implements OnInit {

  @Input() club!: ClubResponseWithTotalMemberModel

  @Output() changeOpenReportAccount = new EventEmitter();

  countReports!: number;
  constructor(
    private _router: Router,
    private _reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getCountReports().subscribe()
  }

  getCountReports(){
    return this._reportService.inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json({
      accountAdminClubId: AuthService.GetAccountInfo().id!,
      clubId: this.club.id!,
      body: ["STATUS_NEW"],
      limit: 1000
    }).pipe(
      tap(res => {
        if(res.res == 0){
          this.countReports = res.data?.totalItems!
        }
      })
    )
  }

  goToDetailClubReport(item: ClubResponseWithTotalMemberModel) {
    this.changeOpenReportAccount.emit(false);
    this._router.navigate(['signalements', item.id!], { queryParams: { clubId: item.id! } })
  }

}
