<p-dialog [(visible)]="showCommentZone" [draggable]="false" [resizable]="false" [modal]="true" [closable]="true"
    (onHide)="onHide()">
    <div class="login-modal">
        <div class="content">
            <div class="text-center fs-20 pb-5">
                {{'COMMENT' | translate}} {{post!.totalComment! > 0 ? '(' + post!.totalComment! + ')' : ''}}
            </div>
            <div class="list-comment" #elCommentsPost #listComments *ngIf="post?.totalComment! >= 0">
                <ilw-infinity-scroll [elementScroll]="elCommentsPost" [templateLoading]="loading"
                    [isLoading]="isLoading" [isScrollToTop]="true" (scrollEnd)="onScroll()" [isDone]="isDone"
                    [isUnsubscribe]="isDone" [handlingAfterGetData]="handlingAfterGetData$">
                    <div class="comments">
                        <ng-container *ngFor="let item of comments.slice().reverse()">
                            <div class="item">
                                <div class="mt-3 flex w-100">
                                    <div class="avatar">
                                        <img class="fit-size cursor-pointer"
                                            [src]="item?.author?.avatar && !item.isBannedByClub ? item?.author?.avatar : defaultAvatar"
                                            alt="" (click)="openAccountInfo(item.authorId!, item.isBannedByClub!, item.author?.isCelebrity!)">
                                    </div>
                                    <div class="detail">
                                        <div class="name"
                                            (click)="openAccountInfo(item?.author?.id!, item.isBannedByClub!, item.author?.isCelebrity!)">
                                            {{item.isBannedByClub ? ('INLIVER_UNKNOWN' | translate) :
                                            (item?.author?.nickName! | limitString:false:40)}}
                                            <span class="ml-2 label-celeb"
                                                *ngIf="item!.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'">
                                                {{'CELEBRITY' | translate}}
                                            </span>
                                        </div>
                                        <div class="time">
                                            <span>{{timeAgo(item?.dateCreated!)}}</span>
                                        </div>
                                        <div class="content" *ngIf="!item.isBannedByClub"
                                            [innerHTML]="urlify(item!.message!)"></div>
                                        <div style="position: relative" *ngIf="!item.isBannedByClub">

                                            <!-- <div class="flex justify-content-between zone-arrow"
                                                *ngIf="checkOverflow(item?.id!)">
                                                <i class="pi pi-arrow-left arrow cursor-pointer"
                                                    (click)="scrollTo(item.id!)"></i>
                                                <i class="pi pi-arrow-right arrow cursor-pointer"
                                                    (click)="scrollTo(item.id!, false)"></i>
                                            </div> -->

                                            <div class="flex flex-wrap" *ngIf="item?.mediaCommentPosts?.length! > 0"
                                                [id]="item.id">
                                                <div *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
                                                    <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                        <div class="list-media flex justify-content-center align-items-center"
                                                            *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                            <i class="pi pi-spin pi-spinner"
                                                                style="font-size: 2rem"></i>
                                                        </div>

                                                        <div class="list-media w-100 h-100 cursor-pointer"
                                                            *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                            <img [src]="getUrl(asset)" class="fit-size"
                                                                (click)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                                        </div>

                                                        <div class="list-media w-100 h-100 cursor-pointer"
                                                            *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                            <!-- <video [src]="asset?.url!" [poster]="getUrl(asset)"
                                                                class="fit-size"
                                                                (dblclick)="showMultiMedia(item?.mediaCommentPosts!, i)"
                                                                controls></video> -->

                                                            <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                                [url]="asset?.url!" [poster]="getUrl(asset)"
                                                                (dblclick)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                                            </app-video-layer>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                        <div class="list-media flex justify-content-center position-relative align-items-center zone-media-fail"
                                                            [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="mr-1 w-100"
                                                *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
                                                <div class="w-100" style="margin: 10px 0px;"
                                                    *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                    <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="action" *ngIf="!item.isBannedByClub">
                                            <div class="flex">
                                                <img src="../../../assets/images/svgs/reply.svg" alt="">
                                                <span (click)="focusText(item)">{{'RESPONSE' | translate}}</span>
                                            </div>
                                        </div>

                                        <div class="content" *ngIf="item.isBannedByClub"
                                            [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                    </div>
                                    <div class="action-menu" *ngIf="!item.isBannedByClub">
                                        <div>
                                            <i class="pi pi-ellipsis-h"
                                                (click)="menu.toggle($event); isHaveParent = false;"></i>
                                            <p-menu #menu [popup]="true" [model]="getItem(item)" [appendTo]="'body'"
                                                styleClass="menu-comment"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-reply" style="margin-bottom: 40px;" *ngIf="item && item?.inverseParent?.length! > 0">
                                <span *ngIf="item.isContinueReply" class="load-reply" (click)="loadMoreComment(item)">
                                    {{'LOAD_MORE_COMMENTS' | translate}}
                                </span>
                                <div *ngFor="let itemChild of item?.inverseParent">
                                    <div class="item-child">
                                        <div class="avatar">
                                            <img class="fit-size cursor-pointer"
                                                [src]="itemChild?.author?.avatar && !itemChild.isBannedByClub! ? itemChild.author?.avatar : defaultAvatar"
                                                alt=""
                                                (click)="openAccountInfo(itemChild.author?.id!, itemChild.isBannedByClub!, itemChild.author?.isCelebrity!)">
                                        </div>
                                        <div class="detail">
                                            <div class="name">
                                                {{!itemChild.isBannedByClub ? (itemChild?.author?.nickName! | limitString:false:40) :
                                                ('INLIVER_UNKNOWN' | translate)}}
                                                <span class="ml-2 label-celeb"
                                                    *ngIf="itemChild.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'">
                                                    {{'CELEBRITY' | translate}}
                                                </span>
                                            </div>
                                            <div class="time">
                                                <span>{{timeAgo(itemChild?.dateCreated!)}}</span>
                                            </div>
                                            <div class="content" *ngIf="!itemChild.isBannedByClub"
                                                [innerHTML]="urlify(itemChild!.message!)"></div>
                                            <div class="mt-2 mb-2" style="position: relative"
                                                *ngIf="itemChild?.mediaCommentPosts?.length! > 0 && !itemChild.isBannedByClub">
                                                <div class="flex flex-wrap w-100">
                                                    <div
                                                        *ngFor="let asset of itemChild?.mediaCommentPosts!; let i = index">
                                                        <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                            <div class="list-media flex justify-content-center align-items-center"
                                                                *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                                <i class="pi pi-spin pi-spinner"
                                                                    style="font-size: 2rem"></i>
                                                            </div>

                                                            <div class="list-media w-100 h-100 cursor-pointer"
                                                                *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                                <img class="fit-size" [src]="getUrl(asset)"
                                                                    (click)="showMultiMedia(itemChild?.mediaCommentPosts!, i)">
                                                            </div>

                                                            <div class="list-media w-100 h-100 cursor-pointer"
                                                                *ngIf="checkTypeMedia(asset) == 'VIDEO'">

                                                                <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                                    [url]="asset?.url!" [poster]="getUrl(asset)"
                                                                    (dblclick)="showMultiMedia(itemChild?.mediaCommentPosts!, i)">
                                                                </app-video-layer>
                                                            </div>


                                                        </ng-container>

                                                        <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                            <div class="list-media flex justify-content-center position-relative align-items-center zone-media-fail"
                                                                [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="w-100"
                                                        *ngFor="let asset of itemChild?.mediaCommentPosts!">
                                                        <div class="flex w-100"
                                                            *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                            <app-wave-form-audio
                                                                [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content" *ngIf="itemChild.isBannedByClub"
                                                [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                        </div>
                                        <div class="action-menu" *ngIf="!itemChild.isBannedByClub">
                                            <div>
                                                <i class="pi pi-ellipsis-h"
                                                    (click)="menu.toggle($event); isHaveParent = false;"></i>
                                                <p-menu #menu [popup]="true" [model]="getItem(itemChild)"
                                                    [appendTo]="'body'" styleClass="menu-comment"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column align-items-end pr-5" id="replyComment"
                                *ngIf="isHaveParent && currentReplyComment.id === item.id">
                                <div class="flex w-90">
                                    <div class="flex flex-wrap"
                                        *ngIf="(urlsChild.length > 0 || urlAudioChild) && !isEditComment"
                                        [ngStyle]="urlsChild.length > 0 || urlAudioChild ? {'padding':'1.5rem'} : {}">
                                        <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                                            *ngFor="let url of sortFiles(urlsChild); let i = index">
                                            <img [src]="url.data" *ngIf="url.format === 'image' && url">
                                            <ng-container
                                                *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                                                <ng-container *ngIf="isChrome">
                                                    <video [id]="url.file.name" [src]="url.data"
                                                        *ngIf="url.format === 'video' && url" controls></video>
                                                </ng-container>
                                                <ng-container *ngIf="!isChrome">
                                                    <div
                                                        class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                                        <img class="icon-play"
                                                            src="../../../assets/images/svgs/play-button.png">
                                                        <div class="mt-2 file-name fs-12 cursor-pointer"
                                                            [pTooltip]="url.file.name">
                                                            {{url.file.name}}</div>
                                                        <video [id]="url.file.name" [src]="url.data"
                                                            style="display: none;"></video>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #playerVideo>
                                                <video [id]="url.file.name" [src]="url.data"
                                                    *ngIf="url.format === 'video' && url" controls></video>
                                            </ng-template>

                                            <audio [id]="url.file.name" [src]="url.data"
                                                *ngIf="url.format === 'audio' && url" controls></audio>

                                            <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                                                *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                                <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}
                                                </div>
                                            </div>

                                            <img class="delete-asset" (click)="removeAssetChild(url.id)"
                                                src="../../../assets/images/svgs/trash-bg-white.svg">
                                        </div>
                                    </div>

                                    <ng-template #mentionListTemplate let-item="item">
                                        <div class="flex align-items-center">
                                            <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt=""
                                                width="40" height="40" style="border-radius: 5px;">
                                            <span class="p-1">{{item.name! | limitString:false:20}}</span>
                                        </div>
                                    </ng-template>


                                </div>

                                <div class="text-error flex flex-column align-items-start w-100"
                                    style="padding: 0px 5.25rem; font-size: 14px;"
                                    *ngIf="isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                                    {{'LIST_ITEM_REASON' | translate}}
                                    <div class="text-error" *ngIf="showErrorMixedFiles">
                                        {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                                    </div>

                                    <div class="text-error" *ngIf="showErrorDuration">
                                        {{'DURATION_MAX_4MINS' | translate}}
                                    </div>

                                    <div class="text-error" *ngIf="showErrorMaximumFiles">
                                        {{'MAXIMUM_4_MEDIA' | translate}}
                                    </div>

                                    <div class="text-error" *ngIf="showErrorLimitSizeFile">
                                        {{'FILE_SIZE_LIMIT_POST' | translate}}
                                    </div>
                                </div>
                                <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="isHaveParent">
                                    <span class="text-error" *ngIf="!isValidToAddAssets">
                                        {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                                    </span>
                                    <span class="text-error" *ngIf="!isValidToAddAudio">
                                        {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                                    </span>
                                    <span class="text-error" *ngIf="showEditRule">
                                        {{'RULE_EDIT_POST' | translate}}
                                    </span>
                                </div>


                                <div class="flex" style="width: 91.5%;">

                                    <div class="flex align-items-center justify-content-between w-100">

                                        <div class="zone-avt flex justify-content-center align-items-center">
                                            <img class="fit-size" [src]="currentUser.avatar ?? defaultAvatar">
                                        </div>

                                        <ng-container *ngIf="openPopupRecordAudioChild">
                                            <app-record-audio-v2 [(openRecording)]="openPopupRecordAudioChild"
                                                [width]="'600px'" [widthAudio]="'550px'" [(url)]="urlAudioChild"
                                                [(blobFile)]="blobFileAudioChild"
                                                (sendAudio)="createReplyComment()"></app-record-audio-v2>
                                        </ng-container>

                                        <div class="flex" *ngIf="!openPopupRecordAudioChild"
                                            style="border: solid 1px rgb(107, 107, 107);width: 88%;border-radius: 15px;">

                                            <textarea class="textarea-reply w-100" style="resize: none;"
                                                *ngIf="!openPopupRecordAudioChild" [(ngModel)]="contentReply"
                                                [mentionConfig]="mentionConfig"
                                                [mentionListTemplate]="mentionListTemplate"
                                                [placeholder]="('COMMENT_REPLY_PLACEHOLDER' | translate) + '@' + (currentReplyComment.author?.nickName! | limitString:false:20)"
                                                autofocus></textarea>

                                            <div class="flex justify-content-end" *ngIf="!openPopupRecordAudioChild">
                                                <div class="cursor-pointer flex align-items-center">
                                                    <img class="cursor-pointer mr-3"
                                                        src="../../../../assets/images/svgs/emojis.svg" alt="" srcset=""
                                                        height="16" (click)="changeShowEmoji($event)">
                                                    <img class="mr-3" src="../../../assets/images/svgs/mic.svg"
                                                        (click)="startRecord(true)"
                                                        *ngIf="(!urlsChild.length && !isEditComment && contentReply?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                                                    <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                                                        *ngIf="(!urlAudioChild && (!isEditComment)) || (isEditComment && (typeOfComment != 'TEXT'))"></i>
                                                    <i class="pi pi-arrow-circle-right mr-3"
                                                        (click)="createReplyComment()"
                                                        *ngIf="contentReply.length > 0 || (urlsChild.length > 0) || (urlAudioChild && blobFileAudioChild) || (isEditComment)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                                (change)="detectFiles($event); isHaveParent = true;">
                        </ng-container>
                    </div>
                </ilw-infinity-scroll>
            </div>

            <!-- <div class="comments text-disabled text-center" style="height: 68%;" *ngIf="post?.totalComment == 0"
                [innerHTML]="'POST_COMMENT_EMPTY' | translate"></div> -->
            <div class="flex flex-column mt-5 input-text">
                <div class="flex flex-wrap align-items-center" *ngIf="(urls.length > 0 || urlAudio) && !isEditComment"
                    [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                    <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                        *ngFor="let url of sortFiles(urls); let i = index">
                        <img [src]="url.data" *ngIf="url.format === 'image' && url">
                        <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                            <ng-container *ngIf="isChrome">
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-container>
                            <ng-container *ngIf="!isChrome">
                                <div
                                    class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                    <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                    <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                        {{url.file.name}}</div>
                                    <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #playerVideo>
                            <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                controls></video>
                        </ng-template>

                        <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                            controls></audio>

                        <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                            *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                            <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}</div>
                        </div>

                        <img class="delete-asset" (click)="removeAsset(url.id)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>
                    <!-- <div *ngIf="urlAudio" class="position-relative">
                            <audio controls>
                                <source [src]="sanitize()" type="audio/wav">
                            </audio>
                            <img class="delete-asset-audio" (click)="removeAudio()"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div> -->
                </div>
                <div class="flex flex-wrap align-items-center"
                    *ngIf="isEditComment && currentReplyComment?.mediaCommentPosts?.length! > 0"
                    [ngStyle]="isEditComment && currentReplyComment!.mediaCommentPosts!.length! > 0 ? {'padding':'1.5rem'} : {}">
                    <div class="position-relative" style="overflow: auto;"
                        *ngFor="let media of filesDelete!; let i = index">
                        <div class="m-3" *ngIf="(checkTypeMedia(media) == 'IMAGE')"
                            style="position: relative;  width: fit-content;" [id]="media.id!">
                            <img class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'IMAGE'" height="100">
                            <img class="delete-asset" (click)="actionDelete(media)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>

                        <div class="m-3" *ngIf="checkTypeMedia(media) == 'VIDEO'"
                            style="position: relative; width: fit-content;" [id]="media.id!">
                            <video class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'VIDEO'" height="100"
                                controls></video>
                            <img class="delete-asset" (click)="actionDelete(media)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>

                        <div class="m-3" *ngIf="checkTypeMedia(media) == 'AUDIO'"
                            style="position: relative; width: fit-content;" [id]="media.id!">
                            <audio style="height: 5rem;" [src]="media?.url!" controls></audio>
                            <img class="delete-asset" (click)="actionDelete(media)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>


                        <div style="position: relative;" class="m-3" *ngIf="checkProcessMediaFail(media)">
                            <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                style="height: 100px !important;" [innerHTML]="'UNKNOWING_FILE' | translate">
                            </div>
                            <img class="delete-asset-audio" (click)="actionDelete(media)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap align-items-center" *ngIf="(urls.length > 0) && isEditComment"
                    [ngStyle]="urls.length > 0 ? {'padding':'1.5rem'} : {}">
                    <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                        *ngFor="let url of sortFiles(urls); let i = index">
                        <img [src]="url.data" *ngIf="url.format === 'image' && url">
                        <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                            <ng-container *ngIf="isChrome">
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-container>
                            <ng-container *ngIf="!isChrome">
                                <div
                                    class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                    <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                    <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                        {{url.file.name}}</div>
                                    <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #playerVideo>
                            <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                controls></video>
                        </ng-template>

                        <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                            controls></audio>

                        <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                            *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                            <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}
                            </div>
                        </div>

                        <img class="delete-asset" (click)="removeAsset(url.id)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>
                </div>

                <div class="text-error flex flex-column align-items-start" style="padding: 0px 2.5rem; font-size: 14px;"
                    *ngIf="!isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                    {{'LIST_ITEM_REASON' | translate}}
                    <div class="text-error" *ngIf="showErrorMixedFiles">
                        {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorDuration">
                        {{'DURATION_MAX_4MINS' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorMaximumFiles">
                        {{'MAXIMUM_4_MEDIA' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorLimitSizeFile">
                        {{'FILE_SIZE_LIMIT_POST' | translate}}
                    </div>
                </div>
                <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="!isHaveParent">
                    <span class="text-error" *ngIf="!isValidToAddAssets">
                        {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                    </span>
                    <span class="text-error" *ngIf="!isValidToAddAudio">
                        {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                    </span>
                    <span class="text-error" *ngIf="showEditRule">
                        {{'RULE_EDIT_POST' | translate}}
                    </span>
                </div>

                <div class="text-error fs-14" style="padding: 0px 2.5rem;"
                    *ngIf="(isEditComment && (typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'ONLY_MEDIAS' || (typeOfComment == 'UNKNOWN' && !isHasText)))">
                    {{'IMPOSSIBLE_TO_EDIT_TEXT' | translate}}
                </div>


                <div class="flex">
                    <ng-template #mentionListTemplate let-item="item">
                        <div class="flex align-items-center">
                            <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40" height="40"
                                style="border-radius: 5px;">
                            <span class="p-1">{{item.name! | limitString:false:20}}</span>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="openPopupRecordAudio">
                        <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'600px'"
                            [widthAudio]="'550px'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                            (sendAudio)="createComment()"></app-record-audio-v2>
                    </ng-container>

                    <textarea class="textarea-cus w-100" style="resize: none;" [(ngModel)]="content"
                        [disabled]="(isEditComment && (typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'ONLY_MEDIAS' || (typeOfComment == 'UNKNOWN' && !isHasText)))"
                        *ngIf="!openPopupRecordAudio" [mentionConfig]="mentionConfig"
                        [mentionListTemplate]="mentionListTemplate"
                        placeholder="{{'COMMENT_WRITE' | translate}}"></textarea>
                    <div #toggleEmoji (click)="clickOnSide($event)">
                        <emoji-mart [isNative]="true" class="emoji emoji-media" [enableSearch]="false" *ngIf="showEmoji"
                            [sheetSize]="32" (emojiClick)="addEmoji($event)"></emoji-mart>
                    </div>

                    <div class="flex justify-content-end pt-4" *ngIf="!openPopupRecordAudio">
                        <div class="cursor-pointer flex pt-4 align-items-end pb-3">
                            <img class="cursor-pointer mr-3" src="../../../../assets/images/svgs/emojis.svg" alt=""
                                srcset="" height="16" (click)="changeShowEmoji($event)">
                            <img class="mr-3" src="../../../assets/images/svgs/mic.svg" (click)="startRecord(false)"
                                *ngIf="(!urls.length && !isEditComment && content?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                            <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                                *ngIf="(!urlAudio && (!isEditComment)) || (isEditComment && (typeOfComment != 'TEXT'))"></i>
                            <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                *ngIf="content?.length! > 0 || (urls?.length! > 0) || (urlAudio! && blobFileAudio!) || (isEditComment)"></i>
                        </div>
                    </div>
                </div>
            </div>


            <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                (change)="detectFiles($event); isHaveParent = false;">
            <div id="zoneAudio"></div>
        </div>
    </div>
</p-dialog>

<ng-template #loading>
    <div class="flex justify-content-center mt-2">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            animationDuration="1s"></p-progressSpinner>
    </div>
</ng-template>

<ng-container *ngIf="isShowPopupConfirm">
    <app-card-confirm [(isShowPopup)]="isShowPopupConfirm" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'DELETE' | translate" (doAction)="deleteComment()" [title]="'COMMENT_DELETE' | translate"
        [content]="'COMMENT_DELETE_CONFIRM' | translate:{value: (currentReplyComment.author?.nickName! | limitString:false:40)}">
    </app-card-confirm>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'COMMENT'" [nameObject]="this.currentReplyComment"
        (refreshWhenReportSuccess)="getCommentByPostId()">
    </app-report>
</ng-container>

<ng-container *ngIf="openPopupBanInliver">
    <app-list-inliver-reason-banned [commentPost]="currentReplyComment" [clubId]="post.clubId!"
        [(showDialog)]="openPopupBanInliver"></app-list-inliver-reason-banned>
</ng-container>

<ng-container *ngIf="isShowMultiMedia">
    <app-show-multi-media [(openShowMutilMedia)]="isShowMultiMedia" [(urls)]="multiMedias"
        [(activeIndex)]="activeIndex"></app-show-multi-media>
</ng-container>