import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { CRYPTO } from "../constant";

@Injectable({
  providedIn: "root",
})
export abstract class CryptoService {
  private static _key: any = CryptoJS.enc.Utf8.parse(CRYPTO.TOKEN_KEY);
  private static _iv: any = CryptoJS.enc.Utf8.parse(CRYPTO.TOKEN_IV);

  public static EncryptUsingAES256(req: string): string {
    let result = CryptoJS.AES.encrypt(JSON.stringify(req), this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return result;
  }

  public static DecryptUsingAES256(encrypted: string): string {
    let result = CryptoJS.AES.decrypt(encrypted, this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return result;
  }
}
