<p-dialog *ngIf="!isMobile && club" [(visible)]="showDialog" [closable]="true" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="onHide()">
    <div style="width: 650px;">
        <div class="flex align-items-center text-white">
            <i class="pi pi-bell fs-24 mr-2"></i>
            <span class="fs-22">{{'NOTIFICATIONS' | translate}}</span>
        </div>
        <div class="flex flex-column mt-5">
            <div class="flex justify-content-between align-items-center mb-5">
                <div class="flex flex-column w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'PUBLICATION_INLIVER' | translate}}
                    </span>
                    <span class="text-disabled fs-14">
                        {{'PUBLICATION_INLIVER_TITLE' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffPublicationsInliverNoti"></p-inputSwitch>
                </div>
            </div>
            <div class="flex justify-content-between align-items-center mb-5">
                <div class="flex flex-column w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'PUBLICATION_ADMIN' | translate}}
                    </span>
                    <span class="text-disabled fs-14">
                        {{'PUBLICATION_ADMIN_TITLE' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffPublicationsAdminNoti"></p-inputSwitch>
                </div>
            </div>
            <div class="flex justify-content-between align-items-center">
                <div class="flex flex-column w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'PUBLICATION_MENTION' | translate}}
                    </span>
                    <span class="text-disabled fs-14">
                        {{'PUBLICATION_MENTION_TITLE' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffMentionsNoti"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="flex justify-content-center mt-6">
            <button pButton pRipple type="button" label="{{'REGISTER' | translate}}"
                class="p-button-outlined p-button-secondary text-white" (click)="save()">
            </button>
        </div>
    </div>
</p-dialog>


<!-- <p-sidebar *ngIf="isMobile" [(visible)]="showDialog" [modal]="true" [draggable]="false" [showCloseIcon]="true" position="bottom" (onHide)="onHide()">
    <div>

    </div>
</p-sidebar> -->
