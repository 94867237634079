import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../api/be/services';
import { STORAGE_KEY } from '../constant';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _accountService: AccountService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN) &&
      LocalStorageService.GetLocalStorage(STORAGE_KEY.EMAIL)
    ) {
      return true;
    } else {
      LoginService.$(true);
      return false;
    }
  }

  quit(route: string) {
    this._router
      .navigate(['announcement', route])
      .then(() => location.reload());
  }
}
