import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AccountClubInputModel, Club, ClubResponseInPostCelebrity, ClubResponseWithTotalMemberAndRoleModel, EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import { AccountService, ClubService, EventService } from 'src/app/core/api/be/services';
import { rulesDefaultWhenCreateClub } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-club-rules',
  templateUrl: './club-rules.component.html',
  styleUrls: ['./club-rules.component.scss']
})
export class ClubRulesComponent implements OnInit {

  @Input() club!: ClubResponseWithTotalMemberAndRoleModel;
  @Input() isShowRulesClub!: boolean
  @Input() isApplied!: boolean
  @Input() isJoined!: boolean
  @Input() nextStep!: boolean
  @Input() totalMembers!: number
  @Input() routeTogoInlive!: string;
  @Input() routeTogoDetailEvent!: string;
  @Input() routeTogoDetailPost: boolean = false;
  @Input() fromMobile: boolean = false;
  @Input() isShowButtonClose = false;
  @Input() clubCeleb!: ClubResponseInPostCelebrity

  @Output() isShowRulesClubChange = new EventEmitter<boolean>();
  @Output() isAppliedChange = new EventEmitter<boolean>();
  @Output() isJoinedChange = new EventEmitter<boolean>();
  @Output() nextStepChange = new EventEmitter<boolean>();

  ruleDefault = rulesDefaultWhenCreateClub;
  event!: EventWithCheckAccountJoinEventResponseModel
  isOpenConfirmJoinEvent: boolean = false;

  constructor(
    private _accountSerivce: AccountService,
    private _eventService: EventService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.isShowRulesClubChange.emit(false);
  }

  acceptRule() {
    let countClick = 0;
    countClick++;
    LoadingService.$(true);
    let body: AccountClubInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      clubId: this.clubCeleb?.id! ? this.clubCeleb?.id! : this.club?.id!
    }
    if (countClick == 1) {
      this._accountSerivce.inlivewithAccountClubRequestPost$Json({
        body: body
      }).pipe(
        tap(res => {
          if (res.data == true) {
            if (this.club?.isPrivate! || this.clubCeleb?.isPrivate!) {
              this.isAppliedChange.emit(true);
              this.nextStepChange.emit(true);
              this.close();
            } else {
              this.isAppliedChange.emit(true);
              this.isJoinedChange.emit(true);
              //this.close();
              if (this.routeTogoInlive) {
                this._router.navigateByUrl(this.routeTogoInlive);
                return;
              }
              else if (this.routeTogoDetailEvent) {
                this.getDetailEvent();
                //this._router.navigate(['manage-club/detail-event', this.routeTogoDetailEvent!], { queryParams: { eventId: this.routeTogoDetailEvent!, clubId: this.club.id! } });
              } else if (this.routeTogoDetailPost) {
                this.getDetailPost();
              }
              else { location.reload() }
            }
            LoadingService.$(false);
          }
        })
      ).subscribe(() => {
        //location.reload();
      });
    }
  }

  getDetailEvent() {
    this._eventService.inlivewithEventByIdAccountRequestIdEventIdGet$Json({
      accountRequestId: AuthService.GetAccountInfo().id!,
      eventId: this.routeTogoDetailEvent
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.event = res.data!
          console.log(this.event);

          this.isOpenConfirmJoinEvent = true;
        }
      })
    ).subscribe();
  }

  getDetailPost() {
    this._router.navigate(['manage-club/post-detail'], {
      queryParams: { clubId: this.clubCeleb.id!, postId: this.clubCeleb.postId! },
    });
  }

  confirmJoinEvent() {
    this._eventService.inlivewithEventJoinPut$Json({
      body: {
        accountId: AuthService.GetAccountInfo().id!,
        eventId: this.event.id!
      }
    }).subscribe(res => {
      if (res.res == 0) {
        window.location.reload();
      }
    })
  }
}
