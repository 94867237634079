import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import {
  Account,
  AccountResponseGeneral,
  AccountResponseWithRoleInCLubModel,
  Album,
  AlbumResponseWithTotalAssetModel,
  Club,
  ClubResponseWithTotalMemberAndGoInliveModel,
  EventWithCheckAccountJoinEventResponseModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  AlbumService,
  ClubService,
  EventService,
  FriendRelationService,
  ReportService,
} from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-left',
  templateUrl: './side-left.component.html',
  styleUrls: ['./side-left.component.scss'],
})
export class SideLeftComponent implements OnInit {
  albums: AlbumResponseWithTotalAssetModel[] = [];
  friends: AccountResponseGeneral[] = [];
  friendsNewest: AccountResponseGeneral[] = [];
  eventsInClub: EventWithCheckAccountJoinEventResponseModel[] = [];

  clubId!: string;
  searchText: string = '';

  isShowMoreAlbums!: boolean;
  isShowMoreClubs!: boolean;
  isShowMoreClubsJoined!: boolean;
  accountInClub!: AccountResponseWithRoleInCLubModel;
  isAcceptedToView!: boolean;
  isAdmin!: boolean;
  isBanned!: boolean;

  showInviteFriendViaMail: boolean = false;
  openReportByAccount = true;

  isJoined!: boolean;
  isHasAccess!: boolean;
  currentClub!: ClubResponseWithTotalMemberAndGoInliveModel;

  listSettings: any[] = [
    {
      id: 0,
      title: this._translateService.instant('NOTIFICATIONS'),
      icon: 'pi pi-bell',
    },
    {
      id: 1,
      title: this._translateService.instant('SECURITY_ACCOUNT'),
      icon: 'pi pi-lock',
    },
  ];

  listMentionLegals: any[] = [
    {
      id: 0,
      title: this._translateService.instant('MENTION_LEGAL_CONDITION'),
      icon: 'pi pi-bell',
    },
    {
      id: 1,
      title: this._translateService.instant('MENTION_LEGAL_POLICY'),
      icon: 'pi pi-lock',
    },
    {
      id: 2,
      title: this._translateService.instant('MENTION_LEGALS'),
      icon: 'pi pi-lock',
    },
    {
      id: 3,
      title: this._translateService.instant('MENTION_LEGAL_COOKIE'),
      icon: 'pi pi-lock',
    },
    {
      id: 4,
      title: this._translateService.instant('MENTION_LEGAL_COMMUNITY'),
      icon: 'pi pi-lock',
    },
  ];

  currentSetting = 0;
  currentLegal = 0;
  currentClubReports!: string;
  currentUser = AuthService.GetAccountInfo();

  constructor(
    public _router: Router,
    private _clubService: ClubService,
    private _albumService: AlbumService,
    private _activeRoute: ActivatedRoute,
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _reportService: ReportService,
    private _messageService: MessageService,
    private _clipboardService: ClipboardService,
    private _eventService: EventService
  ) {}

  ngAfterViewInit() {
    this._activeRoute.queryParams.subscribe((res) => {
      this.currentClubReports = res?.clubId!;
      if (res?.clubId!) {
        this.checkBlockedMember(res?.clubId!)
        this.getStatusClub(res?.clubId!);
        this.getAlbumsFromClub(res?.clubId!);
        this.getRoleAccount(res?.clubId!);
        this.getListEventByClub(res?.clubId!)
      }
      if (this.currentClubReports) {
        this.openReportByAccount = false;
      }
      this.clubId = res?.clubId!;
      this.isJoined = false;
      this.albums = [];
    });
  }

  ngOnInit(): void {
    if (this._router.url.startsWith('/setting-user')) {
      this.setSetting(this.listSettings[0]);
    }
  }

  getAlbumsFromClub(clubId: any) {
    if (clubId) {
      this._albumService
        .inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json({
          clubId: clubId,
          accountId: AuthService.GetAccountInfo().id!,
          limit: 4,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.albums = res.data?.page!;
              this.isShowMoreAlbums = res?.data?.totalItems! > 4 ? true : false;
            }
          })
        )
        .subscribe();
    }
  }

  checkBlockedMember(clubId: any) {
    if (clubId) {
      let body: any = {
        accountRequestId: AuthService.GetAccountInfo().id!,
        clubId: clubId,
      };

      this._accountService.inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json(
        body
      ).pipe(
        tap((res) => {
          if (res.res == 0) {
            this.isBanned = res.data?.isBanned!;
          }
        })
      ).subscribe();
    }
  }

  getStatusClub(clubId: any) {
    if (clubId) {
      this._clubService
        .inlivewithClubByIdClubIdGet$Json({ clubId: clubId })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.currentClub = res?.data!;
            }
          })
        )
        .subscribe();
    }
  }

  createClub() {
    this._router.navigate(['manage-club/create']);
  }

  createAlbum() {
    if (this.albums.length >= 3) {
      return this._messageService.add({
        severity: 'success',
        summary: this._translateService.instant('NOTIFICATION'),
        detail: this._translateService.instant('NOTIFICATION_MAX_ALBUM_REACH'),
        key: 'notificationOnWeb',
        closable: false,
        life: 3 * 1000
      });
    }

    this._router.navigate(['manage-club/create-album', this.clubId], {
      queryParams: { clubId: this.clubId },
    });
  }

  loadMoreAlbums() {
    this._albumService
      .inlivewithAlbumsByClubClubIdGet$Json({
        clubId: this.clubId,
        page: 1,
        limit: 20,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.albums = res.data?.page!;
            this.isShowMoreAlbums =
              res?.data?.totalItems! > this.albums.length ? true : false;
          }
        })
      )
      .subscribe();
  }

  getListEventByClub(clubId: string) {
    this._eventService.inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json({
      ClubId: clubId,
      accountRequestId: AuthService.GetAccountInfo().id!
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.eventsInClub = res.data?.page!.filter(x => !x.isBannedByClub!)!;
        }
      })
    ).subscribe();
  }

  getRoleAccount(clubId: any) {
    this.isJoined = false;
    this.isAcceptedToView = false;
    this.isAdmin = false;

    return this._accountService
      .inlivewithAccountClubDetailAccountIdClubIdGet$Json({
        clubId: clubId,
        accountId: AuthService.GetAccountInfo()?.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.accountInClub = res?.data!;
            this.isJoined = true;
            this.isAcceptedToView =
              this.accountInClub?.roleInClub === 'ROLE_ADMIN' ||
              this.accountInClub?.accessLevelInClub === 'ACCESSLEVEL_PLUS';
            this.isAdmin = this.accountInClub?.roleInClub === "ROLE_ADMIN" ? true : false;
          } else {
            this.isJoined = false;
            this.isAcceptedToView = false;
            this.isAdmin = false;
          }
        }),
        catchError((err) => {
          this.isJoined = false;
          this.isAcceptedToView = false;
          this.isAdmin = false;
          return of(err);
        })
      )
      .subscribe();
  }

  // checkJoinedClub(clubId: string) {
  //   return this._accountService
  //     .inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json({
  //       accountRequestId: AuthService.GetAccountInfo()?.id!,
  //       clubId: clubId,
  //     })
  //     .subscribe((isHasAccess) => {
  //       this.isHasAccess = isHasAccess?.data?.isBanned!;
  //     });
  // }

  setSetting(item: any) {
    this.currentSetting = item.id;
    if (item.id == 0) {
      this._router.navigate(['/setting-user/notification']);
    } else {
      this._router.navigate(['/setting-user/security']);
    }
  }

  setLegal(item: any) {
    this.currentLegal = item.id;
    if (item.id == 0) {
      this._router.navigate(['home/mention-legal/condition']);
    } else if (item.id == 1) {
      this._router.navigate(['home/mention-legal/policy']);
    } else if (item.id == 2) {
      this._router.navigate(['home/mention-legal/legal']);
    } else if (item.id == 3) {
      this._router.navigate(['home/mention-legal/cookie']);
    } else {
      this._router.navigate(['home/mention-legal/comunity']);
    }
  }

  clickScroll() {
    this.isShowMoreClubs = !this.isShowMoreClubs;
    if (!this.isShowMoreClubs) {
      const element = <Element>document.querySelector('#scrollId1');
      element.scrollIntoView();
    }
  }

  getReportsFromClub(clubId: string) {
    let countReports: number;
    this._reportService
      .inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json(
        {
          clubId: clubId,
          accountAdminClubId: AuthService.GetAccountInfo().id!,
          limit: 1000,
          body: ['STATUS_NEW'],
        }
      )
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            countReports = res.data?.totalItems!;
          }
        })
      )
      .subscribe();
  }

  copyLink() {
    this._messageService.clear();

    this._messageService.add({
      severity: 'success',
      summary: this._translateService.instant('NOTIFICATION'),
      detail: 'Copied ' + environment.BASE_URL,
      key: 'noti-copy',
      closable: false,
      life: 3 * 1000
    });

    this._clipboardService.copyFromContent(environment.BASE_URL);
  }

  inviteViaMail() {
    this.showInviteFriendViaMail = true;
  }

  shareWhatsApp() {
    window.open(`https://api.whatsapp.com/send?text=` + environment.BASE_URL);
  }

  createEvent() {
    this._router.navigate(['manage-club/create-event', this.clubId], { queryParams: { clubId: this.clubId } });
  }

  seeAll() {
    if (this.isJoined) {
      this._router.navigate(['manage-club/list-event', this.clubId], { queryParams: { clubId: this.clubId } });
    }
  }

  handleReportAccount() {
    this.openReportByAccount = true;
    this._router.navigate(['signalements', AuthService.GetAccountInfo().id!])
  }
}
