import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Club } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-mobile-edit-privacy-club',
  templateUrl: './mobile-edit-privacy-club.component.html',
  styleUrls: ['./mobile-edit-privacy-club.component.scss'],
})
export class MobileEditPrivacyClubComponent implements OnInit {
  @Input() club!: Club;
  @Input() showDialog: boolean = false;
  @Output() showDialogChange = new EventEmitter();

  isPrivate!: boolean;

  constructor(private _clubService: ClubService) { }

  ngOnInit(): void {
    this.isPrivate = this.club?.isPrivate!;
  }

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  submit() {
    if (this.isPrivate) {
      this.setPrivate().subscribe(res => {
        if (res.res == 0) {
          this.showDialog = false;
          location.reload();
        }
      });
    }
    else {
      this.setPublic().subscribe(res => {
        if (res.res == 0) {
          this.showDialog = false;
          location.reload();
        }
      });
    }
  }

  setPrivate() {
    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club?.id!,
    }
    return this._clubService.inlivewithClubPrivateAdminIdClubIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club?.id!,
        body
      }
    );
  }

  setPublic() {
    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club?.id!,
    }
    return this._clubService.inlivewithClubPublicAdminIdClubIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club?.id!,
        body
      }
    );
  }
}
