<div *ngIf="!isMobile" class="w-100 m-2 zone-club">
    <div class="flex justify-content-between align-items-center">
        <div class="flex align-items-center">
            <div class="zone-avatar">
                <img [src]="getUrl(club!.avatar!)"
                    class="fit-size cursor-pointer" (click)="detailClub()">
            </div>
            <div class="zone-name ml-3 flex flex-column align-items-start">
                <div class="flex align-items-center">
                    <span class="name-club text-white fs-18 mr-3">{{club!.name!}}</span>
                    <div class="zone-role">
                        <span
                            [ngStyle]="getRoleToString(roleInClub) == 'Créateur' ? {'color': '#BFBFBF'} : getRoleToString(roleInClub) == 'Admin' ? {'color':'#45AADA'} : {'color:':'#808080'}">{{getRoleToString(roleInClub)}}</span>
                    </div>
                </div>
                <div class="text-disabled mt-2 fs-14">
                    {{club!.totalMember!}} {{(club!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') | translate}}
                </div>
            </div>
        </div>
        <div *ngIf="!isBanned">
            <button *ngIf="!isApplied && !isJoined && accountId" pButton type="submit"
                label="{{'CLUB_REJOIN' | translate}}" [disabled]="isJoined"
                class="mt-5 p-button-outlined p-button-secondary" (click)="onApplyClub()"></button>
            <button *ngIf="isJoined && accountId" pButton label="{{'MEMBER' | translate}}" [disabled]="isJoined"
                class="mt-5 p-button-outlined p-button-secondary"></button>
            <div class="flex text-white" *ngIf="isApplied && accountId && !isJoined">
                <img src="../../../assets/images/svgs/pending.svg">
                <p>
                    {{'REQUEST_PENDING' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>

<ng-container>
    <app-club-rules [club]="club" [(isApplied)]="isApplied" [fromMobile]="isMobile" [(isJoined)]="isJoined"
        [(nextStep)]="nextStep" [(isShowRulesClub)]="openPopupAcceptRuleClub"></app-club-rules>
</ng-container>