import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  Album,
  AlbumResponseWithTotalAssetModel,
  AlbumUpdateStageModel,
  Stage,
} from 'src/app/core/api/be/models';
import {
  AlbumService,
  ClubService,
  StageService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
@Component({
  selector: 'app-mobile-edit-album',
  templateUrl: './mobile-edit-album.component.html',
  styleUrls: ['./mobile-edit-album.component.scss'],
})
export class MobileEditAlbumComponent implements OnInit {
  @Input() showSidebar: boolean = true;
  @Output() showSidebarChange = new EventEmitter<boolean>();

  @Input() album!: AlbumResponseWithTotalAssetModel;

  @Input() showPopupEditAlbum: boolean = false;

  @Output() showPopupEditAlbumChange = new EventEmitter<boolean>();

  imgBlob!: string | ArrayBuffer | null;
  avtUpload: any;

  isPrivate!: boolean;
  isLinkedToState!: boolean;
  openPopup!: boolean;
  selectedValue: boolean = false;
  isAcceptViewPrivate!: boolean;

  tempName: string = '';
  tempDescription: string = '';

  selectedStage!: Stage;
  defaultStage!: Stage;
  listStages: Stage[] = [];
  cloneListStages: Stage[] = [];
  selectedInlivers: AccountResponseWithRoleInCLubModel[] = [];
  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar;

  constructor(
    private _stageService: StageService,
    private _albumService: AlbumService,
    private _clubService: ClubService
  ) {}

  ngOnInit(): void {
    this.isPrivate = this.album?.isPrivate!;
    this.isLinkedToState = this.album?.stage ? true : false;
    this.tempDescription = this.album?.description!;
    this.isAcceptViewPrivate = this.album?.isAcceptViewPrivate!;
    // this.getListStages().subscribe();
    this.getMembersInAlbum().subscribe();
    this.getListInliverPlus().subscribe();
  }

  selectAvatar(event: any) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.avtUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgBlob = reader.result;
      };
    }
  }

  getMembersInAlbum() {
    return this._albumService
      .inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json(
        {
          accessLevel: 'ACCESSLEVEL_PLUS',
          albumId: this.album.id!,
          limit: 1000,
          accountRequestId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          if (res.res! == 0) {
            this.selectedInlivers = res.data?.page!;
            if (this.selectedInlivers.length > 0) {
              this.selectedValue = true;
            }
          }
        })
      );
  }

  getListInliverPlus() {
    return this._clubService
      .inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json({
        clubId: this.album.clubId!,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000,
      })
      .pipe(
        tap((res) => {
          if (res.data) {
            let found = res.data?.page!.some(
              (x) => x.id === AuthService.GetAccountInfo()?.id!
            );
            if (found) {
              this.listInliverPlus = res.data?.page!.filter(
                (x) => x.id !== AuthService.GetAccountInfo()?.id!
              );
            } else {
              this.listInliverPlus = res.data?.page!;
            }
          }
        })
      );
  }

  onHide() {
    this.showSidebar = false;
    this.showSidebarChange.emit(this.showSidebar);
  }

  cancelImage() {
    this.imgBlob = '';
    this.avtUpload = '';
    (<HTMLInputElement>document.getElementById('file-avatar')).value = '';
  }

  close() {
    this.showPopupEditAlbumChange.emit(false);
  }

  changeStage() {
    setTimeout(() => {
      this.listStages = this.cloneListStages.filter(
        (stage) => stage.isPrivate == this.isPrivate
      );
    }, 500);
  }

  // getListStages() {
  //   return this._stageService
  //     .inlivewithStagesByClubIdClubIdGet$Json({
  //       clubId: this.album?.clubId!,
  //       limit: 20,
  //     })
  //     .pipe(
  //       tap((res) => {
  //         if (res.data) {
  //           const data = res.data?.page!.filter((result) => !result.isDefault);
  //           this.listStages = data;
  //           this.cloneListStages = data;
  //           this.defaultStage = res.data.page!.filter(
  //             (result) => result.isDefault
  //           )[0];
  //           this.selectedStage = this.listStages.find(
  //             (x) => x.id! === this.album.stageId!
  //           )!;
  //           this.changeStage();
  //         }
  //       })
  //     );
  // }

  editAlbum() {
    LoadingService.$(true);
    let countClick = 0;
    countClick++;
    if (countClick == 1) {
      this._albumService
        .inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json({
          adminId: AuthService.GetAccountInfo().id!,
          albumId: this.album?.id!,
          clubId: this.album?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: this.album?.id!,
            clubId: this.album?.clubId!,
            value: this.tempName !== '' ? this.tempName : this.album?.name!,
          },
        })
        .pipe(
          tap(() => {}),
          mergeMap((res) => this.editDescription(res?.data!)),
          mergeMap((res) => this.editForAll(res?.data!)),
          mergeMap((res) => this.editStatus(res?.data!)),
          mergeMap((res) => this.editStage()),
          mergeMap((res) =>
            forkJoin([
              this.avtUpload ? this.editImageCover(res?.data!) : of(null),
              this.selectedInlivers ? this.editMemberToViewAlbum() : of(null),
            ])
          ),
          tap((res) => {
            window.location.reload();
          })
        )
        .subscribe();
    }
  }

  editStage() {
    if (this.isLinkedToState) {
      return this._albumService.inlivewithAlbumUpdateStagePut$Json({
        body: {
          albumId: this.album.id!,
          updateStageId: this.selectedStage!
            ? this.selectedStage.id!
            : this.album.stageId!,
        },
      });
    } else {
      return this._albumService.inlivewithAlbumUpdateStagePut$Json({
        body: {
          albumId: this.album.id!,
          updateStageId: this.defaultStage.id!,
        },
      });
    }
  }

  editDescription(data: AlbumResponseWithTotalAssetModel) {
    return this._albumService.inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo().id!,
        albumId: data.id!,
        clubId: data.clubId!,
        body: {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data.id!,
          clubId: data.clubId!,
          value:
            this.tempDescription !== ''
              ? this.tempDescription
              : this.album?.description!,
        },
      }
    );
  }

  editImageCover(data: AlbumResponseWithTotalAssetModel) {
    return this._albumService.inlivewithAlbumCoverImageAlbumIdPost$Json({
      albumId: data.id!,
      body: {
        AlbumId: data.id!,
        CoverImage: this.avtUpload,
      },
    });
  }

  editForAll(data: AlbumResponseWithTotalAssetModel) {
    if (this.isAcceptViewPrivate) {
      return this._albumService.inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data?.id!,
          clubId: data?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: data?.id!,
            clubId: data?.clubId!,
          },
        }
      );
    } else {
      return this._albumService.inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: this.album?.id!,
          clubId: this.album?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: this.album?.id!,
            clubId: this.album?.clubId!,
          },
        }
      );
    }
  }

  editStatus(data: AlbumResponseWithTotalAssetModel) {
    if (!this.isPrivate) {
      return this._albumService.inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data?.id!,
          clubId: data?.clubId!,
          isAcceptViewPrivate: this.album.isAcceptViewPrivate!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: data?.id!,
            clubId: data?.clubId!,
          },
        }
      );
    } else {
      return this._albumService.inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data?.id!,
          clubId: data?.clubId!,
          isAcceptViewPrivate: this.album.isAcceptViewPrivate!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: data?.id!,
            clubId: data?.clubId!,
          },
        }
      );
    }
  }

  editMemberToViewAlbum() {
    let listString: string[] = [];
    this.selectedInlivers.forEach((x) => {
      listString?.push(x.id!);
    });
    return this._albumService.inlivewithAlbumChangeMemberToAlbumPut$Json({
      body: {
        adminId: AuthService.GetAccountInfo().id!,
        albumId: this.album.id!,
        accountIdWillBeUpdates: listString,
        accessLevel: 'ACCESSLEVEL_PLUS',
      },
    });
  }

  selectedStageChange(event: any) {
    this.isAcceptViewPrivate = event?.isAcceptViewPrivate!;
  }
}
