<p-dialog [(visible)]="showDialog" [resizable]="false" [draggable]="false" (onHide)="onHide()">

    <div class="card flex align-items-center flex-column">

        <div class="title">
            {{'POST_DELETE' | translate}}
        </div>

        <div class="text mt-5" *ngIf="post && post.author?.id != currentUser.id!" [innerHTML]="'POST_DELETE_CONFIRM' | translate:{value:(post.author?.nickName! | limitString:false:40)}"></div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}" class="mt-5 p-button-outlined p-button-secondary mr-2"
                (click)="showDialog = false"></button>
            <button pButton type="submit" label="{{'DELETE' | translate}}" class="mt-5 p-button-outlined p-button-secondary ml-2"
                (click)="isDelete.emit(); showDialog = false"></button>
        </div>
    </div>
</p-dialog>