<p-dialog [(visible)]="showDialog" (onHide)="onHide()" (onShow)="getAsset()" [draggable]="false" [resizable]="true"
    [modal]="true">
    <div class="card" *ngIf="post">
        <div class="flex w-80 mt-5" *ngIf="listAssets?.length! > 0">

            <p-carousel [circular]="false" [value]="listAssets" [page]="indexAsset" (onPage)="getDetailAsset($event)"
                [numVisible]="1" [numScroll]="1" class="w-100" [showIndicators]="false">
                <ng-template let-asset pTemplate="item">
                    <div class="zone-assets flex-column">
                        <img *ngIf="checkTypeMedia(asset) == 'IMAGE'" [src]="asset?.url!"
                            (load)="checkOrientationImage($event, asset)"
                            [ngClass]="asset.isLandscape ? 'landscape' : 'portable'" style="max-width: 100%;">
                        <video *ngIf="checkTypeMedia(asset) == 'VIDEO'" [id]="asset?.id! + 'v'"
                            (loadeddata)="checkOrientationVideo($event, asset)"
                            [ngClass]="asset.isLandscape ? 'landscape' : 'portable'" [src]="asset?.url!" controls
                            [poster]="getPoster(asset?.url!)" [autoplay]="currentAsset.id! == asset?.id! ? true : false"
                            [id]="asset!.url" style="max-width: 100%;"></video>
                        <span class="num-page">
                            {{indexAsset + 1}}/{{listAssets.length}}
                        </span>
                    </div>

                </ng-template>
            </p-carousel>
        </div>
        <div class="flex flex-column position-relative mt-5" style="width: 73%;">

            <div class="flex flex-row justify-content-between w-100 mt-5">
                <div class="flex align-items-center w-100">
                    <div class="flex w-100 ml-2 align-items-center">
                        <div class="zone-avatar">
                            <img [src]="post.author?.avatar! ? post.author?.avatar! : defaultAvatar"
                                class="fit-size cursor-pointer">
                        </div>

                        <span class="fs-18 mr-2 text-white cursor-pointer ml-3">{{(post.author?.nickName! |
                            limitString:false:40) ?? ''}} </span>
                    </div>

                    <div class="flex w-100 ml-2 justify-content-end">
                        <span class="mt-2" style="color: #e6e6e6;">{{timeAgo(post.dateCreated!)}}</span>
                        <!-- {{post.dateCreated! | date: 'dd/MM/yy, HH:mm'}} -->
                    </div>
                </div>
            </div>

            <div class="mb-5 mt-5 text-white">
                <div class="overflow-wrap-breakword" [innerHTML]="urlify(post.message!)"></div>
            </div>

            <div class="flex justify-content-between align-items-center mt-5 ml-1">
                <div class="flex">
                    <div class="flex align-items-center" style="width: 72px">
                        <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post.isReacted"
                            style="font-size: 24px; transition: color 0.5s" [ngClass]="{'liked' : post!.isReacted!}"
                            (click)="likePost()"></i>
                        <span (click)="openLikeZone()" class="fs-12 ml-2 cursor-pointer">{{post.totalReact}}</span>
                    </div>
                    <div class="flex align-items-center ml-5 cursor-pointer">
                        <img class="cursor-pointer"
                            [src]="post!.isCommented ? '../../../assets/images/svgs/commented.svg' : '../../../assets/images/svgs/comment.svg'">
                        <span class="fs-12 ml-2">{{post.totalComment}}</span>
                    </div>
                </div>
            </div>

            <div style="border-bottom: 3px solid #212121;margin-top: 2rem;"></div>

            <div class="action-zone">
                <div style="background-color: black;padding: 1rem 0rem 1rem 0rem;">
                    <div class="comment-zone">
                        <div class="flex flex-column input-text justify-content-center ">
                            <div class="flex flex-wrap align-items-center"
                                *ngIf="(urls.length > 0 || urlAudio) && !isEditComment"
                                [ngStyle]="(urls.length > 0 || urlAudio) ? {'padding':'1.5rem'} : {}">
                                <div class="zone-media-cus" *ngFor="let url of sortFiles(urls); let i = index">
                                    <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url" height="50">
                                    <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url"
                                        height="50" controls></video>
                                    <div class="position-relative" *ngIf="url.format === 'audio'">
                                        <audio controls>
                                            <source [src]=" url.data" type="audio/wav">
                                        </audio>
                                        <img class="delete-asset" (click)="removeAsset(url.id)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>
                                    <div class="position-relative m-3"
                                        style="height: 30px; display: flex; align-items: center"
                                        *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                        <span class="fs-14">{{url.file.name}}</span>
                                        <img class="delete-asset" (click)="removeAsset(url.id)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>
                                    <img class="delete-asset" *ngIf="url.format === 'video' || url.format === 'image'"
                                        (click)="removeAsset(url.id)"
                                        src="../../../assets/images/svgs/trash-bg-white.svg">
                                </div>
                            </div>

                            <div class="flex flex-wrap align-items-center"
                                *ngIf="isEditComment && currentReplyComment?.mediaCommentPosts?.length! > 0"
                                [ngStyle]="isEditComment && currentReplyComment!.mediaCommentPosts!.length! > 0 ? {'padding':'1.5rem'} : {}">
                                <div class="position-relative" style="overflow: auto;"
                                    *ngFor="let media of filesDelete; let i = index">
                                    <div class="m-3" *ngIf="(checkTypeMedia(media) == 'IMAGE')"
                                        style="position: relative;  width: fit-content;" [id]="media.id!">
                                        <img class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'IMAGE'"
                                            height="100">
                                        <img class="delete-asset" (click)="actionDelete(media)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>

                                    <div class="m-3" *ngIf="checkTypeMedia(media) == 'VIDEO'"
                                        style="position: relative; width: fit-content;" [id]="media.id!">
                                        <video class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'VIDEO'"
                                            height="100" controls></video>
                                        <img class="delete-asset" (click)="actionDelete(media)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>

                                    <div class="m-3" *ngIf="checkTypeMedia(media) == 'AUDIO'"
                                        style="position: relative; width: fit-content;" [id]="media.id!">
                                        <audio style="height: 5rem;" [src]="media?.url!" controls></audio>
                                        <img class="delete-asset" (click)="actionDelete(media)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>


                                    <div style="position: relative;" class="m-3" *ngIf="checkProcessMediaFail(media)">
                                        <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                            style="height: 100px !important;"
                                            [innerHTML]="'UNKNOWING_FILE' | translate">
                                        </div>
                                        <img class="delete-asset" (click)="actionDelete(media)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap align-items-center" *ngIf="(urls.length > 0) && isEditComment"
                                [ngStyle]="urls.length > 0 ? {'padding':'1.5rem'} : {}">
                                <div class="position-relative" style="overflow: auto;"
                                    *ngFor="let url of sortFiles(urls); let i = index">
                                    <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url"
                                        height="100">
                                    <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url"
                                        height="100" controls></video>
                                    <div class="position-relative" *ngIf="url.format === 'audio'">
                                        <audio controls>
                                            <source [src]=" url.data" type="audio/wav">
                                        </audio>
                                        <img class="delete-asset" (click)="removeAsset(url.id)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>
                                    <div class="position-relative"
                                        *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                        <span>{{url.file.name}}</span>
                                        <img class="delete-asset" (click)="removeAsset(url.id)"
                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                    </div>
                                    <img class="delete-asset" *ngIf="url.format === 'video' || url.format === 'image'"
                                        (click)="removeAsset(url.id)"
                                        src="../../../assets/images/svgs/trash-bg-white.svg">
                                </div>
                            </div>

                            <div class="text-error flex flex-column align-items-start"
                                style="padding: 0px 2.5rem; font-size: 14px;"
                                *ngIf="!isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                                {{'LIST_ITEM_REASON' | translate}}
                                <div class="text-error" *ngIf="showErrorMixedFiles">
                                    {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                                </div>

                                <div class="text-error" *ngIf="showErrorDuration">
                                    {{'DURATION_MAX_4MINS' | translate}}
                                </div>

                                <div class="text-error" *ngIf="showErrorMaximumFiles">
                                    {{'MAXIMUM_4_MEDIA' | translate}}
                                </div>

                                <div class="text-error" *ngIf="showErrorLimitSizeFile">
                                    {{'FILE_SIZE_LIMIT_POST' | translate}}
                                </div>
                            </div>
                            <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="!isHaveParent">
                                <span class="text-error" *ngIf="!isValidToAddAssets">
                                    {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                                </span>
                                <span class="text-error" *ngIf="!isValidToAddAudio">
                                    {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                                </span>
                                <span class="text-error" *ngIf="showEditRule">
                                    {{'RULE_EDIT_POST' | translate}}
                                </span>
                            </div>

                            <div class="flex justify-content-center align-items-center" style="position: relative;">

                                <ng-template #mentionListTemplate let-item="item">
                                    <div class="flex align-items-center">
                                        <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40"
                                            height="40" style="border-radius: 5px;">
                                        <span class="p-1">{{item.name! | limitString:false:20}}</span>
                                    </div>
                                </ng-template>

                                <ng-container *ngIf="openPopupRecordAudio">
                                    <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'600px'"
                                        [widthAudio]="'550px'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                                        (sendAudio)="createComment()"></app-record-audio-v2>
                                </ng-container>
                                <textarea class="textarea-cus w-100" style="resize: none;" [rows]="1"
                                    *ngIf="!openPopupRecordAudio" [(ngModel)]="content" [mentionConfig]="mentionConfig"
                                    [mentionListTemplate]="mentionListTemplate"
                                    placeholder="{{'COMMENT_WRITE' | translate}}"></textarea>

                                <div class="flex justify-content-center align-items-center cursor-pointer"
                                    *ngIf="!openPopupRecordAudio">
                                    <img class="cursor-pointer mr-3" src="../../../../assets/images/svgs/emojis.svg"
                                        alt="" srcset="" height="16" (click)="changeShowEmoji($event)">
                                    <img class="mr-3" src="../../../assets/images/svgs/mic.svg"
                                        (click)="startRecord(false)" height="16"
                                        *ngIf="(!urls.length && !isEditComment && content?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                                    <i class="pi pi pi-image mr-3"
                                        (click)="(isJoined) ? inputFile.click() : askJoinClub.emit()"
                                        *ngIf="(!urlAudio && (!isEditComment)) || (isEditComment && (typeOfComment == 'ONLY_MEDIAS' || typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'TEXT_AND_MEDIAS' || typeOfComment == 'TEXT_AND_AUDIO' || typeOfComment == 'UNKNOWN'))"></i>
                                    <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                        *ngIf="content?.length! > 0 || (urls?.length! > 0) || (urlAudio! && blobFileAudio!) || (isEditComment)"></i>
                                </div>
                            </div>
                            <input style="display: none" type="file" #inputFile multiple (change)="detectFiles($event)">
                            <div id="zoneAudio"></div>
                        </div>

                    </div>

                    <div #toggleEmoji (click)="clickOnSide($event)">
                        <emoji-mart [isNative]="true" class="emoji" *ngIf="showEmoji" [sheetSize]="32"
                            (emojiClick)="addEmoji($event)"></emoji-mart>
                    </div>
                </div>
            </div>

            <div class="login-modal">
                <div class="content">

                    <div class="list-comment" id="list-comment">
                        <div class="comments">
                            <span *ngIf="!isDone" class="load-comment" (click)="loadMoreComment()">
                                {{'SEE_MORE_COMMENTS' | translate}}
                            </span>
                            <div class="comments" style="padding-bottom: 8rem;">
                                <ng-container *ngFor="let item of comments.slice().reverse()">
                                    <div class="item">
                                        <div class="avatar">
                                            <img class="fit-size cursor-pointer"
                                                [src]="item?.author?.avatar?? defaultAvatar" alt=""
                                                (click)="openAccountInfo(item.authorId!, item.isBannedByClub!)">
                                        </div>
                                        <div class="detail">
                                            <div class="name"
                                                (click)="openAccountInfo(item?.author?.id!, item.isBannedByClub!)">
                                                {{item.isBannedByClub ? ('INLIVER_UNKNOWN' | translate) :
                                                (item?.author?.nickName! | limitString:false:40)}}
                                                <span class="ml-2 label-celeb"
                                                    *ngIf="item.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'">
                                                    {{'CELEBRITY' | translate}}
                                                </span>
                                            </div>
                                            <div class="time">
                                                <span>{{timeAgo(item?.dateCreated!)}}</span>
                                            </div>
                                            <div class="content" [innerHTML]="urlify(item!.message!)">
                                                <!-- {{item?.message}} -->
                                            </div>
                                            <div style="position: relative" *ngIf="!item.isBannedByClub">
                                                <div class="flex" *ngIf="item?.mediaCommentPosts?.length! > 0"
                                                    [id]="item.id">

                                                    <div class="list-media"
                                                        *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
                                                        <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                            <div class="flex justify-content-center align-items-center"
                                                                *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                                <i class="pi pi-spin pi-spinner"
                                                                    style="font-size: 2rem"></i>
                                                            </div>

                                                            <div class="w-100 h-100"
                                                                *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                                <img (click)="showMultiMedia(item?.mediaCommentPosts!, i)"
                                                                    class="fit-size" [src]="getUrl(asset)" alt="">
                                                            </div>

                                                            <div class="w-100 h-100"
                                                                *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                                <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                                    [url]="asset?.url!" [poster]="getUrl(asset)"
                                                                    (dblclick)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                                                </app-video-layer>
                                                            </div>
                                                        </ng-container>


                                                        <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                            <div class="flex justify-content-center media position-relative align-items-center zone-media-fail"
                                                                [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                </div>
                                                <div class="mr-1 w-100"
                                                    *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
                                                    <div class="w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                        <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                            <app-wave-form-audio
                                                                [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                        </ng-container>
                                                        <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                            <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                                                [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="action" *ngIf="!item.isBannedByClub">
                                                <div class="flex">
                                                    <img src="../../../assets/images/svgs/reply.svg" alt="">
                                                    <span (click)="focusText(item)">{{'RESPONSE' | translate}}</span>
                                                </div>
                                            </div>


                                            <div class="content" *ngIf="item.isBannedByClub"
                                                [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                        </div>
                                        <div class="action-menu" *ngIf="!item.isBannedByClub">
                                            <div>
                                                <i class="pi pi-ellipsis-h"
                                                    (click)="menu.toggle($event); isHaveParent = false;"></i>
                                                <p-menu #menu [popup]="true" [model]="getItem(item)" [appendTo]="'body'"
                                                    styleClass="menu-comment"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comment-reply" *ngIf="item && item?.inverseParent?.length! > 0">
                                        <span *ngIf="item.isContinueReply" class="load-reply"
                                            (click)="loadMoreCommentReply(item)">
                                            {{'LOAD_MORE_COMMENTS' | translate}}
                                        </span>
                                        <div *ngFor="let itemChild of item?.inverseParent">
                                            <div class="item-child">
                                                <div class="avatar">
                                                    <img class="fit-size cursor-pointer"
                                                        [src]="itemChild?.author?.avatar && !itemChild.isBannedByClub! ? itemChild.author?.avatar : defaultAvatar"
                                                        alt=""
                                                        (click)="openAccountInfo(itemChild.author?.id!, itemChild.isBannedByClub!)">
                                                </div>
                                                <div class="detail">
                                                    <div class="name">
                                                        {{!itemChild.isBannedByClub ? (itemChild?.author?.nickName! |
                                                        limitString:false:40) :
                                                        ('INLIVER_UNKNOWN' | translate)}}
                                                        <span class="ml-2 label-celeb"
                                                            *ngIf="itemChild.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'">
                                                            {{'CELEBRITY' | translate}}
                                                        </span>
                                                    </div>
                                                    <div class="time">
                                                        <span>{{timeAgo(itemChild?.dateCreated!)}}</span>
                                                    </div>
                                                    <div class="content" *ngIf="!itemChild.isBannedByClub"
                                                        [innerHTML]="urlify(itemChild!.message!)">
                                                    </div>
                                                    <div *ngIf="itemChild?.mediaCommentPosts?.length! > 0  && !itemChild.isBannedByClub"
                                                        class="w-100 mt-5 mb-5" style="position: relative">

                                                        <div class="flex" [id]="itemChild.id">
                                                            <div class="list-media"
                                                                *ngFor="let asset of itemChild?.mediaCommentPosts!; let i = index">
                                                                <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                                    <div class="flex justify-content-center align-items-center"
                                                                        *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                                        <i class="pi pi-spin pi-spinner"
                                                                            style="font-size: 2rem"></i>
                                                                    </div>

                                                                    <div class="w-100 h-100"
                                                                        *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                                        <img (click)="showMultiMedia(itemChild?.mediaCommentPosts!, i)"
                                                                            class="fit-size" [src]="getUrl(asset)"
                                                                            alt="">

                                                                    </div>

                                                                    <div class="w-100 h-100"
                                                                        *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                                        <!-- <video class="fit-size" [src]="asset?.url!"
                                                                            [poster]="getUrl(asset)" controls></video> -->
                                                                        <app-video-layer *ngIf="asset?.url!"
                                                                            [layout]="'full'" [url]="asset?.url!"
                                                                            [poster]="getUrl(asset)"
                                                                            (dblclick)="showMultiMedia(itemChild?.mediaCommentPosts!, i)">
                                                                        </app-video-layer>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                                    <div class="flex justify-content-center media position-relative align-items-center zone-media-fail"
                                                                        [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                                    </div>
                                                                </ng-container>

                                                            </div>
                                                            <div class="w-100"
                                                                *ngFor="let asset of itemChild?.mediaCommentPosts!">
                                                                <ng-container
                                                                    *ngIf="!checkProcessMediaFail(asset) && checkTypeMedia(asset) == 'AUDIO'">
                                                                    <div class="flex w-100">
                                                                        <app-wave-form-audio
                                                                            [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="checkProcessMediaFail(asset) && checkTypeMedia(asset) == 'AUDIO'">
                                                                    <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                                                        [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="content" *ngIf="itemChild.isBannedByClub"
                                                        [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                                </div>
                                                <div class="action-menu" *ngIf="!item.isBannedByClub">
                                                    <div>
                                                        <i class="pi pi-ellipsis-h"
                                                            (click)="menu.toggle($event); isHaveParent = false;"></i>
                                                        <p-menu #menu [popup]="true" [model]="getItem(itemChild)"
                                                            [appendTo]="'body'" styleClass="menu-comment"></p-menu>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column mt-5 align-items-end pr-5" id="replyComment"
                                        *ngIf="isHaveParent && currentReplyComment.id === item.id">

                                        <div class="flex w-90">
                                            <div class="flex flex-wrap"
                                                *ngIf="(urlsChild.length > 0 || urlAudioChild) && !isEditComment"
                                                [ngStyle]="urlsChild.length > 0 || urlAudioChild ? {'padding':'1.5rem'} : {}">
                                                <div class="position-relative" style="overflow: auto;"
                                                    *ngFor="let url of sortFiles(urlsChild); let i = index">
                                                    <img class="m-3" [src]="url.data"
                                                        *ngIf="url.format === 'image' && url" height="80">
                                                    <video class="m-3" [src]="url.data"
                                                        *ngIf="url.format === 'video' && url" height="80"
                                                        controls></video>
                                                    <div class="position-relative" *ngIf="url.format === 'audio'">
                                                        <audio controls>
                                                            <source [src]=" url.data" type="audio/wav">
                                                        </audio>
                                                        <img class="delete-asset-audio"
                                                            (click)="removeAssetChild(url.id)"
                                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                                    </div>
                                                    <div class="position-relative m-3"
                                                        style="height: 30px; display: flex; align-items: center"
                                                        *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                                        <span class="fs-14">{{url.file.name}}</span>
                                                        <img class="delete-asset-audio"
                                                            (click)="removeAssetChild(url.id)"
                                                            src="../../../assets/images/svgs/trash-bg-white.svg">
                                                    </div>
                                                    <img class="delete-asset"
                                                        *ngIf="url.format === 'video' || url.format === 'image'"
                                                        (click)="removeAssetChild(url.id)"
                                                        src="../../../assets/images/svgs/trash-bg-white.svg">
                                                </div>
                                            </div>

                                            <ng-template #mentionListTemplate let-item="item">
                                                <div class="flex align-items-center">
                                                    <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt=""
                                                        width="40" height="40" style="border-radius: 5px;">
                                                    <span class="p-1">{{item.name! | limitString:false:20}}</span>
                                                </div>
                                            </ng-template>


                                        </div>


                                        <div class="text-error flex flex-column align-items-start"
                                            style="padding: 0px 2.5rem; font-size: 14px;"
                                            *ngIf="isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                                            {{'LIST_ITEM_REASON' | translate}}
                                            <div class="text-error" *ngIf="showErrorMixedFiles">
                                                {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorDuration">
                                                {{'DURATION_MAX_4MINS' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorMaximumFiles">
                                                {{'MAXIMUM_4_MEDIA' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorLimitSizeFile">
                                                {{'FILE_SIZE_LIMIT_POST' | translate}}
                                            </div>
                                        </div>
                                        <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="isHaveParent">
                                            <span class="text-error" *ngIf="!isValidToAddAssets">
                                                {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                                            </span>
                                            <span class="text-error" *ngIf="!isValidToAddAudio">
                                                {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                                            </span>
                                            <span class="text-error" *ngIf="showEditRule">
                                                {{'RULE_EDIT_POST' | translate}}
                                            </span>
                                        </div>


                                        <div class="flex" style="width: 95%;">

                                            <div class="flex align-items-center justify-content-between w-100">

                                                <div class="zone-avt flex justify-content-center align-items-center">
                                                    <img class="fit-size" [src]="currentUser.avatar ?? defaultAvatar">
                                                </div>

                                                <ng-container *ngIf="openPopupRecordAudioChild">
                                                    <app-record-audio-v2 [(openRecording)]="openPopupRecordAudioChild"
                                                        [width]="'600px'" [widthAudio]="'550px'" [(url)]="urlAudioChild"
                                                        [(blobFile)]="blobFileAudioChild"
                                                        (sendAudio)="createReplyComment()"></app-record-audio-v2>
                                                </ng-container>

                                                <div class="flex" *ngIf="!openPopupRecordAudioChild"
                                                    style="border: solid 1px rgb(107, 107, 107);width: 94%;border-radius: 15px;">

                                                    <textarea class="textarea-reply w-100" style="resize: none;"
                                                        *ngIf="!openPopupRecordAudioChild" [(ngModel)]="contentReply"
                                                        [mentionConfig]="mentionConfig"
                                                        [mentionListTemplate]="mentionListTemplate"
                                                        [placeholder]="('COMMENT_REPLY_PLACEHOLDER' | translate) + '@' + (currentReplyComment.author?.nickName! | limitString:false:40)"
                                                        autofocus></textarea>

                                                    <div class="flex justify-content-end"
                                                        *ngIf="!openPopupRecordAudioChild">
                                                        <div class="cursor-pointer flex align-items-center">
                                                            <img class="cursor-pointer mr-3"
                                                                src="../../../../assets/images/svgs/emojis.svg" alt=""
                                                                srcset="" height="16" (click)="changeShowEmoji($event)">
                                                            <img class="mr-3" src="../../../assets/images/svgs/mic.svg"
                                                                (click)="startRecord(true)"
                                                                *ngIf="(!urlsChild.length && !isEditComment && contentReply?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                                                            <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                                                                *ngIf="(!urlAudioChild && (!isEditComment)) || (isEditComment && (typeOfComment != 'TEXT'))"></i>
                                                            <i class="pi pi-arrow-circle-right mr-3"
                                                                (click)="createReplyComment()"
                                                                *ngIf="contentReply.length > 0 || (urlsChild.length > 0) || (urlAudioChild && blobFileAudioChild) || (isEditComment)"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                                        (change)="detectFiles($event)">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>


<ng-container *ngIf="isShowPopupConfirm">
    <app-card-confirm [(isShowPopup)]="isShowPopupConfirm" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'DELETE' | translate" (doAction)="deleteComment()" [title]="'COMMENT_DELETE' | translate"
        [content]="'COMMENT_DELETE_CONFIRM' | translate:{value: (currentReplyComment.author?.nickName! | limitString:false:40)}">
    </app-card-confirm>
</ng-container>

<!-- <ng-container *ngIf="openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudio" [(blobFile)]="blobFileAudio">
    </app-record-audio>
</ng-container>

<ng-container *ngIf="isHaveParent && currentReplyComment && openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudioChild" [(blobFile)]="blobFileAudioChild">
    </app-record-audio>
</ng-container> -->

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'COMMENT'" [nameObject]="this.currentReplyComment"
        (refreshWhenReportSuccess)="getCommentByPostId()">
    </app-report>
</ng-container>

<ng-container *ngIf="applyClub">
    <app-confirm-request-join-club [(isConfirmJoinClub)]="applyClub"></app-confirm-request-join-club>
</ng-container>

<ng-container *ngIf="openLikePopup">
    <app-list-like [(showDialog)]="openLikePopup" [isMobile]="false" [post]="post"></app-list-like>
</ng-container>

<ng-container *ngIf="isShowMultiMedia">
    <app-show-multi-media [(openShowMutilMedia)]="isShowMultiMedia" [(urls)]="multiMedias"
        [(activeIndex)]="activeIndex"></app-show-multi-media>
</ng-container>