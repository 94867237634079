<p-dialog [(visible)]="openPopup" [draggable]="false" [resizable]="false" [modal]="true" [closable]="true"
    (onHide)="onHide()">

    <ng-container *ngIf="STEP == 1">
        <div class="card">
            <div class="title">
                Rediffuser le Go Inlive
            </div>
            <div class="subtitle text-white pt-2">
                Le live sera disponible sur ta scène principale
                pendant 24h.
            </div>
            <div class="flex mt-5 justify-content-center text-white">
                <button pButton type="submit" [label]="'CANCEL' | translate"
                    class="m-1 p-button-outlined p-button-secondary" (click)="cancel()">
                </button>
                <button pButton type="submit" [label]="'YES' | translate"
                    class="m-1 p-button-outlined p-button-secondary" (click)="STEP = 2">
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="STEP == 2">
        <div class="card">
            <div class="title">
                Partager le live
            </div>
            <div class="subtitle mt-3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
            </div>

            <div class="flex flex-column w-100">
                <input class="mb-5" style="width:100%" type="text" pInputText [(ngModel)]="name"
                    placeholder="{{'Nom du live' | translate}}" />

                <span class="text-white mb-2">
                    Description
                </span>
                <textarea class="w-100 text-area-cus" [rows]="10" [(ngModel)]="description"
                    placeholder="{{'Que veux-tu dire ?' | translate}}"></textarea>
            </div>

            <div class="flex mt-5 justify-content-center mt-3">
                <button pButton type="submit" [label]="'CANCEL' | translate"
                    class="m-1 p-button-outlined p-button-secondary" (click)="cancel()">
                </button>
                <button pButton type="submit" [label]="'SHARE' | translate"
                    class="m-1 p-button-outlined p-button-secondary" (click)="updatePost()">
                </button>
            </div>
        </div>
    </ng-container>

</p-dialog>