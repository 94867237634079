import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { mergeMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/api/be/services';
import { CheckErrorForm, EMAIL_REGEX } from 'src/app/core/constant';
import { EmailServiceCustom } from 'src/app/core/services/email.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import * as Identity from 'src/app/core/api/identity/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Input() showDialogForgotPassword!: boolean;

  sendmailSuccess: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({});
  emailIsExits!: boolean;

  CheckErrorForm = CheckErrorForm;
  submited: boolean = false;

  constructor(private _accountService: AccountService, private fb: UntypedFormBuilder, private _accountServiceIdentity: Identity.AccountService) {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]]
    })
  }

  ngOnInit(): void {
  }

  checkEmail() {
    this.submited = true;

    if (!this.form.value.email) {
      return;
    }

    this._accountService.inlivewithAccountByEmailEmailGet$Json({ email: this.form.value.email })
      .pipe(
        tap((res) => {
          console.log(res);
          if (res.res == 0) {
            this.emailIsExits = true;
          }
          else {
            this.emailIsExits = false;
          }
        })
      )
      .subscribe();
  }


  forgotPassword() {
    this.submited = true;

    if (!this.form.value.email) {
      return;
    }

    LoadingService.$(true);

    let body = {
      email: this.form?.value?.email!
    }

    this._accountServiceIdentity.identityAccountForgotPasswordPost$Json({ body })
      .pipe(
        tap((res) => {
          console.log(res);
        }),
        mergeMap((res) => {
          let email = this.form.value.email;
          let data = res.data;
          return EmailServiceCustom.SendEmail(email, data!)
        })
      )
      .subscribe(res => {
        LoadingService.$(false);
        this.sendmailSuccess = true;
      }, () => { LoadingService.$(false) })
  }

}
