<div class="card">
    <div class="title">
        {{ 'TELL_US_WHAT_YOU_LIKE' | translate}}
    </div>
    <div class="subtitle">
        {{ '9_HASHTAGS_MAXIMUM_EACH_CATEGORY' | translate}}
    </div>

    <div class="content">
        <span>
            {{'YOUR_SELECTED_TAGS' | translate}}
        </span>

        <p *ngIf="hashtagSelecteds.length == 0" style="font-size: 16px;color: #7a7a7a;">{{'NO_HASHTAG_SELECTED' |
            translate}}</p>

        <div class="flex flex-wrap">
            <p *ngFor="let item of hashtagSelecteds" class="ml-2"
                style="color: black;background-color: white;padding: 7px;border-radius: 6px;display: flex;align-items: center;">
                {{ item.name }}
                <i class="pi pi-times ml-2 cursor-pointer" (click)="deleteHashtag(item)"></i>
            </p>
        </div>

        <!-- <span *ngIf="textErrorMax5" style="font-size: 16px;color:red">Max 5</span> -->

        <div class="mt-1">
            <span>
                {{ 'SELECT_CATEGORY' | translate}}
            </span>
        </div>
        <div class="flex justify-content-between align-items-center">
            <ng-container class="" *ngFor="let c of listCategory | async; let i = index">
                <div class="col-3 flex flex-column mt-3 cursor-pointer" (click)="selectCategory(c.name!)">
                    <div class="poster">
                        <img [src]="getPosterByCategory(c.name!).posterImage!" class="fit-size" alt="" srcset=""
                            [ngStyle]="categorySelected == c.name ? {'border':'solid', 'border-radius':'18px'} : {}">
                        <span style="font-size: 16px;text-shadow: 2px 0px 10px black;">
                            {{c.value! | translate}}
                        </span>
                    </div>

                </div>
            </ng-container>
        </div>
        <div class="flex mt-3" *ngIf="categorySelected">
            <input [(ngModel)]="searchText" (ngModelChange)="search()" class="w-100" pInputText />
        </div>

        <div class="list-hashtag">
            <div class="flex flex-wrap mt-3">
                <div *ngFor="let h of hashtagByCategory; let i = index;">
                    <div (click)="selectHashtag(h)" [id]="h.name"
                        [ngStyle]="checkDisable(h) ? {'color':'gray', 'pointer-events':'none'}:{'color':'white'}"
                        class="p-2 m-1 cursor-pointer" style="border-radius: 5px;">
                        {{h?.name!}}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-content-center" *ngIf="hashtagSelecteds.length >= 3">
            <button pButton type="submit" label="{{'SAVE_CHANGES' | translate}}" (click)="suivant()"
                [disabled]="hashtagSelecteds.length < 3 || hashtagSelecteds.length > 10" class="mt-5 p-button-outlined p-button-secondary"></button>
        </div>
    </div>
</div>