import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewChecked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import {
  debounce,
  debounceTime,
  finalize,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {
  Account,
  AccountResponseWithRoleInCLubModel,
  ClubResponseWithTotalMemberAndGoInliveModel,
} from 'src/app/core/api/be/models';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { MobileService } from 'src/app/core/services/is-mobile.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-club-role-moderation',
  templateUrl: './club-role-moderation.component.html',
  styleUrls: ['./club-role-moderation.component.scss'],
})
export class ClubRoleModerationComponent implements OnInit, AfterViewChecked {
  @Input() showDialog!: boolean;
  @Input() admin!: Account;
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() isMobile: boolean = false;

  @Output() showDialogChange = new EventEmitter();

  currentUser = AuthService.GetAccountInfo();

  openPopupPickAdmin!: boolean;
  openPopupPickInliver!: boolean;
  openToPickAdmin!: boolean;
  openPopupAddAdminMobile!: boolean;
  openPopupAddInliverMobile!: boolean;

  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];
  listInlivers: AccountResponseWithRoleInCLubModel[] = [];
  selectedInlivers: AccountResponseWithRoleInCLubModel[] = [];
  listAdmins: AccountResponseWithRoleInCLubModel[] = [];

  items!: MenuItem[];

  defaultAvatar = defaultAvatar;
  openPopupReport = false;
  currentAccount!: AccountResponseWithRoleInCLubModel;

  constructor(
    private _clubService: ClubService,
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _router: Router,
    private _mobileService: MobileService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getListAdminstrator().subscribe();
    this.getListMember().subscribe();
    this.isMobile = this._mobileService.isMobile();
  }

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  initMenu(account: AccountResponseWithRoleInCLubModel) {
    var translatePipe = new LimitStringPipe();
    this.items =
      account.roleInClub == 'ROLE_ADMIN'
        ? [
          {
            icon: 'pi pi-users',
            label: this._translateService.instant('PROFILE_SEE'),
            command: (event: any) => {
              this.openAccountInfo(account);
            },
          },
          {
            icon: 'pi pi-shield',
            label: this._translateService.instant('RETIRE_ADMIN'),
            command: () => {
              this.retireAdmin(account);
            },
          },
          // {
          //   icon: 'pi pi-user-plus',
          //   label: this._translateService.instant('ADD_COLLABORATOR'),
          //   command: () => {
          //   }
          // },
          // {
          //   icon: 'pi pi-comment',
          //   label: this._translateService.instant('SEND_PRIVATE_MESSAGE'),
          //   command: () => {
          //     this._router.navigate(['account-info'], { queryParams: { accountId: account?.id! } });
          //   }
          // },
          {
            icon: 'pi pi-user-minus',
            label:
              translatePipe.transform(this._translateService.instant('RETIRE') +
                ` ${account.nickName!} ` +
                this._translateService.instant('OF_THE_CLUB'), false, 40),

            command: (event: any) => {
              this.rejectMember(account);
            },
          },
          {
            icon: 'pi pi-flag',
            label: translatePipe.transform(this._translateService.instant('REPORT') +
              ` ${account.nickName!} `, false, 40),
            command: () => {
              this.currentAccount = account;
              this.openPopupReport = true;
            },
          },
          {
            icon: 'pi pi-ban',
            label: translatePipe.transform(this._translateService.instant('BAN') +
              ` ${account.nickName!} `, false, 40),
            command: () => {
              this.currentAccount = account;
              this.openPopupReport = true;
            },
          },
        ]
        : [
          // {
          //   icon: 'pi pi-shield',
          //   label: this._translateService.instant('REMOVE_INLIVERPLUS'),
          //   command: () => {
          //     this.updateAccessLevelAnonymous(account)
          //   }
          // },
          // {
          //   icon: 'pi pi-comment',
          //   label: this._translateService.instant('SEND_PRIVATE_MESSAGE'),
          //   command: () => {
          //     this._router.navigate(['account-info'], { queryParams: { accountId: account?.id! } });
          //   }
          // },
          {
            icon: 'pi pi-flag',
            label: translatePipe.transform(this._translateService.instant('REPORT') +
              ` ${account.nickName!} `, false, 40),
            command: () => {
              this.currentAccount = account;
              this.openPopupReport = true;
            },
          },
          {
            icon: 'pi pi-ban',
            label:
              translatePipe.transform(this._translateService.instant('BAN') +
                ` ${account.nickName!} `, false, 40),
            command: () => {
              this.currentAccount = account;
              this.openPopupReport = true;
            },
          },
        ];

    return this.items;
  }

  openAccountInfo(account: AccountResponseWithRoleInCLubModel) {
    if (AuthService.GetAccountInfo().id! !== account.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: account?.id! },
      });
    }
  }

  rejectMember(account: AccountResponseWithRoleInCLubModel) {
    LoadingService.$(true);

    let body = {
      accountId: account.id!,
      clubId: this.club.id,
    };

    this._clubService
      .inlivewithClubAccountKickMemberOutClubPost$Json({ body })
      .subscribe(() => {
        LoadingService.$(false);
        this.refreshWhenReportSuccess();
      });

    // this._accountService
    //   .inlivewithAccountClubAccountIdDelete$Plain({
    //     accountId: account.id!,
    //     body,
    //   })
    //   .subscribe(() => LoadingService.$(false));
  }

  openToAddAdmin() {
    if (!this.isMobile) {
      this.openPopupPickAdmin = true;
    } else {
      console.log('?');
      this.openPopupAddAdminMobile = true;
    }

    this.selectedInlivers = [];
    this.openToPickAdmin = true;
  }

  openToAddInliver() {
    if (!this.isMobile) {
      this.openPopupPickInliver = true;
    } else {
      this.openPopupAddInliverMobile = true;
    }

    this.selectedInlivers = [];
    this.openToPickAdmin = false;
  }

  ngAfterViewChecked(): void {
    if (
      this.selectedInlivers.length > 0 &&
      !this.openPopupPickAdmin &&
      this.openToPickAdmin
    ) {
      this.selectedInlivers.forEach((x) => {
        this.setRoleInClub(x);
        this.selectedInlivers.splice(
          this.selectedInlivers.findIndex((a) => a.id === x.id),
          1
        );
      });
    } else if (
      this.selectedInlivers.length > 0 &&
      !this.openPopupPickInliver &&
      !this.openToPickAdmin
    ) {
      this.selectedInlivers.forEach((x) => {
        this.updateAccessLevelPlus(x);
        this.selectedInlivers.splice(
          this.selectedInlivers.findIndex((a) => a.id === x.id),
          1
        );
      });
    }
  }

  setRoleInClub(account: AccountResponseWithRoleInCLubModel) {
    return this._clubService
      .inlivewithRoleSetRoleAccountPut$Json({
        body: {
          accountId: account.id!,
          adminId: AuthService.GetAccountInfo().id!,
          clubId: this.club.id!,
          newRole: 'ROLE_ADMIN',
          newAccessLevel: 'ACCESSLEVEL_PLUS',
        },
      })
      .pipe(
        tap(() =>
          this.showNotification(
            account.nickName +
            ' ' +
            this._translateService.instant('ADD_MODERATOR_SUCCESS')
          )
        ),
        mergeMap(() =>
          forkJoin([this.getListAdminstrator(), this.getListMember()])
        )
      )
      .subscribe();
  }

  updateAccessLevelPlus(account: AccountResponseWithRoleInCLubModel) {
    return this._accountService
      .inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json({
        accountId: account.id!,
        clubId: this.club.id!,
        body: {
          accountId: account.id!,
          clubId: this.club.id!,
        },
      })
      .pipe(
        tap(() =>
          this.showNotification(
            account.nickName +
            ' ' +
            this._translateService.instant('GRANT_INLIVER_PLUS_SUCCESS')
          )
        ),
        mergeMap(() => this.getListMember())
      )
      .subscribe();
  }

  updateAccessLevelAnonymous(account: AccountResponseWithRoleInCLubModel) {
    return this._accountService
      .inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json({
        accountId: account.id!,
        clubId: this.club.id!,

        body: {
          accountId: account.id!,
          clubId: this.club.id!,
        },
      })
      .pipe(
        tap(() =>
          this.showNotification(
            account.nickName +
            ' ' +
            this._translateService.instant('DELETE_INLIVERPLUS_SUCCESS')
          )
        ),
        mergeMap(() => this.getListMember())
      )
      .subscribe();
  }

  getListAdminstrator() {
    return this._clubService
      .inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json({
        clubId: this.club.id!,
        limit: 1000,
        accountRequestId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listAdmins = res.data?.page!.filter(
              (x) => x.id !== this.club.author?.id!
            )!;
          }
        })
      );
  }

  retireAdmin(admin: AccountResponseWithRoleInCLubModel) {
    this._clubService
      .inlivewithRoleSetRoleAccountPut$Json({
        body: {
          accountId: admin.id!,
          adminId: AuthService.GetAccountInfo().id!,
          clubId: this.club.id!,
          newRole: 'ROLE_ANONYMOUS',
          newAccessLevel: 'ACCESSLEVEL_ANONYMOUS',
        },
      })
      .pipe(
        mergeMap((res) => this.getListAdminstrator()),
        mergeMap((res) => this.getListMember())
      )
      .subscribe();
  }

  getListMember() {
    return this._clubService
      .inlivewithClubMembersClubIdGet$Json({
        clubId: this.club.id!,
        limit: 1000,
        accountRequestId: AuthService.GetAccountInfo().id!,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listInliverPlus = res.data
              ?.page!.filter(
                ({ id: id1 }) =>
                  !this.listAdmins.some(({ id: id2 }) => id2 === id1)
              )
              .filter(
                (x) =>
                  x.roleInClub == 'ROLE_ANONYMOUS' &&
                  x.accessLevelInClub == 'ACCESSLEVEL_PLUS'
              )!;
            this.listInlivers = res.data?.page!.filter(
              (x) =>
                x.roleInClub == 'ROLE_ANONYMOUS' &&
                x.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS'
            )!;
          }
        })
      );
  }

  refreshWhenReportSuccess() {
    this.openPopupReport = false;
    this.ngOnInit();
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }
}
