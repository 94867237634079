import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {
  ClubResponseWithTotalMemberAndGoInliveModel,
  ClubResponseWithTotalMemberModel,
} from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-club-infomation-view',
  templateUrl: './club-infomation-view.component.html',
  styleUrls: ['./club-infomation-view.component.scss'],
})
export class ClubInfomationViewComponent implements OnInit {
  @Input() isShowPopup!: boolean;
  @Input() title: string = '';
  @Input() clubId!: string;

  @Output() isShowPopupChange = new EventEmitter<boolean>();
  club!: ClubResponseWithTotalMemberAndGoInliveModel;

  constructor(private _router: Router, private _clubService: ClubService) {}

  ngOnInit(): void {
    this.getClubById();
  }

  onHide() {
    this.isShowPopupChange.emit(this.isShowPopup);
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
  }

  viewClub() {
    this._router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: this.clubId },
    });
  }

  getClubById() {
    this._clubService
      .inlivewithClubByIdClubIdGet$Json({ clubId: this.clubId })
      .pipe(
        tap((res) => {
          res.res == 0 ? (this.club = res.data!) : '';
        })
      )
      .subscribe();
  }
}
