<div class="container m-2">
    <div class="zone-avt">
        <span class="skeleton-box"></span>
    </div>
    <div class="content">
        <div class="name">
            <span class="skeleton-box"></span>
        </div>
        <div class="text mt-2">
            <span class="skeleton-box"></span>
        </div>
    </div>
    <div class="flex btn">
        <span class="skeleton-box"></span>
    </div>
</div>