<p-dialog [(visible)]="accountInvalidPopup" (onHide)="onHide()" [closable]="false" [resizable]="false" [modal]="true"
    [draggable]="false">
    <div class="zone-notification">
        <div class="m-3">
            <img class="fit-size" src="./assets/images/svgs/alert-triangle.svg">
        </div>
        <div class="content">
            <span class="title">
                {{'PERSONNALIZE_YOUR_PROFILE' | translate}}
            </span>
            <span class="subtitle" [innerHTML]="'CHOOSING_YOUR_NICKNAME' | translate"></span>
        </div>
        <div class="action">
            <button pButton type="submit" label="{{'CONTINUE' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary mr-2" (click)="gotoEdit()"></button>
        </div>
    </div>
</p-dialog>