<ng-container>
    <div class="card cursor-pointer">
        <!-- <div class="icon-club" *ngIf="live">
            <img class="w-100 h-100" [src]="getIcon()">
        </div> -->

        <div class="status m-3">
            {{'LIVE' | translate}}
        </div>
        <div class="zone-avt cursor-pointer">
            <img [src]="live!.author!.avatar! | fetchUrlImage" class="fit-size" (click)="checkGoInlive()">
        </div>
    </div>

    <div class="info mt-2">
        <div class="club-name flex align-items-center">
            {{live!.author!.nickName}}
            <i [ngClass]="live!.isPrivate! ? 'pi pi-lock ml-2' : 'pi pi-unlock ml-2'"></i>
        </div>

        <!-- <div class="flex text mt-1">
            <i class="flex half-icon-heart mr-1" *ngIf="live!.type! == 'CLUB_TYPE_FAN'"></i>
            <i class="flex half-icon-star mr-1" *ngIf="live!.type! == 'CLUB_TYPE_TALENT'"></i>
            {{live!.type! | translate}}
        </div> -->

        <!-- <div class="mt-1 text">
            {{live!.club!.totalMember!}} {{(live!.club!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') |
            translate}}
        </div> -->
    </div>
</ng-container>


<ng-container *ngIf="isShowReviewCamera">
    <p-sidebar [(visible)]="isShowReviewCamera" position="bottom" [fullScreen]="true" [showCloseIcon]="false"
        [dismissible]="true">
        <app-review-camera (close)="isShowReviewCamera = false" (join)="join()"></app-review-camera>
    </p-sidebar>
</ng-container>