import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClubResponseWithTotalMemberModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-club-joined',
  templateUrl: './club-joined.component.html',
  styleUrls: ['./club-joined.component.scss'],
})
export class ClubJoinedComponent implements OnInit {
  listClubFollowing: ClubResponseWithTotalMemberModel[] = [];

  constructor(private _clubService: ClubService, private _router: Router) { }

  ngOnInit(): void {
    if (!AuthService.GetAccountInfo()?.id!) {
      return;
    }

    if (!this._router.url.includes('/detail-club')) {
      this.getClubFollowed().subscribe((res) => {
        if (res && res.res == 0) {
          this.listClubFollowing = res.data?.page!;
        }
      });
    }
  }

  getClubFollowed() {
    return this._clubService.inlivewithClubsByAccountAccountIdGet$Json({
      accountId: AuthService.GetAccountInfo()?.id!,
      limit: 1000,
    });
  }
}
