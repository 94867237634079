import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountResponseGeneral } from 'src/app/core/api/be/models';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-friend',
  templateUrl: './card-friend.component.html',
  styleUrls: ['./card-friend.component.scss']
})
export class CardFriendComponent implements OnInit {

  @Input() account!: AccountResponseGeneral;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  gotoAccountInfo() {
    if (AuthService.GetAccountInfo().id! !== this.account?.id!) {
      this._router.navigate(['account-info'], { queryParams: { accountId: this.account?.id! } });
    }
  }

}
