/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MentionCreateModel } from '../models/mention-create-model';
import { MentionResponseModelListResponseModel } from '../models/mention-response-model-list-response-model';
import { MentionResponseModelPaginationModelResponseModel } from '../models/mention-response-model-pagination-model-response-model';
import { MentionResponseModelResponseModel } from '../models/mention-response-model-response-model';
import { MentionsCreateModel } from '../models/mentions-create-model';

@Injectable({
  providedIn: 'root',
})
export class MentionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithMentionCreatePost
   */
  static readonly InlivewithMentionCreatePostPath = '/inlivewith/Mention/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatePost$Plain$Response(params?: {
    body?: MentionCreateModel
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatePost$Plain(params?: {
    body?: MentionCreateModel
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatePost$Json$Response(params?: {
    body?: MentionCreateModel
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatePost$Json(params?: {
    body?: MentionCreateModel
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionCreatesPost
   */
  static readonly InlivewithMentionCreatesPostPath = '/inlivewith/Mention/Creates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionCreatesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatesPost$Plain$Response(params?: {
    isPushNoti?: boolean;
    body?: MentionsCreateModel
  }): Observable<StrictHttpResponse<MentionResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionCreatesPostPath, 'post');
    if (params) {
      rb.query('isPushNoti', params.isPushNoti, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionCreatesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatesPost$Plain(params?: {
    isPushNoti?: boolean;
    body?: MentionsCreateModel
  }): Observable<MentionResponseModelListResponseModel> {

    return this.inlivewithMentionCreatesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelListResponseModel>) => r.body as MentionResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionCreatesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatesPost$Json$Response(params?: {
    isPushNoti?: boolean;
    body?: MentionsCreateModel
  }): Observable<StrictHttpResponse<MentionResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionCreatesPostPath, 'post');
    if (params) {
      rb.query('isPushNoti', params.isPushNoti, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionCreatesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMentionCreatesPost$Json(params?: {
    isPushNoti?: boolean;
    body?: MentionsCreateModel
  }): Observable<MentionResponseModelListResponseModel> {

    return this.inlivewithMentionCreatesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelListResponseModel>) => r.body as MentionResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionUnMentionMentionIdDelete
   */
  static readonly InlivewithMentionUnMentionMentionIdDeletePath = '/inlivewith/Mention/UnMention/{mentionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionUnMentionMentionIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionUnMentionMentionIdDelete$Plain$Response(params: {
    mentionId: string;
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionUnMentionMentionIdDeletePath, 'delete');
    if (params) {
      rb.path('mentionId', params.mentionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionUnMentionMentionIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionUnMentionMentionIdDelete$Plain(params: {
    mentionId: string;
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionUnMentionMentionIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionUnMentionMentionIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionUnMentionMentionIdDelete$Json$Response(params: {
    mentionId: string;
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionUnMentionMentionIdDeletePath, 'delete');
    if (params) {
      rb.path('mentionId', params.mentionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionUnMentionMentionIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionUnMentionMentionIdDelete$Json(params: {
    mentionId: string;
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionUnMentionMentionIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionGetMentionByIdMentionIdGet
   */
  static readonly InlivewithMentionGetMentionByIdMentionIdGetPath = '/inlivewith/Mention/GetMentionById/{mentionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionByIdMentionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionByIdMentionIdGet$Plain$Response(params: {
    mentionId: string;
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionByIdMentionIdGetPath, 'get');
    if (params) {
      rb.path('mentionId', params.mentionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionByIdMentionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionByIdMentionIdGet$Plain(params: {
    mentionId: string;
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionGetMentionByIdMentionIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionByIdMentionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionByIdMentionIdGet$Json$Response(params: {
    mentionId: string;
  }): Observable<StrictHttpResponse<MentionResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionByIdMentionIdGetPath, 'get');
    if (params) {
      rb.path('mentionId', params.mentionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionByIdMentionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionByIdMentionIdGet$Json(params: {
    mentionId: string;
  }): Observable<MentionResponseModelResponseModel> {

    return this.inlivewithMentionGetMentionByIdMentionIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelResponseModel>) => r.body as MentionResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionGetMentionsByReceiverReceiverIdGet
   */
  static readonly InlivewithMentionGetMentionsByReceiverReceiverIdGetPath = '/inlivewith/Mention/GetMentionsByReceiver/{receiverId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByReceiverReceiverIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByReceiverReceiverIdGet$Plain$Response(params: {
    receiverId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByReceiverReceiverIdGetPath, 'get');
    if (params) {
      rb.path('receiverId', params.receiverId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByReceiverReceiverIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByReceiverReceiverIdGet$Plain(params: {
    receiverId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByReceiverReceiverIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByReceiverReceiverIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByReceiverReceiverIdGet$Json$Response(params: {
    receiverId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByReceiverReceiverIdGetPath, 'get');
    if (params) {
      rb.path('receiverId', params.receiverId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByReceiverReceiverIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByReceiverReceiverIdGet$Json(params: {
    receiverId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByReceiverReceiverIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionGetMentionsByPostPostIdGet
   */
  static readonly InlivewithMentionGetMentionsByPostPostIdGetPath = '/inlivewith/Mention/GetMentionsByPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByPostPostIdGet$Plain$Response(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByPostPostIdGet$Plain(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByPostPostIdGet$Json$Response(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByPostPostIdGet$Json(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionGetMentionsByCommentPostCommentPostIdGet
   */
  static readonly InlivewithMentionGetMentionsByCommentPostCommentPostIdGetPath = '/inlivewith/Mention/GetMentionsByCommentPost/{commentPostId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Plain$Response(params: {
    commentPostId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByCommentPostCommentPostIdGetPath, 'get');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Plain(params: {
    commentPostId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Json$Response(params: {
    commentPostId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByCommentPostCommentPostIdGetPath, 'get');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Json(params: {
    commentPostId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByCommentPostCommentPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet
   */
  static readonly InlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGetPath = '/inlivewith/Mention/GetMentionsByCommentAlbumAsset/{commentAlbumAssetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Plain$Response(params: {
    commentAlbumAssetId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Plain(params: {
    commentAlbumAssetId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Json$Response(params: {
    commentAlbumAssetId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MentionService.InlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Json(params: {
    commentAlbumAssetId: string;
    page?: number;
    limit?: number;
  }): Observable<MentionResponseModelPaginationModelResponseModel> {

    return this.inlivewithMentionGetMentionsByCommentAlbumAssetCommentAlbumAssetIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MentionResponseModelPaginationModelResponseModel>) => r.body as MentionResponseModelPaginationModelResponseModel)
    );
  }

}
