import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from './primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from '../core/services/translation.service';
import { CustomTranslatePipe } from '../core/pipe/translation-custom.pipe';
import { SafeHtmlPipe } from '../core/pipe/safehtml.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoginComponent } from './components/login/login.component';
import { LoadingComponent } from './widgets/loading/loading.component';
import { SendmailSuccessComponent } from './widgets/sendmail-success/sendmail-success.component';
import { ResetpassSuccessComponent } from './widgets/resetpass-success/resetpass-success.component';
import { ResetpassOupsComponent } from './widgets/resetpass-oups/resetpass-oups.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterSuccessComponent } from './widgets/register-success/register-success.component';
// import { CreateClubComponent } from './components/manage-club/create-club/create-club.component';
// import { DetailClubComponent } from './components/manage-club/detail-club/detail-club.component';
import { CardProfileInliverComponent } from './widgets/card-profile-inliver/card-profile-inliver.component';
import { CardClubComponent } from './widgets/card-club/card-club.component';
import { CardEventComponent } from './widgets/card-event/card-event.component';
import { CardGoinliveComponent } from './widgets/card-goinlive/card-goinlive.component';
import { CardCreateClubSuccessComponent } from './widgets/card-create-club-success/card-create-club-success.component';
import { StepComponent } from './widgets/step/step.component';
import { EditUserComponent } from '../pages/manage-account/edit-user/edit-user.component';
import { CardJoinClubSuccessComponent } from './widgets/card-join-club-success/card-join-club-success.component';
import { ClubRoleModerationComponent } from './components/club-role-moderation/club-role-moderation.component';
import { CardAlbumComponent } from './widgets/card-album/card-album.component';
import { ViewMediaAlbumComponent } from './components/view-media-album/view-media-album.component';
import { ApplyroleAccesslevelComponent } from './widgets/applyrole-accesslevel/applyrole-accesslevel.component';
import { EditClubComponent } from './components/edit-club/edit-club.component';
import { ConfirmRequestJoinClubComponent } from './widgets/confirm-request-join-club/confirm-request-join-club.component';
import { EditDescriptionClubComponent } from './components/edit-description-club/edit-description-club.component';
import { EditPrivacyClubComponent } from './components/edit-privacy-club/edit-privacy-club.component';
import { PostComponent } from './components/post/post.component';
import { ClubRulesComponent } from './widgets/club-rules/club-rules.component';
import { RecordAudioComponent } from './components/record-audio/record-audio.component';
import { ListStageComponent } from './components/list-stage/list-stage.component';
import { CommentPostComponent } from './components/comment-post/comment-post.component';
import { CardConfirmComponent } from './widgets/card-confirm/card-confirm.component';
import { ConfirmDeletePostComponent } from './widgets/confirm-delete-post/confirm-delete-post.component';
import { EditAlbumComponent } from './components/edit-album/edit-album.component';
import { ListInliverComponent } from './widgets/list-inliver/list-inliver.component';
import { CardUserApplyComponent } from './widgets/card-user-apply/card-user-apply.component';
import { ListInliverBannedComponent } from './widgets/list-inliver-banned/list-inliver-banned.component';
import { ListInliverReasonBannedComponent } from './widgets/list-inliver-reason-banned/list-inliver-reason-banned.component';
import { CardClubAccountInfoComponent } from './widgets/card-club-account-info/card-club-account-info.component';
import { ParametersAccountComponent } from '../pages/manage-account/parameters-account/parameters-account.component';
import { CardConfirmHasImageComponent } from './widgets/card-confirm-has-image/card-confirm-has-image.component';
import { ListMembersClubComponent } from './components/list-members-club/list-members-club.component';
import { ListInviteGoinliveComponent } from './components/list-invite-goinlive/list-invite-goinlive.component';
import { CardInviteGoinliveComponent } from './widgets/card-invite-goinlive/card-invite-goinlive.component';
import { SelectTypeGoinliveComponent } from './widgets/select-type-goinlive/select-type-goinlive.component';
import { CdTimerModule } from 'angular-cd-timer';
import { ListViewerGoinliveComponent } from './components/list-viewer-goinlive/list-viewer-goinlive.component';
import { ViewMediaPostComponent } from './components/view-media-post/view-media-post.component';
import { LayoutMediaPostComponent } from './components/post/layout-media-post/layout-media-post.component';

import { NgWaveformModule } from 'ng-waveform';
import { EditPostComponent } from './components/edit-post/edit-post.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { CardConfirmJoinEventComponent } from './widgets/card-confirm-join-event/card-confirm-join-event.component';
import { MentionLegalComponent } from './components/mention-legal/mention-legal.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CardAccountInfoComponent } from './widgets/card-account-info/card-account-info.component';
import { EditPolicyClubComponent } from './widgets/edit-policy-club/edit-policy-club.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { CardAccountFriendConfirmComponent } from './widgets/card-account-friend-confirm/card-account-friend-confirm.component';
import { FetchUrlImagePipe } from '../core/pipe/fetch-url-image.pipe';
import { FetchUrlVideoPipe } from '../core/pipe/fetch-url-video.pipe';
import { CardFriendRequestComponent } from './widgets/card-friend-request/card-friend-request.component';
import { MediaService } from '../core/services/media.service';
import { CardFriendComponent } from './widgets/card-friend/card-friend.component';
import { CreatePostWhenEndliveComponent } from './components/create-post-when-endlive/create-post-when-endlive.component';
import { ScheduleGoinliveComponent } from './components/schedule-goinlive/schedule-goinlive.component';
import { ListInliversGoinliveComponent } from './widgets/list-inlivers-goinlive/list-inlivers-goinlive.component';
import { VideoLayerComponent } from './widgets/video-layer/video-layer.component';
import { CommentAlbumComponent } from './components/comment-album/comment-album.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MentionModule } from 'angular-mentions';
import { ReportComponent } from './components/report/report.component';
import { ReportStepIComponent } from './components/report/report-step-i/report-step-i.component';
import { ReportStepIiComponent } from './components/report/report-step-ii/report-step-ii.component';
import { ReportStepIiiComponent } from './components/report/report-step-iii/report-step-iii.component';
import { ReportStepIvComponent } from './components/report/report-step-iv/report-step-iv.component';
import { ClubReportComponent } from './widgets/club-report/club-report.component';
import { ListLikeComponent } from './widgets/list-like/list-like.component';
import { ImagesLazyloadModule } from './images-lazyload/images-lazyload.module';
import { InviteFriendViaMailComponent } from './components/invite-friend-via-mail/invite-friend-via-mail.component';
import { LayoutMediaPostMobileComponent } from './components/post/layout-media-post-mobile/layout-media-post-mobile.component';
import { MobileConfirmDeleteClubComponent } from './components/mobiles/mobile-confirm-delete-club/mobile-confirm-delete-club.component';
import { MobileConfirmDeleteAlbumComponent } from './components/mobiles/mobile-confirm-delete-album/mobile-confirm-delete-album.component';
import { MobileEditPrivacyClubComponent } from './components/mobiles/mobile-edit-privacy-club/mobile-edit-privacy-club.component';
import { MobileConfirmDeleteAssetAlbumComponent } from './components/mobiles/mobile-confirm-delete-asset-album/mobile-confirm-delete-asset-album.component';
import { MobileViewMediaAlbumComponent } from './components/mobiles/mobile-view-media-album/mobile-view-media-album.component';
import { MobileCommentPostComponent } from './components/mobiles/mobile-comment-post/mobile-comment-post.component';
import { MobileConfirmDeleteCommentComponent } from './components/mobiles/mobile-confirm-delete-comment/mobile-confirm-delete-comment.component';
import { MobileApplyroleAccesslevelComponent } from './components/mobiles/mobile-applyrole-accesslevel/mobile-applyrole-accesslevel.component';
import { MobileEditAlbumComponent } from './components/mobiles/mobile-edit-album/mobile-edit-album.component';
import { MobileListInliverComponent } from './components/mobiles/mobile-list-inliver/mobile-list-inliver.component';
import { MobileConfirmCreateAlbumComponent } from './components/mobiles/mobile-confirm-create-album/mobile-confirm-create-album.component';
import { MobileConfirmCreateStageComponent } from './components/mobiles/mobile-confirm-create-stage/mobile-confirm-create-stage.component';
import { ListLikeMediaAlbumComponent } from './widgets/list-like-media-album/list-like-media-album.component';
import { PageRestrictedComponent } from './components/page-restricted/page-restricted.component';
import { PostSkeletonComponent } from './widgets/post-skeleton/post-skeleton.component';
import { IlwInfinityScrollComponent } from './lib/ilw-infinity-scroll/ilw-infinity-scroll.component';
import { ClubJoinedComponent } from './components/club-joined/club-joined.component';
import { ClubManagedComponent } from './components/club-managed/club-managed.component';
import { MyFriendComponent } from './components/my-friend/my-friend.component';
import { ClubSkeletonComponent } from './widgets/club-skeleton/club-skeleton.component';
import { EventSkeletonComponent } from './widgets/event-skeleton/event-skeleton.component';
import { InliverSkeletonComponent } from './widgets/inliver-skeleton/inliver-skeleton.component';
import { GoinliveSkeletonComponent } from './widgets/goinlive-skeleton/goinlive-skeleton.component';
import { AccountInfoClubSkeletonComponent } from './widgets/account-info-club-skeleton/account-info-club-skeleton.component';
import { AccountInfoFriendSkeletonComponent } from './widgets/account-info-friend-skeleton/account-info-friend-skeleton.component';
import { LimitStringPipe } from '../core/pipe/limit-string.pipe';
import { ClubInfomationViewComponent } from './widgets/club-infomation-view/club-infomation-view.component';
import { ClubNotificationSettingComponent } from './widgets/club-notification-setting/club-notification-setting.component';
import { ClubNotificationAdminSettingComponent } from './widgets/club-notification-admin-setting/club-notification-admin-setting.component';
import { NotificationSkeletonComponent } from './widgets/notification-skeleton/notification-skeleton.component';
import { ListInliverInviteClubComponent } from './widgets/list-inliver-invite-club/list-inliver-invite-club.component';
import { InliverListSkeletonComponent } from './widgets/inliver-list-skeleton/inliver-list-skeleton.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CardInviteJoinClubComponent } from './widgets/card-invite-join-club/card-invite-join-club.component';
import { ReviewCameraComponent } from './widgets/review-camera/review-camera.component';
import { WaveFormAudioComponent } from './components/wave-form-audio/wave-form-audio.component';
import { RecordAudioV2Component } from './components/record-audio-v2/record-audio-v2.component';
import { PostDiscussionComponent } from './widgets/post-discussion/post-discussion.component';
import { CreatePostForAdminComponent } from './components/create-post-for-admin/create-post-for-admin.component';
import { CommentPostDiscussionComponent } from './components/comment-post-discussion/comment-post-discussion.component';
import { BalloonComponent } from './components/balloon/balloon.component';
import { CardGoinliveCelebComponent } from './widgets/card-goinlive-celeb/card-goinlive-celeb.component';
import { CardPostCelebComponent } from './widgets/card-post-celeb/card-post-celeb.component';
import { PostCelebSkeletonComponent } from './widgets/post-celeb-skeleton/post-celeb-skeleton.component';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ListClubsCelebPostComponent } from './components/list-clubs-celeb-post/list-clubs-celeb-post.component';
import { CardPostCelebInClubPageComponent } from './widgets/card-post-celeb-in-club-page/card-post-celeb-in-club-page.component';
import { PostCelebComponent } from './widgets/post-celeb/post-celeb.component';
import { CardApplyClubComponent } from './widgets/card-apply-club/card-apply-club.component';
import { FetchUrlAvatarClubPipe } from '../core/pipe/fetch-url-avatar-club.pipe';
import { AccountInvalidPopupComponent } from './widgets/account-invalid-popup/account-invalid-popup.component';
import { ClubRulesAdminComponent } from './widgets/club-rules-admin/club-rules-admin.component';
import { ShowMultiMediaComponent } from './components/show-multi-media/show-multi-media.component';
import { UploadAvatarComponent } from './widgets/upload-avatar/upload-avatar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FilterListItemsPipe } from '../core/pipe/filterListItems.pipe';
import { NotiRequestAccessLevelComponent } from './widgets/noti-request-access-level/noti-request-access-level.component';
import { NotiReportContentMemberComponent } from './widgets/noti-report-content-member/noti-report-content-member.component';
import { ListMyFriendMessageComponent } from './components/list-my-friend-message/list-my-friend-message.component';
import { ListClubsHashtagComponent } from './components/list-clubs-hashtag/list-clubs-hashtag.component';
import { SidebarModule } from 'primeng/sidebar';
import { PostInliveteamComponent } from './widgets/post-inliveteam/post-inliveteam.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    CustomTranslatePipe,
    SafeHtmlPipe,
    FilterListItemsPipe,
    LoginComponent,
    LoadingComponent,
    SendmailSuccessComponent,
    ResetpassSuccessComponent,
    ResetpassOupsComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    // CreateClubComponent,
    // DetailClubComponent,
    CardProfileInliverComponent,
    CardClubComponent,
    CardEventComponent,
    CardGoinliveComponent,
    CardCreateClubSuccessComponent,
    StepComponent,
    EditUserComponent,
    CardJoinClubSuccessComponent,
    ClubRoleModerationComponent,
    CardAlbumComponent,
    ViewMediaAlbumComponent,
    ApplyroleAccesslevelComponent,
    EditClubComponent,
    ConfirmRequestJoinClubComponent,
    EditDescriptionClubComponent,
    EditPrivacyClubComponent,
    PostComponent,
    ApplyroleAccesslevelComponent,
    ClubRulesComponent,
    RecordAudioComponent,
    ListStageComponent,
    CommentPostComponent,
    CardConfirmComponent,
    ConfirmDeletePostComponent,
    EditAlbumComponent,
    ListInliverComponent,
    CardUserApplyComponent,
    ListInliverBannedComponent,
    ListInliverReasonBannedComponent,
    CardClubAccountInfoComponent,
    ParametersAccountComponent,
    CardConfirmHasImageComponent,
    ListMembersClubComponent,
    ListInviteGoinliveComponent,
    CardInviteGoinliveComponent,
    SelectTypeGoinliveComponent,
    ListViewerGoinliveComponent,
    ViewMediaPostComponent,
    LayoutMediaPostComponent,
    EditPostComponent,
    EditEventComponent,
    CardConfirmJoinEventComponent,
    MentionLegalComponent,
    CardAccountInfoComponent,
    EditPolicyClubComponent,
    CardAccountFriendConfirmComponent,
    FetchUrlImagePipe,
    FetchUrlVideoPipe,
    CardFriendRequestComponent,
    CreatePostWhenEndliveComponent,
    CardFriendComponent,
    ScheduleGoinliveComponent,
    ListInliversGoinliveComponent,
    VideoLayerComponent,
    CommentAlbumComponent,
    ReportComponent,
    ReportStepIComponent,
    ReportStepIiComponent,
    ReportStepIiiComponent,
    ReportStepIvComponent,
    ClubReportComponent,
    ListLikeComponent,
    InviteFriendViaMailComponent,
    LayoutMediaPostMobileComponent,
    MobileConfirmDeleteClubComponent,
    MobileConfirmDeleteAlbumComponent,
    MobileEditPrivacyClubComponent,
    MobileConfirmDeleteAssetAlbumComponent,
    MobileCommentPostComponent,
    MobileConfirmDeleteCommentComponent,
    MobileViewMediaAlbumComponent,
    MobileApplyroleAccesslevelComponent,
    MobileEditAlbumComponent,
    MobileListInliverComponent,
    MobileConfirmCreateAlbumComponent,
    MobileConfirmCreateStageComponent,
    ListLikeMediaAlbumComponent,
    PageRestrictedComponent,
    PostSkeletonComponent,
    IlwInfinityScrollComponent,
    ClubJoinedComponent,
    ClubManagedComponent,
    MyFriendComponent,
    ClubSkeletonComponent,
    EventSkeletonComponent,
    InliverSkeletonComponent,
    GoinliveSkeletonComponent,
    AccountInfoClubSkeletonComponent,
    AccountInfoFriendSkeletonComponent,
    LimitStringPipe,
    ClubInfomationViewComponent,
    ClubNotificationSettingComponent,
    ClubNotificationAdminSettingComponent,
    NotificationSkeletonComponent,
    ListInliverInviteClubComponent,
    InliverListSkeletonComponent,
    CardInviteJoinClubComponent,
    ReviewCameraComponent,
    WaveFormAudioComponent,
    RecordAudioV2Component,
    PostDiscussionComponent,
    CreatePostForAdminComponent,
    CommentPostDiscussionComponent,
    BalloonComponent,
    CardGoinliveCelebComponent,
    CardPostCelebComponent,
    PostCelebSkeletonComponent,
    ListClubsCelebPostComponent,
    CardPostCelebInClubPageComponent,
    PostCelebComponent,
    CardApplyClubComponent,
    FetchUrlAvatarClubPipe,
    AccountInvalidPopupComponent,
    ClubRulesAdminComponent,
    ShowMultiMediaComponent,
    UploadAvatarComponent,
    NotiRequestAccessLevelComponent,
    NotiReportContentMemberComponent,
    ListMyFriendMessageComponent,
    ListClubsHashtagComponent,
    PostInliveteamComponent,
  ],
  imports: [
    KeyFilterModule,
    CommonModule,
    PrimengModule,
    NgWaveformModule,
    FormsModule,
    ReactiveFormsModule,
    CdTimerModule,
    PdfViewerModule,
    MentionModule,
    ImagesLazyloadModule,
    ImageCropperModule,
    PickerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places'],
    }),
    Ng2SearchPipeModule,
  ],
  exports: [
    SidebarModule,
    KeyFilterModule,
    MentionModule,
    CdTimerModule,
    PrimengModule,
    FormsModule,
    SafeHtmlPipe,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    TranslateModule,
    LoginComponent,
    LoadingComponent,
    SendmailSuccessComponent,
    ResetpassSuccessComponent,
    ResetpassOupsComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    // CreateClubComponent,
    // DetailClubComponent,
    CardProfileInliverComponent,
    CardClubComponent,
    CardEventComponent,
    CardGoinliveComponent,
    CardCreateClubSuccessComponent,
    StepComponent,
    CardJoinClubSuccessComponent,
    ClubRoleModerationComponent,
    CardAlbumComponent,
    ViewMediaAlbumComponent,
    EditClubComponent,
    ConfirmRequestJoinClubComponent,
    EditDescriptionClubComponent,
    EditPrivacyClubComponent,
    PostComponent,
    ApplyroleAccesslevelComponent,
    ClubRulesComponent,
    RecordAudioComponent,
    ListStageComponent,
    ConfirmDeletePostComponent,
    EditAlbumComponent,
    ListInliverComponent,
    CardConfirmComponent,
    CardUserApplyComponent,
    ListInliverComponent,
    ListInliverBannedComponent,
    ListInliverReasonBannedComponent,
    CardClubAccountInfoComponent,
    CardConfirmHasImageComponent,
    ListMembersClubComponent,
    ListInviteGoinliveComponent,
    CardInviteGoinliveComponent,
    SelectTypeGoinliveComponent,
    ListViewerGoinliveComponent,
    ViewMediaPostComponent,
    LayoutMediaPostComponent,
    EditPostComponent,
    EditEventComponent,
    CardConfirmJoinEventComponent,
    CardAccountInfoComponent,
    EditPolicyClubComponent,
    CardAccountFriendConfirmComponent,
    FetchUrlImagePipe,
    FetchUrlVideoPipe,
    FilterListItemsPipe,
    CardFriendRequestComponent,
    CreatePostWhenEndliveComponent,
    CardFriendComponent,
    ScheduleGoinliveComponent,
    ListInliversGoinliveComponent,
    VideoLayerComponent,
    ClubReportComponent,
    InviteFriendViaMailComponent,
    LayoutMediaPostMobileComponent,
    MobileConfirmDeleteClubComponent,
    MobileConfirmDeleteAlbumComponent,
    MobileEditPrivacyClubComponent,
    MobileViewMediaAlbumComponent,
    MobileApplyroleAccesslevelComponent,
    MobileEditAlbumComponent,
    MobileListInliverComponent,
    MobileConfirmCreateAlbumComponent,
    ListLikeMediaAlbumComponent,
    PageRestrictedComponent,
    PostSkeletonComponent,
    IlwInfinityScrollComponent,
    ClubJoinedComponent,
    ClubManagedComponent,
    MyFriendComponent,
    ClubSkeletonComponent,
    EventSkeletonComponent,
    InliverSkeletonComponent,
    AccountInfoClubSkeletonComponent,
    AccountInfoFriendSkeletonComponent,
    LimitStringPipe,
    ClubInfomationViewComponent,
    ClubNotificationSettingComponent,
    ClubNotificationAdminSettingComponent,
    NotificationSkeletonComponent,
    ListInliverInviteClubComponent,
    InliverListSkeletonComponent,
    CardInviteJoinClubComponent,
    ReviewCameraComponent,
    WaveFormAudioComponent,
    RecordAudioV2Component,
    PostDiscussionComponent,
    CreatePostForAdminComponent,
    CommentPostDiscussionComponent,
    BalloonComponent,
    ReportComponent,
    CardGoinliveCelebComponent,
    CardPostCelebComponent,
    PostCelebSkeletonComponent,
    ListClubsCelebPostComponent,
    CardPostCelebInClubPageComponent,
    CardApplyClubComponent,
    FetchUrlAvatarClubPipe,
    AccountInvalidPopupComponent,
    ClubRulesAdminComponent,
    ShowMultiMediaComponent,
    UploadAvatarComponent,
    NotiRequestAccessLevelComponent,
    NotiReportContentMemberComponent,
    ListMyFriendMessageComponent,
    ListClubsHashtagComponent,
    PostInliveteamComponent
  ],
})
export class SharedModule {}
