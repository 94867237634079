import { Injectable } from '@angular/core';
import { AccountResponseGeneral } from '../api/be/models';
import { Subject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthService {
  private static _account: AccountResponseGeneral;
  private static _onChangeAvatar$ = new Subject<string>();

  public static SetAccountInfo(account: AccountResponseGeneral): void {
    this._account = account;
    this._onChangeAvatar$.next(account.avatar!);
    LocalStorageService.AddLocalStorage('account', JSON.stringify(account));
  }

  public static GetAccountInfo(): AccountResponseGeneral {
    return this._account || null;
  }

  public static OnChangeAvatar() {
    return this._onChangeAvatar$;
  }
}
