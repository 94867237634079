<p-dialog [(visible)]="showPopupEditAlbum" [draggable]="false" [resizable]="false" [closable]="true" [modal]="true"
    (onHide)="close()">
    <div style="width: 800px; height: 850px; padding: 0px 50px;">
        <div class="flex">
            <div class="zone-avt-album mr-4 position-relative">
                <img [src]="imgBlob ? imgBlob : album!.coverImage!" class="fit-size">
                <i *ngIf="imgBlob" class="pi pi-times cancel-image" (click)="cancelImage()"></i>
                <img src="../../../assets/images/svgs/edit-pencil.svg" (click)="inputFile.click()"
                    class="mr-3 cursor-pointer position-absolute" style="left: 45%;">
            </div>
            <div class="text-disabled w-100 ml-4 fs-18">
                {{'CLUB_ALBUM_NAME' | translate}}
                <input class="w-100" style="margin-top: 6%;" type="text" [(ngModel)]="tempName" pInputText
                    [placeholder]="album!.name!" />
            </div>
            <input style="display: none" id="file-avatar" type="file" #inputFile (change)="selectAvatar($event)"
                accept="image/png, image/jpeg">
        </div>
        <div *ngIf="(getCharacterLeft().numberLeft == 0 && getCharacterLeft().isFullChar) || (getCharacterLeft().numberLeft > 25)"
            class="text-error mt-1">
            {{'ALBUM_NAME_LENGTH' | translate}}
        </div>
        <div *ngIf="(!tempName || tempName.trim().length <= 0)" class="text-error">
            {{'ALBUM_NAME_REQUIRED' | translate}}
        </div>
        <div class="flex row mt-5 align-items-center">
            <div class="justify-content-center flex">
                <div class="label mb-2 flex flex-column" [ngClass]="!isPrivate ? 'text-disabled' : ''">
                    {{'CLUB_ALBUM_STATUS_PUBLIC' | translate}}
                    <span style="margin-top: 10px;">
                        {{'CLUB_ALBUM_PUBLIC_TITLE' | translate}}
                    </span>
                </div>
            </div>
            <div class="col-2 justify-content-center flex">
                <p-inputSwitch [(ngModel)]="isPrivate" (ngModelChange)="changeStatus()"></p-inputSwitch>
            </div>
            <div class="justify-content-center flex">
                <div class="label mb-2 flex flex-column" [ngClass]="isPrivate ? 'text-disabled' : ''">
                    {{'CLUB_ALBUM_STATUS_PRIVATE' | translate}}
                    <span style="margin-top: 10px;">
                        {{'CLUB_ALBUM_PRIVATE_TITLE' | translate}}
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-5" *ngIf="listStages.length! > 0">
            <div class="mb-2 text-white">
                {{(!isPrivate ? 'CLUB_ALBUM_LINK_STAGE_PUBLIC' : 'CLUB_ALBUM_LINK_STAGE_PRIVATE') | translate}}
            </div>
            <p-dropdown class="mt-4" [options]="listStages" [(ngModel)]="selectedStage"
                (ngModelChange)="selectedStageChange($event)" placeholder="{{'CLUB_STAGE_NAME' | translate}}"
                optionLabel="name"></p-dropdown>
        </div>
        <div class="mt-5" *ngIf="isPrivate">
            <div class="text-white mt-3 fs-14" *ngIf="selectedStage.isAcceptViewPrivate">
                {{'ALBUM_ALLOW_INVITATION' | translate}}
            </div>
            <div class="text-white mt-3 fs-14" *ngIf="!selectedStage.isAcceptViewPrivate">
                {{'ALBUM_DISALLOW_INVITATION' | translate}}
            </div>
            <div class="mt-3 text-gray fs-11">
                {{'CLUB_ADVANCE_OPTION_ALBUM_TEXT' | translate}}
            </div>
        </div>
        <div class="mt-5">
            <span class="text-white">
                {{'DESCRIPTION' | translate}}
            </span>
            <textarea class="textarea mt-4" maxlength="250" style="resize: none;" [placeholder]="album!.description!"
                [(ngModel)]="tempDescription" [rows]="6"
                placeholder="{{'CLUB_DESCRIPTION_PLACEHOLDER' | translate}}"></textarea>
            <div *ngIf="(!tempDescription || tempDescription.trim().length! <= 0)" class="text-error">
                {{'ALBUM_DESCRIPTION_REQUIRED' | translate}}
            </div>
        </div>
        <div class="mt-5 flex flex-column">
            <span class="text-white mb-3">
                {{'CLUB_ALBUM_WHO_CAN_POST' | translate}}
            </span>
            <p-radioButton class="mb-2" name="groupname" label="{{'CLUB_ALBUM_ADMIN_CAN_POST' | translate}}"
                [value]="false" [(ngModel)]="selectedValue"></p-radioButton>
            <p-radioButton class="mt-2" name="groupname" label="{{'CLUB_ALBUM_INLIVERPLUS_CAN_POST' | translate}}"
                [value]="true" [(ngModel)]="selectedValue"></p-radioButton>

            <div class="ml-5 fs-14 mt-3 add-inliver cursor-pointer" (click)="openPopup = true;" *ngIf="selectedValue">
                <span *ngIf="selectedInlivers.length == 0" class="mt-3">
                    {{'CLUB_ALBUM_PICK_LIST_MEMBERS' | translate}}
                </span>
                <div class="avatars" *ngIf="selectedInlivers.length > 0">
                    <div class="avatars__item" *ngFor="let inliver of selectedInlivers">
                        <div class="avatars__image zone-avatar">
                            <!-- Image -->
                            <img class="fit-size" [src]="inliver?.avatar ? inliver.avatar : defaultAvatar" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-content-center mt-5">
            <button pButton type="submit" label="{{'REGISTER' | translate}}" (click)="editAlbum()"
                class="mt-5 p-button-outlined p-button-secondary label"></button>
        </div>

    </div>
</p-dialog>>

<ng-container *ngIf="openPopup">
    <app-list-inliver [(openPopup)]="openPopup" [listInliverPlus]="listInliverPlus"
        [(selectedInlivers)]="selectedInlivers" [title]="'CLUB_WHO_CAN_POST' | translate"></app-list-inliver>
</ng-container>