import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export enum EEvent {
  DELETE_POST = 'DELETE_POST',
  HIDDEN_POST = 'HIDDEN_POST',
  CREATE_POST = 'CREATE_POST',
  CREATE_COMMENT = 'CREATE_COMMENT',
  OPEN_POST = 'OPEN_POST',
  END_POST = 'END_POST',
  CLOSE_COMMENT_POST_DISCUSSION = 'CLOSE_COMMENT_POST_DISCUSSION',
  UPDATE_COMMENT_MEDIA = 'UPDATE_COMMENT_MEDIA',
  DELETE_COMMENT = 'DELETE_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  SEND = 'SEND',
  PLAY_AUDIO = 'PLAY_AUDIO',
  PLAY_VIDEO = 'PLAY_VIDEO',
  CHANGE_URL_ALBUM = 'CHANGE_URL_ALBUM'
}

export interface IEvent {
  action: EEvent;
  payload: any;
}

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private subjet = new ReplaySubject<IEvent>();

  constructor() {}

  push(data: IEvent) {
    this.subjet.next(data);
  }

  receive() {
    return this.subjet;
  }
}
