import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  Album,
  AlbumResponseWithTotalAssetModel,
  AlbumUpdateStageModel,
  Stage,
  StageWithCheckExistAlbumModel,
} from 'src/app/core/api/be/models';
import {
  AlbumService,
  ClubService,
  StageService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-edit-album',
  templateUrl: './edit-album.component.html',
  styleUrls: ['./edit-album.component.scss'],
})
export class EditAlbumComponent implements OnInit {
  @Input() showPopupEditAlbum: boolean = false;
  @Input() album!: AlbumResponseWithTotalAssetModel;

  @Output() showPopupEditAlbumChange = new EventEmitter<boolean>();

  imgBlob!: string | ArrayBuffer | null;
  avtUpload: any;

  isPrivate!: boolean;
  isLinkedToState!: boolean;
  openPopup!: boolean;
  selectedValue: boolean = false;
  isAcceptViewPrivate!: boolean;

  tempName: string = '';
  tempDescription: string = '';

  selectedStage!: StageWithCheckExistAlbumModel;
  defaultStage!: StageWithCheckExistAlbumModel;
  listStages: StageWithCheckExistAlbumModel[] = [];
  cloneListStages: StageWithCheckExistAlbumModel[] = [];
  selectedInlivers: AccountResponseWithRoleInCLubModel[] = [];
  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar;

  constructor(
    private _stageService: StageService,
    private _albumService: AlbumService,
    private _clubService: ClubService,
    private _uploadService: UploadService
  ) { }

  ngOnInit(): void {
    this.isPrivate = this.album?.isPrivate!;
    this.isLinkedToState = true;
    this.selectedStage = this.album.stage!;
    this.tempDescription = this.album?.description!;
    this.tempName = this.album?.name!;
    this.isAcceptViewPrivate = this.album?.isAcceptViewPrivate!;
    this.getListStages().subscribe();
    this.getMembersInAlbum().subscribe();
    this.getListInliverPlus().subscribe();
  }

  selectAvatar(event: any) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.avtUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgBlob = reader.result;
      };
    }
  }

  getMembersInAlbum() {
    return this._albumService
      .inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json(
        {
          accessLevel: 'ACCESSLEVEL_PLUS',
          albumId: this.album.id!,
          limit: 1000,
          accountRequestId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          if (res.res! == 0) {
            this.selectedInlivers = res.data?.page!;
            if (this.selectedInlivers.length > 0) {
              this.selectedValue = true;
            }
          }
        })
      );
  }

  getListInliverPlus() {
    return this._clubService
      .inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json({
        clubId: this.album.clubId!,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000,
      })
      .pipe(
        tap((res) => {
          if (res.data) {
            let found = res.data?.page!.some(
              (x) => x.id === AuthService.GetAccountInfo()?.id!
            );
            if (found) {
              this.listInliverPlus = res.data?.page!.filter(
                (x) => x.id !== AuthService.GetAccountInfo()?.id!
              );
            } else {
              this.listInliverPlus = res.data?.page!;
            }
          }
        })
      );
  }

  cancelImage() {
    this.imgBlob = '';
    this.avtUpload = '';
    (<HTMLInputElement>document.getElementById('file-avatar')).value = '';
  }

  close() {
    this.showPopupEditAlbumChange.emit(false);
  }

  changeStatus() {
    this.listStages = this.cloneListStages.filter(
      (stage) => stage.isPrivate == this.isPrivate || stage.isDefault
    );
    setTimeout(() => {
      this.selectedStage = this.listStages[0];
      this.isAcceptViewPrivate = this.selectedStage.isAcceptViewPrivate!;
    }, 300);
  }

  getListStages() {
    return this._stageService
      .inlivewithStagesByClubIdClubIdGet$Json({
        clubId: this.album?.clubId!,
        limit: 20,
      })
      .pipe(
        tap((res) => {
          if (res.data) {
            const data = res.data?.page!;
            this.listStages = data;
            this.cloneListStages = data;
            this.defaultStage = res.data.page!.filter(
              (result) => result.isDefault
            )[0];
            this.selectedStage = this.listStages.find(
              (x) => x.id! === this.album.stageId!
            )!;

            this.listStages = this.cloneListStages.filter(
              (stage) => stage.isPrivate == this.isPrivate || stage.isDefault
            );
          }
        })
      );
  }

  editAlbum() {
    LoadingService.$(true);
    let countClick = 0;
    countClick++;

    if ((this.tempName && _.toArray(this.tempName?.trim()).length! == 0)) {
      countClick = 0;
      return;
    }

    if ((this.tempDescription && (this.tempDescription.trim().length == 0 || _.toArray(this.tempDescription).length! > 250))) {
      countClick = 0;
      return;
    }

    if (countClick == 1 && (this.getCharacterLeft().numberLeft == 0 && !this.getCharacterLeft().isFullChar) || (this.getCharacterLeft().numberLeft > 0 && this.getCharacterLeft().numberLeft <= 24)) {
      this._albumService
        .inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json({
          adminId: AuthService.GetAccountInfo().id!,
          albumId: this.album?.id!,
          clubId: this.album?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: this.album?.id!,
            clubId: this.album?.clubId!,
            value: this.tempName !== '' ? this.tempName : this.album?.name!,
          },
        })
        .pipe(
          tap(() => { }),
          mergeMap((res) => this.editDescription(res?.data!)),
          mergeMap((res) => this.editForAll(res?.data!)),
          mergeMap((res) => this.editStatus(res?.data!)),
          mergeMap((res) => this.editStage()),
          mergeMap((res) =>
            forkJoin([
              this.avtUpload ? this.editImageCover(res?.data!) : of(null),
              this.selectedInlivers ? this.editMemberToViewAlbum() : of(null),
            ])
          ),
          tap((res) => {
            window.location.reload();
          })
        )
        .subscribe();
    } else {
      LoadingService.$(false);
    }

  }

  editStage() {
    if (this.isLinkedToState) {
      return this._albumService.inlivewithAlbumUpdateStagePut$Json({
        body: {
          albumId: this.album.id!,
          updateStageId: this.selectedStage!
            ? this.selectedStage.id!
            : this.album.stageId!,
        },
      });
    } else {
      return this._albumService.inlivewithAlbumUpdateStagePut$Json({
        body: {
          albumId: this.album.id!,
          updateStageId: this.defaultStage.id!,
        },
      });
    }
  }

  editDescription(data: AlbumResponseWithTotalAssetModel) {
    return this._albumService.inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo().id!,
        albumId: data.id!,
        clubId: data.clubId!,
        body: {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data.id!,
          clubId: data.clubId!,
          value:
            this.tempDescription !== ''
              ? this.tempDescription
              : this.album?.description!,
        },
      }
    );
  }

  editImageCover(data: AlbumResponseWithTotalAssetModel) {
    return this._albumService.inlivewithAlbumCoverImageAlbumIdFileNamePost$Json({
      albumId: data.id!,
      fileName: this.avtUpload.name!
    }).pipe(
      switchMap((res) => this._uploadService.AddMediaPosts(this.avtUpload, res.data?.path!, res.data?.hook!, data.id!))
    );
  }

  editForAll(data: AlbumResponseWithTotalAssetModel) {
    if (this.isAcceptViewPrivate) {
      return this._albumService.inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: data?.id!,
          clubId: data?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: data?.id!,
            clubId: data?.clubId!,
          },
        }
      );
    } else {
      return this._albumService.inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json(
        {
          adminId: AuthService.GetAccountInfo().id!,
          albumId: this.album?.id!,
          clubId: this.album?.clubId!,
          body: {
            adminId: AuthService.GetAccountInfo().id!,
            albumId: this.album?.id!,
            clubId: this.album?.clubId!,
          },
        }
      );
    }
  }

  editStatus(data: AlbumResponseWithTotalAssetModel) {
    let body = {
      adminId: AuthService.GetAccountInfo().id!,
      albumId: data?.id!,
      clubId: data?.clubId!,
      isAcceptViewPrivate: this.isAcceptViewPrivate,
      body: {
        adminId: AuthService.GetAccountInfo().id!,
        albumId: data?.id!,
        clubId: data?.clubId!,
      },
    };

    if (!this.isPrivate) {
      return this._albumService.inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(
        body
      );
    } else {
      return this._albumService.inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(
        body
      );
    }
  }

  editMemberToViewAlbum() {
    let listString: string[] = [];
    this.selectedInlivers.forEach((x) => {
      listString?.push(x.id!);
    });
    return this._albumService.inlivewithAlbumChangeMemberToAlbumPut$Json({
      body: {
        adminId: AuthService.GetAccountInfo().id!,
        albumId: this.album.id!,
        accountIdWillBeUpdates: listString,
        accessLevel: 'ACCESSLEVEL_PLUS',
      },
    });
  }

  selectedStageChange(event: any) {
    this.isAcceptViewPrivate = event?.isAcceptViewPrivate!;
  }

  getCharacterLeft() {
    let numberLeft: number;
    let isFullChar: boolean = false;
    numberLeft = 25 - _.toArray(this.tempName)?.length! > 0 ? 25 - _.toArray(this.tempName).length! : 0
    isFullChar = this.tempName?.length! > 25 ? true : false;
    return { numberLeft, isFullChar };
  }
}
