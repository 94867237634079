import { AuthGuard } from './core/guards/auth.guard';
import { environment } from './../environments/environment';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslationService } from './core/services/translation.service';
import { ApplicationInitializerFactory } from './core/services/translate.config';
import { AuthenticationInterceptor } from './core/interceptor/authentication.interceptor';
import { IlwEnumService } from './core/services/ilw-enum.service';

import { ApiModule } from './core/api/be/api.module';
import { IdentityModule } from './core/api/identity/identity.module';

import { NgxsModule } from '@ngxs/store';
import { ClubStateService } from './core/states/club-state.service';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppEnumStateService } from './core/states/app-enum-state.service';
import { NotificationServicee } from './core/services/notification.service';
import { MessageService } from 'primeng/api';
import { MediaService } from './core/services/media.service';
import { MyFavouriteComponent } from './pages/manage-account/my-favourite/my-favourite.component';
import { EditUserComponent } from './pages/manage-account/edit-user/edit-user.component';
import { InitializeApp } from './core/config-app';
import { Router } from '@angular/router';
import { MetaReaderModule } from './core/api/metareader/meta-reader.module';
import { UploadModule } from './core/api/upload/api.module';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, MyFavouriteComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.API_URL }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient],
      },
    }),
    MetaReaderModule.forRoot({ rootUrl: environment.METAREADER_URL }),
    IdentityModule.forRoot({ rootUrl: environment.IDENTITY_URL }),
    UploadModule.forRoot({ rootUrl: environment.UPLOAD_URL }),
    NgxsModule.forRoot([ClubStateService, AppEnumStateService], {
      developmentMode: !environment.production,
    }),
    environment.production ? [] : NgxsLoggerPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    IlwEnumService,
    NotificationServicee,
    MessageService,
    MediaService,
    EditUserComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeApp,
      deps: [Router],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
