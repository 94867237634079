<p-dialog *ngIf="!isMobile" [(visible)]="showDialog" [modal]="true" [draggable]="false" [resizable]="false"
    (onHide)="onHide()">
    <div style="width: 650px;">
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'MODERATION_TITLE' | translate}}
            </span>
        </div>
        <div class="mt-3">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
        </div>
        <div class="mt-5 mb-3">
            <span class="text-white">{{'CREATOR' | translate}}</span>
            <div class="mt-3 flex align-items-center ml-3">
                <div class="zone-avatar">
                    <img [src]="club!.author!.avatar  ? (club!.author!.avatar | fetchUrlImage ): defaultAvatar"
                        class="fit-size">
                </div>
                <span class="text-white fs-16 ml-2">{{club!.author!.nickName! | limitString:false:30}}</span>
            </div>
        </div>

        <div class="mt-5">
            <div class="mt-3 mb-3 flex align-items-center">
                <span class="text-white mr-3">{{'ADMINS' | translate}}</span>
                <div class="create-club cursor-pointer" *ngIf="currentUser.id! == club.author!.id!"
                    (click)="openPopupPickAdmin = true; selectedInlivers = []; openToPickAdmin = true">
                    <i class="pi pi-plus" style="font-size: 20px; color: white;"></i>
                </div>
            </div>
            <span class="text-disabled">{{'CLUB_ADMIN_TITLE' | translate}}</span>
            <div *ngIf="listAdmins">
                <div class="flex justify-content-between align-items-center ml-3" *ngFor="let admin of listAdmins">
                    <div class="mt-3 flex align-items-center">
                        <div class="zone-avatar">
                            <img [src]="admin.avatar ? (admin.avatar | fetchUrlImage) : defaultAvatar" class="fit-size">
                        </div>
                        <span class="text-white fs-16 ml-2">{{admin.nickName! | limitString:false:30}}</span>
                    </div>
                    <div *ngIf="admin.id !== currentUser.id! && currentUser.id! == club.author!.id!">
                        <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                            (click)="menu.toggle($event)"></i>
                        <p-menu #menu [popup]="true" [model]="initMenu(admin)" styleClass="menucus"
                            [appendTo]="'body'"></p-menu>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="mt-5">
            <div class="mt-3 mb-3 flex align-items-center">
                <span class="text-white mr-3">{{'INLIVERSPLUS' | translate}}</span>
                <div class="create-club cursor-pointer"
                    (click)="openPopupPickInliver = true; selectedInlivers = []; openToPickAdmin = false">
                    <i class="pi pi-plus" style="font-size: 20px; color: white;"></i>
                </div>
            </div>
            <span class="mt-3 text-disabled">{{'CLUB_INLIVERPLUS_TITLE' | translate}}</span>
            <div *ngIf="listAdmins">
                <div class="flex justify-content-between align-items-center ml-3"
                    *ngFor="let inliverplus of listInliverPlus">
                    <div class="mt-3 flex align-items-center">
                        <div class="zone-avatar">
                            <img [src]="inliverplus.avatar ? (inliverplus.avatar | fetchUrlImage): defaultAvatar"
                                class="fit-size">
                        </div>
                        <span class="text-white fs-16 ml-2">{{inliverplus.nickName!}}</span>
                    </div>
                    <div *ngIf="inliverplus.id !== currentUser.id!">
                        <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                            (click)="menu.toggle($event)"></i>
                        <p-menu #menu [popup]="true" [model]="initMenu(inliverplus)" styleClass="menucus"
                            [appendTo]="'body'"></p-menu>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</p-dialog>


<p-sidebar *ngIf="isMobile" [(visible)]="showDialog" [baseZIndex]="1" [modal]="true" position="bottom"
    [draggable]="false" [showCloseIcon]="true" (onHide)="onHide()">
    <div class="card">
        <div class="flex justify-content-center">
            <span class="fs-20 text-white">
                {{'MODERATION_TITLE' | translate}}
            </span>
        </div>
        <div class="mt-3">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
        </div>
        <div class="mt-5 mb-3">
            <span class="text-white">{{'CREATOR' | translate}}</span>
            <div class="mt-3 flex align-items-center ml-3">
                <div class="zone-avatar">
                    <img [src]="club!.author!.avatar ?? defaultAvatar" class="fit-size">
                </div>
                <span class="text-white fs-16 ml-2">{{club!.author!.nickName! | limitString:false:30}}</span>
            </div>
        </div>

        <div class="mt-5">
            <div class="mt-3 mb-3 flex align-items-center">
                <span class="text-white mr-3">{{'ADMINS' | translate}}</span>
                <div class="create-club cursor-pointer" (click)="openToAddAdmin()">
                    <i class="pi pi-plus" style="font-size: 20px; color: white;"></i>
                </div>
            </div>
            <span class="text-disabled">{{'CLUB_ADMIN_TITLE' | translate}}</span>
            <div *ngIf="listAdmins">
                <div class="flex justify-content-between align-items-center ml-3" *ngFor="let admin of listAdmins">
                    <div class="mt-3 flex align-items-center">
                        <div class="zone-avatar">
                            <img [src]="admin.avatar ? (admin.avatar | fetchUrlImage) : defaultAvatar" class="fit-size">
                        </div>
                        <span class="text-white fs-16 ml-2">{{admin.nickName! | limitString:false:30}}</span>
                    </div>
                    <div *ngIf="admin.id !== currentUser.id!">
                        <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                            (click)="menu.toggle($event)"></i>
                        <p-menu #menu [popup]="true" [model]="initMenu(admin)" styleClass="menucus"
                            [appendTo]="'body'"></p-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <div class="mt-3 mb-3 flex align-items-center">
                <span class="text-white mr-3">{{'INLIVERSPLUS' | translate}}</span>
                <div class="create-club cursor-pointer" (click)="openToAddInliver()">
                    <i class="pi pi-plus" style="font-size: 20px; color: white;"></i>
                </div>
            </div>
            <span class="mt-3 text-disabled">{{'CLUB_INLIVERPLUS_TITLE' | translate}}</span>
            <div *ngIf="listAdmins">
                <div class="flex justify-content-between align-items-center ml-3"
                    *ngFor="let inliverplus of listInliverPlus">
                    <div class="mt-3 flex align-items-center">
                        <div class="zone-avatar">
                            <img [src]="inliverplus.avatar ? (inliverplus.avatar | fetchUrlImage): defaultAvatar"
                                class="fit-size">
                        </div>
                        <span class="text-white fs-16 ml-2">{{inliverplus.nickName! | limitString:false:30}}</span>
                    </div>
                    <div *ngIf="inliverplus.id !== currentUser.id!">
                        <i class="pi pi-ellipsis-h mr-4 cursor-pointer" style="font-size: 24px"
                            (click)="menu.toggle($event)"></i>
                        <p-menu #menu [popup]="true" [model]="initMenu(inliverplus)" styleClass="menucus"
                            [appendTo]="'body'"></p-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>

<ng-container *ngIf="openPopupPickAdmin">
    <app-list-inliver [(openPopup)]="openPopupPickAdmin" [listInliver]="listInlivers"
        [listInliverPlus]="listInliverPlus" [(selectedInlivers)]="selectedInlivers"
        [title]="'CLUB_ADD_LIST_ADMIN' | translate"></app-list-inliver>
</ng-container>

<ng-container *ngIf="openPopupPickInliver">
    <app-list-inliver [(openPopup)]="openPopupPickInliver" [listInliver]="listInlivers"
        [(selectedInlivers)]="selectedInlivers" [title]="'CLUB_ADD_LIST_INLIVERPLUS' | translate"></app-list-inliver>
</ng-container>

<ng-container *ngIf="openPopupAddAdminMobile">
    <app-mobile-list-inliver [(openPopup)]="openPopupAddAdminMobile" [listInliverPlus]="listInliverPlus"
        [(selectedInlivers)]="selectedInlivers" [title]="'CLUB_ADD_LIST_ADMIN' | translate"></app-mobile-list-inliver>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'INLIVER'" [nameObject]="this.currentAccount"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()"></app-report>
</ng-container>