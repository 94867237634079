<p-dialog [(visible)]="showDialog" [modal]="true" #modal [draggable]="false" (onHide)="onHide()" [resizable]="false"
    [closable]="false">

    <div class="card" id="card">
        <div class="flex justify-content-center title w-100">
            {{'EDIT_POST' | translate}}
        </div>

        <div class="flex flex-column mt-5 mb-3" style="position: relative;">

            <div class="flex flex-column" style="border: solid;border-radius: 10px;">
                <ng-template #mentionListTemplate let-item="item">
                    <div class="flex align-items-center">
                        <img class="mr-3" [src]="item.img" alt="" width="30" height="30"> &nbsp;
                        <span>{{item.name! | limitString:false:20}}</span>
                    </div>
                </ng-template>

                <textarea id="content"
                    [disabled]="typeToEditPost == 'ONLY_MEDIAS' || typeToEditPost == 'ONLY_AUDIO' || (typeToEditPost == 'UNKNOWN' && !isHasText)"
                    class="textarea-cus w-100" style="resize: none;" [rows]="1" [(ngModel)]="content"
                    (ngModelChange)="detachText()" [mentionConfig]="mentionConfig"
                    [mentionListTemplate]="mentionListTemplate" (itemSelected)="itemSelected($event)"></textarea>

                <div *ngIf="indexMetaLinks >= 0 && !metaDataItem">
                    <div *ngIf="post?.metaLinks?.length! >= 1 && post?.metaLinks![indexMetaLinks].title && !post.isBannedByClub"
                        class="zone-metadata" (click)="gotoUrl()">
                        <div class="zone-image-metadata" *ngIf="post?.metaLinks![indexMetaLinks].image! != ''">
                            <img [src]="post.metaLinks![indexMetaLinks].image" class="fit-size">
                        </div>
                        <div class="zone-content-metadata">
                            <span class="domain">
                                {{post.metaLinks![indexMetaLinks].domain}}
                            </span>
                            <span class="title">
                                {{post.metaLinks![indexMetaLinks].title}}
                            </span>
                        </div>
                    </div>
                </div>


                <div *ngIf="metaDataItem && metaDataItem.title!" class="zone-metadata" (click)="gotoUrl(0)">
                    <div class="zone-image-metadata" *ngIf="metaDataItem.image != ''">
                        <img [src]="metaDataItem.image" class="fit-size">
                    </div>
                    <div class="zone-content-metadata">
                        <span class="domain">
                            {{metaDataItem.domain}}
                        </span>
                        <span class="title">
                            {{metaDataItem.title}}
                        </span>
                    </div>
                </div>

                <div class="flex justify-content-end w-100 tool" *ngIf="!openPopupRecordAudio">
                    <div class="cursor-pointer flex align-items-center">
                        <!-- <img class="mr-3" src="../../../assets/images/svgs/mic.svg" height="16" (click)="startRecord()"
                            *ngIf="typeToEditPost == 'ONLY_AUDIO'"> -->
                        <i class="pi pi pi-image mr-3" (click)="inputFile.click()"
                            *ngIf="typeToEditPost == 'ONLY_MEDIAS' || typeToEditPost == 'TEXT_AND_MEDIAS' || typeToEditPost == 'ONLY_AUDIO' || typeToEditPost == 'TEXT_AND_AUDIO' || typeToEditPost == 'UNKNOWN'"></i>
                        <input style="display: none" type="file" #inputFile multiple (change)="detectFiles($event)">
                        <!-- (mediaPosts.length == 0 || !checkContentAudioMediaPost()) ? inputFile.click() : isValidToAddAssets = false -->
                    </div>
                </div>
            </div>
        </div>

        <div class="text-error fs-14"
            *ngIf="typeToEditPost == 'ONLY_MEDIAS' || typeToEditPost == 'ONLY_AUDIO' || (typeToEditPost == 'UNKNOWN' && !isHasText)">
            {{'IMPOSSIBLE_TO_EDIT_TEXT' | translate}}
        </div>

        <div class="flex list-media" *ngIf="mediaPosts.length! > 0" [id]="post.id">

            <!-- <div class="flex zone-arrow" *ngIf="mediaPosts && mediaPosts.length! > 1">
                <i class="pi pi-arrow-left arrow cursor-pointer position-relative"
                    (click)="scrollTo('listMedia', true)"></i>
                <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo('listMedia', false)"></i>
            </div> -->

            <div class="flex flex-wrap" id="listMedia">
                <div *ngFor="let asset of mediaPosts; let i = index">
                    <div class="m-3" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                        style="position: relative;  width: fit-content;" [id]="asset.id!">
                        <img [src]="getUrl(asset)" style="height: auto; max-width: 120px" alt="">
                        <img class="delete-asset" (click)="deleteAsset(asset.id!, i)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>

                    <div class="m-3" *ngIf="checkTypeMedia(asset) == 'VIDEO' || checkTypeMedia(asset) == 'RECORD'"
                        style="position: relative; width: fit-content;" [id]="asset.id!">

                        <video style="height: 10rem;" [src]="asset?.url!" controls></video>
                        <img class="delete-asset" (click)="deleteAsset(asset.id!, i)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>

                    <div class="m-3" *ngIf="checkTypeMedia(asset) == 'AUDIO'"
                        style="position: relative; width: fit-content;" [id]="asset.id!">

                        <audio style="height: 5rem;" [src]="asset?.url!" controls></audio>
                        <img class="delete-asset" (click)="deleteAsset(asset.id!, i)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>

                    <div style="position: relative;" class="m-3" *ngIf="checkFail(asset)">
                        <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                            [innerHTML]="'UNKNOWING_FILE' | translate">
                        </div>
                        <img class="delete-asset" (click)="deleteAsset(asset.id!, i)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>
                </div>
                <!-- <div *ngIf="checkTypeMedia(post.mediaPosts![0]) == 'AUDIO'">
                    <div class="flex justify-content-center align-items-center media w-100"
                        [ngStyle]="checkTypeMedia(post.mediaPosts![0]) == 'AUDIO' ? {'min-width':'20rem'} : {}"
                        *ngFor="let asset of post.mediaPosts; let i = index">
                        <ng-container *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                            <div class="flex flex-column w-100">
                                <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
                            </div>
                            <i class="pi m-2 pi-times button-close" (click)="deleteAsset(asset.id!, i)"></i>
                        </ng-container>
                    </div>
                </div> -->
            </div>
        </div>

        <ng-container *ngIf="openPopupRecordAudio">
            <app-record-audio-v2 class="mt-2" [(openRecording)]="openPopupRecordAudio" [checkSubmit]="false"
                [width]="'525px'" [widthAudio]="'440px'" [(url)]="urlAudio"
                [(blobFile)]="blobFileAudio"></app-record-audio-v2>
        </ng-container>

        <div class="flex flex-wrap mt-5">
            <div class="zone-media-cus" *ngFor="let url of urls; let i = index">
                <img class="m-3" [src]="url.data" *ngIf="url.format === 'image' && url"
                    style="height: auto; max-width: 120px;">
                <video class="m-3" [src]="url.data" *ngIf="url.format === 'video' && url" style="height: 10rem"
                    controls></video>
                <div class="position-relative" *ngIf="url.format === 'audio'">
                    <audio controls>
                        <source [src]=" url.data" type="audio/wav">
                    </audio>
                    <img class="delete-asset-audio" (click)="deleteNewAsset(i)"
                        src="../../../assets/images/svgs/trash-bg-white.svg">
                </div>
                <div class="position-relative"
                    *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                    <span>{{url.file.name}}</span>
                    <img class="delete-asset-audio" (click)="deleteNewAsset(i)"
                        src="../../../assets/images/svgs/trash-bg-white.svg">
                </div>
                <img class="delete-asset" *ngIf="url.format === 'video' || url.format === 'image'"
                    (click)="deleteNewAsset(i)" src="/assets/images/svgs/trash-bg-white.svg">
            </div>
        </div>
        <div id="zoneAudio"></div>

        <div>
            <div class="text-error flex flex-column align-items-start"
                *ngIf="showErrorDuration || showErrorLimitSizeFile || showErrorMixedFiles">
                {{'LIST_ITEM_REASON' | translate}}

                <div class="text-error" *ngIf="showErrorDuration">
                    {{'DURATION_MAX_4MINS' | translate}}
                </div>

                <div class="text-error" *ngIf="showErrorLimitSizeFile">
                    {{'FILE_SIZE_LIMIT_POST' | translate}}
                </div>

                <div class="text-error" *ngIf="showErrorMixedFiles">
                    {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                </div>

            </div>

            <span class="tex-error" *ngIf="urls.length! + mediaPosts.length! > 4">
                {{'MAX_4_MEDIAS_IN_POST' | translate}}
            </span>
            <span class="tex-error" *ngIf="!isValidToAddAssets">
                {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
            </span>
            <span class="tex-error" *ngIf="!isValidToAddAudio">
                {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
            </span>
            <span class="text-error" *ngIf="isShowRuleEdit">
                {{'RULE_EDIT_POST' | translate}}
            </span>

        </div>

        <div class="flex pt-3 pb-3 justify-content-center">
            <button pButton type="submit" label="{{'CANCEL' | translate}}" (click)="confirmCancelPost = true"
                class="mt-5 mr-2 p-button-outlined p-button-secondary label">
            </button>
            <button pButton type="submit" label="{{'REGISTER' | translate}}" (click)="editPost()"
                class="mt-5 ml-2 p-button-outlined p-button-secondary label">
            </button>
        </div>

    </div>
</p-dialog>

<ng-container *ngIf="confirmCancelPost">
    <p-dialog [(visible)]="confirmCancelPost" [draggable]="false" [resizable]="false">
        <div class="card flex align-items-center flex-column">

            <div class="title">
                {{'POST_UNSAVE_CHANGES_TITLE' | translate}}
            </div>

            {{'POST_UNSAVE_CHANGES_SUBTITLE' | translate}}

            <div class="flex justify-content-center mt-3">
                <button pButton type="submit" label="{{'CANCEL' | translate}}"
                    class="mt-5 p-button-outlined p-button-secondary mr-2" (click)="confirmCancelPost = false"></button>
                <button pButton type="submit" label="{{'ABANDON' | translate}}"
                    class="mt-5 p-button-outlined p-button-secondary ml-2" (click)="okCancel()"></button>
            </div>
        </div>
    </p-dialog>
</ng-container>

<!-- <ng-container *ngIf="notifySuccess">
    <p-dialog [(visible)]="notifySuccess" [draggable]="false" [resizable]="false">
        <div class="card flex align-items-center flex-column">

            <div class="title">
                {{'POST_EDIT_SUCCESS' | translate}}
            </div>

            <div class="flex justify-content-center mt-3">
                <button pButton type="submit" label="{{'CONTINUE' | translate}}"
                    class="mt-5 p-button-outlined p-button-secondary ml-2"
                    (click)="goHome(); notifySuccess = false"></button>
            </div>
        </div>
    </p-dialog>
</ng-container> -->