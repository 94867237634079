import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  STORAGE_URL: '',
  STORAGE_URL_IDENTITY: 'https://storage.identity.dev.ilwith.io',
  BASE_URL: 'https://dev.inlivewith.ui.ilwith.io',
  IDENTITY_URL: 'https://dev.identity.ilwith.io',
  METAREADER_URL: 'https://metareader.ilwith.io',
  API_URL: 'https://dev.inlivewith.api.ilwith.io',
  WEB_PORTAL_URL: 'https://dev.identity.ui.ilwith.io',
  INLIVE_GROUP_URL: 'https://inlive-group.com/',
  EMAIL_API_URL: 'https://email.ilwith.io',
  FIREBASE_API_KEY: 'AIzaSyAf7ghfgA2Eunq_4yRaEzGwSNn-sgLSWBA',
  GOOGLE_API_KEY: 'AIzaSyDtLxBSzPRNpy-u_bALJbNCAcpAlKvky5Y',
  DOMAIN_URI_PREFIX: 'inlivewithappmobile.page.link',
  iosBundleId: 'com.inlivegroup.inlivewith',
  androidPackageName: 'com.inlivegroup.inlivewith',
  STANDARD_URL: '',
  HASH_TAG_URL: '',
  FCM_TEST_URL: '',
  FCM_APP_ID: '',
  SIGNALR_URL: 'https://signalr.ilwith.io',
  VERSION: 'dev - 9.9.0',
  UPLOAD_URL: "https://stag.upload.ilwith.io",
  SERVICE_ID: 'inlivewith-dev'
}; 
