import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { AccountResponseGeneral } from 'src/app/core/api/be/models';
import { FriendRelationService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-friend',
  templateUrl: './my-friend.component.html',
  styleUrls: ['./my-friend.component.scss'],
})
export class MyFriendComponent implements OnInit {
  friends: AccountResponseGeneral[] = [];
  friendsNewest: AccountResponseGeneral[] = [];
  searchText: string = '';

  currentUser = AuthService.GetAccountInfo();
  totalFriend!: number;

  showInviteFriendViaMail: boolean = false;

  constructor(
    private _friendService: FriendRelationService,
    private _messageService: MessageService,
    private _clipboardService: ClipboardService,
    private _translateService: TranslateService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    if (!this._router.url.includes('detail-club')) {
      this.getFriendsList();
    }
  }

  searchFriend() {
    if (this.searchText!.trim()! == '') {
      this.getFriendsList();
    }

    this._friendService
      .inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 1000000,
        nickName: this.searchText
      })
      .pipe(
        tap((res) => {
          this.friends = res.data?.page!;
        })
      )
      .subscribe();
  }

  getFriendsList() {
    if (AuthService.GetAccountInfo()) {
      this._friendService
        .inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json({
          accountRelatingId: AuthService.GetAccountInfo().id!,
          limit: 9,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.friends = res.data?.page!;
              this.totalFriend = res.data?.totalItems!;
            }
          })
        )
        .subscribe();
    }
  }

  copyLink() {
    this._messageService.clear();

    this._messageService.add({
      severity: 'success',
      summary: this._translateService.instant('NOTIFICATION'),
      detail: 'Copied ' + environment.BASE_URL,
      key: 'noti-copy',
      closable: false,
    });

    this._clipboardService.copyFromContent(environment.BASE_URL);
  }

  inviteViaMail() {
    this.showInviteFriendViaMail = true;
  }

  shareWhatsApp() {
    window.open(`https://api.whatsapp.com/send?text=` + environment.BASE_URL);
  }

  openMessenger() {
    this.copyLink();
    setTimeout(() => {
      window.open('https://www.messenger.com/new');
    }, 1000);
  }
}
