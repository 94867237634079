<div class="flex justify-content-center align-items-center h-100 w-100">
    <div class="main">
        <i class="pi m-3 pi-times cursor-pointer text-white" style="z-index: 1;position: absolute;" (click)="close.emit()"></i>
        <video class="h-100" id="videoId"></video>

        <div class="m-3 flex justify-content-end" style="z-index: 1;bottom: 0;position: absolute;">

            <div class="flex align-items-center justify-content-center btn-goinlive" (click)="join.emit()">
                {{ 'GO_INLIVE' | translate}} !
            </div>
        </div>
    </div>
</div>