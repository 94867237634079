<p-dialog *ngIf="!isMobile" [(visible)]="showDialog" [draggable]="false" [modal]="true" [resizable]="false" (onHide)="closePopup()">
  <div class="zone-recorder text-white fs-16">
    <h1>
      {{'RECORD_READY' | translate}}
    </h1>

    <div class="record-icon--wrapper" (click)="onClickRecord()">
      <div class="record-icon--ouline0" [ngClass]="classType"></div>
      <div class="record-icon--ouline1" [ngClass]="classType"></div>
      <div class="record-icon--ouline2" [ngClass]="classType"></div>
      <div class="record-icon--ouline3" [ngClass]="classType">
        <img src="../../../assets/images/svgs/Microphone.svg" alt="Microphone"  />
      </div>
    </div>
    <div *ngIf="recording" class="mt-3">
      <span>{{ minutesLabel }}:</span>
      <span>{{ secondsLabel }}</span>
    </div>
    <div class="clearfix"></div>
    <div class="mt-2 w-100">
      <audio class="w-100" controls="" *ngIf="url">
        <source [src]="sanitize(url)" type="audio/wav" />
      </audio>
    </div>
    <div class="flex justify-content-center mt-2">

      <button pButton type="submit" label="{{'NEXT' | translate}}" *ngIf="url && !recording" (click)="close()"
        class="mt-5 p-button-outlined p-button-secondary"></button>
    </div>
  </div>
</p-dialog>

<p-sidebar *ngIf="isMobile" [autoZIndex]="false" [(visible)]="showDialog" [baseZIndex]="2" [draggable]="false" [modal]="true" [showCloseIcon]="false" [dismissible]="false" position="bottom" (onHide)="closePopup()">
  <div class="zone-recorder text-white fs-16">
    <span>
      {{'RECORD_READY' | translate}}
    </span>

    <div class="record-icon--wrapper" (click)="onClickRecord()">
      <div class="record-icon--ouline0" [ngClass]="classType"></div>
      <div class="record-icon--ouline1" [ngClass]="classType"></div>
      <div class="record-icon--ouline2" [ngClass]="classType"></div>
      <div class="record-icon--ouline3" [ngClass]="classType">
        <img src="../../../assets/images/svgs/Microphone.svg" alt="Microphone"  />
      </div>
    </div>
    <div *ngIf="recording" class="mt-3">
      <span>{{ minutesLabel }}:</span>
      <span>{{ secondsLabel }}</span>
    </div>
    <div class="clearfix"></div>
    <div class="mt-2 w-100">
      <audio class="w-100" controls="" *ngIf="url">
        <source [src]="sanitize(url)" type="audio/wav" />
      </audio>
    </div>
    <div class="flex justify-content-center mt-2">

      <button pButton type="submit" label="{{'NEXT' | translate}}" *ngIf="url && !recording" (click)="close()"
        class="mt-5 p-button-outlined p-button-secondary"></button>
    </div>
  </div>
</p-sidebar>