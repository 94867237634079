<div class="forgot-pass-modal" *ngIf="sendmailSuccess == false">
    <div class="title flex">
        {{'USER_RESET_PASSWORD' | translate}}
    </div>
    <div class="content">
        <div class="text-center text">
            {{'USER_RESET_PASSWORD_TITLE' | translate}}
        </div>
        <form [formGroup]="form" (ngSubmit)="forgotPassword()">
            <div class="pt-5">
                <input formControlName="email" class="w-100" type="text" pInputText placeholder="E-mail"
                    (change)="checkEmail()" />
                <div *ngIf="CheckErrorForm(form, 'email', submited )" class="mt-1">
                    <small *ngIf="CheckErrorForm(form, 'email', submited) && !form.controls.email.errors?.required"
                        class="text-error">
                        {{'EMAIL_VALID' | translate}}
                    </small>
                    <small *ngIf="form.controls.email.errors?.required" class="text-error">
                        {{'EMAIL_REQUIRED' | translate}}
                    </small>
                </div>
                <div *ngIf="emailIsExits == false && submited" class="">
                    <small class="text-error">
                        {{'EMAIL_NOT_LINKED' | translate}}
                    </small>
                </div>
            </div>
            <div class="flex pt-5 justify-content-center">
                <button pButton type="submit" label="{{'SEND' | translate}}" class="mt-5 p-button-outlined p-button-secondary">
                </button>
            </div>
        </form>
    </div>
</div>

<ng-container *ngIf="sendmailSuccess">
    <app-sendmail-success [email]="this.form!.value!.email!" (resendEmail)="sendmailSuccess = false"></app-sendmail-success>
</ng-container>