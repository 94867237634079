import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import {
  AccountGuidInputModel,
  CreateHashtagInputModel,
  Hashtag,
  HashtagResponseModel,
} from 'src/app/core/api/be/models';
import { AccountService, HashtagService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import {
  AppEnumStateService,
  IAppEnumStateModel,
} from 'src/app/core/states/app-enum-state.service';
import { ClubCategoriesEnumE } from '../complete-account/step-two/step-two.component';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-favourite',
  templateUrl: './my-favourite.component.html',
  styleUrls: ['./my-favourite.component.scss'],
})
export class MyFavouriteComponent implements OnInit, AfterViewChecked {
  listHashtag: string[] = [];

  listCategory!: Observable<ClubCategoriesEnumE[]>;
  listBackgroundCategory = [
    {
      posterImage: '../../../../../assets/images/category-music.png',
      value: 'MUSIC',
    },
    {
      posterImage: '../../../../../assets/images/category-humor.png',
      value: 'HUMOR',
    },
    {
      posterImage: '../../../../../assets/images/category-dance.png',
      value: 'DANCE',
    },
    {
      posterImage: '../../../../../assets/images/category-media.png',
      value: 'MEDIA',
    },
  ];

  hashtags: HashtagResponseModel[] = [];
  isChecked: boolean = false;
  hashtagSelecteds: any[] = [];
  newHashtagSelecteds: any[] = [];

  categorySelected!: string;
  searchText!: string;

  @Select(AppEnumStateService) _appEnum$!: Observable<IAppEnumStateModel>;
  category$!: Observable<ClubCategoriesEnumE[]>;
  hashtagByCategory!: HashtagResponseModel[];
  textErrorMax5: boolean = false;

  constructor(
    private _accountService: AccountService,
    private _hashtagService: HashtagService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) {
    this.category$ = this._appEnum$.pipe(
      map((res) => {
        console.log(res.appEnum.clubCategories);

        return Object.assign(res.appEnum.clubCategories || []);
      })
    );

    this.listCategory = this.category$;
  }

  ngOnInit(): void {
    this.getHashtagsFromAccount();
    this.getAllHashtags();
  }

  ngAfterViewChecked(): void {
    this.hashtagSelecteds.map((x) => this.setStyleHashtag(x, true));
  }

  getPosterByCategory(categoryName: string) {
    return this.listBackgroundCategory.filter(
      (l) => l.value == categoryName
    )[0]!;
  }

  selectHashtag(item: any) {
    let clicked;
    let temp = this.hashtagSelecteds.filter((h) => h.name == item.name);

    if (temp.length > 0) {
      clicked = false;
      this.hashtagSelecteds = this.hashtagSelecteds.filter(
        (h) => h.name !== item.name
      );
    } else {
      this.hashtagSelecteds.push(item);
      clicked = true;
    }

    this.setStyleHashtag(item, clicked);
  }

  setStyleHashtag(item: any, clicked: any) {
    let a = document.getElementById(item?.name!) as HTMLElement;

    if (!a) {
      return;
    }

    if (clicked) {
      a.style.backgroundColor = 'white';
      a.style.color = 'black';
    } else {
      a.style.backgroundColor = 'transparent';
      a.style.color = 'white';
    }
  }

  checkDisable(item: any): boolean {
    // var res: any = [];
    // this.hashtagSelecteds.forEach(function (v, i) {
    //   res[v.category] = (res[v.category] || 0) + 1;
    // });

    return (
      this.hashtagSelecteds.length >= 9 &&
      this.hashtagSelecteds.findIndex((x) => x.name == item.name) < 0
    );
  }

  getAllHashtags() {
    LoadingService.$(true);
    this._hashtagService
      .inlivewithHashtagsPageGet$Json({ page: 1, limit: 10000 })
      .subscribe((res) => {
        if (res.res == 0) {
          res.data?.page!.map((y) => this.hashtags.push(y));
          LoadingService.$(false);
          this.selectCategory('MUSIC');
        }
      });
  }

  getHashtagByCategory(name: string) {
    let listHashtags: Hashtag[] = [];
    this.hashtags = this.hashtags.filter((x) => x.category === name);
    return listHashtags;
  }

  suivant() {
    const body: AccountGuidInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      values: this.hashtagSelecteds.filter((h) => h?.id!).map((h) => h?.id!),
    };

    LoadingService.$(true);
    this._accountService
      .inlivewithAccountHashtagsAccountIdPut$Json({
        accountId: body?.accountId!,
        body,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            LoadingService.$(false);
          }
        }),
        mergeMap(() =>
          this.newHashtagSelecteds.length > 0
            ? this.createAndAddHashTag()
            : of(null)
        )
      )
      .subscribe(
        (res) => {
          this.showNotification(
            this._translateService.instant('CHANGE_INFOR_CLUB')
          );
          window.location.reload();
        },
        () => {}
      );
  }

  search() {
    let h = this.hashtags.filter((x) => x.category === this.categorySelected);
    this.hashtagByCategory = h.filter((h) =>
      h.name?.toLowerCase()?.includes(this.searchText!.toLowerCase())
    );
  }

  selectCategory(name: string) {
    this.categorySelected = name;
    this.hashtagByCategory = this.hashtags.filter((x) => x.category === name);
  }

  deleteHashtag(h?: Hashtag) {
    // if (this.hashtagSelecteds.length <= 5) {
    //   this.textErrorMax5 = false;
    // }

    this.hashtagSelecteds = this.hashtagSelecteds.filter(
      (x) => x?.normalizedName != h?.normalizedName
    );

    // this.selectHashtag(h)
    this.setStyleHashtag(h, false);
  }

  enterHashtag(event?: KeyboardEvent) {
    if (event && event.code == 'Enter' && this.searchText != '') {
      if (this.hashtagSelecteds.length >= 5) {
        this.textErrorMax5 = true;
        this.searchText = '';
        this.selectCategory(this.categorySelected);
        return;
      }

      if (
        this.hashtagSelecteds.some((x) =>
          x.name!.toLowerCase().includes(this.searchText.toLowerCase())
        )
      ) {
        this.searchText = '';
        this.selectCategory(this.categorySelected);
        return;
      }

      if (
        this.hashtagByCategory.some((x) =>
          x.name!.toLowerCase().includes('#' + this.searchText.toLowerCase())
        )
      ) {
        let item = this.hashtagByCategory.filter(
          (x) => x.name?.toLowerCase() === '#' + this.searchText.toLowerCase()
        )[0];
        this.setStyleHashtag(item, true);
        this.hashtagSelecteds.push(item);
      } else {
        let temp = {
          category: this.categorySelected,
          name: this.searchText,
        };
        this.hashtagSelecteds.push(temp);
        this.newHashtagSelecteds.push(temp);
      }

      if (this.hashtagSelecteds.length >= 5) {
        this.textErrorMax5 = true;
        return;
      }

      this.searchText = '';
      this.selectCategory(this.categorySelected);
    }
  }

  createAndAddHashTag() {
    let body: CreateHashtagInputModel[] = [];
    this.newHashtagSelecteds.map((h) =>
      body.push({
        category: h?.category!,
        name: h?.name!.toString().replaceAll('#', ''),
      })
    );

    return this._hashtagService
      .inlivewithHashtagCreatePost$Json({ body })
      .pipe(mergeMap((res) => this.addHashtag(res.data!)));
  }

  addHashtag(hashtags: HashtagResponseModel[]) {
    const body: AccountGuidInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      values: hashtags.filter((h) => h?.id!).map((h) => h?.id!),
    };

    return this._accountService.inlivewithAccountHashtagsAccountIdPost$Json({
      accountId: body?.accountId!,
      body,
    });
  }

  getHashtagsFromAccount() {
    this._accountService
      .inlivewithAccountHashtagsAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
        limit: 12,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.hashtagSelecteds = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  showNotification(subtitle: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this._messageService.add({
          severity: 'success',
          summary: '',
          detail: subtitle,
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => window.location.reload());
  }
}
