<ng-container>
    <div class="card cursor-pointer">
        <div class="icon-club" *ngIf="live && live!.clubs!.length! == 1">
            <img class="w-100 h-100" [src]="getIcon()">
        </div>

        <div class="status m-3">
            {{'LIVE' | translate}}
        </div>
        <div class="zone-avt cursor-pointer">
            <img *ngIf="live!.isFromCelebrity" [src]="live!.author?.avatar! | fetchUrlImage" class="fit-size" (click)="checkGoInlive()">
            <img *ngIf="!live!.isFromCelebrity" [src]="live!.clubs![0]!.avatar! | fetchUrlImage" class="fit-size"
                (click)="checkGoInlive()">
        </div>
    </div>

    <div class="info mt-2">
        <div class="club-name flex align-items-center">
            <ng-container *ngIf="!live!.isFromCelebrity">
                <div class="text-name">
                    {{live!.clubs![0]!.name!}}
                </div>
            </ng-container>
            <ng-container *ngIf="live!.isFromCelebrity">
                <div class="flex align-items-center">
                    <div class="text-name">
                        {{live!.author?.nickName! | limitString:false:40}}
                    </div>
                    <img class="ml-2 fit-size" src="../../../../assets/images/svgs/celeb-post.svg">
                </div>
            </ng-container>
            <i *ngIf="!live!.isFromCelebrity && live!.clubs![0]!.isPrivate!"
                [ngClass]="live!.clubs![0]!.isPrivate! ? 'pi pi-lock ml-2' : ''"></i>
            <img class="ml-2" src="../../../../assets/images/svgs/unlock-goinlive.svg"
                *ngIf="!live!.isFromCelebrity && !live!.clubs![0]!.isPrivate!">

        </div>

        <div class="flex text mt-1" *ngIf="!live!.isFromCelebrity">
            <i class="flex half-icon-heart mr-1" *ngIf="live!.clubs![0]!.type! == 'CLUB_TYPE_FAN'"></i>
            <i class="flex half-icon-star mr-1" *ngIf="live!.clubs![0]!.type! == 'CLUB_TYPE_TALENT'"></i>
            {{live!.clubs![0]!.type! | translate}}
        </div>

        <div class="mt-1 text fs-12" *ngIf="!live!.isFromCelebrity">
            {{live!.clubs![0]!.totalMember!}} {{(live!.clubs![0]!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') |
            translate}}
        </div>
        <div class="mt-1 text fs-12" *ngIf="live!.isFromCelebrity">
            {{live!.clubs!.length!}} {{(live!.clubs!.length! > 1 ? 'CLUBS' : 'CLUB')}}
        </div>
    </div>
</ng-container>


<ng-container *ngIf="isShowReviewCamera">
    <p-sidebar [(visible)]="isShowReviewCamera" position="bottom" [fullScreen]="true" [showCloseIcon]="false"
        [dismissible]="true">
        <app-review-camera (close)="isShowReviewCamera = false" (join)="join()"></app-review-camera>
    </p-sidebar>
</ng-container>

<ng-container *ngIf="showListClubs">
    <app-list-clubs-celeb-post [clubs]="live.clubs!" [(showListClubsPosted)]="showListClubs" [fromGoInliveCeleb]="true"
        [live]="live"></app-list-clubs-celeb-post>
</ng-container>