<div class="post-zone" *ngIf="post">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
        <div class="flex align-items-center">
            <div class="zone-avatar" *ngIf="!_router.url.startsWith('/home')">
                <img [src]="post.author?.avatar! && !post.isBannedByClub ? post.author?.avatar : defaultAvatar"
                    class="fit-size cursor-pointer">
            </div>
            <div class="zone-avatar" *ngIf="_router.url.startsWith('/home')">
                <img [src]="(getUrl(post.club?.avatar!))" class="fit-size cursor-pointer">
            </div>
            <div class="flex flex-column ml-2">

                <div class="text mt-1 flex align-items-center" *ngIf="_router.url.startsWith('/home')">
                    <div class="mr-2 name-club cursor-pointer" (click)="openClubDetail()">{{post.club?.name!}}</div>
                    <i class="half-icon-heart-white ml-1" style="margin-left: 0.25rem"
                        *ngIf="post.club!.type! == 'CLUB_TYPE_FAN'"></i>
                    <i class="half-icon-star-white ml-1" style="margin-left: 0.25rem"
                        *ngIf="post.club!.type! == 'CLUB_TYPE_TALENT'"></i>
                </div>

                <div class="flex align-items-end">
                    <div class="mr-2 name-author cursor-pointer"
                        *ngIf="_router.url.startsWith('/home') || _router.url.startsWith('/mobile')"
                        (click)="openAccountInfo()">{{post.author?.nickName! | limitString:false:40}} </div>
                    <div class="mr-2 cursor-pointer" style="font-weight: bold; font-size: 16px; color: #FFF;"
                        *ngIf="!_router.url.startsWith('/home') && !_router.url.startsWith('/mobile')"
                        (click)="openAccountInfo()">{{!post.isBannedByClub! ? (post.author?.nickName! | limitString:false:40) :
                        ('INLIVER_UNKNOWN' | translate)}} </div>
                    <div class="mt-1 fs-14" *ngIf="_router.url.startsWith('/home')">
                        <div class="label-role" style="color: #959595;">
                            {{getRoleOfPostAuthor() | translate }}
                        </div>
                        <div class="label-role" *ngIf="post.club?.roleOfPostAuthor! == 'ACCESSLEVEL_CELEBRITY'">
                            {{'CELEBRITY' | translate }}
                        </div>
                    </div>
                </div>

                <div class="mt-1 fs-14" *ngIf="!_router.url.startsWith('/home')">
                    <div style="color: #959595;">
                        {{getRoleOfPostAuthor() | translate }}
                    </div>
                    <div style="color: #959595;" *ngIf="post.club?.roleOfPostAuthor! == 'ACCESSLEVEL_CELEBRITY'">
                        {{'CELEBRITY' | translate }}
                    </div>
                </div>
                <!-- <span *ngIf="!_router.url.startsWith('/mobile')"
                    class="text-disabled fs-11 mt-2">{{timeAgo(post.dateCreated!)}}</span> -->
            </div>
        </div>
        <div *ngIf="!post.isBannedByClub!">
            <i class="pi pi-ellipsis-h cursor-pointer" (click)="menu.toggle($event); renderLinkForCopy()"
                style="font-size: 24px"></i>
            <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" styleClass="menu-post"></p-menu>
        </div>
    </div>

    <div class="zone-voir-club" *ngIf="_router.url.startsWith('/home')" (click)="openClubDetail()">
        <div class="align-items-end flex ml-2">
            <img src="../../../../assets/images/svgs/club_icon.svg">
            <div class="ml-2 fs-14">
                {{'VIEW_CLUB' | translate}}
            </div>
        </div>
        <div>
            <i class="pi pi-angle-right fs-24 mr-1"></i>
        </div>
    </div>

    <div class="flex align-items-center mt-3">
        <img class="mr-2" *ngIf="!_router.url.startsWith('/home') && post.isPinPost" src="../../../../assets/images/svgs/pin-post.svg">
        <div class="flex align-items-center lb-discussion">
            {{'DISCUSSION' | translate}}
        </div>

        <!-- <div class="flex align-items-center stage ml-2" *ngIf="_router.url.startsWith('/home')">
            {{post.stage?.name!}}
        </div> -->
    </div>
    <div class="mt-3 mb-2 flex justify-content-between align-items-center">
        <div class="title" *ngIf="!post.isBannedByClub" [innerHTML]="urlify(post.title)"></div>
        <div class="fs-14" *ngIf="post.isBannedByClub" [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
        <div class="time" *ngIf="duration && post.isDiscussing && !post.isBannedByClub; else timeEndDiscuss">
            <i class="pi pi-clock"></i>
            <span> {{getNumberWithZero(duration.hours()) + ":" + getNumberWithZero(duration.minutes()) + ":" +
                getNumberWithZero(duration.seconds())}} </span>
        </div>
        <ng-template #timeEndDiscuss>
            <div class="time-end">{{post.dateEnd | date:'dd/MM/yy'}} </div>
        </ng-template>
    </div>
    <!-- <div class="mt-2 mb-2" [class.show]="show">
        <div id="content" class="show overflow-wrap-breakword" style="white-space: break-spaces;"
            [innerHTML]="urlify(post!.message! | limitString:completeText)"></div>
    </div>
    <div class="text-right cursor-pointer" *ngIf="post?.message! && post?.message!.length! > 400">
        <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div> -->

    <div class="flex justify-content-center w-100 mb-3"
        *ngIf="!_router.url.startsWith('/mobile') && !post.isBannedByClub">
        <app-layout-media-post [id]="post!.id!" [post]="post" [isJoined]="isJoined" [isAdmin]="isAdmin"
            [listMember]="listMember">
        </app-layout-media-post>
    </div>

    <div class="flex justify-content-center w-100 mt-3"
        *ngIf="_router.url.startsWith('/mobile') && !post.isBannedByClub">
        <app-layout-media-post-mobile [id]="post!.id!" [post]="post" [isJoined]="isJoined" [isAdmin]="isAdmin">
        </app-layout-media-post-mobile>
    </div>

    <div class="list-comment-last mt-1"
        *ngIf="post.commentPosts && post.commentPosts.length > 0 && !post.isBannedByClub">
        <div class="comment-item" *ngFor="let item of post.commentPosts.reverse(); let i = index" [class.mt-2]="i == 1">
            <div class="avatar">
                <img [src]="item.author?.avatar && !item.isBannedByClub ? item.author?.avatar : defaultAvatar" alt=""
                    class="fit-size">
            </div>
            <div class="detail w-100">
                <div class="name" [ngStyle]="{color: findColor(item.author?.id!)}">
                    {{(item.author?.nickName! | limitString:false:40) ?? ('INLIVER_UNKNOWN' | translate)}}
                </div>
                <div class="content w-100" *ngIf="!item.isBannedByClub"
                    [innerHTML]="urlify((item?.message!) | limitString:completeText)"></div>

                <div class="content w-100" *ngIf="item.isBannedByClub"
                    [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>

                <div class="w-100 flex flex-wrap" *ngIf="item.mediaCommentPosts!.length! > 0 && !item.isBannedByClub">
                    <ng-container *ngFor="let asset of item.mediaCommentPosts">
                        <div class="w-100 mt-2"
                            *ngIf="checkTypeMedia(asset) == 'AUDIO' && checkTypeMedia(asset) !== 'UNKNOWN'">
                            <app-wave-form-audio [assetUrl]="asset.url!"></app-wave-form-audio>
                            <div class="flex justify-content-center align-items-center m-2"
                                *ngIf="checkTypeMedia(asset) == 'UNKNOWN'" style="height: 50px">
                                <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="w-100 flex flex-wrap" *ngIf="item.mediaCommentPosts!.length! > 0 && !item.isBannedByClub">
                    <ng-container *ngFor="let asset of item.mediaCommentPosts; let i = index">
                        <ng-container *ngIf="!checkProcessMediaFail(asset)">
                            <div class="media"
                                *ngIf="checkTypeMedia(asset) !== 'UNKNOWN' && checkTypeMedia(asset) !== 'AUDIO'">
                                <div class="w-100 h-100 cursor-pointer" *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                    <img class="fit-size" (click)="showMultiMedia(item?.mediaCommentPosts!, i)"
                                        [src]="asset.url!.replace('/file.', '/thumb.')">
                                </div>
                                <div class="w-100 h-100 cursor-pointer position-relative"
                                    *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                    <!-- <video [src]="asset?.url!" (click)="showMultiMedia(item?.mediaCommentPosts!, i)"
                                        controls></video> -->
                                    <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                        [poster]="getUrl(asset!.url!)"
                                        (dblclick)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                    </app-video-layer>
                                </div>
                                <div class="w-100 h-100 cursor-pointer"
                                    *ngIf="checkTypeMedia(asset) == 'AUDIO' && checkTypeMedia(asset) !== 'UNKNOWN'">

                                    <app-wave-form-audio [assetUrl]="asset.url!"></app-wave-form-audio>
                                </div>
                                <div class="flex justify-content-center align-items-center m-2"
                                    *ngIf="checkTypeMedia(asset) == 'UNKNOWN'" style="height: 50px">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="checkProcessMediaFail(asset)">
                            <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="footer" *ngIf="!post.isBannedByClub">
        <div class="button">
            <button (click)="openCommentForPost()">
                {{ (post.isDiscussing ? 'JOIN_DISCUSSION' : 'SEE_DISCUSSION') | translate }}
            </button>
        </div>
        <!-- <div class="like" *ngIf="post.isCommented">
            <i class="pi pi-heart-fill" [class.zoom-in-zoom-out]="post.isReacted" (click)="likePost()"
                [ngClass]="{'liked' : post!.isReacted!}"></i>
            <ng-container *ngIf="!isAdmin; else numberLike">
                <ng-container *ngIf="post.isReacted">
                    {{'I_LIKED' | translate}}
                </ng-container>
            </ng-container>
            <ng-template #numberLike>
                {{post.totalReact ?? 0}}
            </ng-template>
        </div> -->
        <!-- <div class="status-and-total" *ngIf="post.isCommented">
            <div class="status">
                <div *ngIf="post.isDiscussing; else enddiscuss" class="discussing">
                    {{ 'IN_DISCUSSION' | translate }}
                </div>

                <ng-template #enddiscuss>
                    <div class="time-end-discussion">
                        00:00:00
                    </div>
                </ng-template>
            </div>
            <div class="total" *ngIf="post.isDiscussing && post.totalMemberDiscussion !== 0">
                <div class="total-number" *ngIf="post.totalMemberDiscussion && post.totalMemberDiscussion > 2">
                    +{{post.totalMemberDiscussion - 2}}
                </div>
                <ng-container *ngIf="post.urls">
                    <div *ngFor="let item of post.urls; let i = index" class="avatar"
                        [ngStyle]="{'left' : i == 0 && post.urls.length == 2 ? '20px' : '0'}">
                        <img [src]="item" class="fit-size" alt="">
                    </div>
                </ng-container>
            </div>
        </div> -->
    </div>
</div>

<p-dialog [(visible)]="openPopupEditTitle" [resizable]="false" [draggable]="false">
    <div class="card flex flex-column" style="width: 600px;">
        <div class="title text-center">
            {{'EDIT_TITLE_DISCUSSION' | translate}}
        </div>
        <div class="fs-16 text-white text-left mt-5">
            {{'POST_CREATE_TITLE_LABEL' | translate}}
        </div>
        <div class="flex flex-column" style="position: relative;">
            <input class="input-custom w-100" type="text" id="title"
                [placeholder]="'POST_PLACEHOLDER_TITLE' | translate" [(ngModel)]="tempTitleEdit">
            <span class="text-regular"
                style="position: absolute; font-size: 14px; color: rgba(255, 255, 255, 0.3); right: 0; bottom: 10px;">
                {{40 - tempTitleEdit.trim().length! > 0 ? 40 - tempTitleEdit.trim().length! : 0}}
            </span>
        </div>
        <div *ngIf="tempTitleEdit.length > 40 || tempTitleEdit.length == 0" class="mt-2">
            <span class="text-error">
                {{'LIMIT_TEXT_TITLE_DISCUSSION' | translate}}
            </span>
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary mr-2" (click)="openPopupEditTitle = false"></button>
            <button pButton type="submit" [disabled]="tempTitleEdit.length > 40 || tempTitleEdit.length == 0" id="black"
                label="{{'REGISTER' | translate}}"
                class="mt-5 p-button-outlined bg-white text-black p-button-secondary ml-2"
                (click)="editTitleDiscussion()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showConfirmDeletePost" [resizable]="false" [draggable]="false">
    <div class="card flex align-items-center flex-column">
        <div class="title">
            {{'DELETE_DISCUSSION' | translate}}
        </div>
        <div class="subtitle text-white w-80 mt-3">
            {{'SUBTITLE_DELETE_DISCUSSION' | translate}}
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary mr-2" (click)="showConfirmDeletePost = false"></button>
            <button pButton type="submit" label="{{'DELETE' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary ml-2" (click)="deletePost()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showConfirmEndDiscussion" [resizable]="false" [draggable]="false">
    <div class="card flex align-items-center flex-column">
        <div class="title">
            {{'TITLE_CLOSE_DISCUSSION' | translate}}
        </div>
        <div class="subtitle text-white w-80 mt-3">
            {{'SUBTITLE_CLOSE_DISCUSSION' | translate}}
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary mr-2"
                (click)="showConfirmEndDiscussion = false"></button>
            <button pButton type="submit" label="{{'CLOSE' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary ml-2"
                (click)="endDiscussion(); closeByManual = true; showConfirmEndDiscussion = false"></button>
        </div>
    </div>
</p-dialog>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'POST'" [nameObject]="this.post"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()">
    </app-report>
</ng-container>

<ng-container *ngIf="isShowMultiMedia">
    <app-show-multi-media [(openShowMutilMedia)]="isShowMultiMedia" [(urls)]="multiMedias"
        [(activeIndex)]="activeIndex"></app-show-multi-media>
</ng-container>