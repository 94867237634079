<div class="card">
    <ng-container *ngIf="!resendMail">
        <div class="title">
            {{'INSCRIPTION' | translate}}
        </div>
        <div class="content" [innerHTML]="'SEND_MAIL_NOTIFY' | translate:{value: emailCheck}">
        </div>
        
        <div class="text cursor-pointer" style="text-decoration: underline;" (click)="resendMail = true">
            {{'SEND_MAIL_FAILED' | translate}}
        </div>

        <div class="flex pt-5 justify-content-center">
            <button pButton label="{{'LOGIN' | translate}}" (click)="login.emit()" class="mt-5 p-button-outlined p-button-secondary">
            </button>
        </div>
        
    </ng-container>

    <ng-container *ngIf="resendMail">
        <div class="title">
            {{'INSCRIPTION' | translate}}
        </div>
        <form [formGroup]="form" (ngSubmit)="ReSendConfirmEmail()">
            <div class="content mb-3">
                <div class="mb-5" [innerHTML]="'RESEND_MAIL_NOTIFY' | translate"></div>
                <input formControlName="email" class="w-100 mt-3" type="text" pInputText placeholder="E-mail" />
                <div *ngIf="CheckErrorForm(form, 'email', submited )" class="mt-1">
                    <small *ngIf="CheckErrorForm(form, 'email', submited) && !form.controls.email.errors?.required"
                        class="text-error">
                        {{'EMAIL_VALID' | translate}}
                    </small>
                    <small *ngIf="form.controls.email.errors?.required" class="text-error">
                        {{'EMAIL_REQUIRED' | translate}}
                    </small>
                </div>
            </div>
            <div class="flex pt-5 justify-content-center">
                <button pButton label="{{'NEXT' | translate}}" class="mt-5 p-button-outlined p-button-secondary">
                </button>
            </div>
        </form>
    </ng-container>
</div>