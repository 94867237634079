import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/api/be/services';
import { CheckErrorForm, EMAIL_REGEX } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as Identity from 'src/app/core/api/identity/services';
import { LoginService } from 'src/app/core/services/login.service';
import { RegisterService } from 'src/app/core/services/register.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() showDialog!: boolean;
  @Output() showDialogChange = new EventEmitter();
  @Output() isLoggedIn = new EventEmitter();

  showDialogForgotPassword: boolean = false;

  isLoginSuccess!: boolean;
  emailIsExits!: boolean;

  form: UntypedFormGroup = new UntypedFormGroup({});
  CheckErrorForm = CheckErrorForm;
  submited: boolean = false;

  email!: string;
  password!: string;
  fieldTextType: boolean = false;
  inputType: string = 'password';

  constructor(private fb: UntypedFormBuilder, private _accountService: AccountService, private _router: Router, private _accountServiceIdentity: Identity.AccountService) {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ["", [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType == true) {
      this.inputType = 'text'
    } else {
      this.inputType = 'password'
    }
  }

  onHide() {
    LoadingService.$(false);
    this.showDialogChange.emit(this.showDialog);
  }

  login() {
    this.submited = true;

    if (
      this.form.controls.email.errors ||
      this.form.controls.password.errors
    ) {
      return;
    }

    let body = {
      email: this.form.value.email,
      password: this.form.value.password
    }

    LoadingService.$(true);

    this._accountServiceIdentity.identityAccountLoginPost$Json({ body })
      .pipe(
        tap(res => {
          if (res?.res! < 0) {
            this.isLoginSuccess = false;
            this.submited = false;
          } else {
            LocalStorageService.AddLocalStorage('access_token', res?.accessToken!);
            LocalStorageService.AddLocalStorage('email', res?.data?.email!);
            this.isLoggedIn.emit();
            // this.getAccountInfo();
          }

        }),
        mergeMap((res) => this.getAccountInfo(res.data?.id!)),
        catchError((err) => of(LoadingService.$(false)))
      )
      .subscribe(res => {
        if (res?.res == 0) {
          AuthService.SetAccountInfo(res?.data!)
          this.showDialog = false;

          setTimeout(() => {
            location.reload();
          }, 700)
        }
        else {
          this.isLoginSuccess = false;
          this.submited = false;
        }
        LoadingService.$(false);
      }, () => { });

  }

  register() {
    LoginService.$(false);
    RegisterService.$(true);
  }

  showDialogForgotPass() {
    this.form.reset();
  }

  getAccountInfo(identityUserId: string) {
    return this._accountService.inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json({ identityUserId })
  }
}
