import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inliver-skeleton',
  templateUrl: './inliver-skeleton.component.html',
  styleUrls: ['./inliver-skeleton.component.scss']
})
export class InliverSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
