import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { forkJoin, from, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AccountResponseGeneral } from 'src/app/core/api/be/models';
import {
  AccountService,
  FriendRelationService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-account',
  templateUrl: './parameters-account.component.html',
  styleUrls: ['./parameters-account.component.scss'],
})
export class ParametersAccountComponent implements OnInit {
  @Input() currentSetting: number = 0;

  isPublicationTurnOn!: boolean;
  isIdentificationTurnOn!: boolean;
  isMessageTurnOn!: boolean;
  isAddFriendRequestTurnOn!: boolean;
  isAdvertisementTurnOn!: boolean;

  isValidPassword!: boolean;
  fieldTextType: boolean = false;
  fieldTextTypeForConfirm: boolean = false;
  fieldTextTypeOldPassword: boolean = false;

  isPublicAccount!: boolean;
  isAcceptRecieveMessageAnyone!: boolean;

  oldPassword!: string;
  password!: string;
  confirmPassword!: string;
  inputType: string = 'password';
  inputTypeForConfirm: string = 'password';
  inputTypeForOldPassword: string = 'password';

  openListBlock: boolean = false;
  listAccountsBlocked: AccountResponseGeneral[] = [];

  defaultAvatar = defaultAvatar;
  currentUserIsChoosing!: AccountResponseGeneral;
  openConfirmUnBlockPopup: boolean = false;

  constructor(
    public _router: Router,
    private _accountService: AccountService,
    private _friendService: FriendRelationService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.isPublicAccount = !AuthService.GetAccountInfo().isPrivate!;
    this.isAcceptRecieveMessageAnyone =
      AuthService.GetAccountInfo().isEnableConversation!;
    this.fetchSettingsUser().subscribe();
    this.getListBlockedAccounts();
  }

  // toggleFieldTextType() {
  //   this.fieldTextType = !this.fieldTextType;
  //   if (this.fieldTextType == true) {
  //     this.inputType = 'text';
  //   } else {
  //     this.inputType = 'password';
  //   }
  // }

  // toggleFieldTextTypeConfirm() {
  //   this.fieldTextTypeForConfirm = !this.fieldTextTypeForConfirm;
  //   if (this.fieldTextTypeForConfirm == true) {
  //     this.inputTypeForConfirm = 'text';
  //   } else {
  //     this.inputTypeForConfirm = 'password';
  //   }
  // }

  // toggleFieldTextTypeOldPassword() {
  //   this.fieldTextTypeOldPassword = !this.fieldTextTypeOldPassword;
  //   if (this.fieldTextTypeOldPassword == true) {
  //     this.inputTypeForOldPassword = 'text';
  //   } else {
  //     this.inputTypeForOldPassword = 'password';
  //   }
  // }

  // checkPassWord() {
  //   this.CheckLeastOneLowerCase();
  //   this.CheckLeastOneNumber();
  //   this.CheckLeastOneUpperCase();
  //   this.CheckLength();

  //   let list = [
  //     this.CheckLeastOneLowerCase(),
  //     this.CheckLeastOneNumber(),
  //     this.CheckLeastOneUpperCase(),
  //     this.CheckLength(),
  //   ];
  //   this.isValidPassword =
  //     _.union(list).length == 1 &&
  //     _.union(list)[0] == true &&
  //     this.password === this.confirmPassword;
  //   console.log(this.isValidPassword);
  // }

  // CheckLeastOneLowerCase() {
  //   const PWD_REGEX = new RegExp(/^(?=.*[a-z]).*$/);
  //   const password: string = this.password!;
  //   const result = password?.match(PWD_REGEX);
  //   return result != null;
  // }

  // CheckLeastOneUpperCase() {
  //   const PWD_REGEX = new RegExp(/^(?=.*[A-Z]).*$/);
  //   const password: string = this.password!;
  //   const result = password?.match(PWD_REGEX);
  //   return result != null;
  // }

  // CheckLeastOneNumber() {
  //   const PWD_REGEX = new RegExp(/^(?=.*[0-9]).*$/);
  //   const password: string = this.password!;
  //   const result = password?.match(PWD_REGEX);
  //   return result != null;
  // }

  // CheckLeastOneSpecialCharacter() {
  //   const PWD_REGEX = new RegExp(/^(?=.*?[!@#\$&*~]).*$/);
  //   const password: string = this.password!;
  //   const result = password?.match(PWD_REGEX);
  //   return result != null;
  // }

  // CheckLength() {
  //   const password: string = this.password!;
  //   return password?.length! >= 8;
  // }

  fetchSettingsUser() {
    return this._accountService
      .inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.isPublicationTurnOn = res.data?.isPublicationTurnOn!;
            this.isIdentificationTurnOn = res.data?.isIdentificationTurnOn!;
            this.isMessageTurnOn = res.data?.isMessageTurnOn!;
            this.isAddFriendRequestTurnOn =
              res?.data?.isAddFriendRequestTurnOn!;
            this.isAdvertisementTurnOn = res?.data?.isAdvertisementTurnOn!;
          }
        })
      );
  }

  applyChangesSettings() {
    return this._accountService
      .inlivewithAccountSettingAccountNotificationPost$Json({
        body: {
          accountId: AuthService.GetAccountInfo().id!,
          isAddFriendRequestTurnOn: this.isAddFriendRequestTurnOn,
          isAdvertisementTurnOn: this.isAdvertisementTurnOn,
          isIdentificationTurnOn: this.isIdentificationTurnOn,
          isMessageTurnOn: this.isMessageTurnOn,
          isPublicationTurnOn: this.isPublicationTurnOn,
        },
      })
      .pipe(mergeMap((res) => this.updateAccount()))
      .subscribe(() => {
        this.showNotification(
          this._translateService.instant('CHANGE_INFOR_CLUB')
        );
      });
  }

  applyChanges() {
    from([0])
      .pipe(
        mergeMap(() =>
          this.isPublicAccount
            ? this.setAccountPublic()
            : this.setAccountPrivate()
        ),
        mergeMap(() => this.setAccountIsAcceptRecieveMessageAnyone()),
        mergeMap(() => this.updateAccount())
      )
      .subscribe(() =>
        this.showNotification(
          this._translateService.instant('CHANGE_INFOR_CLUB')
        )
      );
  }

  updateAccount() {
    return this._accountService
      .inlivewithAccountByIdAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            AuthService.SetAccountInfo(res?.data!);
            window.location.reload();
          }
        })
      );
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['../../']);
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  setAccountPrivate() {
    return this._accountService.inlivewithAccountPrivateAccountIdPut$Json({
      accountId: AuthService.GetAccountInfo().id!,
      body: {
        accountId: AuthService.GetAccountInfo().id!,
      },
    });
  }

  setAccountPublic() {
    return this._accountService.inlivewithAccountPublicAccountIdPut$Json({
      accountId: AuthService.GetAccountInfo().id!,
      body: {
        accountId: AuthService.GetAccountInfo().id!,
      },
    });
  }

  setAccountIsAcceptRecieveMessageAnyone() {
    let body = {
      accountId: AuthService.GetAccountInfo().id!,
      isEnableConversation: this.isAcceptRecieveMessageAnyone!,
    };
    return this._accountService.inlivewithAccountSetEnableConversationAccountIdPut$Json(
      body
    );
  }

  changepass() {
    // window.open(
    //   environment.WEB_PORTAL_URL +
    //     `/auth/change-password?callback=${
    //       window.origin
    //     }&email=${AuthService.GetAccountInfo().email!}`,
    //   '_self'
    // );
    window.open(
      environment.WEB_PORTAL_URL +
      `/profile/${LocalStorageService.GetLocalStorage('email')}?callback=${window.origin
      }/home/edit-user&lang=${LocalStorageService.GetLocalStorage(
        'language'
      ).toLowerCase()}`,
      'targetWindow',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=840`
    );
  }

  getListBlockedAccounts() {
    this._friendService
      .inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json({
        accountRelatingId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listAccountsBlocked = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  openConfirmUnBlock(account: AccountResponseGeneral) {
    this.currentUserIsChoosing = account;
    this.openConfirmUnBlockPopup = true;
  }

  unBlock() {
    this._friendService
      .inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json(
        {
          accountRelatedId: this.currentUserIsChoosing.id!,
          accountRelatingId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.showNotification(
              this._translateService.instant('CHANGE_INFOR_CLUB')
            );
            this.openConfirmUnBlockPopup = false;
            this.getListBlockedAccounts();
          }
        })
      )
      .subscribe();
  }

  gotoAccountInfo(account: AccountResponseGeneral) {
    if (AuthService.GetAccountInfo().id! !== account.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: account?.id! },
      });
    }
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }
}
