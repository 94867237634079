import { NotificationServicee } from './../../../core/services/notification.service';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  CommentPostResponseModel,
  MediaCommentPostResponseModel,
  MediaPostResponseModel,
  PostResponseModel,
  ReactInputModel,
} from 'src/app/core/api/be/models';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { MenuItem, MessageService } from 'primeng/api';
import { MobileService } from 'src/app/core/services/is-mobile.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  GROUP_ILW_DISCUSSION,
  MENTION_REGEX,
  URL_REGEX,
  defaultAvatar,
} from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { environment } from 'src/environments/environment';
import { AccountService, PostService } from 'src/app/core/api/be/services';
import { LoadingService } from 'src/app/core/services/loading.service';
import {
  EEvent,
  EventService,
  IEvent,
} from 'src/app/core/services/event.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'app-post-discussion',
  templateUrl: './post-discussion.component.html',
  styleUrls: ['./post-discussion.component.scss'],
})
export class PostDiscussionComponent implements OnInit, OnDestroy {
  listAllOptions: any;

  private _post!: PostResponseModel;

  @Input() set post(value: PostResponseModel) {
    if (value) {
      this._post = value;
      this.makeOption();
      let comments = this._post.commentPosts ?? [];
      if (comments.length >= 3) {
        this._post.commentPosts = comments.filter((c, index) => index < 2);
        this.makeColor(this._post.commentPosts ?? []);
      }
    }
  }
  get post() {
    return this._post;
  }
  @Input() isAdmin = false;
  @Input() isJoined = false;
  @Input() listMember: AccountResponseWithRoleInCLubModel[] = [];

  @Output() deletePostEvent = new EventEmitter<string>();
  @Output() askJoinClub = new EventEmitter();
  @Output() updatePinnedPost = new EventEmitter<any>();

  isMobile: boolean = false;
  show = false;
  items: MenuItem[] = [];
  completeText = false;
  showConfirmDeletePost = false;
  duration!: moment.Duration;
  countDown!: NodeJS.Timeout;
  timeEnd = '';
  showConfirmEndDiscussion = false;
  closeByManual = false;
  showErrorPinnedPost = false;
  tempTitleEdit = '';

  readonly defaultAvatar = defaultAvatar;

  private readonly minColor = 0.2;
  private readonly maxColor = 256;
  nameColors: { authorId: string; color: string }[] = [];

  eventFilter = [
    EEvent.CREATE_COMMENT,
    EEvent.UPDATE_COMMENT_MEDIA,
    EEvent.END_POST,
    EEvent.DELETE_COMMENT,
    EEvent.EDIT_COMMENT,
  ];

  openPopupReport = false;
  openPopupEditTitle = false;
  roleOfCurrentUser!: string;
  linkForCopy!: string;

  isShowMultiMedia = false;
  multiMedias: MediaCommentPostResponseModel[] = [];
  activeIndex = 0;

  subscription = new Subscription();

  refetchWhenReceiveNoti$ = this._notificationService.isReloadComment$$.pipe(
    tap((res: any) => {
      if (!res) {
        return;
      }

      let postId = JSON.parse(res?.content!)!.Content!;

      if (postId && postId == this.post.id) {
        this.getDetailPost().subscribe();

        if (this.post.type == 'DISCUSSION') {
          this.notiService.sendMessage(
            GROUP_ILW_DISCUSSION,
            JSON.parse(res?.content!)!.SenderId!,
            this.post!.id
          );
        }
      }
    })
  );

  refetchWhenReceiveNotiFile$ =
    this._notificationService.isReloadCommentFileProcess$$.pipe(
      tap((res: any) => {
        if (!res) {
          return;
        }

        let postId = JSON.parse(res?.content!)!.Content!.split(',')[1] || '';

        if (postId && postId == this.post.id) {
          this.getDetailPost().subscribe();

          if (this.post.type == 'DISCUSSION') {
            this.notiService.sendMessage(
              GROUP_ILW_DISCUSSION,
              JSON.parse(res?.content!)!.SenderId!,
              this.post!.id
            );
          }
        }
      })
    );

  constructor(
    public _router: Router,
    private _postService: PostService,
    private _translateService: TranslateService,
    private clipboardService: ClipboardService,
    private _messageService: MessageService,
    public _mobileService: MobileService,
    private eventService: EventService,
    private cdf: ChangeDetectorRef,
    private translateService: TranslateService,
    private _accountService: AccountService,
    public _firebaseService: FirebaseService,
    private notiService: NotificationServicee,
    private _notificationService: NotificationServicee
  ) {
    this.eventService
      .receive()
      .pipe(
        filter(
          (p: IEvent) =>
            this.eventFilter.findIndex((e) => e == p.action) >= 0 ||
            (this.post &&
              p.payload &&
              p.payload.id &&
              this.post.id == p.payload.id)
        )
      )
      .subscribe((data: IEvent) => {
        const comment = data.payload as CommentPostResponseModel;

        if (data.action == EEvent.CREATE_COMMENT) {
          if (
            !this.post?.isJoinDiscussion! &&
            this.post! &&
            this.post.id! == comment.postId!
          ) {
            this.post.isJoinDiscussion = true;
            this.initMenu();
          }
          if (this.post! && this.post.id! == comment.postId!) {
            let commentPosts = this.post.commentPosts ?? [];
            if (commentPosts.length >= 2) commentPosts.splice(1, 1);
            else this.post.urls?.push(comment.author?.avatar ?? defaultAvatar);
            commentPosts.unshift(comment);
            commentPosts = _.uniqBy(commentPosts, 'id');
            this.post.commentPosts = commentPosts;
          }
        } else if (data.action == EEvent.UPDATE_COMMENT_MEDIA) {
          if (this.post?.id! === comment.postId!) {
            let index = this.post.commentPosts?.findIndex(
              (x) => x.id == comment.id
            )!;
            if (index > -1) {
              this.post.commentPosts![index] = comment;
            }
          }
        } else if (data.action === EEvent.END_POST) {
          this.post = data.payload.post;
          clearInterval(this.countDown);
          if (data.payload.closeByManual) {
            this.timeEnd = new Date().toISOString();
            this.post.dateEnd = new Date().toISOString();
            return;
          }
          this.timeEnd = this.post.dateEnd!;
        } else if (data.action === EEvent.DELETE_COMMENT) {
          if (this.post! && this.post.id! == comment.postId!) {
            let commentPosts = this.post.commentPosts ?? [];
            commentPosts = commentPosts.filter((x) => x.id !== comment.id!);
            this.post.commentPosts = commentPosts;
          }
        } else if (data.action === EEvent.EDIT_COMMENT) {
          if (this.post! && this.post.id! == comment.postId!) {
            let index = this.post.commentPosts?.findIndex(
              (x) => x.id === comment.id!
            )!;
            if (index > -1) {
              this.post.commentPosts![index] = comment;
            }
          }
        }
      });

    // this.notiService.onReceiveMessage((accountId: string, data: any) => {
    //   if (data && data?.currentReplyCommentId! != '') {
    //     return;
    //   } else this.getDetailPost().subscribe();
    // });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.countDown) {
      clearInterval(this.countDown);
    }
  }

  postLikePost(isAppearing: boolean, type: any) {
    let body: ReactInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      dependObjectId: this.post.id,
      isAppearing: isAppearing,
      type: isAppearing ? type : null,
    };
    return this._postService
      .inlivewithPostReactReactOnPostPost$Json({ body: body })
      .subscribe(
        (res) => {
          if (res.res == -2) {
            this.deletePostEvent.emit(this.post.id!);
          }
        },
        () => {
          this.post.isReacted = !this.post.isReacted;
          if (this.post.isReacted) {
            this.post.totalReact!++;
          } else {
            this.post.totalReact!--;
          }
        }
      );
  }

  likePost() {
    if (!this.isJoined) {
      this.askJoinClub.emit();
      return;
    }
    this.post.isReacted = !this.post.isReacted;
    if (this.post.isReacted) {
      this.post.totalReact!++;
      this.postLikePost(true, 'REACTION_LIKE');
    } else {
      this.post.totalReact!--;
      this.postLikePost(false, null);
    }
  }

  ngOnInit(): void {
    this.subscription
      .add(this.refetchWhenReceiveNoti$.subscribe())
      .add(this.refetchWhenReceiveNotiFile$.subscribe());
    // .add(this.refetchWhenReceiveNotiFile$.subscribe())
    this.initMenu();
    if (this.post.isDiscussing) {
      this.timeRemain();
    } else {
      this.timeEnd = this.post?.dateEnd!;
    }
  }

  getRoleOfPostAuthor() {
    if (this.post.isBannedByClub) {
      return 'NO_ROLE';
    }
    if (
      this.post?.authorId! === this.post.club?.author?.id! ||
      this.post?.authorId! === this.post.club?.authorId!
    ) {
      return 'CREATOR';
    } else if (
      this.post?.authorId! != this.post.club?.author?.id! ||
      this.post?.authorId! != this.post.club?.authorId!
    ) {
      if (this.listMember?.length! > 0) {
        let index = this.listMember.findIndex(
          (x) => x.id! == this.post.authorId!
        );
        if (index < 0) {
          return '';
        }
        if (this.listMember[index].roleInClub === 'ROLE_ADMIN') {
          return 'ADMIN';
        }
      } else {
        if (this.post?.club?.roleOfPostAuthor! === 'ROLE_ADMIN') {
          return 'ADMIN';
        }
      }
    }

    return 'MEMBER';
  }

  openCommentForPost() {
    // if (!this.isJoined) {
    //   this.askJoinClub.emit();
    // } else {
    this.openClubDetail();
    LocalStorageService.AddLocalStorage('currentStageId', this.post.stageId!);
    this.eventService.push({ action: EEvent.OPEN_POST, payload: this.post });
    // }
  }

  hidePost() {
    this.eventService.push({ action: EEvent.HIDDEN_POST, payload: this.post });
    this._postService
      .inlivewithPostHidePostAccountRequestIdPostIdPut$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        postId: this.post.id!,
      }).pipe(
        tap((res) => {
          if (res.res == 0) {
            if (this._router.url.includes('/post-detail')) {
              this._router.navigate(['/home']).then(() => window.location.reload());
            } else {
              this.deletePostEvent.emit(this.post.id!);
            }
          }
        })
      )
      .subscribe();
  }

  initMenuFor() {
    /**
     * OWNER / CREATER / OTHER
     * ______________________________
     *
     * OWNER => create discussion
     * CREATER => creater club
     * OTHER => other admin club or normal user
     *
     */

    this.items = [];

    if (this.roleOfCurrentUser == 'OWNER') {
      this.items.push(this.listAllOptions.EDIT_TITLE_DISCUSSION);
      this.items.push(this.listAllOptions.END_POST);
      this.items.push(this.listAllOptions.DELETE_DISCUSSION);
      this.items.push(this.listAllOptions.DISCUSSION_SHARE);
      if (!this._router.url.startsWith('/home')) {
        this.items.push(this.listAllOptions.PIN_POST);
      }

      return;
    }

    if (this.roleOfCurrentUser == 'CREATER') {
      this.items.push(this.listAllOptions.HIDE_DISCUSSION);
      this.items.push(this.listAllOptions.END_POST);
      this.items.push(this.listAllOptions.DELETE_DISCUSSION);

      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : (this.items.push(this.listAllOptions.PROFILE_SEE),
          this.items.push(this.listAllOptions.PIN_POST));
    }

    if (this.roleOfCurrentUser == 'ADMIN') {
      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : this.items.push(this.listAllOptions.PROFILE_SEE);

      this.items.push(this.listAllOptions.HIDE_DISCUSSION);
      // this.items.push(this.listAllOptions.END_POST);
      // this.items.push(this.listAllOptions.DELETE_DISCUSSION);
    }

    if (this.roleOfCurrentUser == 'OTHER') {
      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : this.items.push(this.listAllOptions.PROFILE_SEE);
      this.items.push(this.listAllOptions.HIDE_DISCUSSION);
    }

    if (this.post.author?.accessLevelInClub! !== "") {
      this.items.push(this.listAllOptions.DISCUSSION_SHARE);
      this.items.push(this.listAllOptions.REPORT_DISCUSSION);
    }
  }

  initMenu() {
    if (
      AuthService.GetAccountInfo().id === this.post.authorId ||
      AuthService.GetAccountInfo().id == this.post.club?.authorId
    ) {
      if (AuthService.GetAccountInfo().id === this.post.club?.authorId) {
        this.roleOfCurrentUser = 'CREATER';
      }

      if (AuthService.GetAccountInfo().id === this.post.authorId) {
        this.roleOfCurrentUser = 'OWNER';
      }
    } else {
      if (this.isAdmin) {
        this.roleOfCurrentUser = 'ADMIN';
      } else {
        this.roleOfCurrentUser = 'OTHER';
      }
    }

    this.initMenuFor();
  }

  deletePost() {
    this._postService
      .inlivewithPostDeletePostDelete$Json({
        stageId: this.post.stageId!,
        postId: this.post.id,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.deletePostEvent.emit(this.post.id!);
            this.eventService.push({
              action: EEvent.DELETE_POST,
              payload: this.post,
            });
            this.showNotification(
              this._translateService.instant('POST_DISCUSSION_DELETE_SUCCESS')
            );
            this.showConfirmDeletePost = false;
          } else {
            this.showNotification(
              this._translateService.instant('AT_LEAST_HAS_A_POST_ON_STAGE')
            );
            this.showConfirmDeletePost = false;
          }
        })
      )
      .subscribe();
  }

  openClubDetail() {
    LoadingService.$(true);
    this._router
      .navigate(['manage-club', 'detail-club'], {
        queryParams: { clubId: this.post?.club?.id! },
      })
      .then(() => LoadingService.$(false));
  }

  openAccountInfo() {
    if (
      AuthService.GetAccountInfo().id! !== this.post.author?.id! &&
      !this.post.isBannedByClub
    ) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: this.post.author?.id! },
      });
    }
  }

  getNumberWithZero(num: number) {
    return num >= 10 ? num.toString() : '0' + num;
  }

  timeRemain() {
    const eventTime: number = moment.utc(this.post.dateEnd).unix();
    const currentTime: number = moment.utc().unix();
    var diffTime = eventTime - currentTime;
    this.duration = moment.duration(diffTime * 1000, 'milliseconds');
    var interval = 1000;
    this.countDown = setInterval(() => {
      this.duration = moment.duration(
        this.duration.asMilliseconds() - interval,
        'milliseconds'
      );
      this.handleCountDownDone();
    }, 1000);
  }

  private handleCountDownDone() {
    if (
      this.duration.asMilliseconds() <= 0 &&
      this.duration.hours() <= 0 &&
      this.duration.minutes() <= 0 &&
      this.duration.seconds() <= 0 &&
      this.duration.milliseconds() <= 0
    ) {
      if (this.countDown) {
        clearInterval(this.countDown);
      }
      this.endDiscussion();
    }
  }

  timeAgo(date: string) {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();
  }

  getUrl(asset: any) {
    if (!asset) {
      return;
    }

    if (!asset!.includes('?')) {
    } else {
      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!;
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset;
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  urlify(text: any) {
    let responseTextFR = 'Réponse à';

    text = text?.replace(responseTextFR, (response: any) => {
      return `<span>${this.translateService.instant(
        'COMMENT_REPLY_PLACEHOLDER'
      )}</span>`;
    });

    let responseText = '🔥 COMMENT_REPLY_PLACEHOLDER';

    text = text?.replace(responseText, (response: any) => {
      return `<span>${this.translateService.instant(
        'COMMENT_REPLY_PLACEHOLDER'
      )}</span>`;
    });

    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }

  endDiscussion() {
    LoadingService.$(true);
    this._postService
      .inlivewithPostEndPostDiscusionPostIdPut$Json({ postId: this.post.id! })
      .subscribe((res) => {
        LoadingService.$(false);
        this.post.isDiscussing = false;
        this.eventService.push({
          action: EEvent.END_POST,
          payload: {
            post: this.post,
            closeByManual: this.closeByManual,
          },
        });
        this.showNotification(
          this._translateService.instant('POST_DISCUSSION_END_SUCCESS')
        );
        //this.ngOnInit();
        location.reload();
      });
  }

  private generateRandomColorRgb() {
    // const red = Math.floor((Math.random() + this.minColor) * this.maxColor);
    // const green = Math.floor((Math.random() + this.minColor) * this.maxColor);
    // const blue = Math.floor((Math.random() + this.minColor) * this.maxColor);
    return 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
  }

  makeColor(comments: CommentPostResponseModel[]) {
    for (let index = 0; index < comments.length!; index++) {
      if (
        this.nameColors.findIndex(
          (n) => n.authorId === comments[index].authorId
        ) < 0
      ) {
        this.nameColors.push({
          authorId: comments[index].authorId!,
          color: this.generateRandomColorRgb(),
        });
      }
    }
  }

  findColor(id: string) {
    if (!this.nameColors.find((c) => c.authorId === id)) {
      var obj = {
        authorId: id,
        color: this.generateRandomColorRgb(),
      };
      this.nameColors.push(obj);
    }
    return this.nameColors.find((c) => c.authorId === id)?.color ?? obj!.color;
  }

  checkTypeMedia(asset: MediaCommentPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  refreshWhenReportSuccess() {
    this.post.isReported = true;
    this.openPopupReport = false;
    this.makeOption();
    this.initMenu();
  }

  // checkHasAccess() {
  //   let body: any = {
  //     accountRequestId: AuthService.GetAccountInfo().id!,
  //     clubId: this.post.clubId,
  //   };

  //   return this._accountService.inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json(
  //     body
  //   );
  // }

  makeOption() {
    var limitStringPipe = new LimitStringPipe();
    this.listAllOptions = {
      END_POST: {
        icon: 'pi pi-calendar-times',
        label: this._translateService.instant('END_POST'),
        disabled: !this.post.isDiscussing,
        command: () => {
          this.showConfirmEndDiscussion = true;
        },
      },
      DELETE_DISCUSSION: {
        icon: 'pi pi-trash',
        label: this._translateService.instant('DELETE_DISCUSSION'),
        command: () => (this.showConfirmDeletePost = true),
      },
      VIEW_CLUB: {
        icon: 'pi pi-star',
        label: this._translateService.instant('VIEW_CLUB'),
        command: () => {
          this.openClubDetail();
        },
      },
      PROFILE_SEE: {
        icon: 'pi pi-user',
        label: limitStringPipe.transform(
          this._translateService.instant('SEE') +
          ' ' +
          this.post.author?.nickName!,
          false,
          40
        ),
        disabled: AuthService.GetAccountInfo().id! == this.post.author?.id!,
        visible:
          this.post.author?.accessLevelInClub! !== 'ACCESSLEVEL_CELEBRITY',
        command: () => {
          this.openAccountInfo();
        },
      },
      HIDE_DISCUSSION: {
        icon: 'pi pi-eye-slash',
        label: this._translateService.instant('HIDE_DISCUSSION'),
        disabled:
          this.isAdmin ||
          (this.post.isDiscussing! && this.post.isJoinDiscussion!),
        command: () => {
          this.hidePost();
        },
      },
      DISCUSSION_SHARE: {
        icon: 'pi pi-share-alt',
        label: this._translateService.instant('DISCUSSION_SHARE'),
        command: () => {
          this.clipboardService.copyFromContent(this.linkForCopy);
          this._messageService.add({
            severity: 'success',
            // summary: this._translateService.instant('NOTIFICATION'),
            detail: this._translateService.instant('COPIED'),
            key: 'copied',
            closable: false,
          });
        },
      },
      REPORT_DISCUSSION: {
        icon: 'pi pi-flag',
        label: this._translateService.instant('REPORT_DISCUSSION'),
        disabled: this.post.isReported,
        command: () => {
          this.openPopupReport = true;
        },
      },
      EDIT_TITLE_DISCUSSION: {
        icon: 'pi pi-pencil',
        label: this._translateService.instant('EDIT_TITLE_DISCUSSION'),
        disabled: this.post.isReported || !this.post.isDiscussing,
        command: () => {
          this.openPopupEditTitle = true;
          this.tempTitleEdit = this.post.title!;
        },
      },
      PIN_POST: {
        icon: this.post.isPinPost ? 'unpin' : 'pin',
        label: this.post.isPinPost
          ? this._translateService.instant('UNPIN_DISCUSSION')
          : this._translateService.instant('PIN_DISCUSSION'),
        visible:
          this.post!.club!.authorId == AuthService.GetAccountInfo().id &&
          this.post.authorId == AuthService.GetAccountInfo().id &&
          this._router.url.includes('manage-club/detail-club'),
        command: () => {
          if (this.isMobile) {
            return;
          }
          this.pinPost(this.post.isPinPost! ? false : true);
        },
      },
    };

    this.initMenu();
  }

  renderLinkForCopy() {
    //LoadingService.$(true);

    let url =
      environment.BASE_URL +
      '/manage-club/post-detail' +
      '?postId=' +
      this.post?.id! +
      '&clubId=' +
      this.post?.clubId! +
      '&stageId=' +
      this.post?.stageId!;

    this._firebaseService.generateDeepLink(url).subscribe((res: any) => {
      this.linkForCopy = res.shortLink + '?efr=1';
      //LoadingService.$(false);
    });
  }

  getDetailPost() {
    let body = {
      postId: this.post.id!,
      accountRequestId: AuthService.GetAccountInfo().id!,
    };

    return this._postService.inlivewithPostGetByIdPostIdGet$Json(body).pipe(
      tap((res) => {
        if (res.res == 0) {
          this.post = res.data!;
          if (this.post.commentPosts?.length! == 0) {
            window.location.reload();
          }
        }
      })
    );
  }

  checkProcessMediaFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  showMultiMedia(urls: MediaCommentPostResponseModel[], index: number) {
    this.multiMedias = urls;
    this.activeIndex = index;
    this.isShowMultiMedia = true;
  }

  getAvtClub(url: string) {
    return url!.replace('/file.', '/thumb.');
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }

  editTitleDiscussion() {
    let body = {
      accountRequestId: AuthService.GetAccountInfo().id,
      id: this.post.id,
      authorId: this.post.authorId,
      message: this.tempTitleEdit,
      stageId: this.post.stageId,
    };

    if (this.tempTitleEdit.length == 0 || this.tempTitleEdit.length > 40) {
      return;
    } else {
      this._postService
        .inlivewithPostEditTitlePostDiscussionPut$Json({
          body,
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              this.post.title = res.data?.title!;
              this.openPopupEditTitle = false;
            }
          })
        )
        .subscribe();
    }
  }

  pinPost(isPinned: boolean) {
    this._postService
      .inlivewithPostPinPostPostIdStageIdPost$Json({
        postId: this.post.id!,
        stageId: this.post.stageId!,
        isPinPost: isPinned,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.post.isPinPost = isPinned;
            let payload = {
              post: this.post!,
              isPinned: isPinned,
              error: false,
            };
            this.makeOption();
            this.items.pop();
            this.items.push(this.listAllOptions.PIN_POST);
            this.updatePinnedPost.emit(payload);
          }

          if (res.res == -3) {
            let payload = {
              post: this.post!,
              isPinned: isPinned,
              error: true,
            };
            this.updatePinnedPost.emit(payload);
          }
        })
      )
      .subscribe();
  }
}
