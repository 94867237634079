import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EEvent, EventService } from 'src/app/core/services/event.service';
import { FullScreenServices } from 'src/app/core/services/full-screen.service';

@Component({
  selector: 'app-video-layer',
  templateUrl: './video-layer.component.html',
  styleUrls: ['./video-layer.component.scss'],
})
export class VideoLayerComponent implements OnInit, AfterViewInit {
  videoId = new Date().getTime();
  pause(): void {
    throw new Error('Method not implemented.');
  }
  play() {
    throw new Error('Method not implemented.');
  }
  private _layout = 'premiere';
  private _number!: number;
  private _url = '';
  private _poster =
    'https://dev-ilme.ilwdev.cloud/Inlive_Me/default_thumbnail.jpg';

  @Output() ratioVideo = new EventEmitter();
  @Output() playOrPause = new EventEmitter();

  @Input() set layout(value: string) {
    this._layout = value;
  }
  get layout(): string {
    return this._layout;
  }
  @Input() set url(value: string) {
    this._url = value;
    if (!!this.myVideo) {
      this.myVideo.nativeElement.load();
    }
  }
  get url(): string {
    return this._url;
  }
  @Input() set poster(value: string) {
    let result = value.replace('https://dev-ilme.ilwdev.cloud', '');
    this._poster = result == 'null' || result == '' ? this._poster : value;
  }
  get poster(): string {
    return this._poster;
  }
  @Input() idVideo!: string;

  playPath: string = '../../../assets/images/svgs/play-button.png';
  pausePath: string = '../../../assets/images/svgs/pause-icon.svg';
  mutePath: string = '../../../assets/images/svgs/volume-mute.svg';
  volumePath: string = '../../../assets/images/svgs/volume.svg';
  videoStarted = false;
  @ViewChild('videoPlayer', { static: true }) myVideo!: ElementRef;
  @ViewChild('video') video!: ElementRef;
  @Output() requestPlayback: EventEmitter<VideoLayerComponent> =
    new EventEmitter<VideoLayerComponent>();
  currentPlayingVideo!: HTMLVideoElement;
  currTime: string = '00:00';
  isPlay: boolean = false;
  isMuted = false;
  movieLoaded: boolean = false;
  videoDuration = '00:00';
  isEnded: boolean = false;
  bindSticky() {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefox) {
      this.video.nativeElement.classList.add('bur-button');
    } else {
      this.video.nativeElement.classList.remove('bur-button');
    }
  }
  constructor(
    private fullScreenServices: FullScreenServices,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    if (this.layout !== 'premiere') this.myVideo.nativeElement.muted = true;
    this.myVideo.nativeElement.controls = false;
  }

  volume() {
    if (this.myVideo.nativeElement.muted) {
      this.myVideo.nativeElement.muted = false;
      this.isMuted = true;
    } else {
      this.myVideo.nativeElement.muted = true;
      this.isMuted = false;
    }
  }

  ngDoCheck() {
    if (this.myVideo.nativeElement.paused) {
      this.isPlay = false;
    } else {
      this.isPlay = true;
    }
  }

  playPause(videoId: any) {
    let videos = document.getElementsByTagName(
      'video'
    ) as HTMLCollectionOf<HTMLVideoElement>;

    for (let index = 0; index < videos.length; index++) {
      if (videos[index].id != videoId.toString()) {
        videos[index].pause();
        videos[index].muted = true;
      } else {
        if (this.myVideo.nativeElement.paused) {
          if (this.myVideo.nativeElement.muted) {
            this.myVideo.nativeElement.muted = false;
            this.isMuted = true;
          }
          this.playOrPause.emit(true);
          this.myVideo.nativeElement.play();
          this.isPlay = true;
          this.isEnded = false;
        } else {
          if (this.myVideo.nativeElement.muted) {
            this.myVideo.nativeElement.muted = false;
            this.isMuted = true;
          } else {
            this.myVideo.nativeElement.muted = true;
            this.isMuted = false;
          }
          this.playOrPause.emit(false);
          this.myVideo.nativeElement.pause();
          this.isPlay = false;
        }
      }
    }
  }

  getBrowser() {
    if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Webkit';
    } else if (navigator.userAgent.indexOf('Opera') != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('MSIE') != -1) {
      return 'IE';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else {
      return 'unknown';
    }
  }

  makeFullScreen(video: any) {
    try {
      if (video.webkitEnterFullScreen) {
        /** Modern IOS */
        let sefl = this;
        // if (sefl.getBrowser() === 'Safari') {
        video.addEventListener('webkitfullscreenchange', function (e: any) {
          if (e.target.webkitDisplayingFullscreen) {
            sefl.fullScreenServices.getIsSafari(true);
          } else {
            sefl.fullScreenServices.getIsSafari(false);
          }
        });
        // }
        // video.webkitEnterFullScreen();
        if (window.innerWidth < 576) {
          video.webkitEnterFullScreen();
        } else {
          video.webkitRequestFullscreen();
        }
      } else if (video.webkitRequestFullscreen) {
        /* Safari */
        let sefl = this;
        // if (sefl.getBrowser() === 'Safari') {
        video.addEventListener('webkitfullscreenchange', function (e: any) {
          if (e.target.webkitDisplayingFullscreen) {
            sefl.fullScreenServices.getIsSafari(true);
          } else {
            sefl.fullScreenServices.getIsSafari(false);
          }
        });
        // }
        video.webkitRequestFullScreen();
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen();
      } else if (video.mozRequestFullScreen) {
        /* Moz */
        video.mozRequestFullScreen();
      } else if (video.requestFullscreen) {
        video.requestFullscreen();
      }
    } catch (error) {
      alert('Oops! Cannot enter fullscreen.');
    }
  }

  processMetaData(video: any) {
    this.videoDuration = this.secondsToMinutesAndSeconds(video.duration);

    let widthVideo = this.myVideo.nativeElement.offsetWidth;
    let heightVideo = this.myVideo.nativeElement.offsetHeight;
    let widthInHeight = heightVideo / widthVideo;
    this.ratioVideo.emit(widthInHeight);
    // this.handleVideoOnload(video);
  }

  secondsToMinutesAndSeconds(time: any) {
    var minutes = Math.floor(time / 60);
    var seconds = Math.round(time % 60);

    return (
      '' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0')
    );
  }

  updateDisplayTime(video: any) {
    this.currTime = this.secondsToMinutesAndSeconds(video.currentTime);
  }
  videoEnd(event: any) {
    this.isEnded = true;
    this.isPlay = false;
  }

  ngAfterViewInit(): void {
    this.addEventCheckVideo();
  }

  addEventCheckVideo() {
    let videos = document.querySelectorAll('video');

    videos!.forEach((v) => {
      v.addEventListener('play', (event) => {
        console.log('play');
        this.eventService.push({
          action: EEvent.PLAY_VIDEO,
          payload: { isPlaying: true, videoId: v.id },
        });
      });
    });
  }
}
