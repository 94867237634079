import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Account, AccountClubBannedResponseModel, AccountResponseGeneral } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-inliver-banned',
  templateUrl: './list-inliver-banned.component.html',
  styleUrls: ['./list-inliver-banned.component.scss']
})
export class ListInliverBannedComponent implements OnInit {

  @Input() showDialog!: boolean;
  @Input() clubId!: string;
  @Input() isMobile!: boolean;

  @Output() showDialogChange = new EventEmitter();

  defaultAvatar = defaultAvatar

  listInliversBanned: AccountClubBannedResponseModel[] = [];

  constructor(
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getListInliversBanned().subscribe();
  }

  onHide() {
    this.showDialogChange.emit(false);
  }

  getListInliversBanned() {
    return this._clubService.inlivewithClubAccountBannedAdminClubIdClubIdGet$Json({
      clubId: this.clubId!,
      adminClubId: AuthService.GetAccountInfo().id!,
      limit: 1000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listInliversBanned = res.data?.page!;
        }
      })
    )
  }

  unBlock(account: AccountClubBannedResponseModel) {
    return this._clubService.inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json({
      clubId: this.clubId!,
      adminClubId: AuthService.GetAccountInfo().id!,
      body: [account.accountBannedId!]
    }).pipe(
      tap(() => this.showNotification(account.accountBanned?.nickName! + ' ' + this._translateService.instant('REVOKE_UNBANNED_SUCCESS'))),
      mergeMap(res => res.res == 0 ? this.getListInliversBanned() : of(null))
    ).subscribe();
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    })
  }

}
