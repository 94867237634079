import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ClubService } from 'src/app/core/api/be/services';
import { rulesDefaultWhenCreateClub } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-edit-policy-club',
  templateUrl: './edit-policy-club.component.html',
  styleUrls: ['./edit-policy-club.component.scss']
})
export class EditPolicyClubComponent implements OnInit {

  @Input() openEditPolicyClub: boolean = false;
  @Input() policy: string = rulesDefaultWhenCreateClub
  @Input() clubId!: string;

  @Output() openEditPolicyClubChange = new EventEmitter<boolean>();


  constructor(
    private _clubService: ClubService
  ) { }

  ngOnInit(): void {

  }

  onHide() {
    this.openEditPolicyClubChange.emit(this.openEditPolicyClub);
  }

  editPolicy() {
    let countClick = 0;
    countClick++;
    LoadingService.$(true);
    if (countClick == 1) {
      this._clubService.inlivewithClubUpdatePolicyClubIdPut$Json({
        clubId: this.clubId,
        body: {
          policy: this.policy
        }
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            LoadingService.$(false)
            window.location.reload();
          }
        }),
        catchError(res => {
          LoadingService.$(false)
          return of(res);
        }),
        finalize(() => {
          LoadingService.$(false)
          window.location.reload();
        })
      ).subscribe();
    }
  }
}
