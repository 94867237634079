import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { NgWaveformComponent } from 'ng-waveform';
import { MenuItem } from 'primeng/api';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  AlbumAssetResponseGeneral,
  CommentAlbumAssetResponseModel,
  CreateCommentAlbumAssetResponseModelResponseModel,
  MediaCommentAlbumAsset,
  MediaCommentAlbumAssetResponseModel,
  MediaCreateResponseModel,
  MediaPostResponseModel,
  MentionReceiver,
} from 'src/app/core/api/be/models';
import { AccountService, AlbumService, MentionService } from 'src/app/core/api/be/services';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  MENTION_REGEX,
  TYPE_MENTION,
  URL_REGEX,
  base64ToBlob,
  defaultAvatar,
  getFileExtension,
  typeAudio,
} from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-comment-album',
  templateUrl: './comment-album.component.html',
  styleUrls: ['./comment-album.component.scss'],
})
export class CommentAlbumComponent implements OnInit {
  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;

  @Input() commentAsset!: CommentAlbumAssetResponseModel[];
  @Input() asset!: AlbumAssetResponseGeneral;
  @Input() listMention: any[] = [];
  @Input() popupChanged = false;
  @Input() isJoined!: boolean;

  applyClub: boolean = false;

  @Output() refresh = new EventEmitter<boolean>();

  currentReplyComment!: CommentAlbumAssetResponseModel;

  isHaveParent!: boolean;
  isAcceptToViewMenu!: boolean;

  urls: any[] = [];
  filePosts: any[] = [];
  filesDelete: any[] = [];
  filesEdit: any[] = [];
  blobFileAudio!: Blob;
  urlAudio!: string;

  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  urlsChild: any[] = [];
  filePostsChild: any[] = [];
  urlAudioChild!: string;
  blobFileAudioChild!: Blob;

  content: string = '';
  contentReply: string = '';
  typeOfComment: string = '';
  openPopupRecordAudio: boolean = false;
  openPopupRecordAudioChild: boolean = false;
  isEditComment: boolean = false;
  openPopupReport: boolean = false;

  countClicked = 0;
  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;
  isValidToAddAssets: boolean = true;
  isValidToAddAudio: boolean = true;
  showEditRule = false;

  multiMedias: MediaCommentAlbumAssetResponseModel[] = [];
  activeIndex: number = 0;
  isShowMultiMedia = false;

  mentionConfig: any = {};
  tribute!: any;

  defaultAvatar = defaultAvatar;

  refetchWhenReceiveNoti$ =
    this._notificationService.isReloadCommentAlbum$$.pipe(
      tap(() => {
        this.refresh.emit();
      })
    );
  clubId: any;
  isAdmin!: boolean;
  isHasText!: boolean;

  isChrome =
    navigator.userAgent.toLowerCase().includes('chrome') &&
    !navigator.userAgent.toLowerCase().includes('edg');

  constructor(
    private _router: Router,
    private _translateService: TranslateService,
    private _albumService: AlbumService,
    private domSanitizer: DomSanitizer,
    private _notificationService: NotificationServicee,
    private _activeRoute: ActivatedRoute,
    private _accountService: AccountService,
    private _mentionService: MentionService,
    private _uploadService: UploadService
  ) {
    this._activeRoute.queryParams
      .pipe(
        tap((p: any) => {
          this.clubId = p.clubId!;
        }),
        mergeMap(() => this.checkRoleInClub())
      )
      .subscribe(() => { });
  }

  ngOnInit(): void {
    this.refetchWhenReceiveNoti$.subscribe();
    this.initConfigMention();
  }

  ngOnChanges() {
    if (this.popupChanged) {
      this.clear();
    }
  }

  clear() {
    this.showErrorDuration = false;
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    this.showEditRule = false;
    this.isHaveParent = false;
    this.isValidToAddAssets = true;
    this.isValidToAddAudio = true;
    this.content = '';
    this.contentReply = '';
    this.urls = [];
    this.urlsChild = [];
    this.filePosts = [];
    this.filePostsChild = [];
    this.isEditComment = false;
  }

  getItem(comment: CommentAlbumAssetResponseModel) {
    let items: MenuItem[] = [];
    this.isAcceptToViewMenu =
      comment?.authorId! === AuthService.GetAccountInfo()?.id!;

    items = this.isAcceptToViewMenu
      ? [
        {
          icon: 'pi pi-pencil',
          label: this._translateService.instant('MODIFIER'),
          disabled: this.checkAllDoneHaveParams(comment),
          command: () => {
            this.isEditComment = true;
            this.currentReplyComment = comment;
            this.content = comment.message!;
            this.filesDelete = comment.mediaCommentAlbumAssets!
            this.filesEdit = [];
            this.typeOfComment = this.checkTypeToEditComment(comment);

            if (this.typeOfComment === 'UNKNOWN') {
              if (this.content && this.content.trim().length > 0) {
                this.isHasText = true;
              } else this.isHasText = false;
            }
          },
        },
        {
          icon: 'pi pi-trash',
          disabled: this.isEditComment,
          label: this._translateService.instant('DELETE'),
          command: () => {
            this.deleteCommentAsset(comment);
          },
        },
      ]
      : [
        {
          icon: 'pi pi-users',
          label: this._translateService.instant('PROFILE_SEE'),
          command: () => {
            this.openAccountInfo(comment?.authorId!, comment.isBannedByClub!);
          },
        },
        {
          icon: 'pi pi-flag',
          label: this._translateService.instant('COMMENT_REPORT'),
          disabled: comment.isReported,
          command: () => {
            this.openPopupReport = true;
            this.currentReplyComment = comment;
          },
        },
      ];

    if (this.isAdmin) {
      items.push({
        icon: 'pi pi-trash',
        disabled: this.isEditComment,
        label: this._translateService.instant('DELETE'),
        command: () => {
          this.deleteCommentAsset(comment);
        },
      });
    }

    items = _.unionBy(items, 'icon');

    return items;
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  openAccountInfo(item: any, isBanned: boolean) {
    if (AuthService.GetAccountInfo().id! !== item && isBanned == false) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: item },
      });
    }
  }

  timeAgo = (date: string) => {
    const timeNow = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    const parsedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();

    if (moment.utc().diff(date, 'days') >= 7) {
      return moment
        .utc(date)
        .locale(currentLang ? currentLang : 'fr')
        .format('L');
    }

    /**
     *  If the post has been created less than a minute ago
     */
    if (moment(timeNow).diff(moment(parsedDate), 'seconds') <= 60) {
      return this._translateService.instant('POST_DATE_NOW');
    }

    //   moment.updateLocale('fr', {
    //     relativeTime : {
    //         past:   "%s",
    //         s  : '%d s',
    //         ss : '%d s',
    //         m:  "%d m",
    //         mm: "%d m",
    //         h:  "an h",
    //         hh: "%d h",
    //         d:  "%d j",
    //         dd: "%d j",
    //         M:  "%d mois",
    //         MM: "%d mois",
    //         y:  "%d an",
    //         yy: "%d années"

    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ')
      ? text
        .replace('il y a ', '')
        .replace('une', ' 1')
        .replace('jour', ' j')
        .replace('minute', ' min')
        .replace('heure', ' h')
        .replace('s', '')
        .replace('quelque secondes', '1s')
        .replace('un', ' 1')
      : text;

    return text;
  };

  focusText(item: CommentAlbumAssetResponseModel) {
    this.isHaveParent = true;
    this.currentReplyComment = item;
    this.urlsChild = [];
    this.filePostsChild = [];
    this.urlAudioChild = '';
    this.contentReply = '';
  }

  removeAssetChild(fileName: string) {
    this.urlsChild = this.urlsChild.filter((file) => file.id !== fileName);
    this.filePostsChild = this.filePostsChild.filter(
      (file) => file.id !== fileName
    );

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urlsChild.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urlsChild[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  detectFiles(event1: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    var isCheckMedia = false;
    var isCheckAudio = false;

    /* check maximum 4 files */
    if (event1.target.files.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
    }

    for (let index = 0; index < event1.target.files.length; index++) {
      const file = event1.target.files[index];

      if (file) {
        LoadingService.$(true);
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event1.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (file.type.split('/')[0] === 'audio') {
          isCheckAudio = true;
        }
        if (
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'image'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          LoadingService.$(false);
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          // console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              // console.log(f_duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                return;
              } else {
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              }
            });
        }

        reader.onload = () => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          file.id = new Date().getTime();
          if (this.isHaveParent) {
            this.filePostsChild.push(file);
            this.urlsChild.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
              originalTypeFile: file.type,
            });
          } else {
            this.filePosts.push(file);
            this.urls.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
              originalTypeFile: file.type,
            });
          }

          /* check maximum 4 files */
          if (this.urls.length > 4 || this.urlsChild.length > 4) {
            this.showErrorMaximumFiles = true;
            LoadingService.$(false);
            //event.target.value = null;
            //return;
          }
          event1.target.value = null;
        };
      }
      LoadingService.$(false);
    }
  }

  removeAsset(fileName: string) {
    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urls.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urls.length; index++) {
      /*check mixed file */
      if (this.urls[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urls[index].format === 'image' ||
        this.urls[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urls[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
        this.urls[index].file.type.split('/')[0] === 'video' ||
        this.urls[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urls[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  urlify(text: any) {
    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMention?.filter(
      (user) => user!.username!.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  createCommentReply() {
    if (!this.isJoined) {
      this.applyClub = true;
      return;
    }

    this.countClicked++;

    if (this.urlsChild.length > 4) {
      this.countClicked = 0;
      return;
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    LoadingService.$(true);

    var isCheckAudio = false;
    var isCheckMedia = false;
    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        // console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);
            console.log(f_duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            } else {
              //this.showErrorDuration = false;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              //LoadingService.$(false);
              //return;
            }
          });
      }
    }

    if (
      this.showErrorLimitSizeFile ||
      this.showErrorMaximumFiles ||
      this.showErrorDuration
    ) {
      this.countClicked = 0;
      LoadingService.$(false);
      return;
    } else {
      if (this.countClicked == 1) {
        this._albumService
          .inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json({
            body: {
              AlbumId: this.asset.albumId!,
              AuthorId: AuthService.GetAccountInfo().id!,
              AlbumAssetId: this.currentReplyComment.albumAssetId!,
              ParentId: this.currentReplyComment.id!,
              Message: this.contentReply,
              MediaCommentFiles: [],
              MediaFileNames: this.filePostsChild.map(x => x.name)
            },
          })
          .pipe(
            mergeMap((res) => forkJoin([this.updateAsset(), this.filePostsChild.length > 0 ? this.uploadMedia(this.filePostsChild, res.data?.mediaCreateResponseModels!) : of(null)])),
            tap((res) => {
              if (res[0].res == 0) {
                this.commentAsset = res[0].data?.page!;
                (this.contentReply = ''), (this.isHaveParent = false);
                this.filePostsChild = [];
                this.urlsChild = [];
                this.urlAudioChild = '';
                this.blobFileAudioChild = new Blob();
                this.currentReplyComment = {};
                this.showErrorDuration = false;
                this.showErrorLimitSizeFile = false;
                this.showErrorMaximumFiles = false;
                this.showErrorMixedFiles = false;
                this.isEditComment = false;
              }
            })
          )
          .subscribe(() => {
            this.countClicked = 0;
            LoadingService.$(false);
            this.refresh.emit();
          });
      } else {
        //this.showErrorMaximumFiles = false;
        this.countClicked = 0;
        LoadingService.$(false);
      }
    }
  }

  addMentionReceivers(receivers: MentionReceiver[], objectId: string) {
    this._mentionService.inlivewithMentionCreatesPost$Json({
      isPushNoti: this.filePosts.length > 0 ? false : true,
      body: {
        clubId: this.clubId!,
        dependObjectId: objectId,
        senderId: AuthService.GetAccountInfo().id!,
        receivers: receivers,
        type: TYPE_MENTION.COMMENT_ALBUM
      }
    }).subscribe();
  }

  createComment() {
    if (!this.isJoined) {
      this.applyClub = true;
      return;
    }
    //============

    this.countClicked++;
    this.isHaveParent = false;

    if (this.urls.length > 4) {
      this.countClicked = 0;
      return;
    }

    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    LoadingService.$(true);

    if (this.isEditComment) {
      if (this.typeOfComment == 'TEXT') {
        if (this.content.trim().length! > 0 && this.countClicked == 1) {
          this._albumService
            .inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json({
              body: {
                commentId: this.currentReplyComment.id!,
                message: this.content,
              },
            })
            .pipe(
              mergeMap(() =>
                this.getCommentAlbumAssetById(this.currentReplyComment)
              ),
              tap((res) => {
                var index = this.commentAsset.findIndex(
                  (x) => x.id === res.data!.id!
                );
                if (index > -1) {
                  if (this.currentReplyComment.id == res.data?.id!) {
                    this.commentAsset[index] = res.data!;
                    //this.currentReplyComment = res.data!;
                  } else {
                    var indexChild = res.data?.inverseParent!.findIndex(
                      (x) => x.id === this.currentReplyComment.id!
                    )!;

                    if (indexChild > -1) {
                      this.commentAsset[index].inverseParent![indexChild!] =
                        res.data?.inverseParent![indexChild!]!;
                      //this.currentReplyComment = res.data?.inverseParent![indexChild!]!
                    }
                  }
                }
              })
            )
            .subscribe(() => {
              this.content = '';
              this.isHaveParent = false;
              this.urls = [];
              this.filePosts = [];
              this.urlAudio = '';
              this.currentReplyComment = {};
              this.isEditComment = false;
              this.countClicked = 0;
              //this.getCommentByAssetId();
              LoadingService.$(false);
              //this.refresh.emit();
            });
        } else {
          this.countClicked = 0;
          this.showEditRule = true;
          LoadingService.$(false);
        }
        return;
      }

      if (
        this.filePosts.length! +
        this.filesDelete.length >
        4
      ) {
        this.showErrorMaximumFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        this.showErrorMaximumFiles = false;
      }

      if (this.typeOfComment == 'UNKNOWN') {
        if (this.isHasText && this.content.trim().length == 0) {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }

        if (
          this.filesDelete.length! +
          this.filePosts.length >
          0
        ) {
          var isValidToAddAudio = true;
          var isValidToAddAssets = true;

          if (this.currentReplyComment.mediaCommentAlbumAssets?.length! > 0) {
            this.currentReplyComment.mediaCommentAlbumAssets!.forEach((x) => {
              if (this.checkTypeMedia(x) === 'AUDIO') {
                this.filePosts.forEach((x) => {
                  if (
                    x.type.split('/')[0] === 'image' ||
                    x.type.split('/')[0] === 'video'
                  ) {
                    isValidToAddAssets = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
              if (
                this.checkTypeMedia(x) === 'IMAGE' ||
                this.checkTypeMedia(x) === 'VIDEO'
              ) {
                this.filePosts.forEach((x) => {
                  if (x.type.split('/')[0] === 'audio') {
                    isValidToAddAudio = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
            });
          }

          if (this.filePosts.length > 0) {
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                isValidToAddAssets = false;
                //LoadingService.$(false);
                //return;
              }

              if (x.type.split('/')[0] === 'audio') {
                isValidToAddAudio = false;
                //LoadingService.$(false);
                //return;
              }

              if (!isValidToAddAssets && !isValidToAddAudio) {
                this.showErrorMixedFiles = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this._albumService
                  .inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                    },
                  })
                  .pipe(
                    mergeMap(() =>
                      this.addMediaOnCommentAsset(this.currentReplyComment)
                    ),
                    mergeMap(() =>
                      this.getCommentAlbumAssetById(this.currentReplyComment)
                    ),
                    tap((res) => {
                      var index = this.commentAsset.findIndex(
                        (x) => x.id === res.data!.id!
                      );
                      if (index > -1) {
                        if (this.currentReplyComment.id == res.data?.id!) {
                          this.commentAsset[index] = res.data!;
                          //this.currentReplyComment = res.data!;
                        } else {
                          var indexChild = res.data?.inverseParent!.findIndex(
                            (x) => x.id === this.currentReplyComment.id!
                          )!;

                          if (indexChild > -1) {
                            this.commentAsset[index].inverseParent![
                              indexChild!
                            ] = res.data?.inverseParent![indexChild!]!;
                            //this.currentReplyComment = res.data?.inverseParent![indexChild!]!
                          }
                        }
                      }
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    (this.content = ''), (this.isHaveParent = false);
                    this.urls = [];
                    this.filePosts = [];
                    this.urlAudio = '';
                    this.blobFileAudio = new Blob();
                    this.currentReplyComment = {};
                    this.isEditComment = false;
                    this.showEditRule = false;
                    //this.getCommentByAssetId();
                    LoadingService.$(false);
                    //this.refresh.emit();
                  });
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
        }
        return;
      }

      if (
        this.typeOfComment == 'ONLY_MEDIAS' ||
        this.typeOfComment == 'ONLY_AUDIO'
      ) {
        if (this.typeOfComment == 'ONLY_AUDIO') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        } else if (this.typeOfComment == 'ONLY_MEDIAS') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        }

        if (
          this.showErrorLimitSizeFile ||
          this.showErrorMaximumFiles ||
          this.showErrorDuration
        ) {
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        } else {
          if (
            this.filesDelete.length!! +
            this.filePosts.length ==
            0
          ) {
            this.showEditRule = true;
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          }

          if (this.countClicked == 1) {
            if (this.filePosts.length >= 0 || this.urlAudio) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this.addMediaOnCommentAsset(this.currentReplyComment)
                  .pipe(
                    mergeMap(() =>
                      this.getCommentAlbumAssetById(this.currentReplyComment)
                    ),
                    tap((res) => {
                      var index = this.commentAsset.findIndex(
                        (x) => x.id === res.data!.id!
                      );
                      if (index > -1) {
                        if (this.currentReplyComment.id == res.data?.id!) {
                          this.commentAsset[index] = res.data!;
                          //this.currentReplyComment = res.data!;
                        } else {
                          var indexChild = res.data?.inverseParent!.findIndex(
                            (x) => x.id === this.currentReplyComment.id!
                          )!;

                          if (indexChild > -1) {
                            this.commentAsset[index].inverseParent![
                              indexChild!
                            ] = res.data?.inverseParent![indexChild!]!;
                            //this.currentReplyComment = res.data?.inverseParent![indexChild!]!
                          }
                        }
                      }
                    }),
                    tap((res) => {
                      this.content = '';
                      this.isHaveParent = false;
                      this.urls = [];
                      this.filePosts = [];
                      this.urlAudio = '';
                      this.currentReplyComment = {};
                      this.isEditComment = false;
                      this.showEditRule = false;
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    LoadingService.$(false);
                  });
              }
            } else {
              this.content = '';
              this.isHaveParent = false;
              this.urls = [];
              this.filePosts = [];
              this.urlAudio = '';
              this.currentReplyComment = {};
              this.isEditComment = false;
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            }
          }
        }
        return;
      }

      if (this.typeOfComment == 'TEXT_AND_MEDIAS') {
        if (
          this.content &&
          this.filePosts?.length! +
          this.filesDelete.length! >
          0
        ) {
          this.showEditRule = false;

          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._albumService
                  .inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap(() =>
                      this.addMediaOnCommentAsset(this.currentReplyComment)
                    ),
                    mergeMap(() =>
                      this.getCommentAlbumAssetById(this.currentReplyComment)
                    ),
                    tap((res) => {
                      var index = this.commentAsset.findIndex(
                        (x) => x.id === res.data!.id!
                      );
                      if (index > -1) {
                        if (this.currentReplyComment.id == res.data?.id!) {
                          this.commentAsset[index] = res.data!;
                          //this.currentReplyComment = res.data!;
                        } else {
                          var indexChild = res.data?.inverseParent!.findIndex(
                            (x) => x.id === this.currentReplyComment.id!
                          )!;

                          if (indexChild > -1) {
                            this.commentAsset[index].inverseParent![
                              indexChild!
                            ] = res.data?.inverseParent![indexChild!]!;
                            //this.currentReplyComment = res.data?.inverseParent![indexChild!]!
                          }
                        }
                      }
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    this.countClicked = 0;
                    (this.content = ''), (this.isHaveParent = false);
                    this.urls = [];
                    this.filePosts = [];
                    this.urlAudio = '';
                    this.blobFileAudio = new Blob();
                    this.currentReplyComment = {};
                    this.isEditComment = false;
                    this.showEditRule = false;
                    //this.getCommentByAssetId();
                    LoadingService.$(false);
                    //this.refresh.emit();
                  });
                return;
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeOfComment == 'TEXT_AND_AUDIO') {
        if (
          this.content &&
          this.filePosts.length! +
          this.filesDelete.length! >
          0
        ) {
          this.showEditRule = false;
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._albumService
                  .inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap(() =>
                      this.addMediaOnCommentAsset(this.currentReplyComment)
                    ),
                    mergeMap(() =>
                      this.getCommentAlbumAssetById(this.currentReplyComment)
                    ),
                    tap((res) => {
                      var index = this.commentAsset.findIndex(
                        (x) => x.id === res.data!.id!
                      );
                      if (index > -1) {
                        if (this.currentReplyComment.id == res.data?.id!) {
                          this.commentAsset[index] = res.data!;
                          //this.currentReplyComment = res.data!;
                        } else {
                          var indexChild = res.data?.inverseParent!.findIndex(
                            (x) => x.id === this.currentReplyComment.id!
                          )!;

                          if (indexChild > -1) {
                            this.commentAsset[index].inverseParent![
                              indexChild!
                            ] = res.data?.inverseParent![indexChild!]!;
                            //this.currentReplyComment = res.data?.inverseParent![indexChild!]!
                          }
                        }
                      }
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    (this.content = ''), (this.isHaveParent = false);
                    this.urls = [];
                    this.filePosts = [];
                    this.urlAudio = '';
                    this.blobFileAudio = new Blob();
                    this.currentReplyComment = {};
                    this.isEditComment = false;
                    this.showEditRule = false;
                    //this.getCommentByAssetId();
                    LoadingService.$(false);
                    //this.refresh.emit();
                  });
                return;
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }
    } else {
      var isCheckAudio = false;
      var isCheckMedia = false;

      var mentionIds: MentionReceiver[] = []

      if (this.urls.length > 4) {
        this.countClicked = 0;
        this.showErrorMaximumFiles = true;
        LoadingService.$(false);
        //return;
      } else {
        this.showErrorMaximumFiles = false;
        //LoadingService.$(false);
      }

      for (let index = 0; index < this.urls.length; index++) {
        /*check mixed file */
        if (this.urls[index].format === 'audio') {
          isCheckAudio = true;
        }
        if (
          this.urls[index].format === 'image' ||
          this.urls[index].format === 'video'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          this.countClicked = 0;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          return;
        }

        /* check Duration */
        if (
          typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
          this.urls[index].file.type.split('/')[0] === 'video' ||
          this.urls[index].file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(this.urls[index].file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              console.log(f_duration);

              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                //LoadingService.$(false);
                //return;
              }
            });
        }
      }

      if (this.content) {
        let listMentions = this.content.match(MENTION_REGEX);

        this.listMention
          .filter((user) =>
            listMentions
              ?.map((value) => value.replace('@', '').replaceAll(' ', ''))
              .includes(user.username.replaceAll(' ', ''))
          )!
          .forEach((value) => mentionIds.push({
            id: value.id,
            name: value.name
          }))! || [];
      }

      if (
        this.showErrorLimitSizeFile ||
        this.showErrorMaximumFiles ||
        this.showErrorDuration
      ) {
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        if (this.countClicked == 1) {
          this._albumService
            .inlivewithAlbumCreateCommentOnAlbumAssetPost$Json({
              body: {
                AuthorId: AuthService.GetAccountInfo().id!,
                Message: this.content,
                MediaCommentFiles: [],
                AlbumAssetId: this.asset.id!,
                AlbumId: this.asset.albumId!,
                MediaFileNames: this.filePosts.map(x => x.name!)
              },
            })
            .pipe(
              tap((res: CreateCommentAlbumAssetResponseModelResponseModel) => this.addMentionReceivers(mentionIds, res.data?.id!)),
              mergeMap((res) => forkJoin([this.updateAsset(), this.filePosts.length! > 0 ? this.uploadMedia(this.filePosts, res.data?.mediaCreateResponseModels!) : of(null)])),
              tap(res => {
                if (res[0].res == 0) {
                  this.commentAsset = res[0].data?.page!;
                  (this.content = ''), (this.isHaveParent = false);
                  this.urls = [];
                  this.filePosts = [];
                  this.urlAudio = '';
                  this.blobFileAudio = new Blob();
                  this.currentReplyComment = {};
                  this.isEditComment = false;

                }
              })
            )
            .subscribe(() => {
              this.countClicked = 0;
              LoadingService.$(false);
              //this.refresh.emit();
            });
        }
      }
    }
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }

  updateAsset() {
    return this._albumService.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json(
      {
        albumAssetId: this.asset.id!,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 10,
        page: 1,
      }
    );
  }

  uploadMedia(files: any[], mediaCreateCommentAlbum: MediaCreateResponseModel[]) {
    let obs: Observable<any>[] = [];
    files.map((file, index) => {
      obs.push(
        this._uploadService.AddMediaPosts(file, mediaCreateCommentAlbum[index].path!, mediaCreateCommentAlbum[index].hook!, mediaCreateCommentAlbum[index].id!)
      )
    })

    return forkJoin(obs);
  }

  checkTypeMedia(asset: MediaCommentAlbumAsset): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url!) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaCommentAlbumAsset) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  deleteCommentAsset(comment: CommentAlbumAssetResponseModel) {
    this._albumService
      .inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json({
        commentId: comment.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
          }
        }),
        mergeMap((res) => this.updateAsset()),
        tap((res) => {
          if (res.res == 0) {
            this.commentAsset = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  sanitizeChild() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudioChild);
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio = new Blob();
  }

  removeAudioChild() {
    this.urlAudioChild = '';
    this.blobFileAudioChild = new Blob();
  }

  startRecord(isParent: boolean) {
    if (isParent) {
      this.openPopupRecordAudioChild = true;
      this.urlAudioChild = '';
      this.contentReply = '';
    } else {
      this.content = '';
      this.urlAudio = '';
      this.openPopupRecordAudio = true;
    }
  }

  getCommentByAssetId() {
    return this._albumService
      .inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json(
        {
          albumAssetId: this.asset.id!,
          accountRequestId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.commentAsset = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  getCommentAlbumAssetById(commentAlbumAsset: CommentAlbumAssetResponseModel) {
    return this._albumService.inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json(
      {
        accountRequestId: AuthService.GetAccountInfo().id!,
        clubId: this.clubId!,
        commentAlbumAssetId: commentAlbumAsset.parentId
          ? commentAlbumAsset.parentId
          : commentAlbumAsset.id!,
      }
    );
  }

  actionDelete(media: MediaCommentAlbumAsset) {
    this.filesEdit.push(media);
    this.filesDelete = this.filesDelete.filter(x => x.id! !== media.id!);
  }

  deleteFromComment() {
    if (this.filesEdit.length > 0) {
      let stringIds: string[] = [];
      this.filesEdit.map(x => stringIds.push(x.id));
      this._albumService
        .inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json({
          body: {
            commentAlbumAssetId: this.filesDelete[0]?.commentAlbumAssetId! ?? this.filesEdit[0]?.commentAlbumAssetId!,
            mediaOnCommentAlbumAssetIds: stringIds,
          },
        })
        .pipe(
          mergeMap(() =>
            this.getCommentAlbumAssetById(this.currentReplyComment!)
          ),
          tap((res) => {
            this.filesDelete = [];
            this.filesEdit = [];
            var index = this.commentAsset.findIndex(
              (x) => x.id === res.data!.id!
            );
            if (index > -1) {
              if (this.currentReplyComment.id == res.data?.id!) {
                this.commentAsset[index] = res.data!;
                this.currentReplyComment = res.data!;
              } else {
                var indexChild = res.data?.inverseParent!.findIndex(
                  (x) => x.id === this.currentReplyComment.id!
                )!;

                if (indexChild > -1) {
                  this.commentAsset[index].inverseParent![indexChild!] =
                    res.data?.inverseParent![indexChild!]!;
                  this.currentReplyComment =
                    res.data?.inverseParent![indexChild!]!;
                }
              }
            }

            this.commentAsset.forEach((c) => {
              c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
            });
          })
        )
        .subscribe();
    }

  }

  addMediaOnCommentAsset(comment: MediaCommentAlbumAsset) {
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts = [];
      this.filePosts.push(this.blobFileAudio);
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild = [];
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    return this._albumService.inlivewithAlbumCreateMediaCommentOnAlbumAssetPost$Json(
      {
        albumId: this.asset.albumId!,
        albumAssetId: this.asset.id!,
        clubId: this.clubId!,
        commentAlbumAssetId: comment.id!!,
        body: this.isHaveParent
          ? this.filePostsChild.map(x => x.name!)
          : this.filePosts.map(x => x.name!),
      }
    ).pipe(
      map(res => {
        let obs: Observable<any>[] = [];
        if (this.isHaveParent) {
          this.filePostsChild.forEach((file, index) => {
            obs.push(
              this._uploadService.AddMediaPosts(file, res.data![index].path!, res.data![index].hook!, res.data![index].id!)
            )
          })
        } else {
          this.filePosts.forEach((file, index) => {
            obs.push(
              this._uploadService.AddMediaPosts(file, res.data![index].path!, res.data![index].hook!, res.data![index].id!)
            )
          })
        }
        return obs ?? []
      }),
      mergeMap((res) => forkJoin(res))
    );
  }

  checkRoleInClub() {
    return this._accountService
      .inlivewithAccountClubDetailAccountIdClubIdGet$Json({
        clubId: this.clubId!,
        accountId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          this.isAdmin = res.data?.roleInClub == 'ROLE_ADMIN' ? true : false;
        })
      );
  }

  getUrlpopo() {
    return 'data:video/mp4;base64';
  }

  checkTypeToEditComment(comment: CommentAlbumAssetResponseModel) {
    let type = '';
    if (comment.mediaCommentAlbumAssets?.length! > 0) {
      comment.mediaCommentAlbumAssets?.forEach((x) => {
        if (this.checkTypeMedia(x) == 'AUDIO') {
          if (comment.message!) {
            type = 'TEXT_AND_AUDIO';
          } else type = 'ONLY_AUDIO';
        } else if (
          this.checkTypeMedia(x) == 'IMAGE' ||
          this.checkTypeMedia(x) == 'VIDEO'
        ) {
          if (comment.message!) {
            type = 'TEXT_AND_MEDIAS';
          } else type = 'ONLY_MEDIAS';
        }
      });

      if (
        type == 'TEXT_AND_AUDIO' ||
        type == 'ONLY_AUDIO' ||
        type == 'TEXT_AND_MEDIAS' ||
        type == 'ONLY_MEDIAS'
      ) {
        return type;
      } else return 'UNKNOWN';
    } else type = 'TEXT';

    return type;
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  checkProcessMediaFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showMultiMedia(urls: MediaCommentAlbumAssetResponseModel[], index: number) {
    this.multiMedias = urls;
    this.activeIndex = index;
    this.isShowMultiMedia = true;
  }

  checkAllDoneHaveParams(comment: CommentAlbumAssetResponseModel) {
    let result = comment.mediaCommentAlbumAssets?.every((x) =>
      x.url?.includes('?')
    )!;
    return !result;
  }
}
