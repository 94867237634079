<div class="flex mt-3 w-100 align-items-center pb-3 raw-noti cursor-pointer">
    <div class="flex mr-2 w-80">
        <div class="zone-avatar">
            <img [src]="notification!.senderAvatar ?? defaultAvatar" class="fit-size">
        </div>
        <div class="flex ml-2 align-items-center" style="white-space: pre-line">
            <span [innerHTML]="notification!.subTitle!"></span>
        </div>
    </div>

    <div class="flex justify-content-end w-20 fs-16 text-gray">
        {{notification!.updateDate + 'Z' | date: 'dd/MM/yy, HH:mm'}}
    </div>
</div>

<div class="flex" style="margin-left: 60px;">
    <button pButton label="{{'REFUSE' | translate}}"
        class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2" (click)="doAction(false)">
    </button>
    <button pButton label="{{'ACCEPT' | translate}}"
        class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2" (click)="doAction(true)">
    </button>
</div>

<div class="mt-4" style="border-bottom: 1px solid #3B3B3B;"></div>