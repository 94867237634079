<p-dialog [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()" [closable]="true">
    <div class="card">
        <div class="flex flex-column align-items-center justify-content-center text-center">
            
            <div class="flex position-fixed">
                <div class="zone-avt" *ngIf="club && club.avatar">
                    <img [src]="club.avatar | fetchUrlAvatarClub" class="fit-size">
                </div>
            </div>
    
            <div class="label mt-5" *ngIf="club && club.name">
                {{club.name | limitString:false:50}}
            </div>

            <div class="fs-16 text-white flex flex-column text-center mt-3 mb-5">
                <span>
                    {{'CLUB_QUIT_TITLE' | translate}}
                </span>
                <span [innerHTML]="'CLUB_QUIT_SUB_TITLE' | translate"></span>
            </div>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-dialog>
