import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export abstract class LoadingService {
  private static readonly _subject = new ReplaySubject<boolean>();
  private static _loading: boolean = false;

  public static $(value: boolean) {
    this._subject.next(value);
  }

  public static $$(): Observable<boolean> {
    return this._subject.pipe(
      tap((res) => {
        this._loading = res;
      })
    );
  }

  public static IsLoading(): boolean {
    return this._loading;
  }
}
