<div class="container m-2">
    <div class="zone-avt cursor-pointer" [ngClass]="addFriendDirectly ? 'cursor-pointer' : ''" (click)="openProfile()">
        <img [src]="inliver!.avatar! || './assets/images/svgs/avatar-default.svg'" alt="" srcset="" class="fit-size">
    </div>
    <div class="content">
        <div class="name">
            {{inliver!.nickName! | limitString:false:20}}
        </div>
        <div class="text mt-1">
            {{inliver!.totalMutualCluds!}} {{'CLUB_COMMON_BETWEEN_USER' | translate}}
        </div>
    </div>
    <div class="flex btn">
        <button *ngIf="!_router.url.startsWith('/inlivers-suggestion-list')" (click)="openProfile()" pButton
            [label]="'SEE' | translate" class="p-button-outlined p-button-secondary">
        </button>
        <button *ngIf="_router.url.startsWith('/inlivers-suggestion-list')" (click)="handle()" pButton
            [label]="'ADD' | translate" class="p-button-outlined p-button-secondary">
        </button>
    </div>
</div>