import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountResponseGeneral } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-card-account-friend-confirm',
  templateUrl: './card-account-friend-confirm.component.html',
  styleUrls: ['./card-account-friend-confirm.component.scss']
})
export class CardAccountFriendConfirmComponent implements OnInit {

  @Input() isShowPopup!: boolean;
  @Input() title: string = '';
  @Input() firstButtonTitle: string = '';
  @Input() secondButtonTitle: string = '';
  @Input() account!: AccountResponseGeneral
  @Input() isFriend!: boolean
  @Input() isMobile!: boolean

  @Output() isShowPopupChange = new EventEmitter<boolean>();
  @Output() doAction = new EventEmitter();

  defaultAvatar = defaultAvatar

  constructor() { }

  ngOnInit(): void {
  }

  onHide() {
    this.isShowPopupChange.emit(this.isShowPopup);
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
  }

}
