<p-dialog [(visible)]="showPopup" [draggable]="false" [resizable]="false" [closable]="true" [modal]="true"
    (onHide)="close()">
    <section>
        <div class="title-create-post" *ngIf="!isAdmin">
            <span>
                {{'CREATE_A_POST' | translate}}
            </span>
        </div>
        <div class="title-create-post" *ngIf="isAdmin">
            <span>
                {{'WHAT_YOUR_CHOICE' | translate}}
            </span>
        </div>
        <div class="list-type" *ngIf="isAdmin">
            <div class="type-item" [class.activePublication]="typePost === PostEnum.PUBLICATION"
                (click)="typePost = PostEnum.PUBLICATION; content = ''; metaDataItem = {}; initConfigMention()">
                <span class="title">{{'POST_TYPE_PUBLICATION_TITLE' | translate}}</span>
                <span class="content">{{'POST_TYPE_PUBLICATION_CONTENT' | translate}}</span>
            </div>
            <div class="type-item" [class.activeDiscussion]="typePost === PostEnum.DISCUSSION"
                (click)="typePost = PostEnum.DISCUSSION; content = ''; metaDataItem = {}; initConfigMention()">
                <span class="title">{{'POST_TYPE_DISCUSSION_TITLE' | translate}}</span>
                <span class="content">{{'POST_TYPE_DISCUSSION_CONTENT' | translate}}</span>
            </div>
        </div>
        <div class="mt-5 flex flex-column" *ngIf="typePost === PostEnum.DISCUSSION && isAdmin"
            style="position: relative;">
            <label for="title">{{'POST_CREATE_TITLE_LABEL' | translate}}</label>
            <input class="input-custom" type="text" id="title" [placeholder]="'POST_PLACEHOLDER_TITLE' | translate"
                [(ngModel)]="title">
            <span class="text-regular"
                style="position: absolute; font-size: 14px; color: rgba(255, 255, 255, 0.3); right: 0; bottom: 10px;">
                {{getCharacterLeft().numberLeft}}
            </span>
        </div>
        <div *ngIf="!checkInvalidTitle()" class="mt-2">
            <span class="text-error">
                {{'LIMIT_TEXT_TITLE_DISCUSSION' | translate}}
            </span>
        </div>
        <div class="mt-5 flex flex-column">

            <label for="">{{'POST_CREATE_MESSAGE_LABEL' | translate}}</label>
            <div class="flex flex-column mt-2 input-text"
                *ngIf="stages && currentStage && isJoined && isHasAccess && !applyRole">
                <div class="flex flex-wrap " [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                    <div class="zone-media-cus flex" *ngFor="let url of sortFiles(urls); let i = index">
                        <img [src]="url.data" *ngIf="url.format === 'image' && url">

                        <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                            <ng-container *ngIf="isChrome">
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-container>
                            <ng-container *ngIf="!isChrome">
                                <div
                                    class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                    <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                    <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                        {{url.file.name}}</div>
                                    <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-template #playerVideo>
                            <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                controls></video>
                        </ng-template>

                        <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                            controls></audio>

                        <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                            *ngIf="(url.format !== 'audio' && url.format !== 'video' && url.format !== 'image') || url.format == ''">
                            <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}</div>
                        </div>

                        <div class="delete-asset" (click)="removeAsset(url.id)">
                            <i class="pi pi-times-circle"></i>
                        </div>

                    </div>
                </div>

                <div class="fs-14 p-2 flex align-items-center justify-content-start" *ngIf="typePost === PostEnum.DISCUSSION && getCharacterLeft().isFullCharContent">
                    <img class="mr-2" src="../../../../assets/images/svgs/attention.svg">
                    <span class="text-error">
                        {{'LIMIT_CHARACTERS' | translate}} {{content.length! + '/' + 500}}
                    </span>
                </div>

                <div class="text-error flex flex-column align-items-start" style="padding: 1.5rem;"
                    *ngIf="showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile">
                    {{'LIST_ITEM_REASON' | translate}}
                    <div class="text-error" *ngIf="showErrorMixedFiles">
                        {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorDuration">
                        {{'DURATION_MAX_4MINS' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorMaximumFiles">
                        {{'MAXIMUM_4_MEDIA' | translate}}
                    </div>

                    <div class="text-error" *ngIf="showErrorLimitSizeFile">
                        {{'FILE_SIZE_LIMIT_POST' | translate}}
                    </div>
                </div>

                <div style="position: relative;">

                    <ng-template #mentionListTemplate let-item="item">
                        <div class="flex align-items-center">
                            <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40" height="40"
                                style="border-radius: 5px;">
                            <span class="p-1">{{item.name! | limitString:false:20}}</span>
                        </div>
                    </ng-template>

                    <!-- <ng-container *ngIf="openPopupRecordAudio">
                        <div class="flex p-2 w-100">
                            <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'48vw'"
                                [widthAudio]="'42vw'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                                [isShowSend]="checkInvalidTitle()" (sendAudio)="createPost()"></app-record-audio-v2>
                        </div>
                    </ng-container> -->

                    <textarea class="textarea-cus w-100" style="resize: none;" *ngIf="!openPopupRecordAudio" [rows]="1"
                        [(ngModel)]="content" [mentionConfig]="mentionConfig" (ngModelChange)="detect()"
                        [mentionListTemplate]="mentionListTemplate" (itemSelected)="itemSelected($event)"
                        [placeholder]="(typePost == PostEnum.DISCUSSION ? 'POST_PLACEHOLDER_MESSAGE' : 'POST_PLACEHOLDER') | translate"></textarea>

                    <div *ngIf="metaDataItem && metaDataItem.domain! && typePost === PostEnum.PUBLICATION" class="zone-metadata" (click)="gotoUrl()">
                        <div class="zone-image-metadata" *ngIf="metaDataItem.image != ''">
                            <img [src]="metaDataItem.image" class="fit-size">
                        </div>
                        <div class="zone-content-metadata">
                            <span class="domain">
                                {{metaDataItem.domain}}
                            </span>
                            <span class="title">
                                {{metaDataItem.title}}
                            </span>
                        </div>
                    </div>

                    <div class="flex justify-content-end" *ngIf="!openPopupRecordAudio">
                        <div class="cursor-pointer flex align-items-end pb-3">
                            <img class="cursor-pointer mr-3" src="../../../../assets/images/svgs/emojis.svg" alt=""
                                srcset="" height="16" (click)="changeShowEmoji($event)">
                            <!-- <img class="mr-3" src="/assets/images/svgs/mic.svg" height="16" (click)="startRecord()"
                                *ngIf="!urls.length && content?.length! == 0"> -->
                            <i class="pi pi pi-image mr-3" (click)="inputFile.click()" *ngIf="!urlAudio"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-error" *ngIf="isErrorFile">
                {{'UNDEFINED_TYPE_FILE' | translate}}
            </div>

            <div class="text-error" *ngIf="showErrorLimitSizeFile">
                {{'FILE_SIZE_LIMIT_POST' | translate}}
            </div>
            <div class="mt-4 flex align-items-center justify-content-center"
                *ngIf="typePost === PostEnum.DISCUSSION && isAdmin">
                <button pButton label="{{'CANCEL' | translate}}"
                    class="p-button-outlined p-button-secondary btn-white mr-3" (click)="close()"></button>
                <button pButton type="submit"
                    [disabled]="!(checkShowIconCreatePost() && checkInvalidTitle() && !this.getCharacterLeft().isFullCharContent) || (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles  || showErrorLimitSizeFile)"
                    label="{{'LAUNCH_POST_DISCUSSION' | translate}}" (click)="createPost()"
                    class="p-button-outlined p-button-secondary btn-black ml-3"></button>
            </div>

            <div class="mt-4 flex align-items-center justify-content-center" *ngIf="typePost === PostEnum.PUBLICATION">
                <button pButton label="{{'CANCEL' | translate}}"
                    class="p-button-outlined p-button-secondary btn-white mr-3" (click)="close()"></button>
                <button pButton type="submit"
                    [disabled]="!(checkShowIconCreatePost() && checkInvalidTitle()) || (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles  || showErrorLimitSizeFile)"
                    label="{{'PUBLISH' | translate}}" (click)="createPost()"
                    class="p-button-outlined p-button-secondary btn-black ml-3"></button>
            </div>

        </div>
        <div #toggleEmoji (click)="clickOnSide($event)">
            <emoji-mart [isNative]="true" class="emoji emoji-media" [enableSearch]="false" *ngIf="showEmoji"
                [sheetSize]="32" (emojiClick)="addEmoji($event)"></emoji-mart>
        </div>
        <div id="zoneAudio"></div>
    </section>
</p-dialog>
<input style="display: none" id="file-avatar" type="file" #inputFile multiple (change)="detectFiles($event)">