import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {
  ClubResponseInPostCelebrity,
  ClubResponseInPostCelebrityPaginationModel,
  GoInliveResponseModelWithClubDetail,
} from 'src/app/core/api/be/models';
import { GoInliveService, PostService } from 'src/app/core/api/be/services';
import { ILWAppEnum } from 'src/app/core/constant';

@Component({
  selector: 'app-list-clubs-celeb-post',
  templateUrl: './list-clubs-celeb-post.component.html',
  styleUrls: ['./list-clubs-celeb-post.component.scss'],
})
export class ListClubsCelebPostComponent implements OnInit {
  @Input() clubs: ClubResponseInPostCelebrity[] = [];
  @Input() showListClubsPosted: boolean = false;
  @Input() postCelebId!: string;

  @Output() showListClubsPostedChange = new EventEmitter();

  searchText: string = '';
  openPopupAcceptRuleClub = false;
  currentClubCeleb!: ClubResponseInPostCelebrity;

  listClubsJoined: ClubResponseInPostCelebrity[] = [];
  listClubsHaventJoined: ClubResponseInPostCelebrity[] = [];

  @Input() fromGoInliveCeleb: boolean = false;
  @Input() live!: GoInliveResponseModelWithClubDetail;
  clubIdSelected!: string;
  topClub!: ClubResponseInPostCelebrity[];

  constructor(
    private _router: Router,
    private _goInliveService: GoInliveService,
    private _postService: PostService
  ) { }

  ngOnInit(): void {
    if (this.fromGoInliveCeleb == true) {
      this.getGoInliveById();
    } else {
      // this.listClubsJoined = this.clubs.filter((x) => x.isJoinedClub);
      // this.listClubsHaventJoined = this.clubs.filter((x) => !x.isJoinedClub);
      this.getClubByPostCelebId();
    }
  }

  onHide() {
    this.showListClubsPostedChange.emit(false);
  }

  onApplyClub(club: ClubResponseInPostCelebrity) {
    if (this.fromGoInliveCeleb) {
      this.gotoClub(club);
    } else {
      if (!club?.isPrivate!) {
        this.openPopupAcceptRuleClub = true;
        this.currentClubCeleb = club;
      } else {
        this.gotoClub(club);
      }
    }
  }

  gotoDetail(club: ClubResponseInPostCelebrity) {
    if (this.fromGoInliveCeleb) {
      this.clubIdSelected = club.id!;
      this.goToViewGoInlive();
      this.onHide();
      return;
    }

    this._router
      .navigate(['manage-club/post-detail'], {
        queryParams: { postId: club.postId!, clubId: club.id!, stageId: club.stageId! },
      })
      .then(() => {
        this.showListClubsPostedChange.emit(false);
      });
  }

  gotoClub(club: ClubResponseInPostCelebrity) {
    if (this.fromGoInliveCeleb) {
      this._router.navigate(['manage-club/detail-club'], {
        queryParams: { clubId: club.id! },
      });
      return;
    }
    if (club.isPrivate) {
      this._router.navigate(['manage-club/detail-club'], {
        queryParams: { clubId: club.id! },
      });
    } else this.gotoDetail(club);
  }

  goToViewGoInlive() {
    this._router.navigate(['go-inlive-view'], {
      queryParams: {
        goInliveId: this.live?.id,
        channel: this.live?.channelName,
        hostUid: this.live?.author?.agoraUid!,
        mode: this.live.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
        type: this.live.type!,
        clubIdSelected: this.clubIdSelected,
        isCelebLive: true,
      },
    });
  }

  getGoInliveById() {
    this._goInliveService
      .inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json({
        goInliveId: this.live.id!,
      })
      .pipe(
        tap((res) => {
          this.clubs = res.data?.clubs!;
          this.listClubsJoined = res.data!.clubs!;
          this.listClubsHaventJoined = res.data!.clubsNotJoined!;
        })
      )
      .subscribe();
  }

  getClubByPostCelebId() {
    let body = {
      postCelebId: this.postCelebId,
      page: 1,
      limit: 3,
      isFilterJoined: true,
    };

    this._postService
      .inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json(body)
      .pipe(
        tap(res => {
          if (res.res == 0) {
            this.topClub = res.data!.topClub!.page!;
            this.listClubsJoined = res.data!.allClub!;
          } else {
            this._router.navigate(['announcement', 'not-found']);
          }
        })
      )
      .subscribe();
  }

  getUrl(asset: any) {
    if (!asset?.includes('?')) {
    } else {
      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!;
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset;
  }

  getIcon(club: ClubResponseInPostCelebrity) {
    let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter(
      (c: any) => c.value == club.category
    )[0]?.url!;
    return iconUrl;
  }
}
