import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { ResultSearchAccountViewModel } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-invalid-popup',
  templateUrl: './account-invalid-popup.component.html',
  styleUrls: ['./account-invalid-popup.component.scss']
})
export class AccountInvalidPopupComponent implements OnInit {

  @Input() accountInvalidPopup: boolean = false;

  @Output() accountInvalidPopupChange = new EventEmitter<boolean>();

  accountIdentity!: ResultSearchAccountViewModel;
  accountILW = AuthService.GetAccountInfo();

  constructor(
    private _accountService: AccountService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  onHide() {
    this.accountInvalidPopupChange.emit(false);
  }

  gotoEdit() {
    this.edit();
  }

  edit() {
    const tab = window.open(
      environment.WEB_PORTAL_URL +
      `/profile/${LocalStorageService.GetLocalStorage('email')}?callback=${window.origin
      }/edit-user&lang=${LocalStorageService.GetLocalStorage(
        'language'
      ).toLowerCase()}`,
      'targetWindow',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=840`
    );

    window.addEventListener('message', (e) => {
      if (e.data === 'close') {
        this.getAccountIdentityByEmail().subscribe(() => {
          this.syncAccountInfo();
        });
      }
    });
  }

  getAccountIdentityByEmail() {
    return this._accountService
      .inlivewithAccountGetAccountIdentityByEmailEmailGet$Json({
        email: LocalStorageService.GetLocalStorage('email'),
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.accountIdentity = res.data!;
          } else {
            this.logout();
          }
        })
      );
  }

  syncAccountInfo() {
    this.accountILW = AuthService.GetAccountInfo();

    if (
      // this.accountILW.lastName !== this.accountIdentity.lastName ||
      // this.accountILW.firstName !== this.accountIdentity.firstName ||
      this.accountILW.nickName !== this.accountIdentity.displayName ||
      // this.accountILW.phoneNumber !== this.accountIdentity.phoneNumber ||
      this.accountILW.gender !== this.accountIdentity.gender ||
      this.accountILW.birthday !== this.accountIdentity.birthDay ||
      this.accountILW.avatar !== this.accountIdentity.avatarUrl
    ) {
      LoadingService.$(true);
      let body = {
        accountId: AuthService.GetAccountInfo()?.id!,
        lastName: this.accountIdentity.lastName,
        firstName: this.accountIdentity.firstName,
        nickName: this.accountIdentity.displayName,
        phoneNumber: this.accountIdentity.phoneNumber!,
        gender: this.accountIdentity.gender || 'GENDER_UNDEFINED',
        avatar: this.accountIdentity!.avatarUrl!
          ? this.accountIdentity.avatarUrl!?.replace(
            environment.STORAGE_URL_IDENTITY,
            ''
          )
          : '',
      };
      this._accountService
        .inlivewithAccountUpdateAccountIdPut$Json({
          accountId: AuthService.GetAccountInfo()?.id!,
          body: body,
        })
        // .pipe(mergeMap(() => this.setBirthday()))
        .subscribe((res) => {
          AuthService.SetAccountInfo(res?.data!);
          this.onHide();
          LoadingService.$(false);
        });
    }
  }

  setBirthday() {
    let body = {
      accountId: AuthService.GetAccountInfo().id!,
      birthday: new Date(this.accountIdentity.birthDay! + 'Z').toISOString(),
    };
    return this._accountService.inlivewithAccountBirthdayAccountIdPut$Json({
      accountId: AuthService.GetAccountInfo()?.id!,
      body: body,
    });
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['explorer']).then(() => {
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

}
