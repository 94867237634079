import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";


import { SideLeftComponent } from './side-left/side-left.component';
import { SideRightComponent } from './side-right/side-right.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";

@NgModule({
  declarations: [LayoutComponent, NavMenuComponent, SideLeftComponent, SideRightComponent],
  imports: [CommonModule, RouterModule, SharedModule, Ng2SearchPipeModule],
  exports: [LayoutComponent],
})
export class LayoutModule { }
