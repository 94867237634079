<p-dialog [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()" [closable]="true">
    <div>
        <div class="flex flex-column justify-content-center">
            <div class="flex justify-content-center">
                <img src="../../../assets/images/svgs/club-private.svg">
            </div>
            <span class="text-white mt-3">
                {{title}}
            </span>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton label="{{'CANCEL' | translate}}" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton label="{{'REQUEST' | translate}}" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="requestAccessLevel()">
            </button>
        </div>
    </div>
</p-dialog>
