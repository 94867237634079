import {
  Component,
  OnInit,
  HostListener,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { filter, mergeMap, tap } from 'rxjs/operators';

import {
  GROUP_ILW_DISCUSSION,
  ILWAppEnum,
  STORAGE_KEY,
  imagesTutorial,
} from './core/constant';
import { LoginService } from './core/services/login.service';
import { RegisterService } from './core/services/register.service';
import { Select, Store } from '@ngxs/store';
import {
  AccountService,
  AppEnumService,
  ClubService,
} from './core/api/be/services';
import { LocalStorageService } from './core/services/local-storage.service';
import { InitClubState } from './core/states/club-state.service';
import {
  AppEnumStateService,
  IAppEnumStateModel,
  InitAppEnumState,
} from './core/states/app-enum-state.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { NotificationServicee } from './core/services/notification.service';
import {
  Account,
  AccountResponseGeneral,
  MessageResponseModel,
} from './core/api/be/models';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { EditUserComponent } from './pages/manage-account/edit-user/edit-user.component';
import jwt_decode from 'jwt-decode';
import { NgWaveformComponent } from 'ng-waveform';
import { EventService, IEvent } from './core/services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showLogin: boolean = false;
  showRegister: boolean = false;
  registerSuccess: boolean = false;
  currentAccount!: AccountResponseGeneral;
  email!: string;
  images = imagesTutorial;
  activeIndex = 0;
  @Select(AppEnumStateService) _state$!: Observable<IAppEnumStateModel>;

  @HostListener('window:resize')
  onResize() {
    this.checkResize(this._router.url);
  }

  data: any;

  @ViewChildren('ng-waveform') waveform!: QueryList<NgWaveformComponent>;

  constructor(
    private _store: Store,
    private _clubService: ClubService,
    private _appEnumService: AppEnumService,
    public _router: Router,
    private _accountService: AccountService,
    private primengConfig: PrimeNGConfig,
    private _signalR: NotificationServicee,
    private messageService: MessageService,
    private _translateService: TranslateService,
    private _notificationService: NotificationServicee,
    public editUser: EditUserComponent,
    private translateService: TranslateService,
    private titleService: Title,
    private el: ElementRef,
    private eventService: EventService
  ) {
    let account!: Account;

    if (LocalStorageService.GetLocalStorage(STORAGE_KEY.ACCOUNT)) {
      account = JSON.parse(
        LocalStorageService.GetLocalStorage(STORAGE_KEY.ACCOUNT)
      );
    }
    if (account) {
      if (account?.isCelebrity) {
        this.navigateToAccountIsCeleb();
        return;
      }
      AuthService.SetAccountInfo(account);
      editUser
        .getAccountIdentityByEmail()
        .subscribe(() => editUser.syncAccountInfo());
    }
    this.handleReceipeDataFromIdentity();
    forkJoin([this._InitAppEnum(), this.getAccountByEmail()]).subscribe();
    this._state$
      .pipe(
        tap((res) => {
          ILWAppEnum.ENUM_ILW = res?.appEnum!;
        })
      )
      .subscribe();
  }

  handleReceipeDataFromIdentity() {
    window.addEventListener('message', (event) => {
      let data: {
        accountId: string;
        token: string;
        email: string;
        isCreated: string;
        confirmEmailUrl: string;
        action: string;
      } = event.data;

      if (data && data.accountId) {
        this.data = data;
        this.checkResize(this._router.url);

        let decoded: object = jwt_decode(data.token);
        let key = Object.keys(decoded).find(
          (k) => k.indexOf('identity/claims/role') >= 0
        );

        if (key) {
          let obj = Object.assign(decoded);
          if (typeof obj[key] == 'string') {
            if (obj[key] === 'celebrities') {
              this.navigateToAccountIsCeleb();
              return;
            }
          } else {
            let roles: string[] = obj[key];
            if (roles.includes('celebrities')) {
              this.navigateToAccountIsCeleb();
              return;
            }
          }
        }

        data.token
          ? LocalStorageService.AddLocalStorage('access_token', data.token!)
          : '';
        data.email
          ? LocalStorageService.AddLocalStorage('email', data.email!)
          : '';
        this.email = LocalStorageService.GetLocalStorage('email');

        data.accountId
          ? LocalStorageService.AddLocalStorage(
            'identityUserId',
            data.accountId!
          )
          : '';

        this._accountService
          .inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json({
            identityUserId: data.accountId,
          })
          .subscribe((res) => {
            if (res.res == 0) {
              AuthService.SetAccountInfo(res?.data!);
              setTimeout(() => {
                window.open(window.origin, '_self');
              }, 500);
            } else {
              this._router.navigate(['complete-account'], {
                queryParams: { data: JSON.stringify(data) },
              });
            }
          });
      }
    });
  }

  getAccountByEmail() {
    let email = LocalStorageService.GetLocalStorage(STORAGE_KEY.EMAIL) || null;
    if (email) {
      return this._accountService
        .inlivewithAccountByEmailEmailGet$Json({ email })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              if (res.data?.isBlocked) {
                this.navigateToAccountIsBlock();
                return;
              }

              if (res.data?.isCelebrity) {
                this.navigateToAccountIsCeleb();
              }

              if (res.data?.isDeleted) {
                localStorage.clear();
                location.reload();
                return;
              }

              AuthService.SetAccountInfo(res.data!);
              this.currentAccount = res.data!;
              if (res.data?.totalNotis != 0)
                this.titleService.setTitle(
                  `(${res.data?.totalNotis}) Inlive With - Réseau Social Musical`
                );
            }
          }),
          mergeMap((res) => {
            if (res.res == 0) {
              return this._accountService.inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json(
                { accountId: res.data?.id!, isOnline: true }
              );
            }
            return of(res);
          })
        );
    }

    return of(null);
  }

  ngOnInit(): void {
    let currentLang = LocalStorageService.GetLocalStorage('language');
    this.primengConfig.ripple = true;
    this.translateService.use(currentLang);
    this.translateService
      .get('primeng')
      .subscribe((res) => this.primengConfig.setTranslation(res));
    this._OnLogin().subscribe();
    this._OnRegister().subscribe();

    if (AuthService.GetAccountInfo() && AuthService.GetAccountInfo().id) {
      this._signalR.init();
      this.connectConversation();
      this.connectNotification();
    }
    this.handleRefreshPage();

    this.eventService
      .receive()
      .pipe(
        filter((p: IEvent) => p.action == 'PLAY_AUDIO'),
        tap((res) => {
          this.handlePlayAudio(res.payload);
        })
      )
      .subscribe();

    this.eventService
      .receive()
      .pipe(
        filter((p: IEvent) => p.action == 'PLAY_VIDEO'),
        tap((res) => {
          this.handlePlayVideo(res.payload);
        })
      )
      .subscribe();
  }

  handleRefreshPage() {
    this._router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        tap((res) => {
          this.checkResize(res.url);
        })
      )
      .subscribe();
  }

  checkResize(router: string) {
    if (router.includes('verify-email-success')) {
      return;
    }

    let a = window.innerWidth;
    let isMobile = router.startsWith('/mobile');

    if (a! < 900) {
      if (isMobile) {
        return;
      }
      this._router.navigate(['mobile'], {
        queryParams: { data: JSON.stringify(this.data) },
      });
    } else {
      if (isMobile) {
        this._router.navigate(['explorer']);
      }
    }
  }

  private _InitClubState() {
    return this._clubService.inlivewithClubsPageGet$Json({ limit: 10000 }).pipe(
      tap((res) => {
        if (res.res == 0) {
          this._store.dispatch(new InitClubState(res.data?.page!));
        }
      })
    );
  }

  private _InitAppEnum() {
    return this._appEnumService.inlivewithAppEnumAllGet$Json().pipe(
      tap((res) => {
        if (res.res == 0) {
          console.log(res);

          this._store.dispatch(new InitAppEnumState(res.data!));
        }
      })
    );
  }

  private _OnLogin() {
    return LoginService.$$().pipe(
      tap((res) => {
        this.showLogin = res;
      })
    );
  }

  private _OnRegister() {
    return RegisterService.$$().pipe(
      tap((res) => {
        this.showRegister = res;
      })
    );
  }

  connectConversation() {
    this._signalR.connectHub();
    this._signalR.onConversation(this.receiveMessage.bind(this));
  }

  connectNotification() {
    this._signalR.onNotification(this.showNotification.bind(this));
    this._signalR.conected$.subscribe(() => {
      this._signalR.joinGroup(GROUP_ILW_DISCUSSION);
    });
  }

  receiveMessage(data: any) {
    console.log('====>>> onConversation:', data);

    if (data.senderId && data.senderId !== AuthService.GetAccountInfo().id) {
      this._notificationService.isReloadConversation$$.next();
      this._notificationService.isReloadNotiIsRead$$.next(true);

      if (this._router.url.includes('go-inlive')) {
        return;
      }

      if (data.type == 'MESSAGE_TEXT') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_MEDIA') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_RECEIVE_IMAGE') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_RECEIVE_VIDEO') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_RECEIVE_VOCAL') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_RECEIVE_TEXT_FRIEND') {
        this.showMessage(data, data.bodyNoti!);
      }
      if (data.type == 'MESSAGE_RECEIVE_TEXT_NOT_FRIEND') {
        this.showMessage(data, data.bodyNoti!);
      }
    }
  }

  showMessage(data: any, detail: string) {
    this.messageService.clear();

    this.messageService.add({
      severity: 'success',
      // summary: this._translateService.instant('YOU_HAVE_NEW_MESSAGE'),
      detail: detail,
      key: 'message',
      closable: false,
      data: data,
      life: 3 * 1000,
    });
  }

  showNotification(data: any) {
    console.log(data);

    if (!data!.name!) {
      return;
    }

    if (
      data!.name! == 'NOTI_GOINLIVE_END' &&
      this._router.url.includes('isCelebLive=true') &&
      this._router.url.includes(JSON.parse(data?.content!)!.Content)
    ) {
      location.reload();
    }

    if (
      data!.name! == 'NOTI_GOINLIVE_INVITATION' ||
      data!.name! == 'NOTI_CELEBRITY_JOINING_GOINLIVE' ||
      data!.name! == 'NOTI_GOINLIVE_CELEBRITY_QUIT'
    ) {
      this.showNewNotification(
        'notification',
        JSON.parse(data?.content!)!.Title!,
        data
      );
    }

    if (data!.name! == 'NOTI_GOINLIVE_END') {
      this.showNewNotification(
        'notification',
        JSON.parse(data?.content!)!.Title!,
        data
      );
    }

    if (data!.name! == 'NOTI_GOINLIVE_CREATE') {
      this.showNewNotification(
        'notification',
        JSON.parse(data?.content!)!.Title!,
        data
      );
    }

    switch (data!.name!) {
      case 'NOTI_GOINLIVE_VIEWER_QUIT':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_VIEWER_ENTER':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_VIEW_ACCEPT_PARTICIPANT':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_VIEW_DECLINE_PARTICIPANT':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_CELEBRITY_JOINING_GOINLIVE':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_CELEBRITY_QUIT':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      default:
        break;
    }

    if (this._router.url.includes('go-inlive')) {
      return;
    }

    if (data!.name!) {
      this._notificationService.isReloadNoti$$.next(true);
      this._notificationService.isReloadNotiIsRead$$.next(true);
    }

    switch (data!.name!) {
      case 'NOTI_MESSAGE_RECEIVE':
        break;
      case 'NOTI_POST_FILE_PROCESS_FAIL':
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_POST_FILE_PROCESS_DONE':
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_COMMENT_FILE_PROCESS_DONE':
        this._notificationService.isReloadCommentFileProcess$$.next(data);
        break;
      case 'NOTI_COMMENT_FILE_PROCESS_FAIL':
        this._notificationService.isReloadCommentFileProcess$$.next(data);
        break;
      case 'NOTI_COMMENT_POST':
        this._notificationService.isReloadComment$$.next(data);
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_CLUB_FILE_PROCESS_DONE':
        this._notificationService.isReloadClub$$.next(true);
        break;
      case 'NOTI_CLUB_FILE_PROCESS_FAIL':
        this._notificationService.isReloadClub$$.next(true);
        break;
      case 'NOTI_EVENT_FILE_PROCESS_DONE':
        const eventId: string = JSON.parse(data?.content!)!.Content! || '';
        this._notificationService.isReloadEvent$$.next(eventId);
        break;
      case 'NOTI_EVENT_FILE_PROCESS_FAIL':
        const eventId1: string = JSON.parse(data?.content!)!.Content! || '';
        this._notificationService.isReloadEvent$$.next(eventId1);
        break;
      case 'NOTI_COMMENT_ALBUM_FILE_PROCESS_DONE':
        this._notificationService.isReloadCommentAlbum$$.next(true);
        break;
      case 'NOTI_COMMENT_ALBUM_FILE_PROCESS_FAIL':
        this._notificationService.isReloadCommentAlbum$$.next(true);
        break;
      case 'NOTI_MESSAGE_FILE_PROCESS_DONE':
        const messageId: string = JSON.parse(data?.content!)!.Content! || '';
        this._notificationService.isReloadChat$$.next(messageId);
        break;
      case 'NOTI_MESSAGE_FILE_PROCESS_FAIL':
        const messageId1: string = JSON.parse(data?.content!)!.Content! || '';
        this._notificationService.isReloadChat$$.next(messageId1);
        break;
      case 'NOTI_AVATAR_FILE_PROCESS_DONE':
        // this.refreshAccountByEmail();
        break;
      case 'NOTI_ALBUMASSET_FILE_PROCESS_DONE':
        this._notificationService.isReloadAlbum$$.next(true);
        break;
      case 'NOTI_ALBUMASSET_FILE_PROCESS_FAIL':
        this._notificationService.isReloadAlbum$$.next(true);
        break;
      case 'NOTI_COVERIMAGE_ALBUM_PROCESS_DONE':
        this._notificationService.isReloadAvatarAlbum$$.next(true);
        break;
      case 'NOTI_COVERIMAGE_ALBUM_PROCESS_FAIL':
        this._notificationService.isReloadAvatarAlbum$$.next(true);
        break;
      case 'DENY_INVITE_GOINLIVE':
        this._notificationService.isReloadListInviteGoInlive$$.next(true);
        break;
      case 'NOTI_COMMENT_POST_DISCUSSION':
        this._notificationService.isShowNotificationDiscussion$$.next(data);
        this._notificationService.isReloadComment$$.next(data);
        break;
      case 'NOTI_GOINLIVE_INVITATION':
        this._notificationService.isReloadGoInlive$$.next(true);
        break;
      case 'NOTI_CREATE_NEW_POST':
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_CELEB_CREATE_NEW_POST':
        this._notificationService.isReloadPostCeleb$$.next(data);
        break;
      case 'NOTI_REACT_POST':
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_GOINLIVE_CREATE':
        this._notificationService.isReloadGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_CELEBRITY_CREATE':
        this._notificationService.isReloadGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_END':
        this._notificationService.isReloadGoInlive$$.next(true);
        break;
      case 'NOTI_GOINLIVE_CELEBRITY_END':
        this._notificationService.isReloadGoInlive$$.next(true);
        break;
      case 'NOTI_ACCOUNT_BANNED_FROM_CLUB':
        const clubId: string = JSON.parse(data?.club!)!.Id! || '';
        this._notificationService.isReloadClubAfterBanned$$.next(clubId);
        break;
      case 'DELETE_POST_CELEB':
        this._notificationService.isReloadPostCeleb$$.next(true);
        break;
      case 'ADMIN_CREATE_NEW_POST':
        this._notificationService.isReloadPostCeleb$$.next(data);
        break;
      default:
        break;
    }

    if (
      data!.name! == 'NOTI_ACCEPTED_ADD_FRIEND_REQUEST' ||
      data!.name! == 'NOTI_ADD_FRIEND_REQUEST' ||
      data!.name! == 'NOTI_REQUEST_CLUB' ||
      data!.name! == 'NOTI_REQUEST_ACCESSLEVEL' ||
      data!.name! == 'NOTI_APPROVAL_JOIN_PRIVATECLUB' ||
      data!.name! == 'NOTI_APPROVAL_UPGRADE_ACCESSLEVEL' ||
      data!.name! == 'NOTI_ADMIN_INVI_ACCOUNT_JOIN_CLUB' ||
      data!.name! == 'NOTI_CHANGE_INFOR_CLUB' ||
      data!.name! == 'NOTI_GRANT_ADMIN_CLUB' ||
      data!.name! == 'NOTI_GRANT_INLIVER_PLUS' ||
      data!.name! == 'NOTI_REVOKE_INLIVER_PLUS' ||
      data!.name! == 'NOTI_CHANGE_POLICY_CLUB' ||
      data!.name! == 'NOTI_REVOKE_ACCOUNT_BANNED_CLUB' ||
      data!.name! == 'NOTI_EDIT_CLUB_NOTIFICATION' ||
      data!.name! == 'NOTI_REPLY_COMMENT_POST' ||
      data!.name! == 'NOTI_CREATE_NEW_POST_DISCUSSION' ||
      data!.name! == 'NOTI_POST_DISCUSTION_END' ||
      data!.name! == 'NOTI_CREATE_EVENT_FOR_USER' ||
      data!.name! == 'NOTI_CREATE_EVENT_FOR_ADMIN' ||
      data!.name! == 'NOTI_CHANGE_EVENT_INFORMATION' ||
      data!.name! == 'NOTI_ACCOUNT_JOIN_PUBLIC_CLUB' ||
      data!.name! == 'NOTI_ACCEPT_INVI_CLUB' ||
      data!.name! == 'NOTI_CREATE_EVENT_PUBLIC' ||
      data!.name! == 'NOTI_MENTION' ||
      data!.name! == 'NOTI_INVI_CLUB' ||
      data!.name! == 'NOTI_CREATE_NEW_POST' ||
      data!.name! == 'NOTI_REACT_POST' ||
      data!.name! == 'NOTI_COMMENT_ALBUM_ASSET' ||
      data!.name! == 'NOTI_COMMENT_POST' ||
      data!.name! == 'NOTI_REPORT_IGNORE_TO_REPORTER' ||
      data!.name! == 'NOTI_REPORT_WARN_TO_REPORTER' ||
      data!.name! == 'NOTI_ANNOUCEMENT_CLUB_WARNED_TO_REPORTED' ||
      data!.name! == 'NOTI_ANNOUCEMENT_CLUB_DELETED_TO_REPORTED' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_CLUB' ||
      data!.name! == 'NOTI_WARN_REPORT_CLUB' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_POST' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_COMMENT_POST' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_ALBUM_ASSET' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_COMMENT_ALBUM_ASSET' ||
      data!.name! == 'NOTI_WARN_REPORT_ACCOUNT' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_ACCOUNT' ||
      data!.name! == 'MESSAGE_RECEIVE_VOCAL' ||
      data!.name! == 'MESSAGE_RECEIVE_VIDEO' ||
      data!.name! == 'MESSAGE_RECEIVE_IMAGE' ||
      data!.name! == 'NOTI_ADD_FRIEND_WITHOUT_REQUEST' ||
      data!.name! == 'SUPPER_CLUB_CREATE_STAGE' ||
      data!.name! == 'SUPPER_CLUB_CREATE_ALBUM' ||
      data!.name! == 'REPORT_DELETE_TO_REPORTER' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_CLUB' ||
      data!.name! == 'NOTI_WARN_REPORT_CLUB' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_ACCOUNT' ||
      data!.name! == 'NOTI_WARN_REPORT_ACCOUNT' ||
      data!.name! == 'NOTI_ACCEPTED_REPORT_CONTENT' ||
      data!.name! == 'NOTI_REPORT_IGNORE_TO_REPORTER' ||
      data!.name! == 'NOTI_REPORT_ACCEPTED_TO_REPORTER' ||
      data!.name! == 'NOTI_CELEB_CREATE_NEW_POST' ||
      data!.name! == 'NOTI_POST_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_COMMENT_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_CLUB_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_EVENT_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_COMMENT_ALBUM_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_MESSAGE_FILE_PROCESS_FAIL' ||
      data!.name! == 'NOTI_ALBUMASSET_FILE_PROCESS_FAIL' ||
      data!.name! == 'ADMIN_CREATE_NEW_POST'
    ) {
      this.showNewNotification(
        'notification',
        JSON.parse(data?.content!)!.Title!,
        data
      );
    }

    if (data!.name! == 'NOTI_ACCEPTED_REPORT_ACCOUNT') {
      window.location.reload();
      localStorage.clear();
    }

    if (data!.name! == 'DELETE_ACCOUNT') {
      localStorage.clear();
      this._router.navigate(['explorer']).then(() => {
        setTimeout(() => {
          location.reload();
        }, 500);
      });
    }
  }

  navigateToAccountIsCeleb() {
    localStorage.clear();
    this._router
      .navigate(['announcement', 'account-is-artist'])
      .then(() => location.reload());
  }

  navigateToAccountIsBlock() {
    localStorage.clear();
    this._router
      .navigate(['announcement', 'account-block'])
      .then(() => location.reload());
  }

  showNewNotification(keyMessage: string, detail: string, data: any) {
    this.messageService.add({
      severity: 'success',
      summary: JSON.parse(data?.content!)!.Title!,
      detail: JSON.parse(data?.content!)!.SubTitle!,
      key: keyMessage,
      closable: false,
      data: data,
      life: 3 * 1000,
    });
  }

  // refreshAccountByEmail() {
  //   this._accountService
  //     .inlivewithAccountByEmailEmailGet$Json({
  //       email: AuthService.GetAccountInfo().email!,
  //     })
  //     .subscribe((res) => {
  //       if (res.res == 0) {
  //         AuthService.SetAccountInfo(res?.data!);
  //       }
  //     });
  // }

  close() {
    this.setDoneTutorial();
  }

  setDoneTutorial() {
    this._accountService
      .inlivewithAccountFinishTutorialPut$Json()
      .subscribe(() => (this.currentAccount.isDoneTutorial = true));
  }

  handlePlayAudio(currentId: string) {
    this.stopAllVideo();

    let waves = this.el.nativeElement.getElementsByClassName(
      'zone-audio'
    ) as NodeListOf<HTMLDivElement>;
    if (Array.from(waves).length > 0) {
      Array.from(waves).forEach((res) => {
        if (res.id !== currentId) {
          let btnPause = res.getElementsByClassName('btn-pause');
          if (btnPause.item(0)) {
            let el = btnPause.item(0) as HTMLElement;
            el.click();
          }
        }
      });
    }

    document.getElementById(currentId)?.click();
  }

  handlePlayVideo(data: any) {
    this.stopAllAudio();

    if (data.isPlaying) {
      let videos = document.querySelectorAll('video');
      videos.forEach((v) => {
        if (v.id !== data.videoId) {
          v!.pause();
        }
      });
    }
  }

  stopAllVideo() {
    let videos = document.querySelectorAll('video');
    videos.forEach((v) => {
      v!.pause();
    });
  }

  stopAllAudio() {
    let waves = this.el.nativeElement.getElementsByClassName(
      'zone-audio'
    ) as NodeListOf<HTMLDivElement>;
    if (Array.from(waves).length > 0) {
      Array.from(waves).forEach((res) => {
        let btnPause = res.getElementsByClassName('btn-pause');
        if (btnPause.item(0)) {
          let el = btnPause.item(0) as HTMLElement;
          el.click();
        }
      });
    }
    return;
  }
}
