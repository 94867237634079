<ng-container
  *ngIf="!_router.url.startsWith('/account-info') && !_router.url.startsWith('/edit-user') && !_router.url.startsWith('/setting-user') && !_router.url.startsWith('/home/mention-legal')">

  <div class="card p-3"
    *ngIf="_router.url.startsWith('/home') || _router.url.startsWith('/explorer') || _router.url.startsWith('/notification') ||  _router.url.startsWith('/chat')">
    <div class="title">
      <div>{{'MY_CREATED_EVENTS' | translate}} ({{eventsByAuthor.length!}})</div>
      <div class="create-event" *ngIf="clubId && isAdmin" (click)="createEvent()">
        <i class="pi pi-plus" style="font-size: 20px;"></i>
      </div>
    </div>
    <div class="mt-4 mb-4" *ngIf="eventsByAuthor.length >= 1">
      <div class="mt-4" *ngFor="let event of eventsByAuthor">
        <app-card-event [event]="event" [isJoinClub]="clubId ? isJoined : true"></app-card-event>
      </div>
    </div>
    <div class="mt-4 mb-4" *ngIf="eventsByAuthor.length === 0">
      <div class="mt-4">
        <span class="text-gray fs-16">{{ 'NO_EVENTS_CREATED_FROM_USER' | translate }}</span>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="(isJoined) || (!isJoined && !club?.isPrivate!)">
    <div *ngIf="_router.url.startsWith('/manage-club/')">
      <div class="card p-3 mt-3">
        <div class="title">
          <div>{{ 'CLUB_RULE' | translate}}</div>
          <div class="create-event" *ngIf="clubId && isAdmin" (click)="editClubRule()">
            <i class="pi pi-pencil" style="font-size: 18px;"></i>
          </div>
        </div>
        <div class="mt-4" *ngIf="club">
          <div class="mb-4">
            <div class="mt-4">
              <div style="overflow-wrap: break-word;" [innerText]="club.policy!"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="card-2 p-3 mt-3" *ngIf="events.length! >= 0 && _router.url.startsWith('/home') || _router.url.startsWith('/notification') ||  _router.url.startsWith('/chat')">
    <div class="title">{{ 'UPCOMING_EVENTS' | translate}}</div>
    <div class="mt-4">
      <div class="mb-4" *ngIf="events.length >= 1">
        <div class="mt-4" *ngFor="let event of events">
          <app-card-event [event]="event" [isJoinClub]="clubId ? isJoined : true"></app-card-event>
        </div>
      </div>
      <div *ngIf="events.length === 0">
        <div class="mt-4">
          <span class="text-gray fs-16">{{ 'NO_EVENTS_LISTED' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-2 p-3 mt-3" *ngIf="eventsIsNotInClub.length! >= 0 &&  _router.url.startsWith('/explorer')">
    <div class="title">{{ 'UPCOMING_EVENTS' | translate}}</div>
    <div class="mt-4">
      <div class="mb-4" *ngIf="eventsIsNotInClub.length >= 1">
        <div class="mt-4" *ngFor="let event of eventsIsNotInClub">
          <app-card-event [event]="event" [isJoinClub]="false"></app-card-event>
        </div>
      </div>
      <div *ngIf="eventsIsNotInClub.length === 0">
        <div class="mt-4">
          <span class="text-gray fs-16">{{ 'NO_EVENTS_LISTED' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="openEditPolicyClub">
  <app-edit-policy-club [(openEditPolicyClub)]="openEditPolicyClub" [policy]="club.policy!"
    [clubId]="clubId"></app-edit-policy-club>
</ng-container>
<!-- <div class="card-2"> -->
<!-- </div> -->
<ng-container *ngIf="isLogined">
  <div style="position: absolute;top: 0;height: 100%;">
    <app-comment-post-discussion></app-comment-post-discussion>
  </div>
</ng-container>