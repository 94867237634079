import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, takeLast, tap, timeout } from 'rxjs/operators';
import { IdentityService } from '../services/identity.service';
import { STORAGE_KEY } from '../constant';
import { AccountService } from '../api/be/services';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private _accountService: AccountService) {}
  a: any[] = [];
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let req;

    if (
      request.url.indexOf('/inlivewith/lang') >= 0 ||
      request.url.includes('firebasedynamiclinks')
    ) {
      req = request.clone();
    } else {
      req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${LocalStorageService.GetLocalStorage(
            STORAGE_KEY.TOKEN
          )}`,
        },
      });
    }

    return next.handle(req).pipe(
      map((res: any) => {
        // if (res.url) {
        //   console.log(res.url);
        // }
        // if (res && res.body && res.body['dataStr']) {
        //   try {
        //     res.body['data'] =
        //       JSON.parse(atob(res.body['dataStr'])) ??
        //       atob(res.body['dataStr']);
        //     if (res.body['data'] == 'null') {
        //       res.body['data'] = null;
        //     }
        //   } catch {
        //     res.body['data'] = atob(res.body['dataStr']);
        //   }
        //   res.body['dataStr'] = null;
        // }
        return res;
      }),
      // timeout(3 * 60 * 1000),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.refreshToken().subscribe();
        }
        if (err.status === 500) {
          return of(new HttpResponse({ body: err.error, status: 0 }));
        }
        throw err.message;
      })
    );
  }

  refreshToken() {
    return this._accountService
      .inlivewithAccountRefreshTokenPost$Json({
        token: LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN),
      })
      .pipe(
        takeLast(1),
        tap((res) => {
          if (res.res == 0) {
            const newToken = res.data!;
            LocalStorageService.AddLocalStorage(STORAGE_KEY.TOKEN, newToken);
            location.reload();
          } else {
            LocalStorageService.ClearLocalStorage();
            window.open(environment.WEB_PORTAL_URL, '_self');
          }
        })
      );
  }
}
