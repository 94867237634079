<div class="flex flex-column" *ngIf="accountIdentity">


    <div class="flex justify-content-between align-items-center">
        <div>
            <i class="pi pi-arrow-left mt-4 mb-3 cursor-pointer" style="font-size: 24px" routerLink="../"></i>
        </div>
        <div class="cursor-pointer pt-3" (click)="openPopup()">
            {{'USER_DELETE' | translate}}
        </div>
    </div>

    <div class="poster flex flex-wrap">
        <div class="poster flex bg">
            <div class="flex align-itms-center justify-content-centers mr-3">
                <div class="zone-avatar">
                    <img width="107" height="107" [src]="accountIdentity.avatarUrl!" class="fit-size">
                </div>
            </div>

            <div class="flex flex-column justify-content-center w-100">
                <div class="name-club">
                    <span>
                        {{accountIdentity.displayName}}
                    </span>
                </div>
            </div>
        </div>
        <div class="login-modal">
            <div class="w-100">
                <div class="flex pt-5 flex-column">
                    <label>
                        {{'NICKNAME' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 flex align-items-center">
                        <input readonly="readonly" [value]="accountIdentity!.displayName!" class="w-100 pl-0"
                            type="text" pInputText />
                    </div>
                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'LASTNAME' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 flex align-items-center">
                        <input readonly="readonly" [value]="accountIdentity.lastName" class="w-100 pl-0" type="text"
                            pInputText />
                    </div>
                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'FIRSTNAME' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 flex align-items-center">
                        <input readonly="readonly" [value]="accountIdentity.firstName" class="w-100 pl-0" type="text"
                            pInputText />
                    </div>
                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'EMAIL' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 flex align-items-center">
                        <input class="w-100 pl-0 text-disabled" [value]="accountIdentity.email" type="text" pInputText
                            readonly="readonly" />
                    </div>
                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'GENDER' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 mt-3 flex align-items-center">
                        <div class="border text-gray">{{getGender()}}</div>
                    </div>

                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'TELEPHONE' | translate}}
                    </label>
                    <div class="p-input-icon-right w-100 flex align-items-center">
                        <input readonly="readonly" [value]="accountIdentity.phoneNumber" class="w-100 pl-0" type="text"
                            pInputText />
                    </div>
                </div>

                <div class="flex pt-5 flex-column">
                    <label>
                        {{'BIRTHDAY' | translate}}
                    </label>
                    <div class="mt-3 flex justify-content-between">
                        <div class="border m-1 text-gray">{{getBirthday().day }}</div>
                        <div class="border m-1 text-gray">{{getBirthday().month }}</div>
                        <div class="border m-1 text-gray">{{getBirthday().year }}</div>
                    </div>
                </div>

                <!-- <div class="flex pt-3 pb-3 pt-5 justify-content-center ">
                    <button pButton (click)="logout()" label="{{'LOGOUT' | translate}}"
                        class="p-button-outlined p-button-secondary">
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- <p-dialog [draggable]="false" [resizable]="false" [(visible)]="isOpenDeleteAccountPopup" [modal]="true"
    [closable]="false">
    <div class="modal-title">
        <label>
            {{'USER_DELETE_TITLE' | translate}}
        </label>
    </div>
    <p class="mb-6">
        {{'USER_DELETE_SUB_TITLE' | translate}}
    </p>
    <div class="modal-title">
        <label>
            {{'USER_DELETE_USEFUL_INFOR' | translate}}
        </label>
    </div>
    <p [innerHTML]="'USER_DELETE_USEFUL_INFOR_TITLE' | translate">

    </p>
    <div class="flex flex-column align-items-center">
        <button pButton label="{{'USER_KEEP_ACCOUNT' | translate}}" (click)="onHide()"
            class="mt-5 p-button-outlined p-button-secondary">
        </button>
        <button pButton label="{{'USER_DELETE' | translate}}" (click)="onDelete()"
            class="mt-5 p-button-red p-button-secondary">
        </button>
    </div>
</p-dialog> -->