<div class="position-relative container cursor-pointer"
    [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}" *ngIf="post"
    (click)="gotoDetail()">
    <div *ngIf="checkTypeMedia(post.mediaPosts![0]) == 'VIDEO'" class="wrap-video">
        <img class="icon-play" src="../../../../assets/images/svgs/play-post-video-celeb.svg">
    </div>
    <div *ngIf="post.mediaPosts?.length! > 0" class="w-100 h-100">
        <img loading="lazy" style="border-radius: 15px;"
            *ngIf="(checkTypeMedia(post.mediaPosts![0]) == 'IMAGE' || checkTypeMedia(post.mediaPosts![0]) == 'VIDEO') && checkTypeMedia(post.mediaPosts![0]) != 'RECORD'"
            [src]="getUrl(post.mediaPosts![0])" alt="" class="fit-size" />

        <div class="flex w-100 h-100 justify-content-center align-items-center"
            *ngIf="checkTypeMedia(post.mediaPosts![0]) == 'UNKNOWN'">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin: 3rem;"></i>
        </div>
    </div>
    <div class="flex fs-12 text-white w-90 mt-5" style="position: absolute; top: 45px"
        *ngIf="post.isAdminPost && post.mediaPosts?.length! > 0 && checkTypeMedia(post.mediaPosts![0]) == 'AUDIO'"
        [innerHTML]="post.mediaPosts![0].originalFileName">
    </div>
    <div class="flex flex-column w-90 mt-5" style="position: absolute; top: 35px"
        *ngIf="post.mediaPosts?.length! > 0 && checkTypeMedia(post.mediaPosts![0]) == 'AUDIO'">
        <app-wave-form-audio [assetUrl]="post.mediaPosts![0].url!" [forPostCeleb]="true"></app-wave-form-audio>
    </div>
    <div class="zone-header flex-column h-90">
        <div class="flex justify-content-between">
            <div class="zone-info-celeb">
                <div class="flex align-items-center">
                    <div class="zone-avatar">
                        <img [src]="post.author?.avatar ?? defaultAvatar" class="fit-size cursor-pointer">
                    </div>
                    <div class="ml-2">
                        <span class="text-name text-white mr-2 cursor-pointer">
                            {{post.author!.nickName! | limitString:false:14}}
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!post.isAdminPost">
                <img class="fit-size" src="../../../assets/images/svgs/celeb-post.svg">
            </div>

        </div>

        <div class="flex flex-column justify-content-between h-100" *ngIf="post.mediaPosts?.length! == 0">
            <div class="flex w-100">
                <div class="mt-3 text-message text-message-body" [innerHTML]="urlify(post.message)"></div>
            </div>

            <div class="flex">
                <div class="flex align-items-center fs-12 text-white">
                    <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post!.isReacted!"
                        style="font-size: 19px; margin-right: 5px; transition: color 0.5s"
                        [ngClass]="{'liked' : post!.isReacted!}" (click)="likePost($event)"></i>
                    {{post.totalReact}}
                    <img class="mr-2" style="margin-left: 20px;" src="../../../assets/images/svgs/comment.svg"
                        width="16" height="16" alt="" srcset="">
                    {{post.totalComment}}
                </div>
            </div>
        </div>
    </div>


    <div class="zone-footer"
        [ngStyle]="post.isAdminPost ? {'background': '#5F80BD 0% 0% no-repeat padding-box'} : {'background': '#5F61BD 0% 0% no-repeat padding-box'}"
        *ngIf="post.mediaPosts?.length! > 0">
        <div class="mt-2" *ngIf="post.message">
            <div class="text-message text-message-footer">
                {{post.message}}
            </div>
        </div>
        <div class="flex justify-content-between align-items-center w-100 mt-2">
            <div class="flex align-items-center">
                <div class="flex align-items-center fs-12 text-white">
                    <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post!.isReacted!"
                        style="font-size: 19px; margin-right: 5px; transition: color 0.5s"
                        [ngClass]="{'liked' : post!.isReacted!}" (click)="likePost($event)"></i>
                    {{post.totalReact}}
                    <img class="mr-2" style="margin-left: 20px;" src="../../../assets/images/svgs/comment.svg"
                        width="16" height="16" alt="" srcset="">
                    {{post.totalComment}}
                </div>
            </div>
            <div class="flex justify-content-center align-items-center zone-count-media"
                style="background-image: url('../../../assets/images/svgs/celeb-post-icon-right.svg');"
                *ngIf="post.mediaPosts?.length! > 1">
                <div class="text-bold number-count-media">
                    {{post.mediaPosts?.length}}</div>
            </div>
        </div>
    </div>
</div>