import { Component, Input, OnInit } from '@angular/core';
import { EHashtag } from 'src/app/pages/main-menu/explorer/explorer.component';

@Component({
  selector: 'app-list-clubs-hashtag',
  templateUrl: './list-clubs-hashtag.component.html',
  styleUrls: ['./list-clubs-hashtag.component.scss']
})
export class ListClubsHashtagComponent implements OnInit {

  @Input() hashtag!: EHashtag
  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

}
