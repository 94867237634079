import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TranslateLoader } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<JSON> {
    lang = LocalStorageService.GetLocalStorage('language').toLowerCase() || "fr";
    return this.http
      .get<JSON>(`../../../assets/i18n/${lang}.json`)
      .pipe(map((res: JSON) => res))
    // return this.http
    //   .get<JSON>(
    //     `${environment.STORAGE_URL}/inlivewith/lang/${lang}.json`
    //   )
    //   .pipe(
    //     map((res: JSON) => res),
    //     catchError(() =>
    //       this.http
    //         .get<JSON>(`../../../assets/i18n/${lang}.json`)
    //         .pipe(map((res: JSON) => res))
    //     )
    //   );
  }
}
