import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private http: HttpClient) {}

  generateDeepLink(url: string) {
    const fire_base_api = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.FIREBASE_API_KEY}`;
    const body = {
      dynamicLinkInfo: {
        domainUriPrefix: environment.DOMAIN_URI_PREFIX,
        link: url,
        androidInfo: { androidPackageName: environment.androidPackageName },
        iosInfo: { iosBundleId: environment.iosBundleId },
        navigationInfo: {},
      },
      suffix: { option: 'SHORT' },
    };

    return this.http.post(fire_base_api, body);
  }
}
