import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { CommentPost } from 'src/app/core/api/be/models';
import { ReportService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { MobileService } from 'src/app/core/services/is-mobile.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, AfterViewInit {

  @Input() showDialog!: boolean;
  @Input() type!: string;
  @Input() nameObject!: any;


  @Output() showDialogChange = new EventEmitter();
  @Output() refreshWhenReportSuccess = new EventEmitter();

  currentStep: number = 1;
  countClicked: number = 0;

  value!: string;
  name!: string;
  description: string = '';

  isMobile: boolean = this._mobileService.isMobile();

  constructor(
    private _reportService: ReportService,
    private _mobileService: MobileService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngAfterViewInit(): void {
    let elm = document.getElementById('zone-report');
    if (this.isMobile) {
      elm!.style.width! = '90vw';
    } else {
      elm!.style.width! = '750px';
    }
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.showDialogChange.emit(false);
  }

  createReport(type: string) {
    this.countClicked++;
    if (this.countClicked === 1) {
      if (type == "COMMENT") {
        this.reportComment()
      }
      else if (type == "POST") {
        this.reportPost()
      }
      else if (type == "COMMENT_ALBUM") {
        this.reportCommentAlbum();
      }
      else if (type == "CLUB") {
        this.reportClub()
      }
      else if (type == "INLIVER") {
        this.reportInliver();
      }
    }
  }

  next(event: Event) {
    this.currentStep++;
    if (this.currentStep == 2) {
      console.log(this.value);
    }
  }

  getNameObject(nameObject: any) {
    if (this.type == "COMMENT") {
      return nameObject.author.nickName
    }
    else if (this.type == "POST") {
      return nameObject.author.nickName
    }
    else if (this.type == "COMMENT_ALBUM") {
      return nameObject.author.nickName
    }
    else if (this.type == "CLUB") {
      return nameObject.name
    }
    else if (this.type == "INLIVER") {
      return nameObject.nickName
    }
  }

  reportComment() {
    return this._reportService.inlivewithReportCreateReportCommentPostPost$Json({
      body: {
        commentPostId: this.nameObject.id!,
        reasonReport: this.value,
        reporterId: AuthService.GetAccountInfo().id!,
        description: this.description ? this.description : this.name
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.refreshWhenReportSuccess.emit();
          this.currentStep++
          this.countClicked = 0;
        } else {
          this.showDialog = false
          this.countClicked = 0;
        }
      })
    ).subscribe();
  } 

  reportPost() {
    return this._reportService.inlivewithReportCreateReportPostPost$Json({
      body: {
        postId: this.nameObject.id!,
        clubId: this.nameObject.clubId!,
        stageId: this.nameObject.stageId!,
        reasonReport: this.value,
        reporterId: AuthService.GetAccountInfo().id!,
        description: this.description ? this.description : this.name
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.showNotification(this._translateService.instant('CREATE_REPORT_CONTENT_SUCCESS'));
          this.currentStep++;
          this.refreshWhenReportSuccess.emit();
          this.countClicked = 0;
        } else {
          this.showDialog = false
          this.countClicked = 0;
        }
      })
    ).subscribe();
  }

  reportCommentAlbum() {
    return this._reportService.inlivewithReportCreateReportCommentAlbumAssetPost$Json({
      body: {
        commentAlbumAssetId: this.nameObject.id!,
        reasonReport: this.value,
        reporterId: AuthService.GetAccountInfo().id!,
        description: this.description ? this.description : this.name
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.currentStep++;
          this.refreshWhenReportSuccess.emit();
          this.countClicked = 0;
        } else {
          this.showDialog = false
          this.countClicked = 0;
        }
      })
    ).subscribe();
  }

  reportClub() {
    return this._reportService.inlivewithReportCreateReportClubPost$Json({
      body: {
        clubId: this.nameObject.id!,
        reasonReport: this.value,
        reporterId: AuthService.GetAccountInfo().id!,
        description: this.description ? this.description : this.name
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.showNotification(this._translateService.instant('CREATE_REPORT_CLUB_SUCCESS'));
          this.currentStep++;
          this.refreshWhenReportSuccess.emit();
          this.countClicked = 0;
        } else {
          this.showDialog = false
          this.countClicked = 0;
        }
      })
    ).subscribe();
  }

  reportInliver() {
    this._reportService.inlivewithReportCreateReportAccountPost$Json({
      body: {
        accountId: this.nameObject.id!,
        reasonReport: this.value,
        reporterId: AuthService.GetAccountInfo().id!,
        description: this.description ? this.description : this.name
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.showNotification(this._translateService.instant('CREATE_REPORT_INLIVER_SUCCESS'));
          this.currentStep++;
          this.refreshWhenReportSuccess.emit();
          this.countClicked = 0;
        } else {
          this.showDialog = false
          this.countClicked = 0;
        }
      })
    ).subscribe();
  }

  showNotification(detail: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: detail,
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => {
      this.currentStep++;
      this.refreshWhenReportSuccess.emit();
    });
  }
}
