<ng-container
    *ngIf="!_router.url.startsWith('/manage-club/detail-club') && !_router.url.startsWith('/manage-club/detail-album') && !_router.url.startsWith('/manage-club/detail-album') && !_router.url.startsWith('/manage-club/create-album') && !_router.url.startsWith('/manage-club/create-event')  && !_router.url.startsWith('/edit-user') && !_router.url.startsWith('/account-info') && !_router.url.startsWith('/setting-user') && !_router.url.startsWith('/home/mention-legal') && !_router.url.startsWith('/manage-club/detail-event') && !_router.url.startsWith('/manage-club/list-event') && !_router.url.startsWith('/signalements') && !_router.url.startsWith('/manage-club/report-club') && !_router.url.startsWith('/my-favourite') && !_router.url.startsWith('/announcement/not-found')  && !_router.url.startsWith('/edit-user')">


    <app-club-managed></app-club-managed>
    <app-club-joined></app-club-joined>
    <app-my-friend></app-my-friend>


</ng-container>

<ng-container *ngIf="currentClub">
    <div *ngIf="(((isJoined && !isBanned) || (!isJoined && !currentClub.isPrivate)) && !currentClub.isBlocked)">
        <div class="card p-3 mt-3"
            *ngIf="(_router.url.startsWith('/manage-club/detail-club') || _router.url.startsWith('/manage-club/detail-album')) || _router.url.startsWith('/manage-club/create-album') || _router.url.startsWith('/manage-club/create-event') || _router.url.startsWith('/manage-club/detail-event') || _router.url.startsWith('/manage-club/list-event')">
            <div class="title align-items-center">
                <div class="flex align-items-baseline">
                    {{'ALBUMS' | translate}}
                    <small class="text-disabled fs-16 ml-2" *ngIf="isAdmin && isJoined">{{'MAX_3' | translate}}</small>
                </div>
                <div *ngIf="albums?.length! > 0 && isAdmin && isJoined" class="cursor-pointer create-club"
                    (click)="createAlbum()">
                    <i class="pi pi-plus" style="font-size: 20px;"></i>
                </div>
            </div>

            <div class="flex flex-wrap justify-content-between">
                <div *ngIf="albums?.length! == 0 && isAdmin && isJoined" class="btn-create mt-4 cursor-pointer mt-4"
                    (click)="createAlbum()">
                    <i class="pi pi-plus mb-2" style="font-size: 24px;"></i>
                    {{ 'CREATE' | translate}}
                </div>

                <div class="flex mt-4" *ngIf="albums?.length! === 0 && !isAdmin">
                    <span class="text-gray">
                        {{'CLUB_NO_ALBUM_YET' | translate}}
                    </span>
                </div>
                <div class="flex mt-4" *ngFor="let album of albums">
                    <app-card-album [isAcceptedToView]="isAcceptedToView" [isJoined]="isJoined" [album]="album">
                    </app-card-album>
                </div>
            </div>

            <div class="flex align-items-center justify-content-center mt-3 cursor-pointer" (click)="loadMoreAlbums()"
                *ngIf="isShowMoreAlbums">
                <span class="fs-14">{{ 'SEE_EVERYTHING' | translate}}</span>
            </div>
        </div>
        <div class="card p-3 mt-5"
            *ngIf="!isBanned && (_router.url.startsWith('/manage-club/detail-club') || _router.url.startsWith('/manage-club/detail-album')) || _router.url.startsWith('/manage-club/create-album') || _router.url.startsWith('/manage-club/create-event') || _router.url.startsWith('/manage-club/detail-event') || _router.url.startsWith('/manage-club/list-event')">
            <div *ngIf="eventsInClub?.length! >= 1">
                <div class="title">
                    <div>{{ 'EVENTS' | translate}} ({{eventsInClub.length!}})</div>
                    <div class="create-event" *ngIf="clubId && isAdmin" (click)="createEvent()">
                        <i class="pi pi-plus" style="font-size: 20px;"></i>
                    </div>
                    <div class="fs-16 cursor-pointer" *ngIf="clubId && !isAdmin && eventsInClub.length! > 0"
                        (click)="seeAll()">
                        <span class="fs-14">{{'SEE_EVERYTHING' | translate}}</span>
                    </div>
                </div>
                <div class="mt-4 mb-4">
                    <div class="fs-16 cursor-pointer" *ngIf="clubId && isAdmin && eventsInClub.length! > 0"
                        (click)="seeAll()">
                        <span class="fs-14">{{'SEE_EVERYTHING' | translate}}</span>
                    </div>
                    <div class="mt-4" *ngFor="let event of eventsInClub!.slice(0,2)">
                        <app-card-event [event]="event" [isJoinClub]="clubId ? isJoined : true"></app-card-event>
                    </div>
                </div>
            </div>
            <div class="title flex-column align-items-start" *ngIf="eventsInClub.length === 0">
                <div class="flex w-100 justify-content-between">
                    <div>{{ 'UPCOMING_EVENTS' | translate}}</div>
                    <div class="create-event" *ngIf="clubId && isAdmin" (click)="createEvent()">
                        <i class="pi pi-plus" style="font-size: 20px;"></i>
                    </div>
                </div>
                <div class="flex mt-4">
                    <span class="text-gray fs-16" style="letter-spacing: 0px;
                    font-weight: 500;">{{'CLUB_NO_EVENTS' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="_router.url.startsWith('/setting-user')">
    <div class="card p-3 mt-3">
        <div class="title align-items-center">
            {{ 'SETTINGS_AND_PRIVACY' | translate}}
        </div>
        <div class="mt-3" *ngFor="let item of listSettings">
            <div class="flex mb-2 align-items-center cursor-pointer"
                [ngStyle]="currentSetting == item.id ? {'color':'white'} : {'color':'gray'}" (click)="setSetting(item)">
                <i [ngClass]="item.icon"></i>
                <span class="ml-3">{{item.title}}</span>
            </div>
        </div>
    </div>
</div>


<div class="flex flex-column" *ngIf="_router.url.startsWith('/signalements')">
    <div class="p-3 mt-3" [ngClass]="openReportByAccount ? 'card' : ''">
        <div class="title align-items-center cursor-pointer" (click)="handleReportAccount()">
            {{ 'PROFILE' | translate}}
        </div>
    </div>
    <app-club-managed [isReport]="true" [currentClubReports]="currentClubReports"
        [(openReportByAccount)]="openReportByAccount"></app-club-managed>
</div>

<div class="flex flex-column" *ngIf="_router.url.startsWith('/my-favourite')">
    <div class="card p-3 mt-3">
        <div class="title align-items-center cursor-pointer">
            {{ 'MY_FAVOURITE' | translate}}
        </div>
    </div>
</div>


<div *ngIf="_router.url.startsWith('/home/mention-legal')">
    <div class="card p-3 mt-3">
        <div class="title align-items-center">
            {{ 'MENTION_LEGALS' | translate}}
        </div>
        <div class="mt-3" *ngFor="let item of listMentionLegals">
            <div class="flex mb-2 align-items-center cursor-pointer"
                [ngStyle]="currentLegal == item.id ? {'color':'white'} : {'color':'gray'}" (click)="setLegal(item)">
                <i [ngClass]="item.icon"></i>
                <span class="ml-3">{{item.title}}</span>
            </div>
        </div>
    </div>
</div>

<p-toast position="top-right" key="noti-max-album-reach">
    <ng-template let-message pTemplate="message">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-copy mr-3" style="font-size: 3rem"></i>
            <div class="flex flex-column">
                <span class="mb-1">{{message.summary}}</span>
                <div class="wrap-text">
                    <span class="mt-1">{{message.detail}}</span>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>