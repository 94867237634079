import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  ReactionPostResponseModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  AlbumService,
  PostService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-like-media-album',
  templateUrl: './list-like-media-album.component.html',
  styleUrls: ['./list-like-media-album.component.scss'],
})
export class ListLikeMediaAlbumComponent implements OnInit {
  @Input() albumAssetId!: string;

  defaultAvatar = defaultAvatar;
  currentUser = AuthService.GetAccountInfo();
  items!: MenuItem[];

  listInliversPlus: ReactionPostResponseModel[] = [];
  listInlivers: ReactionPostResponseModel[] = [];
  // listReact: ReactionPostResponseModel[] = [];

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _accountService: AccountService,
    private _postService: PostService,
    private _albumService: AlbumService
  ) {}

  ngOnInit(): void {
    console.log(this.albumAssetId);

    this.getUserLikeMedia();
  }

  initMenu(account: AccountResponseWithRoleInCLubModel) {
    this.items = [
      {
        icon: 'pi pi-users',
        label: this._translateService.instant('PROFILE_SEE'),
        command: (event: any) => {
          // this.openAccountInfo(account);
        },
      },
    ];

    return this.items;
  }

  getUserLikeMedia() {
    return this._albumService
      .inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json({
        albumAssetId: this.albumAssetId,
        accountRequestId: AuthService.GetAccountInfo().id!,
        limit: 100000,
      })
      .pipe(
        tap((res) => {
          this.listInlivers = res?.data?.page!.filter(account => account!.account?.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS')!;
          this.listInliversPlus = res?.data?.page!.filter(account => account!.account?.accessLevelInClub == 'ACCESSLEVEL_PLUS')!;
        })
      )
      .subscribe();
  }
}
