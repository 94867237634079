import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClubResponseWithTotalMemberModel } from 'src/app/core/api/be/models';
import { ILWAppEnum } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-card-club',
  templateUrl: './card-club.component.html',
  styleUrls: ['./card-club.component.scss']
})
export class CardClubComponent implements OnInit {
  @Input() club!: ClubResponseWithTotalMemberModel;
  @Input() showQuantityMembers = true;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  detailClub() {
    this._router.navigate(['manage-club/detail-club'], { queryParams: { clubId: this.club?.id! } });
  }

  getIcon() {
    let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter((c: any) => c.value == this.club.category)[0]?.url!;
    return iconUrl;
  }

  getUrl(asset: any) {

    if (!asset?.includes('?')) {

    } else {

      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset;
  }

}
