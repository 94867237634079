<p-dialog *ngIf="!isMobile && club" [(visible)]="showDialog" [closable]="true" [modal]="true" [draggable]="false"
    [resizable]="false" (onHide)="onHide()">
    <div style="width: 650px;">
        <div class="flex flex-column align-items-center">
            <span class="text-white fs-22 mb-2">{{'NOTIFICATIONS' | translate}}</span>
            <span class="text-disabled fs-16 mt-2">{{'ADMIN_NOTIFICATION_SETTING' | translate}}</span>
        </div>
        <div class="select-all">
            <div class="flex justify-content-between align-items-center mb-5">
                <div class="flex w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'ALL_NOTIFICATIONS' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isAll" (ngModelChange)="clear()"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="flex flex-column mt-5">
            <div class="flex justify-content-between align-items-center mb-5">
                <div class="flex w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'PUBLICATION' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffPublicationsNoti" (ngModelChange)="check()"></p-inputSwitch>
                </div>
            </div>
            <div class="flex justify-content-between align-items-center mb-5">
                <div class="flex w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'IDENTIFICATION' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffIdentificationsNoti" (ngModelChange)="check()"></p-inputSwitch>
                </div>
            </div>
            <div class="flex justify-content-between align-items-center">
                <div class="flex w-60">
                    <span class="text-white mb-2 fs-18">
                        {{'ACCEPT_MEMBER' | translate}}
                    </span>
                </div>
                <div>
                    <p-inputSwitch [(ngModel)]="isTurnOffInliverNoti" (ngModelChange)="check()"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="flex justify-content-center mt-4">
            <button pButton pRipple type="button" label="{{'REGISTER' | translate}}"
                class="p-button-outlined p-button-secondary text-white" (click)="save()">
            </button>
        </div>
    </div>
</p-dialog>


<!-- <p-sidebar *ngIf="isMobile" [(visible)]="showDialog" [modal]="true" [draggable]="false" [showCloseIcon]="true" position="bottom" (onHide)="onHide()">
    <div>

    </div>
</p-sidebar> -->