import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import {
  AccountInputModel,
  AccountResponseGeneral,
  GendersEnum,
  ResultSearchAccountViewModel,
} from 'src/app/core/api/be/models';
import { AccountService, AppEnumService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CheckErrorForm, defaultAvatar } from 'src/app/core/constant';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  STORAGE_URL_IDENTITY = environment.STORAGE_URL_IDENTITY;
  t = new Date().getTime();

  defaultAvatar = defaultAvatar;
  accountILW = AuthService.GetAccountInfo();
  accountIdentity!: ResultSearchAccountViewModel;
  form: UntypedFormGroup = new UntypedFormGroup({});
  CheckErrorForm = CheckErrorForm;
  submited: boolean = false;

  birthday!: Date;

  isOpenDeleteAccountPopup: boolean = false;

  listGender!: GendersEnum[];
  selectedGender!: any;

  constructor(
    private _router: Router,
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getAccountIdentityByEmail().subscribe();
  }

  getAccount() {
    return AuthService.GetAccountInfo();
  }

  edit() {
    const tab = window.open(
      environment.WEB_PORTAL_URL +
      `/profile/${LocalStorageService.GetLocalStorage('email')}?callback=${window.origin
      }/home/edit-user&lang=${LocalStorageService.GetLocalStorage(
        'language'
      ).toLowerCase()}`,
      'targetWindow',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=840`
    );

    window.addEventListener('message', (e) => {
      if (e.data === 'close') {
        this.getAccountIdentityByEmail().subscribe(() => {
          this.syncAccountInfo(true);
        });
      }
    });
  }

  setBirthday() {
    let body = {
      accountId: AuthService.GetAccountInfo().id!,
      birthday: new Date(this.accountIdentity.birthDay!).toISOString(),
    };
    return this._accountService.inlivewithAccountBirthdayAccountIdPut$Json({
      accountId: AuthService.GetAccountInfo()?.id!,
      body: body,
    })
  }

  openPopup() {
    //this.isOpenDeleteAccountPopup = true;
    this.edit();
  }

  onHide() {
    this.isOpenDeleteAccountPopup = false;
  }

  checkAge() {
    let date = this.birthday?.getDate() + 1;
    let month = this.birthday?.getMonth() + 1;
    let birthday = month + '-' + date + '-' + this.birthday?.getFullYear();

    let idx = new Date().getFullYear() - new Date(birthday).getFullYear();
    if (idx < 13) {
      return { invalidAge: true };
    }
    return null;
  }

  getGender() {
    if (this.accountIdentity.gender == 'GENDER_FEMALE') {
      return this._translateService.instant('FEMALE');
    }

    if (this.accountIdentity.gender == 'GENDER_MALE') {
      return this._translateService.instant('MALE');
    }

    if (this.accountIdentity.gender == 'GENDER_UNDEFINED') {
      return this._translateService.instant('UNDEFINED');
    }

    return this._translateService.instant('UNDEFINED');
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['explorer']).then(() => {
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  // logout() {
  //   localStorage.clear();
  //   window.open(
  //     environment.WEB_PORTAL_URL + `/auth/logout?callback=${window.origin}`,
  //     'targetWindow',
  //     `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=640`
  //   );

  //   window.addEventListener('message', () => {
  //     location.reload();
  //   });
  // }

  getAccountIdentityByEmail() {
    return this._accountService
      .inlivewithAccountGetAccountIdentityByEmailEmailGet$Json({
        email: LocalStorageService.GetLocalStorage('email'),
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.accountIdentity = res.data!;
          } else {
            this.logout();
          }
        })
      );
  }

  getBirthday() {
    return {
      day: new Date(
        this.accountIdentity?.birthDay?.toString()! + 'Z'
      ).getDate(),
      month:
        new Date(this.accountIdentity?.birthDay?.toString()! + 'Z').getMonth() +
        1,
      year: new Date(
        this.accountIdentity?.birthDay?.toString()! + 'Z'
      ).getFullYear(),
    };
  }

  syncAccountInfo(isReload?: boolean) {
    this.accountILW = AuthService.GetAccountInfo();

    if (
      // this.accountILW.lastName !== this.accountIdentity.lastName ||
      // this.accountILW.firstName !== this.accountIdentity.firstName ||
      // this.accountILW.nickName !== this.accountIdentity.displayName ||
      // this.accountILW.phoneNumber !== this.accountIdentity.phoneNumber ||
      this.accountILW.gender !== this.accountIdentity.gender ||
      this.accountILW.birthday !== this.accountIdentity.birthDay ||
      this.accountILW.avatar !== this.accountIdentity.avatarUrl
    ) {
      LoadingService.$(true);
      let body = {
        accountId: AuthService.GetAccountInfo()?.id!,
        // lastName: this.accountIdentity.lastName,
        // firstName: this.accountIdentity.firstName,
        nickName: this.accountIdentity.displayName,
        // phoneNumber: this.accountIdentity.phoneNumber!,
        gender: this.accountIdentity.gender || 'GENDER_UNDEFINED',
        avatar: this.accountIdentity!.avatarUrl!
          ? this.accountIdentity.avatarUrl!?.replace(
            environment.STORAGE_URL_IDENTITY,
            ''
          )
          : '',
      };
      this._accountService
        .inlivewithAccountUpdateAccountIdPut$Json({
          accountId: AuthService.GetAccountInfo()?.id!,
          body: body,
        })
        .pipe(
          tap(() => LoadingService.$(false)),
          // mergeMap(() => this.setBirthday())
        )
        .subscribe((res) => {
          AuthService.SetAccountInfo(res?.data!);
          LoadingService.$(false);
          if (isReload) {
            this.showNotification(
              this._translateService.instant('CHANGE_INFOR_CLUB')
            );
          }
        });
    }
  }

  showNotification(subtitle: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this._messageService.add({
          severity: 'success',
          summary: '',
          detail: subtitle,
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => location.reload());
  }
}
