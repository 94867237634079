<div class="flex flex-column w-100" *ngIf="post.mediaPosts.length > 0">
    <div class="flex justify-content-center align-items-center img-main"
        *ngIf="checkTypeMedia(currentMedia) !== 'AUDIO'">
        <img loading="lazy"
            *ngIf="(checkTypeMedia(currentMedia) == 'IMAGE' || checkTypeMedia(currentMedia) == 'VIDEO') && checkTypeMedia(currentMedia) != 'RECORD'"
            [src]="getUrl(currentMedia)" alt="" class="h-100" (load)="onLoad($event)"
            [ngClass]="imgMainIsLandscape == true ? 'landscape':'portable'" />
        <video *ngIf="getUrl(currentMedia) == currentMedia?.url! && checkTypeMedia(currentMedia) == 'RECORD'"
            [src]="getUrl(currentMedia)" class="h-100" controls [muted]="true"></video>
        <div *ngIf="checkTypeMedia(currentMedia) == 'VIDEO'" class="wrap-video">
            <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
        </div>
    </div>
    <div class="mt-2 list-media" *ngIf="post.mediaPosts.length > 1">
        <div class="flex mr-1 ml-1 media" *ngFor="let asset of post.mediaPosts; let i = index"
            (click)="selectMedia(asset)" [ngClass]="asset.id == currentMedia.id ? 'media-selected':''">
            <img loading="lazy"
                *ngIf="(checkTypeMedia(asset) == 'IMAGE' || checkTypeMedia(asset) == 'VIDEO') && checkTypeMedia(asset) != 'RECORD'"
                [src]="getUrl(asset)" alt="" class="fit-size" (click)="openMedia(i)" />
            <video *ngIf="getUrl(asset) == asset?.url! && checkTypeMedia(asset) == 'RECORD'" (click)="openMedia(i)"
                [id]="asset" [src]="getUrl(asset)" class="fit-size" controls [muted]="true"></video>
            <div *ngIf="checkTypeMedia(asset) == 'VIDEO'" (click)="openMedia(i)" class="wrap-video">
                <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
            </div>
        </div>

    </div>
</div>

<div *ngIf="post?.mediaPosts?.length! > 0">
    <div class="flex justify-content-center media w-100" *ngFor="let asset of post.mediaPosts; let i = index">
        <div class="flex flex-column w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
            <div class="flex flex align-items-center justify-content-center zone-audio">
                <div class="cursor-pointer flex align-items-center justify-content-center" (click)="onPlayButtonClick()"
                    style="width: 4rem;">
                    <i class="pi pi-play btn-audio" *ngIf="!isPlayingAudio"></i>
                    <i class="pi pi-pause btn-audio" *ngIf="isPlayingAudio"></i>
                </div>

                <div class="ware-form">
                    <ng-waveform #waveform class="waveform" [src]="(asset?.newUrl!)" backgroundColor="#242536"
                        regionBackgroundColor="rgba(255, 255, 255, 0.7)" regionStartStickColor="#21f032"
                        regionEndStickColor="red" regionTextColor="#09417e" [withRegionLabels]="true"
                        waveColor="#50c9ff" (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)"
                        (durationChange)="onDurationChange($event)" (timeUpdate)="onTimeUpdate($event)"
                        (paused)="onPaused()" (regionChange)="onRegionChange($event)">
                    </ng-waveform>
                </div>

                <div style="width: 4rem; text-align: center">
                    {{timeOffAudio*1000 | date:'mm:ss'}}
                </div>
            </div>
        </div>
    </div>
</div>