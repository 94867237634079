<p-dialog [(visible)]="showDialog" [draggable]="false" [resizable]="false" [modal]="true" (onHide)="onHide()">

    <div class="card">
        <div class="title">
            {{'LES_GOINLIVE' | translate}}
        </div>
        <div class="mt-4">
            <div pRipple class="flex mt-3 cursor-pointer" (click)="goInlive('GOINLIVE_CLASSIC')" style="border-radius: 50px; padding: 1rem;">
                <div class="ml-3 mr-3">
                    <p-checkbox name="group2" value="classic" [disabled]="true"></p-checkbox>
                </div>
                <div>
                    <div class="text">
                        {{'GO_INLIVE' | translate}}
                        <img class="ml-2" src="../../../../assets/images/svgs/inlive-classic.svg" alt="" srcset="">
                    </div>
                    <div class="sub-text mt-2" [innerHTML]="'GOINLIVE_CLASSIC_TITLE' | translate"></div>
                </div>
            </div>
            <div pRipple class="flex mt-2 cursor-pointer" (click)="goInlive('GOINLIVE_PLUS')" style="border-radius: 50px; padding: 1rem;">
                <div class="ml-3 mr-3">
                    <p-checkbox name="group1" value="plus" [disabled]="true"></p-checkbox>
                </div>
                <div>
                    <div class="text">
                        {{'GO_INLIVE' | translate}}
                        <img class="ml-2" src="../../../../assets/images/svgs/inlive-plus.svg" alt="" srcset="">

                    </div>
                    <div class="sub-text mt-2" [innerHTML]="'GOINLIVE_PLUS_TITLE' | translate"></div>
                </div>
            </div>
            <!-- <div pRipple class="flex mt-2 cursor-pointer" (click)="goSchedule()" style="border-radius: 50px; padding: 1rem;">
                <div class="ml-3 mr-3 mt-1">
                    <p-checkbox name="group1" value="plus" [disabled]="true"></p-checkbox>
                </div>
                <div>
                    <div class="text">
                        {{'SCHEDULE_GOINLIVE' | translate}}
                        <img class="ml-3" src="../../../../assets/images/svgs/inlive-plus.svg" alt="" srcset="">

                    </div>
                    <div class="sub-text mt-3">
                        {{'SCHEDULE_GOINLIVE_TITLE' | translate}}
                    </div>
                </div>
            </div> -->

        </div>
    </div>

</p-dialog>

<ng-container>
    <app-schedule-goinlive [(showSchedule)]="openPopupScheduleGoInlive" [clubId]="club.id!"></app-schedule-goinlive>
</ng-container>