import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fetchUrlImage',
})
export class FetchUrlImagePipe implements PipeTransform {
  transform(url: string, ...args: unknown[]): unknown {
    if (!url) {
      return window.origin + '/assets/images/svgs/avatar-default.svg';
    }

    if (url.includes('avatar-default')) {
      return url;
    }

    if (
      url.includes('?i=') ||
      url.includes('?v=') ||
      url.includes('?a=') ||
      url.includes('?t=') ||
      url.includes('?=')
    ) {
      return url.replace('file.', 'thumb.');
    } else {
      return window.origin + '/assets/images/naloader.svg';
    }
  }
}
