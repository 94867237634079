import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Stage, StageWithCheckExistAlbumModel } from 'src/app/core/api/be/models';
import {
  base64ToBlob,
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  getFileExtension,
  PostEnum,
  typeAudio,
  URL_REGEX,
} from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';
import _ from 'lodash';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { Metadata, OpenGraphModel } from 'src/app/core/api/metareader/models';
import { LinkPreviewService, MetaReadderGrpcServiceService } from 'src/app/core/api/metareader/services';
import { of } from 'rxjs';
import { EventService } from 'src/app/core/services/event.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-create-post-for-admin',
  templateUrl: './create-post-for-admin.component.html',
  styleUrls: ['./create-post-for-admin.component.scss'],
})
export class CreatePostForAdminComponent implements OnInit {
  @Input() showPopup = false;
  @Output() showPopupChange = new EventEmitter<boolean>();

  @Input() clubId = '';
  @Input() listMention: any[] = [];
  @Input() stages: StageWithCheckExistAlbumModel[] = [];
  @Input() currentStage!: StageWithCheckExistAlbumModel;
  @Input() isJoined = false;
  @Input() isHasAccess = false;
  @Input() applyRole = false;
  @Input() isAdmin = false;

  @Output() postEvent = new EventEmitter<{
    urls: any[];
    typePost: PostEnum;
    title: string;
    content: string;
    filePosts: any[];
    blobFileAudio: Blob;
    urlAudio: string;
    mediaFiles: any[];
  }>();

  readonly PostEnum = PostEnum;
  urls: any[] = [];
  typePost!: PostEnum;
  title = '';
  content: any;
  urlAudio = '';
  blobFileAudio!: Blob;
  filePosts: any[] = [];

  openPopupRecordAudio = false;
  isSubmmited = false;
  showEmoji = false;
  isErrorFile = false;
  tribute: any;
  mentionConfig: any = {};

  metaDataItem!: OpenGraphModel

  @ViewChild('toggleEmoji') toggleEmoji!: ElementRef;
  intervalDetectFile!: NodeJS.Timeout;

  isChrome = navigator.userAgent.toLowerCase().includes('chrome') && !navigator.userAgent.toLowerCase().includes('edg');

  constructor(
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private _metaReader: LinkPreviewService,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleEmoji!.nativeElement!) {
        this.showEmoji = false;
      }
    });
  }

  ngOnInit(): void {
    this.initConfigMention();
    this.typePost = PostEnum.PUBLICATION;
  }

  close() {
    this.showPopup = false;
    this.showPopupChange.emit(this.showPopup);
  }

  checkInvalidTitle() {
    if (this.typePost === PostEnum.DISCUSSION) {
      if (
        this.getCharacterLeft().isFullCharTitle ||
        (this.isSubmmited && this.title.trim().length == 0)
      ) {
        return false;
      }
    }
    return true;
  }

  checkShowIconCreatePost() {
    return (
      (this.urls.length > 0 && this.urls.length <= 4) ||
      (this.urls.length >= 0 &&
        this.urls.length <= 4 &&
        this.content &&
        this.content.trim().length > 0)
    );
  }

  createPost() {
    this.isSubmmited = true;

    if (this.typePost === PostEnum.PUBLICATION) {
      let urlsBrowser: string[] = [];

      this.content?.replace(URL_REGEX, (url: any) => {
        urlsBrowser.push(decodeURIComponent(url));
      })

      this.postEvent.emit({
        mediaFiles: this.urls,
        urls: urlsBrowser,
        typePost: this.typePost,
        title: this.title,
        content: this.content,
        filePosts: this.filePosts,
        blobFileAudio: this.blobFileAudio,
        urlAudio: this.urlAudio,
      });
      this.close();
      return;
    }

    if (this.title.trim().length > 0 && this.checkInvalidTitle() && !this.getCharacterLeft().isFullCharContent) {
      let urlsBrowser: string[] = [];

      // this.content?.replace(URL_REGEX, (url: any) => {
      //   urlsBrowser.push(url);
      // })

      this.postEvent.emit({
        mediaFiles: this.urls,
        urls: urlsBrowser,
        typePost: this.typePost,
        title: this.title,
        content: this.content,
        filePosts: this.filePosts,
        blobFileAudio: this.blobFileAudio,
        urlAudio: this.urlAudio,
      });

      this.close();
    }
  }

  startRecord() {
    this.openPopupRecordAudio = true;
    this.content = '';
  }

  itemSelected(event: any) {
    // console.log('line 965', event);
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: this.typePost == PostEnum.DISCUSSION ? [] : _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;

  detectFiles(event: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    var isCheckMedia = false;
    var isCheckAudio = false;

    /* check maximum 4 files */
    if (event.target.files.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      if (file) {
        LoadingService.$(true);
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (file.type.split('/')[0] === 'audio') {
          isCheckAudio = true;
        }
        if (
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'image'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          //return;
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              //console.log(f_duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                //return;
              }
            });
        }

        reader.onload = () => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          file.id = new Date().getTime();
          this.filePosts.push(file);
          this.urls.push({
            name: file.name,
            format: file.type.split('/')[0],
            data: this.domSanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(blob!)
            ),
            description: '',
            file: file,
            id: file.id,
            originalTypeFile: file.type,
          });

          /* check maximum 4 files */
          if (this.urls.length > 4) {
            this.showErrorMaximumFiles = true;
            LoadingService.$(false);
            //event.target.value = null;
            //return;
          }
          event.target.value = null;
        };
      }
      LoadingService.$(false);
    }
  }

  removeAsset(fileName: string) {
    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urls.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urls.length; index++) {
      /*check mixed file */
      if (this.urls[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urls[index].format === 'image' ||
        this.urls[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urls[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
        this.urls[index].file.type.split('/')[0] === 'video' ||
        this.urls[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urls[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }

  clickOnSide(event: Event) {
    event.stopPropagation();
  }

  changeShowEmoji(event: any) {
    event.stopPropagation();
    this.showEmoji = !this.showEmoji;
  }

  addEmoji(event: any) {
    event.$event.stopPropagation();
    if (this.content) {
      this.content += event.emoji.native;
    } else this.content = event.emoji.native;
  }

  detect() {

    var urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    if (this.content == '') {
      this.metaDataItem = {};
      return;
    }

    let wordSearch = this.content;

    setTimeout(() => {
      if (wordSearch == this.content) {
        this.content?.replace(URL_REGEX, (url: any) => {
          if (url != '') {
            // LoadingService.$(true);
            this._metaReader.apiLinkPreviewGetLinkPreviewPost$Json({
              body: [decodeURIComponent(url)]
            }).pipe(
              debounceTime(2000),
              tap(res => {
                this.metaDataItem = res.data![0] || {};
                // LoadingService.$(false);
              }),
              catchError(err => {
                // LoadingService.$(false);
                return of(err);
              })
            ).subscribe();
          }
        });
      }
    }, 2000);

  }

  gotoUrl() {
    window.open(this.metaDataItem.url!, '_blank');
  }

  getCharacterLeft() {
    let numberLeft: number;
    let isFullCharTitle: boolean = false;
    let isFullCharContent: boolean = false;
    numberLeft = 40 - _.toArray(this.title.trimStart().trimEnd())?.length! > 0 ? 40 - _.toArray(this.title.trimStart().trimEnd()).length! : 0
    isFullCharContent = _.toArray(this.content?.trimStart().trimEnd())?.length! > 500 ? true : false;
    isFullCharTitle = _.toArray(this.title?.trimStart().trimEnd())?.length! > 40 ? true : false;
    return { numberLeft, isFullCharTitle, isFullCharContent };
  }
}
