import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface SendEmailViewModel {
  appId: string;
  name: string;
  to: string;
  subject: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class EmailServiceCustom {
  private static readonly AppId = 'inlivewith';
  private static readonly ListName = ['contact@inlivewith.com'];

  private static readonly ApiUrl = environment.EMAIL_API_URL;

  static readonly httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );

  public static SendEmail(email: string, data: string) {
    const subject = 'INLIVEWITH: ACTIVATION LINK';
    const message = `${window.origin}/reset-password${data}`;
    const name = this.ListName[0];
    const url = `${this.ApiUrl}/email/smtp/send`;
    const body: SendEmailViewModel = {
      appId: this.AppId,
      name: name,
      to: email,
      subject: subject,
      message: message,
    };
    return this.httpClient.post(url, body);
  }

  public static SendEmailActiveAccount(email: string, data: string) {
    const subject = 'INLIVEWITH: ACTIVATION LINK';
    const name = this.ListName[0];
    const url = `${this.ApiUrl}/email/smtp/send`;
    const body: SendEmailViewModel = {
      appId: this.AppId,
      name: name,
      to: email,
      subject: subject,
      message: data,
    };
    return this.httpClient.post(url, body);
  }

  public static SendEmailInvite(email: string, subject: string, data: string) {
    const name = 'noreply@inlive-group.com';
    const url = `${this.ApiUrl}/email/smtp/send`;
    const body: SendEmailViewModel = {
      appId: 'inlivegroup',
      name: name,
      to: email,
      subject: subject,
      message: data,
    };

    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/*+json');

    return this.httpClient.post(url, body, { headers });
  }
}
