<p-dialog [(visible)]="openPopupEdit" [modal]="true" [draggable]="false" (onHide)="closePopup()" [resizable]="false"
    [closable]="true">
    <div style="width: 800px;">
        <div>
            <span class="text-white fs-22">
                {{'CLUB_INFOR' | translate}}
            </span>
        </div>
        <div class="flex align-items-center mt-5">
            <div class="zone-avt mr-2">
                <img [src]="imgBlob ? imgBlob : club!.avatar!" class="fit-size">
            </div>
            <i *ngIf="imgBlob" class="pi pi-times cancel-image" (click)="cancelImage()"></i>
            <span *ngIf="!imgBlob" class="label ml-2 cursor-pointer" (click)="inputFile.click()">
                {{'CLUB_PICK_IMAGE' | translate}}
            </span>
            <input style="display: none" id="file-avatar" type="file" #inputFile (change)="selectAvatar($event)"
                accept="image/png, image/jpeg">
        </div>
        <div class="mt-5">
            <input class="w-100" type="text" #name="ngModel" [(ngModel)]="tempClubName"
                (ngModelChange)="checkClubNameExistRegister($event)" pInputText [placeholder]="club!.name!"
                [pattern]="REGEX" />
        </div>
        <div *ngIf="checkClubName" class="mt-1">
            <small *ngIf="checkClubNameExist" class="text-error">
                {{'CLUB_NAME_EXISTED' | translate}}
            </small>
        </div>
        <small *ngIf="name.errors?.['pattern'] || isCharLetter(tempClubName)" class="text-error">
            {{'CLUB_NAME_PATTERN_CHARACTERS' | translate}}
        </small>
        <div class="mt-1"
            *ngIf="(isCharLetter(form?.controls?.name?.value) && getCharacterLeft().numberLeft == 0 && getCharacterLeft().isFullChar) || (getCharacterLeft().numberLeft > 38 && getCharacterLeft().numberLeft < 40)">
            <small class="text-error">
                {{'CREATE_CLUB_NAME_CHECK_LENGTH' | translate}}
            </small>
        </div>
        <div class="mt-7">
            <span class="text-white fs-18">
                {{'CLUB_CATEGORY' | translate}}
            </span>
        </div>
        <div class="flex align-items-center mt-3">
            <img *ngIf="!selectedCategory" [src]="getIcon()" class="mr-3">
            <img *ngIf="selectedCategory" [src]="selectedCategory!.url" class="mr-3">

            <p-dropdown [options]="listCategories" optionLabel="label" [showClear]="false"
                placeholder="{{club!.category! | translate}}" (onChange)="selectCategogy($event)">
                <ng-template let-category pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img [src]="category.url" style="width: 18px" />
                        <div>{{ category.value | translate }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="mt-7">
            <span class="text-white fs-18">
                {{'THEMED_WITH' | translate}}
            </span>
        </div>
        <form [formGroup]="form" class="mt-5">
            <div class="flex flex-column align-items-center justify-content-between" *ngIf="form?.controls">
                <div class="flex w-100">
                    <div class="flex w-50">
                        <p-checkbox class="fs-14 mr-3" [binary]="true" [(ngModel)]="celebListExist"
                            formControlName="isLinked" label="{{'MY_CLUB_IS_ABOUT' | translate}}">
                        </p-checkbox>
                    </div>
                    <div class="flex w-50 flex-column">

                        <div class="flex flex-column w-100 autoComplete-celeb">
                            <div class=" flex w-100">
                                <p-autoComplete class="w-100" (onSelect)="selectedSuggestion($event)"
                                    [disabled]="this.listCelebs.length >= 10" (onKeyUp)="SuggestionEntered($event)"
                                    (completeMethod)="searchSuggestionList($event.query)"
                                    [readonly]="!form.controls.isLinked.value" [suggestions]="suggestionList"
                                    [minLength]="2" formControlName="textSearchSuggestion" field="name">
                                </p-autoComplete>
                                <button pButton pRipple type="button" label="{{'ADD' | translate}}"
                                    class="p-button-text text-white" [disabled]="this.listCelebs.length >= 10"
                                    (click)="addToSuggestionListCallee()">
                                </button>
                            </div>
                        </div>

                        <span class="text-error mt-1" *ngIf="listCelebs.length >= 10">
                            {{'MAX_10_CELEB' | translate}}
                        </span>
                    </div>

                </div>

                <div class="flex w-100">
                    <div class="flex w-50"></div>
                    <div class="flex flex-wrap align-items-center w-50" *ngIf="celebListExist">
                        <div class="flex align-items-center mr-2 mt-2"
                            *ngFor="let newHashtag of listCelebs; let i = index">
                            <div class="flex align-items-center hashtag">
                                <span class="flex pl-2"> {{newHashtag | limitString:false:20}}</span>
                                <i class="flex pi pi-times-circle m-2 cursor-pointer"
                                    (click)="listCelebs.splice(i, 1)"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </form>
        <div class="mt-7">
            <span class="text-white fs-18">
                {{'CLUB_RELATED_WORD' | translate}}
            </span>
        </div>
        <div *ngIf="currentHashtags && listHashtagByCategory" class="flex flex-wrap mt-3 zone-hashtag">
            <div class="flex" *ngFor="let h of listHashtagByCategory">
                <div class="flex m-1 cursor-pointer hashtag" (click)="selectHashtag(h)"
                    [class.selected-hashtag]="getItemsSelected(h)">
                    <span class="m-2">{{h.name}}</span>
                </div>
            </div>
        </div>
        <span *ngIf="textErrorMax5" class="fs-16 text-error mt-4 ml-1">Max 5</span>
        <span *ngIf="currentHashtags.length! < 3" class="fs-16 text-error mt-4 ml-1">
            {{'SELECTION_MIN_HASHTAG' | translate}}
        </span>

        <div class="mt-4 flex justify-content-center">
            <button pButton label="{{'REGISTER' | translate}}"
                [disabled]="currentHashtags.length! < 3 || checkClubNameExist"
                class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                (click)="editClub()"></button>
        </div>
    </div>
</p-dialog>


<ng-container *ngIf="showDialogUploadAvatar">
    <app-upload-avatar [imageChangedEvent]="imageChangedEvent" [(showDialog)]="showDialogUploadAvatar"
        (upload)="upload($event)" (showDialogChange)="imageChangedEvent = ''"></app-upload-avatar>
</ng-container>