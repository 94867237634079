import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaCommentAlbumAssetResponseModel, MediaCommentPostResponseModel } from 'src/app/core/api/be/models';

@Component({
  selector: 'app-show-multi-media',
  templateUrl: './show-multi-media.component.html',
  styleUrls: ['./show-multi-media.component.scss']
})
export class ShowMultiMediaComponent implements OnInit {

  @Input() openShowMutilMedia = false;
  @Input() urls: MediaCommentPostResponseModel[] | MediaCommentAlbumAssetResponseModel[] = [];
  @Input() activeIndex: number = 0;

  @Output() openShowMutilMediaChange = new EventEmitter();
  @Output() urlsChange = new EventEmitter();
  @Output() activeIndexChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.activeIndexChange.emit(0);
    this.urlsChange.emit([]);
    this.openShowMutilMediaChange.emit(false);
  }


  checkTypeMedia(asset: MediaCommentPostResponseModel | MediaCommentAlbumAssetResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url!) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaCommentPostResponseModel | MediaCommentAlbumAssetResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  checkOrientationImage(event: Event, asset: any) {
    const smallImage = event?.target as HTMLImageElement;
    let imageWidth = Number(smallImage.naturalWidth);
    let imageHeight = Number(smallImage.naturalHeight);
    asset.isLandscape = imageWidth / imageHeight > 1;
  }

  checkOrientationVideo(event: Event, asset: any) {
    const smallImage = event?.target as HTMLVideoElement;
    let imageWidth = Number(smallImage.videoWidth);
    let imageHeight = Number(smallImage.videoHeight);
    asset.isLandscape = imageWidth / imageHeight >= 1;
  }
}
