<div class="label" *ngIf="hashtag.listClub && hashtag.listClub.length! > 0">
    {{ 'CLUBS_WITH' | translate}} {{hashtag.name}}
</div>

<div class="list-goinlive">
    <div class="flex justify-content-between zone-arrow"
        *ngIf="hashtag.listClub && hashtag.listClub.length! > 0">
        <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(hashtag.id!)"></i>
        <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(hashtag.id!, false)"></i>
    </div>
    <div class="flex list-club" [id]="hashtag.id!">
        <ng-container *ngFor="let club of hashtag.listClub; let i = index">
            <div class="flex m-2">
                <app-card-club [club]="club"></app-card-club>
            </div>
        </ng-container>
    </div>
</div>