<p-dialog [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()" [closable]="true">
    <div class="card">
        <div class="flex flex-column align-items-center justify-content-center text-center">
            
            <div class="flex position-fixed">
                <div class="zone-avt">
                    <img style="width: 60px !important; height: 60px !important;" src="../../../assets/images/svgs/calendars.svg" class="fit-size">
                </div>
            </div>
    
            <div class="label mt-5 fs-16">
                {{'EVENT_CONFIRM_TITLE' | translate}}
            </div>
            <div class="mt-3 fs-16 text-white">
                {{'EVENT_CONFIRM_SUBTITLE' | translate}}
            </div>

            <div class="text-white flex flex-column text-center mt-5 mb-5">
                <span class="fs-20">
                    {{event.name! | limitString:false:20}}
                </span>
                <div class="fs-16 flex flex-column align-items-center mt-2">
                    <div class="flex align-items-center">
                        <img class="mr-1" src="../../../assets/images/svgs/calendar.svg">
                        <span class="ml-2">
                            {{ event.dateStart + 'Z' | date:'dd MMM yy HH:mm' }} - {{event.dateEnd + 'Z' | date:'dd MMM yy HH:mm'}}
                        </span>
                    </div>
                    <div class="flex align-items-center mt-2">
                        <i class="pi pi-map-marker mr-1"></i>
                        <span>{{event.place}}</span>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-dialog>
