import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  AccountResponseGeneral,
  AccountWithRelationshipResponseModel,
} from 'src/app/core/api/be/models';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { FriendRelationService } from 'src/app/core/api/be/services/friend-relation.service';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-account-info',
  templateUrl: './card-account-info.component.html',
  styleUrls: ['./card-account-info.component.scss'],
})
export class CardAccountInfoComponent implements OnInit {
  @Input() account!: AccountWithRelationshipResponseModel;
  @Input() accountId!: string;
  @Input() isMobile!: boolean;

  openConfirmFriend!: boolean;

  defaultAvatar = defaultAvatar;
  accountOwnId = AuthService.GetAccountInfo()?.id!;

  constructor(
    private _friendService: FriendRelationService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    console.log(!this.account.isFriend && !this.account.isAddFriend && (this.accountOwnId !== this.account.id))
  }

  accountInfo() {
    if (AuthService.GetAccountInfo().id! !== this.account.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: this.account?.id! },
      });
    }
  }

  doAction() {
    this._friendService
      .inlivewithFriendRelationAddFriendPost$Json({
        accountRelatingId: AuthService.GetAccountInfo().id!,
        accountRelatedId: this.account.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.openConfirmFriend = false;
            if (this.account.isPrivate) {
              this.account.isAddFriend = true;
            }
            else {
              this.account.isFriend = true;
            }
          }
        })
      )
      .subscribe();
  }
}
