import { AuthService } from 'src/app/core/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-card-invite-join-club',
  templateUrl: './card-invite-join-club.component.html',
  styleUrls: ['./card-invite-join-club.component.scss'],
})
export class CardInviteJoinClubComponent implements OnInit {
  @Input() invitation!: NotificationResponse;
  defaultAvatar = defaultAvatar;
  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {}

  doAction(isAccept: boolean) {
    let body = {
      accountId: AuthService.GetAccountInfo()?.id!,
      approval: isAccept,
      clubId: this.invitation!.clubId!,
      notificationId: this.invitation!.id!,
    };
    this._accountService
      .inlivewithAccountApprovalInvitaionJoinClubPost$Json({ body })
      .subscribe(() => window.location.reload());
  }
}
