import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-club-notification-setting',
  templateUrl: './club-notification-setting.component.html',
  styleUrls: ['./club-notification-setting.component.scss'],
})
export class ClubNotificationSettingComponent implements OnInit {
  @Input() isMobile!: boolean;
  @Input() showDialog!: boolean;
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;

  @Output() showDialogChange = new EventEmitter();

  isTurnOffPublicationsInliverNoti!: boolean;
  isTurnOffPublicationsAdminNoti!: boolean;
  isTurnOffMentionsNoti!: boolean;

  constructor(private _clubService: ClubService) {}

  ngOnInit(): void {
    this.getNotiSetting().subscribe();
  }

  onHide() {
    this.showDialogChange.emit(false);
  }

  editNotiSetting() {
    this._clubService
      .inlivewithClubEditNotificationInClubForMemberPut$Json({
        body: {
          accountRequestId: AuthService.GetAccountInfo().id!,
          clubId: this.club.id!,
          isTurnOffMentionsNoti: !this.isTurnOffMentionsNoti,
          isTurnOffPublicationsAdminNoti: !this.isTurnOffPublicationsAdminNoti,
          isTurnOffPublicationsInliverNoti:
            !this.isTurnOffPublicationsInliverNoti,
        },
      })
      .pipe(
        tap((res) => {
          this.onHide();
        })
      )
      .subscribe();
  }

  getNotiSetting() {
    LoadingService.$(true);

    return this._clubService
      .inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json({
        accountId: AuthService.GetAccountInfo().id!,
        clubId: this.club.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.isTurnOffPublicationsInliverNoti =
              !res.data?.isTurnOffPublicationsInliverNoti!;
            this.isTurnOffPublicationsAdminNoti =
              !res.data?.isTurnOffPublicationsAdminNoti!;
            this.isTurnOffMentionsNoti = !res.data?.isTurnOffMentionsNoti!;
          }
        }),
        finalize(() => LoadingService.$(false))
      );
  }

  save() {
    this.editNotiSetting();
  }
}
