<div class="card-apply">
    <div class="flex align-items-center justify-content-between">
        <div class="flex">
            <div class="zone-avatar">
                <img [src]="invitation!.senderAvatar ?? defaultAvatar" class="fit-size">
            </div>
            <div class="flex align-items-center w-80" style="white-space: pre-line;">
                <span class="ml-2" [innerHTML]="invitation!.subTitle!"></span>
            </div>
        </div>
        <div class="flex">
            <div class="flex align-items-center justify-content-end w-100 time-noti">
                {{invitation!.updateDate! + 'Z' | date: 'dd/MM/yy, HH:mm'}}
            </div>
        </div>
    </div>
    <div class="flex ml-6 mt-2">
        <button pButton label="{{'REFUSE' | translate}}"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="doAction(false)">
        </button>
        <button pButton label="{{'ACCEPT' | translate}}"
            class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
            (click)="doAction(true)">
        </button>
    </div>
</div>