import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { GoInliveResponseModelWithClubDetail } from 'src/app/core/api/be/models';
import { GoInliveService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-goinlive-celeb',
  templateUrl: './card-goinlive-celeb.component.html',
  styleUrls: ['./card-goinlive-celeb.component.scss'],
})
export class CardGoinliveCelebComponent implements OnInit {
  @Input() live!: GoInliveResponseModelWithClubDetail;
  isShowReviewCamera: boolean = false;

  constructor(
    private _router: Router,
    private _goInliveService: GoInliveService
  ) {}

  ngOnInit(): void {}

  // getIcon() {
  //   let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter(
  //     (c: any) => c.value == this.live?.club?.category
  //   )[0]?.url!;
  //   return iconUrl;
  // }

  checkGoInlive() {
    if (this.live.isPrivate) {
      this.isShowReviewCamera = true;
    } else {
      this.goToViewGoInlive();
    }
  }

  join() {
    this._goInliveService
      .inlivewithGoInliveGetInvitationByGoInliveIdGet$Json({
        goInliveId: this.live.id!,
      })
      .pipe(
        map((res) => {
          let invitationId = res.data?.page?.filter(
            (data) => data.accountId! == AuthService.GetAccountInfo().id!
          )[0].id;

          return invitationId?.toString()!;
        }),
        mergeMap((invitationId) => this.handleInvite(true, invitationId))
      )
      .subscribe((res) => {
        if (res.res == 0) {
          this.goToViewGoInlive();
        }
      });
  }

  handleInvite(isAccept: boolean, invitationId: string) {
    let body = {
      invitationId,
      accountId: AuthService.GetAccountInfo().id!,
      isAccept: isAccept,
      isDeny: !isAccept,
    };

    return this._goInliveService.inlivewithGoInliveSelectGoInlivedInvitePut$Json(
      {
        body,
      }
    );
  }

  goToViewGoInlive() {
    this._router.navigate(['go-inlive-view'], {
      queryParams: {
        goInliveId: this.live?.id,
        channel: this.live?.channelName,
        hostUid: this.live?.author?.agoraUid!,
        mode: this.live.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
        type: this.live.type!,
        isCelebLive: true,
      },
    });
  }
}
