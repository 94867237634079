/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountClubModel } from '../models/account-club-model';
import { AccountWithRoleInGoInliveResponseModelListResponseModel } from '../models/account-with-role-in-go-inlive-response-model-list-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CelebrityAliasClubsResponseModelListResponseModel } from '../models/celebrity-alias-clubs-response-model-list-response-model';
import { CelebrityAliasClubsResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-clubs-response-model-pagination-model-response-model';
import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { CelebrityAnalyzeActivitiesResponseModelResponseModel } from '../models/celebrity-analyze-activities-response-model-response-model';
import { CelebrityAnalyzeInliverResponseModelResponseModel } from '../models/celebrity-analyze-inliver-response-model-response-model';
import { CelebrityClubAnalyzeActivitiesResponseModelResponseModel } from '../models/celebrity-club-analyze-activities-response-model-response-model';
import { CelebrityClubsResponseModelPaginationModelResponseModel } from '../models/celebrity-clubs-response-model-pagination-model-response-model';
import { CelebrityPostResponeModelPaginationModelResponseModel } from '../models/celebrity-post-respone-model-pagination-model-response-model';
import { CelebrityPostWithDetailClubResponeModelResponseModel } from '../models/celebrity-post-with-detail-club-respone-model-response-model';
import { ClubResponseInCelebritysPostPaginationModelResponseModel } from '../models/club-response-in-celebritys-post-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { CommentPostForCelebrityResponseModelPaginationModelResponseModel } from '../models/comment-post-for-celebrity-response-model-pagination-model-response-model';
import { GoInliveAccountWatchingResponseModel } from '../models/go-inlive-account-watching-response-model';
import { GoInliveClubWithMembersResponeModelListResponseModel } from '../models/go-inlive-club-with-members-respone-model-list-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class CelebrityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithCelebrityUpdateIsFavouritedStatusPut
   */
  static readonly InlivewithCelebrityUpdateIsFavouritedStatusPutPath = '/inlivewith/Celebrity/UpdateIsFavouritedStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityUpdateIsFavouritedStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityUpdateIsFavouritedStatusPut$Plain$Response(params?: {
    body?: AccountClubModel
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityUpdateIsFavouritedStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityUpdateIsFavouritedStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityUpdateIsFavouritedStatusPut$Plain(params?: {
    body?: AccountClubModel
  }): Observable<StringResponseModel> {

    return this.inlivewithCelebrityUpdateIsFavouritedStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityUpdateIsFavouritedStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityUpdateIsFavouritedStatusPut$Json$Response(params?: {
    body?: AccountClubModel
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityUpdateIsFavouritedStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityUpdateIsFavouritedStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityUpdateIsFavouritedStatusPut$Json(params?: {
    body?: AccountClubModel
  }): Observable<StringResponseModel> {

    return this.inlivewithCelebrityUpdateIsFavouritedStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet
   */
  static readonly InlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGetPath = '/inlivewith/Celebrity/GetFavouritedClubClubHaveTheMostMember/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetFavouritedClubClubHaveTheMostMemberAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubHaveTheMostMemberGet
   */
  static readonly InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath = '/inlivewith/Celebrity/GetTheClubHaveTheMostMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTopUpClubCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTopUpClubCelebrityIdGetPath = '/inlivewith/Celebrity/GetTopUpClub/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopUpClubCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopUpClubCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopUpClubCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopUpClubCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopUpClubCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopUpClubCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopUpClubCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopUpClubCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopUpClubCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopUpClubCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopUpClubCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopUpClubCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGetPath = '/inlivewith/Celebrity/GetClubTotalAnalyzeForCelebrity/{clubId}/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Plain$Response(params: {
    clubId: string;
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Plain(params: {
    clubId: string;
    celebrityId: string;
  }): Observable<CelebrityClubAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityClubAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Json$Response(params: {
    clubId: string;
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Json(params: {
    clubId: string;
    celebrityId: string;
  }): Observable<CelebrityClubAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetClubTotalAnalyzeForCelebrityClubIdCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityClubAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityClubAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet
   */
  static readonly InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath = '/inlivewith/Celebrity/GetTheMostRecentClubWithRecentPosts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubsHaveTheMostActivesGet
   */
  static readonly InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath = '/inlivewith/Celebrity/GetTheClubsHaveTheMostActives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListCelebrityAliasGet
   */
  static readonly InlivewithCelebrityGetListCelebrityAliasGetPath = '/inlivewith/Celebrity/GetListCelebrityAlias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityAliasGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityAliasGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityAliasGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityAliasGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityAliasGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityAliasGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityAliasGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityAliasGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet
   */
  static readonly InlivewithCelebrityGetListCelebrityAliasByStatusStatusGetPath = '/inlivewith/Celebrity/GetListCelebrityAliasByStatus/{status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Plain$Response(params: {
    status: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityAliasByStatusStatusGetPath, 'get');
    if (params) {
      rb.path('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Plain(params: {
    status: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Json$Response(params: {
    status: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityAliasByStatusStatusGetPath, 'get');
    if (params) {
      rb.path('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Json(params: {
    status: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityAliasByStatusStatusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebritySearchCelebrityAliasKeywordGet
   */
  static readonly InlivewithCelebritySearchCelebrityAliasKeywordGetPath = '/inlivewith/Celebrity/SearchCelebrityAlias/{keyword}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response(params: {
    keyword: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchCelebrityAliasKeywordGetPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain(params: {
    keyword: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchCelebrityAliasKeywordGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response(params: {
    keyword: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchCelebrityAliasKeywordGetPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Json(params: {
    keyword: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTotalInliverForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeInliverResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>) => r.body as CelebrityAnalyzeInliverResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeInliverResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>) => r.body as CelebrityAnalyzeInliverResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath = '/inlivewith/Celebrity/GetTheClubHaveTheMostMemberThatCelebLinked/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGetPath = '/inlivewith/Celebrity/GetClubForProfileByCelebId/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    isOrderByDateCreate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('isOrderByDateCreate', params.isOrderByDateCreate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Plain(params: {
    celebrityId: string;
    isOrderByDateCreate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    isOrderByDateCreate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('isOrderByDateCreate', params.isOrderByDateCreate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Json(params: {
    celebrityId: string;
    isOrderByDateCreate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetClubForProfileByCelebIdCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTotalAnalyzeForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityInviteInGoInlivePost
   */
  static readonly InlivewithCelebrityInviteInGoInlivePostPath = '/inlivewith/Celebrity/InviteInGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityInviteInGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityInviteInGoInlivePost$Plain$Response(params?: {
    goInliveId?: string;
    body?: Array<AccountClubModel>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityInviteInGoInlivePostPath, 'post');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityInviteInGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityInviteInGoInlivePost$Plain(params?: {
    goInliveId?: string;
    body?: Array<AccountClubModel>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithCelebrityInviteInGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityInviteInGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityInviteInGoInlivePost$Json$Response(params?: {
    goInliveId?: string;
    body?: Array<AccountClubModel>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityInviteInGoInlivePostPath, 'post');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityInviteInGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityInviteInGoInlivePost$Json(params?: {
    goInliveId?: string;
    body?: Array<AccountClubModel>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithCelebrityInviteInGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityHandleInvitationGoInlivePost
   */
  static readonly InlivewithCelebrityHandleInvitationGoInlivePostPath = '/inlivewith/Celebrity/HandleInvitationGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityHandleInvitationGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityHandleInvitationGoInlivePost$Plain$Response(params?: {
    goInliveId?: string;
    status?: boolean;
    body?: AccountClubModel
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityHandleInvitationGoInlivePostPath, 'post');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('status', params.status, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityHandleInvitationGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityHandleInvitationGoInlivePost$Plain(params?: {
    goInliveId?: string;
    status?: boolean;
    body?: AccountClubModel
  }): Observable<GoInliveAccountWatchingResponseModel> {

    return this.inlivewithCelebrityHandleInvitationGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModel>) => r.body as GoInliveAccountWatchingResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityHandleInvitationGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityHandleInvitationGoInlivePost$Json$Response(params?: {
    goInliveId?: string;
    status?: boolean;
    body?: AccountClubModel
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityHandleInvitationGoInlivePostPath, 'post');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('status', params.status, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityHandleInvitationGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityHandleInvitationGoInlivePost$Json(params?: {
    goInliveId?: string;
    status?: boolean;
    body?: AccountClubModel
  }): Observable<GoInliveAccountWatchingResponseModel> {

    return this.inlivewithCelebrityHandleInvitationGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModel>) => r.body as GoInliveAccountWatchingResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet
   */
  static readonly InlivewithCelebrityGetAccountsJoiningGoInliveByCelebGetPath = '/inlivewith/Celebrity/GetAccountsJoiningGoInliveByCeleb';

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Plain$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetAccountsJoiningGoInliveByCelebGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Plain(params?: {
    goInliveId?: string;
  }): Observable<GoInliveClubWithMembersResponeModelListResponseModel> {

    return this.inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>) => r.body as GoInliveClubWithMembersResponeModelListResponseModel)
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Json$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetAccountsJoiningGoInliveByCelebGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Json(params?: {
    goInliveId?: string;
  }): Observable<GoInliveClubWithMembersResponeModelListResponseModel> {

    return this.inlivewithCelebrityGetAccountsJoiningGoInliveByCelebGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveClubWithMembersResponeModelListResponseModel>) => r.body as GoInliveClubWithMembersResponeModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebritySearchAccountsJoiningGoInliveGet
   */
  static readonly InlivewithCelebritySearchAccountsJoiningGoInliveGetPath = '/inlivewith/Celebrity/SearchAccountsJoiningGoInlive';

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchAccountsJoiningGoInliveGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchAccountsJoiningGoInliveGet$Plain$Response(params?: {
    goInliveId?: string;
    name?: string;
  }): Observable<StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchAccountsJoiningGoInliveGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchAccountsJoiningGoInliveGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchAccountsJoiningGoInliveGet$Plain(params?: {
    goInliveId?: string;
    name?: string;
  }): Observable<AccountWithRoleInGoInliveResponseModelListResponseModel> {

    return this.inlivewithCelebritySearchAccountsJoiningGoInliveGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>) => r.body as AccountWithRoleInGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchAccountsJoiningGoInliveGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchAccountsJoiningGoInliveGet$Json$Response(params?: {
    goInliveId?: string;
    name?: string;
  }): Observable<StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchAccountsJoiningGoInliveGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchAccountsJoiningGoInliveGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchAccountsJoiningGoInliveGet$Json(params?: {
    goInliveId?: string;
    name?: string;
  }): Observable<AccountWithRoleInGoInliveResponseModelListResponseModel> {

    return this.inlivewithCelebritySearchAccountsJoiningGoInliveGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRoleInGoInliveResponseModelListResponseModel>) => r.body as AccountWithRoleInGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTopPublicationForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetListPublicationForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGetPath = '/inlivewith/Celebrity/GetPostCelebrityById/{postCelebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Plain$Response(params: {
    postCelebrityId: string;
    limitClub?: number;
  }): Observable<StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('postCelebrityId', params.postCelebrityId, {});
      rb.query('limitClub', params.limitClub, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Plain(params: {
    postCelebrityId: string;
    limitClub?: number;
  }): Observable<CelebrityPostWithDetailClubResponeModelResponseModel> {

    return this.inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>) => r.body as CelebrityPostWithDetailClubResponeModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Json$Response(params: {
    postCelebrityId: string;
    limitClub?: number;
  }): Observable<StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('postCelebrityId', params.postCelebrityId, {});
      rb.query('limitClub', params.limitClub, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Json(params: {
    postCelebrityId: string;
    limitClub?: number;
  }): Observable<CelebrityPostWithDetailClubResponeModelResponseModel> {

    return this.inlivewithCelebrityGetPostCelebrityByIdPostCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostWithDetailClubResponeModelResponseModel>) => r.body as CelebrityPostWithDetailClubResponeModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGetPath = '/inlivewith/Celebrity/GetClubAnalyzeForPostCelebrityById/{postCelebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Plain$Response(params: {
    postCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('postCelebrityId', params.postCelebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Plain(params: {
    postCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseInCelebritysPostPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>) => r.body as ClubResponseInCelebritysPostPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Json$Response(params: {
    postCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('postCelebrityId', params.postCelebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Json(params: {
    postCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseInCelebritysPostPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetClubAnalyzeForPostCelebrityByIdPostCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseInCelebritysPostPaginationModelResponseModel>) => r.body as ClubResponseInCelebritysPostPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath = '/inlivewith/Celebrity/GetListClubHasUseCelebritySuggestName/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain(params: {
    celebrityId: string;
  }): Observable<CelebrityAliasClubsResponseModelListResponseModel> {

    return this.inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>) => r.body as CelebrityAliasClubsResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json(params: {
    celebrityId: string;
  }): Observable<CelebrityAliasClubsResponseModelListResponseModel> {

    return this.inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelListResponseModel>) => r.body as CelebrityAliasClubsResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet
   */
  static readonly InlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGetPath = '/inlivewith/Celebrity/ListClubHasLinkForDiscover/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    isOrderByDateCreated?: boolean;
    numberClubToTake?: number;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('isOrderByDateCreated', params.isOrderByDateCreated, {});
      rb.query('numberClubToTake', params.numberClubToTake, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Plain(params: {
    celebrityId: string;
    isOrderByDateCreated?: boolean;
    numberClubToTake?: number;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    isOrderByDateCreated?: boolean;
    numberClubToTake?: number;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('isOrderByDateCreated', params.isOrderByDateCreated, {});
      rb.query('numberClubToTake', params.numberClubToTake, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Json(params: {
    celebrityId: string;
    isOrderByDateCreated?: boolean;
    numberClubToTake?: number;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityListClubHasLinkForDiscoverCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetCommentsOnPostPostIdGet
   */
  static readonly InlivewithCelebrityGetCommentsOnPostPostIdGetPath = '/inlivewith/Celebrity/GetCommentsOnPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetCommentsOnPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetCommentsOnPostPostIdGet$Plain$Response(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetCommentsOnPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetCommentsOnPostPostIdGet$Plain(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostForCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetCommentsOnPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>) => r.body as CommentPostForCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetCommentsOnPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetCommentsOnPostPostIdGet$Json$Response(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetCommentsOnPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetCommentsOnPostPostIdGet$Json(params: {
    postId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostForCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetCommentsOnPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostForCelebrityResponseModelPaginationModelResponseModel>) => r.body as CommentPostForCelebrityResponseModelPaginationModelResponseModel)
    );
  }

}
