<p-dialog [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()"
    [closable]="true">
    <div>
        <div class="flex flex-column justify-content-center text-center">

            <span class="text-white fs-20">
                {{title}}
            </span>

            <div class="mt-3 fs-14 text-white">
                {{content}}
            </div>

        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle"
                class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle"
                class="p-button-red p-button-secondary text-black bg-white border-white ml-2"
                (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-dialog>