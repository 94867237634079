<p-sidebar [(visible)]="showDialog" position="bottom" [showCloseIcon]="true" [dismissible]="false">
    <div class="card">
        <div class="fex header fs-18">
            {{'CLUB_PRIVACY' | translate}}
        </div>

        <div class="flex mt-5 flex-column">
            <div class="flex">
                <div>
                    <p-radioButton inputId="public" name="groupname" [value]="false" [(ngModel)]="isPrivate">
                    </p-radioButton>
                </div>
                <div class="flex flex-column ml-3">
                    <label for="public" class="cursor-pointer text-white">
                        {{'CLUB_STATUS_PUBLIC' | translate}}
                    </label>
                    <span class="cursor-pointer" class="sub-text mt-2">
                        {{'CLUB_STATUS_PUBLIC_TITLE' | translate}}
                    </span>
                </div>
            </div>
            <div class="flex mt-4">
                <div>
                    <p-radioButton inputId="private" name="groupname" [value]="true" [(ngModel)]="isPrivate">
                    </p-radioButton>
                </div>
                <div class="flex flex-column ml-3 ">
                    <label for="private" class="cursor-pointer text-white">
                        {{'CLUB_STATUS_PRIVATE' | translate}}
                    </label>
                    <span class="cursor-pointer" class="sub-text mt-2">
                        {{'CLUB_STATUS_PRIVATE_TITLE' | translate}}
                    </span>
                </div>
            </div>
        </div>

        <div class="flex justify-content-center mt-5">
            <button pButton label="{{'REGISTER' | translate}}" class="btn-custom" (click)="submit()"></button>
        </div>
    </div>
</p-sidebar>