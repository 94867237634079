import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info-club-skeleton',
  templateUrl: './account-info-club-skeleton.component.html',
  styleUrls: ['./account-info-club-skeleton.component.scss']
})
export class AccountInfoClubSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
