<p-dialog [(visible)]="showListClubsPosted" [resizable]="false" [closable]="true" [draggable]="false" [modal]="true"
    (onHide)="onHide()">
    <div class="zone">
        <div class="zone-header" *ngIf="!!listClubsJoined&&!!topClub">
            <div class="title">
                {{'LOOK_CLUBS' | translate}} ({{listClubsJoined.length! + topClub.length! }})
            </div>
            <div class="subtitle">
                {{(fromGoInliveCeleb ? 'LOOK_CLUBS_GOINLIVE' : 'LOOK_CLUBS_SUBTITLE') | translate}}
            </div>
            <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%;position: relative;">
                <i class="pi pi-search icon-search"></i>
                <input style="width:100%" type="text" pInputText [(ngModel)]="searchText"
                    placeholder="{{'INPUT_SEARCH_CLUBS' | translate}}" />
            </div>
        </div>


        <div class="title text-white mt-3 fs-22" *ngIf="topClub?.length! > 0">
            {{'TOP_CLUB' | translate}}
        </div>
        <div class="flex" *ngIf="topClub?.length! > 0">
            <div class="flex flex-column m-2 top-club" *ngFor="let item of topClub; let i = index"
                (click)="item.isJoinedClub ? gotoDetail(item) : gotoClub(item) ">
                <img [src]="getUrl(item!.avatar!) | fetchUrlAvatarClub" alt="" class="fit-size avt">
                <img class="icon-category" [src]="getIcon(item)">
                <span class="index"> {{i+1}}</span>

                <div class="club-name">
                    {{item.name}}
                </div>

                <div class="text-gray fs-12 mt-1">
                    {{item.totalMember}} {{ (item!.totalMember! > 1 ? 'MEMBERS_LOWER' : 'MEMBER_LOWER') | translate}}
                </div>
            </div>
        </div>

        <div class="zone-clubs">
            <div class="title mt-3" *ngIf="listClubsJoined?.length! > 0">
                {{'FOLLOWED_CLUBS' | translate}} ({{listClubsJoined!.length!}})
            </div>
            <div class="list-club-joined" *ngIf="listClubsJoined?.length! > 0">
                <div class="flex align-items-center justify-content-between mt-3 mb-3"
                    *ngFor="let club of listClubsJoined | filter:searchText">
                    <div class="flex align-items-center cursor-pointer w-100 cursor-hover" (click)="gotoDetail(club)">
                        <div class="zone-avt">
                            <img class="fit-size border-image" [src]="club.avatar | fetchUrlAvatarClub">
                        </div>
                        <div class="flex align-items-center">
                            <span class="ml-2 text-white">{{club.name}}</span>
                            <img class="ml-2" *ngIf="club.isPrivate" [src]="'../../../assets/images/svgs/lock.svg'">
                        </div>
                    </div>
                </div>
            </div>

            <div class=" title mt-3" *ngIf="listClubsHaventJoined!.length! > 0">
                {{'SUGGESTION_CLUBS' | translate}} ({{listClubsHaventJoined!.length!}})
            </div>
            <div class="list-club-joined" *ngIf="listClubsHaventJoined!.length! > 0">
                <div class="flex align-items-center justify-content-between mt-3 mb-3"
                    *ngFor="let club of listClubsHaventJoined | filter:searchText">
                    <div class="flex align-items-center cursor-pointer w-100 cursor-hover" (click)="gotoClub(club)">
                        <div class="zone-avt">
                            <img class="fit-size border-image" [src]="club.avatar | fetchUrlAvatarClub">
                        </div>
                        <div class="flex align-items-center">
                            <span class="ml-2 text-white">{{club.name}}</span>
                            <img class="ml-2" *ngIf="club.isPrivate" [src]="'../../../assets/images/svgs/lock.svg'">
                        </div>
                    </div>
                    <div>
                        <button pButton label="{{'CLUB_REJOIN' | translate}}"
                            class="p-button-red p-button-secondary bg-white text-black"
                            (click)="onApplyClub(club)"></button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="flex justify-content-center align-items-center" *ngIf="listClubsJoined.length == 0">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div> -->
    </div>
</p-dialog>

<ng-container *ngIf="openPopupAcceptRuleClub && currentClubCeleb">
    <app-club-rules [clubCeleb]="currentClubCeleb" [routeTogoDetailPost]="true"
        [(isShowRulesClub)]="openPopupAcceptRuleClub"></app-club-rules>
</ng-container>