<p-dialog [(visible)]="openPopup" [modal]="true" [draggable]="false" [modal]="true" (onHide)="hidePopup()">
    <div class="card w-80">
        <ng-container *ngIf="STEP == 1">
            <div class="flex align-items-center justify-content-center text-white">
                <span class="label text-center fs-22">{{'GOINLIVE_INVITE' | translate}}</span>
            </div>
            <div class="flex align-items-center justify-content-center text-white">
                <span class="text-center fs-16 mt-3">{{'GOINLIVE_INVITE_TITLE' | translate}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="STEP == 2">
            <div class="flex align-items-center justify-content-center text-white">
                <span class="label text-center fs-22">{{'MANAGE_MY_GUEST' | translate}}
                    ({{listUidParticipantJoined!.length!}})</span>
            </div>
        </ng-container>

        <div class="flex w-100 align-items-center search mt-5 mb-2" style="width:100%;position: relative;">
            <i class="pi pi-search icon-search"></i>
            <input style="width:100%" [(ngModel)]="searchText" type="text" pInputText (ngModelChange)="searchInliver()"
                placeholder="{{'INPUT_SEARCH_MEMBER' | translate}}" />
        </div>



        <ng-container
            *ngIf="type == 'GOINLIVE_PLUS' && listInliverInvitedPending && listInliverInvitedPending!.length > 0">
            <div class="fs-20 text-white mt-3">
                {{'WAITING' | translate}}
            </div>
            <div class="flex align-items-center mt-3 mb-3" *ngFor="let inliver of listInliverInvitedPending">
                <div class="zone-avt">
                    <img class="avatar cursor-pointer" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                    <img *ngIf="inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'" class="inliver-plus"
                        src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
                </div>
                <label class="ml-2 label text-white cursor-pointer"
                    [for]="inliver?.id!">{{inliver?.account!.nickName! | limitString:false:40}}</label>

                <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="cancelInvite(inliver)"
                    class="ml-auto p-button-outlined p-button-secondary">
                </button>

            </div>
        </ng-container>

        <ng-container
            *ngIf="type !== 'GOINLIVE_PLUS' && listInliverInvitedPending && listInliverInvitedPending!.length > 0">
            <div class="fs-20 text-white mt-3">
                {{'WAITING' | translate}}
            </div>
            <div class="flex align-items-center mt-3 mb-3" *ngFor="let inliver of listInliverInvitedPending">

                <div class="zone-avt">
                    <img class="avatar cursor-pointer" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                    <img *ngIf="inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'" class="inliver-plus"
                        src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
                </div>
                <label class="ml-2 label text-white cursor-pointer" [for]="inliver?.id!">{{inliver!.nickName! | limitString:false:40}}</label>

                <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="cancelInvite(inliver)"
                    class="ml-auto p-button-outlined p-button-secondary">
                </button>

            </div>
        </ng-container>


        <ng-container *ngIf="STEP == 2">

            <div class="inliver-normal" *ngIf="listParticipantJoined?.length! > 0 | filter: searchText">

                <div class="flex text-white" *ngIf="STEP == 2">
                    <span class="fs-20 mt-2">{{'INVITER' | translate}}</span>
                </div>

                <div class="list-inliverplus" *ngIf="listParticipantJoined.length > 0">
                    <div class="flex align-items-center mt-5 mb-3" *ngFor="let inliver of listParticipantJoined">
                        <div style="position: relative;">
                            <div class="zone-avt">
                                <img class="avatar cursor-pointer"
                                    [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                                <img *ngIf="inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'" class="inliver-plus"
                                    src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">

                                <img class="hand-yellow" src="../../../../assets/images/svgs/hand-yellow.svg" width="20"
                                    height="20" alt="" srcset=""
                                    *ngIf="listUidRaiseHand.includes(inliver.agoraUid!.toString())">

                                <img class="hand-yellow" src="../../../../assets/images/svgs/sand-clock.svg" width="20"
                                    height="20" alt="" srcset=""
                                    *ngIf="listUidWaitingUser.includes(inliver.agoraUid!.toString())">
                            </div>
                        </div>
                        <div class="flex align-items-center">
                            <div class="ml-2 label text-white">{{inliver.nickName! | limitString:false:40}}</div>
                            <div *ngIf="inliver.isCelebrity"> ({{'CELEBRITY' | translate}})</div>
                        </div>
                        <ng-container *ngIf="STEP == 2">
                            <button pButton type="button" label="{{'RETIRE' | translate}}"
                                (click)="showConfirmKickUser = true; participantSelectedForKick = inliver"
                                class="ml-auto p-button-outlined p-button-secondary">
                            </button>


                            <p-dialog [(visible)]="showConfirmKickUser" [modal]="true" [draggable]="false"
                                [modal]="true">
                                <div class="flex flex-column align-items-center justify-content-center">

                                    {{'TITLE_CONFIRM_KICK_VIEWER_GOINLIVE' | translate}}

                                    <div class="flex mt-2">

                                        <button pButton type="button" label="{{'CANCEL' | translate}}"
                                            (click)="showConfirmKickUser = false"
                                            class="m-1 p-button-outlined p-button-secondary">
                                        </button>

                                        <button pButton type="button" label="{{'CONFIRM' | translate}}"
                                            (click)="kickParticipant(participantSelectedForKick)"
                                            class="m-1 p-button-outlined p-button-secondary">
                                        </button>
                                    </div>
                                </div>
                            </p-dialog>

                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <div class="inliver-normal" *ngIf="listInliver?.length! > 0">

                <div class="flex text-white" *ngIf="STEP == 2">
                    <span class="fs-20 mt-2">{{'PARTICIPANTS' | translate}}</span>
                </div>

                <div class="list-inliverplus" *ngIf="listInliver.length > 0">
                    <div class="flex align-items-center mt-5 mb-3" *ngFor="let inliver of listInliver">
                        <p-checkbox class="mr-4 pl-1 cursor-pointer" name="group2" value="inliver"
                            [value]="inliver?.id!"
                            [disabled]="inliverInvited.length >= LIMIT_PARTICIPANT && !inliverInvited.includes(inliver?.id!)"
                            *ngIf="STEP == 1" [(ngModel)]="inliverInvited" [inputId]="inliver?.id!">
                        </p-checkbox>
                        <div class="zone-avt">
                            <img class="avatar cursor-pointer" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                            <img *ngIf="inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'" class="inliver-plus"
                                src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
                        </div>
                        <div class="flex align-items-center">
                            <label class="cursor-pointer" [for]="inliver?.id!">
                                <div class="ml-2 label text-white cursor-pointer">{{inliver.nickName! | limitString:false:40}}</div>
                            </label>
                            <div *ngIf="inliver.isCelebrity"> ({{'CELEBRITY' | translate}})</div>
                        </div>
                        <ng-container *ngIf="STEP == 2">
                            <!-- <ng-container *ngIf="expression"></ng-container> -->
                            <!-- <button pButton type="button" label="{{'ADD' | translate}}"  [disabled]="inliverInvited.length >= 2 && inliverInvited.includes(inliver?.id!)"
                                class="ml-auto p-button-outlined p-button-secondary" (click)="invite(inliver.id!)">
                            </button> -->
                            <button pButton type="button" label="{{'ADD' | translate}}" [id]="inliver?.id!"
                                [disabled]="listUidParticipantJoined.length >= LIMIT_PARTICIPANT"
                                class="ml-auto p-button-outlined p-button-secondary" (click)="invite(inliver.id!)">
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="flex pt-3 pb-3 justify-content-center" *ngIf="STEP == 1">
                <button pButton type="submit" label="{{'ADD' | translate}}" (click)="valider()"
                    *ngIf="listInliver?.length! > 0" class="mt-5 p-button-outlined p-button-secondary label">
                </button>
            </div>
        </ng-container>
    </div>
</p-dialog>