/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiInfosService } from './services/api-infos.service';
import { HealthcheckService } from './services/healthcheck.service';
import { LinkPreviewService } from './services/link-preview.service';
import { MetaReadderGrpcServiceService } from './services/meta-readder-grpc-service.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiInfosService,
    HealthcheckService,
    LinkPreviewService,
    MetaReadderGrpcServiceService,
    ApiConfiguration
  ],
})
export class MetaReaderModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<MetaReaderModule> {
    return {
      ngModule: MetaReaderModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MetaReaderModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MetaReaderModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
