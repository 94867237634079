import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-review-camera',
  templateUrl: './review-camera.component.html',
  styleUrls: ['./review-camera.component.scss'],
})
export class ReviewCameraComponent implements OnInit, AfterViewInit, OnDestroy {
  mediaDevices = navigator.mediaDevices;

  @Output() close = new EventEmitter();
  @Output() join = new EventEmitter();

  stream: any;

  constructor() {}
  ngAfterViewInit(): void {
    var video = document.getElementById('videoId') as HTMLVideoElement;

    this.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        this.stream = stream;
        video!.srcObject = stream;
        video!.addEventListener('loadedmetadata', () => {
          video!.play();
        });
      })
      .catch();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.stream.getTracks().forEach(function (track: any) {
      track.stop();
    });
  }
}
