import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mention-legal',
  templateUrl: './mention-legal.component.html',
  styleUrls: ['./mention-legal.component.scss']
})
export class MentionLegalComponent implements OnInit {

  constructor(
    public _router: Router,
  ) { }

  pdfSrc = "../../../assets/file-comunity.pdf"

  ngOnInit(): void {
  }

}
