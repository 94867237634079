import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import {
  AlbumAsset,
  AlbumAssetResponseGeneral,
  CommentAlbumAssetResponseModel,
  ReactInputModel,
} from 'src/app/core/api/be/models';
import { AlbumService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-mobile-view-media-album',
  templateUrl: './mobile-view-media-album.component.html',
  styleUrls: ['./mobile-view-media-album.component.scss'],
})
export class MobileViewMediaAlbumComponent implements OnInit {
  @Input() showSidebar: boolean = true;
  @Output() showSidebarChange = new EventEmitter<boolean>();

  @Input() showComment: boolean = false;
  @Output() showCommentChange = new EventEmitter<boolean>();

  @Input() showListLike: boolean = false;
  @Output() showListLikeChange = new EventEmitter<boolean>();

  @Input() listAssets!: AlbumAsset[];
  currentAsset!: AlbumAssetResponseGeneral;

  @Output() indexAssetChange = new EventEmitter<number>();
  @Input() indexAsset!: number;

  datepipe: DatePipe = new DatePipe('en-US');

  listComments!: CommentAlbumAssetResponseModel[];
  dateCreated!: string | null;

  defaultAvatar = defaultAvatar;
  isLandscape!: boolean;

  // Handle gesure ===
  touchendX = 0;
  touchendY = 0;
  touchstartX = 0;
  touchstartY = 0;
  // ==================

  constructor(private _albumService: AlbumService) { }

  ngOnInit(): void {
    this.currentAsset = this.listAssets[this.indexAsset] || this.listAssets[0];

    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
  }

  getDetailAsset(event: any) {
    console.log('getDetailAsset');

    let asset = event.page - this.indexAsset;
    if (asset > 0) {
      let checkPlaying = <HTMLVideoElement>(
        document.getElementById(`${this.listAssets[event.page - 1]?.id}`)
      );

      if (checkPlaying) {
        checkPlaying.pause();
      }
    } else {
      let checkPlaying = <HTMLVideoElement>(
        document.getElementById(`${this.listAssets[event.page + 1]?.id}`)
      );

      if (checkPlaying) {
        checkPlaying.pause();
      }
    }

    this.currentAsset = this.listAssets[event.page];
    this.refresh();
    this.indexAsset = event.page;
    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
  }

  getAsset() {
    this.currentAsset = this.listAssets[this.indexAsset];
    this.refresh();
    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
  }

  onLoad(event: any) {
    const smallImage = event?.target! as HTMLImageElement;
    let imageWidth = Number(smallImage.width);
    let imageHeight = Number(smallImage.height);
    this.isLandscape = imageWidth / imageHeight >= 1;
  }

  checkTypeMedia(asset: AlbumAsset): string {
    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1]!.startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1]!.startsWith('v')) {
      return 'VIDEO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: AlbumAsset) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  refreshAlbum() {
    return this._albumService
      .inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json({
        albumAssetId: this.currentAsset.id!,
        accountRequestId: AuthService.GetAccountInfo().id!,
      })
      .pipe(
        tap((res) => {
          this.currentAsset = res.data!;
        })
      );
  }

  refreshComment() {
    return this._albumService
      .inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json(
        {
          accountRequestId: AuthService.GetAccountInfo().id!,
          albumAssetId: this.currentAsset.id!,
          limit: 10,
          page: 1,
        }
      )
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listComments = res.data?.page!;
          }
        })
      );
  }

  refresh() {
    return forkJoin([this.refreshComment(), this.refreshAlbum()]).subscribe();
  }

  react() {
    this.currentAsset.isReacted = !this.currentAsset.isReacted;
    if (this.currentAsset.isReacted) {
      this.currentAsset.totalReact!++;
      this.likeALbum(true, 'REACTION_LIKE');
    } else {
      this.currentAsset.totalReact!--;
      this.likeALbum(false, null);
    }
  }

  likeALbum(isAppearing: boolean, type: any) {
    let body: ReactInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      dependObjectId: this.currentAsset.id,
      isAppearing: isAppearing,
      type: isAppearing ? type : null,
    };
    return this._albumService
      .inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json({
        body: body,
      })
      .pipe(
        tap((res) => { }),
        mergeMap((res) => this.refreshAlbum()),
        tap((res) => {
          if (res.res == 0) {
            this.currentAsset = res.data!;
          }
        }),
        catchError((err) => {
          this.currentAsset.isReacted = !this.currentAsset.isReacted;
          if (this.currentAsset.isReacted) {
            this.currentAsset.totalReact!++;
          } else {
            this.currentAsset.totalReact!--;
          }
          return of(err);
        })
      )
      .subscribe();
  }

  onHide() {
    this.showSidebar = false;
    this.showSidebarChange.emit(this.showSidebar);

    this.indexAssetChange.emit(this.indexAsset);

    this.currentAsset = {};
  }

  onHideComment() {
    this.showComment = false;
    this.showCommentChange.emit(this.showSidebar);
  }

  onHideListLike() {
    this.showListLike = false;
    this.showListLikeChange.emit(this.showListLike);
  }

  scrollTo(isLeft: boolean = true) {
    let a = document.getElementById('listMedia')!;
    a.scrollLeft = isLeft
      ? a.scrollLeft - window.innerWidth
      : a.scrollLeft + window.innerWidth;
  }

  next() {
    if (this.indexAsset == this.listAssets.length - 1) {
      return;
    }

    this.indexAsset++;
    this.currentAsset = this.listAssets[this.indexAsset];
    this.refresh();
  }

  pre() {
    if (this.indexAsset == 0) {
      return;
    }

    this.indexAsset--;
    this.currentAsset = this.listAssets[this.indexAsset];
    this.refresh();
  }

  touchend(event: any) {
    this.touchendX = event.changedTouches[0]?.screenX!;
    this.touchendY = event.changedTouches[0]?.screenY!;
    this.handleGesure();
  }

  handleGesure() {
    if (this.touchendX < this.touchstartX) {
      // swiped left
      this.pre();
      return;
    }
    if (this.touchendX > this.touchstartX) {
      // swiped right
      this.next();
      return;
    }
    if (this.touchendY < this.touchstartY) {
      // swiped down
    }
    if (this.touchendY > this.touchstartY) {
      // swiped left
    }
    if (this.touchendY == this.touchstartY) {
      // tap
    }
  }
}
