import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberAndRoleModel  } from 'src/app/core/api/be/models';
import { AccountService, ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-card-club-account-info',
  templateUrl: './card-club-account-info.component.html',
  styleUrls: ['./card-club-account-info.component.scss'],
})
export class CardClubAccountInfoComponent implements OnInit {
  @Input() club!: ClubResponseWithTotalMemberAndRoleModel;
  @Input() accountId!: string;
  @Input() isMobile!: boolean;

  isBanned!: boolean;
  isJoined!: boolean;
  isApplied!: boolean;
  openPopupAcceptRuleClub!: boolean;
  nextStep!: boolean;

  roleInClub!: string;

  constructor(
    private _clubService: ClubService,
    private _accountService: AccountService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.isJoined = this.club.isJointClub!;
    this.roleInClub = this.club?.roleInClub!;
    this.isApplied = this.club.isPendingJointPrivateClub!;
  }

  getRoleToString(role: string) {
    if (this.accountId == this.club.authorId!) {
      return 'Créateur';
    }

    if (role == 'ROLE_ADMIN') {
      return 'Admin';
    } else return 'Membre';
  }

  onApplyClub() {
    let countClick = 0;
    countClick++;
    if (countClick == 1) {
      this.openPopupAcceptRuleClub = true;
    }
  }

  detailClub() {
    this._router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: this.club?.id! },
    });
  }
  
  getUrl(asset: any) {
    if(asset == null){
      asset = defaultAvatar;
      return;
    }

    if (!asset!.includes('?')) {

    } else {

      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset;
  }
}
