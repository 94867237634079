import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import {
  AccountResponseWithRoleInCLubModel,
  AlbumAsset,
  CommentPostResponseModel,
  CommentPostResponseModelResponseModel,
  MediaCommentPost,
  MediaCommentPostResponseModel,
  MediaPost,
  MediaPostResponseModel,
  PostResponseModel,
  ReactInputModel,
} from 'src/app/core/api/be/models';
import { ClubService, PostService } from 'src/app/core/api/be/services';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  MENTION_REGEX,
  URL_REGEX,
  base64ToBlob,
  defaultAvatar,
  getFileExtension,
  typeAudio,
} from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { NgWaveformComponent } from 'ng-waveform';
import _ from 'lodash';
import { EEvent, EventService } from 'src/app/core/services/event.service';
@Component({
  selector: 'app-view-media-post',
  templateUrl: './view-media-post.component.html',
  styleUrls: ['./view-media-post.component.scss'],
})
export class ViewMediaPostComponent implements OnInit, AfterViewInit {
  @Output() showDialogChange = new EventEmitter<boolean>();
  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;
  @ViewChild('listComments') listCommentsEl!: ElementRef<HTMLDivElement>;
  @ViewChild('toggleEmoji') toggleEmoji!: ElementRef;

  @Input() showDialog!: boolean;
  @Input() listAssets!: MediaPost[];

  @Output() indexAssetChange = new EventEmitter<number>();
  @Input() indexAsset!: number;

  @Output() deletePostEvent = new EventEmitter();

  private _post!: PostResponseModel;
  @Input() set post(value: PostResponseModel) {
    if (value) {
      this._notificationService.joinGroup(value.id!);
      this._post = value;
    }
  }
  get post() {
    return this._post;
  }

  private listUserForMention!: AccountResponseWithRoleInCLubModel[];
  @Input() set listMember(value: AccountResponseWithRoleInCLubModel[]) {
    if (value) {
      this.listUserForMention = value;

      let stageIsPrivate = this.post.stage?.isPrivate;
      let mentionList = value!.filter((inliver) =>
        stageIsPrivate
          ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
          : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
          inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
      )!;

      mentionList.forEach((data) => {
        if (data.id == AuthService.GetAccountInfo().id) {
          return;
        }
        this.listMention.push({
          username: data.nickName?.trimStart(),
          name: data.nickName?.trimStart(),
          img: data.avatar,
          id: data.id,
        });
      });
      this.initConfigMention();
    } else {
      this.getListInlivers();
    }
  }
  get listMember() {
    return this.listUserForMention;
  }

  @Input() isJoined!: boolean;
  @Input() isAdmin!: boolean;

  @Output() askJoinClub = new EventEmitter();

  datepipe: DatePipe = new DatePipe('en-US');

  currentAsset!: AlbumAsset;
  dateCreated!: string | null;

  defaultAvatar = defaultAvatar;
  isLandscape!: boolean;

  listMention: any[] = [];
  tribute!: any;
  mentionConfig: any = {};
  page = 1;

  items!: MenuItem[];

  tempComment!: CommentPostResponseModel;

  openPopupRecordAudio: boolean = false;
  openPopupRecordAudioChild: boolean = false;

  currentReplyComment!: CommentPostResponseModel;
  contentReply: string = '';

  content: string = '';
  urls: any[] = [];
  filePosts: any[] = [];
  urlAudio!: string;
  blobFileAudio!: Blob;
  format!: string;

  isHaveParent!: boolean;
  isOverflow!: boolean;
  isAcceptToViewMenu!: boolean;
  isShowPopupConfirm!: boolean;
  isOwner!: boolean;
  openPopupReport!: boolean;
  applyClub!: boolean;
  openLikePopup!: boolean;
  showEmoji = false;
  isDone = false;
  isLoading = false;
  isEditComment: boolean = false;
  typeOfComment!: string;

  urlAudioChild!: string;
  blobFileAudioChild!: Blob;
  urlsChild: any[] = [];
  filePostsChild: any[] = [];
  comments: CommentPostResponseModel[] = [];

  filesDelete: any[] = [];
  filesEdit: any[] = [];

  postId!: string;
  clubId!: string;

  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  countClicked = 0;
  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;
  isValidToAddAssets: boolean = true;
  isValidToAddAudio: boolean = true;
  showEditRule = false;

  subscription = new Subscription();

  refetchWhenReceiveNoti$ = this._notificationService.isReloadComment$$.pipe(
    tap((commentId) => {
      //this.getPostById();
      if (commentId) {
        //this.getPostById();
        this.refreshComments(commentId);
      }
    })
  );

  handlingAfterGetData$ = new Subject();
  currentUser = AuthService.GetAccountInfo();

  constructor(
    public _router: Router,
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    private _translateService: TranslateService,
    private _activeRoute: ActivatedRoute,
    private _notificationService: NotificationServicee,
    private _clubService: ClubService,
    private eventService: EventService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleEmoji?.nativeElement!) {
        this.showEmoji = false;
      }
    });
    this._activeRoute.queryParams.subscribe((p) => {
      if (p.postId!) {
        this.postId = p.postId!;
        this.clubId = p.clubId!;
      }
    });
  }

  ngAfterViewInit(): void {
    this.addEventCheckVideo();
  }

  ngOnInit(): void {
    this.currentAsset = this.listAssets[this.indexAsset];
    this.subscription.add(this.refetchWhenReceiveNoti$.subscribe());
    this.getCommentByPostId().subscribe();
    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
    this._notificationService.onReceiveMessage(
      (accountId: string, data: any) => {
        if (
          data &&
          data.data.id &&
          this.comments.findIndex((c) => c.id === data.data.id) < 0 &&
          !data.fromDirectly
        ) {
          if (data.isHaveParent) {
            let index = this.comments?.findIndex(
              (x) => x.id == data.currentReplyCommentId!
            )!;
            if (index > -1) {
              let indexChild = this.comments![index].inverseParent!.findIndex(
                (x) => x.id == data.data.id
              )!;
              if (indexChild > -1) {
                return;
              }
              this.comments![index].inverseParent?.push(data.data!);
            }
            return;
          } else {
            this.comments.unshift(data.data);
          }

          this.scrollToBottom();
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  onHide() {
    if (this.postId!) {
      this._router.navigate(['manage-club', 'post-detail'], {
        queryParams: { postId: this.post.id!, clubId: this.post.clubId!, stageId: this.post.stageId! },
      });
    }

    this.showDialogChange.emit(this.showDialog);
    this.indexAssetChange.emit(this.indexAsset);
    this._notificationService.leaveGroup(this.post.id!);
    this.currentAsset = {};
  }

  getDetailAsset(event: any) {
    let asset = event.page - this.indexAsset;
    if (asset > 0) {
      let checkPlaying = <HTMLVideoElement>(
        document.getElementById(`${this.listAssets[event.page - 1]?.id + 'v'}`)
      );
      if (checkPlaying) {
        checkPlaying.pause();
      }
    } else {
      let checkPlaying = <HTMLVideoElement>(
        document.getElementById(`${this.listAssets[event.page + 1]?.id + 'v'}`)
      );
      if (checkPlaying) {
        checkPlaying.pause();
      }
    }

    let checkPlaying = <HTMLVideoElement>(
      document.getElementById(`${this.listAssets[event.page]?.id + 'v'}`)
    );
    if (checkPlaying) {
      checkPlaying.play();
    }

    this.currentAsset = this.listAssets[event.page];
    this.indexAsset = event.page;
    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
  }

  getAsset() {
    this.currentAsset = this.listAssets[this.indexAsset];
    this.dateCreated = this.datepipe.transform(
      this.currentAsset?.dateCreated,
      'dd/MM/YYYY - HH:mm'
    );
  }

  timeAgo = (date: string) => {
    const currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();

    if (moment.utc().diff(date, 'days') >= 7) {
      return moment
        .utc(date)
        .locale(currentLang ? currentLang : 'fr')
        .format('L');
    }

    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ')
      ? text
        .replace('il y a ', '')
        .replace('une', ' 1')
        .replace('jour', ' j')
        .replace('minute', ' min')
        .replace('heure', ' h')
        .replace('s', '')
        .replace('quelque secondes', '1s')
        .replace('un', ' 1')
      : text;

    return text;
  };

  removeAsset(fileName: string) {
    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urls.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urls.length; index++) {
      /*check mixed file */
      if (this.urls[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urls[index].format === 'image' ||
        this.urls[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urls[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
        this.urls[index].file.type.split('/')[0] === 'video' ||
        this.urls[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urls[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  startRecord(isParent: boolean) {
    if (!this.isJoined) {
      this.applyClub = true;
      return;
    }

    if (isParent) {
      this.openPopupRecordAudioChild = true;
      this.urlAudioChild = '';
      this.contentReply = '';
    } else {
      this.content = '';
      this.openPopupRecordAudio = true;
      this.urlAudio = '';
    }
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  urlify(text: any) {
    let mentionRegex = /\B@\w+/g;

    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName!.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replace('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  likePost() {
    if (!this.isJoined) {
      this.applyClub = true;
      return;
    }
    this.post.isReacted = !this.post.isReacted;
    if (this.post.isReacted) {
      this.post.totalReact!++;
      this.postLikePost(true, 'REACTION_LIKE');
    } else {
      this.post.totalReact!--;
      this.postLikePost(false, null);
    }
  }

  postLikePost(isAppearing: boolean, type: any) {
    let body: ReactInputModel = {
      clubId: this.post.clubId!,
      accountId: AuthService.GetAccountInfo().id!,
      dependObjectId: this.post.id,
      isAppearing: isAppearing,
      type: isAppearing ? type : null,
      stageId: this.post.stageId!
    };
    return this._postService
      .inlivewithPostReactReactOnPostPost$Json({
        body: body,
      })
      .subscribe(
        (res) => {
          // if (res.res == -2) {
          //   this.deletePostEvent.emit(this.post.id!);
          // }
        },
        () => {
          this.post.isReacted = !this.post.isReacted;
          if (this.post.isReacted) {
            this.post.totalReact!++;
          } else {
            this.post.totalReact!--;
          }
        }
      );
  }

  updatePost() {
    return this._postService.inlivewithPostGetByIdPostIdGet$Json(
      {
        postId: this.post.id!,
        clubId: this.post.clubId!,
        stageId: this.post.stageId!
      }
    );
  }

  detectFiles(event1: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    var isCheckMedia = false;
    var isCheckAudio = false;

    /* check maximum 4 files */
    if (event1.target.files.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    }

    for (let index = 0; index < event1.target.files.length; index++) {
      const file = event1.target.files[index];
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event1.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (file.type.split('/')[0] === 'audio') {
          isCheckAudio = true;
        }
        if (
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'image'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          //return;
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              console.log(f_duration);

              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                //return;
              }
            });
        }

        reader.onload = (event: any) => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          file.id = new Date().getTime();
          if (this.isHaveParent) {
            this.filePostsChild.push(file);
            this.urlsChild.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
            });
          } else {
            this.filePosts.push(file);
            this.urls.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
            });
          }

          /* check maximum 4 files */
          if (this.urls.length > 4 || this.urlsChild.length > 4) {
            this.showErrorMaximumFiles = true;
            LoadingService.$(false);
            //event.target.value = null;
            //return;
          }

          event1.target.value = null;
        };
      }
    }
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }

  createComment() {
    /**
     * For handle mentions, reopen later
     *
     */

    // let rg = /\B@\w+/g;
    // let listMentions = this.content.match(rg);

    // let mentionIds =
    //   this.listMention
    //     .filter((user) =>
    //       listMentions
    //         ?.map((value) => value.replace('@', ''))
    //         .includes(user.username.replaceAll(' ', '_'))
    //     )!
    //     .map((value) => value.id!)! || [];
    this.countClicked++;

    if (!this.isJoined) {
      this.countClicked = 0;
      this.applyClub = true;
      return;
    }

    LoadingService.$(true);
    this.tempComment = {};
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    if (!this.isEditComment) {
      var isCheckAudio = false;
      var isCheckMedia = false;

      if (this.urls.length > 4) {
        this.countClicked = 0;
        this.showErrorMaximumFiles = true;
        LoadingService.$(false);
      } else {
        this.showErrorMaximumFiles = false;
      }

      for (let index = 0; index < this.urls.length; index++) {
        /*check mixed file */
        if (this.urls[index].format === 'audio') {
          isCheckAudio = true;
        }
        if (
          this.urls[index].format === 'image' ||
          this.urls[index].format === 'video'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
        /* check Duration */
        if (
          typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
          this.urls[index].file.type.split('/')[0] === 'video' ||
          this.urls[index].file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(this.urls[index].file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              // console.log(f_duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              } else {
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              }
            });
        }
      }

      if (
        this.showErrorLimitSizeFile ||
        this.showErrorMaximumFiles ||
        this.showErrorDuration
      ) {
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        if (this.countClicked == 1) {
          let obs: Observable<CommentPostResponseModelResponseModel> =
            this._postService.inlivewithPostCommentCreateCommentPostPost$Json({
              body: {
                AuthorId: AuthService.GetAccountInfo().id!,
                Message: this.content,
                PostId: this.post.id!,
                StageId: this.post.stageId!,
                MediaCommentFiles: this.filePosts,
              },
            });

          obs.subscribe((res) => {
            if (res.res == 0) {
              let payload = {
                data: res.data!,
                isHaveParent: false,
                currentReplyCommentId:
                  this.currentReplyComment?.id! ?? 'currentReplyCommentId',
                fromDirectly: false,
              };
              this._notificationService.sendMessage(
                this.post?.id!,
                AuthService.GetAccountInfo().id!,
                payload
              );

              if (!this.isHaveParent) {
                document.getElementById('list-comment')!.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                  inline: 'nearest',
                });
              }

              this.tempComment = res.data!;

              this.content = '';
              this.urls = [];
              this.filePosts = [];
              this.currentReplyComment = {};
              this.urlAudio = '';
              this.post.totalComment!++;
              console.log(this.isHaveParent);

              this.countClicked = 0;
              LoadingService.$(false);
            }
            LoadingService.$(false);
          });
        }
      }
    }

    if (this.isEditComment) {
      if (this.filePosts.length! + this.filesDelete?.length! > 4) {
        this.showErrorMaximumFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        this.showErrorMaximumFiles = false;
      }

      if (this.typeOfComment == 'TEXT') {
        if (this.content.trim().length > 0 && this.countClicked == 1) {
          this._postService
            .inlivewithPostCommentEditContentCommentOnPostPut$Json({
              body: {
                commentId: this.currentReplyComment.id!,
                // mentionReceiverIds:
                //   mentionIds?.length! > 0 ? mentionIds : null,
                message: this.content,
              },
            })
            .pipe(
              tap((res) => {
                var index = this.comments.findIndex(
                  (x) => x.id === this.currentReplyComment.id
                );
                if (index > -1) {
                  this.comments[index].message! = res.data!.message!;
                } else {
                  var index = this.comments.findIndex(
                    (x) => x.id === res.data?.parentId!
                  );
                  var indexChild = this.comments[
                    index
                  ].inverseParent!.findIndex((x) => x.id === res.data?.id!);

                  if (indexChild > -1) {
                    this.comments[index].inverseParent![indexChild!].message! =
                      res.data!.message!;
                  }
                }
                this.comments.forEach((c) => {
                  c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
                });
                this.getItem(res.data!);
              }),
              tap((res) => {
                if (res.res == 0) {
                  this.content = '';
                  this.isHaveParent = false;
                  this.currentReplyComment = {};
                  this.isEditComment = false;
                }
              })
            )
            .subscribe(() => {
              this.countClicked = 0;
              LoadingService.$(false);
            });
          return;
        } else {
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeOfComment == 'UNKNOWN') {
        if (this.filesDelete.length! + this.filePosts.length > 0) {
          var isValidToAddAudio = true;
          var isValidToAddAssets = true;

          if (this.currentReplyComment.mediaCommentPosts?.length! > 0) {
            this.currentReplyComment.mediaCommentPosts!.forEach((x) => {
              if (this.checkTypeMedia(x) === 'AUDIO') {
                this.filePosts.forEach((x) => {
                  if (
                    x.type.split('/')[0] === 'image' ||
                    x.type.split('/')[0] === 'video'
                  ) {
                    isValidToAddAssets = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
              if (
                this.checkTypeMedia(x) === 'IMAGE' ||
                this.checkTypeMedia(x) === 'VIDEO'
              ) {
                this.filePosts.forEach((x) => {
                  if (x.type.split('/')[0] === 'audio') {
                    isValidToAddAudio = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
            });
          }

          if (this.filePosts.length > 0) {
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                isValidToAddAssets = false;
              }

              if (x.type.split('/')[0] === 'audio') {
                isValidToAddAudio = false;
              }

              if (!isValidToAddAssets && !isValidToAddAudio) {
                this.showErrorMixedFiles = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      // mentionReceiverIds:
                      //   mentionIds?.length! > 0 ? mentionIds : null,
                      message: this.content,
                    },
                  })
                  .pipe(
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        this.currentReplyComment = {};
                        this.isEditComment = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    this.scrollToBottom();
                    LoadingService.$(false);
                  });
              }
            }
          }
        }
        return;
      }

      if (
        this.typeOfComment == 'ONLY_MEDIAS' ||
        this.typeOfComment == 'ONLY_AUDIO'
      ) {
        if (this.typeOfComment == 'ONLY_AUDIO') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        } else if (this.typeOfComment == 'ONLY_MEDIAS') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        }

        if (
          this.showErrorLimitSizeFile ||
          this.showErrorMaximumFiles ||
          this.showErrorDuration
        ) {
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        } else {
          if (this.filesDelete.length! + this.filePosts.length == 0) {
            this.showEditRule = true;
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          }

          if (this.countClicked == 1) {
            if (this.filePosts.length >= 0 || this.urlAudio) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this.addMediaOnComment(this.currentReplyComment)
                  .pipe(
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                        this.comments.forEach((c) => {
                          c.inverseParent = _.orderBy(
                            c.inverseParent,
                            'dateCreated'
                          );
                        });
                      }
                    }),
                    tap(() => {
                      this.content = '';
                      this.isHaveParent = false;
                      this.urls = [];
                      this.filePosts = [];
                      this.urlAudio = '';
                      this.currentReplyComment = {};
                      this.isEditComment = false;
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    LoadingService.$(false);
                  });
              } else {
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }
            } else {
              this.content = '';
              this.isHaveParent = false;
              this.urls = [];
              this.filePosts = [];
              this.urlAudio = '';
              this.currentReplyComment = {};
              this.isEditComment = false;
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            }
          }
        }
      }

      if (this.typeOfComment == 'TEXT_AND_AUDIO') {
        if (
          this.content &&
          this.filePosts.length! + this.filesDelete?.length! > 0
        ) {
          this.showEditRule = false;
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      // mentionReceiverIds:
                      //   mentionIds?.length! > 0 ? mentionIds : null,
                      message: this.content,
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        this.currentReplyComment = {};
                        this.isEditComment = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    LoadingService.$(false);
                    this.countClicked = 0;
                    this.scrollToBottom();
                  });

                return;
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeOfComment == 'TEXT_AND_MEDIAS') {
        if (
          this.content &&
          this.filePosts.length! + this.filesDelete?.length! > 0
        ) {
          this.showEditRule = false;
          if (this.filePosts.length >= 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      // mentionReceiverIds:
                      //   mentionIds?.length! > 0 ? mentionIds : null,
                      message: this.content,
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        this.currentReplyComment = {};
                        this.isEditComment = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    LoadingService.$(false);
                    this.countClicked = 0;
                    this.scrollToBottom();
                  });

                return;
              }
            } else {
              LoadingService.$(false);
              this.countClicked = 0;
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }
    }
  }

  createReplyComment() {
    this.countClicked++;

    if (!this.isJoined) {
      this.countClicked = 0;
      this.applyClub = true;
      return;
    }

    LoadingService.$(true);
    this.tempComment = {};

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    var isCheckAudio = false;
    var isCheckMedia = false;

    if (this.urlsChild.length > 4) {
      this.countClicked = 0;
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      }
      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        //console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);
            // console.log(f_duration);
            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }

    if (
      this.showErrorLimitSizeFile ||
      this.showErrorMaximumFiles ||
      this.showErrorDuration
    ) {
      this.countClicked = 0;
      LoadingService.$(false);
      return;
    } else {
      if (this.countClicked == 1) {
        let obs: Observable<CommentPostResponseModelResponseModel> =
          this._postService.inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json(
            {
              commentId: this.currentReplyComment.id!,
              body: {
                AuthorId: AuthService.GetAccountInfo().id!,
                ParentId: this.currentReplyComment.id!,
                Message: this.contentReply,
                PostId: this.post.id!,
                MediaCommentFiles: this.filePostsChild,
                StageId: this.post.stageId!
              },
            }
          );

        obs.subscribe((res) => {
          if (res.res == 0) {
            let payload = {
              data: res.data!,
              isHaveParent: true,
              currentReplyCommentId:
                this.currentReplyComment?.id! ?? 'currentReplyCommentId',
              fromDirectly: false,
            };
            this._notificationService.sendMessage(
              this.post?.id!,
              AuthService.GetAccountInfo().id!,
              payload
            );

            this.tempComment = res.data!;

            this.contentReply = '';
            this.isHaveParent = false;
            this.urlsChild = [];
            this.filePostsChild = [];
            this.currentReplyComment = {};
            this.urlAudioChild = '';
            this.post.totalComment!++;
            this.countClicked = 0;
            LoadingService.$(false);
          }
          LoadingService.$(false);
        });
      }
    }
  }

  openAccountInfo(item: any, isBanned: boolean) {
    if (AuthService.GetAccountInfo().id! !== item && isBanned == false) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: item },
      });
    }
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  showPopupConfirm() {
    this.isShowPopupConfirm = true;
  }

  focusText(item: CommentPostResponseModel) {
    if (this.isHaveParent && item.id === this.currentReplyComment.id!) {
      this.isHaveParent = false;
    } else {
      this.isHaveParent = true;
      this.currentReplyComment = item;
      this.urlsChild = [];
      this.filePostsChild = [];
      this.urlAudioChild = '';

      setTimeout(() => {
        document.getElementById('replyComment')!.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }, 300);
    }
  }

  removeAssetChild(fileName: string) {
    this.urlsChild = this.urlsChild.filter((file) => file.id !== fileName);
    this.filePostsChild = this.filePostsChild.filter(
      (file) => file.id !== fileName
    );

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urlsChild.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urlsChild[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  removeAudioChild() {
    this.urlAudioChild = '';
    this.blobFileAudioChild;
  }

  sanitizeChild() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudioChild);
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio;
  }

  deleteComment() {
    this.comments = this.comments.filter(
      (c) => c.id != this.currentReplyComment.id
    );

    this.comments.forEach((res) => {
      res.inverseParent = res.inverseParent!.filter(
        (c) => c.id !== this.currentReplyComment.id
      );
    });

    this._postService
      .inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(
        {
          commentPostId: this.currentReplyComment.id!,
          accountRequestDeleteId: AuthService.GetAccountInfo().id!,
        }
      )
      .subscribe((res) => {
        if (res.res == 0) {
          this.currentReplyComment = {};
          this.isShowPopupConfirm = false;
        }
      });
  }

  getItem(comment: CommentPostResponseModel) {
    let items: MenuItem[] = [];
    this.isAcceptToViewMenu =
      comment?.authorId! === AuthService.GetAccountInfo()?.id!;
    items =
      this.isAdmin && !this.isAcceptToViewMenu
        ? [
          {
            icon: 'pi pi-users',
            label: this._translateService.instant('PROFILE_SEE'),
            command: (event: any) => {
              this.openAccountInfo(
                comment?.authorId!,
                comment.isBannedByClub!
              );
            },
          },
          {
            icon: 'pi pi-ban',
            label: this._translateService.instant('CLUB_BAN_USER'),
            command: () => { },
          },
          {
            icon: 'pi pi-trash',
            label: this._translateService.instant('COMMENT_DELETE'),
            command: () => this.showPopupConfirm(),
          },
        ]
        : this.isAcceptToViewMenu
          ? [
            {
              icon: 'pi pi-pencil',
              label: this._translateService.instant('MODIFIER'),
              command: () => {
                this.isEditComment = true;
                this.currentReplyComment = comment;
                this.content = comment.message!;
                this.typeOfComment = this.checkTypeToEditComment(comment);
                this.filesDelete = comment.mediaCommentPosts!;
                this.filesEdit = [];
              },
            },
            {
              icon: 'pi pi-trash',
              label: this._translateService.instant('DELETE'),
              command: () => {
                this.currentReplyComment = comment;
                this.showPopupConfirm();
              },
            },
          ]
          : [
            {
              icon: 'pi pi-users',
              label: this._translateService.instant('PROFILE_SEE'),
              command: () => {
                this.openAccountInfo(
                  comment?.authorId!,
                  comment.isBannedByClub!
                );
              },
            },
            {
              icon: 'pi pi-flag',
              disabled: comment.isReported,
              label: this._translateService.instant('COMMENT_REPORT'),
              command: () => {
                this.openPopupReport = true;
                this.currentReplyComment = comment;
              },
            },
          ];

    return items;
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  scrollToBottom() {
    if (this.listCommentsEl) {
      setTimeout(() => {
        this.listCommentsEl.nativeElement.scrollTop =
          this.listCommentsEl.nativeElement.scrollHeight;
      }, 10);
    }
  }

  getCommentByPostId(limit: number = 6) {
    LoadingService.$(true);
    return this._postService
      .inlivewithPostGetCommentsOnPostPostIdGet$Json({
        postId: this.post.id!,
        stageId: this.post.stageId!,
        limit: limit,
        page: this.page++,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.comments = [...this.comments, ...(res.data?.page ?? [])];
            this.comments.forEach((c) => {
              this.getItem(c);
            });

            this.comments.forEach((c) => {
              c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
            });

            if (res.data?.currentPage! == 1) {
              this.scrollToBottom();
            }
            if (res.data?.next == -1) {
              this.isDone = true;
            }
          } else if (res.res == -2) {
            this.deletePostEvent.emit();
          }

          LoadingService.$(false);
        })
      );
  }

  getPostById() {
    let body = {
      postId: this.post.id!,
      clubId: this.post.clubId!,
      stageId: this.post.stageId!,
    };
    this._postService
      .inlivewithPostGetByIdPostIdGet$Json(body)
      .subscribe((res) => {
        this.post = res.data!;
      });
  }

  openLikeZone() {
    if (!this.isJoined) {
      this.applyClub = true;
      return;
    } else {
      this.openLikePopup = true;
      return;
    }
  }

  onPlayButtonClick() {
    this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
    this.isPlayingAudio = !this.isPlayingAudio;
  }

  onTrackLoaded(event: any) { }

  onTrackRendered(event: any) { }

  onTimeUpdate(event: any) {
    this.timeOffAudio = event.time;
    if (event.progress >= 100) {
      this.isPlayingAudio = false;
    }
  }

  onDurationChange(event: any) {
    this.timeOffAudio = event;
  }

  onRegionChange(event: any) { }

  onPaused() { }

  getListInlivers() {
    return this._clubService
      .inlivewithClubGetAllMembersClubIdGet$Json({
        clubId: this.post.clubId!,
        limit: 100000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listMember = res.data?.page!;

            let stageIsPrivate = this.post.stage?.isPrivate;

            let mentionList = res.data?.page!.filter((inliver) =>
              stageIsPrivate
                ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
                : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
                inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
            )!;

            mentionList.forEach((data) => {
              if (data.id == AuthService.GetAccountInfo().id) {
                return;
              }
              this.listMention.push({
                username: data.nickName?.trimStart(),
                name: data.nickName?.trimStart(),
                img: data.avatar,
                id: data.id,
              });
            });

            this.initConfigMention();
          }
        })
      )
      .subscribe();
  }

  changeShowEmoji(event: any) {
    event.stopPropagation();
    this.showEmoji = !this.showEmoji;
  }

  addEmoji(event: any) {
    event.$event.stopPropagation();
    if (this.content) {
      this.content += event.emoji.native;
    } else this.content = event.emoji.native;
  }

  getPoster(url: string) {
    let _url = url.replace('file.mp4', 'poster.jpg');
    return _url;
  }

  loadMoreComment() {
    //let page = parseInt(`${commentParent.inverseParent?.length! / 3}`) + 1;
    this.getCommentByPostId().subscribe();
  }

  loadMoreCommentReply(commentParent: CommentPostResponseModel) {
    let page = parseInt(`${commentParent.inverseParent?.length! / 3}`) + 1;
    this._postService
      .inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json({
        commentId: commentParent.id!,
        postId: commentParent.postId!,
        stageId: this.post.stageId!,
        limit: 3,
        page: page,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            commentParent.inverseParent = _.orderBy(
              res.data?.page!.concat(commentParent.inverseParent!),
              'dateCreated'
            );
            commentParent.inverseParent = _.uniqBy(
              commentParent.inverseParent,
              'id'
            );
            res.data?.page!.length! < 3
              ? (commentParent.isContinueReply = false)
              : (commentParent.isContinueReply = true);
            let index = this.comments.findIndex(
              (x) => x.id == commentParent.id
            );
            this.comments[index] = commentParent;
          }
        })
      )
      .subscribe();
  }

  getCommentByCommentId(commendId: string) {
    return this._postService.inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json(
      {
        postId: this.post.id!,
        stageId: this.post.stageId!,
        commentId: commendId,
      }
    );
  }

  refreshComments(commentId: string) {
    this.getCommentByCommentId(commentId)
      .pipe(
        tap((res) => {
          var index = this.comments.findIndex((x) => x.id === commentId);
          if (index > -1) {
            this.comments[index] = res.data!;
          } else {
            var index = this.comments.findIndex(
              (x) => x.id === res.data?.parentId!
            );
            var indexChild = this.comments[index]?.inverseParent!.findIndex(
              (x) => x.id === res.data?.id!
            );

            if (indexChild > -1) {
              this.comments[index].inverseParent![indexChild!] = res.data!;
            }
          }
          this.comments.forEach((c) => {
            c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
          });
        })
      )
      .subscribe();
  }

  checkOrientationImage(event: Event, asset: any) {
    const smallImage = event?.target as HTMLImageElement;
    let imageWidth = Number(smallImage.naturalWidth);
    let imageHeight = Number(smallImage.naturalHeight);
    asset.isLandscape = imageWidth / imageHeight > 1;
  }

  checkOrientationVideo(event: Event, asset: any) {
    const smallImage = event?.target as HTMLVideoElement;
    let imageWidth = Number(smallImage.videoWidth);
    let imageHeight = Number(smallImage.videoHeight);
    asset.isLandscape = imageWidth / imageHeight >= 1;
  }

  checkProcessMediaFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkTypeToEditComment(comment: CommentPostResponseModel) {
    let type = '';
    if (comment.mediaCommentPosts?.length! > 0) {
      comment.mediaCommentPosts?.forEach((x) => {
        if (this.checkTypeMedia(x) == 'AUDIO') {
          if (comment.message!) {
            type = 'TEXT_AND_AUDIO';
          } else type = 'ONLY_AUDIO';
        } else if (
          this.checkTypeMedia(x) == 'IMAGE' ||
          this.checkTypeMedia(x) == 'VIDEO'
        ) {
          if (comment.message!) {
            type = 'TEXT_AND_MEDIAS';
          } else type = 'ONLY_MEDIAS';
        }
      });

      if (
        type == 'TEXT_AND_AUDIO' ||
        type == 'ONLY_AUDIO' ||
        type == 'TEXT_AND_MEDIAS' ||
        type == 'ONLY_MEDIAS'
      ) {
        return type;
      } else return 'UNKNOWN';
    } else type = 'TEXT';

    return type;
  }

  actionDelete(media: MediaCommentPost) {
    this.filesEdit.push(media);
    this.filesDelete = this.filesDelete.filter((x) => x.id! !== media.id!);
  }

  deleteFromComment() {
    if (this.filesEdit.length > 0) {
      let stringIds: string[] = [];
      this.filesEdit.map((x) => stringIds.push(x.id));
      this._postService
        .inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json({
          body: {
            commentPostId:
              this.filesDelete[0]?.commentPostId! ??
              this.filesEdit[0]?.commentPostId!,
            postId: this.post.id!,
            mediaOnCommentPostFileUrls: stringIds,
          },
        })
        .pipe(
          mergeMap((res) =>
            this.getCommentByCommentId(this.currentReplyComment.id!)
          ),
          tap((res) => {
            this.filesDelete = [];
            this.filesEdit = [];
            if (res.res == 0) {
              this.currentReplyComment = res.data!;
            }
          })
        )
        .subscribe();
    }
  }

  addMediaOnComment(mediaComment: MediaCommentPost) {
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts = [];
      this.filePosts.push(this.blobFileAudio);
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild = [];
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    return this._postService.inlivewithPostCommentAddMediaCommentOnPostPut$Json(
      {
        body: {
          CommentId: mediaComment.id!,
          MediaCommentFiles: this.isHaveParent
            ? this.filePostsChild
            : this.filePosts,
        },
      }
    );
  }

  multiMedias: MediaCommentPostResponseModel[] = [];
  activeIndex: number = 0;
  isShowMultiMedia = false;

  showMultiMedia(urls: MediaCommentPostResponseModel[], index: number) {
    this.multiMedias = urls;
    this.activeIndex = index;
    this.isShowMultiMedia = true;
  }

  addEventCheckVideo() {
    let videos = document.querySelectorAll('video');

    videos!.forEach((v) => {
      v.addEventListener('play', (event) => {
        console.log('play');
        this.eventService.push({
          action: EEvent.PLAY_VIDEO,
          payload: { isPlaying: true, videoId: v.id },
        });
      });
    });
  }

  clickOnSide(event: Event) {
    event.stopPropagation();
  }
}
