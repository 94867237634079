import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Album,
  AlbumResponseWithTotalAssetModel,
} from 'src/app/core/api/be/models';
import { EEvent, EventService } from 'src/app/core/services/event.service';
import { MobileService } from 'src/app/core/services/is-mobile.service';

@Component({
  selector: 'app-card-album',
  templateUrl: './card-album.component.html',
  styleUrls: ['./card-album.component.scss'],
})
export class CardAlbumComponent implements OnInit {
  isShowPopup: boolean = false;
  @Input() album!: AlbumResponseWithTotalAssetModel;
  @Input() isAcceptedToView!: boolean;
  @Input() isJoined!: boolean;
  @Input() inPost!: boolean;

  isConfirmJoinClub: boolean = false;

  isMobile: boolean = this._mobileService.isMobile();

  constructor(
    private _router: Router,
    private _mobileService: MobileService,
    private _eventService: EventService

  ) { }

  ngOnInit(): void { }

  openAlbum() {
    let path = '';
    this.isMobile ? (path = 'mobile') : (path = 'manage-club');

    let payload = {
      clubId: this.album.clubId!,
      albumId: this.album.id!
    }
    
    if (!this.album.isPrivate) {
      this._router.navigate([path, 'detail-album', this.album?.id!], {
        queryParams: { clubId: this.album.clubId },
      }).then(() => this._eventService.push({ action: EEvent.CHANGE_URL_ALBUM, payload: payload }));
    }

    if (this.album.isPrivate && !this.isAcceptedToView) {
      this.isShowPopup = true;
    } else if (this.isAcceptedToView) {
      this._router.navigate([path, 'detail-album', this.album?.id!], {
        queryParams: { clubId: this.album.clubId },
      }).then(() => this._eventService.push({ action: EEvent.CHANGE_URL_ALBUM, payload: payload }));
    }
  }

  getUrl(asset: any) {
    if (asset == null) {
      return 'UNKNOWN';
    }

    if (!asset.includes('?')) {
      return 'UNKNOWN';
    } else {
      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!;
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset;
  }
}
