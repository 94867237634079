import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  MediaPostResponseModel,
  PostOfCelebrityResponseModel,
  PostResponseModel,
  ReactInputModel,
} from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { MENTION_REGEX, URL_REGEX, defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-card-post-celeb-in-club-page',
  templateUrl: './card-post-celeb-in-club-page.component.html',
  styleUrls: ['./card-post-celeb-in-club-page.component.scss'],
})
export class CardPostCelebInClubPageComponent implements OnInit {
  @Input() post!: PostResponseModel;
  @Input() isJoined!: boolean;

  @Output() askJoinClub = new EventEmitter();
  @Output() deletePostEvent = new EventEmitter<string>();

  countMedia: number = 0;

  defaultAvatar = defaultAvatar;

  constructor(
    private _router: Router,
    private _postService: PostService
  ) { }

  ngOnInit(): void { }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (asset?.url.includes('agora/recording')) {
        return asset?.url!;
      }

      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        if (this.countMedia == 1) {
          return asset?.url!;
        } else {
          return asset?.url!.replace('/file.', '/thumb.');
        }
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  gotoDetail() {
    this._router.navigate(['manage-club/post-detail'], {
      queryParams: { postId: this.post.id!, clubId: this.post.clubId!, stageId: this.post.stageId },
    });
  }

  likePost(event: any) {
    event.stopPropagation();
    if (!this.isJoined) {
      this.askJoinClub.emit();
      return;
    }
    this.post.isReacted = !this.post.isReacted;
    if (this.post.isReacted) {
      this.post.totalReact!++;
      this.postLikePost(true, 'REACTION_LIKE');
    } else {
      this.post.totalReact!--;
      this.postLikePost(false, null);
    }
  }

  postLikePost(isAppearing: boolean, type: any) {
    let body: ReactInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      dependObjectId: this.post.id,
      isAppearing: isAppearing,
      type: isAppearing ? type : null,
      clubId: this.post.clubId!,
      stageId: this.post.stageId!
    };
    return this._postService
      .inlivewithPostReactReactOnPostPost$Json({
        body: body,
      })
      .subscribe(
        (res) => {
          if (res.res == -2) {
            this.deletePostEvent.emit(this.post.id!);
          }
        },
        () => {
          this.post.isReacted = !this.post.isReacted;
          if (this.post.isReacted) {
            this.post.totalReact!++;
          } else {
            this.post.totalReact!--;
          }
        }
      );
  }

  urlify(text: any) {
    text = text?.replace(MENTION_REGEX, (username: string) => {
      return `<a>${username}</a>`;
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }
}
