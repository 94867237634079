<div class="flex justify-content-center flex-column text-center text-white" style="overflow-wrap: anywhere;">
    <span class="mt-2 fs-18 mb-2 text-center">
        {{title}} @{{nameObject}}
    </span>
    <div class="mt-2 mb-2">
        <span>{{name}}</span>
    </div>

    <div *ngIf="name === 'Autre'">
        <textarea class="textarea mt-4" style="resize: none;" [(ngModel)]="description" [rows]="6"
            placeholder="Reason"></textarea>
    </div>

    <div class="flex align-items-center justify-content-center">
        <button pButton type="submit" label="{{'CANCEL' | translate}}" (click)="cancel.emit()"
            class="mt-5 ml-2 p-button-outlined p-button-secondary label"></button>
        <button pButton type="submit" label="{{'SEND' | translate}}" [disabled]="name === 'Autre' && description.length! == 0"
            (click)="goNext()" class="mt-5 ml-2 p-button-outlined p-button-secondary label"></button>
    </div>
</div>