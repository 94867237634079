import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-confirm-delete-club',
  templateUrl: './mobile-confirm-delete-club.component.html',
  styleUrls: ['./mobile-confirm-delete-club.component.scss']
})
export class MobileConfirmDeleteClubComponent implements OnInit {

  @Input() isShowPopup!:boolean;
  @Input() title:string = '';
  @Input() firstButtonTitle:string = '';
  @Input() secondButtonTitle:string = '';
  @Input() username!:string
  @Input() content:string = '';

  @Output() isShowPopupChange = new EventEmitter<boolean>();
  @Output() doAction = new EventEmitter();

  
  constructor() { }

  ngOnInit(): void {
  }

  onHide() {
    this.isShowPopupChange.emit(this.isShowPopup);
  }

  closePopup() {
    this.isShowPopupChange.emit(false);
  }

}
