import { Injectable } from '@angular/core';
import { AppEnumService } from '../api/be/services';
import { ILWAppEnum } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class IlwEnumService {
  constructor(private _appEnumService: AppEnumService) {
    this.getAllAppEnum();
  }

  getAllAppEnum() {
    this._appEnumService.inlivewithAppEnumAllGet$Json()
      .subscribe(res => {
        ILWAppEnum.ENUM_ILW = Object.assign(res?.data!);
        return ILWAppEnum.ENUM_ILW;
      })
  }
}
