import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitString'
})
export class LimitStringPipe implements PipeTransform {

  transform(value: string, completeText: boolean, limit = 400, ellipsis = '...') {

    if (!value) {
      return;
    } else {
      if(completeText){
        return value;
      }
      return value.length > limit ? value.substring(0, limit) + ellipsis : value;
    }
  }
}
