import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  GoInliveInviteModel,
  InvitationGoInliveResponseModel,
} from 'src/app/core/api/be/models';
import {
  AgoraService,
  ClubService,
  GoInliveService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

export interface Message {
  message: string;
  name: string;
  avatar: string;
  roleInClub?: string;
  accessLevelInClub?: string;
}
@Component({
  selector: 'app-list-invite-goinlive',
  templateUrl: './list-invite-goinlive.component.html',
  styleUrls: ['./list-invite-goinlive.component.scss'],
})
export class ListInviteGoinliveComponent implements OnInit, OnDestroy {
  @Input() openPopup!: boolean;
  @Input() clubId!: string;
  @Input() goInliveId!: string;
  @Input() listInviter: any[] = [];
  @Output() listInviterChange!: EventEmitter<any>;
  @Output() inviteParticipant = new EventEmitter();
  @Input() listUidJoined!: any[];

  @Input() listUidParticipantJoined!: any[];
  listParticipantJoined!: any[];

  @Output() openPopupChange = new EventEmitter();
  @Output() kickUser = new EventEmitter();

  @Output() cancelInviteEmitter = new EventEmitter();

  @Input() STEP!: number;
  @Output() STEPChange = new EventEmitter();

  @Input() userCancelInviteParticipant: any[] = [];
  @Output() userCancelInviteParticipantChange = new EventEmitter();

  @Input() listUidRaiseHand!: string[];
  @Output() listUidRaiseHandChange = new EventEmitter();
  @Output() checkInvite = new EventEmitter();

  @Input() listUidInvitedPending: string[] = [];
  @Output() listUidInvitedPendingChange = new EventEmitter();

  @Input() listUidAnonymous: any[] = [];
  @Input() listUidWaitingUser: any[] = [];
  @Output() listUidWaitingUserChange = new EventEmitter();

  listInliverInvitedPending: any[] = [];

  participantSelectedForKick: any;

  defaultAvatar = defaultAvatar;
  listInliver: AccountResponseWithRoleInCLubModel[] = [];
  listAllMemberClub: AccountResponseWithRoleInCLubModel[] = [];
  inliverInvited: string[] = [];
  type!: string;
  searchText!: string;
  showConfirmKickUser: boolean = false;

  LIMIT_PARTICIPANT = 36;

  subscription = new Subscription();

  reloadNoti$ = this._signalR.isReloadListInviteGoInlive$$.pipe(
    tap(() => {
      if (this.type == 'GOINLIVE_PLUS') {
        this.getInvitation();
      } else {
        this.getAccountJoiningGoInlive();
      }
    })
  );

  constructor(
    private _clubService: ClubService,
    private _goInliveService: GoInliveService,
    private _activeRoute: ActivatedRoute,
    private _signalR: NotificationServicee
  ) {}

  ngOnInit(): void {
    this.listUidWaitingUser = this.listUidWaitingUser.map((uid) =>
      uid.toString()
    );

    this._activeRoute.queryParams
      .pipe(
        tap((p) => {
          this.type = p.type!;
        })
      )
      .subscribe(() => {
        this.subscription.add(this.reloadNoti$.subscribe());
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hidePopup() {
    if (this.type == 'GOINLIVE_PLUS') {
      this.checkInvite.emit();
    }
    this.listUidInvitedPendingChange.emit(this.listUidInvitedPending);
    this.openPopupChange.emit(false);
  }

  getAccountJoiningGoInlive() {
    LoadingService.$(true);

    let _accountsJoiningGoInlive =
      this._goInliveService.inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json(
        {
          goInliveId: this.goInliveId,
        }
      );

    forkJoin([_accountsJoiningGoInlive])
      .pipe(
        tap((res) => {
          this.listAllMemberClub = res[0].data
            ?.map((a) => a.account!)
            ?.filter(
              (account) => account.id !== AuthService.GetAccountInfo().id!
            )!;

          this.handleInliver();
        })
      )
      .subscribe(() => LoadingService.$(false));
  }

  getMemberClub() {
    this._clubService
      .inlivewithClubGetAllMembersClubIdGet$Json({
        clubId: this.clubId!,
        limit: 10000,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listAllMemberClub = res.data?.page!.filter(
              (account) => account.id !== AuthService.GetAccountInfo().id!
            )!;
            this.handleInliver();
          }
        })
      )
      .subscribe(() => LoadingService.$(false));
  }

  valider() {
    this.listInviter = this.listInliver.filter((inliver) =>
      this.inliverInvited!.includes(inliver.id!)
    );

    this.pushMessageInviteToParticipant(this.inliverInvited);

    if (this.type == 'GOINLIVE_PLUS') {
      LoadingService.$(true);

      let body: GoInliveInviteModel = {
        goInliveId: this.goInliveId!,
        accountIds: this.inliverInvited,
        clubId: this.clubId,
      };

      this.getInvitation();

      this._goInliveService
        .inlivewithGoInliveInviteToGoInlivePost$Json({
          body: body,
        })
        .subscribe(() => {
          this.STEPChange.emit(2);
          this.hidePopup();
        });
      return;
    }

    this.STEPChange.emit(2);
    this.hidePopup();
  }

  invite(id: string) {
    if (this.type == 'GOINLIVE_CLASSIC') {
      this.pushMessageInviteToParticipant([id]);
      this.hidePopup();
      return;
    }

    let body: GoInliveInviteModel = {
      goInliveId: this.goInliveId!,
      accountIds: [id],
      clubId: this.clubId,
    };

    return this._goInliveService
      .inlivewithGoInliveInviteToGoInlivePost$Json({
        body: body,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.pushMessageInviteToParticipant([id]);
            this.hidePopup();
          }
        })
      )
      .subscribe(() => this.getAccountJoiningGoInlive());
  }

  pushMessageInviteToParticipant(ids: string[]) {
    this.listUidJoined.filter((inliver) => !ids.includes(inliver.agoraUid));
    ids.forEach((id) => {
      this.listInliver.forEach((inliver) => {
        if (inliver.id == id) {
          this.inviteParticipant.emit(inliver.agoraUid);
        }
      });
    });
  }

  kickParticipant(inliver: AccountResponseWithRoleInCLubModel) {
    console.log('========kickParticipant:', inliver);

    let body = {
      goInliveId: this.goInliveId!,
      authorId: AuthService.GetAccountInfo().id!,
      body: [inliver.id!],
    };

    if (this.type == 'GOINLIVE_CLASSIC') {
      this.kickUser.emit(inliver?.agoraUid!);
      this.hidePopup();
      return;
    }

    this._goInliveService
      .inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json(
        body
      )
      .pipe(
        tap((res) => {
          this.kickUser.emit(inliver?.agoraUid!);
        })
      )
      .subscribe(() => this.hidePopup());
  }

  getInvitation() {
    this._goInliveService
      .inlivewithGoInliveGetInvitationByGoInliveIdGet$Json({
        goInliveId: this.goInliveId,
        limit: 100000,
      })
      .pipe(
        tap((res) => {
          this.listInliverInvitedPending = res.data?.page?.filter(
            (data) => data.isPending
          )!;

          this.listInliverInvitedPending =
            this.listInliverInvitedPending.filter(
              (inliver) =>
                !this.listUidParticipantJoined!.includes(
                  inliver.account?.agoraUid!
                )
            );

          // this.listInliverInvitedPending.forEach((inliver) => {
          //   let accessLevel = this.listAllMemberClub.filter(
          //     (data) => data.id == inliver.accountId
          //   )[0]?.accessLevelInClub!;
          //   inliver['accessLevelInClub'] = accessLevel || '';
          // });
        })
      )
      .subscribe(() => this.getMemberClub());
  }

  cancelInvite(inliver: any) {
    if (this.type == 'GOINLIVE_CLASSIC') {
      if (this.listUidInvitedPending.includes(inliver?.agoraUid!.toString())) {
        this.listUidInvitedPending = this.listUidInvitedPending.filter(
          (value) => value !== inliver?.agoraUid!.toString()
        );
      }

      this.getAccountJoiningGoInlive();
      this.cancelInviteEmitter.emit(inliver?.agoraUid!.toString());
      return;
    }

    this._goInliveService
      .inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json({
        accountId: inliver.account?.id!,
        goInliveId: this.goInliveId,
      })
      .subscribe(() => this.getInvitation());
  }

  searchInliver() {
    if (this.searchText.trim() == '') {
      this.listInliver = this.listAllMemberClub;
    }

    this.listInliver = this.listAllMemberClub.filter((inliver) =>
      inliver.nickName?.toLowerCase().includes(this.searchText.toLowerCase())
    );

    if (this.type !== 'GOINLIVE_PLUS') {
      this.handleInliver();
    }
  }

  handleInliver() {
    this.listAllMemberClub = this.listAllMemberClub!.filter(
      (account) => !this.listUidAnonymous.includes(account.agoraUid?.toString())
    )!;

    this.listUidJoined = this.listUidJoined.map((u) => (u = parseInt(u)));
    this.listUidParticipantJoined = this.listUidParticipantJoined.map(
      (u) => (u = parseInt(u))
    );

    if (this.type == 'GOINLIVE_CLASSIC') {
      this.listParticipantJoined = this.listAllMemberClub!.filter((u) =>
        this.listUidParticipantJoined.includes(
          u.agoraUid || parseInt(u.agoraUid!.toString())
        )
      )!;
      this.listInliver = this.listAllMemberClub!.filter((u) =>
        this.listUidJoined.includes(u.agoraUid)
      )!;

      this.listInliver.forEach((inliver) => {
        if (inliver.isCelebrity) {
          this.STEP = 2;
        }
      });

      this.listInliver = this.listInliver.filter(
        (u) => !this.listUidParticipantJoined.includes(u.agoraUid)
      );

      if (this.STEP == 2) {
        this.listInliver = this.listInliver.filter(
          (u) => !this.listUidParticipantJoined.includes(u.agoraUid)
        )!;
      }

      this.listInliver = this.listInliver!.filter(
        (data) =>
          !this.listUidInvitedPending!.includes(data?.agoraUid!.toString())
      );

      this.listInliver = this.listInliver.filter(
        (data) =>
          !this.listInliverInvitedPending
            .map((a) => a.account?.agoraUid)
            .includes(data.agoraUid!)
      );

      this.listInliverInvitedPending = this.listAllMemberClub.filter((data) =>
        this.listUidInvitedPending.includes(data?.agoraUid!.toString())
      );
      this.listInliverInvitedPending = this.listInliverInvitedPending.filter(
        (data) =>
          !this.userCancelInviteParticipant.includes(data?.agoraUid!.toString())
      );
    } else {
      this.listInliver = this.listAllMemberClub!.filter(
        (u) => !this.listUidJoined.includes(u.agoraUid)
      )!;

      this.listInliver = this.listInliver.filter(
        (data) =>
          !this.listInliverInvitedPending
            .map((a) => a.account?.agoraUid)
            .includes(data.agoraUid!)
      );

      setTimeout(() => {
        this.listParticipantJoined = this.listAllMemberClub!.filter((u) =>
          this.listUidParticipantJoined.includes(u.agoraUid)
        )!;
      }, 500);
    }

    this.listInliverInvitedPending.forEach((inliver) => {
      let accessLevel = this.listAllMemberClub.filter(
        (data) => data.id == inliver.accountId
      )[0]?.accessLevelInClub!;
      inliver['accessLevelInClub'] = accessLevel || '';
    });
  }
}
