<div *ngFor="let itemBig of userApplyInfo">
    <div class="card-apply" *ngFor="let item of itemBig.accounts">
        <div class="flex align-items-center">
            <div class="zone-avatar">
                <img [src]="item.avatar! ? item?.avatar : defaultAvatar" class="fit-size">
            </div>
            <div class="flex">
                <span class="text-white ml-3">{{item?.nickName! | limitString:false:40}}</span>
                <span class="text-disabled ml-2">{{(isFirst ? 'CLUB_WISH_INLIVERPLUS': 'CLUB_WISH_MEMBER') | translate}} <span style="font-weight: bold;">{{itemBig?.clubName!}}</span></span>
            </div>
        </div>
        <div class="flex ml-6 mt-2">
            <button pButton label="{{'REFUSE' | translate}}" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                (click)="doAction(false, itemBig, item)"
            >
            </button>
            <button pButton label="{{'ACCEPT' | translate}}" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
                (click)="doAction(true, itemBig, item)">
            </button>
        </div>
    </div>
</div>