import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { mergeMap, tap } from 'rxjs/operators';
import {
  Account,
  AccountResponseGeneral,
  AccountResponseWithRoleInCLubModel,
  ClubResponseInPostCelebrity,
  GoInliveResponseModelWithClubDetail,
} from '../core/api/be/models';
import {
  AccountService,
  GoInliveService,
  MentionService,
  NotificationService,
  PostService,
} from '../core/api/be/services';
import { AuthService } from '../core/services/auth.service';
import { imagesTutorial } from '../core/constant';
import { LoadingService } from '../core/services/loading.service';
import { LocalStorageService } from '../core/services/local-storage.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  currentUser: AccountResponseGeneral = AuthService.GetAccountInfo();
  accountInvalidPopup = false;
  openSlideTutorial = false;
  activeIndex = 0;
  images = imagesTutorial;
  completeText!: boolean;
  isShowReviewCamera: boolean = false;
  tempNoti: any;

  showListClubsPosted = false;
  tempGoInlive!: GoInliveResponseModelWithClubDetail;
  tempClubCelebPosted!: ClubResponseInPostCelebrity[];
  showListClubsCelebGoinlive!: boolean;
  tempPostCelebId!: string;

  constructor(
    public _router: Router,
    private messageService: MessageService,
    private _goInliveService: GoInliveService,
    private _notificationService: NotificationService,
    private _postService: PostService
  ) { }

  ngOnInit(): void { }

  closeMessage(message: any) {
    this._router.navigate(['/notification']).then(() => {
      this.messageService.clear();
    });
  }

  clearMessage() {
    this.messageService.clear();
  }

  openMessage(message: any) {
    this._router.navigate(['chat', message!.data!.conversationId!]);
  }

  openGoInlive(message: any) {
    this._goInliveService
      .inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json({
        goInliveId: JSON.parse(message?.data?.content!)!.Content!,
        isStreaming: true,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            if (res?.data && res?.data!.isStreaming!) {
              if (res.data.isFromCelebrity) {
                return;
              }
              this._router.navigate(['go-inlive-view'], {
                queryParams: {
                  goInliveId: res?.data?.id,
                  channel: res?.data?.channelName,
                  hostUid: res?.data?.author?.agoraUid!,
                  mode: res?.data.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
                  type: res?.data.type!,
                },
              });
            }
          }
        })
      )
      .subscribe(() => {
        // this.deleteInvitation(JSON.parse(message?.data?.content!)!.Id!);
      });
  }

  openDetailClub(message: any) {
    if (this._router.url.includes('/manage-club/detail-club')) {
      this._router
        .navigate(['manage-club/detail-club'], {
          queryParams: { clubId: JSON.parse(message?.data?.club!)!.Id! },
        })
        .then(() => window.location.reload());
    } else
      this._router.navigate(['manage-club/detail-club'], {
        queryParams: { clubId: JSON.parse(message?.data?.club!)!.Id! },
      });
  }

  openDiscussion(message: any) {
    if (message.data) {
      this._router.navigate(['manage-club/detail-club'], {
        queryParams: { clubId: message.data.ClubId },
      });
    }
  }

  openDetailPost(message: any) {
    console.log(JSON.parse(JSON.parse(message?.data!.content!).Content!).PostId!);

    this._router.navigate(['manage-club/post-detail'], {
      queryParams: {
        postId: JSON.parse(JSON.parse(message?.data!.content!).Content!).PostId!,
        clubId: JSON.parse(message?.data.club!).Id!,
        stageId: JSON.parse(JSON.parse(message?.data!.content!).Content!).StageId!,
      },
    });
  }

  openDetailPostViaMention(message: any) {
    let postId = JSON.parse(JSON.parse(message?.data.content!).Content).PostId!;
    let clubId = JSON.parse(JSON.parse(message?.data.content!).Content).ClubId!;
    let stageId = JSON.parse(JSON.parse(message?.data.content!).Content).StageId!;
    this._router.navigate(['manage-club/post-detail'], {
      queryParams: {
        postId,
        clubId,
        stageId
      },
    });
  }

  openDetailAlbumViaMention(message: any) {
    let albumId = JSON.parse(
      JSON.parse(message?.data.content!).Content
    ).AlbumId;
    let clubId = JSON.parse(JSON.parse(message?.data.content!).Content).ClubId;

    let albumAssetId = JSON.parse(
      JSON.parse(message?.data.content!).Content
    ).AlbumAssetId;

    this._router.navigate(['manage-club', 'detail-album', albumId], {
      queryParams: { clubId, albumAssetId },
    });
  }

  deleteInvitation(id: any) {
    this._notificationService
      .inlivewithNotificationDeleteDelete$Json({ notiId: id })
      .subscribe();
  }

  isShow() {
    return (
      !this._router.url.startsWith('/announcement/verify-email-success') &&
      !this._router.url.startsWith('/announcement/account-block') &&
      !this._router.url.startsWith('/announcement/account-is-artist') &&
      !this._router.url.startsWith('/go-inlive') &&
      !this._router.url.startsWith('/go-inlive-view') &&
      !this._router.url.startsWith('/mobile') &&
      this._router.url !== '/'
    );
  }

  openDetailEvent(message: any) {
    let eventId = JSON.parse(message?.data.content!).Content!;
    let clubId = JSON.parse(message?.data.club).Id!;
    if (clubId && eventId) {
      this._router.navigate(['manage-club/detail-event', eventId], {
        queryParams: { eventId: eventId, clubId: clubId! },
      });
    } else {
      if (clubId) {
        this._router.navigate(['manage-club', 'detail-club'], {
          queryParams: { clubId: clubId },
        });
      } else {
        this._router.navigate(['/notification']);
      }
    }
  }

  openDetailAlbum(message: any) {
    let albumId = JSON.parse(JSON.parse(message?.data.content!).Content).AlbumId!;
    let albumAssetId = JSON.parse(JSON.parse(message?.data.content!).Content).AlbumAssetId!;
    let clubId = JSON.parse(message?.data.club!).Id!;

    if (clubId && albumId) {
      this._router.navigate(['manage-club', 'detail-album', albumId], {
        queryParams: { clubId, albumAssetId },
      });
    } else {
      if (clubId) {
        this._router.navigate(['manage-club', 'detail-club'], {
          queryParams: { clubId: clubId },
        });
      } else {
        this._router.navigate(['/notification']);
      }
    }
  }

  close() {
    this.activeIndex = 0;
    this.openSlideTutorial = false;
  }

  getAvatarSender(data: any) {
    return JSON.parse(data.sender).Avatar;
  }

  getThumbnail(data: any) {
    if (data.data.imageUrl) {
      return data.data.imageUrl.replace('file.', 'thumb.') ?? '';
    } else {
      return '';
    }
  }

  goGoInlive(message: any) {
    if (this._router.url.includes('go-inlive')) {
      return;
    }

    LoadingService.$(true);
    this._goInliveService
      .inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json({
        goInliveId: JSON.parse(message?.data.content!).Content,
        isStreaming: true,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            if (res!.data!.author?.id == AuthService.GetAccountInfo().id) {
              return;
            }

            if (res?.data?.isFromCelebrity) {
              this.tempGoInlive = res.data!;
              this.showListClubsCelebGoinlive = true;
            } else {
              this._router.navigate(['go-inlive-view'], {
                queryParams: {
                  goInliveId: res?.data?.id,
                  channel: res?.data?.channelName,
                  hostUid: res?.data?.author?.agoraUid!,
                  mode: 'live',
                  type: res?.data!.type!,
                },
              });
            }
          }
        })
      )
      .subscribe(() => {
        LoadingService.$(false);
      });
  }

  goToReportPage(message: any) {
    let clubId = JSON.parse(message?.data.club!).Id!;
    this._router.navigate(['manage-club/report-club', clubId], {
      queryParams: { clubId: clubId },
    });
  }

  openProfileInliver(message: any) {
    if (this._router.url.includes('/account-info')) {
      this._router
        .navigate(['account-info'], {
          queryParams: {
            accountId: JSON.parse(message?.data?.content!)!.SenderId!,
          },
        })
        .then(() => window.location.reload());
    } else
      this._router.navigate(['account-info'], {
        queryParams: {
          accountId: JSON.parse(message?.data?.content!)!.SenderId!,
        },
      });
  }
  openDetailInliver(message: any) {
    if (this._router.url.includes('/account-info')) {
      this._router
        .navigate(['account-info'], {
          queryParams: {
            accountId: JSON.parse(message?.data?.content!)!.Content!,
          },
        })
        .then(() => window.location.reload());
    } else
      this._router.navigate(['account-info'], {
        queryParams: {
          accountId: JSON.parse(message?.data?.content!)!.Content!,
        },
      });
  }

  handleReportRedirect(message: any) {
    console.log(message.data);
    if (message.data.club! == '') {
      this.openDetailInliver(message);
    } else {
      this.openDetailClub(message);
    }
  }

  clickNotification(message: any) {

    switch (message.data.name) {
      case 'NOTI_ACCOUNT_JOIN_PUBLIC_CLUB':
        this.openDetailClub(message);
        break;
      case 'NOTI_GRANT_ADMIN_CLUB':
        this.openDetailClub(message);
        break;
      case 'NOTI_GRANT_INLIVER_PLUS':
        this.openDetailClub(message);
        break;
      case 'NOTI_APPROVAL_JOIN_PRIVATECLUB':
        this.openDetailClub(message);
        break;
      case 'NOTI_APPROVAL_UPGRADE_ACCESSLEVEL':
        this.openDetailClub(message);
        break;
      // case 'NOTI_ACCEPT_INVI_CLUB':
      //   this.openDetailClub(message);
      //   break;
      case 'NOTI_CREATE_NEW_POST':
        this.openDetailPost(message);
        break;
      case 'NOTI_REACT_POST':
        this.openDetailClub(message);
        break;
      case 'NOTI_COMMENT_POST':
        this.openDetailPost(message);
        break;
      case 'ADMIN_CREATE_NEW_POST':
        this.handleClickNotiCelebCreateNewPost(message);
        break;
      case 'NOTI_REPLY_COMMENT_POST':
        this.openDetailPost(message);
        break;
      case 'NOTI_CREATE_NEW_POST_DISCUSSION':
        this.openDetailPost(message);
        break;
      case 'NOTI_COMMENT_POST_DISCUSSION':
        this.openDetailPost(message);
        break;
      case 'NOTI_CREATE_EVENT_FOR_USER':
        this.openDetailEvent(message);
        break;
      case 'NOTI_CREATE_EVENT_PUBLIC':
        this.openDetailEvent(message);
        break;
      case 'NOTI_CHANGE_EVENT_INFORMATION':
        this.openDetailEvent(message);
        break;
      case 'NOTI_MENTION':
        if (
          JSON.parse(JSON.parse(message?.data.content!).Content).AlbumId &&
          JSON.parse(JSON.parse(message?.data.content!).Content).AlbumAssetId
        ) {
          this.openDetailAlbumViaMention(message);
        } else {
          this.openDetailPostViaMention(message);
        }
        break;
      case 'NOTI_CREATE_NEW_POST':
        this.openDetailPost(message);
        break;
      case 'NOTI_REACT_POST':
        this.openDetailPost(message);
        break;
      case 'NOTI_COMMENT_ALBUM_ASSET':
        this.openDetailAlbum(message);
        break;
      case 'NOTI_GOINLIVE_CREATE':
        this.goGoInlive(message);
        break;
      case 'NOTI_REPORT_IGNORE_TO_REPORTER':
        this.handleReportRedirect(message);
        break;
      case 'NOTI_REPORT_WARN_TO_REPORTER':
        this.handleReportRedirect(message);
        break;
      case 'NOTI_ANNOUCEMENT_CLUB_DELETED_TO_REPORTED':
        this.goToReportPage(message);
        break;
      case 'NOTI_WARN_REPORT_CLUB':
        this.goToReportPage(message);
        break;
      case 'NOTI_ACCEPTED_ADD_FRIEND_REQUEST':
        this.openProfileInliver(message);
        break;
      case 'SUPPER_CLUB_CREATE_STAGE':
        this.openDetailClub(message);
        LocalStorageService.AddLocalStorage(
          'currentStageId',
          JSON.parse(message?.data?.content!)!.Content!
        );
        break;
      case 'SUPPER_CLUB_CREATE_ALBUM':
        this.openDetailAlbum(message);
        break;
      case 'NOTI_ACCEPTED_REPORT_CONTENT':
        this.openDetailClub(message);
        break;
      case 'NOTI_REPORT_IGNORE_TO_REPORTER':
        this.openDetailClub(message);
        break;
      case 'NOTI_REPORT_ACCEPTED_TO_REPORTER':
        this.openDetailClub(message);
        break;
      case 'NOTI_CELEB_CREATE_NEW_POST':
        this.handleClickNotiCelebCreateNewPost(message);
        break;
      case 'NOTI_CELEBRITY_JOINING_GOINLIVE':
        this.goGoInlive(message);
        break;
      case 'NOTI_GOINLIVE_INVITATION':
        if (this._router.url.includes('go-inlive')) {
          if (this._router.url.includes('go-inlive-view')) {
            this._router.navigate(['/notification']);
            return;
          } else {
            return;
          }
        } else {
          this.isShowReviewCamera = true;
          this.tempNoti = message;
        }
        break;
      case 'NOTI_GOINLIVE_CELEBRITY_QUIT':
        break;
      case 'NOTI_GOINLIVE_END':
        break;
      default:
        this.closeMessage(message);
        break;
    }
  }

  handleInvite(isAccept: boolean) {
    let body = {
      invitationId: JSON.parse(this.tempNoti?.data!.content!).Content!,
      accountId: AuthService.GetAccountInfo().id!,
      isAccept: isAccept,
      isDeny: !isAccept,
    };

    this._goInliveService
      .inlivewithGoInliveSelectGoInlivedInvitePut$Json({
        body,
      })
      .subscribe((res) => {
        this.openGoInliveFromInvite(res.data?.goInliveId!);
      });
  }

  openGoInliveFromInvite(goInliveId: string) {
    LoadingService.$(true);
    this._goInliveService
      .inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json({
        goInliveId: goInliveId!,
        isStreaming: true,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            if (res?.data && res?.data!.isStreaming!) {
              this._router
                .navigate(['go-inlive-view'], {
                  queryParams: {
                    channel: res.data!.channelName!,
                    hostUid: res.data?.author?.agoraUid!,
                    type: res?.data.type,
                    goInliveId: res?.data.id,
                    mode: res?.data.type! == 'GOINLIVE_CLASSIC' ? 'live' : null,
                  },
                })
                .then(() => {
                  this.isShowReviewCamera = false;
                });
            }
          }
        })
      )
      .subscribe(() => {
        LoadingService.$(false);
      });
  }

  handleClickNotiCelebCreateNewPost(message: any) {
    console.log(message);

    let postId = JSON.parse(JSON.parse(message.data.content).Content!).PostId!;
    let totalNumberClub = JSON.parse(JSON.parse(message.data.content).Content!).TotalNumberClub!;
    this.tempPostCelebId = postId;
    if (!postId) {
      return;
    }
    if (totalNumberClub > 1) {
      LoadingService.$(true);
      this._postService
        .inlivewithPostGetClubByPostCelebrityIdGet$Json({
          postCelebId: postId,
        })
        .subscribe((res) => {
          if (res.res == 0) {
            this.tempClubCelebPosted = res.data?.clubs!;
            this.showListClubsPosted = true;
            LoadingService.$(false);
          } else {
            this._router.navigate(['announcement', 'not-found']);
          }
        });
    } else {
      this.openDetailPost(message)
    }
  }
}
