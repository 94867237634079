import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { Account, AccountResponseWithRoleInCLubModel, PostResponseModel, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { AccountService, PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-like',
  templateUrl: './list-like.component.html',
  styleUrls: ['./list-like.component.scss']
})
export class ListLikeComponent implements OnInit {

  @Input() showDialog!: boolean;
  @Input() post!: PostResponseModel;
  @Input() isMobile: boolean = false;

  defaultAvatar = defaultAvatar
  currentUser = AuthService.GetAccountInfo();
  items!: MenuItem[];

  listInliversPlus: ReactionPostResponseModel[] = [];
  listInlivers: ReactionPostResponseModel[] = [];

  @Output() showDialogChange = new EventEmitter();

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _accountService: AccountService,
    private _postService: PostService
  ) { }

  ngOnInit(): void {
    this.getReactOnPost();
  }

  initMenu(account: ReactionPostResponseModel) {
    this.items = [
      {
        icon: 'pi pi-users',
        label: this._translateService.instant('PROFILE_SEE'),
        command: (event: any) => {
          this.openAccountInfo(account);
        },
      }
    ]

    return this.items;
  }

  onHide() {
    this.showDialogChange.emit(false);
  }

  openAccountInfo(account: ReactionPostResponseModel) {
    if (AuthService.GetAccountInfo().id! !== account.accountId!) {
      if (!this.isMobile) {
        this._router.navigate(['account-info'], { queryParams: { accountId: account?.accountId! } });
      } else {
        this._router.navigate(['mobile', 'account-info'], { queryParams: { accountId: account?.accountId! } });
      }
    }
  }


  getReactOnPost() {
    this._postService.inlivewithPostGetReactOnPostPostIdGet$Json({
      postId: this.post.id!,
      stageId: this.post.stageId!,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listInlivers = res?.data?.page!.filter(account => account!.account?.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' && account.isAppearing)!;
          this.listInliversPlus = res?.data?.page!.filter(account => account!.account?.accessLevelInClub == 'ACCESSLEVEL_PLUS' && account.isAppearing)!;
        }
      })
    ).subscribe();
  }
}
