import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpXhrBackend,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from '../api/be/request-builder';
import { STORAGE_KEY } from '../constant';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) { }

  public AddMediaPosts(file: Blob, postId: string) {
    const endPoint = '/inlivewith/Post/MediaPost/AddMediaPost';
    const body = {
      PostId: postId,
      MediaPostFiles: [file],
    };
    return this._request(body, endPoint, 'put', true);
  }

  public CreateMessage(fileNames: string[], conversationId: string, type: string) {
    const endPoint = '/inlivewith/Message/Create';
    const body = {
      SenderId: AuthService.GetAccountInfo().id!,
      ConversationId: conversationId,
      Content: '',
      Type: type,
      Files: [],
      MediaFileNames: fileNames
    };
    return this._request(body, endPoint, 'post', true);
  }

  private _request(
    body: any,
    endPoint: string,
    method: string,
    isFormData: boolean = false,
    context?: HttpContext
  ) {
    const rb = new RequestBuilder(environment.API_URL, endPoint, method);
    rb.body(body, !isFormData ? 'application/*+json' : 'multipart/form-data');

    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'text/json',
        reportProgress: true,
      })
    );
  }
}
