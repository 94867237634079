<p-dialog [(visible)]="openEditPolicyClub" (onHide)="onHide()" [draggable]="false" [modal]="true">

    <div class="card">
        <div class="flex flex-column">
            <span class="text-white fs-26 mb-3">{{'CLUB_RULE' | translate}}</span>
            <span class="mt-3 text-white fs-16">{{'CLUB_CREATE_RULE_OBLIGATE' | translate}}</span>
            <span class="mt-3 text-disabled fs-14" [innerHTML]="'CLUB_CREATE_RULE_TITLE' | translate"></span>
        </div>
        <div class="mt-3">
            <textarea class="textarea" [(ngModel)]="policy" [rows]="20" placeholder=""></textarea>
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'REGISTER' | translate}}" class="mt-5 p-button-outlined p-button-secondary ml-2" (click)="editPolicy()"></button>
        </div>
    </div>
</p-dialog>