import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { identity, map } from 'lodash';
import { Subscription, forkJoin, of } from 'rxjs';
import { filter, finalize, mergeMap, tap } from 'rxjs/operators';
import {
  AccountResponseInGoInlive,
  AccountResponseWithRoleInCLubModel,
  ClubResponseInGoInlive,
  GoInliveAccountWatchingResponseModel,
  GoInliveInviteModel,
  GoInliveResponseModelWithClubDetail,
} from 'src/app/core/api/be/models';
import { ClubService, GoInliveService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-list-viewer-goinlive',
  templateUrl: './list-viewer-goinlive.component.html',
  styleUrls: ['./list-viewer-goinlive.component.scss'],
})
export class ListViewerGoinliveComponent implements OnInit {
  @Input() openPopup!: boolean;
  @Input() clubId!: string;
  @Input() goInliveId!: string;
  @Input() isAdmin!: boolean;
  @Input() adminId!: string; // Input from go-inlive page
  @Input() listUidAnonymous: any[] = [];
  @Input() live!: GoInliveResponseModelWithClubDetail;
  @Input() listUidJoined!: any[];
  @Input() isCelebLive!: boolean;

  @Output() openPopupChange = new EventEmitter();

  @Output() kickUser = new EventEmitter();
  showConfirmKickUser: boolean = false;

  defaultAvatar = defaultAvatar;
  listInliverJoined: AccountResponseInGoInlive[] = [];
  selectedInlivers: string[] = [];
  userSelected!: AccountResponseInGoInlive;

  searchText!: string;
  listInliverClone!: AccountResponseInGoInlive[];

  subscription = new Subscription();

  reloadNoti$ = this._signalR.isReloadListInviteGoInlive$$.pipe(
    tap(() => {
      this.getAccountsJoiningGoInlive();
    })
  );

  constructor(
    private _goInliveService: GoInliveService,
    private _signalR: NotificationServicee
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.reloadNoti$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hidePopup() {
    this.openPopupChange.emit(false);
  }

  getAccountsJoiningGoInlive() {
    LoadingService.$(true);
    this._goInliveService
      .inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json({
        goInliveId: this.live.id,
      })
      .pipe(
        tap((res) => {
          this.listInliverJoined = res
            .data!.filter((u) => u.role !== 'ANONYMOUS_CELEBRITY')!
            .map((a) => a.account!);

          this.listInliverClone = Object.assign(this.listInliverJoined);

          LoadingService.$(false);
        })
      )
      .subscribe();
  }

  kickViewer() {
    let body = {
      goInliveId: this.goInliveId!,
      authorId: AuthService.GetAccountInfo().id!,
      body: [this.userSelected.id!],
    };

    this._goInliveService
      .inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json(
        body
      )
      .pipe(
        tap((res) => {
          this.kickUser.emit(this.userSelected.agoraUid!);
        })
      )
      .subscribe(() => this.hidePopup());
  }

  confirmKickUser(inliver: AccountResponseWithRoleInCLubModel) {
    this.showConfirmKickUser = true;
    this.userSelected = inliver;
  }

  searchInliver() {
    if (this.searchText.trim() == '') {
      this.listInliverJoined = this.listInliverClone;
    }

    this.listInliverJoined = this.listInliverClone.filter((inliver: any) =>
      inliver.nickName?.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
