<section *ngIf="post">
    <div class="header">
        <div class="title">
            <div class="name" [innerHTML]="urlify(post.title)"></div>
            <div class="cursor-pointer flex align-items-center">
                <i class="pi pi-angle-down" (click)="close()"></i>
                <i class="pi pi-ellipsis-h ml-3" (click)="menu.toggle($event);renderLinkForCopy()"></i>
                <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" styleClass="menu-post"></p-menu>
            </div>
        </div>
        <div class="sub-title">
            {{post.stage?.name}}
        </div>
        <div class="info">
            <div class="total-member">
                <span id="title">
                    <img src="/assets/images/svgs/person.svg" alt="">
                    {{post.totalMemberDiscussion ?? 0}}
                </span>
            </div>
            <div class="line"></div>
            <div class="time" *ngIf="post.isDiscussing; else time">
                <span class="mr-2" id="title">
                    {{'END_IN' | translate}}
                </span>
                <span class="text-red" id="title"> {{getNumberWithZero(duration.hours()) + ":" +
                    getNumberWithZero(duration.minutes()) + ":" +
                    getNumberWithZero(duration.seconds())}} </span>
            </div>
            <ng-template #time>
                <div class="time">
                    <span id="title"> {{'COMPLETED' | translate}} {{'THE' | translate}} </span>
                    <span class="text-bold" style="color: #e6e6e6;" id="title"> {{timeEnd}} </span>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="list-comment" #elCommentsPostDiscussion>
        <ilw-infinity-scroll [elementScroll]="elCommentsPostDiscussion" [templateLoading]="loading"
            [isLoading]="isLoading" [isScrollToTop]="true" (scrollEnd)="onScroll()" [isDone]="isDone"
            [isUnsubscribe]="isDone" [handlingAfterGetData]="handlingAfterGetData$">
            <div *ngFor="let item of comments.slice().reverse()" class="comment-item">
                <div class="avatar cursor-pointer" (click)="openAccountInfoFromChat(item)">
                    <img [src]="item.author?.avatar ?? defaultAvatar" class="fit-size" alt="">
                </div>

                <div class="detail">
                    <div class="name" [ngStyle]="{color: findColor(item.author?.id!)}">
                        <div class="flex justify-content-between">
                            <div class="flex align-items-baseline">
                                <div class="mr-1 cursor-pointer" (click)="openAccountInfoFromChat(item)">
                                    {{item.isBannedByClub ? ('INLIVER_UNKNOWN' | translate) : (item.author?.nickName! | limitString:false:10)}}
                                </div>
                                <div class="ml-1 fs-14" style="color: #e6e6e6">
                                    {{checkZ(item?.dateCreated!) | date:'HH:mm'}}
                                </div>
                            </div>
                            <div class="zone-action-comment" *ngIf="!item.isBannedByClub">
                                <img *ngIf="post.isDiscussing" class="mr-2 cursor-pointer" (click)="focusText(item)"
                                    src="../../../assets/images/svgs/reply-comment-discussion.svg">
                                <i class="pi pi-pencil ml-2 mr-2 cursor-pointer"
                                    *ngIf="item.authorId === authId && item.mediaCommentPosts?.length! == 0"
                                    style="color: white;" (click)="editComment(item)"></i>
                                <i class="pi pi-trash ml-2 mr-2 cursor-pointer" *ngIf="item.authorId === authId"
                                    style="color: red;" (click)="showPopupConfirmDeleteComment(item)"></i>
                                <i class="pi pi-flag ml-2 cursor-pointer" *ngIf="item.authorId !== authId"
                                    style="color: red;" (click)="reportComment(item)"></i>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="item.inverseParent?.length! > 0">
                        <span class="reply"> 
                            {{'COMMENT_REPLY_PLACEHOLDER' | translate}} @{{item.author?.nickName!}}
                        </span>
                    </div> -->
                    <div class="content" *ngIf="!item.isBannedByClub" [innerHTML]="urlify(item!.message!)"></div>

                    <div class="w-100 flex justify-content-between" *ngIf="item.mediaCommentPosts!.length! > 0">
                        <ng-container *ngFor="let asset of item.mediaCommentPosts">
                            <div class="flex justify-content-center align-items-center m-2"
                                *ngIf="checkTypeMedia(asset) == 'UNKNOWN' && !checkProcessMediaFail(asset)"
                                style="height: 50px">
                                <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                            </div>
                        </ng-container>
                    </div>

                    <div class="w-100 flex flex-wrap" *ngIf="item.mediaCommentPosts!.length! > 0">
                        <ng-container *ngFor="let asset of item.mediaCommentPosts; let i = index">
                            <div class="w-100"
                                *ngIf="checkTypeMedia(asset) == 'AUDIO' && checkTypeMedia(asset) !== 'UNKNOWN' && !checkProcessMediaFail(asset)">
                                <app-wave-form-audio [assetUrl]="asset.url!"></app-wave-form-audio>
                            </div>
                        </ng-container>
                    </div>

                    <div class="w-100 flex flex-wrap"
                        *ngIf="item.mediaCommentPosts!.length! > 0 && !item.isBannedByClub">
                        <ng-container *ngFor="let asset of item.mediaCommentPosts; let i = index">
                            <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                <div class="media"
                                    *ngIf="checkTypeMedia(asset) !== 'UNKNOWN' && checkTypeMedia(asset) !== 'AUDIO'">
                                    <div class="w-100 h-100 cursor-pointer" *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                        <img class="fit-size" [src]="asset.url!.replace('/file.', '/thumb.')"
                                            (click)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                    </div>
                                    <div class="w-100 h-100 cursor-pointer position-relative"
                                        *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                        <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                            [poster]="getUrl(asset)"
                                            (dblclick)="showMultiMedia(item?.mediaCommentPosts!, i)">
                                        </app-video-layer>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="checkProcessMediaFail(asset)">
                                <div class="flex justify-content-center position-relative align-items-center media zone-media-fail"
                                    [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="content" *ngIf="item.isBannedByClub"
                        [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>

                </div>
            </div>
        </ilw-infinity-scroll>
    </div>
    <div class="footer" *ngIf="post.isDiscussing">
        <div class="flex"
            [ngStyle]="urls.length > 0 ? {'height':'110px', 'overflow':'scroll', 'scroll-behavior': 'smooth', 'margin-top': '8px'} : {}">
            <div class="zone-media-cus flex position-relative" *ngFor="let url of sortFiles(urls); let i = index">
                <img [src]="url.data" *ngIf="url.format === 'image' && url">
                <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                    <ng-container *ngIf="isChrome">
                        <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                            controls></video>
                    </ng-container>
                    <ng-container *ngIf="!isChrome">
                        <div
                            class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                            <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                            <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                {{url.file.name}}</div>
                            <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #playerVideo>
                    <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url" controls></video>
                </ng-template>

                <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url" controls></audio>

                <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                    *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                    <div style="overflow: hidden;text-overflow: ellipsis;"> {{url.file.name}}
                    </div>
                </div>

                <i class="pi pi-times delete-asset" (click)="removeAsset(url.id)"></i>
            </div>
        </div>

        <ng-template #mentionListTemplate let-item="item">
            <div class="flex align-items-center" id="na-check" style="z-index: 99999999;">
                <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40" height="40"
                    style="border-radius: 5px;">
                <span class="p-1">{{item.name | limitString:false:20}}</span>
            </div>
        </ng-template>

        <span *ngIf="is4max">
            {{'MAXIMUM_4_MEDIA' | translate}}
        </span>

        <div class="text-error flex flex-column align-items-start"
            *ngIf="showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile">
            {{'LIST_ITEM_REASON' | translate}}
            <div class="text-error" *ngIf="showErrorMixedFiles">
                {{'CANNOT_MIXED_THOSE_FILE' | translate}}
            </div>

            <div class="text-error" *ngIf="showErrorDuration">
                {{'DURATION_MAX_4MINS' | translate}}
            </div>

            <div class="text-error" *ngIf="showErrorMaximumFiles">
                {{'MAXIMUM_4_MEDIA' | translate}}
            </div>

            <div class="text-error" *ngIf="showErrorLimitSizeFile">
                {{'FILE_SIZE_LIMIT_POST' | translate}}
            </div>
        </div>

        <div class="fs-14 p-1 flex align-items-baseline justify-content-between" *ngIf="isCommenttingReply">
            <span id="title" *ngIf="isCommenttingReply && !isEditComment">
                {{'COMMENT_REPLY_PLACEHOLDER' | translate}} {{ '@' + (currentComment.author?.nickName! | limitString:false:20)}}
            </span>
            <span id="title" *ngIf="isCommenttingReply && isEditComment">
                {{currentComment!.message!.split('\r\n')[0]!}}
            </span>
            <i class="pi pi-times cursor-pointer" (click)="isCommenttingReply = false; currentComment = {}"></i>
        </div>

        <div class="fs-14 p-2 flex align-items-center justify-content-center"
            *ngIf="this.getCharacterLeft().isFullCharContent && !sendMsg">
            <img class="mr-2" src="../../../../assets/images/svgs/attention.svg">
            <span class="text-error">
                {{'LIMIT_CHARACTERS' | translate}} {{getCharacterLeft().numberLength! + '/' + 500}}
            </span>
        </div>

        <ng-container *ngIf="openPopupRecordAudio">
            <div class="flex p-2 w-100" style="border: 1px solid gray; border-radius: 10px;">
                <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'48vw'" [widthAudio]="'42vw'"
                    [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                    (sendAudio)="createCommentByAudio()"></app-record-audio-v2>
            </div>
        </ng-container>

        <div class="zone-input" id="zone-input" *ngIf="!openPopupRecordAudio">
            <textarea *ngIf="isJoinedClub" #textarea id="textarea-cus" class="w-100 text-area-cus" [(ngModel)]="content"
                (keypress)="autoGrowTextZone($event);" (keyup)="autoGrowTextZone($event);"
                (keydown)="autoGrowTextZone($event); !isEditComment ? createComment($event) : editCommentFunc($event)"
                [mentionConfig]="mentionConfig" [mentionListTemplate]="mentionListTemplate"
                (opened)="checkIsOpenMentionList(true)" (closed)="checkIsOpenMentionList(false)"
                [disabled]="showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles  || showErrorLimitSizeFile"
                placeholder="{{'SEND_A_MESSAGE' | translate}}"></textarea>
            <div class="flex align-items-center p-3 " *ngIf="isJoinedClub == false">
                <img src="../../../../assets/images/svgs/info.svg" alt="" srcset="">
                <div class="ml-3 text">
                    {{'NOTI_JOIN_DISCUSSION' | translate}}
                </div>
            </div>

        </div>
        <div class="flex w-100" *ngIf="isJoinedClub && !openPopupRecordAudio">
            <div class="flex justify-content-between zone-action w-100">
                <div class="flex">
                    <div class="action-btn">
                        <img src="../../../../assets/images/svgs/emojis.svg" alt="" srcset=""
                            (click)="changeShowEmoji($event)">
                    </div>
                    <div class="action-btn" *ngIf="(!urls.length && !content && !isEditComment)">
                        <img src="../../../assets/images/svgs/mic.svg" height="16" (click)="startRecord()">
                    </div>
                    <div class="action-btn" *ngIf="(!urlAudio && !isEditComment)">
                        <i class="pi pi-image" (click)="inputFile.click()"></i>
                    </div>
                    <div class="action-btn" *ngIf="isEditComment">
                        <i class="pi pi-times" (click)="isEditComment = false; content = ''"></i>
                    </div>
                </div>
                <div class="flex zone-btn-send">
                    <div class="flex justify-content-center align-items-center"
                        (click)="triggerEventEnterSendDiscussion()">
                        <img class="cursor-pointer" src="../../../../assets/images/svgs/send-button.svg" width="40"
                            height="40" [ngStyle]="((!urls.length && !content) || (content && getCharacterLeft().numberLength > 500) || (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles 
                            || showErrorLimitSizeFile)) ? {'opacity': '0.5'}:{}">
                    </div>
                </div>

            </div>
        </div>

        <!-- <ng-container *ngIf="openPopupRecordAudio">
            <div class="flex p-2 w-100" style="border: 1px solid gray; border-radius: 10px;">
                <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'48vw'" [widthAudio]="'42vw'"
                    [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                    (sendAudio)="createCommentByAudio()"></app-record-audio-v2>
            </div>
        </ng-container> -->
    </div>
</section>
<ng-template #loading>
    <div class="flex justify-content-center mt-2">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            animationDuration="1s"></p-progressSpinner>
    </div>
</ng-template>

<div #toggleEmoji (click)="clickOnSide($event)">
    <emoji-mart [isNative]="true" class="emoji" [enableSearch]="false" *ngIf="showEmoji" [sheetSize]="32"
        (emojiClick)="addEmoji($event)"></emoji-mart>
</div>

<input style="display: none" id="select-file" type="file" #inputFile multiple (change)="detectFiles($event)">

<p-dialog [(visible)]="showConfirmEndDiscussion" [resizable]="false" [draggable]="false">
    <div class="card flex align-items-center flex-column">
        <div class="title">
            {{'TITLE_CLOSE_DISCUSSION' | translate}}
        </div>
        <div class="subtitle text-white w-80 mt-3">
            {{'SUBTITLE_CLOSE_DISCUSSION' | translate}}
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined text-white p-button-secondary mr-2"
                (click)="showConfirmEndDiscussion = false"></button>
            <button pButton type="submit" label="{{'CLOSE' | translate}}"
                class="mt-5 p-button-outlined text-white p-button-secondary ml-2"
                (click)="endDiscussion(); closeByManual = true; showConfirmEndDiscussion = false"></button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showConfirmDeletePost" [resizable]="false" [draggable]="false">
    <div class="card flex align-items-center flex-column">
        <div class="title">
            {{'DELETE_DISCUSSION' | translate}}
        </div>
        <div class="subtitle text-white w-80 mt-3">
            {{'SUBTITLE_DELETE_DISCUSSION' | translate}}
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined text-white p-button-secondary mr-2"
                (click)="showConfirmDeletePost = false"></button>
            <button pButton type="submit" label="{{'DELETE' | translate}}"
                class="mt-5 p-button-outlined text-white p-button-secondary ml-2" (click)="deletePost()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="openPopupEditTitle" [resizable]="false" [draggable]="false">
    <div class="card flex flex-column" style="width: 600px;">
        <div class="title text-center">
            {{'EDIT_TITLE_DISCUSSION' | translate}}
        </div>
        <div class="fs-16 text-white text-left mt-5">
            {{'POST_CREATE_TITLE_LABEL' | translate}}
        </div>
        <div class="flex flex-column" style="position: relative;">
            <input class="input-custom w-100" type="text" id="title"
                [placeholder]="'POST_PLACEHOLDER_TITLE' | translate" [(ngModel)]="tempTitleEdit">
            <span class="text-regular"
                style="position: absolute; font-size: 14px; color: rgba(255, 255, 255, 0.3); right: 0; bottom: 10px;">
                {{40 - tempTitleEdit.trim().length! > 0 ? 40 - tempTitleEdit.trim().length! : 0}}
            </span>
        </div>
        <div *ngIf="tempTitleEdit.length > 40 || tempTitleEdit.length == 0" class="mt-2">
            <span class="text-error">
                {{'LIMIT_TEXT_TITLE_DISCUSSION' | translate}}
            </span>
        </div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary mr-2" (click)="openPopupEditTitle = false"></button>
            <button pButton type="submit" [disabled]="tempTitleEdit.length > 40 || tempTitleEdit.length == 0"
                label="{{'REGISTER' | translate}}"
                class="mt-5 p-button-outlined text-black bg-white p-button-secondary ml-2"
                (click)="editTitleDiscussion()"></button>
        </div>
    </div>
</p-dialog>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'COMMENT'" [nameObject]="this.currentComment"
        (refreshWhenReportSuccess)="getComments()">
    </app-report>
</ng-container>

<ng-container *ngIf="openPopupReportPost">
    <app-report [(showDialog)]="openPopupReportPost" [type]="'POST'" [nameObject]="this.post"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()">
    </app-report>
</ng-container>

<ng-container *ngIf="isShowMultiMedia">
    <app-show-multi-media [(openShowMutilMedia)]="isShowMultiMedia" [(urls)]="multiMedias"
        [(activeIndex)]="activeIndex"></app-show-multi-media>
</ng-container>

<ng-container *ngIf="isShowPopupConfirm">
    <app-card-confirm [(isShowPopup)]="isShowPopupConfirm" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'DELETE' | translate" (doAction)="deleteComment()"
        [title]="'DELETE_MESSAGE_DISSCUSSION' | translate" [content]="'CONFIRM_DELETE_COMMENT_DISSCUSSION' | translate">
    </app-card-confirm>
</ng-container>