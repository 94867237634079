<div class="card m-1 cursor-pointer" *ngIf="event && !event.isBannedByClub"
    style="background-image: url('{{getPoster(event.poster!)}}'); background-size: cover;" (click)="detailEvent(event)">
    <div class="content" pRipple>
        <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center" *ngIf="event.authorId !== accountId">
                <div class="avt-club m-2">
                    <img width="42" height="42" [src]="event.author?.avatar! ? event.author?.avatar! : defaultAvatar">
                </div>
                <div class="club-name fs-14">{{event.author?.nickName! | limitString:false:20}}</div>
                <div class="follow flex align-items-center ml-2">
                    <i class="pi pi-users mr-1 ml-3"></i>
                    {{event.numberAccountJoinEvent!}}
                </div>
            </div>
            <div class="edit-event">
                <img *ngIf="event.authorId === accountId" src="../../../assets/images/svgs/edit-pencil.svg"
                    class="cursor-pointer" (click)="editEvent($event, event)">
            </div>
        </div>
        <div class="info-event ml-3 mt-1">
            <span style="font-size: 20px; font-weight: bold;">{{event.name! | limitString:false:35}}</span>
            <div class="flex align-items-center mt-2 fs-12 text-regular">
                <i class="pi pi-clock mr-1"></i>
                {{ event.dateStart! + 'Z' | date:'dd/MM/yy HH:mm' }} {{event.type === "GOINLIVE_EVENT" ? '' : '-' +
                (event.dateEnd! + 'Z' | date:'dd/MM/yy HH:mm')}}
            </div>
            <div class="flex align-items-center mt-2 fs-12 text-regular">
                <i class="pi pi-map-marker mr-1"></i>
                <span class="fs-12 place-title">{{event.place! | limitString:false:35}}</span>
            </div>
        </div>

        <div class="flex justify-content-between align-items-center">
            <div *ngIf="event.accountJoinEvents?.length! > 0" class="ml-5 position-absolute" style="bottom: 10px;">
                <div class="avatars">
                    <div class="avatars__item" *ngFor="let item of getAccountJoinEvent()">
                        <div class="avatars__image zone-avatar">
                            <!-- Image -->
                            <img class="fit-size" [src]="item.avatar! ? item.avatar! : defaultAvatar" />
                        </div>
                    </div>
                    <div class="avatars__item">
                        <div class="zone-avatar avatar-more fs-14" *ngIf="event.numberAccountJoinEvent! > 2">
                            + {{event.numberAccountJoinEvent! - 2}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="event.accountJoinEvents?.length! == 0">

            </div>
            <button *ngIf="event.authorId != accountId && !checkEventEnd" pButton type="submit"
                label="{{(event.isAccountJoinEvent ? 'EVENT_UNJOIN' : 'EVENT_JOIN') | translate}}"
                (click)="joinEvent(event!.isAccountJoinEvent!, $event)"
                class="mt-5 p-button-outlined p-button-secondary status-button"></button>
        </div>
    </div>
</div>

<ng-container *ngIf="isOpenEditEventPopup">
    <app-edit-event [(isOpenEditEventPopup)]="isOpenEditEventPopup" [event]="event"></app-edit-event>
</ng-container>

<!-- <ng-container *ngIf="isOpenConfirmJoinEvent">
    <app-card-confirm-join-event [(isShowPopup)]="isOpenConfirmJoinEvent" [event]="event"
        [firstButtonTitle]="'NO_THANKS' | translate" [secondButtonTitle]="'YES_JOIN_EVENT' | translate"
        (doAction)="confirmJoinEvent()"></app-card-confirm-join-event>
</ng-container> -->

<ng-container *ngIf="isOpenConfirmRuleClub">
    <app-club-rules [club]="club" [(isJoined)]="isJoinClub" [routeTogoDetailEvent]="event.id!"
        [(isShowRulesClub)]="isOpenConfirmRuleClub" [totalMembers]="totalMembers"></app-club-rules>
</ng-container>