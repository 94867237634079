<p-dialog [(visible)]="openPopup" [modal]="true" [draggable]="false" [modal]="true" [closable]="true"
    (onHide)="hidePopup()">
    <div class="card">
        <div class="flex align-items-center justify-content-center text-white">
            <span class="label text-center fs-22">{{'INVITE_INLIVER' | translate}}</span>
        </div>
        <div>
            <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%; position: relative;">
                <i class="pi pi-search icon-search"></i>
                <input style="width:100%" type="text" [(ngModel)]="searchText" pInputText
                    placeholder="{{'SEARCH_INLIVER' | translate}}" />
            </div>
            <div class="pending" *ngIf="listInliverInvitedPending.length > 0">
                <div class="fs-20 text-white mt-3">
                    {{'ON_HOLD' | translate}}
                </div>
                <div class="flex align-items-center mt-3 mb-3" *ngFor="let inliver of listInliverInvitedPending  | filter:searchText">
    
                    <img class="avatar mr-1 cursor-pointer"
                        [src]="inliver!.account!.avatar! ? inliver!.account!.avatar! : defaultAvatar">
                    <label class="ml-2 label text-white cursor-pointer"
                        [for]="inliver.id!">{{inliver!.account!.nickName! | limitString:false:40}}</label>
    
                    <!-- <span class="ml-auto text-white">
                        {{'WAITING' | translate}}
                    </span> -->
    
                </div>
            </div>
            <div>
                <div class="fs-20 text-white mt-3">
                    {{'INLIVERS' | translate}}
                </div>
                <div class="flex align-items-center mt-3 mb-3" *ngFor="let inliver of listMembers | filter:searchText">
                    <p-checkbox name="group2" class="ml-1 mr-2" [value]="inliver" [(ngModel)]="selectedInlivers"
                        [inputId]="inliver?.id!"></p-checkbox>
                    <img class="avatar mr-1 cursor-pointer" [src]="inliver?.avatar! | fetchUrlImage">
                    <label class="ml-2 label text-white cursor-pointer" [for]="inliver.id!">{{inliver.nickName! | limitString:false:40}}</label>
                </div>
            </div>
            <!-- <div class="inliver-normal" *ngIf="listMembers?.length! > 0" #listInvite>
                <ilw-infinity-scroll [elementScroll]="listInvite" [templateLoading]="loading" (scrollEnd)="loadFriend()"
                    [isDone]="isDone" [isUnsubscribe]="isDone">
                    <div class="flex align-items-center mt-2 mb-2" *ngFor="let inliver of listMembers | filter:searchText">
                        <p-checkbox name="group2" class="ml-1" [value]="inliver" [(ngModel)]="selectedInlivers"
                            [inputId]="inliver?.id!"></p-checkbox>
                        <img class="avatar ml-4 mr-1" [src]="inliver?.avatar! | fetchUrlImage">
                        <label class="ml-2 label text-white" [for]="inliver.id!">{{inliver.nickName}}</label>
                    </div>
                </ilw-infinity-scroll>
            </div> -->
            <div class="flex pt-3 pb-3 justify-content-center" *ngIf="listMembers?.length! > 0">
                <button pButton type="submit" label="{{'Inviter' | translate}}" (click)="invite()"
                    class="p-button-outlined p-button-secondary label">
                </button>
            </div>
        </div>
        <div *ngIf="listMembers?.length! == 0 && listInliverInvitedPending.length >=1" class="mt-4 ">
            <span class="text-disabled fs-16">
                {{'CREATOR_ALREADY_INVITED_INLIVER' | translate}}
            </span>
        </div>
        <div *ngIf="listMembers?.length! == 0 && listInliverInvitedPending.length === 0" class="mt-4 ">
            <span class="text-disabled fs-16">
                {{'INVITE_DO_NOT_HAVE_INLIVER' | translate}}
            </span>
        </div>
        <!-- <div *ngIf="isNextStep" class="mt-5">
            <span class="text-disabled fs-16">
                {{'INVITE_INLIVER_SUBTITLE' | translate}}
            </span>
        </div> -->
    </div>
</p-dialog>

<ng-template #loading>
    <app-inliver-list-skeleton></app-inliver-list-skeleton>
</ng-template>