<!-- <ng-container *ngIf="checkProcessMedia()"> -->
<div class="post-zone"
    *ngIf="post && !isHidden && ((!post?.isPostOfCeleb! && !post?.isAdminPost!) || _router.url.startsWith('/manage-club/post-detail'))">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
        <div class="flex align-items-center">
            <div class="zone-avatar" *ngIf="!_router.url.startsWith('/home')">
                <img [src]="post.author?.avatar! && !post.isBannedByClub ? post.author?.avatar : defaultAvatar"
                    class="fit-size cursor-pointer">
            </div>
            <div class="zone-avatar" *ngIf="_router.url.startsWith('/home')">
                <img [src]="(getUrl(post.club?.avatar!)) | fetchUrlAvatarClub" class="fit-size cursor-pointer">
            </div>
            <div class="flex flex-column ml-2">
                <div class="text mt-1 flex align-items-center" *ngIf="_router.url.startsWith('/home')">
                    <span class="mr-2 name-club cursor-pointer" *ngIf="_router.url.startsWith('/home')"
                        (click)="openClubDetail()">{{post.club?.name!}}</span>
                    <i class="half-icon-heart-white ml-1" style="margin-left: 0.25rem"
                        *ngIf="post.club!.type! == 'CLUB_TYPE_FAN'"></i>
                    <i class="half-icon-star-white ml-1" style="margin-left: 0.25rem"
                        *ngIf="post.club!.type! == 'CLUB_TYPE_TALENT'"></i>
                </div>
                <div class="flex align-items-center">
                    <span class="mr-2 name-author cursor-pointer" *ngIf="_router.url.startsWith('/home')"
                        (click)="openAccountInfo()">{{post.author?.nickName! | limitString:false:40}} </span>
                    <span class="mr-2 cursor-pointer" style="font-weight: bold; font-size: 16px; color: #FFF;"
                        *ngIf="!_router.url.startsWith('/home') && !_router.url.startsWith('/mobile')"
                        (click)="openAccountInfo()">{{!post.isBannedByClub! ? (post.author?.nickName! |
                        limitString:false:40) :
                        ('INLIVER_UNKNOWN' | translate)}} </span>
                    <div style="margin-top: 7px;"
                        *ngIf="_router.url.startsWith('/manage-club/post-detail') && post.author?.isCelebrity!">
                        <img class="fit-size" src="../../../assets/images/svgs/celeb-post.svg">
                    </div>
                    <div class="fs-14" *ngIf="_router.url.startsWith('/home')">
                        <div class="label-role" style="color: #959595;"
                            *ngIf="!(post?.isPostOfCeleb! && post.author?.isCelebrity! && post.isAdminPost!)">
                            {{getRoleOfPostAuthor() | translate }}
                        </div>
                        <div class="label-role" *ngIf="post.author?.accessLevelInClub! == 'ACCESSLEVEL_CELEBRITY'">
                            {{'CELEBRITY' | translate }}
                        </div>
                    </div>
                </div>

                <div class="mt-1 fs-14" *ngIf="!_router.url.startsWith('/home')">
                    <div style="color: #959595;"
                        *ngIf="!(post?.isPostOfCeleb! && post.author?.isCelebrity! && post.isAdminPost!)">
                        {{getRoleOfPostAuthor() | translate }}
                    </div>
                    <div *ngIf="post.club?.roleOfPostAuthor! == 'ACCESSLEVEL_CELEBRITY'">
                        {{'CELEBRITY' | translate }}
                    </div>
                </div>
                <!-- <span *ngIf="!_router.url.startsWith('/mobile') && !_router.url.startsWith('/home')"
                    class="text-disabled fs-11 mt-2">{{timeAgo(post.dateCreated!)}}</span> -->
            </div>
        </div>
        <div class="flex justify-content-center align-items-center" *ngIf="!post.isBannedByClub && !post.isAdminPost">
            <!-- <img class="mr-3" src="../../../../assets/images/svgs/pin.svg"
                *ngIf="post.author?.accessLevelInClub! == 'ACCESSLEVEL_CELEBRITY'" width="20"> -->
            <div>
                <i class="pi pi-ellipsis-h cursor-pointer" (click)="menu.toggle($event); renderLinkForCopy(); log()"
                    style="font-size: 24px"></i>
                <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" styleClass="menu-post"></p-menu>
            </div>
        </div>
    </div>

    <div class="zone-voir-club" *ngIf="_router.url.startsWith('/home')" (click)="openClubDetail()">
        <div class="align-items-end flex ml-2">
            <img src="../../../../assets/images/svgs/club_icon.svg">
            <span class="ml-2 fs-14">
                {{'VIEW_CLUB' | translate}}
            </span>
        </div>
        <div>
            <i class="pi pi-angle-right fs-24 mr-1"></i>
        </div>
    </div>

    <div class="mb-3 mt-3 flex align-items-center justify-content-between">
        <div class="flex align-items-center">
            <img class="mr-2" *ngIf="!_router.url.startsWith('/home') && post.isPinPost"
                src="../../../../assets/images/svgs/pin-post.svg">
            <span class="publication mr-2">
                <!-- <i class="pi pi-star" style="font-size: 12px" *ngIf="post.stage?.isDefault"> </i> -->
                {{'POST_TYPE_PUBLICATION_TITLE' | translate}}
            </span>
            <!-- <i class="pi pi-star" style="font-size: 12px" *ngIf="post.stage?.isDefault"> </i> -->
            <!-- <span class="stage">
                {{post.stage?.name!}}
            </span> -->
        </div>
        <span *ngIf="!_router.url.startsWith('/mobile')" class="fs-14 mt-2 fw-500"
            style="color: #E6E6E6;">{{timeAgo(post.dateCreated!)}}</span>
    </div>
    <!-- <div class="mt-3 flex justify-content-between align-items-center" *ngIf="_router.url.startsWith('/mobile')">
            <span class="stage-mobile text-gray fs-14">
                {{post.stage?.name!}}
            </span>

            <span class="fs-11" style="color: #E6E6E6;">{{timeAgo(post!.dateCreated!)}}</span>

        </div> -->
    <div class="mt-5 mb-2" [class.show]="show">
        <div id="content" class="show overflow-wrap-breakword fs-16" style="white-space: break-spaces"
            *ngIf="!post.isBannedByClub" [innerHTML]="urlify(post!.message! | limitString:completeText)">
        </div>
    </div>

    <div class="text-right cursor-pointer"
        *ngIf="post?.message! && post?.message!.length! > 400 && !post.isBannedByClub">
        <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div>

    <div *ngIf="indexMetaLinks >= 0">
        <div *ngIf="post?.metaLinks?.length! >= 1 && (post?.metaLinks![indexMetaLinks].title != '' || post?.metaLinks![indexMetaLinks].domain != '') && !post.isBannedByClub"
            (click)="gotoUrl()" class="zone-metadata">
            <div class="zone-image-metadata" *ngIf="post?.metaLinks![indexMetaLinks].image! != ''">
                <img [src]="post.metaLinks![indexMetaLinks].image" class="fit-size">
            </div>
            <div class="zone-content-metadata">
                <span class="domain">
                    {{post.metaLinks![indexMetaLinks].domain}}
                </span>
                <span class="title">
                    {{post.metaLinks![indexMetaLinks].title}}
                </span>
            </div>
        </div>
    </div>

    <div class="flex justify-content-center w-100" *ngIf="!post.isBannedByClub">
        <app-layout-media-post [id]="post!.id!" [post]="post" [isJoined]="isJoined" [isAdmin]="isAdmin"
            [listMember]="listMember">
        </app-layout-media-post>
    </div>

    <div class="mt-5 mb-2" [class.show]="show">
        <div id="content" class="show overflow-wrap-breakword fs-16" style="white-space: break-spaces"
            *ngIf="post.isBannedByClub" [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
    </div>
    <!-- <div class="flex justify-content-center w-100 mt-3" *ngIf="_router.url.startsWith('/mobile')">
        <app-layout-media-post-mobile [id]="post!.id!" [post]="post" [isJoined]="isJoined" [isAdmin]="isAdmin">
        </app-layout-media-post-mobile>
    </div> -->


    <!-- <div class="mt-5 mb-5" style="position: relative;">

        <div class="flex justify-content-between zone-arrow" *ngIf="isOverflow">
            <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(post.id!)"></i>
            <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(post.id!, false)"></i>
        </div>

        <div class="flex list-media" *ngIf="post?.mediaPosts?.length! > 0" [id]="post.id">
            <div *ngFor="let asset of post.mediaPosts">

                <div class="flex mr-4"
                    *ngIf="!asset?.url!.endsWith('.mp4') && !asset?.url!.endsWith('.aac')  && !asset?.url!.endsWith('.wav') && !asset?.url!.endsWith('.mov')"
                    style="height: 300px">
                    <img [src]="asset?.url!" alt="" style="height: 100%;width: auto;">
                </div>

                <div class="mr-4" *ngIf="asset?.url!.endsWith('.mp4') || asset?.url!.endsWith('.mov')"
                    style="height: 300px;">
                    <video style="height: 100%" [id]="asset" [src]="asset?.url!" controls></video>
                </div>

                <div class="flex" *ngIf="asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')">
                    <audio controls>
                        <source [src]="asset?.url!">
                    </audio>
                </div>

            </div>

        </div>
    </div> -->
    <div *ngIf="!_router.url.startsWith('/mobile') && !post.isBannedByClub"
        class="flex justify-content-between align-items-center mt-5">
        <div class="flex">
            <div class="flex align-items-center" style="margin-right: 24px">
                <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post!.isReacted!"
                    style="font-size: 24px; transition: color 0.5s" [ngClass]="{'liked' : post!.isReacted!}"
                    (click)="likePost()"></i>
                <span (click)="openLikeZone()" class="fs-14 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
            </div>
            <div class="flex cursor-pointer align-items-center" (click)="openCommentZone()">
                <img class="cursor-pointer"
                    [src]="post!.isCommented ? '../../../assets/images/svgs/commented.svg' : '../../../assets/images/svgs/comment.svg'">
                <span class="fs-14 ml-2">{{post!.totalComment!}}</span>
            </div>
        </div>
        <div class="comment-zone">
            <div class="flex flex-column input-text justify-content-center ">
                <div class="flex flex-column w-100" [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                    <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                        *ngFor="let url of sortFiles(urls); let i = index">
                        <img [src]="url.data" *ngIf="url.format === 'image' && url">
                        <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                            <ng-container *ngIf="isChrome">
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-container>
                            <ng-container *ngIf="!isChrome">
                                <div
                                    class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                    <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                    <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                        {{url.file.name}}</div>
                                    <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #playerVideo>
                            <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                controls></video>
                        </ng-template>

                        <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                            controls></audio>

                        <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                            *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                            <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}</div>
                        </div>

                        <img class="delete-asset" (click)="removeAsset(url.id)"
                            src="../../../assets/images/svgs/trash-bg-white.svg">
                    </div>
                    <div class="text-error flex flex-column align-items-start"
                        *ngIf="showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile">
                        {{'LIST_ITEM_REASON' | translate}}
                        <div class="text-error" *ngIf="showErrorMixedFiles">
                            {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorDuration">
                            {{'DURATION_MAX_4MINS' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorMaximumFiles">
                            {{'MAXIMUM_4_MEDIA' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorLimitSizeFile">
                            {{'FILE_SIZE_LIMIT_POST' | translate}}
                        </div>
                    </div>


                </div>

                <div class="flex justify-content-center align-items-center" style="position: relative;">
                    <ng-template #mentionListTemplate let-item="item">
                        <div class="flex align-items-center">
                            <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40" height="40"
                                style="border-radius: 5px;">
                            <span class="p-1">{{item.name! | limitString:false:20}}</span>
                        </div>
                    </ng-template>
                    <ng-container *ngIf="openPopupRecordAudio">
                        <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'525px'"
                            [widthAudio]="'440px'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                            (sendAudio)="createComment()"></app-record-audio-v2>
                    </ng-container>
                    <textarea class="textarea-cus w-100" id="textarea_comment_post" style="resize: none;"
                        *ngIf="!openPopupRecordAudio && !created" [rows]="1" [(ngModel)]="content"
                        (ngModelChange)="detachText()" [mentionConfig]="mentionConfig"
                        [mentionListTemplate]="mentionListTemplate" (itemSelected)="itemSelected($event)"
                        placeholder="{{'COMMENT_WRITE' | translate}}"></textarea>

                    <div #toggleEmoji (click)="clickOnSide($event)">
                        <emoji-mart [isNative]="true" class="emoji" [enableSearch]="false"
                            [ngClass]="(checkTypeMedia(post.mediaPosts![0]) == 'AUDIO' || post.mediaPosts?.length! == 0) ? 'emoji-audio' : 'emoji-media'"
                            *ngIf="showEmoji" [sheetSize]="32" (emojiClick)="addEmoji($event)"></emoji-mart>
                    </div>

                    <div class="flex justify-content-center align-items-center cursor-pointer"
                        *ngIf="!openPopupRecordAudio && !created">
                        <img class="cursor-pointer mr-3" src="../../../../assets/images/svgs/emojis.svg" alt=""
                            srcset="" height="16" (click)="changeShowEmoji($event)">
                        <img class="mr-3" src="../../../assets/images/svgs/mic.svg" (click)="startRecord()" height="16"
                            *ngIf="!urls.length && content.trim().length == 0">
                        <i class="pi pi pi-image mr-3" (click)="isJoined ? inputFile.click() : askJoinClub.emit()"
                            *ngIf="!urlAudio"></i>
                        <ng-container
                            *ngIf="!showErrorMixedFiles && !showErrorDuration && !showErrorMaximumFiles && !showErrorLimitSizeFile">
                            <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                *ngIf="content.length > 0 || (urls.length > 0) || (urlAudio && blobFileAudio)"></i>
                        </ng-container>
                    </div>
                </div>
                <input style="display: none" type="file" #inputFile multiple (change)="detectFiles($event)">
                <div id="zoneAudio"></div>
            </div>

        </div>
    </div>

    <!-- <div *ngIf="_router.url.startsWith('/mobile')">
            <div class="flex mt-3">
                <div class="flex justify-content-center align-items-center" style="width: 30px">
                    <i class="pi pi-heart-fill cursor-pointer" [class.zoom-in-zoom-out]="post!.isReacted!"
                        style="font-size: 18px; transition: color 0.5s" [ngClass]="{'liked' : post!.isReacted!}"
                        (click)="likePost()"></i>
                    <span (click)="openLikeZone(true)" class="fs-12 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
                </div>
                <div class="flex justify-content-center align-items-center cursor-pointer" style="margin-left: 24px;"
                    (click)="openCommentZone(true)">
                    <img class="cursor-pointer"
                        [src]="post!.isCommented ? '../../../assets/images/svgs/commented.svg' : '../../../assets/images/svgs/comment.svg'">
                    <span class="fs-12 ml-2">{{post!.totalComment!}}</span>
                </div>
            </div>
        </div> -->
</div>

<div class="post-zone"
    *ngIf="post && post.isPostOfCeleb && post.author?.isCelebrity! && post.author?.nickName !== 'Inlive Team' && (_router.url.startsWith('/home') || _router.url.startsWith('/manage-club/detail-club'))">
    <app-post-celeb [post]="post" [isAdmin]="isAdmin" [isJoined]="isJoined" [listMember]="listMember"
        (deletePostEvent)="deletePostEvent.emit(post.id!)" (askJoinClub)="askJoinClub.emit()"></app-post-celeb>
</div>

<div class="post-zone"
    *ngIf="post && post.isAdminPost && (_router.url.startsWith('/home') || _router.url.startsWith('/manage-club/detail-club'))">
    <app-post-inliveteam [post]="post" [isAdmin]="isAdmin" [isJoined]="isJoined" [listMember]="listMember"
        (deletePostEvent)="deletePostEvent.emit(post.id!)" (askJoinClub)="askJoinClub.emit()"></app-post-inliveteam>
</div>

<ng-container *ngIf="isHidden">
    <div class="flex align-items-center post-zone justify-content-between">
        <div class="flex align-items-center">
            <div class="mr-4">
                <i class="pi pi-times-circle fs-24"></i>
            </div>
            <div class="flex flex-column">
                <span>{{'HIDE_POST' | translate}}</span>
                <span>{{'HIDE_POST_TITLE' | translate}}</span>
            </div>
        </div>
        <div>
            <button pButton pRipple type="button" label="{{'CANCEL' | translate}}"
                class="p-button-outlined p-button-secondary text-white" (click)="unHide()">
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="showConfirmDeletePost">
    <app-confirm-delete-post [(showDialog)]="showConfirmDeletePost" [post]="post" (isDelete)="deletePost()">
    </app-confirm-delete-post>
</ng-container>

<ng-container *ngIf="showCommentZone">
    <app-comment-post [(showCommentZone)]="showCommentZone" [post]="post" [isAdmin]="isAdmin" (refresh)="refresh()"
        [listMember]="listMember" (deletePostEvent)="deletePostEvent.emit(this.post.id)"
        (commentEvent)="updateComment($event)">
    </app-comment-post>
</ng-container>

<ng-container *ngIf="showCommentZoneMobile">
    <app-mobile-comment-post [(showCommentZone)]="showCommentZoneMobile" [post]="post" [isAdmin]="isAdmin"
        (refresh)="refresh()">
    </app-mobile-comment-post>
</ng-container>

<ng-container *ngIf="openPopupBanInliver">
    <app-list-inliver-reason-banned [post]="post" [(showDialog)]="openPopupBanInliver"
        (getNewListMember)="refreshPage()"></app-list-inliver-reason-banned>
</ng-container>

<ng-container *ngIf="openPopUpEditPost">
    <app-edit-post [(showDialog)]="openPopUpEditPost" [listMention]="listMention"
        (updatePostEvent)="updatePostAfterEdit($event)" [post]="postForEdit"></app-edit-post>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'POST'" [nameObject]="this.post"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()"></app-report>
</ng-container>

<ng-container *ngIf="openZoneLike">
    <app-list-like [(showDialog)]="openZoneLike" [isMobile]="isMobile" [post]="post"></app-list-like>
</ng-container>
<!-- </ng-container> -->