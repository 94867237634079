import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AccountResponseWithRoleInCLubModel,
  AlbumAsset,
  CommentPostResponseModel,
  MediaCommentPost,
  MediaCommentPostResponseModel,
  MediaCreateResponseModel,
  MediaPostResponseModel,
  MentionReceiver,
  PostResponseModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  ClubService,
  MentionService,
  PostService,
} from 'src/app/core/api/be/services';
import * as moment from 'moment';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  MENTION_REGEX,
  TYPE_MENTION,
  URL_REGEX,
  base64ToBlob,
  defaultAvatar,
  getFileExtension,
  typeAudio,
  typeImageMedia,
} from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import _ from 'lodash';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-comment-post',
  templateUrl: './comment-post.component.html',
  styleUrls: ['./comment-post.component.scss'],
})
export class CommentPostComponent implements OnInit {
  @Input() showCommentZone: boolean = false;
  @Input() isAdmin!: boolean;
  @Input() post!: PostResponseModel;

  @Output() showCommentZoneChange = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<boolean>();

  @Output() commentEvent = new EventEmitter<boolean>();
  @Output() deletePostEvent = new EventEmitter();

  @ViewChild('listComments') listCommentsEl!: ElementRef<HTMLDivElement>;
  @ViewChild('toggleEmoji') toggleEmoji!: ElementRef;

  comments: CommentPostResponseModel[] = [];

  defaultAvatar = defaultAvatar;

  isHaveParent!: boolean;
  openPopupRecordAudio: boolean = false;
  openPopupRecordAudioChild: boolean = false;
  openPopupBanInliver: boolean = false;
  isOverflow!: boolean;
  isAcceptToViewMenu!: boolean;
  isShowPopupConfirm!: boolean;
  isOwner!: boolean;
  openPopupReport!: boolean;
  isEditComment: boolean = false;
  isLoading = false;
  showEmoji = false;
  isDone = false;
  page = 1;
  countClicked = 0;
  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;
  isValidToAddAssets: boolean = true;
  isValidToAddAudio: boolean = true;
  showEditRule = false;

  currentReplyComment!: CommentPostResponseModel;
  currentAsset: AlbumAsset = {};
  content: string = '';
  contentReply: string = '';
  urlAudio!: string;
  format!: string;
  typeOfComment!: string;
  isHasText!: boolean;
  tempComment!: CommentPostResponseModel;

  blobFileAudio!: Blob;
  urls: any[] = [];
  filePosts: any[] = [];
  multiMedias: MediaCommentPostResponseModel[] = [];
  activeIndex: number = 0;
  isShowMultiMedia = false;

  urlAudioChild!: string;
  blobFileAudioChild!: Blob;
  urlsChild: any[] = [];
  filePostsChild: any[] = [];

  filesDelete: any[] = [];
  filesEdit: any[] = [];

  handlingAfterGetData$ = new Subject();

  subscription = new Subscription();
  isChrome =
    navigator.userAgent.toLowerCase().includes('chrome') &&
    !navigator.userAgent.toLowerCase().includes('edg');

  private listUserForMention!: AccountResponseWithRoleInCLubModel[];
  @Input() set listMember(value: AccountResponseWithRoleInCLubModel[]) {
    if (value) {
      this.listUserForMention = value;
      this._notificationService.joinGroup(this.post.id!);
      let stageIsPrivate = this.post.stage?.isPrivate;
      let mentionList = value!.filter((inliver) =>
        stageIsPrivate
          ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
          : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
          inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
      )!;

      mentionList.forEach((data) => {
        if (data.id == AuthService.GetAccountInfo().id) {
          return;
        }
        this.listMention.push({
          username: data.nickName?.trimStart(),
          name: data.nickName?.trimStart(),
          img: data.avatar,
          id: data.id,
        });
      });
      this.initConfigMention();
    } else {
      this.getListInlivers();
    }
  }
  get listMember() {
    return this.listUserForMention;
  }

  listMention: any[] = [];
  tribute!: any;
  mentionConfig: any = {};

  destroy$ = new Subject<any>();

  refetchWhenReceiveNoti$ = this._notificationService.isReloadComment$$.pipe(
    tap((res: any) => {
      if (!res) {
        return;
      }

      let commentId = JSON.parse(res?.content!)!.Content!;

      if (commentId) {
        this.refreshComments(commentId);
      }
    })
  );

  refetchWhenReceiveNotiFile$ =
    this._notificationService.isReloadCommentFileProcess$$.pipe(
      tap((res: any) => {
        if (!res) {
          return;
        }

        let data = JSON.parse(res?.content!)!.Content!;

        let commentId = data.split(',')![0] || '';
        if (commentId) {
          this.refreshComments(commentId);
        }
      })
    );

  refetchWhenReceiveNotiReloadPost$ =
    this._notificationService.isReloadPost$$.pipe(
      tap((data) => {
        if (data) {
          // this.refreshComments();
        }
      })
    );

  currentUser = AuthService.GetAccountInfo();

  constructor(
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    private _router: Router,
    private _translateService: TranslateService,
    private _notificationService: NotificationServicee,
    private _clubService: ClubService,
    public _accountService: AccountService,
    private renderer: Renderer2,
    private cdf: ChangeDetectorRef,
    private _mentionService: MentionService,
    private _uploadService: UploadService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleEmoji!.nativeElement!) {
        this.showEmoji = false;
      }
    });
  }

  clickOnSide(event: Event) {
    event.stopPropagation();
  }

  onHide() {
    //this.refresh.emit();
    this.showCommentZoneChange.emit(false);
    this.isDone = false;
    this.comments = [];
    this.page = 0;
    this._notificationService.leaveGroup(this.post.id!);
  }

  ngOnInit(): void {
    this.subscription
      .add(this.refetchWhenReceiveNoti$.subscribe())
      .add(this.refetchWhenReceiveNotiReloadPost$.subscribe())
      .add(this.refetchWhenReceiveNotiFile$.subscribe());
    // this.refetchWhenReceiveNoti$.subscribe();
    // this.refetchWhenReceiveNotiReloadPost$.subscribe();
    this.getCommentByPostId().subscribe();
    this._notificationService.onReceiveMessage(
      (accountId: string, data: any) => {
        if (
          data &&
          data.data.id &&
          this.comments.findIndex((c) => c.id === data.data.id) < 0 &&
          data.fromDirectly
        ) {
          if (data.isHaveParent) {
            let index = this.comments?.findIndex(
              (x) => x.id == data.currentReplyCommentId!
            )!;
            if (index > -1) {
              let indexChild = this.comments![index].inverseParent!.findIndex(
                (x) => x.id == data.data.id
              )!;
              if (indexChild > -1) {
                return;
              }
              this.comments![index].inverseParent?.push(data.data!);
            }
            return;
          } else {
            this.comments.unshift(data.data);
          }
          // this.tempComment = data.data;
          this.scrollToBottom();
        }
      }
    );
  }

  scrollToBottom() {
    if (this.listCommentsEl) {
      setTimeout(() => {
        this.listCommentsEl.nativeElement.scrollTop =
          this.listCommentsEl.nativeElement.scrollHeight;
      }, 10);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  getItem(comment: CommentPostResponseModel) {
    let items: MenuItem[] = [];
    this.isAcceptToViewMenu =
      comment?.authorId! === AuthService.GetAccountInfo()?.id!;
    items =
      this.isAdmin && !this.isAcceptToViewMenu
        ? [
          {
            icon: 'pi pi-users',
            label: this._translateService.instant('PROFILE_SEE'),
            command: (event: any) => {
              this.openAccountInfo(comment?.authorId!, comment.isBannedByClub!, comment.author?.isCelebrity!);
            },
          },
          {
            icon: 'pi pi-ban',
            label: this._translateService.instant('CLUB_BAN_USER'),
            command: () => {
              this.currentReplyComment = comment;
              this.openPopupBanInliver = true;
              //this.bannedFromClub();
            },
          },
          {
            icon: 'pi pi-trash',
            visible: this.checkDisable(comment),
            label: this._translateService.instant('COMMENT_DELETE'),
            command: () => {
              this.currentReplyComment = comment;
              this.showPopupConfirm();
            },
          },
        ]
        : this.isAcceptToViewMenu
          ? [
            {
              icon: 'pi pi-pencil',
              label: this._translateService.instant('MODIFIER'),
              disabled: this.checkAllDoneHaveParams(comment),
              command: () => {
                this.isEditComment = true;
                this.currentReplyComment = comment;
                this.content = comment.message!;
                this.typeOfComment = this.checkTypeToEditComment(comment);
                this.filesDelete = comment.mediaCommentPosts!
                this.filesEdit = [];

                if (this.typeOfComment === 'UNKNOWN') {
                  if (this.content.trim().length > 0) {
                    this.isHasText = true;
                  } else this.isHasText = false;
                }
              },
            },
            {
              icon: 'pi pi-trash',
              disabled: this.isEditComment,
              label: this._translateService.instant('DELETE'),
              command: () => {
                this.currentReplyComment = comment;
                this.showPopupConfirm();
              },
            },
          ]
          : [
            {
              icon: 'pi pi-users',
              label: this._translateService.instant('PROFILE_SEE'),
              command: () => {
                this.openAccountInfo(comment?.authorId!, comment.isBannedByClub!, comment.author?.isCelebrity!);
              },
            },
            {
              icon: 'pi pi-flag',
              disabled: comment.isReported,
              label: this._translateService.instant('COMMENT_REPORT'),
              command: () => {
                this.openPopupReport = true;
                this.currentReplyComment = comment;
              },
            },
          ];

    return items;
  }

  timeAgo = (date: string) => {
    const timeNow = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    const parsedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();

    if (moment.utc().diff(date, 'days') >= 7) {
      return moment
        .utc(date)
        .locale(currentLang ? currentLang : 'fr')
        .format('L');
    }

    /**
     *  If the post has been created less than a minute ago
     */
    if (moment(timeNow).diff(moment(parsedDate), 'seconds') <= 60) {
      return this._translateService.instant('POST_DATE_NOW');
    }

    //   moment.updateLocale('fr', {
    //     relativeTime : {
    //         past:   "%s",
    //         s  : '%d s',
    //         ss : '%d s',
    //         m:  "%d m",
    //         mm: "%d m",
    //         h:  "an h",
    //         hh: "%d h",
    //         d:  "%d j",
    //         dd: "%d j",
    //         M:  "%d mois",
    //         MM: "%d mois",
    //         y:  "%d an",
    //         yy: "%d années"

    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ')
      ? text
        .replace('il y a ', '')
        .replace('une', ' 1')
        .replace('jour', ' j')
        .replace('minute', ' min')
        .replace('heure', ' h')
        .replace('s', '')
        .replace('quelque secondes', '1s')
        .replace('un', ' 1')
      : text;

    return text;
  };

  focusText(item: CommentPostResponseModel) {
    this.isHaveParent = true;
    this.currentReplyComment = item;
    this.urlsChild = [];
    this.filePostsChild = [];
    this.urlAudioChild = '';
    this.contentReply = '';

    setTimeout(() => {
      document.getElementById('replyComment')!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 300);
  }

  startRecord(isParent: boolean) {
    if (isParent) {
      this.openPopupRecordAudioChild = true;
      this.urlAudioChild = '';
      this.contentReply = '';
    } else {
      this.content = '';
      this.urlAudio = '';
      this.openPopupRecordAudio = true;
    }
  }

  createReplyComment() {
    this.countClicked++;

    if (this.urlsChild.length > 4) {
      this.countClicked = 0;
      return;
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    LoadingService.$(true);

    var mentionIdsInReply: MentionReceiver[] = [];

    if (this.contentReply) {
      let listMentionsInReply = this.contentReply.match(MENTION_REGEX);
      this.listMention
        .filter((user) =>
          listMentionsInReply
            ?.map((value) => value.replace('@', '').replaceAll(' ', ''))
            .includes(user.username.replaceAll(' ', ''))
        )!
        .forEach((value) => mentionIdsInReply.push({
          id: value.id,
          name: value.name
        }))! || [];
    }

    var isCheckAudio = false;
    var isCheckMedia = false;
    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        // console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);
            console.log(f_duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            } else {
              //this.showErrorDuration = false;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              //LoadingService.$(false);
              //return;
            }
          });
      }
    }

    if (
      this.showErrorLimitSizeFile ||
      this.showErrorMaximumFiles ||
      this.showErrorDuration
    ) {
      this.countClicked = 0;
      LoadingService.$(false);
      return;
    } else {
      if (this.countClicked == 1 && this.filePostsChild.length! <= 4) {
        let nameFiles = this.filePostsChild.map(x => x.name);
        this._postService
          .inlivewithPostCommentCreateCommentPostPost$Json({
            body: {
              AuthorId: AuthService.GetAccountInfo().id!,
              ParentId: this.currentReplyComment.id!,
              Message: this.contentReply,
              PostId: this.post.id!,
              StageId: this.post.stageId!,
              MediaCommentFiles: [],
              MediaFileNames: nameFiles
            },
          })
          .pipe(
            tap((res) => {
              if (res.res == 0) {
                let payload = {
                  data: res.data!,
                  isHaveParent: this.isHaveParent ?? false,
                  currentReplyCommentId:
                    this.currentReplyComment.id! ?? 'currentReplyCommentId',
                  fromDirectly: true,
                };

                this.addMentionReceivers(mentionIdsInReply, res.data?.id!)

                this._notificationService.sendMessage(
                  this.post?.id!,
                  AuthService.GetAccountInfo().id!,
                  payload
                );

                if (res.data?.mediaCreateResponseModels?.length! > 0) {
                  this.addMediaPosts(this.filePostsChild, res.data?.mediaCreateResponseModels!)
                }

                this.commentEvent.emit(true);
                this.contentReply = '';
                this.isHaveParent = false;
                this.filePostsChild = [];
                this.urlsChild = [];
                this.urlAudioChild = '';
                this.currentReplyComment = {};
                this.showErrorDuration = false;
                this.showErrorLimitSizeFile = false;
                this.showErrorMaximumFiles = false;
                this.showErrorMixedFiles = false;
                this.isEditComment = false;
              }
            })
          )
          .subscribe(() => {
            this.countClicked = 0;
            LoadingService.$(false);
          });
      } else {
        //this.showErrorMaximumFiles = false;
        this.countClicked = 0;
        LoadingService.$(false);
      }
    }
  }

  private addMediaPosts(fileComments: any[], mediaCreateComment: MediaCreateResponseModel[]) {
    let obs: Observable<any>[] = [];
    fileComments.forEach((file: Blob, i: number) => {
      obs.push(
        this._uploadService.AddMediaPosts(file, mediaCreateComment[i].path!, mediaCreateComment[i].hook!, mediaCreateComment[i].id!)
      );
    });

    if (obs.length > 0) {
      forkJoin(obs).subscribe();
    }
  }

  addMentionReceivers(receivers: MentionReceiver[], objectId: string) {
    this._mentionService.inlivewithMentionCreatesPost$Json({
      isPushNoti: this.filePosts.length > 0 ? false : true,
      body: {
        clubId: this.post.clubId!,
        dependObjectId: objectId,
        senderId: AuthService.GetAccountInfo().id!,
        receivers: receivers,
        type: TYPE_MENTION.COMMENT_POST,
      }
    }).subscribe();
  }

  createComment() {
    this.countClicked++;
    this.isHaveParent = false;

    if (this.urls.length > 4) {
      this.countClicked = 0;
      return;
    }

    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    var mentionIds: MentionReceiver[] = []

    LoadingService.$(true);

    if (this.content) {
      let listMentions = this.content.match(MENTION_REGEX);
      this.listMention
        .filter((user) =>
          listMentions
            ?.map((value) => value.replace('@', '').replaceAll(' ', ''))
            .includes(user.username.replaceAll(' ', ''))
        )!
        .forEach((value) => {
          mentionIds.push({
            id: value.id,
            name: value.name
          })
        })! || [];
    }

    if (this.isEditComment) {
      if (
        this.filePosts.length! +
        this.filesDelete.length! >
        4
      ) {
        this.showErrorMaximumFiles = true;
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        this.showErrorMaximumFiles = false;
      }

      if (this.typeOfComment == 'TEXT') {
        if (this.content.trim().length > 0 && this.countClicked == 1) {
          this._postService
            .inlivewithPostCommentEditContentCommentOnPostPut$Json({
              body: {
                commentId: this.currentReplyComment.id!,
                message: this.content,
                mentionReceivers: mentionIds
              },
            })
            .pipe(
              tap((res) => {
                var index = this.comments.findIndex(
                  (x) => x.id === this.currentReplyComment.id
                );
                if (index > -1) {
                  this.comments[index].message! = res.data!.message!;
                } else {
                  var index = this.comments.findIndex(
                    (x) => x.id === res.data?.parentId!
                  );
                  var indexChild = this.comments[
                    index
                  ].inverseParent!.findIndex((x) => x.id === res.data?.id!);

                  if (indexChild > -1) {
                    this.comments[index].inverseParent![indexChild!].message! =
                      res.data!.message!;
                  }
                }
                this.comments.forEach((c) => {
                  c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
                });
                this.getItem(res.data!);
                // this.addMentionReceivers(mentionIds, res.data?.id!)
              }),
              tap((res) => {
                if (res.res == 0) {
                  this.content = '';
                  this.isHaveParent = false;
                  this.currentReplyComment = {};
                  this.isEditComment = false;
                }
              })
            )
            .subscribe(() => {
              this.countClicked = 0;
              LoadingService.$(false);
            });
          return;
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeOfComment == 'UNKNOWN') {
        if (this.isHasText && this.content.trim().length == 0) {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }

        if (
          this.filesDelete.length! +
          this.filePosts.length >
          0
        ) {
          var isValidToAddAudio = true;
          var isValidToAddAssets = true;

          if (this.currentReplyComment.mediaCommentPosts?.length! > 0) {
            this.currentReplyComment.mediaCommentPosts!.forEach((x) => {
              if (this.checkTypeMedia(x) === 'AUDIO') {
                this.filePosts.forEach((x) => {
                  if (
                    x.type.split('/')[0] === 'image' ||
                    x.type.split('/')[0] === 'video'
                  ) {
                    isValidToAddAssets = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
              if (
                this.checkTypeMedia(x) === 'IMAGE' ||
                this.checkTypeMedia(x) === 'VIDEO'
              ) {
                this.filePosts.forEach((x) => {
                  if (x.type.split('/')[0] === 'audio') {
                    isValidToAddAudio = false;
                    this.countClicked = 0;
                    LoadingService.$(false);
                    return;
                  }
                });
              }
            });
          }

          if (this.filePosts.length > 0) {
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                isValidToAddAssets = false;
                //LoadingService.$(false);
                //return;
              }

              if (x.type.split('/')[0] === 'audio') {
                isValidToAddAudio = false;
                //LoadingService.$(false);
                //return;
              }

              if (!isValidToAddAssets && !isValidToAddAudio) {
                this.showErrorMixedFiles = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            // if (!isValidToAddAssets || !isValidToAddAudio) {
            //   this.showErrorMixedFiles = true;
            //   this.countClicked = 0;
            //   LoadingService.$(false);
            //   return;
            // }

            if (this.countClicked == 1) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                      mentionReceivers: mentionIds
                    },
                  })
                  .pipe(
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                      //this.addMentionReceivers(mentionIds, res.data?.id!)
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        this.currentReplyComment = {};
                        this.isEditComment = false;
                        this.showEditRule = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    this.scrollToBottom();
                    LoadingService.$(false);
                  });
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
        }
        return;
      }

      if (
        this.typeOfComment == 'ONLY_MEDIAS' ||
        this.typeOfComment == 'ONLY_AUDIO'
      ) {
        if (this.typeOfComment == 'ONLY_AUDIO') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = true;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        } else if (this.typeOfComment == 'ONLY_MEDIAS') {
          if (this.filePosts.length > 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }
        }

        if (
          this.showErrorLimitSizeFile ||
          this.showErrorMaximumFiles ||
          this.showErrorDuration
        ) {
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        } else {
          if (
            this.filesDelete.length! +
            this.filePosts.length ==
            0
          ) {
            this.showEditRule = true;
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          }

          if (this.countClicked == 1) {
            if (this.filePosts.length >= 0 || this.urlAudio) {
              this.deleteFromComment();
              if (this.isValidToAddAssets && this.isValidToAddAudio) {
                this.addMediaOnComment(this.currentReplyComment)
                  .pipe(
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                        this.comments.forEach((c) => {
                          c.inverseParent = _.orderBy(
                            c.inverseParent,
                            'dateCreated'
                          );
                        });
                      }
                      this.addMentionReceivers(mentionIds, res.data?.id!)
                    }),
                    tap(() => {
                      this.content = '';
                      this.isHaveParent = false;
                      this.urls = [];
                      this.filePosts = [];
                      this.urlAudio = '';
                      this.currentReplyComment = {};
                      this.isEditComment = false;
                      this.showEditRule = false;
                    })
                  )
                  .subscribe(() => {
                    this.countClicked = 0;
                    LoadingService.$(false);
                  });
              } else {
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }
            } else {
              this.content = '';
              this.isHaveParent = false;
              this.urls = [];
              this.filePosts = [];
              this.urlAudio = '';
              //this.currentReplyComment = {};
              this.isEditComment = false;
              this.countClicked = 0;
              LoadingService.$(false);
              return;
            }
          }
        }
      }

      if (this.typeOfComment == 'TEXT_AND_AUDIO') {
        if (
          this.content &&
          this.filePosts.length! +
          this.filesDelete.length! >
          0
        ) {
          this.showEditRule = false;
          if (this.filePosts.length > 0) {
            this.isValidToAddAssets = true;
            this.filePosts.forEach((x) => {
              if (
                x.type.split('/')[0] === 'image' ||
                x.type.split('/')[0] === 'video'
              ) {
                this.isValidToAddAssets = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      this.countClicked = 0;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                      mentionReceivers: mentionIds
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                      // this.addMentionReceivers(mentionIds, res.data?.id!)
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        //this.currentReplyComment = {};
                        this.isEditComment = false;
                        this.showEditRule = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    LoadingService.$(false);
                    this.countClicked = 0;
                    this.scrollToBottom();
                  });

                return;
              }
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }

      if (this.typeOfComment == 'TEXT_AND_MEDIAS') {
        if (
          this.content &&
          this.filePosts.length! +
          this.filesDelete.length! >
          0
        ) {
          this.showEditRule = false;
          if (this.filePosts.length >= 0) {
            this.isValidToAddAudio = true;
            this.filePosts.forEach((x) => {
              if (x.type.split('/')[0] === 'audio') {
                this.isValidToAddAudio = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check size file */
              const fileSize = x.size;
              const fileMb = fileSize / 1024 ** 2;
              if (fileMb > FILE_SIZE_LIMIT_POST) {
                this.showErrorLimitSizeFile = false;
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              }

              /* check Duration */
              if (
                typeAudio.includes(getFileExtension(x.name)) ||
                x.type.split('/')[0] === 'video' ||
                x.type.split('/')[0] === 'audio'
              ) {
                var f_duration = 0;
                var fileUrl = window.URL.createObjectURL(x);
                const audio = document.createElement('audio');
                audio.id = fileUrl;
                document.getElementById('zoneAudio')?.appendChild(audio);
                document
                  .getElementById(`${fileUrl}`)!
                  .setAttribute('src', fileUrl);
                document
                  .getElementById(`${fileUrl}`)
                  ?.addEventListener('canplaythrough', (event) => {
                    let test = event.target as HTMLAudioElement;
                    f_duration = Math.round(test.duration);
                    if (f_duration >= FILE_DURATION_LIMIT) {
                      this.showErrorDuration = true;
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      this.countClicked = 0;
                      LoadingService.$(false);
                      return;
                    } else {
                      document
                        .getElementById(`${fileUrl}`)
                        ?.removeAttribute('src');
                      //LoadingService.$(false);
                    }
                  });
              }
            });
          }

          if (
            this.showErrorLimitSizeFile ||
            this.showErrorMaximumFiles ||
            this.showErrorDuration
          ) {
            this.countClicked = 0;
            LoadingService.$(false);
            return;
          } else {
            if (this.countClicked == 1) {
              if (
                this.isValidToAddAssets &&
                this.isValidToAddAudio &&
                !this.showEditRule
              ) {
                this._postService
                  .inlivewithPostCommentEditContentCommentOnPostPut$Json({
                    body: {
                      commentId: this.currentReplyComment.id!,
                      message: this.content,
                      mentionReceivers: mentionIds
                    },
                  })
                  .pipe(
                    tap((res) => this.deleteFromComment()),
                    mergeMap((res) =>
                      this.addMediaOnComment(this.currentReplyComment)
                    ),
                    mergeMap((res) =>
                      this.getCommentByCommentId(this.currentReplyComment.id!)
                    ),
                    tap((res) => {
                      var index = this.comments.findIndex(
                        (x) => x.id === this.currentReplyComment.id!
                      );
                      if (index > -1) {
                        this.comments[index] = res.data!;
                      } else {
                        var index = this.comments.findIndex(
                          (x) => x.id === res.data?.parentId!
                        );
                        var indexChild = this.comments[
                          index
                        ].inverseParent!.findIndex(
                          (x) => x.id === res.data?.id!
                        );

                        if (indexChild > -1) {
                          this.comments[index].inverseParent![indexChild!] =
                            res.data!;
                        }
                      }
                      this.comments.forEach((c) => {
                        c.inverseParent = _.orderBy(
                          c.inverseParent,
                          'dateCreated'
                        );
                      });
                      this.getItem(res.data!);
                      // this.addMentionReceivers(mentionIds, res.data?.id!)
                    }),
                    tap((res) => {
                      if (res.res == 0) {
                        (this.content = ''), (this.isHaveParent = false);
                        this.urls = [];
                        this.filePosts = [];
                        this.urlAudio = '';
                        //this.currentReplyComment = {};
                        this.isEditComment = false;
                        this.showEditRule = false;
                      }
                    })
                  )
                  .subscribe(() => {
                    LoadingService.$(false);
                    this.countClicked = 0;
                    this.scrollToBottom();
                  });

                return;
              }
            } else {
              LoadingService.$(false);
              this.countClicked = 0;
            }
          }
        } else {
          this.showEditRule = true;
          this.countClicked = 0;
          LoadingService.$(false);
          return;
        }
      }
    } else {
      var isCheckAudio = false;
      var isCheckMedia = false;

      if (this.urls.length > 4) {
        this.countClicked = 0;
        this.showErrorMaximumFiles = true;
        LoadingService.$(false);
        //return;
      } else {
        this.showErrorMaximumFiles = false;
        //LoadingService.$(false);
      }

      for (let index = 0; index < this.urls.length; index++) {
        /*check mixed file */
        if (this.urls[index].format === 'audio') {
          isCheckAudio = true;
        }
        if (
          this.urls[index].format === 'image' ||
          this.urls[index].format === 'video'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          this.countClicked = 0;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          return;
        }

        /* check Duration */
        if (
          typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
          this.urls[index].file.type.split('/')[0] === 'video' ||
          this.urls[index].file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(this.urls[index].file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              console.log(f_duration);

              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                this.countClicked = 0;
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                //LoadingService.$(false);
                //return;
              }
            });
        }
      }

      if (
        this.showErrorLimitSizeFile ||
        this.showErrorMaximumFiles ||
        this.showErrorDuration
      ) {
        this.countClicked = 0;
        LoadingService.$(false);
        return;
      } else {
        if (this.countClicked == 1) {
          let nameFiles = this.filePosts.map(x => x.name);
          this._postService
            .inlivewithPostCommentCreateCommentPostPost$Json({
              body: {
                AuthorId: AuthService.GetAccountInfo().id!,
                Message: this.content,
                PostId: this.post.id!,
                StageId: this.post.stageId!,
                MediaCommentFiles: [],
                MediaFileNames: nameFiles
              },
            })
            .pipe(
              tap((res) => {
                if (res.res == 0) {
                  let payload = {
                    data: res.data!,
                    isHaveParent: this.isHaveParent ?? false,
                    currentReplyCommentId:
                      this.currentReplyComment?.id! ?? 'currentReplyCommentId',
                    fromDirectly: true,
                  };
                  this.addMentionReceivers(mentionIds, res.data?.id!)

                  if (res.data?.mediaCreateResponseModels?.length! > 0) {
                    this.addMediaPosts(this.filePosts, res.data?.mediaCreateResponseModels!)
                  }

                  this._notificationService.sendMessage(
                    this.post?.id!,
                    AuthService.GetAccountInfo().id!,
                    payload
                  );
                  this.commentEvent.next(true);
                  (this.content = ''), (this.isHaveParent = false);
                  this.urls = [];
                  this.filePosts = [];
                  this.urlAudio = '';
                  this.isEditComment = false;
                  //this.currentReplyComment = {};
                }
              })
            )
            .subscribe(() => {
              this.scrollToBottom();
              this.countClicked = 0;
              LoadingService.$(false);
            });
        }
      }
    }
  }

  detectFiles(event: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    var isCheckMedia = false;
    var isCheckAudio = false;

    /* check maximum 4 files */
    if (event.target.files.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      if (file) {
        LoadingService.$(true);
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (file.type.split('/')[0] === 'audio') {
          isCheckAudio = true;
        }
        if (
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'image'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          //return;
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              //console.log(f_duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                //return;
              }
            });
        }

        reader.onload = () => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          file.id = new Date().getTime();
          if (this.isHaveParent) {
            this.filePostsChild.push(file);
            this.urlsChild.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
              originalTypeFile: file.type,
            });
          } else {
            this.filePosts.push(file);
            this.urls.push({
              name: file.name,
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
              id: file.id,
              originalTypeFile: file.type,
            });
          }

          /* check maximum 4 files */
          if (this.urls.length > 4 || this.urlsChild.length > 4) {
            this.showErrorMaximumFiles = true;
            LoadingService.$(false);
            //event.target.value = null;
            //return;
          }
          event.target.value = null;
        };
      }
      LoadingService.$(false);
    }
  }

  removeAsset(fileName: string) {
    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urls.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urls.length; index++) {
      /*check mixed file */
      if (this.urls[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urls[index].format === 'image' ||
        this.urls[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urls[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
        this.urls[index].file.type.split('/')[0] === 'video' ||
        this.urls[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urls[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio = new Blob();
  }

  removeAssetChild(fileName: string) {
    this.urlsChild = this.urlsChild.filter((file) => file.id !== fileName);
    this.filePostsChild = this.filePostsChild.filter(
      (file) => file.id !== fileName
    );

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urlsChild.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urlsChild.length; index++) {
      /*check mixed file */
      if (this.urlsChild[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urlsChild[index].format === 'image' ||
        this.urlsChild[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urlsChild[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urlsChild[index].file.name)) ||
        this.urlsChild[index].file.type.split('/')[0] === 'video' ||
        this.urlsChild[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urlsChild[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        console.log('???', document.getElementById(`${fileUrl}`)!);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  removeAudioChild() {
    this.urlAudioChild = '';
    this.blobFileAudioChild;
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  sanitizeChild() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudioChild);
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  showPopupConfirm() {
    this.isShowPopupConfirm = true;
  }

  deleteComment() {
    LoadingService.$(true);
    this._postService
      .inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(
        {
          commentPostId: this.currentReplyComment.id!,
          accountRequestDeleteId: AuthService.GetAccountInfo().id!,
        }
      )
      .pipe(
        tap((res) => {
          this.commentEvent.emit(false);
          this.comments = this.comments.filter(
            (c) => c.id !== this.currentReplyComment.id
          );

          this.comments.forEach((res) => {
            res.inverseParent = res.inverseParent!.filter(
              (c) => c.id !== this.currentReplyComment.id
            );
          });

          this.comments = this.comments.filter(
            (c) => c.id !== this.currentReplyComment.id
          );
        }),
        tap((res) => {
          if (res.res == 0) {
            this.currentReplyComment = {};
            this.isShowPopupConfirm = false;
            LoadingService.$(false);
          }
        })
      )
      .subscribe(() => {
        this.comments.forEach((c) => {
          c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
        });
        LoadingService.$(false);
      });
  }

  openAccountInfo(item: any, isBanned: boolean, isCeleb: boolean) {
    if (AuthService.GetAccountInfo().id! !== item && isBanned == false && isCeleb == false) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: item },
      });
    }
  }

  getCommentByPostId(limit: number = 6) {
    return this._postService
      .inlivewithPostGetCommentsOnPostPostIdGet$Json({
        postId: this.post.id!,
        stageId: this.post.stageId!,
        limit: limit,
        page: this.page++,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.comments = [...this.comments, ...(res.data?.page ?? [])];
            this.comments = _.unionBy(this.comments, 'dateCreated');
            this.comments.forEach((c) => {
              this.getItem(c);
            });

            this.comments.forEach((c) => {
              c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
            });

            if (res.data?.currentPage! == 1) {
              this.scrollToBottom();
            }
            if (res.data?.next == -1) {
              this.isDone = true;
            }
          } else if (res.res == -2) {
            this.deletePostEvent.emit();
          }
        })
      );
  }

  onScroll() {
    this.getCommentByPostId().subscribe(() =>
      this.handlingAfterGetData$.next()
    );
  }

  getCommentByCommentId(commendId: string) {
    return this._postService.inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json(
      {
        postId: this.post.id!,
        stageId: this.post.stageId!,
        commentId: commendId,
      }
    );
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav') ||
          asset?.url!.endsWith('.mp3')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  actionDelete(media: MediaCommentPost) {
    this.filesEdit.push(media);
    this.filesDelete = this.filesDelete.filter(x => x.id! !== media.id!);
  }

  deleteFromComment() {
    if (this.filesEdit.length > 0) {
      let stringIds: string[] = [];
      this.filesEdit.map(x => stringIds.push(x.id));
      this._postService
        .inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json({
          body: {
            commentPostId: this.filesDelete[0]?.commentPostId! ?? this.filesEdit[0]?.commentPostId!,
            postId: this.post.id!,
            mediaOnCommentPostFileUrls: stringIds,
          },
        })
        .pipe(
          mergeMap((res) =>
            this.getCommentByCommentId(this.currentReplyComment.id!)
          ),
          tap((res) => {
            this.filesDelete = [];
            this.filesEdit = [];
            var index = this.comments.findIndex(
              (x) => x.id === this.currentReplyComment.id!
            );
            if (index > -1) {
              this.comments[index] = res.data!;
            } else {
              var index = this.comments.findIndex(
                (x) => x.id === res.data?.parentId!
              );
              var indexChild = this.comments[index].inverseParent!.findIndex(
                (x) => x.id === res.data?.id!
              );

              if (indexChild > -1) {
                this.comments[index].inverseParent![indexChild!] = res.data!;
              }
              this.comments.forEach((c) => {
                c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
              });
            }

            this.currentReplyComment = res.data!;
          })
        )
        .subscribe();
    }
  }

  addMediaOnComment(mediaComment: MediaCommentPost) {
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts = [];
      this.filePosts.push(this.blobFileAudio);
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild = [];
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    return this._postService.inlivewithPostCreateMediaCommentPostPost$Json(
      {
        commentPostId: mediaComment.id!,
        body: this.isHaveParent
          ? this.filePostsChild.map(x => x.name)
          : this.filePosts.map(x => x.name),
      }
    ).pipe(
      tap(res => {
        if (res.res == 0) {
          this.addMediaPosts(this.isHaveParent ? this.filePostsChild : this.filePosts, res.data!);
        }
      })
    );
  }

  bannedFromClub() {
    return this._clubService
      .inlivewithClubCreateAccountClubBannedPost$Json({
        body: {
          accountBannedId: this.currentReplyComment.authorId!,
          authorId: AuthService.GetAccountInfo().id!,
          clubId: this.post.clubId!,
          type: 'ADMIN_BANNED_MEMBER',
        },
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.refresh.emit();
          }
        })
      )
      .subscribe();
  }

  urlify(text: any) {
    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName!.replaceAll(' ', '') == uname
    )[0];

    if (user) {
      if (user.isBlockedByClub) {
        return `<a style="pointer-events: none;">${this._translateService.instant('INLIVER_UNKNOWN')}</a>`;
      }
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', '')}" target="_blank">${username}</a>`;
    }
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  itemSelected(event: any) { }

  getListInlivers() {
    return this._clubService
      .inlivewithClubGetAllMembersClubIdGet$Json({
        clubId: this.post.clubId!,
        limit: 100000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listMember = res.data?.page!;

            let stageIsPrivate = this.post.stage?.isPrivate;

            let mentionList = res.data?.page!.filter((inliver) =>
              stageIsPrivate
                ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
                : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
                inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
            )!;

            if (this.listMention.length == 0) {
              mentionList.forEach((data) => {
                if (data.id == AuthService.GetAccountInfo().id) {
                  return;
                }
                this.listMention.push({
                  username: data.nickName?.trimStart(),
                  name: data.nickName?.trimStart(),
                  img: data.avatar,
                  id: data.id,
                });
              });
            }

            this.initConfigMention();
          }
        })
      )
      .subscribe();
  }

  checkTypeToEditComment(comment: CommentPostResponseModel) {
    let type = '';
    if (comment.mediaCommentPosts?.length! > 0) {
      comment.mediaCommentPosts?.forEach((x) => {
        if (this.checkTypeMedia(x) == 'AUDIO') {
          if (comment.message!) {
            type = 'TEXT_AND_AUDIO';
          } else type = 'ONLY_AUDIO';
        } else if (
          this.checkTypeMedia(x) == 'IMAGE' ||
          this.checkTypeMedia(x) == 'VIDEO'
        ) {
          if (comment.message!) {
            type = 'TEXT_AND_MEDIAS';
          } else type = 'ONLY_MEDIAS';
        }
      });

      if (
        type == 'TEXT_AND_AUDIO' ||
        type == 'ONLY_AUDIO' ||
        type == 'TEXT_AND_MEDIAS' ||
        type == 'ONLY_MEDIAS'
      ) {
        return type;
      } else return 'UNKNOWN';
    } else type = 'TEXT';

    return type;
  }

  getRoleInClub(account: AccountResponseWithRoleInCLubModel) {
    if (account.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY') {
      return this._translateService.instant('CELEBRITY');
    }
    if (
      account.roleInClub == 'ROLE_ANONYMOUS' &&
      account.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS'
    ) {
      return this._translateService.instant('INLIVER');
    }
    if (
      account.roleInClub == 'ROLE_ANONYMOUS' &&
      account.accessLevelInClub == 'ACCESSLEVEL_PLUS'
    ) {
      return this._translateService.instant('INLIVER_PLUS');
    }
    if (account.roleInClub == 'ROLE_ADMIN') {
      return this._translateService.instant('ADMIN');
    }
  }

  checkDisable(comment: CommentPostResponseModel) {
    if (this.post.club?.authorId! == comment.authorId) {
      return false;
    } else {
      return true;
    }
  }

  changeShowEmoji(event: any) {
    event.stopPropagation();
    this.showEmoji = !this.showEmoji;
  }

  addEmoji(event: any) {
    event.$event.stopPropagation();
    if (this.isHaveParent) {
      this.contentReply += event.emoji.native;
    } else this.content += event.emoji.native;
  }

  loadMoreComment(commentParent: CommentPostResponseModel) {
    let page = parseInt(`${commentParent.inverseParent?.length! / 3}`) + 1;
    this._postService
      .inlivewithPostGetCommentsReplyPostIdCommentIdGet$Json({
        commentId: commentParent.id!,
        postId: commentParent.postId!,
        stageId: this.post.stageId!,
        limit: 3,
        page: page,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            commentParent.inverseParent = _.orderBy(
              res.data?.page!.concat(commentParent.inverseParent!),
              'dateCreated'
            );
            res.data?.page!.length! < 3
              ? (commentParent.isContinueReply = false)
              : (commentParent.isContinueReply = true);
            let index = this.comments.findIndex(
              (x) => x.id == commentParent.id
            );
            this.comments[index] = commentParent;
          }
        })
      )
      .subscribe();
  }

  refreshComments(commentId: string) {
    this.getCommentByCommentId(commentId)
      .pipe(
        tap((res) => {
          var index = this.comments.findIndex((x) => x.id === commentId!);
          if (index > -1) {
            this.comments[index] = res.data!;
          } else {
            var index = this.comments.findIndex(
              (x) => x.id === res.data?.parentId!
            );
            var indexChild = this.comments[index]?.inverseParent!.findIndex(
              (x) => x.id === res.data?.id!
            );

            if (indexChild > -1) {
              this.comments[index].inverseParent![indexChild!] = res.data!;
            }
          }
          this.comments.forEach((c) => {
            c.inverseParent = _.orderBy(c.inverseParent, 'dateCreated');
          });
        })
      )
      .subscribe();
  }

  checkProcessMedia(asset: MediaPostResponseModel) {
    // isProcessed = true
    if (asset.isProcessed) {
      if (
        asset.url?.includes('?i=') ||
        asset.url?.includes('?v=') ||
        asset.url?.includes('?a=')
      ) {
        return true;
      } else {
        return false;
      }
    }
    // isProcessed = false
    else {
      if (
        asset.url?.includes('?i=') ||
        asset.url?.includes('?v=') ||
        asset.url?.includes('?a=')
      ) {
        return false;
      } else {
        if (this.post.author?.id == AuthService.GetAccountInfo().id) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  checkProcessMediaFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showMultiMedia(urls: MediaCommentPostResponseModel[], index: number) {
    this.multiMedias = urls;
    this.activeIndex = index;
    this.isShowMultiMedia = true;
  }

  checkAllDoneHaveParams(comment: CommentPostResponseModel) {
    let result = comment.mediaCommentPosts?.every((x) => x.url?.includes('?'))!;
    return !result;
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }
}
