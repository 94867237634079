<p-galleria [value]="urls" [(visible)]="openShowMutilMedia" [numVisible]="5" (visibleChange)="close()" [circular]="true"
    [showItemNavigators]="true" [fullScreen]="true" [showThumbnails]="false" [showIndicators]="false"
    [(activeIndex)]="activeIndex" [showItemNavigatorsOnHover]="true" [showIndicatorsOnItem]="false">
    <ng-template pTemplate="item" let-item>
        <div class="flex justify-content-center align-items-center">
            <img (load)="checkOrientationImage($event, item)" *ngIf="checkTypeMedia(item) == 'IMAGE'" [src]="item.url"
                [ngClass]="item.isLandscape ? 'landscape' : 'portable'" />
            <video  (load)="checkOrientationVideo($event, item)" *ngIf="checkTypeMedia(item) == 'VIDEO'" [src]="item.url"
                controls autoplay [ngClass]="item.isLandscape ? 'landscape' : 'portable'"></video>
        </div>
    </ng-template>
</p-galleria>