import { debounceTime, filter, tap } from 'rxjs/operators';
import {
  AccountResponseGeneral,
  CommentPostResponseModel,
  PostResponseModel,
} from 'src/app/core/api/be/models';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PostService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  EEvent,
  EventService,
  IEvent,
} from 'src/app/core/services/event.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GROUP_ILW_DISCUSSION } from 'src/app/core/constant';

@Component({
  selector: 'app-balloon',
  templateUrl: './balloon.component.html',
  styleUrls: ['./balloon.component.scss'],
})
export class BalloonComponent implements OnInit, OnDestroy {
  posts: PostResponseModel[] = [];
  account: AccountResponseGeneral;
  postsHaveNewMessage: string[] = [];
  currentPost: PostResponseModel | null = null;
  isShowLeft = false;
  isShowRight = true;
  isShowScroll = false;

  eventFilter = [
    EEvent.HIDDEN_POST,
    EEvent.DELETE_POST,
    EEvent.END_POST,
    EEvent.OPEN_POST,
    EEvent.CREATE_COMMENT,
  ];

  eventScroll = new Subject();
  @ViewChild('listBalloon') listBalloonEl!: ElementRef<HTMLDivElement>;

  constructor(
    private _postService: PostService,
    private eventService: EventService,
    private notiService: NotificationServicee,
    private router: Router,
    private cdf: ChangeDetectorRef
  ) {
    this.account = AuthService.GetAccountInfo();
    this.eventService
      .receive()
      .pipe(
        filter(
          (p: IEvent) => this.eventFilter.findIndex((e) => e == p.action) >= 0
        )
      )
      .subscribe((data: IEvent) => {
        const post = data.payload as PostResponseModel;
        if (data.action === EEvent.OPEN_POST) this.currentPost = post;
        else if (data.action === EEvent.CREATE_COMMENT) {
          // const comment = data.payload as CommentPostResponseModel;
          // this.notiService.sendMessage(
          //   GROUP_ILW_DISCUSSION,
          //   this.account.id!,
          //   comment.postId!
          // );
        } else this.posts = this.posts.filter((p) => p.id != post.id);
      });
    this.notiService.conected$.subscribe(() => {
      this.notiService.onReceiveMessage((accountId: string, data: string) => {
        if (
          this.postsHaveNewMessage.findIndex((p) => p === data) < 0 &&
          AuthService.GetAccountInfo().id !== accountId
        ) {
          this.postsHaveNewMessage.push(data);
        }
      });
    });
  }

  checkActive(postId: string) {
    return this.postsHaveNewMessage.findIndex((p) => p == postId) >= 0;
  }

  ngOnInit(): void {
    this._postService
      .inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json({
        accountRequestId: this.account.id!,
        limit: 10000
      })
      .subscribe((res) => {
        if (res.res == 0) {
          this.posts = res.data?.page || [];
          this.cdf.detectChanges();
          this.isShowScroll =
            this.listBalloonEl.nativeElement.scrollWidth >
            this.listBalloonEl.nativeElement.offsetWidth;
          if (this.isShowScroll) {
            this.listBalloonEl.nativeElement.addEventListener('scroll', (e) => {
              this.eventScroll.next();
            });
          }
        }
      });

    this.eventScroll.pipe(debounceTime(100)).subscribe(() => {
      const el = this.listBalloonEl.nativeElement;
      const w = el.getBoundingClientRect().width;
      this.isShowLeft = el.scrollLeft > 0;
      this.isShowRight = el.scrollLeft + w < el.scrollWidth - 10;
    });
  }

  openCommentPostDiscussion(post: PostResponseModel) {
    this.router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: post.clubId },
    });
    this.eventService.push({ action: EEvent.OPEN_POST, payload: post });
    this.postsHaveNewMessage = this.postsHaveNewMessage.filter(
      (p) => p !== post.id
    );
  }

  scrollTo(isLeft: boolean = true) {
    let a = this.listBalloonEl.nativeElement;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  ngOnDestroy(): void {}
}
