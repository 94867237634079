import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import _ from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  MessageResponseModel,
  NotificationResponseResponseModel,
} from '../api/be/models';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationServicee {
  public hubConnection!: HubConnection;
  messages: Subject<string> = new Subject();
  conected$ = new ReplaySubject();

  isReloadGoInlive!: boolean;
  isReloadPost!: boolean;
  isReloadClub!: boolean;
  isReloadAvatar!: boolean;
  isReloadNoti!: boolean;
  isReloadComment!: string;
  isReloadCommentAlbum!: boolean;
  isReloadAlbum!: boolean;
  isReloadAvatarAlbum!: boolean;
  isReloadChat!: string;
  isReloadConversation!: string;
  isReloadEvent!: string;
  isReloadNotiIsRead!: boolean;
  isReloadListInviteGoInlive!: boolean;
  isShowNotificationDiscussion!: boolean;
  isReloadCommentFileProcess!: boolean;
  isReloadClubAfterBanned!: string;
  isReloadPostCeleb!: boolean;

  public isReloadGoInlive$$ = new BehaviorSubject<boolean>(
    this.isReloadGoInlive
  );
  public isReloadPost$$ = new BehaviorSubject<boolean>(this.isReloadPost);
  public isReloadClub$$ = new BehaviorSubject<boolean>(this.isReloadClub);
  public isReloadClubAfterBanned$$ = new BehaviorSubject<string>(
    this.isReloadClubAfterBanned
  );
  public isReloadComment$$ = new Subject<string>();
  public isReloadCommentFileProcess$$ = new BehaviorSubject<boolean>(
    this.isReloadCommentFileProcess
  );
  public isReloadCommentMedia$$ = new Subject<string>();
  public isReloadCommentAlbum$$ = new BehaviorSubject<boolean>(
    this.isReloadCommentAlbum
  );
  public isReloadAvatar$$ = new BehaviorSubject<boolean>(this.isReloadAvatar);
  public isReloadAlbum$$ = new BehaviorSubject<boolean>(this.isReloadAlbum);
  public isReloadAvatarAlbum$$ = new BehaviorSubject<boolean>(
    this.isReloadAvatarAlbum
  );
  public isReloadChat$$ = new ReplaySubject<string>();
  public isReloadConversation$$ = new ReplaySubject<string>();
  public isReloadEvent$$ = new ReplaySubject<string>();
  public isReloadNoti$$ = new BehaviorSubject<boolean>(this.isReloadNoti);
  public isReloadNotiIsRead$$ = new BehaviorSubject<boolean>(
    this.isReloadNotiIsRead
  );
  public isReloadListInviteGoInlive$$ = new BehaviorSubject<boolean>(
    this.isReloadListInviteGoInlive
  );

  public isShowNotificationDiscussion$$ = new BehaviorSubject<any>(
    this.isShowNotificationDiscussion
  );

  public listIdPostForNotiDiscussion: any[] = [];

  refetchWhenReceiveNoti$ = this.isShowNotificationDiscussion$$.pipe(
    tap((data) => {
      if (data) {
        if (
          !this.listIdPostForNotiDiscussion.includes(
            JSON.parse(data!.content!).Content!
          )
        ) {
          this.listIdPostForNotiDiscussion.push(
            JSON.parse(data!.content!).Content!
          );
          this.listIdPostForNotiDiscussion = _.union(
            this.listIdPostForNotiDiscussion
          );
          this.showNotiDiscussion(data);
        }
      }
    })
  );

  public isReloadPostCeleb$$ = new BehaviorSubject<boolean>(
    this.isReloadPostCeleb
  );

  constructor(
    private messageService: MessageService,
    private _translateService: TranslateService
  ) {
    this.refetchWhenReceiveNoti$.subscribe();
  }

  init() {
    const id = AuthService.GetAccountInfo().id!;
    const endpoint = `${environment.API_URL}/inlivewith/Account/IsOnline/${id}/false`;
    const url = `${environment.SIGNALR_URL}/signalr?userId=${id}&appType=ILWITH&action=POST&endpoint=${endpoint}`;
    const opt = {
      transport: HttpTransportType.LongPolling | HttpTransportType.WebSockets,
    };

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, opt)
      .withAutomaticReconnect()
      .configureLogging(2)
      .build();

    this.hubConnection.onclose(() => {
      console.log('[Developer] SignalR for Chat is "Closed"');
    });

    this.hubConnection.onreconnecting(() => {
      console.log('[Developer] SignalR for Chat is "Closed"');
    });
  }

  connectHub() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalrService: Successful connection message');
        this.conected$.next();
      })
      .catch(() => {
        console.log('SignalrService: Error while establishing connection');
      });
  }

  onChat(groupName: string, callback: any) {
    this.hubConnection.on('BroadcastConversation', (data) => {
      let mes: MessageResponseModel = data['data'];
      mes = _.mapKeys(mes, (value, key) => _.camelCase(key));
      callback(mes);
    });
    this.joinGroup(groupName);
  }

  onConversation(callback: any) {
    this.hubConnection.on('BroadcastConversation', (data) => {
      let mes: MessageResponseModel = data['data'];
      mes = _.mapKeys(mes, (value, key) => _.camelCase(key));
      callback(mes);
    });

    this.hubConnection.onreconnected((data) => {
      console.log('[Developer] SignalR for Chat is "Reconnected"');
      callback(data);
    });
  }

  onInliveGroup(callback: any) {
    this.hubConnection.on('BroadcastInliveGroup', (data) => {
      let accountIds: string[] = data['data']['accountIds'];
      let conversationIds: string[] = data['data']['conversationIds'];
      let isOnline: boolean = data['type'] == 2; // type isonline = 2
      callback(accountIds, conversationIds, isOnline);
    });

    this.hubConnection.onreconnected((data) => {
      console.log('[Developer] SignalR for Chat is "Reconnected"');
      callback(data);
    });
  }

  onNotification(callback: any) {
    this.hubConnection.on('BroadcastNotification', (data) => {
      let mes: NotificationResponseResponseModel = data['data'];
      mes = _.mapKeys(mes, (value, key) => _.camelCase(key));
      callback(mes);
    });

    this.hubConnection.onreconnected((data) => {
      console.log('[Developer] SignalR for Notification is "Reconnected"');
      callback(data);
    });
  }

  joinGroup(groupName: string) {
    this.hubConnection.invoke('JoinGroup', groupName);
  }

  leaveGroup(groupName: string) {
    this.hubConnection.invoke('LeaveGroup', groupName);
  }

  sendMessage<T>(groupName: string, accountId: string, value: T) {
    this.hubConnection.invoke(
      'SendMessageToGroup',
      groupName,
      accountId,
      value
    );
  }

  onReceiveMessage(callback: any) {
    this.hubConnection.on('SendMessageToGroup', (accountId, value) => {
      callback(accountId, value);
    });
  }

  isTyping(groupName: string, accountId: string, value: boolean) {
    this.hubConnection.invoke('IsTyping', groupName, accountId, value);
  }

  onTyping(callback: any) {
    this.hubConnection.on('IsTyping', (accountId, value) => {
      callback(accountId, value);
    });
  }

  showNotiDiscussion(data: any) {
    this.messageService.clear();

    this.messageService.add({
      severity: 'success',
      summary: JSON.parse(data?.content!)!.Title!,
      detail: JSON.parse(data?.content!)!.SubTitle!,
      key: 'noti-comment-post-discussion',
      data: data,
      closable: false,
    });
  }
}
