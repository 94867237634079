import { Component, Input, OnInit } from '@angular/core';
import { AccountEventResponseModel, ClubResponseWithTotalMemberAndGoInliveModel, ClubResponseWithTotalMemberModel, EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { defaultAvatar } from 'src/app/core/constant';
import { ClubService, EventService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit {

  @Input() event!: EventWithCheckAccountJoinEventResponseModel
  @Input() isJoinClub: boolean = true;

  club!: ClubResponseWithTotalMemberAndGoInliveModel
  totalMembers!: number;

  startedDate!: string;
  endedDate!: string;
  interval!: NodeJS.Timeout;

  accountId: string = AuthService.GetAccountInfo().id!
  checkEventEnd!: boolean

  defaultAvatar = defaultAvatar;
  defaultPoster = "../../../../assets/images/svgs/matt-club-avt.svg";

  isOpenEditEventPopup: boolean = false;
  isOpenConfirmJoinEvent: boolean = false;
  isOpenConfirmRuleClub: boolean = false;

  constructor(
    private _router: Router,
    private _eventService: EventService,
    private _clubService: ClubService
  ) { }

  ngOnInit(): void {
    this.club = this.event.club ? Object.assign(this.event.club!): {};
    this.checkEventEnd = this.checkTimeEnd();
    // this.interval = setInterval(() => {

    //   if (this.checkEventEnd) {
    //     clearInterval(this.interval);
    //   }
    // }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }


  getTotalMembers() {
    this._clubService.inlivewithClubAccountCountClubIdGet$Json({
      clubId: this.event.clubId!
    }).subscribe((res) => {
      this.totalMembers = res.data!
    })
  }

  detailEvent(event: EventWithCheckAccountJoinEventResponseModel) {
    // if (this.isJoinClub) {
    this._router.navigate(['manage-club/detail-event', this.event.id!], { queryParams: { eventId: this.event.id!, clubId: this.event.clubId! } });
    // } else {
    //   this._router.navigate(['manage-club/detail-club'], { queryParams: { clubId: this.event.clubId! } });
    // }
  }

  joinEvent(joined: boolean, event: Event) {
    event.stopPropagation();
    if (this.isJoinClub) {
      if (joined) {
        this._eventService.inlivewithEventUnJoinPut$Json({
          body: {
            accountId: AuthService.GetAccountInfo().id!,
            eventId: this.event.id!
          }
        }).subscribe(res => {
          if (res.res == 0) {
            window.location.reload();
          }
        })
      } else {
        this.confirmJoinEvent();
      }
    } else {
      this.getTotalMembers();
      this.isOpenConfirmRuleClub = true;
    }
  }

  confirmJoinEvent() {
    this._eventService.inlivewithEventJoinPut$Json({
      body: {
        accountId: AuthService.GetAccountInfo().id!,
        eventId: this.event.id!
      }
    }).subscribe(res => {
      if (res.res == 0) {
        window.location.reload();
      }
    })
  }

  getAccountJoinEvent() {
    return this.event.accountJoinEvents?.filter(x => x.id! != this.event.author?.id!).slice(0, 2)!
  }

  editEvent(event: Event, eventt: EventWithCheckAccountJoinEventResponseModel) {
    event.stopPropagation();
    this.isOpenEditEventPopup = true;
  }

  getPoster(url: string) {
    if (!url) {
      return;
    }

    if (url.includes('?i=') || url.includes('?v=') || url.includes('?a=') || url.includes('?t=') || url.includes('?=')) {
      return this.getUrl(url);
    } else {
      return window.origin + '/assets/images/naloader.svg';
    }
  }

  getUrl(asset: any) {

    if (!asset!.includes('?')) {

    } else {

      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset;
  }

  checkTimeEnd() {
    if (this.event.type === 'NORMAL_EVENT') {
      if (new Date().getSeconds() > new Date(this.event.dateEnd!).getSeconds()) {
        return false;
      }
      return true;
    } else {
      if (new Date().getSeconds() > new Date(this.event.dateStart!).getSeconds()) {
        return false;
      }
      return true;
    }
  }

}
