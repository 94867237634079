<div class="flex flex-column text-white" style="overflow-wrap: anywhere;" *ngIf="listReasonReport">
    <span class="mt-2 fs-18 mb-2 text-center">
        {{title}} @{{nameObject}}
    </span>
    <div class="mt-3 mb-2 text-left">
        <span class="mt-2 fs-16 mb-1" *ngIf="type == 'COMMENT' || type == 'COMMENT_ALBUM'">
            {{'WHY_REPORT_COMMENT' | translate}} @{{nameObject}} ? <br><br>
            <span class="fs-14 text-disabled">
                {{'WHY_REPORT_SUBTITLE' | translate }}
            </span>
        </span>
        <span class="mt-2 fs-16 mb-1" *ngIf="type == 'POST'">
            {{'WHY_REPORT_POST' | translate}} @{{nameObject}} ? <br><br>
            <span class="fs-14 text-disabled">
                {{'WHY_REPORT_SUBTITLE' | translate }}
            </span>
        </span>
        <span class="mt-2 fs-16 mb-1" *ngIf="type == 'CLUB'">
            {{'WHY_REPORT_CLUB' | translate}} @{{nameObject}} ? <br><br>
            <span class="fs-14 text-disabled">
                {{'WHY_REPORT_SUBTITLE' | translate }}
            </span>
        </span>
        <span class="mt-2 fs-16 mb-1" *ngIf="type == 'INLIVER'">
            {{'WHY_REPORT_CLUB' | translate}} @{{nameObject}} ? <br><br>
            <span class="fs-14 text-disabled">
                {{'WHY_REPORT_SUBTITLE' | translate }}
            </span>
        </span>
    </div>
    <div class="mt-2 flex flex-column align-items-start">
        <div class="fs-16 mb-3 mt-3" *ngFor="let reason of listReasonReport">
            <span class="cursor-pointer" (click)="goNext(reason.value)">
                {{reason.value | translate}}
            </span>
        </div>
    </div>
    <div class="flex align-items-center justify-content-center">
        <button pButton type="submit" label="{{'CANCEL' | translate}}" (click)="cancel.emit()"
            class="mt-5 ml-2 p-button-outlined p-button-secondary label"></button>
    </div>
</div>