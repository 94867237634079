<div class="card p-3 mt-3">
    <div class="title">
        {{ 'MY_FRIENDS' | translate}} ({{totalFriend}})
    </div>
    <div class="flex w-100 align-items-center search mt-3" style="width:100%;position: relative;">
        <i class="pi pi-search icon-search"></i>
        <input style="width:100%" type="text" pInputText [(ngModel)]="searchText" (ngModelChange)="searchFriend()"
            placeholder="{{ 'TO_MEET_FRIENDS' | translate}}" />
    </div>
    <div class="mt-3" *ngIf="friends.length > 0">
        <span class="fs-14" style="font-weight: bold;">
            {{'FRIEND_ADDED_RECENTTLY' | translate}}
        </span>
        <div class="flex flex-wrap" style="overflow: hidden;">
            <ng-container *ngFor="let friend of friends; let i = index">
                <div class="flex mt-2 mr-2 ml-2" *ngIf="i < 3">
                    <app-card-friend [account]="friend"></app-card-friend>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="mt-5" *ngIf="friends.length > 0">
        <div class="flex justify-content-between align-items-center">
            <span class="fs-14" style="font-weight: bold;">
                {{'FRIEND_LIST' | translate}}
            </span>

            <span class="text-view-all cursor-pointer" routerLink="/friend-list">
                {{'SEE_EVERYTHING' | translate}}
            </span>

        </div>
        <div class="flex flex-wrap" style="overflow: hidden;">
            <ng-container *ngFor="let friend of friends; let i = index">
                <div class="flex mt-2 mr-2 ml-2" *ngIf="i >= 3">
                    <app-card-friend [account]="friend"></app-card-friend>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="friends.length == 0 && currentUser?.id!">
        <div class="ml-2 mt-5 pt-5">
            {{'TEXT_INVITE_FRIEND_JOIN_ILW' | translate}}
        </div>

        <div class="list-social mt-2 flex justify-content-around">
            <div class="m-2 flex align-items-center justify-content-center flex-column">
                <div class="icon cursor-pointer link" (click)="copyLink()"></div>
                <span>
                    {{'CLUB_COPY_LINK_SHARE' | translate}}
                </span>
            </div>
            <div class="m-2 flex align-items-center justify-content-center flex-column">
                <div class="icon cursor-pointer whatsapp" (click)="shareWhatsApp()"></div>
                <span>
                    {{'APP_WHATSAPP' | translate}}
                </span>
            </div>
            <div class="m-2 flex align-items-center justify-content-center flex-column">
                <div class="icon cursor-pointer mess" (click)="openMessenger()"></div>
                <span>
                    {{'APP_MESSENGER' | translate}}
                </span>
            </div>
            <div class="m-2 flex align-items-center justify-content-center flex-column">
                <div class="icon cursor-pointer gmail" (click)="inviteViaMail()"></div>
                <span>
                    {{'GMAIL' | translate}}
                </span>
            </div>
        </div>
    </div>

</div>

<ng-container *ngIf="showInviteFriendViaMail">
    <app-invite-friend-via-mail [(showDialog)]="showInviteFriendViaMail"></app-invite-friend-via-mail>
</ng-container>