import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Club, ClubInputModel, ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';


@Component({
  selector: 'app-edit-privacy-club',
  templateUrl: './edit-privacy-club.component.html',
  styleUrls: ['./edit-privacy-club.component.scss']
})
export class EditPrivacyClubComponent implements OnInit {

  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() showDialog: boolean = false;
  @Output() showDialogChange = new EventEmitter();

  isPrivate!: boolean;

  constructor(
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.isPrivate = this.club?.isPrivate!;
  }

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  submit() {
    if (this.isPrivate) {
      this.setPrivate().subscribe(res => {
        if (res.res == 0) {
          this.showNotification(this._translateService.instant('CHANGE_INFOR_CLUB'))
          location.reload();
        }
      });
    }
    else {
      this.setPublic().subscribe(res => {
        if (res.res == 0) {
          this.showNotification(this._translateService.instant('CHANGE_INFOR_CLUB'))
          location.reload();
        }
      });
    }
  }

  setPrivate() {
    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club?.id!,
    }
    return this._clubService.inlivewithClubPrivateAdminIdClubIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club?.id!,
        body
      }
    );
  }

  setPublic() {
    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: this.club?.id!,
    }
    return this._clubService.inlivewithClubPublicAdminIdClubIdPut$Json(
      {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: this.club?.id!,
        body
      }
    );
  }

  showNotification(subtitle: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: subtitle,
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => this.onHide());
  }
}
