import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private static readonly _subject = new ReplaySubject<boolean>();
  private static _show: boolean = false;

  public static $(value: boolean) {
    // this._subject.next(value);
    window.open(
      environment.WEB_PORTAL_URL + `/home?callback=${window.origin}/announcement/verify-email-success&lang=${LocalStorageService.GetLocalStorage('language').toLowerCase()}`,
      'targetWindow',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=640`
    );
  }

  public static $$(): Observable<boolean> {
    return this._subject.pipe(
      tap((res) => {
        this._show = res;
      })
    );
  }

  public static IsLoading(): boolean {
    return this._show;
  }
}
