/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountStageResponseModelListResponseModel } from '../models/account-stage-response-model-list-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { Int64ResponseModel } from '../models/int-64-response-model';
import { StageGuidsInputModel } from '../models/stage-guids-input-model';
import { StageInputModel } from '../models/stage-input-model';
import { StageObjectCreateInputModel } from '../models/stage-object-create-input-model';
import { StageResponseModelResponseModel } from '../models/stage-response-model-response-model';
import { StageStringInputModel } from '../models/stage-string-input-model';
import { StageWithCheckExistAlbumModelPaginationModelResponseModel } from '../models/stage-with-check-exist-album-model-pagination-model-response-model';
import { StageWithCheckExistAlbumModelResponseModel } from '../models/stage-with-check-exist-album-model-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class StageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithStagesPageGet
   */
  static readonly InlivewithStagesPageGetPath = '/inlivewith/Stages/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesByClubIdClubIdGet
   */
  static readonly InlivewithStagesByClubIdClubIdGetPath = '/inlivewith/Stages/ByClubId/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet
   */
  static readonly InlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGetPath = '/inlivewith/Stages/ByClubIdAndAcceptViewPrivate/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdAndAcceptViewPrivateClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageByIdStageIdGet
   */
  static readonly InlivewithStageByIdStageIdGetPath = '/inlivewith/Stage/ById/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByIdStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Plain$Response(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByIdStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByIdStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Plain(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageByIdStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByIdStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Json$Response(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByIdStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByIdStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Json(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageByIdStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageHasAccessAccountIdClubIdStageIdGet
   */
  static readonly InlivewithStageHasAccessAccountIdClubIdStageIdGetPath = '/inlivewith/Stage/HasAccess/{accountId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageHasAccessAccountIdClubIdStageIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageHasAccessAccountIdClubIdStageIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageCreateAdminIdClubIdPost
   */
  static readonly InlivewithStageCreateAdminIdClubIdPostPath = '/inlivewith/Stage/Create/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageCreateAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StrictHttpResponse<StageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageCreateAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StageResponseModelResponseModel> {

    return this.inlivewithStageCreateAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModelResponseModel>) => r.body as StageResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageCreateAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StrictHttpResponse<StageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageCreateAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StageResponseModelResponseModel> {

    return this.inlivewithStageCreateAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModelResponseModel>) => r.body as StageResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesLikeNameNameGet
   */
  static readonly InlivewithStagesLikeNameNameGetPath = '/inlivewith/Stages/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageByNameNameGet
   */
  static readonly InlivewithStageByNameNameGetPath = '/inlivewith/Stage/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Plain$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Plain(params: {
    name: string;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Json$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Json(params: {
    name: string;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageNameStageIdGet
   */
  static readonly InlivewithStageNameStageIdGetPath = '/inlivewith/Stage/Name/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageNameStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Json(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageNameStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageNameAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageNameAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Name/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDescriptionStageIdGet
   */
  static readonly InlivewithStageDescriptionStageIdGetPath = '/inlivewith/Stage/Description/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageDescriptionStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Json(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageDescriptionStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDescriptionAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageDescriptionAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Description/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesByDefaultGet
   */
  static readonly InlivewithStagesByDefaultGetPath = '/inlivewith/Stages/ByDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByDefaultGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByDefaultGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByDefaultGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByDefaultGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesByNonDefaultGet
   */
  static readonly InlivewithStagesByNonDefaultGetPath = '/inlivewith/Stages/ByNonDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByNonDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByNonDefaultGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByNonDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByNonDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByNonDefaultGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByNonDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByNonDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByNonDefaultGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByNonDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByNonDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByNonDefaultGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesByNonDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsDefaultStageIdGet
   */
  static readonly InlivewithStageIsDefaultStageIdGetPath = '/inlivewith/Stage/IsDefault/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDefaultStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDefaultStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDefaultStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDefaultStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDefaultStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDefaultStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDefaultStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDefaultStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDefaultAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageDefaultAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Default/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDefaultAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDefaultAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDefaultAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesPublicGet
   */
  static readonly InlivewithStagesPublicGetPath = '/inlivewith/Stages/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPublicGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPublicGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPublicGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPublicGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPublicGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPublicGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesPrivateGet
   */
  static readonly InlivewithStagesPrivateGetPath = '/inlivewith/Stages/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPrivateGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPrivateGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPrivateGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPrivateGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPrivateGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPrivateGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsPublicStageIdGet
   */
  static readonly InlivewithStageIsPublicStageIdGetPath = '/inlivewith/Stage/IsPublic/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPublicStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Plain$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPublicStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPublicStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Plain(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPublicStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPublicStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Json$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPublicStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPublicStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Json(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPublicStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsPrivateStageIdGet
   */
  static readonly InlivewithStageIsPrivateStageIdGetPath = '/inlivewith/Stage/IsPrivate/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPrivateStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPrivateStageIdGet$Plain$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPrivateStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPrivateStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPrivateStageIdGet$Plain(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPrivateStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPrivateStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPrivateStageIdGet$Json$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPrivateStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPrivateStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPrivateStageIdGet$Json(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPrivateStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut
   */
  static readonly InlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePutPath = '/inlivewith/Stage/Public/{adminId}/{clubId}/{stageId}/{isAcceptViewPrivate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut
   */
  static readonly InlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePutPath = '/inlivewith/Stage/Private/{adminId}/{clubId}/{stageId}/{isAcceptViewPrivate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    isAcceptViewPrivate: boolean;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesAccessLevelAnonymousGet
   */
  static readonly InlivewithStagesAccessLevelAnonymousGetPath = '/inlivewith/Stages/AccessLevel/Anonymous';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesAccessLevelAnonymousGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelAnonymousGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesAccessLevelAnonymousGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelAnonymousGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesAccessLevelAnonymousGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesAccessLevelAnonymousGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelAnonymousGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesAccessLevelAnonymousGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelAnonymousGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesAccessLevelAnonymousGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesAccessLevelPlusGet
   */
  static readonly InlivewithStagesAccessLevelPlusGetPath = '/inlivewith/Stages/AccessLevel/Plus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesAccessLevelPlusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelPlusGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesAccessLevelPlusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelPlusGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesAccessLevelPlusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesAccessLevelPlusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelPlusGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesAccessLevelPlusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesAccessLevelPlusGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesAccessLevelPlusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelIsAnonymousStageIdGet
   */
  static readonly InlivewithStageAccessLevelIsAnonymousStageIdGetPath = '/inlivewith/Stage/AccessLevel/IsAnonymous/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsAnonymousStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsAnonymousStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsAnonymousStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelIsPlusStageIdGet
   */
  static readonly InlivewithStageAccessLevelIsPlusStageIdGetPath = '/inlivewith/Stage/AccessLevel/IsPlus/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsPlusStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsPlusStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsPlusStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsPlusStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/AccessLevel/Anonymous/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/AccessLevel/Plus/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesMembersCountGet
   */
  static readonly InlivewithStagesMembersCountGetPath = '/inlivewith/Stages/Members/Count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersCountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersCountGet$Plain$Response(params?: {
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersCountGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersCountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersCountGet$Plain(params?: {
    clubId?: string;
    stageId?: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithStagesMembersCountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersCountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersCountGet$Json$Response(params?: {
    clubId?: string;
    stageId?: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersCountGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersCountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersCountGet$Json(params?: {
    clubId?: string;
    stageId?: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithStagesMembersCountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesMembersGet
   */
  static readonly InlivewithStagesMembersGetPath = '/inlivewith/Stages/Members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersGet$Plain$Response(params?: {
    clubId?: string;
    stageId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersGet$Plain(params?: {
    clubId?: string;
    stageId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithStagesMembersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersGet$Json$Response(params?: {
    clubId?: string;
    stageId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesMembersGet$Json(params?: {
    clubId?: string;
    stageId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithStagesMembersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesMembersAdminIdClubIdStageIdPost
   */
  static readonly InlivewithStagesMembersAdminIdClubIdStageIdPostPath = '/inlivewith/Stages/Members/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersAdminIdClubIdStageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<AccountStageResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersAdminIdClubIdStageIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStageResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersAdminIdClubIdStageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<AccountStageResponseModelListResponseModel> {

    return this.inlivewithStagesMembersAdminIdClubIdStageIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountStageResponseModelListResponseModel>) => r.body as AccountStageResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersAdminIdClubIdStageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<AccountStageResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersAdminIdClubIdStageIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStageResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersAdminIdClubIdStageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdPost$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<AccountStageResponseModelListResponseModel> {

    return this.inlivewithStagesMembersAdminIdClubIdStageIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountStageResponseModelListResponseModel>) => r.body as AccountStageResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesMembersAdminIdClubIdStageIdDelete
   */
  static readonly InlivewithStagesMembersAdminIdClubIdStageIdDeletePath = '/inlivewith/Stages/Members/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersAdminIdClubIdStageIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<AccountStageResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStageResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersAdminIdClubIdStageIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<AccountStageResponseModelListResponseModel> {

    return this.inlivewithStagesMembersAdminIdClubIdStageIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountStageResponseModelListResponseModel>) => r.body as AccountStageResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesMembersAdminIdClubIdStageIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<AccountStageResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesMembersAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStageResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesMembersAdminIdClubIdStageIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagesMembersAdminIdClubIdStageIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<AccountStageResponseModelListResponseModel> {

    return this.inlivewithStagesMembersAdminIdClubIdStageIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountStageResponseModelListResponseModel>) => r.body as AccountStageResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesDeletedGet
   */
  static readonly InlivewithStagesDeletedGetPath = '/inlivewith/Stages/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesActivatedGet
   */
  static readonly InlivewithStagesActivatedGetPath = '/inlivewith/Stages/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StageWithCheckExistAlbumModelPaginationModelResponseModel> {

    return this.inlivewithStagesActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelPaginationModelResponseModel>) => r.body as StageWithCheckExistAlbumModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsDeletedStageIdGet
   */
  static readonly InlivewithStageIsDeletedStageIdGetPath = '/inlivewith/Stage/IsDeleted/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDeletedStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDeletedStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDeletedStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDeletedStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDeletedStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDeletedStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDeletedStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDeletedStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDeleteAdminIdClubIdStageIdDelete
   */
  static readonly InlivewithStageDeleteAdminIdClubIdStageIdDeletePath = '/inlivewith/Stage/Delete/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDeleteAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDeleteAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageWithCheckExistAlbumModelResponseModel> {

    return this.inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageWithCheckExistAlbumModelResponseModel>) => r.body as StageWithCheckExistAlbumModelResponseModel)
    );
  }

}
