<p-dialog [(visible)]="openPopup" [modal]="true" [draggable]="false" [modal]="true" (onHide)="hidePopup()">
    <div class="card">
        <div class="flex align-items-center justify-content-center">
            <span class="label text-center text-white fs-22">{{title}}</span>
        </div>
        <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%;position: relative;">
            <i class="pi pi-search icon-search"></i>
            <input style="width:100%" type="text" pInputText [(ngModel)]="searchText" (ngModelChange)="search()"
                placeholder="{{'INPUT_SEARCH_MEMBER' | translate}}" />
        </div>
        <div class="admin-club" *ngIf="listAdminstrators.length > 0">
            <div class="title mt-3">
                <span class="text-white fs-20">{{'MODERATERS' | translate}} ({{listAdminstrators!.length!}})</span>
            </div>
            <div class="list-inliverplus">

                <div class="flex align-items-center justify-content-between mt-3 mb-3"
                    *ngFor="let admin of listAdminstrators | filter:searchText">
                    <div class="flex align-items-center">
                        <div class="zone-avt">
                            <img class="fit-size border-image cursor-pointer"
                                [src]="admin.avatar ? (admin.avatar | fetchUrlImage) : defaultAvatar"
                                (click)="openAccountInfo(admin)">
                            <img *ngIf="club.author?.id! === admin.id!" class="isAdmin"
                                src="../../../assets/images/svgs/admin-club.svg">
                        </div>
                        <div class="flex flex-column align-items-start">
                            <span class="ml-2 text-white">{{admin.nickName! | limitString:false:40}}</span>
                            <span *ngIf="club.author?.id! === admin.id!" [ngClass]="getColorRole(admin)">{{'CREATOR' |
                                translate}}</span>
                            <span *ngIf="club.author?.id! !== admin.id!" [ngClass]="getColorRole(admin)">{{'ADMIN' |
                                translate}}</span>
                        </div>
                    </div>
                    <div *ngIf="accountOwner !== admin.id!">
                        <i class="pi pi-ellipsis-h cursor-pointer"
                            (click)="checkIsFriend(admin.id!); menu.toggle($event);"></i>
                        <p-menu #menu [popup]="true" [model]="getItemMenu(admin, 'MODERATERS')" [appendTo]="'body'"
                            styleClass="menu-comment"></p-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-club mt-5" *ngIf="listInliverPlus.length > 0">
            <div class="title">
                <span class="text-white fs-20">{{'INLIVERSPLUS' | translate}} ({{listInliverPlus!.length!}})</span>
            </div>
            <div class="list-inliverplus">

                <div class="flex align-items-center justify-content-between mb-3 mt-3"
                    *ngFor="let inliverPlus of listInliverPlus | filter:searchText">
                    <div class="flex align-items-center cursor-pointer" (click)="openAccountInfo(inliverPlus)">
                        <div class="zone-avt">
                            <img class="fit-size border-image cursor-pointer"
                                [src]="inliverPlus.avatar ? (inliverPlus.avatar | fetchUrlImage) : defaultAvatar">
                        </div>
                        <div class="flex flex-column align-items-start">
                            <span class="ml-2 text-white">{{inliverPlus.nickName! | limitString:false:40}}</span>
                            <!-- <span [ngClass]="getColorRole(inliverPlus)">Admin</span> -->
                        </div>
                    </div>
                    <div *ngIf="accountOwner !== inliverPlus.id!">
                        <i class="pi pi-ellipsis-h cursor-pointer"
                            (click)="menu.toggle($event); checkIsFriend(inliverPlus.id!)"></i>
                        <p-menu #menu [popup]="true" [model]="getItemMenu(inliverPlus, 'INLIVERSPLUS')"
                            [appendTo]="'body'" styleClass="menu-comment"></p-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-club mt-5" *ngIf="listInliver.length > 0">
            <div class="title">
                <span class="text-white fs-20">{{'INLIVERS' | translate}} ({{listInliver!.length!}})</span>
            </div>
            <div class="list-inliverplus">

                <div class="flex align-items-center justify-content-between mb-3 mt-3"
                    *ngFor="let inliver of listInliver">
                    <div class="flex align-items-center cursor-pointer" (click)="openAccountInfo(inliver)">
                        <div class="zone-avt">
                            <img class="fit-size border-image cursor-pointer"
                                [src]="inliver.avatar ? (inliver.avatar | fetchUrlImage) : defaultAvatar">
                        </div>
                        <div class="flex flex-column align-items-start">
                            <span class="ml-2 text-white">{{inliver.nickName! | limitString:false:40}}</span>
                            <!-- <span [ngClass]="getColorRole(inliverPlus)">Admin</span> -->
                        </div>
                    </div>
                    <div *ngIf="accountOwner !== inliver.id!">
                        <i class="pi pi-ellipsis-h cursor-pointer"
                            (click)="menu.toggle($event); checkIsFriend(inliver.id!)"></i>
                        <p-menu #menu [popup]="true" [model]="getItemMenu(inliver, 'INLIVERS')" [appendTo]="'body'"
                            styleClass="menu-comment"></p-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<ng-container *ngIf="openPopupBanInliver">
    <app-list-inliver-reason-banned [account]="currentAccount" [clubId]="club!.id!" [(showDialog)]="openPopupBanInliver"
        (getNewListMember)="getNewListMember()"></app-list-inliver-reason-banned>
</ng-container>

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'INLIVER'" [nameObject]="this.currentAccount"
        (refreshWhenReportSuccess)="refreshWhenReportSuccess()"></app-report>
</ng-container>