<p-dialog [(visible)]="openListMyFriend" [resizable]="false" [closable]="true" [draggable]="false" [modal]="true"
    (onHide)="onHide()">
    <div class="zone">
        <div class="zone-header">
            <div class="title">
                {{'NEW_MESSAGE' | translate}}
            </div>
            <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%; position: relative;">
                <span class="text-white icon-search">
                    {{'HAS' | translate}}
                </span>
                <div class="flex flex-wrap align-items-center text-white ml-3" *ngIf="selectedFriends.length! > 0">
                    <div class="selected" *ngFor="let data of selectedFriends">
                        <span class="fs-14 mr-1">
                            {{data.nickName! | limitString:false:20}}
                        </span>
                        <i class="pi pi-times cursor-pointer ml-1 fs-14" (click)="removeSelected(data)"></i>
                    </div>
                </div>
                <input style="width:100%" type="text" pInputText [(ngModel)]="searchText"
                    (ngModelChange)="getListFriend()" placeholder="{{'RESEARCH' | translate}}" />
            </div>
        </div>
        <div class="zone-clubs">
            <div class="list-club-joined" *ngIf="listFriends!.length! > 0 && selectedFriends.length! == 0">
                <div class="flex align-items-start justify-content-between mt-3 mb-3"
                    *ngFor="let friend of listFriends">
                    <div class="flex align-items-center cursor-pointer">
                        <div class="zone-avt">
                            <img class="fit-size border-image" [src]="friend.avatar ?? defaultAvatar">
                        </div>
                        <div class="flex flex-column ml-3">
                            <span class="text-white">{{friend.nickName! | limitString:false:40}}</span>
                            <span>
                                {{friend.totalCommondClub}} {{'CLUB_COMMON_BETWEEN_USER' | translate}}
                            </span>
                        </div>
                    </div>
                    <p-checkbox class="checkbox-asset checkbox-message" name="groupname" [value]="friend"
                        [(ngModel)]="selectedFriends"></p-checkbox>
                </div>
            </div>
            <div class="text-disabled" *ngIf="listFriends?.length! == 0">
                {{'NO_INLIVERS_SELECTED' | translate}}
            </div>
        </div>

        <div class="flex pt-3 pb-3 justify-content-center zone-button">
            <button pButton label="{{'CONVERSE' | translate}}" (click)="valider()"
                class="p-button p-button-outlined text-white bg-blue label">
            </button>
        </div>
    </div>
</p-dialog>