import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { of } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Club, StageWithCheckExistAlbumModel, StageStringInputModel } from 'src/app/core/api/be/models';
import { ClubService, StageService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-list-stage',
  templateUrl: './list-stage.component.html',
  styleUrls: ['./list-stage.component.scss']
})
export class ListStageComponent implements OnInit {

  @Input() listStage!: StageWithCheckExistAlbumModel[];
  @Input() showDialog: boolean = false;

  @Output() showDialogChange = new EventEmitter();

  currentStage!: StageWithCheckExistAlbumModel;

  isPrivate!: boolean
  isEditting!: boolean;
  isAcceptViewPrivate!: boolean;
  isExisited: boolean = false;

  tempName: string = ''

  constructor(
    private _stageService: StageService
  ) { }

  ngOnInit(): void {
    console.log('listStage:', this.listStage);
    //this.currentStage = this.listStage[1];
  }

  onHide() {
    this.showDialogChange.emit(this.showDialog);
  }

  deleteStage(stage: StageWithCheckExistAlbumModel) {

    let body = {
      adminId: AuthService.GetAccountInfo()?.id!,
      clubId: stage.clubId!,
      stageId: stage.id!,
    }

    LoadingService.$(true);

    this._stageService.inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json(
      {
        adminId: AuthService.GetAccountInfo()?.id!,
        clubId: stage.clubId!,
        stageId: stage.id!,
        body
      })
      .subscribe(res => {
        LoadingService.$(false);
        location.reload();
      })
  }

  collapseZoneStage(stage: StageWithCheckExistAlbumModel) {
    this.currentStage = stage;
    this.isPrivate = stage.isPrivate!
    this.isEditting = true;
    this.isAcceptViewPrivate = this.currentStage?.isAcceptViewPrivate!;
    this.tempName = '';
    this.isExisited = false;
  }

  editStage(stage: StageWithCheckExistAlbumModel) {
    if (this.isExisited || _.toArray(this.tempName?.trim()).length! > 20) {
      return;
    } else {
      let body: StageStringInputModel = {
        adminId: AuthService.GetAccountInfo().id!,
        clubId: stage.clubId!,
        stageId: stage.id!,
        value: this.tempName ? this.tempName : stage.name
      }
      this._stageService.inlivewithStageNameAdminIdClubIdStageIdPut$Json({
        adminId: AuthService.GetAccountInfo().id!,
        clubId: stage.clubId!,
        stageId: stage.id!,
        body: body
      }).pipe(
        mergeMap(res => this.editStatusStage(res.data!)),
        tap(res => {
          if (res?.res == 0) {

          }
        }),
        finalize(() => {
          window.location.reload();
          this.onHide();
        })
      ).subscribe()
    }
  }

  editStatusStage(data: StageWithCheckExistAlbumModel) {
    let body = {
      adminId: AuthService.GetAccountInfo().id!,
      clubId: data.clubId!,
      stageId: data.id!
    }

    // if(this.isPrivate === data.isPrivate) return of(null);

    if (this.isPrivate) {
      return this._stageService.inlivewithStagePrivateAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json({
        adminId: AuthService.GetAccountInfo().id!,
        clubId: data.clubId!,
        stageId: data.id!,
        isAcceptViewPrivate: this.isAcceptViewPrivate,
        body: body
      })
    } else {
      return this._stageService.inlivewithStagePublicAdminIdClubIdStageIdIsAcceptViewPrivatePut$Json({
        adminId: AuthService.GetAccountInfo().id!,
        clubId: data.clubId!,
        stageId: data.id!,
        isAcceptViewPrivate: this.isAcceptViewPrivate,
        body: body
      })
    }
  }

  checkExistName() {
    if (this.listStage.find(x => x.name?.trim() === this.tempName.trim())) {
      this.isExisited = true;
      return;
    }
    return this.isExisited = false;
  }

  getCharacterLeft() {
    let numberLeft: number;
    let isFullChar: boolean = false;
    numberLeft = 20 - _.toArray(this.tempName?.trimStart().trimEnd())?.length! > 0 ? 20 - _.toArray(this.tempName?.trimStart().trimEnd()).length! : 0
    isFullChar = this.tempName?.length! > 20 ? true : false;
    return { numberLeft, isFullChar };
  }

}
