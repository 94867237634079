import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { mergeMap, tap } from 'rxjs/operators';
import { AlbumAsset, CommentPost, CommentPostResponseModel, MediaCommentPost, MediaPostResponseModel, PostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { base64ToBlob, defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-mobile-comment-post',
  templateUrl: './mobile-comment-post.component.html',
  styleUrls: ['./mobile-comment-post.component.scss']
})
export class MobileCommentPostComponent implements OnInit {

  // @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;

  // private _post: any;
  // @Input() set post(value: any) {
  //   console.log(value);

  //   if (value) {
  //     value.commentPosts.forEach(async (m: any) => {
  //       const url = m.url;
  //       console.log(url);

  //       var res = await fetch(url);
  //       var blob = await res.blob();
  //       m['newUrl'] = this.sanitizer.sanitize(
  //         SecurityContext.URL,
  //         this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
  //       );
  //     });
  //     this._post = value;
  //   }
  // }
  // get post() {
  //   return this._post;
  // }

  @Input() showCommentZone: boolean = false;
  @Input() post!: PostResponseModel;
  @Input() isAdmin!: boolean;

  @Output() showCommentZoneChange = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<boolean>();

  comments: CommentPostResponseModel[] = [];

  defaultAvatar = defaultAvatar;

  isHaveParent!: boolean;
  openPopupRecordAudio: boolean = false;
  isOverflow!: boolean;
  isAcceptToViewMenu!: boolean;
  isShowPopupConfirm!: boolean;
  isOwner!: boolean;
  openPopupReport!: boolean;
  isEditComment: boolean = false;

  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  currentReplyComment!: CommentPostResponseModel;
  currentAsset!: AlbumAsset;
  content: string = '';
  contentReply: string = '';
  urlAudio!: string;
  format!: string;

  blobFileAudio!: Blob;
  urls: any[] = [];
  filePosts: any[] = [];

  urlAudioChild!: string;
  blobFileAudioChild!: Blob;
  urlsChild: any[] = [];
  filePostsChild: any[] = [];

  refetchWhenReceiveNoti$ = this._notificationService.isReloadComment$$.pipe(
    tap(() => {
      this.getPostById();
      this.getCommentByPostId();
    })
  );

  constructor(
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    private _router: Router,
    private _translateService: TranslateService,
    private _notificationService: NotificationServicee,
    private sanitizer: DomSanitizer
  ) { }

  onHide() {
    this.refresh.emit();
    this.showCommentZoneChange.emit(false);
  }

  ngOnInit(): void {
    this.refetchWhenReceiveNoti$.subscribe();
    this.getCommentByPostId();
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  getItem(comment: CommentPostResponseModel) {
    let items: MenuItem[] = [];
    this.isAcceptToViewMenu =
      comment?.authorId! === AuthService.GetAccountInfo()?.id!;
    items =
      this.isAdmin && !this.isAcceptToViewMenu
        ? [
          {
            icon: 'pi pi-users',
            label: this._translateService.instant('PROFILE_SEE'),
            command: (event: any) => {
              this.openAccountInfo(comment?.authorId!);
            },
          },
          {
            icon: 'pi pi-ban',
            label: this._translateService.instant('CLUB_BAN_USER'),
            command: () => { },
          },
          {
            icon: 'pi pi-trash',
            label: this._translateService.instant('COMMENT_DELETE'),
            command: () => {
              this.currentReplyComment = comment
              this.showPopupConfirm()
            }
          },
        ]
        : this.isAcceptToViewMenu
          ? [
            {
              icon: 'pi pi-pencil',
              label: this._translateService.instant('MODIFIER'),
              command: () => {
                this.isEditComment = true;
                this.currentReplyComment = comment;
                this.content = comment.message!;
              },
            },
            {
              icon: 'pi pi-trash',
              disabled: this.isEditComment,
              label: this._translateService.instant('DELETE'),
              command: () => {
                this.currentReplyComment = comment
                this.showPopupConfirm()
              }
            },
          ]
          : [
            {
              icon: 'pi pi-users',
              label: this._translateService.instant('PROFILE_SEE'),
              command: () => {
                this.openAccountInfo(comment?.authorId!);
              },
            },
            {
              icon: 'pi pi-flag',
              disabled: comment.isReported,
              label: this._translateService.instant('COMMENT_REPORT'),
              command: () => {
                this.openPopupReport = true;
                this.currentReplyComment = comment
              },
            },
          ];

    return items;
  }

  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();
  };

  focusText(item: CommentPostResponseModel) {
    this.isHaveParent = true;
    this.currentReplyComment = item;
    this.urlsChild = [];
    this.filePostsChild = [];
    this.urlAudioChild = '';
  }

  // updatePost() {
  //   return this._postService.inlivewithPostGetByIdPostIdAccountRequestIdGet$Json(
  //     {
  //       postId: this.post.id!,
  //       accountRequestId: AuthService.GetAccountInfo().id!,
  //     }
  //   );
  // }

  startRecord() {
    this.urlAudio = '';
    this.openPopupRecordAudio = true;
  }

  createComment() {
    LoadingService.$(true);
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild.push(this.blobFileAudioChild);
    }

    LoadingService.$(true);

    // if (this.isHaveParent) {
    //   this._postService
    //     .inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json({
    //       commentId: this.currentReplyComment.id!,
    //       body: {
    //         AuthorId: AuthService.GetAccountInfo().id!,
    //         ParentId: this.currentReplyComment.id!,
    //         Message: this.contentReply,
    //         PostId: this.post.id!,
    //         MediaCommentFiles: this.filePostsChild,
    //       },
    //     })
    //     .pipe(
    //       tap((res) => { }),
    //       mergeMap((res) => this.updatePost()),
    //       tap((res) => {
    //         if (res.res == 0) {
    //           this.post = res.data!;
    //           (this.contentReply = ''), (this.isHaveParent = false);
    //           this.filePostsChild = [];
    //           this.urlsChild = [];
    //           this.urlAudioChild = '';
    //           this.currentReplyComment = {};
    //         }
    //       })
    //     )
    //     .subscribe(() => {
    //       this.getCommentByPostId();
    //       LoadingService.$(false);
    //       this.refresh.emit();
    //     });
    // } else {
    //   if (this.isEditComment) {
    //     this._postService.inlivewithPostCommentEditContentCommentOnPostPut$Json({
    //       body: {
    //         commentId: this.currentReplyComment.id!,
    //         message: this.content
    //       }
    //     }).pipe(
    //       tap((res) => { }),
    //       mergeMap(() => this.addMediaOnComment(this.currentReplyComment)),
    //       mergeMap((res) => this.updatePost()),
    //       tap((res) => {
    //         if (res.res == 0) {
    //           this.post = res.data!;
    //           (this.content = ''), (this.isHaveParent = false);
    //           this.urls = [];
    //           this.filePosts = [];
    //           this.urlAudio = '';
    //           this.currentReplyComment = {};
    //           this.isEditComment = false;
    //         }
    //       })
    //     ).subscribe(() => {
    //       this.getCommentByPostId();
    //       LoadingService.$(false);
    //       this.refresh.emit();
    //     });
    //   } else {
    //     this._postService
    //       .inlivewithPostCommentCreateCommentPostPost$Json({
    //         body: {
    //           AuthorId: AuthService.GetAccountInfo().id!,
    //           Message: this.content,
    //           PostId: this.post.id!,
    //           MediaCommentFiles: this.filePosts,
    //         },
    //       })
    //       .pipe(
    //         tap((res) => { }),
    //         mergeMap((res) => this.updatePost()),
    //         tap((res) => {
    //           if (res.res == 0) {
    //             this.post = res.data!;
    //             (this.content = ''), (this.isHaveParent = false);
    //             this.urls = [];
    //             this.filePosts = [];
    //             this.urlAudio = '';
    //             this.currentReplyComment = {};
    //           }
    //         })
    //       )
    //       .subscribe(() => {
    //         this.getCommentByPostId();
    //         LoadingService.$(false);
    //         this.refresh.emit();
    //       });
    //   }
    // }
  }

  detectFiles(event: any) {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event: any) => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);

          if (this.isHaveParent) {
            this.filePostsChild.push(file);
            this.urlsChild.push({
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
            });
          } else {
            this.filePosts.push(file);
            this.urls.push({
              format: file.type.split('/')[0],
              data: this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blob!)
              ),
              description: '',
              file: file,
            });
          }
        };
      }
    }
  }

  removeAsset(index: number) {
    this.urls.splice(index, 1);
    this.filePosts.splice(index, 1);
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio = new Blob();
  }

  removeAssetChild(index: number) {
    this.urlsChild.splice(index, 1);
    this.filePostsChild.splice(index, 1);
  }

  removeAudioChild() {
    this.urlAudioChild = '';
    this.blobFileAudioChild;
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  sanitizeChild() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudioChild);
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  showPopupConfirm() {
    this.isShowPopupConfirm = true;
  }

  deleteComment() {
    LoadingService.$(true);
    // this._postService
    //   .inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(
    //     {
    //       commentPostId: this.currentReplyComment.id!,
    //       accountRequestDeleteId: AuthService.GetAccountInfo().id!,
    //     }
    //   )
    //   .pipe(
    //     tap((res) => { }),
    //     mergeMap((res) => this.updatePost()),
    //     tap((res) => {
    //       if (res.res == 0) {
    //         this.post = res?.data!;
    //         this.currentReplyComment = {};
    //         this.isShowPopupConfirm = false;
    //         LoadingService.$(false);
    //       }
    //     })
    //   )
    //   .subscribe(() => {
    //     this.getCommentByPostId();
    //     LoadingService.$(false);
    //     this.refresh.emit();
    //   });
  }

  openAccountInfo(item: any) {
    if (AuthService.GetAccountInfo().id! !== item) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: item },
      });
    }
  }

  getPostById() {
    let body = {
      postId: this.post.id!,
      accountRequestId: AuthService.GetAccountInfo().id!,
    };
    this._postService
      .inlivewithPostGetByIdPostIdGet$Json(body)
      .subscribe((res) => {
        this.post = res.data!;
      });
  }

  getCommentByPostId() {
    // this._postService
    //   .inlivewithPostGetCommentsOnPostPostIdGet$Json({
    //     postId: this.post.id!
    //   })
    //   .subscribe((res) => {
    //     this.comments = res?.data?.page!;
    //     this.comments.forEach(c => {
    //       this.getItem(c)
    //     })
    //   });
  }

  getCommentByCommentId(media: MediaCommentPost) {
    return this._postService
      .inlivewithPostGetCommentsPostByIdPostIdCommentIdGet$Json({
        postId: this.post.id!,
        commentId: media.id!,
      })
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.');
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  deleteFromComment(media: MediaCommentPost) {
    this._postService.inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json({
      body: {
        commentPostId: media.commentPostId,
        postId: this.post.id!,
        mediaOnCommentPostFileUrls: [media.id!]
      }
    }).pipe(
      mergeMap((res) => this.getCommentByCommentId(this.currentReplyComment)),
      tap((res) => {
        if (res.res == 0) {
          this.currentReplyComment = res.data!;
        }
      })
    ).subscribe();
  }

  addMediaOnComment(comment: MediaCommentPost){
    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    if (this.urlAudioChild && this.blobFileAudioChild) {
      this.filePostsChild.push(this.blobFileAudioChild);
    }
    
    return this._postService.inlivewithPostCommentAddMediaCommentOnPostPut$Json({
      body: {
        CommentId: comment.id!,
        MediaCommentFiles: this.filePosts
      }
    })
  }

  // onPlayButtonClick() {
  //   this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
  //   this.isPlayingAudio = !this.isPlayingAudio;
  // }

  // onTrackLoaded(event: any) { }

  // onTrackRendered(event: any) { }

  // onTimeUpdate(event: any) {
  //   this.timeOffAudio = event.time;
  // }

  // onDurationChange(event: any) {
  //   this.timeOffAudio = event;
  // }

  // onRegionChange(event: any) { }

  // onPaused() { }

}
