<div class="flex flex-column">
    <div (click)="backBtn()">
        <i class="pi pi-arrow-left mt-4 mb-3 cursor-pointer text-white"></i>
    </div>
    <div class="description">
        <div class="flex flex-column">
            <span class="fs-30">
                {{ 'CONTENT_NOT_FOUND_TITLE' | translate }}
            </span>
            <span class="mt-2 fs-16">
                {{ 'CONTENT_NOT_FOUND_TEXT' | translate }}
            </span>
        </div>
    </div>
</div>