import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-noti-request-access-level',
  templateUrl: './noti-request-access-level.component.html',
  styleUrls: ['./noti-request-access-level.component.scss'],
})
export class NotiRequestAccessLevelComponent implements OnInit {
  @Input() notification!: NotificationResponse;
  @Output() refreshPage = new EventEmitter();

  defaultAvatar = defaultAvatar;

  constructor(private _clubService: ClubService) {}

  ngOnInit(): void {}

  doAction(type: boolean) {
    LoadingService.$(true);

    this._clubService
      .inlivewithClubAccountApprovalAccessLevelPut$Json({
        body: {
          accountId: this.notification.senderId,
          clubId: this.notification.clubId!,
          approval: type,
          accessLevel: type ? 'ACCESSLEVEL_PLUS' : 'ACCESSLEVEL_ANONYMOUS',
        },
      })
      .subscribe(() => {
        LoadingService.$(false);
        this.refreshPage.emit();
      });
  }
}
