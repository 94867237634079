<p-dialog [(visible)]="openPopup" [modal]="true" [draggable]="false" [modal]="true" (onHide)="hidePopup()">
    <div class="card">
        <div class="flex align-items-center justify-content-center text-white">
            <span class="label text-center fs-22">{{'PRESENT_ON_LIVE' | translate}}
                ({{listInliverJoined.length}})
            </span>
        </div>
        <div class="flex w-100 align-items-center search mt-3 mb-2" style="width:100%;position: relative;">
            <i class="pi pi-search icon-search"></i>
            <input [(ngModel)]="searchText" (ngModelChange)="searchInliver()" style="width:100%" type="text" pInputText
                placeholder="{{'INPUT_SEARCH_MEMBER' | translate}}" />
        </div>
        <div class="inliver-normal" *ngIf="listInliverJoined?.length! > 0">
            <div class="list-inliverplus flex flex-column" *ngIf="listInliverJoined.length > 0">
                <ng-container *ngIf="live! && live!.isFromCelebrity">
                    <div class="text-white fs-22">
                        {{'CELEBRITY' | translate}}
                    </div>
                    <!-- [ngStyle]="{display:inliver?.isCelebrity! ? 'flex !important': 'none !important'}" -->
                    <div class="flex align-items-center mt-1" *ngFor="let inliver of listInliverJoined">
                        <ng-container *ngIf="inliver!.isCelebrity">
                            <div class="zone-avt">
                                <img class="avatar" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                                <img class="inliver-plus" src="../../../../assets/images/svgs/celeb-tag.svg">
                            </div>
                            <div class="ml-2 label text-white">{{inliver.nickName! | limitString:false:40}}</div>
                        </ng-container>
                    </div>
                    <div style="border: solid 1px;margin-top: 2rem;"></div>
                </ng-container>


                <div class="flex align-items-center mt-2 mb-3" *ngFor="let inliver of listInliverJoined; let i = index"
                    [ngStyle]="{order: (!isAdmin && inliver.id == live!.author!.id!) ? 0 : i + 1}">
                    <ng-container *ngIf="!inliver!.isCelebrity">
                        <img class="avatar mr-1" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                        <div class="ml-2 label text-white">{{inliver.nickName! | limitString:false:40}}</div>

                        <label class="text-gray ml-1 fs-14" *ngIf="isAdmin && inliver.id == adminId">
                            ({{'ME' | translate}})
                        </label>

                        <label class="text-gray ml-1 fs-14" *ngIf="!isAdmin && inliver.id == live!.author!.id!">
                            ({{'CREATOR' | translate}})
                        </label>

                        <button pButton type="button" label="{{'RETIRE' | translate}}"
                            (click)="confirmKickUser(inliver)" *ngIf="isAdmin && inliver.id != adminId"
                            class="ml-auto p-button-outlined p-button-secondary mr-2">
                        </button>
                    </ng-container>

                    <ng-container *ngIf="inliver!.isCelebrity && !isCelebLive">
                        <img class="avatar mr-1" [src]="inliver.avatar ? inliver.avatar : defaultAvatar">
                        <div class="ml-2 label text-white">{{inliver.nickName! | limitString:false:40}}</div>

                        <label class="text-gray ml-1 fs-14">
                            ({{'CELEBRITY' | translate}})
                        </label>

                        <button pButton type="button" label="{{'RETIRE' | translate}}"
                            (click)="confirmKickUser(inliver)" *ngIf="isAdmin && inliver.id != adminId"
                            class="ml-auto p-button-outlined p-button-secondary mr-2">
                        </button>
                    </ng-container>

                    <p-dialog [(visible)]="showConfirmKickUser" [modal]="true" [draggable]="false" [modal]="true">
                        <div class="flex flex-column align-items-center justify-content-center">

                            {{'TITLE_CONFIRM_KICK_VIEWER_GOINLIVE' | translate}}

                            <div class="flex mt-2">

                                <button pButton type="button" label="{{'CANCEL' | translate}}"
                                    (click)="showConfirmKickUser = false" *ngIf="isAdmin"
                                    class="m-1 p-button-outlined p-button-secondary">
                                </button>

                                <button pButton type="button" label="{{'CONFIRM' | translate}}" (click)="kickViewer()"
                                    *ngIf="isAdmin" class="m-1 p-button-outlined p-button-secondary">
                                </button>
                            </div>
                        </div>
                    </p-dialog>

                </div>
            </div>
        </div>

    </div>
</p-dialog>