<ng-container *ngIf="asset">

    <div class="login-modal">
        <div class="content">
            <div class="list-comment">
                <div class="comments" *ngIf="commentAsset?.length! > 0">
                    <ng-container *ngFor="let item of commentAsset">
                        <div class="item">
                            <div class="avatar">
                                <img class="fit-size cursor-pointer"
                                    [src]="!item.isBannedByClub! ? (item?.author?.avatar! | fetchUrlImage) : defaultAvatar"
                                    alt="" (click)="openAccountInfo(item.authorId!, item.isBannedByClub!)">
                            </div>
                            <div class="detail">
                                <div class="name" (click)="openAccountInfo(item?.author?.id!, item.isBannedByClub!)">
                                    {{!item.isBannedByClub! ? (item?.author?.nickName! | limitString:false:40) : ('INLIVER_UNKNOWN' |
                                    translate)}}
                                </div>
                                <div class="time">
                                    <span>{{timeAgo(item?.dateCreated!)}}</span>
                                </div>
                                <div class="content" *ngIf="!item.isBannedByClub" [innerHTML]="urlify(item!.message!)"></div>
                                <div class="content" *ngIf="item.isBannedByClub" [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                <div style="position: relative" *ngIf="!item.isBannedByClub">
                                    <div class="flex" *ngIf="item?.mediaCommentAlbumAssets!.length! > 0" [id]="item.id">
                                        <div class="mr-1 ml-1 list-media" [ngClass]="{'w-100': checkTypeMedia(asset) == 'AUDIO'}"
                                            *ngFor="let asset of item?.mediaCommentAlbumAssets!; let i = index">
                                            <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                <div class="w-100 h-100 flex justify-content-center align-items-center"
                                                    *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                                </div>
                                                <div class="h-100 cursor-pointer"
                                                    *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                    <img class="fit-size" [src]="getUrl(asset)"
                                                        (click)="showMultiMedia(item?.mediaCommentAlbumAssets!, i)">
                                                </div>
                                                <div class="h-100 flex cursor-pointer"
                                                    *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                    <!-- <video class="fit-size" [src]="asset?.url!" [poster]="getUrl(asset)"
                                                        (click)="showMultiMedia(item?.mediaCommentAlbumAssets!, i)"></video> -->
                                                    <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                        [url]="asset?.url!" [poster]="getUrl(asset)"
                                                        (dblclick)="showMultiMedia(item?.mediaCommentAlbumAssets!, i)">
                                                    </app-video-layer>
                                                </div>
                                                <div class="w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                    <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                                    [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                                <div class="action">
                                    <div class="flex" *ngIf="!item.isBannedByClub">
                                        <img src="../../../assets/images/svgs/reply.svg" alt="">
                                        <span (click)="focusText(item)">{{'RESPONSE' | translate}}</span>
                                    </div>
                                    <div class="flex flex-column mt-5 input-text" id="replyComment"
                                        *ngIf="isHaveParent && currentReplyComment.id === item.id">
                                        <div class="flex flex-wrap"
                                            *ngIf="(urlsChild.length > 0 || urlAudioChild) && !isEditComment"
                                            [ngStyle]="urlsChild.length > 0 || urlAudioChild ? {'padding':'1.5rem'} : {}">
                                            <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                                                *ngFor="let url of sortFiles(urlsChild); let i = index">
                                                <img [src]="url.data" *ngIf="url.format === 'image' && url">
                                                <ng-container
                                                    *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                                                    <ng-container *ngIf="isChrome">
                                                        <video [id]="url.file.name" [src]="url.data"
                                                            *ngIf="url.format === 'video' && url" controls></video>
                                                    </ng-container>
                                                    <ng-container *ngIf="!isChrome">
                                                        <div
                                                            class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                                            <img class="icon-play"
                                                                src="../../../assets/images/svgs/play-button.png">
                                                            <div class="mt-2 file-name fs-12 cursor-pointer"
                                                                [pTooltip]="url.file.name">
                                                                {{url.file.name}}</div>
                                                            <video [id]="url.file.name" [src]="url.data"
                                                                style="display: none;"></video>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #playerVideo>
                                                    <video [id]="url.file.name" [src]="url.data"
                                                        *ngIf="url.format === 'video' && url" controls></video>
                                                </ng-template>

                                                <audio [id]="url.file.name" [src]="url.data"
                                                    *ngIf="url.format === 'audio' && url" controls></audio>

                                                <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                                                    *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                                    <div style="overflow: auto;text-overflow: ellipsis;">
                                                        {{url.file.name}}</div>
                                                </div>

                                                <img class="delete-asset" (click)="removeAssetChild(url.id)"
                                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                                            </div>
                                        </div>
                                        <div class="text-error flex flex-column align-items-start"
                                            style="padding: 0px 2.5rem; font-size: 14px;"
                                            *ngIf="isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                                            {{'LIST_ITEM_REASON' | translate}}
                                            <div class="text-error" *ngIf="showErrorMixedFiles">
                                                {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorDuration">
                                                {{'DURATION_MAX_4MINS' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorMaximumFiles">
                                                {{'MAXIMUM_4_MEDIA' | translate}}
                                            </div>

                                            <div class="text-error" *ngIf="showErrorLimitSizeFile">
                                                {{'FILE_SIZE_LIMIT_POST' | translate}}
                                            </div>
                                        </div>
                                        <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="isHaveParent">
                                            <span class="text-error" *ngIf="!isValidToAddAssets">
                                                {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                                            </span>
                                            <span class="text-error" *ngIf="!isValidToAddAudio">
                                                {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                                            </span>
                                            <span class="text-error" *ngIf="showEditRule">
                                                {{'RULE_EDIT_POST' | translate}}
                                            </span>
                                        </div>
                                        <div class="flex" *ngIf="currentReplyComment">

                                            <ng-container *ngIf="openPopupRecordAudioChild">
                                                <app-record-audio-v2 [(openRecording)]="openPopupRecordAudioChild"
                                                    [width]="'600px'" [widthAudio]="'550px'" [(url)]="urlAudioChild"
                                                    [(blobFile)]="blobFileAudioChild"
                                                    (sendAudio)="createCommentReply()"></app-record-audio-v2>
                                            </ng-container>

                                            <textarea class="textarea-cus w-100" style="resize: none;"
                                                *ngIf="!openPopupRecordAudioChild" [(ngModel)]="contentReply"
                                                [mentionConfig]="mentionConfig"
                                                [mentionListTemplate]="mentionListTemplate"
                                                [placeholder]="('COMMENT_REPLY_PLACEHOLDER' | translate) + '@' + (currentReplyComment.author?.nickName! | limitString:false:40)"
                                                autofocus></textarea>
                                            <div class="flex justify-content-end pt-4"
                                                *ngIf="!openPopupRecordAudioChild">
                                                <div class="cursor-pointer flex align-items-center pt-4">
                                                    <img class="mr-3" src="../../../assets/images/svgs/mic.svg"
                                                        (click)="startRecord(true)"
                                                        *ngIf="(!urlsChild.length && !isEditComment && contentReply?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                                                    <i class="pi pi pi-image mr-3"
                                                        *ngIf="(!urlAudioChild && (!isEditComment)) || (isEditComment && (typeOfComment != 'TEXT'))"
                                                        (click)="inputFile.click()"></i>
                                                    <i class="pi pi-arrow-circle-right mr-3"
                                                        (click)="createCommentReply()"
                                                        *ngIf="contentReply.length > 0 || (urlsChild.length > 0) || (urlAudioChild && blobFileAudioChild) || (isEditComment)"></i>
                                                </div>
                                            </div>

                                            <ng-template #mentionListTemplate let-item="item">
                                                <div class="flex align-items-center">
                                                    <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt=""
                                                        width="40" height="40" style="border-radius: 5px;">
                                                    <span class="p-1">{{item.name! | limitString:false:20}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                                        (change)="detectFiles($event); isHaveParent = true;">
                                </div>
                            </div>
                            <div class="action-menu" *ngIf="!item.isBannedByClub">
                                <div>
                                    <i class="pi pi-ellipsis-h"
                                        (click)="menu.toggle($event); isHaveParent = false;"></i>
                                    <p-menu #menu [popup]="true" [model]="getItem(item)" [appendTo]="'body'"
                                        styleClass="menu-comment"></p-menu>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item && item?.inverseParent?.length! > 0">
                            <div *ngFor="let itemChild of item?.inverseParent">
                                <div class="item-child">
                                    <div class="avatar">
                                        <img class="fit-size cursor-pointer"
                                            [src]="!itemChild.isBannedByClub! ? (itemChild?.author?.avatar! | fetchUrlImage) : defaultAvatar"
                                            alt="" (click)="openAccountInfo(itemChild, itemChild.isBannedByClub!)">
                                    </div>
                                    <div class="detail">
                                        <div class="name">
                                            {{!itemChild.isBannedByClub! ? (itemChild?.author?.nickName! | limitString:false:40) :
                                            ('INLIVER_UNKNOWN' | translate)}}
                                        </div>
                                        <div class="time">
                                            <span>{{timeAgo(itemChild?.dateCreated!)}}</span>
                                        </div>
                                        <div class="content" *ngIf="!itemChild.isBannedByClub" [innerHTML]="urlify(itemChild!.message!)"></div>
                                        <div class="content" *ngIf="itemChild.isBannedByClub" [innerHTML]="'THE_CONTENT_IS_NOT_AVAILABLE' | translate"></div>
                                        <div class="mt-2 mb-4" style="position: relative">
                                            <div class="flex" *ngIf="itemChild?.mediaCommentAlbumAssets!.length! > 0 && !itemChild.isBannedByClub!"
                                                [id]="itemChild.id">
                                                <div class="mr-1 ml-1 list-media"
                                                    *ngFor="let asset of itemChild?.mediaCommentAlbumAssets!; let i = index">
                                                    <ng-container *ngIf="!checkProcessMediaFail(asset)">
                                                        <div class="w-100 h-100 flex justify-content-center align-items-center"
                                                            *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                                                            <i class="pi pi-spin pi-spinner"
                                                                style="font-size: 2rem"></i>
                                                        </div>

                                                        <div class="w-100 h-100 cursor-pointer"
                                                            *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                                                            <img class="fit-size" [src]="getUrl(asset)"
                                                                (click)="showMultiMedia(itemChild?.mediaCommentAlbumAssets!, i)">
                                                        </div>

                                                        <div class="w-100 h-100 flex cursor-pointer"
                                                            *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                                            <!-- <video class="fit-size" [src]="asset?.url!"
                                                                [poster]="getUrl(asset)"
                                                                (click)="showMultiMedia(itemChild?.mediaCommentAlbumAssets!, i)"></video> -->
                                                            <app-video-layer *ngIf="asset?.url!" [layout]="'full'"
                                                                [url]="asset?.url!" [poster]="getUrl(asset)"
                                                                (dblclick)="showMultiMedia(itemChild?.mediaCommentAlbumAssets!, i)">
                                                            </app-video-layer>
                                                        </div>

                                                        <div class="w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                                            <app-wave-form-audio
                                                                [assetUrl]="asset?.url!"></app-wave-form-audio>
                                                        </div>

                                                    </ng-container>


                                                    <ng-container *ngIf="checkProcessMediaFail(asset)">
                                                        <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                                            [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-menu" *ngIf="!itemChild.isBannedByClub">
                                        <div>
                                            <i class="pi pi-ellipsis-h"
                                                (click)="menu.toggle($event); isHaveParent = false;"></i>
                                            <p-menu #menu [popup]="true" [model]="getItem(itemChild)"
                                                [appendTo]="'body'" styleClass="menu-comment"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="flex flex-column mt-5 input-text">
                    <div class="flex flex-wrap" *ngIf="(urls.length > 0 || urlAudio) && !isEditComment"
                        [ngStyle]="urls.length > 0 || urlAudio ? {'padding':'1.5rem'} : {}">
                        <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                            *ngFor="let url of sortFiles(urls); let i = index">
                            <img [src]="url.data" *ngIf="url.format === 'image' && url">
                            <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                                <ng-container *ngIf="isChrome">
                                    <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                        controls></video>
                                </ng-container>
                                <ng-container *ngIf="!isChrome">
                                    <div
                                        class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                        <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                        <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                            {{url.file.name}}</div>
                                        <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #playerVideo>
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-template>

                            <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                                controls></audio>

                            <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                                *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}</div>
                            </div>

                            <img class="delete-asset" (click)="removeAsset(url.id)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>

                    <div class="flex flex-wrap"
                        *ngIf="isEditComment && currentReplyComment?.mediaCommentAlbumAssets?.length! > 0"
                        [ngStyle]="isEditComment && currentReplyComment!.mediaCommentAlbumAssets!.length! > 0 ? {'padding':'1.5rem'} : {}">
                        <div class="position-relative"
                            *ngFor="let media of filesDelete!; let i = index">
                            <div class="m-3" *ngIf="(checkTypeMedia(media) == 'IMAGE')"
                                style="position: relative;  width: fit-content;" [id]="media.id!">
                                <img class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'IMAGE'" height="75">
                                <img class="delete-asset" (click)="actionDelete(media)"
                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                            </div>

                            <div class="m-3" *ngIf="checkTypeMedia(media) == 'VIDEO'"
                                style="position: relative; width: fit-content;" [id]="media.id!">
                                <video class="m-3" [src]="media.url" *ngIf="checkTypeMedia(media) == 'VIDEO'"
                                    height="150" controls></video>
                                <img class="delete-asset" (click)="actionDelete(media)"
                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                            </div>

                            <div class="m-3" *ngIf="checkTypeMedia(media) == 'AUDIO'"
                                style="position: relative; width: fit-content;" [id]="media.id!">
                                <audio style="height: 5rem;" [src]="media?.url!" controls></audio>
                                <img class="delete-asset" (click)="actionDelete(media)"
                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                            </div>


                            <div style="position: relative;" class="m-3" *ngIf="checkProcessMediaFail(media)">
                                <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
                                    style="height: 100px !important;" [innerHTML]="'UNKNOWING_FILE' | translate">
                                </div>
                                <img class="delete-asset-audio" (click)="actionDelete(media)"
                                    src="../../../assets/images/svgs/trash-bg-white.svg">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap" *ngIf="(urls.length > 0) && isEditComment"
                        [ngStyle]="urls.length > 0 ? {'padding':'1.5rem'} : {}">
                        <div class="zone-media-cus flex position-relative" style="overflow: auto;"
                            *ngFor="let url of sortFiles(urls); let i = index">
                            <img [src]="url.data" *ngIf="url.format === 'image' && url">
                            <ng-container *ngIf="url.originalTypeFile.includes('quicktime'); else playerVideo">
                                <ng-container *ngIf="isChrome">
                                    <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                        controls></video>
                                </ng-container>
                                <ng-container *ngIf="!isChrome">
                                    <div
                                        class="flex flex-column align-items-center justify-content-center h-100 text-white zone-file zone-video-quicktime">
                                        <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
                                        <div class="mt-2 file-name fs-12 cursor-pointer" [pTooltip]="url.file.name">
                                            {{url.file.name}}</div>
                                        <video [id]="url.file.name" [src]="url.data" style="display: none;"></video>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #playerVideo>
                                <video [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'video' && url"
                                    controls></video>
                            </ng-template>

                            <audio [id]="url.file.name" [src]="url.data" *ngIf="url.format === 'audio' && url"
                                controls></audio>

                            <div class="flex align-items-center h-100 text-white zone-file cursor-pointer"
                                *ngIf="url.format !== 'image' && url.format !== 'audio' && url.format !== 'video'">
                                <div style="overflow: auto;text-overflow: ellipsis;"> {{url.file.name}}
                                </div>
                            </div>

                            <img class="delete-asset" (click)="removeAsset(url.id)"
                                src="../../../assets/images/svgs/trash-bg-white.svg">
                        </div>
                    </div>

                    <div class="text-error flex flex-column align-items-start"
                        style="padding: 0px 2.5rem; font-size: 14px;"
                        *ngIf="!isHaveParent && (showErrorMixedFiles || showErrorDuration || showErrorMaximumFiles || showErrorLimitSizeFile)">
                        {{'LIST_ITEM_REASON' | translate}}
                        <div class="text-error" *ngIf="showErrorMixedFiles">
                            {{'CANNOT_MIXED_THOSE_FILE' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorDuration">
                            {{'DURATION_MAX_4MINS' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorMaximumFiles">
                            {{'MAXIMUM_4_MEDIA' | translate}}
                        </div>

                        <div class="text-error" *ngIf="showErrorLimitSizeFile">
                            {{'FILE_SIZE_LIMIT_POST' | translate}}
                        </div>
                    </div>
                    <div style="padding: 0px 2.5rem; font-size: 14px;" *ngIf="!isHaveParent">
                        <span class="text-error" *ngIf="!isValidToAddAssets">
                            {{'NO_IMAGE_WHEN_HAS_AUDIO' | translate}}
                        </span>
                        <span class="text-error" *ngIf="!isValidToAddAudio">
                            {{'NO_AUDIO_WHEN_HAS_IMAGE' | translate}}
                        </span>
                        <span class="text-error" *ngIf="showEditRule">
                            {{'RULE_EDIT_POST' | translate}}
                        </span>
                    </div>

                    <div class="text-error fs-14" style="padding: 0px 2.5rem;"
                        *ngIf="(isEditComment && (typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'ONLY_MEDIAS' || (typeOfComment == 'UNKNOWN' && !isHasText)))">
                        {{'IMPOSSIBLE_TO_EDIT_TEXT' | translate}}
                    </div>

                    <div class="flex">
                        <ng-container *ngIf="openPopupRecordAudio">
                            <app-record-audio-v2 [(openRecording)]="openPopupRecordAudio" [width]="'600px'"
                                [widthAudio]="'550px'" [(url)]="urlAudio" [(blobFile)]="blobFileAudio"
                                (sendAudio)="createComment()"></app-record-audio-v2>
                        </ng-container>

                        <textarea class="textarea-cus w-100" style="resize: none;" *ngIf="!openPopupRecordAudio"
                            [mentionConfig]="mentionConfig" [mentionListTemplate]="mentionListTemplate"
                            [disabled]="(isEditComment && (typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'ONLY_MEDIAS' || (typeOfComment == 'UNKNOWN' && !isHasText)))"
                            [(ngModel)]="content" placeholder="{{'POST_PLACEHOLDER' | translate}}"></textarea>
                        <div class="flex justify-content-end pt-4" *ngIf="!openPopupRecordAudio">
                            <div class="cursor-pointer flex pt-4 align-items-center">
                                <img class="mr-3" src="../../../assets/images/svgs/mic.svg" (click)="startRecord(false)"
                                    *ngIf="(!urls.length && !isEditComment && content?.length! == 0) || (isEditComment && typeOfComment == 'ONLY_AUDIO')">
                                <i class="pi pi pi-image mr-3"
                                    *ngIf="(!urlAudio && (!isEditComment)) || (isEditComment && (typeOfComment == 'ONLY_MEDIAS' || typeOfComment == 'ONLY_AUDIO' || typeOfComment == 'TEXT_AND_MEDIAS' || typeOfComment == 'TEXT_AND_AUDIO' || typeOfComment == 'UNKNOWN'))"
                                    (click)="inputFile.click()"></i>
                                <i class="pi pi-arrow-circle-right mr-3" (click)="createComment()"
                                    *ngIf="content?.length! > 0 || (urls?.length! > 0) || (urlAudio! && blobFileAudio!) || (isEditComment)"></i>
                            </div>
                        </div>

                        <ng-template #mentionListTemplate let-item="item">
                            <div class="flex align-items-center">
                                <img class="mr-2 mt-1 mb-1" [src]="item.img | fetchUrlImage" alt="" width="40"
                                    height="40" style="border-radius: 5px;">
                                <span class="p-1">{{item.name! | limitString:false:20}}</span>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <input style="display: none" id="file-avatar" type="file" #inputFile multiple
                    (change)="detectFiles($event); isHaveParent = false;">
                <div id="zoneAudio"></div>

            </div>
        </div>
    </div>

</ng-container>

<!-- <ng-container *ngIf="openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudio" [(blobFile)]="blobFileAudio">
    </app-record-audio>
</ng-container>


<ng-container *ngIf="isHaveParent && currentReplyComment && openPopupRecordAudio">
    <app-record-audio [(showDialog)]="openPopupRecordAudio" [(url)]="urlAudioChild" [(blobFile)]="blobFileAudioChild">
    </app-record-audio>
</ng-container> -->

<ng-container *ngIf="openPopupReport">
    <app-report [(showDialog)]="openPopupReport" [type]="'COMMENT_ALBUM'" [nameObject]="this.currentReplyComment"
        (refreshWhenReportSuccess)="getCommentByAssetId()">
    </app-report>
</ng-container>

<ng-container *ngIf="isShowMultiMedia">
    <app-show-multi-media [(openShowMutilMedia)]="isShowMultiMedia" [(urls)]="multiMedias"
        [(activeIndex)]="activeIndex"></app-show-multi-media>
</ng-container>

<!-- <ng-container *ngIf="isShowPopupConfirm">
    <app-card-confirm [(isShowPopup)]="isShowPopupConfirm" [firstButtonTitle]="'CANCEL' | translate"
        [secondButtonTitle]="'REPORT' | translate" (doAction)="deleteComment()" [title]="'COMMENT_DELETE' | translate"
        [content]="'COMMENT_DELETE_CONFIRM' | translate:{value: currentReplyComment.author?.nickName!}">
    </app-card-confirm>
</ng-container> -->

<ng-container *ngIf="applyClub">
    <app-confirm-request-join-club [(isConfirmJoinClub)]="applyClub"></app-confirm-request-join-club>
</ng-container>