<p-dialog *ngIf="!isMobile" [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()" [closable]="true">
    <div class="card">
        <div class="flex flex-column align-items-center justify-content-center text-center">
            
            <div class="flex position-fixed">
                <div class="zone-avt" *ngIf="account">
                    <img [src]="account.avatar ? account.avatar : defaultAvatar" class="fit-size">
                </div>
            </div>

            <div class="fs-16 text-white flex flex-column text-center mt-3 mb-5">
                <span>
                    {{ (isFriend ? 'REMOVE_FRIEND_CONFIRM' : 'REQUEST_FRIEND_CONFIRM') | translate}}
                </span>
            </div>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-dialog>


<p-sidebar *ngIf="isMobile" [(visible)]="isShowPopup" [modal]="true" [draggable]="false" (onHide)="closePopup()" position="bottom" [showCloseIcon]="true">
    <div>
        <div class="flex flex-column align-items-center justify-content-center text-center">
            
            <div class="flex position-fixed">
                <div class="zone-avt" *ngIf="account">
                    <img [src]="account.avatar ? account.avatar : defaultAvatar" class="fit-size">
                </div>
            </div>

            <div class="fs-16 text-white flex flex-column text-center mt-3 mb-5">
                <span>
                    {{ (isFriend ? 'REMOVE_FRIEND_CONFIRM' : 'REQUEST_FRIEND_CONFIRM') | translate}}
                </span>
            </div>
            
        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle" class="p-button-red p-button-secondary text-white bg-transparent border-white ml-2"
             (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-sidebar>
