<p-sidebar [(visible)]="showSidebar" position="bottom" [showCloseIcon]="false" [dismissible]="false">
    <div class="flex justify-content-center flex-column align-items-center mt-5 text-white">
        <div class="w-80 text-center">
            {{'CLUB_ALBUM_DELETE_CONFIRM' | translate}}
        </div>

        <div class="flex mt-3">
            <button pButton label="{{'CANCEL' | translate}}" class="p-button-outlined p-button-secondary text-white m-3"
                (click)="cancel()">
            </button>
            <button pButton label="{{'YES' | translate}}" class="p-button-outlined p-button-secondary text-white m-3"
                (click)="deleteAlbum()">
            </button>
        </div>
    </div>
</p-sidebar>