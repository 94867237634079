import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-report-step-i',
  templateUrl: './report-step-i.component.html',
  styleUrls: ['./report-step-i.component.scss']
})
export class ReportStepIComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() cancel = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

}
