import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PostResponseModel } from 'src/app/core/api/be/models';
import { PostService, StageService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-create-post-when-endlive',
  templateUrl: './create-post-when-endlive.component.html',
  styleUrls: ['./create-post-when-endlive.component.scss'],
})
export class CreatePostWhenEndliveComponent implements OnInit {
  @Input() post!: PostResponseModel;

  @Input() openPopup!: boolean;
  @Output() openPopupChange = new EventEmitter();
  @Output() acceptCreatePost = new EventEmitter<any>();

  @Input() clubId!: string;
  STEP: number = 1;

  name!: string;
  description!: string;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  onHide() {}

  cancel() {
    this._router.navigate(['manage-club/detail-club'], {
      queryParams: { clubId: this.clubId! },
    });
  }

  updatePost() {
    if (!this.name) {
      return;
    }

    let contentPost = this.name + ' \n ' + this.description;
    this.acceptCreatePost.emit(contentPost);
  }
}
