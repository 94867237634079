<app-layout *ngIf="!_router.url.startsWith('/mobile') && _router.url != '/'">
  <router-outlet></router-outlet>
</app-layout>

<ng-container *ngIf="_router.url == '/'">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="_router.url.startsWith('/mobile')">
  <div class="flex h-100" style="overflow-x: hidden;" id="content-main">
    <div class="w-100 h-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>


<app-loading></app-loading>

<ng-container *ngIf="showLogin">
  <app-login [(showDialog)]="showLogin"></app-login>
</ng-container>

<ng-container *ngIf="showRegister">
  <app-register [(showDialogRegister)]="showRegister"></app-register>
</ng-container>

<ng-container *ngIf="currentAccount">
  <p-galleria [value]="images" [(visible)]="!currentAccount.isDoneTutorial!" [containerStyle]="{'width':'90%'}"
    [numVisible]="5" [circular]="true" [showItemNavigators]="true" [fullScreen]="true" [showThumbnails]="false"
    [showIndicators]="activeIndex == 4 ? false : true" [(activeIndex)]="activeIndex" [showItemNavigatorsOnHover]="true"
    [showIndicatorsOnItem]="true" (visibleChange)="close()">
    <ng-template pTemplate="item" let-item>
      <img [src]="item.poster" style="width: 100%; display: block;" />
      <button *ngIf="activeIndex == 4" pButton pRipple type="button" label="{{'LETS_GO' | translate}}" (click)="close()"
        class="p-button-outlined p-button-secondary fixed-button"></button>
    </ng-template>
  </p-galleria>
</ng-container>


<!-- <ng-container>
  <div class="flex small-banner">
    <div class="flex fs-11">
      Register or login to our app to get the best of our aplication
    </div>
    <div class="flex btn-login">
      {{'CONNECTION' | translate}}
    </div>
  </div>
</ng-container> -->


<!-- <p-dialog [(visible)]="registerSuccess" [modal]="true">
  <ng-container *ngIf="registerSuccess">
    <app-register-success [emailCheck]="email"></app-register-success>
  </ng-container>
</p-dialog> -->