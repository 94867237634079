import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { switchMap, tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel } from 'src/app/core/api/be/models';
import { ClubService, GoInliveScheduleService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-schedule-goinlive',
  templateUrl: './schedule-goinlive.component.html',
  styleUrls: ['./schedule-goinlive.component.scss']
})
export class ScheduleGoinliveComponent implements OnInit {

  @Input() showSchedule: boolean = false;
  @Input() clubId!: string;

  @Output() showScheduleChange = new EventEmitter<boolean>();

  goInliveName: string = ''
  dateCreated: any;
  hourCreated: any;
  openPopupAddInlivers: boolean = false;

  listInliverPlus: AccountResponseWithRoleInCLubModel[] = [];
  selectedInlivers: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar;

  constructor(
    private _clubService: ClubService,
    private _goInliveScheduleService: GoInliveScheduleService
  ) { }

  ngOnInit(): void {
    this.hourCreated = moment().format("HH:mm");
    this.dateCreated = new Date();
    this.getListInliverPlus().subscribe();
  }

  onHide() {
    this.showScheduleChange.emit(false);
  }

  getListInliverPlus() {
    return this._clubService.inlivewithClubGetAllMembersClubIdGet$Json({ clubId: this.clubId, limit: 1000 }).pipe(
      tap(res => {
        if (res.data) {
          let found = res.data?.page!.some(x => x.id === AuthService.GetAccountInfo()?.id!);
          if (found) {
            this.listInliverPlus = res.data?.page!.filter(x => x.id !== AuthService.GetAccountInfo()?.id!);
          } else {
            this.listInliverPlus = res.data?.page!;
          }
        }
      })
    )
  }

  scheduleGoInlive() {
    LoadingService.$(true);
    this._goInliveScheduleService.inlivewithGoInliveScheduleCreateGoInliveSchedulePost$Json({
      body: {
        authorId: AuthService.GetAccountInfo().id!,
        clubId: this.clubId,
        name: this.goInliveName,
        timeStart: this.hourCreated,
        startDate: new Date(this.dateCreated.replace(/-/g, "/")).toISOString(),
        channelName: '',
        type: 'GOINLIVE_CLASSIC'
      }
    }).pipe(
      switchMap((res) => this.addInliversToSchedule(res.data?.id!)),
      tap(res => {
        if(res?.res == 0){
          LoadingService.$(false);
          this.onHide();
        }
      })
    ).subscribe()
  }

  addInliversToSchedule(goInliveId: string) {
    if (this.selectedInlivers.length > 0) {
      let listString: string[] = [];
      this.selectedInlivers.forEach(x => {
        listString?.push(x.id!);
      })
      return this._goInliveScheduleService.inlivewithGoInliveScheduleInviteToGoInliveSchedulePost$Json({
        body: {
          accountIds: listString,
          goInliveScheduleId: goInliveId
        }
      })
    } else return of(null);
  }

}
