<p-dialog [(visible)]="openPopupManner" [draggable]="false" [resizable]="false" [modal]="true" (onHide)="closePopup()">
    <div class="card">
        <div class="header">
            <span class="title">
                {{'CLUB_MANNER' | translate}}
            </span>
            <span class="sub-title">
                {{'CLUB_DEFINE_MANNER' | translate}}
            </span>
        </div>
        <div class="content">
            <textarea class="textarea-cus" style="resize: none;" [(ngModel)]="content" [rows]="20" placeholder=""></textarea>
        </div>
        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'REGISTER' | translate}}"
                class="mt-5 p-button-outlined p-button-secondary ml-2" (click)="editClubRule()"></button>
        </div>
    </div>
</p-dialog>