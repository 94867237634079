import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-club-rules-admin',
  templateUrl: './club-rules-admin.component.html',
  styleUrls: ['./club-rules-admin.component.scss']
})
export class ClubRulesAdminComponent implements OnInit {

  @Input() openPopupManner = false;
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;

  @Output() openPopupMannerChange = new EventEmitter();

  content: string = '';

  constructor(
    private _clubService: ClubService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.content = this.club.policy!;
  }

  closePopup() {
    this.openPopupMannerChange.emit(false);
  }

  editClubRule() {
    LoadingService.$(true);
    this._clubService.inlivewithClubUpdatePolicyClubIdPut$Json({
      clubId: this.club.id!,
      body: {
        policy: this.content
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.showNotification()
        }
      })
    ).subscribe();
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(this._messageService.add({
        severity: 'success',
        summary: '',
        detail: this._translateService.instant('CHANGE_INFOR_CLUB'),
        key: 'notificationOnWeb',
        closable: false,
        data: {},
        life: 3 * 1000,
      }));
    });

    promise.then(res => window.location.reload());
  }

}
