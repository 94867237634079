<div class="nav-menu flex">
    <div class="col-4 flex align-items-center">
        <div class="logo ml-5" routerLink="home">
            <img width="40" src="../../../assets/images/svgs/logo-inlivewith.svg">
        </div>
        <div class="pl-5 ml-5 flex w-100 align-items-center search">
            <i class="pi pi-search icon-search"></i>
            <input class="w-100" type="text" pInputText [(ngModel)]="searchText" (ngModelChange)="search()"
                (keydown)="gotoSearchPage($event)" placeholder="{{'SEARCH' | translate}}" (blur)="outFocus()"
                (focus)="openSearchZone = true" />

            <ng-container *ngIf="searchText">
                <div #searchZone *ngIf="openSearchZone" class="result-club flex flex-column" id="test">
                    <div *ngFor="let item of clubs" (click)="openDetailClub(item)" [ngStyle]="{'order' : item.order}">
                        <div class="zone-club flex">
                            <div class="zone-avt" style="border-radius: 25rem !important;">
                                <img [src]="item?.avatar! | fetchUrlAvatarClub" class="fit-size">
                            </div>
                            <div class="flex flex-column">
                                <span class="ml-2 name-result-search"
                                    style="white-space: nowrap; max-width: 414px; text-overflow: ellipsis;overflow: hidden;">{{item.name}}</span>
                                <span class="ml-2 type-result-search">{{'CLUB' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let item of inlivers" (click)="gotoAccountInfo(item)"
                        [ngStyle]="{'order' : item.order}">
                        <div class="zone-club flex">
                            <div class="zone-avt">
                                <img [src]="item?.avatar! | fetchUrlImage" class="fit-size">
                            </div>
                            <div class="flex flex-column">
                                <span class="ml-2 name-result-search">{{item.nickName! | limitString:false:40}}</span>
                                <span class="ml-2 type-result-search">{{'INLIVER_IN_SEARCH' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let item of events" (click)="detailEvent(item)" [ngStyle]="{'order' : item.order}">
                        <div class="zone-club flex">
                            <div class="zone-avt">
                                <img [src]="item?.poster! | fetchUrlImage" class="fit-size">
                            </div>
                            <div class="flex flex-column">
                                <span class="ml-2 name-result-search">{{item.name}}</span>
                                <span class="ml-2 type-result-search">{{'EVENT' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-4 h-100" style="padding: inherit">
        <div class="menu flex justify-content-center" style="height:100%">
            <ng-container *ngFor="let item of links">
                <div class="flex w-100 h-100 align-items-center justify-content-center link" [routerLink]="item"
                    [ngClass]="{'active': _router.url.includes(item)}" (click)="clickMenu(item)">
                    <img width="33" [src]="pathAssets + 'menu-' + item + '.svg'">
                    <i *ngIf="item == 'chat' && (dataCheckNoti &&  dataCheckNoti!.conversation!)"
                        class="pi pi-circle-fill dot-noti-chat"></i>
                    <i *ngIf="item.includes('notification') && haveNoti"
                        class="pi pi-circle-fill mr-1 dot-noti"></i>
                    <img width="50" class="mt-5" [src]="pathAssets + 'menu-' + item + '-active.svg'">
                </div>
                <!-- <div style="width: 50px;height: 1rem;background-color: brown;"></div> -->
            </ng-container>
        </div>
    </div>
    <div class="col-4 flex align-items-center justify-content-end">

        <div class="flex mr-5" style="color: black;">
            <small *ngIf="!environment.production">Version {{environment.VERSION}}</small>
        </div>
        <div class="flex align-items-center menu-avatar m-3" *ngIf="isLoggedIn">
            <div class="zone-avt">
                <img [src]="account.avatar ? (account.avatar | fetchUrlImage) : defaultAvatar" class="fit-size">
            </div>
            <i class="pi pi-bars ml-2" style="font-size: 23px; z-index: 100000;" (click)="menu.toggle($event)"></i>

            <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>

        <div class="flex" *ngIf="!isLoggedIn">
            <button id="CONNECTION" pButton pRipple type="button" label="{{'CONNECTION' | translate}}" (click)="login()"
                class="p-button-outlined p-button-secondary"></button>
        </div>

        <div class="flex align-items-center justify-content-center m-3" *ngIf="!isLoggedIn">
            <img src="../../../assets/images/svgs/contact.svg" width="30" height="30" alt="" srcset=""
                (click)="openContact()">
        </div>

        <div class="flex align-items-center justify-content-center">
            <p-dropdown id="LANGUAGE_DROPDOWN" [placeholder]="languageSelected" [options]="listLanguage"
                (onChange)="selectLanguage($event)">
            </p-dropdown>
        </div>
        <div class="m-3 flex align-items-center justify-content-center">
            <img src="../../../assets/images/svgs/menu-multiapp.svg" #modal (click)="openMenuMultiApp()">
        </div>
    </div>
</div>

<ng-container class="position-relative modal fade" *ngIf="openMenuMulti">
    <div class="flex flex-column position-absolute menu-multi-app" id="menu-multi">
        <!-- <a href="">
            <img class="mb-2 cursor-pointer fit-size" src="../../../assets/images/svgs/inlivewith.svg">
        </a> -->
        <a href="https://www.inlive-stream.com/" target="_blank">
            <img class="mb-2 cursor-pointer fit-size" src="../../../assets/images/svgs/inlivestream.svg">
        </a>
        <a href="https://inlive-tickets.com/" target="_blank">
            <img class="mb-2 cursor-pointer fit-size" src="../../../assets/images/svgs/inliveticket.svg">
        </a>
        <a href="https://www.inlive-store.com/" target="_blank">
            <img class="mb-2 cursor-pointer fit-size" src="../../../assets/images/svgs/inlivestore.svg">
        </a>
        <a href="https://www.inlive-me.com/home" target="_blank">
            <img class="mb-2 cursor-pointer fit-size" src="../../../assets/images/svgs/inliveme.svg">
        </a>
    </div>
</ng-container>


<!-- <ng-container *ngIf="showDialog">
    <app-login [(showDialog)]="showDialog" (isLoggedIn)="isLoggedIn = true"
        (register)="showDialog = false; showDialogRegister = true"></app-login>
</ng-container> -->

<!-- <ng-container *ngIf="showDialogRegister">
    <app-register [(showDialogRegister)]="showDialogRegister" (login)="showDialog = true; showDialogRegister = false">
    </app-register>
</ng-container> -->