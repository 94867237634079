/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountConversationResponseModel } from '../models/account-conversation-response-model';
import { ApprovalNewConversationModel } from '../models/approval-new-conversation-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ConversationCreateAndUpdateModel } from '../models/conversation-create-and-update-model';
import { ConversationKickMemeberModel } from '../models/conversation-kick-memeber-model';
import { ConversationNotificationModel } from '../models/conversation-notification-model';
import { ConversationNotificationModelResponseModel } from '../models/conversation-notification-model-response-model';
import { ConversationResponseModelPaginationModelResponseModel } from '../models/conversation-response-model-pagination-model-response-model';
import { ConversationResponseModelResponseModel } from '../models/conversation-response-model-response-model';
import { ConversationUpdate } from '../models/conversation-update';
import { GuidListResponseModel } from '../models/guid-list-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class ConversationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithConversationPageGet
   */
  static readonly InlivewithConversationPageGetPath = '/inlivewith/Conversation/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetByIdConversationIdAccountIdGet
   */
  static readonly InlivewithConversationGetByIdConversationIdAccountIdGetPath = '/inlivewith/Conversation/GetById/{conversationId}/{accountId}';

  /**
   * AccountRelated In Response: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByIdConversationIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByIdConversationIdAccountIdGet$Plain$Response(params: {
    conversationId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByIdConversationIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelated In Response: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByIdConversationIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByIdConversationIdAccountIdGet$Plain(params: {
    conversationId: string;
    accountId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationGetByIdConversationIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * AccountRelated In Response: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByIdConversationIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByIdConversationIdAccountIdGet$Json$Response(params: {
    conversationId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByIdConversationIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelated In Response: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByIdConversationIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByIdConversationIdAccountIdGet$Json(params: {
    conversationId: string;
    accountId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationGetByIdConversationIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet
   */
  static readonly InlivewithConversationGetIdsConversationHaveOnlineAccountIdGetPath = '/inlivewith/Conversation/GetIdsConversationHaveOnline/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetIdsConversationHaveOnlineAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<GuidListResponseModel> {

    return this.inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetIdsConversationHaveOnlineAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<GuidListResponseModel> {

    return this.inlivewithConversationGetIdsConversationHaveOnlineAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationSearchByNameAccountIdNameGet
   */
  static readonly InlivewithConversationSearchByNameAccountIdNameGetPath = '/inlivewith/Conversation/SearchByName/{accountId}/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationSearchByNameAccountIdNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationSearchByNameAccountIdNameGet$Plain$Response(params: {
    accountId: string;
    name: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationSearchByNameAccountIdNameGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationSearchByNameAccountIdNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationSearchByNameAccountIdNameGet$Plain(params: {
    accountId: string;
    name: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationSearchByNameAccountIdNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationSearchByNameAccountIdNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationSearchByNameAccountIdNameGet$Json$Response(params: {
    accountId: string;
    name: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationSearchByNameAccountIdNameGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationSearchByNameAccountIdNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationSearchByNameAccountIdNameGet$Json(params: {
    accountId: string;
    name: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationSearchByNameAccountIdNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetByAccountIdsAccountId1AccountId2Get
   */
  static readonly InlivewithConversationGetByAccountIdsAccountId1AccountId2GetPath = '/inlivewith/Conversation/GetByAccountIds/{accountId1}/{accountId2}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Plain$Response(params: {
    accountId1: string;
    accountId2: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAccountIdsAccountId1AccountId2GetPath, 'get');
    if (params) {
      rb.path('accountId1', params.accountId1, {});
      rb.path('accountId2', params.accountId2, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Plain(params: {
    accountId1: string;
    accountId2: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Json$Response(params: {
    accountId1: string;
    accountId2: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAccountIdsAccountId1AccountId2GetPath, 'get');
    if (params) {
      rb.path('accountId1', params.accountId1, {});
      rb.path('accountId2', params.accountId2, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Json(params: {
    accountId1: string;
    accountId2: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationGetByAccountIdsAccountId1AccountId2Get$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetByAuthorAuthorIdGet
   */
  static readonly InlivewithConversationGetByAuthorAuthorIdGetPath = '/inlivewith/Conversation/GetByAuthor/{authorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAuthorAuthorIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAuthorAuthorIdGet$Plain$Response(params: {
    authorId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAuthorAuthorIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAuthorAuthorIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAuthorAuthorIdGet$Plain(params: {
    authorId: string;
    page?: number;
    limit?: number;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationGetByAuthorAuthorIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAuthorAuthorIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAuthorAuthorIdGet$Json$Response(params: {
    authorId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAuthorAuthorIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAuthorAuthorIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAuthorAuthorIdGet$Json(params: {
    authorId: string;
    page?: number;
    limit?: number;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationGetByAuthorAuthorIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetByAccountMemberAccountMemberIdGet
   */
  static readonly InlivewithConversationGetByAccountMemberAccountMemberIdGetPath = '/inlivewith/Conversation/GetByAccountMember/{accountMemberId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAccountMemberAccountMemberIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountMemberAccountMemberIdGet$Plain$Response(params: {
    accountMemberId: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAccountMemberAccountMemberIdGetPath, 'get');
    if (params) {
      rb.path('accountMemberId', params.accountMemberId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAccountMemberAccountMemberIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountMemberAccountMemberIdGet$Plain(params: {
    accountMemberId: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationGetByAccountMemberAccountMemberIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetByAccountMemberAccountMemberIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountMemberAccountMemberIdGet$Json$Response(params: {
    accountMemberId: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetByAccountMemberAccountMemberIdGetPath, 'get');
    if (params) {
      rb.path('accountMemberId', params.accountMemberId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetByAccountMemberAccountMemberIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetByAccountMemberAccountMemberIdGet$Json(params: {
    accountMemberId: string;
    page?: number;
    limit?: number;
    isAccept?: boolean;
  }): Observable<ConversationResponseModelPaginationModelResponseModel> {

    return this.inlivewithConversationGetByAccountMemberAccountMemberIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelPaginationModelResponseModel>) => r.body as ConversationResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet
   */
  static readonly InlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGetPath = '/inlivewith/Conversation/GetNumberConversationWasNotReadYet/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Json(params: {
    accountRequestId: string;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithConversationGetNumberConversationWasNotReadYetAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationKickMemberInConversationPut
   */
  static readonly InlivewithConversationKickMemberInConversationPutPath = '/inlivewith/Conversation/KickMemberInConversation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationKickMemberInConversationPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationKickMemberInConversationPut$Plain$Response(params?: {
    body?: ConversationKickMemeberModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationKickMemberInConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationKickMemberInConversationPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationKickMemberInConversationPut$Plain(params?: {
    body?: ConversationKickMemeberModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithConversationKickMemberInConversationPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationKickMemberInConversationPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationKickMemberInConversationPut$Json$Response(params?: {
    body?: ConversationKickMemeberModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationKickMemberInConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationKickMemberInConversationPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationKickMemberInConversationPut$Json(params?: {
    body?: ConversationKickMemeberModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithConversationKickMemberInConversationPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationAddMemberToConversationPut
   */
  static readonly InlivewithConversationAddMemberToConversationPutPath = '/inlivewith/Conversation/AddMemberToConversation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationAddMemberToConversationPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationAddMemberToConversationPut$Plain$Response(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationAddMemberToConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationAddMemberToConversationPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationAddMemberToConversationPut$Plain(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationAddMemberToConversationPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationAddMemberToConversationPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationAddMemberToConversationPut$Json$Response(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationAddMemberToConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationAddMemberToConversationPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationAddMemberToConversationPut$Json(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationAddMemberToConversationPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationCreatePost
   */
  static readonly InlivewithConversationCreatePostPath = '/inlivewith/Conversation/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationCreatePost$Plain$Response(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationCreatePost$Plain(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationCreatePost$Json$Response(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationCreatePost$Json(params?: {
    body?: ConversationCreateAndUpdateModel
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationApprovalNewConversationPut
   */
  static readonly InlivewithConversationApprovalNewConversationPutPath = '/inlivewith/Conversation/ApprovalNewConversation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationApprovalNewConversationPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationApprovalNewConversationPut$Plain$Response(params?: {
    body?: ApprovalNewConversationModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationApprovalNewConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationApprovalNewConversationPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationApprovalNewConversationPut$Plain(params?: {
    body?: ApprovalNewConversationModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithConversationApprovalNewConversationPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationApprovalNewConversationPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationApprovalNewConversationPut$Json$Response(params?: {
    body?: ApprovalNewConversationModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationApprovalNewConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationApprovalNewConversationPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationApprovalNewConversationPut$Json(params?: {
    body?: ApprovalNewConversationModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithConversationApprovalNewConversationPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationUpdatePost
   */
  static readonly InlivewithConversationUpdatePostPath = '/inlivewith/Conversation/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationUpdatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationUpdatePost$Plain$Response(params?: {
    body?: ConversationUpdate
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationUpdatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationUpdatePost$Plain(params?: {
    body?: ConversationUpdate
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationUpdatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationUpdatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationUpdatePost$Json$Response(params?: {
    body?: ConversationUpdate
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationUpdatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationUpdatePost$Json(params?: {
    body?: ConversationUpdate
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationUpdatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationDeleteConversationIdDelete
   */
  static readonly InlivewithConversationDeleteConversationIdDeletePath = '/inlivewith/Conversation/Delete/{conversationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationDeleteConversationIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteConversationIdDelete$Plain$Response(params: {
    conversationId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationDeleteConversationIdDeletePath, 'delete');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationDeleteConversationIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteConversationIdDelete$Plain(params: {
    conversationId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationDeleteConversationIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationDeleteConversationIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteConversationIdDelete$Json$Response(params: {
    conversationId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationDeleteConversationIdDeletePath, 'delete');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationDeleteConversationIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteConversationIdDelete$Json(params: {
    conversationId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationDeleteConversationIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationDeleteByAccountConversationIdAccountIdDelete
   */
  static readonly InlivewithConversationDeleteByAccountConversationIdAccountIdDeletePath = '/inlivewith/Conversation/DeleteByAccount/{conversationId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Plain$Response(params: {
    conversationId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationDeleteByAccountConversationIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Plain(params: {
    conversationId: string;
    accountId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Json$Response(params: {
    conversationId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ConversationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationDeleteByAccountConversationIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Json(params: {
    conversationId: string;
    accountId: string;
  }): Observable<ConversationResponseModelResponseModel> {

    return this.inlivewithConversationDeleteByAccountConversationIdAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationResponseModelResponseModel>) => r.body as ConversationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationEditNotificationInConversationPut
   */
  static readonly InlivewithConversationEditNotificationInConversationPutPath = '/inlivewith/Conversation/EditNotificationInConversation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationEditNotificationInConversationPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationEditNotificationInConversationPut$Plain$Response(params?: {
    body?: ConversationNotificationModel
  }): Observable<StrictHttpResponse<AccountConversationResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationEditNotificationInConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountConversationResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationEditNotificationInConversationPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationEditNotificationInConversationPut$Plain(params?: {
    body?: ConversationNotificationModel
  }): Observable<AccountConversationResponseModel> {

    return this.inlivewithConversationEditNotificationInConversationPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountConversationResponseModel>) => r.body as AccountConversationResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationEditNotificationInConversationPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationEditNotificationInConversationPut$Json$Response(params?: {
    body?: ConversationNotificationModel
  }): Observable<StrictHttpResponse<AccountConversationResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationEditNotificationInConversationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountConversationResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationEditNotificationInConversationPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithConversationEditNotificationInConversationPut$Json(params?: {
    body?: ConversationNotificationModel
  }): Observable<AccountConversationResponseModel> {

    return this.inlivewithConversationEditNotificationInConversationPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountConversationResponseModel>) => r.body as AccountConversationResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithConversationCheckNotificationInConversationGet
   */
  static readonly InlivewithConversationCheckNotificationInConversationGetPath = '/inlivewith/Conversation/CheckNotificationInConversation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationCheckNotificationInConversationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationCheckNotificationInConversationGet$Plain$Response(params?: {
    conversationId?: string;
    accountId?: string;
  }): Observable<StrictHttpResponse<ConversationNotificationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationCheckNotificationInConversationGetPath, 'get');
    if (params) {
      rb.query('conversationId', params.conversationId, {});
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationNotificationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationCheckNotificationInConversationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationCheckNotificationInConversationGet$Plain(params?: {
    conversationId?: string;
    accountId?: string;
  }): Observable<ConversationNotificationModelResponseModel> {

    return this.inlivewithConversationCheckNotificationInConversationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationNotificationModelResponseModel>) => r.body as ConversationNotificationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithConversationCheckNotificationInConversationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationCheckNotificationInConversationGet$Json$Response(params?: {
    conversationId?: string;
    accountId?: string;
  }): Observable<StrictHttpResponse<ConversationNotificationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ConversationService.InlivewithConversationCheckNotificationInConversationGetPath, 'get');
    if (params) {
      rb.query('conversationId', params.conversationId, {});
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationNotificationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithConversationCheckNotificationInConversationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithConversationCheckNotificationInConversationGet$Json(params?: {
    conversationId?: string;
    accountId?: string;
  }): Observable<ConversationNotificationModelResponseModel> {

    return this.inlivewithConversationCheckNotificationInConversationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationNotificationModelResponseModel>) => r.body as ConversationNotificationModelResponseModel)
    );
  }

}
