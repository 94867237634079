import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription, of } from 'rxjs';
import { catchError, last, mergeMap, tap, throttleTime } from 'rxjs/operators';
import {
  AccountResponseWithRoleInCLubModel,
  MediaPostResponseModel,
  PostResponseModel,
  ReactInputModel,
  Post,
  MetaLink,
  MentionReceiver,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  ClubService,
  MentionService,
  PostService,
} from 'src/app/core/api/be/services';
import {
  FILE_DURATION_LIMIT,
  FILE_SIZE_LIMIT_POST,
  MENTION_REGEX,
  TYPE_MENTION,
  URL_REGEX,
  base64ToBlob,
  defaultAvatar,
  getFileExtension,
  typeAudio,
} from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/environments/environment';
import 'linkify-plugin-mention';
import 'linkify-plugin-hashtag';
import { MobileService } from 'src/app/core/services/is-mobile.service';
import _ from 'lodash';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { NotificationServicee } from 'src/app/core/services/notification.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { log, time } from 'console';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit, AfterViewInit {
  @Input() post!: PostResponseModel;
  postForEdit!: PostResponseModel;
  @Input() isAdmin!: boolean;
  @Input() isJoined!: boolean;

  @Output() askJoinClub = new EventEmitter();
  @Output() deletePostEvent = new EventEmitter<string>();
  @Output() updatePostEvent = new EventEmitter<Post>();
  @Output() updatePinnedPost = new EventEmitter<any>();

  roleOfPostAuthor!: string;
  linkForCopy!: string;

  defaultAvatar = defaultAvatar;

  created: boolean = false;
  isOverflow!: boolean;
  isLiked!: boolean;
  isHidden!: boolean;
  isAcceptToDelete!: boolean;
  showCommentZone: boolean = false;
  showCommentZoneMobile: boolean = false;
  showConfirmDeletePost: boolean = false;
  openPopupBanInliver: boolean = false;
  openPopUpEditPost: boolean = false;
  openPopupReport: boolean = false;
  openZoneLike: boolean = false;
  isMobile: boolean = false;
  show: boolean = false;
  showEmoji = false;

  items!: MenuItem[];

  openPopupRecordAudio: boolean = false;

  content: string = '';
  urls: any[] = [];
  filePosts: any[] = [];
  urlAudio!: string;
  blobFileAudio!: Blob;
  format!: string;
  completeText!: boolean;

  isChrome =
    navigator.userAgent.toLowerCase().includes('chrome') &&
    !navigator.userAgent.toLowerCase().includes('edg');

  private listUserForMention!: AccountResponseWithRoleInCLubModel[];
  @Input() set listMember(value: AccountResponseWithRoleInCLubModel[]) {
    if (value?.length! > 0) {
      this.listUserForMention = value;

      let stageIsPrivate = this.post.stage?.isPrivate;
      let mentionList = value!.filter((inliver) =>
        stageIsPrivate
          ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
          : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
          inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
      )!;

      mentionList.forEach((data) => {
        if (data.id == AuthService.GetAccountInfo().id) {
          return;
        }
        this.listMention.push({
          username: data.nickName?.trimStart(),
          name: data.nickName?.trimStart(),
          img: data.avatar,
          id: data.id,
        });
      });
      this.initConfigMention();
    } else {
      //this.getListInlivers();
    }
  }
  get listMember() {
    return this.listUserForMention;
  }

  @Input() listMention: any[] = [];
  mentionConfig: any = {};

  tribute!: any;
  indexMetaLinks: number = 0;

  subscription = new Subscription();

  refetchWhenReceiveNotiDonePost$ =
    this._notificationService.isReloadPost$$.pipe(
      tap((res: any) => {
        if (!res) {
          return;
        }

        let idPost = JSON.parse(res!.content).Content;

        if (idPost && idPost !== this.post.id) {
          return;
        }

        idPost && idPost == this.post.id ? this.refresh() : '';
      })
    );

  @ViewChild('toggleEmoji') toggleEmoji!: ElementRef;
  roleOfCurrentUser!: string;

  listAllOptions: any;
  intervalDetectFile!: NodeJS.Timeout;

  constructor(
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    public _router: Router,
    private _translateService: TranslateService,
    private clipboardService: ClipboardService,
    private _messageService: MessageService,
    public _mobileService: MobileService,
    private _notificationService: NotificationServicee,
    private _clubService: ClubService,
    private renderer: Renderer2,
    public _firebaseService: FirebaseService,
    private _accountService: AccountService,
    private _mentionService: MentionService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleEmoji?.nativeElement!) {
        this.showEmoji = false;
      }
    });
  }

  clickOnSide(event: Event) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isOverflow = this.checkOverflow(this.post.id!);
    }, 1000);
  }

  initConfigMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: _.unionBy(this.listMention, 'id'),
          triggerChar: '@',
          mentionSelect: (item: any) => {
            this.tribute = `@${item.name}`;
            this.tribute = this.tribute.toString().replaceAll(' ', '');
            return ` ${this.tribute} `;
          },
          labelKey: 'name',
          disableSearch: false,
        },
      ],
    };
  }

  ngOnInit(): void {
    this.getMetaLinksIndex();
    this.subscription.add(this.refetchWhenReceiveNotiDonePost$.subscribe());
    this.makeOption();

    this.initConfigMention();
    this.postForEdit = Object.assign({ ...this.post });
    // this.postForEdit.mediaPosts = [...(this.post.mediaPosts || [])];
    this.isMobile = this._mobileService.isMobile();
  }

  getMetaLinksIndex() {
    let arrayOfStringUrls: string[] = [];
    let urls = this.post?.message?.match(URL_REGEX);
    if (urls?.length! > 0) {
      let urlsConvert = urls?.map((x) => decodeURIComponent(x));
      this.post.metaLinks?.map((x) => {
        arrayOfStringUrls?.push(decodeURIComponent(x.url!));
      })!;
      let commonItem = _.intersectionWith(
        urlsConvert,
        arrayOfStringUrls,
        _.isEqual
      );
      this.indexMetaLinks = this.post.metaLinks?.findIndex(
        (x) => commonItem[0] == decodeURIComponent(x.url!)
      )!;
      return;
    } else {
      this.indexMetaLinks = -1;
      return;
    }
  }

  getRoleOfPostAuthor() {
    if (this.post?.isBannedByClub!) {
      return 'NO_ROLE';
    }

    if (this.post?.isAdminPost!) {
      return '';
    }

    if (
      this.post?.authorId! === this.post.club?.author?.id! ||
      this.post?.authorId! === this.post.club?.authorId!
    ) {
      return 'CREATOR';
    } else if (
      this.post?.authorId! != this.post.club?.author?.id! ||
      this.post?.authorId! != this.post.club?.authorId!
    ) {
      if (this.listMember?.length! > 0) {
        let index = this.listMember.findIndex(
          (x) => x.id! == this.post.authorId!
        );
        if (index < 0) {
          return '';
        }
        if (this.listMember[index].roleInClub === 'ROLE_ADMIN') {
          return 'ADMIN';
        }
      } else {
        if (this.post.club?.roleOfPostAuthor === 'ROLE_ADMIN') {
          return 'ADMIN';
        } else return 'MEMBER';
      }
    }

    return 'MEMBER';
  }

  initMenuFor() {
    /**
     * OWNER / CREATER / OTHER
     * ______________________________
     *
     * OWNER => create discussion
     * CREATER => creater club
     * OTHER => other admin club or normal user
     *
     */

    this.items = [];

    if (this.roleOfCurrentUser == 'OWNER') {
      this.items.push(this.listAllOptions.MODIFIER);
      this.items.push(this.listAllOptions.POST_DELETE);
      this.items.push(this.listAllOptions.POST_SHARE);
      this.items.push(this.listAllOptions.PIN_POST);

      return;
    }

    if (this.roleOfCurrentUser == 'CREATER') {
      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : (this.items.push(this.listAllOptions.PROFILE_SEE),
          this.items.push(this.listAllOptions.PIN_POST));

      this.items.push(this.listAllOptions.HIDE_POST);
      if (!this.post.isPostOfCeleb) {
        if (this.post.author?.accessLevelInClub! !== '') {
          this.items.push(this.listAllOptions.POST_DELETE);
          this.items.push(this.listAllOptions.CLUB_BAN_USER);
        } else {
          this.items.push(this.listAllOptions.POST_DELETE);
        }
      }
    }

    if (this.roleOfCurrentUser == 'ADMIN') {
      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : this.items.push(this.listAllOptions.PROFILE_SEE);
      this.items.push(this.listAllOptions.HIDE_POST);
      if (!this.post.isPostOfCeleb) {
        if (this.post.author?.accessLevelInClub! !== '') {
          this.items.push(this.listAllOptions.POST_DELETE);
          this.items.push(this.listAllOptions.CLUB_BAN_USER);
        } else {
          this.items.push(this.listAllOptions.POST_DELETE);
        }
      }
    }

    if (this.roleOfCurrentUser == 'OTHER') {
      this._router.url.startsWith('/home')
        ? this.items.push(this.listAllOptions.VIEW_CLUB)
        : this.items.push(this.listAllOptions.PROFILE_SEE);
      this.items.push(this.listAllOptions.HIDE_POST);
    }

    if (this.post.author?.accessLevelInClub! !== '') {
      this.items.push(this.listAllOptions.POST_SHARE);
      this.items.push(this.listAllOptions.POST_REPORT);
    }
  }

  initMenu() {
    if (
      AuthService.GetAccountInfo().id === this.post.authorId ||
      AuthService.GetAccountInfo().id === this.post.club?.authorId
    ) {
      if (AuthService.GetAccountInfo().id === this.post.club?.authorId) {
        this.roleOfCurrentUser = 'CREATER';
      }

      if (AuthService.GetAccountInfo().id === this.post.authorId) {
        this.roleOfCurrentUser = 'OWNER';
      }
    } else {
      if (this.isAdmin) {
        this.roleOfCurrentUser = 'ADMIN';
      } else {
        this.roleOfCurrentUser = 'OTHER';
      }
    }

    this.initMenuFor();
  }

  timeAgo = (date: string) => {
    const timeNow = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    const parsedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();

    if (moment.utc().diff(date, 'days') >= 7) {
      return moment
        .utc(date)
        .locale(currentLang ? currentLang : 'fr')
        .format('L');
    }

    /**
     *  If the post has been created less than a minute ago
     */
    if (moment(timeNow).diff(moment(parsedDate), 'seconds') <= 60) {
      return this._translateService.instant('POST_DATE_NOW');
    }

    //   moment.updateLocale('fr', {
    //     relativeTime : {
    //         past:   "%s",
    //         s  : '%d s',
    //         ss : '%d s',
    //         m:  "%d m",
    //         mm: "%d m",
    //         h:  "an h",
    //         hh: "%d h",
    //         d:  "%d j",
    //         dd: "%d j",
    //         M:  "%d mois",
    //         MM: "%d mois",
    //         y:  "%d an",
    //         yy: "%d années"

    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ')
      ? text
        .replace('il y a ', '')
        .replace('une', ' 1')
        .replace('jour', ' j')
        .replace('minute', ' min')
        .replace('heure', ' h')
        .replace('s', '')
        .replace('quelque secondes', '1s')
        .replace('un', ' 1')
      : text;

    return text;
  };

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  deletePost() {
    this._postService
      .inlivewithPostDeletePostDelete$Json({
        postId: this.post.id,
        stageId: this.post.stageId!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.deletePostEvent.emit(this.post.id);
            this.showNotification(
              this._translateService.instant('ACCOUNT_DELETE_POST')
            );
          } else {
            this.showNotification(
              this._translateService.instant('AT_LEAST_HAS_A_POST_ON_STAGE')
            );
          }
        })
      )
      .subscribe();
  }

  likePost() {
    if (!this.isJoined) {
      this.askJoinClub.emit();
      return;
    }
    this.post.isReacted = !this.post.isReacted;
    if (this.post.isReacted) {
      this.post.totalReact!++;
      this.postLikePost(true, 'REACTION_LIKE');
    } else {
      this.post.totalReact!--;
      this.postLikePost(false, null);
    }
  }

  openCommentZone(isMobile: boolean = false) {
    console.log('check');

    LoadingService.$(true);
    if (!this.isJoined) {
      LoadingService.$(false);
      this.askJoinClub.emit();
      return;
    }
    LoadingService.$(false);
    if (isMobile) {
      this.showCommentZoneMobile = true;
      return;
    } else {
      this.showCommentZone = true;
      return;
    }
  }

  postLikePost(isAppearing: boolean, type: any) {
    let body: ReactInputModel = {
      accountId: AuthService.GetAccountInfo().id!,
      dependObjectId: this.post.id,
      isAppearing: isAppearing,
      type: isAppearing ? type : null,
      stageId: this.post.stageId!,
      clubId: this.post.clubId!,
    };
    return this._postService
      .inlivewithPostReactReactOnPostPost$Json({
        body: body,
      })
      .subscribe(
        (res) => {
          if (res.res == -2) {
            this.deletePostEvent.emit(this.post.id!);
          }
        },
        () => {
          this.post.isReacted = !this.post.isReacted;
          if (this.post.isReacted) {
            this.post.totalReact!++;
          } else {
            this.post.totalReact!--;
          }
        }
      );
  }

  updatePost() {
    return this._postService.inlivewithPostGetByIdPostIdGet$Json({
      postId: this.post.id!,
      stageId: this.post.stageId!,
      clubId: this.post.clubId!,
    });
  }

  showErrorLimitSizeFile = false;
  showErrorMaximumFiles = false;
  showErrorMixedFiles = false;
  showErrorDuration = false;

  detectFiles(event: any) {
    this.showErrorLimitSizeFile = false;
    this.showErrorMaximumFiles = false;
    this.showErrorMixedFiles = false;
    var isCheckMedia = false;
    var isCheckAudio = false;

    /* check maximum 4 files */
    if (event.target.files.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      if (file) {
        LoadingService.$(true);
        let reader = new FileReader();
        reader.readAsDataURL(file);

        /* check Size file size */
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb > FILE_SIZE_LIMIT_POST) {
          this.showErrorLimitSizeFile = true;
          event.target.value = null;
          LoadingService.$(false);
          return;
        }

        if (file.type.split('/')[0] === 'audio') {
          isCheckAudio = true;
        }
        if (
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'image'
        ) {
          isCheckMedia = true;
        }
        if (isCheckAudio && isCheckMedia) {
          this.showErrorMixedFiles = true;
          // isCheckAudio = false;
          // isCheckMedia = false;
          LoadingService.$(false);
          //return;
        }

        if (
          typeAudio.includes(getFileExtension(file.name)) ||
          file.type.split('/')[0] === 'video' ||
          file.type.split('/')[0] === 'audio'
        ) {
          var f_duration = 0;
          var fileUrl = window.URL.createObjectURL(file);
          const audio = document.createElement('audio');
          audio.id = fileUrl;
          document.getElementById('zoneAudio')?.appendChild(audio);
          document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
          //console.log('???', document.getElementById(`${fileUrl}`)!);
          document
            .getElementById(`${fileUrl}`)
            ?.addEventListener('canplaythrough', (event) => {
              let test = event.target as HTMLAudioElement;
              f_duration = Math.round(test.duration);
              //console.log(f_duration);
              if (f_duration >= FILE_DURATION_LIMIT) {
                this.showErrorDuration = true;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                return;
              } else {
                //this.showErrorDuration = false;
                document.getElementById(`${fileUrl}`)?.removeAttribute('src');
                LoadingService.$(false);
                //return;
              }
            });
        }

        reader.onload = () => {
          let base64Str: string = reader.result as string;
          if (!base64Str) {
            return;
          }
          let blob = base64ToBlob(base64Str);
          file.id = new Date().getTime();
          this.filePosts.push(file);
          this.urls.push({
            name: file.name,
            format: file.type.split('/')[0],
            data: this.domSanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(blob!)
            ),
            description: '',
            file: file,
            id: file.id,
            originalTypeFile: file.type,
          });

          /* check maximum 4 files */
          if (this.urls.length > 4) {
            this.showErrorMaximumFiles = true;
            LoadingService.$(false);
            //event.target.value = null;
            //return;
          }
          event.target.value = null;
        };
      }
      LoadingService.$(false);
    }
  }

  removeAsset(fileName: string) {
    this.urls = this.urls.filter((file) => file.id !== fileName);
    this.filePosts = this.filePosts.filter((file) => file.id !== fileName);

    var isCheckAudio = false;
    var isCheckMedia = false;
    this.showErrorDuration = false;
    this.showErrorMixedFiles = false;

    if (this.urls.length > 4) {
      this.showErrorMaximumFiles = true;
      LoadingService.$(false);
      //event.target.value = null;
      //return;
    } else {
      this.showErrorMaximumFiles = false;
    }

    for (let index = 0; index < this.urls.length; index++) {
      /*check mixed file */
      if (this.urls[index].format === 'audio') {
        isCheckAudio = true;
      }
      if (
        this.urls[index].format === 'image' ||
        this.urls[index].format === 'video'
      ) {
        isCheckMedia = true;
      }
      if (isCheckAudio && isCheckMedia) {
        this.showErrorMixedFiles = true;
      }

      /* check Size file size */
      const fileSize = this.urls[index].file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > FILE_SIZE_LIMIT_POST) {
        this.showErrorLimitSizeFile = true;
        return;
      }

      /* check Duration */
      if (
        typeAudio.includes(getFileExtension(this.urls[index].file.name)) ||
        this.urls[index].file.type.split('/')[0] === 'video' ||
        this.urls[index].file.type.split('/')[0] === 'audio'
      ) {
        var f_duration = 0;
        var fileUrl = window.URL.createObjectURL(this.urls[index].file);
        const audio = document.createElement('audio');
        audio.id = fileUrl;
        document.getElementById('zoneAudio')?.appendChild(audio);
        document.getElementById(`${fileUrl}`)!.setAttribute('src', fileUrl);
        document
          .getElementById(`${fileUrl}`)
          ?.addEventListener('canplaythrough', (event) => {
            let test = event.target as HTMLAudioElement;
            f_duration = Math.round(test.duration);

            if (f_duration >= FILE_DURATION_LIMIT) {
              this.showErrorDuration = true;
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
              return;
            } else {
              document.getElementById(`${fileUrl}`)?.removeAttribute('src');
            }
          });
      }
    }
  }

  createComment() {
    this.showEmoji = false;
    this.created = true;
    this.openPopupRecordAudio = false;
    var mentionIds: MentionReceiver[] = []

    if (this.content) {
      let listMentions = this.content.match(MENTION_REGEX);
      this.listMention
        .filter((user) =>
          listMentions
            ?.map((value) => value.replace('@', ''))
            .includes(user.username.replaceAll(' ', ''))
        )!
        .forEach((value) => {
          mentionIds.push({
            id: value.id,
            name: value.name
          })
        })! || [];
    }

    if (!this.isJoined) {
      this.askJoinClub.emit();
      return;
    }

    if (this.urlAudio && this.blobFileAudio) {
      this.filePosts.push(this.blobFileAudio);
    }

    LoadingService.$(true);

    this._postService
      .inlivewithPostCommentCreateCommentPostPost$Json({
        body: {
          AuthorId: AuthService.GetAccountInfo().id!,
          Message: this.content,
          PostId: this.post.id!,
          StageId: this.post.stageId!,
          MediaCommentFiles: this.filePosts,
        }
      })
      .pipe(
        tap((res) => { }),
        mergeMap((res) => this.updatePost()),
        tap((res) => {
          if (res.res == 0) {
            this.addMentionReceivers(mentionIds, res.data?.id!)

            this.post = res.data!;
            (this.content = ''), (this.urls = []);
            this.filePosts = [];
            (this.urlAudio = ''), LoadingService.$(false);
            this.created = false;
          }
        })
      )
      .subscribe();
  }

  addMentionReceivers(receivers: MentionReceiver[], objectId: string) {
    this._mentionService.inlivewithMentionCreatesPost$Json({
      isPushNoti: this.filePosts.length > 0 ? false : true,
      body: {
        clubId: this.post.clubId!,
        dependObjectId: objectId,
        senderId: AuthService.GetAccountInfo().id!,
        receivers: receivers,
        type: TYPE_MENTION.COMMENT_POST,
      }
    }).subscribe();
  }

  startRecord() {
    if (!this.isJoined) {
      this.askJoinClub.emit();
      return;
    }
    this.urlAudio = '';
    this.content = '';
    this.openPopupRecordAudio = true;
  }

  sanitize() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.urlAudio);
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    text = text?.replace(MENTION_REGEX, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${(
        url
      )}" target="_blank">${(url)}</a>`;
    });
  }

  openAccountInfo() {
    if (
      AuthService.GetAccountInfo().id! !== this.post.author?.id! &&
      !this.post.author?.isCelebrity! && !this.post.isAdminPost
    ) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: this.post.author?.id! },
      });
    }
  }

  openClubDetail() {
    LoadingService.$(true);
    this._router
      .navigate(['manage-club', 'detail-club'], {
        queryParams: { clubId: this.post?.club?.id! },
      })
      .then(() => LoadingService.$(false));
  }

  refresh() {
    LoadingService.$(true);
    let body = {
      postId: this.post.id!,
      stageId: this.post.stageId!,
      clubId: this.post.clubId!,
    };
    return this._postService
      .inlivewithPostGetByIdPostIdGet$Json(body)
      .subscribe((res) => {
        LoadingService.$(false);
        this.post = res.data!;
        this.postForEdit = { ...this.post };
        this.postForEdit.mediaPosts = [...(this.post.mediaPosts || [])];
      });
  }

  openLikeZone(isMobile: boolean = false) {
    this.isMobile = isMobile;
    this.openZoneLike = true;
  }

  getUrl(asset: any) {
    if (!asset) {
      return;
    }

    if (!asset!.includes('?')) {
    } else {
      if (asset!.split('?')[1].startsWith('i')) {
        return asset?.replace('/file.', '/thumb.')!;
      }

      if (asset!.split('?')[1].startsWith('v')) {
        return asset!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset;
  }

  removeAudio() {
    this.urlAudio = '';
    this.blobFileAudio = new Blob();
  }

  refreshWhenReportSuccess() {
    this.post.isReported = true;
    this.openPopupReport = false;
    this.makeOption();
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '').replaceAll(' ', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replaceAll(' ', '') == uname
    )[0];
    
    if (user && this.post.mentions?.some(x => x.receiverName === user.nickName) && user.id! !== AuthService.GetAccountInfo().id!) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  itemSelected(event: any) { }

  hideShowPost() {
    this._postService
      .inlivewithPostHidePostAccountRequestIdPostIdPut$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        postId: this.post.id!,
      }).pipe(
        tap((res) => {
          if (res.res == 0) {
            if (this._router.url.includes('/post-detail')) {
              this._router.navigate(['/home']).then(() => window.location.reload());
            } else {
              this.deletePostEvent.emit(this.post.id);
            }
          }
        })
      )
      .subscribe();
  }

  unHide() {
    this._postService
      .inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json({
        accountRequestId: AuthService.GetAccountInfo().id!,
        postId: this.post.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.isHidden = false;
          }
        })
      )
      .subscribe();
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  updateComment(isCreate: boolean) {
    // if (!isCreate) {
    //   this.post.totalComment!--;
    // } else this.post.totalComment!++;
    // this.post.isCommented = !(!isCreate && this.post.totalComment === 0);
    this.refresh();
  }

  bannedFromClub() {
    return this._clubService
      .inlivewithClubCreateAccountClubBannedPost$Json({
        body: {
          accountBannedId: this.post.authorId!,
          authorId: AuthService.GetAccountInfo().id!,
          clubId: this.post.clubId!,
          type: 'ADMIN_BANNED_MEMBER',
        },
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            //this.refresh.emit();
            window.location.reload();
          }
        })
      )
      .subscribe();
  }

  refreshPage() {
    window.location.reload();
  }

  changeShowEmoji(event: any) {
    event.stopPropagation();
    this.showEmoji = !this.showEmoji;
  }

  addEmoji(event: any) {
    event.$event.stopPropagation();
    if (this.content) {
      this.content += event.emoji.native;
    } else this.content = event.emoji.native;
    var textarea = document.getElementById('textarea_comment_post');
    textarea!.scrollTop = textarea!.scrollHeight;
  }

  makeOption() {
    var limitStringPipe = new LimitStringPipe();
    this.listAllOptions = {
      PROFILE_SEE: {
        icon: 'pi pi-user',
        label: limitStringPipe.transform(
          this._translateService.instant('SEE') +
          ' ' +
          this.post.author?.nickName!,
          false,
          20
        ),
        visible:
          this.post.author?.accessLevelInClub! !== 'ACCESSLEVEL_CELEBRITY',
        command: () => {
          if (this.isMobile) {
            return;
          }
          this.openAccountInfo();
        },
      },
      VIEW_CLUB: {
        icon: 'pi pi-star',
        label: this._translateService.instant('VIEW_CLUB'),
        command: () => {
          if (this.isMobile) {
            return;
          }
          this.openClubDetail();
        },
      },
      CLUB_BAN_USER: {
        icon: 'pi pi-ban',
        label: this._translateService.instant('CLUB_BAN_USER'),
        visible:
          AuthService.GetAccountInfo().id! === this.post.club?.authorId ||
          this.getRoleOfPostAuthor() === 'MEMBER',
        command: () => {
          this.openPopupBanInliver = true;
        },
      },
      POST_DELETE: {
        icon: 'pi pi-trash',
        visible:
          AuthService.GetAccountInfo().id! === this.post.club?.authorId ||
          AuthService.GetAccountInfo().id! === this.post.authorId! ||
          this.getRoleOfPostAuthor() === 'MEMBER',
        label: this._translateService.instant('POST_DELETE'),
        command: () => (this.showConfirmDeletePost = true),
      },
      HIDE_POST: {
        icon: 'pi pi-eye-slash',
        label: this._translateService.instant('HIDE_POST'),
        command: () => {
          this.hideShowPost();
        },
      },
      MODIFIER: {
        icon: 'pi pi-pencil',
        disabled: !this.post?.mediaPosts?.every((result) =>
          result.url?.includes('?')
        ),
        label: this._translateService.instant('EDIT_POST'),
        command: () => {
          this.openPopUpEditPost = true;
        },
      },
      POST_SHARE: {
        icon: 'pi pi-share-alt',
        label: this._translateService.instant('POST_SHARE'),
        command: () => {
          this.renderLinkForCopy();
          this.clipboardService.copyFromContent(this.linkForCopy);
          this._messageService.add({
            severity: 'success',
            // summary: this._translateService.instant('NOTIFICATION'),
            detail: this._translateService.instant('COPIED'),
            key: 'copied',
            closable: false,
          });
        },
      },
      POST_REPORT: {
        icon: 'pi pi-flag',
        label: this._translateService.instant('POST_REPORT'),
        disabled: this.post.isReported,
        command: () => {
          this.openPopupReport = true;
        },
      },
      PIN_POST: {
        icon: this.post.isPinPost ? 'unpin' : 'pin',
        label: this.post.isPinPost
          ? this._translateService.instant('UNPIN_PUBLICATION')
          : this._translateService.instant('PIN_PUBLICCATION'),
        visible:
          this.post!.club!.authorId == AuthService.GetAccountInfo().id &&
          this.post.authorId == AuthService.GetAccountInfo().id &&
          this._router.url.includes('manage-club/detail-club'),
        command: () => {
          if (this.isMobile) {
            return;
          }
          this.pinPost(this.post.isPinPost! ? false : true);
        },
      },
    };

    this.initMenu();
  }

  getListInlivers() {
    this._clubService
      .inlivewithClubGetAllMembersClubIdGet$Json({
        clubId: this.post.clubId!,
        limit: 100000,
        page: 1,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listMember = res.data?.page!;

            let stageIsPrivate = this.post.stage?.isPrivate;

            let mentionList = res.data?.page!.filter((inliver) =>
              stageIsPrivate
                ? inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
                : inliver.accessLevelInClub == 'ACCESSLEVEL_ANONYMOUS' ||
                inliver.accessLevelInClub == 'ACCESSLEVEL_PLUS'
            )!;

            mentionList.forEach((data) => {
              if (data.id == AuthService.GetAccountInfo().id) {
                return;
              }
              this.listMention.push({
                username: data.nickName?.trimStart(),
                name: data.nickName?.trimStart(),
                img: data.avatar,
                id: data.id,
              });
            });

            this.initConfigMention();
          }
        })
      )
      .subscribe();
  }

  detachText() {
    if (this.listMember && this.listMember.length > 0) {
      return;
    } else {
      if (this.content.includes('@')) {
        this.getListInlivers();
      }
    }
  }

  renderLinkForCopy() {
    //LoadingService.$(true);

    let url =
      environment.BASE_URL +
      '/manage-club/post-detail' +
      '?postId=' +
      this.post?.id! +
      '&clubId=' +
      this.post?.clubId! +
      '&stageId=' +
      this.post?.stageId!;

    this._firebaseService.generateDeepLink(url).subscribe((res: any) => {
      this.linkForCopy = res.shortLink + '?efr=1';
      //LoadingService.$(false);
    });
  }

  sortFiles(files: any) {
    return _.orderBy(files, 'format', 'desc');
  }

  // checkProcessMedia() {
  //   let numberOfMedia = this.post.numberOfMedia;
  //   let mediaPosts = this.post.mediaPosts!;

  //   if (numberOfMedia == 0) {
  //     return true;
  //   } else {
  //     if (mediaPosts.length > 0) {
  //       return true;
  //     } else {
  //       if (this.post.author?.id == AuthService.GetAccountInfo().id) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   }
  // }

  showNotification(detail: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: detail,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }

  gotoUrl() {
    window.open(this.post.metaLinks![0].url!, '_blank');
  }

  pinPost(isPinned: boolean) {
    this._postService
      .inlivewithPostPinPostPostIdStageIdPost$Json({
        postId: this.post.id!,
        stageId: this.post.stageId!,
        isPinPost: isPinned,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.post.isPinPost = isPinned;
            let payload = {
              post: this.post!,
              isPinned: isPinned,
              error: false,
            };
            this.makeOption();
            this.updatePinnedPost.emit(payload);
          }

          if (res.res == -3) {
            let payload = {
              post: this.post!,
              isPinned: isPinned,
              error: true,
            };
            this.updatePinnedPost.emit(payload);
          }
        })
      )
      .subscribe();
  }

  log() {
    this.initMenuFor();
  }

  updatePostAfterEdit(event: any) {
    setTimeout(() => {
      this.refresh();
    }, 1000);
    this.getMetaLinksIndex();
  }
}
