/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubResponseInGoInlivePaginationModelResponseModel } from '../models/club-response-in-go-inlive-pagination-model-response-model';
import { ClubsByGoInliveIdResponePaginationModelResponseModel } from '../models/clubs-by-go-inlive-id-respone-pagination-model-response-model';
import { EndGoInliveDetail } from '../models/end-go-inlive-detail';
import { GoInliveAccountCreateModel } from '../models/go-inlive-account-create-model';
import { GoInliveAccountWatchingResponseModelListResponseModel } from '../models/go-inlive-account-watching-response-model-list-response-model';
import { GoInliveAccountWatchingResponseModelResponseModel } from '../models/go-inlive-account-watching-response-model-response-model';
import { GoInliveCreateModel } from '../models/go-inlive-create-model';
import { GoInliveInputModel } from '../models/go-inlive-input-model';
import { GoInliveInviteModel } from '../models/go-inlive-invite-model';
import { GoInliveResponseModelWithClubDetailPaginationModelResponseModel } from '../models/go-inlive-response-model-with-club-detail-pagination-model-response-model';
import { GoInliveResponseModelWithClubDetailResponseModel } from '../models/go-inlive-response-model-with-club-detail-response-model';
import { InvitationGoInliveResponseModelListResponseModel } from '../models/invitation-go-inlive-response-model-list-response-model';
import { InvitationGoInliveResponseModelPaginationModelResponseModel } from '../models/invitation-go-inlive-response-model-pagination-model-response-model';
import { InvitationGoInliveResponseModelResponseModel } from '../models/invitation-go-inlive-response-model-response-model';
import { KickedOutGoInliveAccountResponseModelListResponseModel } from '../models/kicked-out-go-inlive-account-response-model-list-response-model';
import { TheTotalGoInliveResponseResponseModel } from '../models/the-total-go-inlive-response-response-model';
import { TheTottalInformationGoInliveResponseResponseModel } from '../models/the-tottal-information-go-inlive-response-response-model';

@Injectable({
  providedIn: 'root',
})
export class GoInliveService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithGoInlivePageGet
   */
  static readonly InlivewithGoInlivePageGetPath = '/inlivewith/GoInlive/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInlivePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInlivePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInlivePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInlivePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInlivePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInlivePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInlivePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInlivePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetTotalInformationGoInliveGet
   */
  static readonly InlivewithGoInliveGetTotalInformationGoInliveGetPath = '/inlivewith/GoInlive/GetTotalInformationGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetTotalInformationGoInliveGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTotalInformationGoInliveGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetTotalInformationGoInliveGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetTotalInformationGoInliveGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTotalInformationGoInliveGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<TheTottalInformationGoInliveResponseResponseModel> {

    return this.inlivewithGoInliveGetTotalInformationGoInliveGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>) => r.body as TheTottalInformationGoInliveResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetTotalInformationGoInliveGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTotalInformationGoInliveGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetTotalInformationGoInliveGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetTotalInformationGoInliveGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTotalInformationGoInliveGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<TheTottalInformationGoInliveResponseResponseModel> {

    return this.inlivewithGoInliveGetTotalInformationGoInliveGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TheTottalInformationGoInliveResponseResponseModel>) => r.body as TheTottalInformationGoInliveResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByIdGoInliveIdGet
   */
  static readonly InlivewithGoInliveGetGoInliveByIdGoInliveIdGetPath = '/inlivewith/GoInlive/GetGoInliveById/{goInliveId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Plain$Response(params: {
    goInliveId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByIdGoInliveIdGetPath, 'get');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Plain(params: {
    goInliveId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json$Response(params: {
    goInliveId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByIdGoInliveIdGetPath, 'get');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json(params: {
    goInliveId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByIdGoInliveIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByStreamingGet
   */
  static readonly InlivewithGoInliveGetGoInliveByStreamingGetPath = '/inlivewith/GoInlive/GetGoInliveByStreaming';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByStreamingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByStreamingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByAccountGet
   */
  static readonly InlivewithGoInliveGetGoInliveByAccountGetPath = '/inlivewith/GoInlive/GetGoInliveByAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByAccountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Plain(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByAccountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Json$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByAccountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Json(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByAccountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveThatAccountNotInClubGet
   */
  static readonly InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath = '/inlivewith/GoInlive/GetGoInliveThatAccountNotInClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByClubGet
   */
  static readonly InlivewithGoInliveGetGoInliveByClubGetPath = '/inlivewith/GoInlive/GetGoInliveByClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByClubGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Plain$Response(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByClubGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByClubGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Plain(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByClubGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByClubGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Json$Response(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByClubGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByClubGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Json(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByClubGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetClubsOfCelebIsStreamingGet
   */
  static readonly InlivewithGoInliveGetClubsOfCelebIsStreamingGetPath = '/inlivewith/GoInlive/GetClubsOfCelebIsStreaming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetClubsOfCelebIsStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseInGoInlivePaginationModelResponseModel> {

    return this.inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>) => r.body as ClubResponseInGoInlivePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetClubsOfCelebIsStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseInGoInlivePaginationModelResponseModel> {

    return this.inlivewithGoInliveGetClubsOfCelebIsStreamingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseInGoInlivePaginationModelResponseModel>) => r.body as ClubResponseInGoInlivePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCreatePost
   */
  static readonly InlivewithGoInliveCreatePostPath = '/inlivewith/GoInlive/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Plain$Response(params?: {
    body?: GoInliveCreateModel
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Plain(params?: {
    body?: GoInliveCreateModel
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Json$Response(params?: {
    body?: GoInliveCreateModel
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Json(params?: {
    body?: GoInliveCreateModel
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetTheMostFamousGoInLiveGet
   */
  static readonly InlivewithGoInliveGetTheMostFamousGoInLiveGetPath = '/inlivewith/GoInlive/GetTheMostFamousGoInLive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetTheMostFamousGoInLiveGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTheMostFamousGoInLiveGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<TheTotalGoInliveResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetTheMostFamousGoInLiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TheTotalGoInliveResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetTheMostFamousGoInLiveGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTheMostFamousGoInLiveGet$Plain(params?: {
  }): Observable<TheTotalGoInliveResponseResponseModel> {

    return this.inlivewithGoInliveGetTheMostFamousGoInLiveGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TheTotalGoInliveResponseResponseModel>) => r.body as TheTotalGoInliveResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetTheMostFamousGoInLiveGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTheMostFamousGoInLiveGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<TheTotalGoInliveResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetTheMostFamousGoInLiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TheTotalGoInliveResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetTheMostFamousGoInLiveGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetTheMostFamousGoInLiveGet$Json(params?: {
  }): Observable<TheTotalGoInliveResponseResponseModel> {

    return this.inlivewithGoInliveGetTheMostFamousGoInLiveGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TheTotalGoInliveResponseResponseModel>) => r.body as TheTotalGoInliveResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveUpdateEndGoInlivePut
   */
  static readonly InlivewithGoInliveUpdateEndGoInlivePutPath = '/inlivewith/GoInlive/UpdateEndGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveUpdateEndGoInlivePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveUpdateEndGoInlivePut$Plain$Response(params?: {
    body?: EndGoInliveDetail
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveUpdateEndGoInlivePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveUpdateEndGoInlivePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveUpdateEndGoInlivePut$Plain(params?: {
    body?: EndGoInliveDetail
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveUpdateEndGoInlivePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveUpdateEndGoInlivePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveUpdateEndGoInlivePut$Json$Response(params?: {
    body?: EndGoInliveDetail
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveUpdateEndGoInlivePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveUpdateEndGoInlivePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveUpdateEndGoInlivePut$Json(params?: {
    body?: EndGoInliveDetail
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveUpdateEndGoInlivePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveOutGoInLivePost
   */
  static readonly InlivewithGoInliveOutGoInLivePostPath = '/inlivewith/GoInlive/OutGoInLive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveOutGoInLivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveOutGoInLivePost$Plain$Response(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveOutGoInLivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveOutGoInLivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveOutGoInLivePost$Plain(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveOutGoInLivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveOutGoInLivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveOutGoInLivePost$Json$Response(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveOutGoInLivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveOutGoInLivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveOutGoInLivePost$Json(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveOutGoInLivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetClubsGoInLiveIdGet
   */
  static readonly InlivewithGoInliveGetClubsGoInLiveIdGetPath = '/inlivewith/GoInlive/GetClubsGoInLiveId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetClubsGoInLiveIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsGoInLiveIdGet$Plain$Response(params?: {
    goInLiveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetClubsGoInLiveIdGetPath, 'get');
    if (params) {
      rb.query('goInLiveId', params.goInLiveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetClubsGoInLiveIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsGoInLiveIdGet$Plain(params?: {
    goInLiveId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubsByGoInliveIdResponePaginationModelResponseModel> {

    return this.inlivewithGoInliveGetClubsGoInLiveIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>) => r.body as ClubsByGoInliveIdResponePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetClubsGoInLiveIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsGoInLiveIdGet$Json$Response(params?: {
    goInLiveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetClubsGoInLiveIdGetPath, 'get');
    if (params) {
      rb.query('goInLiveId', params.goInLiveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetClubsGoInLiveIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetClubsGoInLiveIdGet$Json(params?: {
    goInLiveId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubsByGoInliveIdResponePaginationModelResponseModel> {

    return this.inlivewithGoInliveGetClubsGoInLiveIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubsByGoInliveIdResponePaginationModelResponseModel>) => r.body as ClubsByGoInliveIdResponePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveJoinGoInlivePost
   */
  static readonly InlivewithGoInliveJoinGoInlivePostPath = '/inlivewith/GoInlive/JoinGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveJoinGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveJoinGoInlivePost$Plain$Response(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveJoinGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveJoinGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveJoinGoInlivePost$Plain(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<GoInliveAccountWatchingResponseModelListResponseModel> {

    return this.inlivewithGoInliveJoinGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>) => r.body as GoInliveAccountWatchingResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveJoinGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveJoinGoInlivePost$Json$Response(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveJoinGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveJoinGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveJoinGoInlivePost$Json(params?: {
    body?: GoInliveAccountCreateModel
  }): Observable<GoInliveAccountWatchingResponseModelListResponseModel> {

    return this.inlivewithGoInliveJoinGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>) => r.body as GoInliveAccountWatchingResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetAccountsJoiningGoInliveGet
   */
  static readonly InlivewithGoInliveGetAccountsJoiningGoInliveGetPath = '/inlivewith/GoInlive/GetAccountsJoiningGoInlive';

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAccountsJoiningGoInliveGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAccountsJoiningGoInliveGet$Plain$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAccountsJoiningGoInliveGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAccountsJoiningGoInliveGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAccountsJoiningGoInliveGet$Plain(params?: {
    goInliveId?: string;
  }): Observable<GoInliveAccountWatchingResponseModelListResponseModel> {

    return this.inlivewithGoInliveGetAccountsJoiningGoInliveGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>) => r.body as GoInliveAccountWatchingResponseModelListResponseModel)
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAccountsJoiningGoInliveGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json(params?: {
    goInliveId?: string;
  }): Observable<GoInliveAccountWatchingResponseModelListResponseModel> {

    return this.inlivewithGoInliveGetAccountsJoiningGoInliveGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelListResponseModel>) => r.body as GoInliveAccountWatchingResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet
   */
  static readonly InlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGetPath = '/inlivewith/GoInlive/GetRoleOfViewerInGoInlive/{goInliveId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Plain$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Plain(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<GoInliveAccountWatchingResponseModelResponseModel> {

    return this.inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>) => r.body as GoInliveAccountWatchingResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Json$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Json(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<GoInliveAccountWatchingResponseModelResponseModel> {

    return this.inlivewithGoInliveGetRoleOfViewerInGoInliveGoInliveIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveAccountWatchingResponseModelResponseModel>) => r.body as GoInliveAccountWatchingResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveEndGoInlivePut
   */
  static readonly InlivewithGoInliveEndGoInlivePutPath = '/inlivewith/GoInlive/EndGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveEndGoInlivePut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Plain$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveEndGoInlivePutPath, 'put');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveEndGoInlivePut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Plain(params?: {
    goInliveId?: string;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveEndGoInlivePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveEndGoInlivePut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Json$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveEndGoInlivePutPath, 'put');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveEndGoInlivePut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Json(params?: {
    goInliveId?: string;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveEndGoInlivePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost
   */
  static readonly InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath = '/inlivewith/GoInlive/KickedOutGoInliveAccount/{goInliveId}/{authorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath, 'post');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('authorId', params.authorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<KickedOutGoInliveAccountResponseModelListResponseModel> {

    return this.inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>) => r.body as KickedOutGoInliveAccountResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath, 'post');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('authorId', params.authorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<KickedOutGoInliveAccountResponseModelListResponseModel> {

    return this.inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>) => r.body as KickedOutGoInliveAccountResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetAllInvitationGet
   */
  static readonly InlivewithGoInliveGetAllInvitationGetPath = '/inlivewith/GoInlive/GetAllInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAllInvitationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAllInvitationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetAllInvitationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAllInvitationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAllInvitationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetAllInvitationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetInvitationByAccountGet
   */
  static readonly InlivewithGoInliveGetInvitationByAccountGetPath = '/inlivewith/GoInlive/GetInvitationByAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByAccountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Plain$Response(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByAccountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Plain(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByAccountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByAccountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Json$Response(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByAccountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Json(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByAccountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetInvitationByGoInliveIdGet
   */
  static readonly InlivewithGoInliveGetInvitationByGoInliveIdGetPath = '/inlivewith/GoInlive/GetInvitationByGoInliveId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByGoInliveIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByGoInliveIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByGoInliveIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Json(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveInviteToGoInlivePost
   */
  static readonly InlivewithGoInliveInviteToGoInlivePostPath = '/inlivewith/GoInlive/InviteToGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveInviteToGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Plain$Response(params?: {
    body?: GoInliveInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveInviteToGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveInviteToGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Plain(params?: {
    body?: GoInliveInviteModel
  }): Observable<InvitationGoInliveResponseModelListResponseModel> {

    return this.inlivewithGoInliveInviteToGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>) => r.body as InvitationGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveInviteToGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Json$Response(params?: {
    body?: GoInliveInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveInviteToGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveInviteToGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Json(params?: {
    body?: GoInliveInviteModel
  }): Observable<InvitationGoInliveResponseModelListResponseModel> {

    return this.inlivewithGoInliveInviteToGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>) => r.body as InvitationGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveSelectGoInlivedInvitePut
   */
  static readonly InlivewithGoInliveSelectGoInlivedInvitePutPath = '/inlivewith/GoInlive/SelectGoInlivedInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSelectGoInlivedInvitePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response(params?: {
    body?: GoInliveInputModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveSelectGoInlivedInvitePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Plain(params?: {
    body?: GoInliveInputModel
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSelectGoInlivedInvitePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response(params?: {
    body?: GoInliveInputModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveSelectGoInlivedInvitePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Json(params?: {
    body?: GoInliveInputModel
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut
   */
  static readonly InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath = '/inlivewith/GoInlive/CancelInvitaionGoInlived/{goInliveId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath, 'put');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath, 'put');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet
   */
  static readonly InlivewithGoInliveCheckAccountIsStreamingInClubClubIdGetPath = '/inlivewith/GoInlive/CheckAccountIsStreamingInClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCheckAccountIsStreamingInClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCheckAccountIsStreamingInClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveCheckAccountIsStreamingInClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut
   */
  static readonly InlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPutPath = '/inlivewith/GoInlive/UpdateRaiseHandStatus/{goinliveId}/{accountId}/{status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Plain$Response(params: {
    goinliveId: string;
    accountId: string;
    status: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPutPath, 'put');
    if (params) {
      rb.path('goinliveId', params.goinliveId, {});
      rb.path('accountId', params.accountId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Plain(params: {
    goinliveId: string;
    accountId: string;
    status: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Json$Response(params: {
    goinliveId: string;
    accountId: string;
    status: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPutPath, 'put');
    if (params) {
      rb.path('goinliveId', params.goinliveId, {});
      rb.path('accountId', params.accountId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Json(params: {
    goinliveId: string;
    accountId: string;
    status: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveUpdateRaiseHandStatusGoinliveIdAccountIdStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
