import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  ClubResponseInPostCelebrity,
  MediaPostResponseModel,
  PostOfCelebrityForNewFeedResponseModel,
} from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { MENTION_REGEX, URL_REGEX, defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-post-celeb',
  templateUrl: './card-post-celeb.component.html',
  styleUrls: ['./card-post-celeb.component.scss'],
})
export class CardPostCelebComponent implements OnInit {
  @Input() post!: PostOfCelebrityForNewFeedResponseModel;

  countMedia: number = 0;

  defaultAvatar = defaultAvatar;

  showListClubsPosted: boolean = false;

  constructor(
    private _postService: PostService,
    private _router: Router
  ) { }

  ngOnInit(): void { }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (asset?.url.includes('agora/recording')) {
        return asset?.url!;
      }

      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        if (this.countMedia == 1) {
          return asset?.url!;
        } else {
          return asset?.url!.replace('/file.', '/thumb.');
        }
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  urlify(text: any) {
    text = text?.replace(MENTION_REGEX, (username: string) => {
      return `<a>${username}</a>`;
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${decodeURIComponent(url)}" target="_blank">${decodeURIComponent(url)}</a>`;
    });
  }

  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();
  };

  handleClick() {
    if (this.post.totalNumberOfClub! > 1) {
      this.showListClubsPosted = true
    } else {
      this.getClubByPostCelebId();
    }
  }

  getClubByPostCelebId() {
    let body = {
      postCelebId: this.post.postCelebrityId!,
      page: 1,
      limit: 3,
      isFilterJoined: true,
    };

    this._postService
      .inlivewithPostGetClubWithMostMemberByPostCelebrityIdGet$Json(body)
      .subscribe((res) => {
        this.gotoDetail(res.data?.topClub?.page![0]!)
      });
  }

  gotoDetail(club: ClubResponseInPostCelebrity) {
    this._router
      .navigate(['manage-club/post-detail'], {
        queryParams: { postId: club.postId!, clubId: club.id!, stageId: club.stageId! },
      })
  }
}
