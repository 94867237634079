/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplicationUserResponseModel } from '../models/application-user-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ChangePasswordViewModel } from '../models/change-password-view-model';
import { CheckAccountPasswordViewModel } from '../models/check-account-password-view-model';
import { CompleteExternalAuthModel } from '../models/complete-external-auth-model';
import { CompleteExternalAuthResultModelResponseModel } from '../models/complete-external-auth-result-model-response-model';
import { DateTimeNullableResponseModel } from '../models/date-time-nullable-response-model';
import { DeleteViewModel } from '../models/delete-view-model';
import { ForgetPasswordViewModel } from '../models/forget-password-view-model';
import { LoginResultModelResponseModel } from '../models/login-result-model-response-model';
import { LoginViewModel } from '../models/login-view-model';
import { RegisterResultModelResponseModel } from '../models/register-result-model-response-model';
import { RegisterViewModel } from '../models/register-view-model';
import { ResetPasswordViewModel } from '../models/reset-password-view-model';
import { ResultSearchAccountViewModelResponseModel } from '../models/result-search-account-view-model-response-model';
import { StringIListResponseModel } from '../models/string-i-list-response-model';
import { StringResponseModel } from '../models/string-response-model';
import { UserRoleViewModel } from '../models/user-role-view-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityAccountRegisterPost
   */
  static readonly IdentityAccountRegisterPostPath = '/identity/Account/Register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Plain$Response(params?: {
    body?: RegisterViewModel
  }): Observable<StrictHttpResponse<RegisterResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Plain(params?: {
    body?: RegisterViewModel
  }): Observable<RegisterResultModelResponseModel> {

    return this.identityAccountRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RegisterResultModelResponseModel>) => r.body as RegisterResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Json$Response(params?: {
    body?: RegisterViewModel
  }): Observable<StrictHttpResponse<RegisterResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Json(params?: {
    body?: RegisterViewModel
  }): Observable<RegisterResultModelResponseModel> {

    return this.identityAccountRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RegisterResultModelResponseModel>) => r.body as RegisterResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountReSendConfirmEmailEmailPost
   */
  static readonly IdentityAccountReSendConfirmEmailEmailPostPath = '/identity/Account/ReSendConfirmEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountReSendConfirmEmailEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountReSendConfirmEmailEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountReSendConfirmEmailEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Plain(params: {
    email: string;
  }): Observable<StringResponseModel> {

    return this.identityAccountReSendConfirmEmailEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountReSendConfirmEmailEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountReSendConfirmEmailEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountReSendConfirmEmailEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Json(params: {
    email: string;
  }): Observable<StringResponseModel> {

    return this.identityAccountReSendConfirmEmailEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountLoginPost
   */
  static readonly IdentityAccountLoginPostPath = '/identity/Account/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Plain$Response(params?: {
    body?: LoginViewModel
  }): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Plain(params?: {
    body?: LoginViewModel
  }): Observable<LoginResultModelResponseModel> {

    return this.identityAccountLoginPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Json$Response(params?: {
    body?: LoginViewModel
  }): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Json(params?: {
    body?: LoginViewModel
  }): Observable<LoginResultModelResponseModel> {

    return this.identityAccountLoginPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountCompleteExternalLoginAccountIdPost
   */
  static readonly IdentityAccountCompleteExternalLoginAccountIdPostPath = '/identity/Account/CompleteExternalLogin/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCompleteExternalLoginAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: CompleteExternalAuthModel
  }): Observable<StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCompleteExternalLoginAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCompleteExternalLoginAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Plain(params: {
    accountId: string;
    body?: CompleteExternalAuthModel
  }): Observable<CompleteExternalAuthResultModelResponseModel> {

    return this.identityAccountCompleteExternalLoginAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>) => r.body as CompleteExternalAuthResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCompleteExternalLoginAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: CompleteExternalAuthModel
  }): Observable<StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCompleteExternalLoginAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCompleteExternalLoginAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Json(params: {
    accountId: string;
    body?: CompleteExternalAuthModel
  }): Observable<CompleteExternalAuthResultModelResponseModel> {

    return this.identityAccountCompleteExternalLoginAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>) => r.body as CompleteExternalAuthResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountConfirmEmailGet
   */
  static readonly IdentityAccountConfirmEmailGetPath = '/identity/Account/ConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountConfirmEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Plain$Response(params?: {
    email?: string;
    token?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountConfirmEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Plain(params?: {
    email?: string;
    token?: string;
  }): Observable<BooleanResponseModel> {

    return this.identityAccountConfirmEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountConfirmEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Json$Response(params?: {
    email?: string;
    token?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountConfirmEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Json(params?: {
    email?: string;
    token?: string;
  }): Observable<BooleanResponseModel> {

    return this.identityAccountConfirmEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountValidateAccountAccountIdPost
   */
  static readonly IdentityAccountValidateAccountAccountIdPostPath = '/identity/Account/ValidateAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountValidateAccountAccountIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountValidateAccountAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountValidateAccountAccountIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.identityAccountValidateAccountAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountValidateAccountAccountIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountValidateAccountAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountValidateAccountAccountIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.identityAccountValidateAccountAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountForgotPasswordPost
   */
  static readonly IdentityAccountForgotPasswordPostPath = '/identity/Account/ForgotPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForgotPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Plain$Response(params?: {
    body?: ForgetPasswordViewModel
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForgotPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Plain(params?: {
    body?: ForgetPasswordViewModel
  }): Observable<StringResponseModel> {

    return this.identityAccountForgotPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForgotPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Json$Response(params?: {
    body?: ForgetPasswordViewModel
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForgotPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Json(params?: {
    body?: ForgetPasswordViewModel
  }): Observable<StringResponseModel> {

    return this.identityAccountForgotPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountResetPasswordPost
   */
  static readonly IdentityAccountResetPasswordPostPath = '/identity/Account/ResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountResetPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Plain$Response(params?: {
    body?: ResetPasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountResetPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Plain(params?: {
    body?: ResetPasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountResetPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountResetPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Json$Response(params?: {
    body?: ResetPasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountResetPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Json(params?: {
    body?: ResetPasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountResetPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountChangePasswordPost
   */
  static readonly IdentityAccountChangePasswordPostPath = '/identity/Account/ChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Plain$Response(params?: {
    body?: ChangePasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Plain(params?: {
    body?: ChangePasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountChangePasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Json$Response(params?: {
    body?: ChangePasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Json(params?: {
    body?: ChangePasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountChangePasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAddToRolePost
   */
  static readonly IdentityAccountAddToRolePostPath = '/identity/Account/AddToRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddToRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Plain$Response(params?: {
    body?: UserRoleViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddToRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddToRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Plain(params?: {
    body?: UserRoleViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountAddToRolePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddToRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Json$Response(params?: {
    body?: UserRoleViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddToRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddToRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Json(params?: {
    body?: UserRoleViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountAddToRolePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRemoveFromRolePost
   */
  static readonly IdentityAccountRemoveFromRolePostPath = '/identity/Account/RemoveFromRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveFromRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Plain$Response(params?: {
    body?: UserRoleViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveFromRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveFromRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Plain(params?: {
    body?: UserRoleViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountRemoveFromRolePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveFromRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Json$Response(params?: {
    body?: UserRoleViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveFromRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveFromRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Json(params?: {
    body?: UserRoleViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountRemoveFromRolePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetUserRolesEmailGet
   */
  static readonly IdentityAccountGetUserRolesEmailGetPath = '/identity/Account/GetUserRoles/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserRolesEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserRolesEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserRolesEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Plain(params: {
    email: string;
  }): Observable<StringIListResponseModel> {

    return this.identityAccountGetUserRolesEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringIListResponseModel>) => r.body as StringIListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserRolesEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserRolesEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserRolesEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Json(params: {
    email: string;
  }): Observable<StringIListResponseModel> {

    return this.identityAccountGetUserRolesEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringIListResponseModel>) => r.body as StringIListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRefreshTokenPost
   */
  static readonly IdentityAccountRefreshTokenPostPath = '/identity/Account/RefreshToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRefreshTokenPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Plain$Response(params?: {
    token?: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRefreshTokenPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Plain(params?: {
    token?: string;
  }): Observable<StringResponseModel> {

    return this.identityAccountRefreshTokenPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRefreshTokenPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Json$Response(params?: {
    token?: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRefreshTokenPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Json(params?: {
    token?: string;
  }): Observable<StringResponseModel> {

    return this.identityAccountRefreshTokenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountDeleteByIdDelete
   */
  static readonly IdentityAccountDeleteByIdDeletePath = '/identity/Account/DeleteById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Plain$Response(params?: {
    id?: string;
    body?: DeleteViewModel
  }): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Plain(params?: {
    id?: string;
    body?: DeleteViewModel
  }): Observable<ApplicationUserResponseModel> {

    return this.identityAccountDeleteByIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Json$Response(params?: {
    id?: string;
    body?: DeleteViewModel
  }): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Json(params?: {
    id?: string;
    body?: DeleteViewModel
  }): Observable<ApplicationUserResponseModel> {

    return this.identityAccountDeleteByIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByUsernameUserNameGet
   */
  static readonly IdentityAccountSearchByUsernameUserNameGetPath = '/identity/Account/SearchByUsername/{userName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByUsernameUserNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Plain$Response(params: {
    userName: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByUsernameUserNameGetPath, 'get');
    if (params) {
      rb.path('userName', params.userName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByUsernameUserNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Plain(params: {
    userName: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByUsernameUserNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByUsernameUserNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Json$Response(params: {
    userName: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByUsernameUserNameGetPath, 'get');
    if (params) {
      rb.path('userName', params.userName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByUsernameUserNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Json(params: {
    userName: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByUsernameUserNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByIdAccountIdGet
   */
  static readonly IdentityAccountSearchByIdAccountIdGetPath = '/identity/Account/SearchById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByEmailEmailGet
   */
  static readonly IdentityAccountSearchByEmailEmailGetPath = '/identity/Account/SearchByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Plain(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Json(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountForceChangePasswordPost
   */
  static readonly IdentityAccountForceChangePasswordPostPath = '/identity/Account/ForceChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForceChangePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Plain$Response(params?: {
    body?: ChangePasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForceChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForceChangePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Plain(params?: {
    body?: ChangePasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountForceChangePasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForceChangePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Json$Response(params?: {
    body?: ChangePasswordViewModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForceChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForceChangePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Json(params?: {
    body?: ChangePasswordViewModel
  }): Observable<BooleanResponseModel> {

    return this.identityAccountForceChangePasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountCheckAccountPasswordPost
   */
  static readonly IdentityAccountCheckAccountPasswordPostPath = '/identity/Account/CheckAccountPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCheckAccountPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Plain$Response(params?: {
    body?: CheckAccountPasswordViewModel
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCheckAccountPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCheckAccountPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Plain(params?: {
    body?: CheckAccountPasswordViewModel
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountCheckAccountPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCheckAccountPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Json$Response(params?: {
    body?: CheckAccountPasswordViewModel
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCheckAccountPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCheckAccountPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Json(params?: {
    body?: CheckAccountPasswordViewModel
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountCheckAccountPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetBirthdayByIdAccountIdGet
   */
  static readonly IdentityAccountGetBirthdayByIdAccountIdGetPath = '/identity/Account/GetBirthdayById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetBirthdayByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetBirthdayByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetBirthdayByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.identityAccountGetBirthdayByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetBirthdayByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetBirthdayByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetBirthdayByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.identityAccountGetBirthdayByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

}
