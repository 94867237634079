<p-dialog [(visible)]="isShowRulesClub" [draggable]="false" [modal]="true" [resizable]="false" [closable]="false">
    <div class="card flex align-items-center flex-column" *ngIf="club">

        <div class="flex" style="margin-top: -7rem; position: fixed;">
            <div class="zone-avt">
                <img [src]="club.avatar! | fetchUrlAvatarClub" class="fit-size">
            </div>
        </div>

        <div class="label mt-5 text-white fs-20">
            {{club.name!}}
        </div>
        <div class="mt-3">
            <img src="../../../assets/images/svgs/members.svg">
            {{club.totalMember! == 0 ? totalMembers : club.totalMember!}} {{(club.totalMember! > 1 ? 'MEMBERS' :
            'MEMBER') | translate}}
        </div>

        <div class="text mt-5 first-zone">
            <span>{{'CLUB_RULE_TITLE' | translate}}</span>
        </div>

        <div class="rules-zone text-white mt-7" [innerText]="club!.policy!">
        </div>

        <div class="flex justify-content-center mt-5" *ngIf="!isShowButtonClose">
            <button pButton type="submit" label="{{'NOT_AGREE' | translate}}" (click)="close()"
                class="mt-5 mr-2 p-button-outlined p-button-secondary"></button>
            <button pButton type="submit"
                label="{{(routeTogoDetailEvent ? 'JOIN_CLUB_AND_EVENT' : 'AGREE') | translate}}" (click)="acceptRule()"
                class="mt-5 ml-2 p-button-outlined p-button-secondary"></button>
        </div>

        <div class="flex justify-content-center mt-5" *ngIf="isShowButtonClose">
            <button pButton type="submit" label="{{'Fermer' | translate}}" (click)="close()"
                class="mt-5 mr-2 p-button-outlined p-button-secondary"></button>
        </div>
    </div>


    <div class="card flex align-items-center flex-column" *ngIf="clubCeleb">

        <div class="flex" style="margin-top: -7rem; position: fixed;">
            <div class="zone-avt">
                <img [src]="clubCeleb.avatar! | fetchUrlImage" class="fit-size">
            </div>
        </div>

        <div class="label mt-5 text-white fs-20">
            {{clubCeleb.name!}}
        </div>

        <div class="text mt-5 first-zone">
            <span>{{'CLUB_RULE_TITLE' | translate}}</span>
        </div>

        <div class="rules-zone text-white mt-7" [innerText]="clubCeleb!.policy!">
        </div>

        <div class="flex justify-content-center mt-5" *ngIf="!isShowButtonClose">
            <button pButton type="submit" label="{{'NOT_AGREE' | translate}}" (click)="close()"
                class="mt-5 mr-2 p-button-outlined p-button-secondary"></button>
            <button pButton type="submit" [label]="'AGREE' | translate" (click)="acceptRule()"
                class="mt-5 ml-2 p-button-outlined p-button-secondary"></button>
        </div>
    </div>
</p-dialog>

<!-- <p-sidebar [(visible)]="isShowRulesClub" [showCloseIcon]="true" [dismissible]="false" position="bottom">
    <div class="flex align-items-center flex-column" *ngIf="club && fromMobile && isShowRulesClub">

        <div class="flex" style="margin-top: -7rem; position: fixed;">
            <div class="zone-avt">
                <img [src]="club.avatar! | fetchUrlImage" class="fit-size">
            </div>
        </div>
    
        <div class="label mt-5 text-white fs-20">
            {{club.name!}}
        </div>
        <div class="mt-3">
            <img src="../../../assets/images/svgs/members.svg"> 
            {{club.totalMember! == 0 ? totalMembers : club.totalMember!}} {{(club.totalMember! > 1 ? 'MEMBERS' : 'MEMBER') | translate}}
        </div>
    
        <div class="text mt-5 first-zone">
            <span>{{'CLUB_RULE_TITLE' | translate}}</span>
        </div>
    
        <div class="rules-zone text-white mt-7" [innerText]="club!.policy!">
        </div>
    
        <div class="flex justify-content-center mt-5">
            <button pButton type="submit" label="{{'NOT_AGREE' | translate}}" (click)="close()"
                class="mt-5 mr-2 p-button-outlined p-button-secondary"></button>
            <button pButton type="submit" label="{{(routeTogoDetailEvent ? 'JOIN_CLUB_AND_EVENT' : 'AGREE') | translate}}" (click)="acceptRule()"
                class="mt-5 ml-2 p-button-outlined p-button-secondary"></button>
        </div>
    </div>
</p-sidebar> -->


<ng-container *ngIf="isOpenConfirmJoinEvent">
    <app-card-confirm-join-event [(isShowPopup)]="isOpenConfirmJoinEvent" [event]="event"
        [reloadPage]="event ? true : false" [firstButtonTitle]="'NO_THANKS' | translate"
        [secondButtonTitle]="'YES_JOIN_EVENT' | translate"
        (doAction)="confirmJoinEvent()"></app-card-confirm-join-event>
</ng-container>