<p-dialog [(visible)]="isConfirmJoinClub" [autoZIndex]="true" [closable]="true" [resizable]="false" [draggable]="false" [modal]="true" (onHide)="onHide()">

    <div class="card flex align-items-center flex-column">

        <div class="flex" style="margin-top: -17rem;position: fixed;">
            <div class="zone-avt" *ngIf="club && club.avatar">
                <img [src]="club.avatar | fetchUrlAvatarClub" class="fit-size">
            </div>
        </div>

        <div class="label mt-7" *ngIf="club && club.name">
            {{club.name | limitString:false:50}}
        </div>

        <div class="text mt-3" style="width: 76%;" [innerHTML]="'CLUB_CONFIRM_JOIN_TITLE' | translate"></div>

        <div class="flex justify-content-center mt-3">
            <button pButton type="submit" label="{{'CANCEL' | translate}}" class="mt-3 p-button-outlined p-button-secondary mr-2" (click)="isConfirmJoinClub = false"></button>
            <button pButton type="submit" label="{{'CLUB_REJOIN' | translate}}" class="mt-3 p-button-outlined p-button-secondary ml-2" (click)="joinClub()"></button>
        </div>
    </div>
</p-dialog>