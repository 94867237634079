import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-card-create-club-success',
  templateUrl: './card-create-club-success.component.html',
  styleUrls: ['./card-create-club-success.component.scss'],
})
export class CardCreateClubSuccessComponent implements OnInit {
  showDialog = true;

  @Input() avatar!: File;
  @Input() name!: string;
  @Input() imgBlob!: string | ArrayBuffer | null;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    var reader = new FileReader();
    reader.readAsDataURL(this.avatar);
    reader.onload = (_event) => {
      this.imgBlob = reader.result;
    };
  }

  close() {
    // this.showDialog = false;
    LoadingService.$(true);
    this._router.navigate(['home']).then(() => {
      location.reload();
      LoadingService.$(false);
    });
  }
}
