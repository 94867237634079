import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-confirm-create-album',
  templateUrl: './mobile-confirm-create-album.component.html',
  styleUrls: ['./mobile-confirm-create-album.component.scss']
})
export class MobileConfirmCreateAlbumComponent implements OnInit {

  @Input() showPopupConfirm: boolean = false;
  @Input() imgBlob:any
  @Input() name!: string;

  @Output() showPopupConfirmChange = new EventEmitter()
  @Output() doAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closePopup(){
    this.showPopupConfirmChange.emit(false);
  }
}
