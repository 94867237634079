<p-dialog [(visible)]="showDialog" [draggable]="false" [closable]="true" [resizable]="false" [modal]="true"
    [header]="'USER_PICK_IMAGE' | translate" (onHide)="showDialogChange.emit(false)">
    <div class="content">
        <div class="image">
            <!-- <img [src]="avatar" alt="">
            <div class="img-crop" [ngStyle]="{width: r+'px', height: r+'px'}">
                <img [src]="avatar" alt="">
            </div> -->
            <image-cropper [imageChangedEvent]="imageChangedEvent" format="jpeg" (imageCropped)="imageCropped($event)"
                (loadImageFailed)="loadImageFaile()" (imageLoaded)="imageLoaded()"></image-cropper>
        </div>
    </div>

    <div class="button">
        <button type="button" (click)="showDialogChange.emit(false); upload.emit(croppedImage)">
            {{'REGISTER' | translate}}
        </button>
    </div>
</p-dialog>