import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Account } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-card-user-apply',
  templateUrl: './card-user-apply.component.html',
  styleUrls: ['./card-user-apply.component.scss']
})
export class CardUserApplyComponent implements OnInit {

  @Input() userApplyInfo: any
  @Input() isFirst!: boolean;

  @Output() refreshPage = new EventEmitter();

  defaultAvatar = defaultAvatar

  constructor(
    private _clubService: ClubService
  ) { }

  ngOnInit(): void {
    console.log('userApplyInfo', this.userApplyInfo);

  }

  doAction(approval: boolean, itemBig: any, item: Account) {
    LoadingService.$(true);
    if (this.isFirst) {
      this._clubService.inlivewithClubAccountApprovalAccessLevelPut$Json({
        body: {
          accountId: item.id!,
          clubId: itemBig.clubId!,
          approval: approval,
          accessLevel: approval ? "ACCESSLEVEL_PLUS" : "ACCESSLEVEL_ANONYMOUS"
        }
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.refreshPage.emit();
            LoadingService.$(false);
          }
        })
      ).subscribe(()=>location.reload());
    } else {
      this._clubService.inlivewithClubAccountApprovalPut$Json({
        body: {
          accountId: item.id!,
          clubId: itemBig.clubId!,
          approval: approval,
        }
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.refreshPage.emit();
            LoadingService.$(false);
          }
        })
      ).subscribe(()=>location.reload());
    }
  }

}
