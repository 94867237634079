import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { AppEnumService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-report-step-ii',
  templateUrl: './report-step-ii.component.html',
  styleUrls: ['./report-step-ii.component.scss']
})
export class ReportStepIiComponent implements OnInit {

  @Input() type!: string;
  @Input() nameObject!: string;
  @Input() value!: string;
  @Input() name!: string;

  @Output() next = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() nameChange = new EventEmitter();

  title!: string;
  listReasonReport: any[] = [];

  constructor(
    private _translateService: TranslateService,
    private _appEnumService: AppEnumService
  ) { }

  ngOnInit(): void {

    this.getAppEnum()
    this.getTitle();

  }

  getTitle() {
    if (this.type == "EVENT") {
      return this.title = this._translateService.instant('REPORT_EVENT');
    } else if (this.type == "CLUB") {
      return this.title = this._translateService.instant('REPORT_CLUB');
    } else if (this.type == "COMMENT" || this.type == "COMMENT_ALBUM") {
      return this.title = this._translateService.instant('REPORT_COMMENT');
    } else if (this.type == "POST") {
      return this.title = this._translateService.instant('REPORT_POST');
    } else if (this.type == "INLIVER") {
      return this.title = this._translateService.instant('REPORT_INLIVER');
    }
  }

  getAppEnum() {
    if (this.type == "INLIVER") {
      this._appEnumService.inlivewithAppEnumReportAccountReasonsGet$Json({
        limit: 100
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.listReasonReport = res.data?.page!
          }
        })
      ).subscribe();
    } else {
      this._appEnumService.inlivewithAppEnumReportReasonsGet$Json({
        limit: 100
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.listReasonReport = res.data?.page!
          }
        })
      ).subscribe();
    }
  }

  getNameByKey(key: string) {
    if (key == "ADULT_CONTENT") {
      return this._translateService.instant('ADULT_CONTENT');
    } else if (key == "HEARSAY") {
      return this._translateService.instant('HEARSAY');
    } else if (key == "HARASSMENT_VIOLENCE") {
      return this._translateService.instant('HARASSMENT_VIOLENCE');
    } else if (key == "SELLING_ILLEGAT") {
      return this._translateService.instant('SELLING_ILLEGAT');
    } else if (key == "INFRINGEMENT_INTELLECTUAL") {
      return this._translateService.instant('INFRINGEMENT_INTELLECTUAL');
    } else if (key == "SUICIDE_SELF_HARM") {
      return this._translateService.instant('SUICIDE_SELF_HARM');
    } else if (key == "INCITING_HATE_SPEECH") {
      return this._translateService.instant('INCITING_HATE_SPEECH');
    } else if (key == "OTHER") {
      return this._translateService.instant('OTHER');
    }
  }

  goNext(key: string) {
    this.valueChange.emit(key);
    this.nameChange.emit(this.getNameByKey(key));
    this.next.emit();
  }

}
