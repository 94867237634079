<section class="zone-setting" *ngIf="_router.url.startsWith('/home/mention-legal/condition')">
    <div class="flex flex-column p-3">
        <span class="text-white">Condition</span>
    </div>
</section>

<section class="zone-setting" *ngIf="_router.url.startsWith('/home/mention-legal/policy')">
    <div class="flex flex-column p-3">
        <span class="text-white">Policy</span>
    </div>
</section>

<section class="zone-setting" *ngIf="_router.url.startsWith('/home/mention-legal/legal')">
    <div class="flex flex-column p-3">
        <span class="text-white">Legal</span>
    </div>
</section>

<section class="zone-setting" *ngIf="_router.url.startsWith('/home/mention-legal/cookie')">
    <div class="flex flex-column p-3">
        <span class="text-white">Cookie</span>
    </div>
</section>

<section class="zone-setting" *ngIf="_router.url.startsWith('/home/mention-legal/comunity')">
    <div class="flex flex-column p-3">
        <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              style="height: 900px"
        ></pdf-viewer>
    </div>
</section>
